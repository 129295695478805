/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Rate, Row, Tag } from 'antd';
import { useAppDispatch, useReviewState } from '@core/.';
import { resetReviewRefreshing, selectReview } from '@core/features';
import { GoogleReview, Inquiry, InquiryStage, Review, StarRating } from '@core/models';
import { formatRelativeUtcDate, shortenString } from '@utils/.';
import { RateConversion } from '@constants/rate-conversion';
import { heroApiService } from '@services/service-register';
import { CenteredCardColumn } from '..';
import React, { useEffect, useState } from 'react';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

type Props = {
  item: GoogleReview;
  inquiry?: Inquiry;
  size: 'small' | 'large';
};

const ReviewCard = ({ item, inquiry, size }: Props) => {
  const dispatch = useAppDispatch();
  const { isReviewRefreshing } = useReviewState();
  const [loading, setLoading] = useState(true);
  const [localReview, setLocalReview] = useState<Review | undefined>(undefined);
  const open = async (item: GoogleReview) => {
    let respond: Review;
    if (localReview) {
      // Update info that can be changed
      localReview.comment = item.comment;
      localReview.reviewer = item.reviewer;
      localReview.rating = parseInt(StarRating[item.starRating]);
      localReview.updated_date = item.updateTime;

      if (item.reviewReply) {
        localReview.is_responded = true;
        localReview.responded_date = item.reviewReply.updateTime;
        localReview.generated_response = item.reviewReply.comment;
      }
      respond = await heroApiService.saveReview(localReview, true);
    } else {
      respond = await heroApiService.saveReview(item, false);
    }
    dispatch(selectReview(respond));
  };

  const fetchLocalReview = async () => {
    setLoading(true);
    const _localReview = await heroApiService.getLocalReviewByGoogleId(item.reviewId);
    setLocalReview(_localReview);
    setLoading(false);
  };

  useEffect(() => {
    const onReviewUpdate = async () => {
      if (isReviewRefreshing === item.reviewId) {
        await fetchLocalReview();
        dispatch(resetReviewRefreshing({}));
      }
    };
    onReviewUpdate();
  }, [item.reviewId, isReviewRefreshing]);

  useEffect(() => {
    const onComponentMount = async () => {
      await fetchLocalReview();
    };
    onComponentMount();
  }, [isReviewRefreshing]);

  const renderCardStatus = () => {
    return !item.reviewReply && !localReview?.is_responded ? (
      <Tag color="red">Not responded</Tag>
    ) : (
      <Tag color="green">Responded</Tag>
    );
  };

  const renderInquiryStatus = (): React.ReactElement => {
    if (inquiry?.contact_email || inquiry?.contact_phone)
      return (
        <p>
          {inquiry?.contact_email} {inquiry?.contact_phone}
        </p>
      );
    else if (inquiry?.status === InquiryStage.Sent || inquiry?.status === InquiryStage.Opened) {
      return <Tag color="yellow"> Asking for Contact</Tag>;
    }
    return <></>;
  };

  return (
    <Card
      bordered={false}
      loading={loading}
      onClick={() => open(item)}
      size="small"
      className="no-padding"
      style={{
        cursor: 'pointer',
        background: GLOBAL_THEME_COLOR.$primary_color,
        marginBottom: '10px',
        borderRadius: 5,
      }}>
      <Row
        gutter={20}
        style={{
          padding: '10px 0px',
        }}>
        <CenteredCardColumn span={size === 'small' ? 3 : 2}>
          <img
            referrerPolicy="no-referrer"
            style={{ marginLeft: 20, width: 40 }}
            src={item.reviewer.profilePhotoUrl}
            alt={item.reviewer.profilePhotoUrl}
          />
        </CenteredCardColumn>
        <CenteredCardColumn span={9}>
          <React.Fragment>
            <div style={{ display: 'flex' }}>
              <h4 style={{ display: 'flex', alignItems: 'center', margin: 0, fontSize: 15 }}>
                {item.reviewer.displayName}
              </h4>
              <h3
                style={{
                  display: 'flex',
                  marginLeft: 10,
                  fontWeight: 'normal',
                  fontSize: 'smaller',
                  color: 'gray',
                  alignItems: 'center',
                }}>
                {formatRelativeUtcDate(item.updateTime)}
              </h3>
            </div>
            <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, fontSize: 12 }}>
              {shortenString(localReview?.comment || item.comment, size === 'small' ? 40 : 70)}
            </span>
          </React.Fragment>
        </CenteredCardColumn>
        <CenteredCardColumn span={size === 'small' ? 5 : 4}>
          <React.Fragment>
            <div style={{ width: 100 }}>{renderCardStatus()}</div>
          </React.Fragment>
        </CenteredCardColumn>
        <CenteredCardColumn span={size === 'small' ? 5 : 4}>
          <Rate
            style={{ fontSize: size === 'small' ? 15 : 18 }}
            defaultValue={0}
            disabled
            value={RateConversion[item.starRating]}
          />
        </CenteredCardColumn>

        {size !== 'small' && (
          <CenteredCardColumn span={3}>
            <div style={{ width: 100 }}>{renderInquiryStatus()}</div>
          </CenteredCardColumn>
        )}
      </Row>
    </Card>
  );
};

export default ReviewCard;
