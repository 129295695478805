/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Input, Popconfirm, Popover, Skeleton } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import LoadableContainer from '@components/LoadableContainer';
import { DeleteOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { StudioPhoto, StudioPhotoSource } from '@core/models';
import { useAppDispatch, useAuthState, useStudioState } from '@core/configureStore';
import {
  deleteStudioPhotos,
  fetchGalleryImages,
  updateStudioPhoto,
} from '@core/features/studio/thunks';
import { formatRelativeUtcDate } from '@utils/date.util';
import AnimatedComponent from '@components/AnimatedComponent';
import { socialStudioApiService } from '@services/service-register';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { NewlineText } from '@components/index';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {
  studioPhotoId: number;
  onClose: () => void;
};

interface DescriptionItemProps {
  title: string;
  content: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
    <p style={{ fontWeight: 'bold', marginRight: 10 }}>{title}:</p>
    {content}
  </div>
);

const StudioImageItemDetail = ({ studioPhotoId, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { isPhotoUpdating } = useStudioState();
  const [studioPhoto, setStudioPhoto] = useState<StudioPhoto | null>(null);
  const [description, setDescription] = useState<string>(studioPhoto?.description || '');
  const [tags, setTags] = useState<string[]>(studioPhoto?.labels || []);
  const [loading, setLoading] = useState(false);

  const handleUpdateStudioPhoto = async () => {
    if (!user || !studioPhoto) return;
    await dispatch(
      updateStudioPhoto({
        mediaId: studioPhoto.media_id,
        labels: tags,
        description,
      })
    );
  };

  const handleRemoveItem = async () => {
    if (!user || !studioPhoto) return;
    await dispatch(deleteStudioPhotos({ studioPhotos: [studioPhoto] }));
    await dispatch(fetchGalleryImages());
    onClose();
  };

  const itemUpdated = useMemo(
    () =>
      studioPhoto?.description !== description ||
      studioPhoto?.labels.length !== tags.length ||
      studioPhoto?.labels.some(label => tags.some(tag => tag !== label)),
    [studioPhoto, description, tags]
  );

  useEffect(() => {
    const init = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const _studioPhoto = await socialStudioApiService.getStudioPhotoById(studioPhotoId);
        setStudioPhoto(_studioPhoto);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    init();
  }, [studioPhotoId, user]);

  useEffect(() => {
    setDescription(studioPhoto?.description || '');
    setTags(studioPhoto?.labels || []);
  }, [studioPhoto]);

  const PreviewGeneratedContent = () => {
    const [isPreviewLoading, setIsPreviewLoading] = useState(true);
    const [content, setContent] = useState('');
    useEffect(() => {
      const init = async () => {
        if (!studioPhoto) return;
        if (!user?.selected_location) return;
        setIsPreviewLoading(true);
        const content = await socialStudioApiService.generateSocialPostContent({
          location: user.selected_location,
          imageDescription: description,
          imageSourceIfExist: studioPhoto.source,
        });
        setContent(content);
        setIsPreviewLoading(false);
      };
      init();
    }, [description, studioPhoto]);
    return (
      <div style={{ width: 300 }}>
        {isPreviewLoading ? (
          <Skeleton />
        ) : (
          <p style={{ marginTop: 10, fontSize: 13, padding: '20px 20px' }}>
            <NewlineText text={content || ''} />
          </p>
        )}
      </div>
    );
  };

  return (
    <AnimatedComponent.OpacityFadeInDiv delay={300}>
      <LoadableContainer isLoading={loading} loadComponent={<LoadingOutlined />}>
        <React.Fragment>
          <div style={{ paddingBottom: 50 }}>
            <div
              style={{
                backgroundImage: `url("${studioPhoto?.url}")`,
                height: 300,
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                padding: '10px 20px',
              }}></div>
            {itemUpdated && (
              <Button
                type="primary"
                loading={isPhotoUpdating}
                className="success-btn"
                style={{ width: '100%', marginTop: 20 }}
                onClick={handleUpdateStudioPhoto}>
                <SaveOutlined />
                Update
              </Button>
            )}
            <Divider />
            <LoadableContainer loadComponent={<></>} isLoading={!studioPhoto}>
              <React.Fragment>
                {studioPhoto?.source === StudioPhotoSource.SquareProductPhoto ? (
                  <div style={{ marginBottom: 20 }}>
                    <h4>Product Description</h4>
                    <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                      Describe your Square product to help Amazely generate better posts
                    </p>
                  </div>
                ) : (
                  <div style={{ marginBottom: 20 }}>
                    <h4>Annotation</h4>
                    <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                      Help Amazely generate better posts by annotating the photo.
                    </p>
                  </div>
                )}
                <Input.TextArea
                  value={description || ''}
                  placeholder="Add annotation"
                  onChange={e => setDescription(e.target.value)}
                />
                <Popover trigger={'click'} content={<PreviewGeneratedContent />}>
                  <Button style={{ width: '100%', marginTop: 10 }}>
                    <SaveOutlined />
                    Preview content written by Amazely
                  </Button>
                </Popover>
                <Divider />
                <DescriptionItem
                  title="Uploaded at"
                  content={<div>{formatRelativeUtcDate(studioPhoto?.updated_date as any)}</div>}
                />
                {studioPhoto?.source === StudioPhotoSource.SquareProductPhoto && (
                  <DescriptionItem
                    title="Photo Source"
                    content={
                      <div style={{ ...MIDDLE_STYLE }}>
                        <h4 style={{ marginRight: 5, fontWeight: 'normal' }}>Square</h4>
                        <img alt="Square logo" src="square-inc.png" width={30} height={30} />
                      </div>
                    }
                  />
                )}
                <Popconfirm
                  onConfirm={handleRemoveItem}
                  title="Delete the image"
                  description="Are you sure to delete this image?"
                  okButtonProps={{
                    size: 'middle',
                  }}
                  cancelButtonProps={{
                    size: 'middle',
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button danger style={{ width: '100%', marginTop: 10 }}>
                    <DeleteOutlined /> Delete
                  </Button>
                </Popconfirm>
              </React.Fragment>
            </LoadableContainer>
          </div>
        </React.Fragment>
      </LoadableContainer>
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default StudioImageItemDetail;
