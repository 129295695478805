import moment, { Moment, unitOfTime } from 'moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import { blogApiService, socialStudioApiService } from '@services/service-register';
import { Optional } from './type.util';

export const US_DATE_LAYOUT = 'MM/DD/YY';
export const DATE_FORMAT = `dddd, ${US_DATE_LAYOUT} HH:MM`;

export const MONTH_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export type DateRange<T extends Moment | Date> = {
  startDate: T;
  endDate: T;
};

export const formatDate = (date: Date) => {
  return moment(date).format(DATE_FORMAT);
};

export const formatUtcDate = (date: string, customFormat?: string): string => {
  return moment.utc(date).format(customFormat || DATE_FORMAT);
};

export const formatUtcTimestamp = (date: Optional<string>): number => {
  if (!date) return 0;
  return moment.utc(date).unix();
};

export const formatRelativeUtcDate = (date: string) => {
  return moment.utc(date).fromNow();
};

export const formatRelativeTimestampDate = (ts: number) => {
  return moment.unix(ts).fromNow();
};

export const formatTimestamp = (date: number) => {
  return moment.unix(date).format(DATE_FORMAT);
};

export const timeDiff = (date: string, unit: unitOfTime.Diff) => {
  return moment().diff(moment.utc(date), unit);
};

export const formatUtcDateWithTimeDiff = (date: string, amount: number, unit: unitOfTime.Diff) => {
  return moment.utc(date).add(amount, unit).format(DATE_FORMAT);
};

export const delay = (miliseconds: number) => {
  return setTimeout(() => {}, miliseconds);
};

export const unixToDate = (t: number) => {
  return moment.unix(t).toDate();
};

export const unixtoString = (t: number) => {
  return moment.unix(t).toDate().toUTCString();
};

export function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * @param dayINeed start from (0 - Monday) to (6 - Sunday)
 * @returns {moment.Moment}
 */
export const getNextDayInstance = (dayINeed: number): moment.Moment => {
  const today = moment().isoWeekday();
  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(dayINeed);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment().add(1, 'weeks').isoWeekday(dayINeed);
  }
};

export const getSpecificWeekday = (day: number) => {
  return moment().day(day).format('dddd');
};

export const getWeekdays = () => {
  // Create an array of days of the week starting from Sunday
  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    daysOfWeek.push(getSpecificWeekday(i));
  }
  return daysOfWeek;
};

// Function to group object array by month
export function groupObjectArrayByMonth<T>(itemWithDateList: T[], pointedField: keyof T) {
  const groupedPosts: Record<string, T[]> = {};

  itemWithDateList.forEach(post => {
    if (post[pointedField]) {
      const monthKey = moment(post[pointedField] as any).format(MONTH_KEY_DATE_FORMAT);
      if (!groupedPosts[monthKey]) {
        groupedPosts[monthKey] = [];
      }
      groupedPosts[monthKey].push(post);
    }
  });

  return groupedPosts;
}

export const formatTimeNumber = (num: number) => {
  if (num >= 10) return num.toString();
  return `0${num}`;
};

export const getMonthKey = (monthMoment: number) =>
  moment.unix(monthMoment).format(MONTH_KEY_DATE_FORMAT);

export const isDateInBetweenRange = (startDate: number, endDate: number, date: number) => {
  return startDate <= date && date <= endDate;
};

/// Provide the bounded `start date` - `end date`, if the `date` is in range
/// We return the date. Otherwise, return the `start date`
export const alignDateInRange = (startDate: number, endDate: number, date: number) => {
  // If the provided time is in the range, we take the current time
  return isDateInBetweenRange(startDate, endDate, date) ? date : startDate;
};

export const getSocialPostRangeSchedules = (startDate: Moment, months: number) => {
  const currentDate = startDate.unix();
  const now = moment().unix();
  const endDate = startDate.add(months, 'months').endOf('month').unix();
  return socialStudioApiService.getStudioSchedules(
    currentDate,
    endDate,
    alignDateInRange(currentDate, endDate, now)
  );
};

export const getBlogPostRangeSchedules = (startDate: Moment, months: number) => {
  const currentDate = startDate.unix();
  const now = moment().unix();
  const endDate = startDate.add(months, 'months').endOf('month').unix();
  return blogApiService.getBlogPostSchedules(
    currentDate,
    endDate,
    alignDateInRange(currentDate, endDate, now)
  );
};

export const isAfterDate = (utcString: string | null, months: number) =>
  moment(utcString).isAfter(moment().subtract(months, 'months'));

export const getDefaultMonthKey = () => getMonthKey(moment().unix());

export function isInYesterday(date: Date | string) {
  const inputDate = moment(date);
  const yesterday = moment().subtract(1, 'days');
  return inputDate.isSame(yesterday, 'day');
}

type WindowParameter = {
  value: moment.DurationInputArg1;
  unit: moment.unitOfTime.DurationConstructor | undefined;
};

export const isInTimeWindow = (date: Date, { value, unit }: WindowParameter) => {
  const today = moment();
  const window = moment().subtract(value, unit);
  const comparedDate = moment(date.toUTCString());
  return comparedDate.isBetween(window, today);
};

export const getDateConfigFromMoment = (m: Moment) => ({
  day: m.get('date'),
  month: m.get('month'),
  hour: m.hour(),
  minute: m.minute(),
  year: m.year(),
});

export const getMomentFromDateConfig = (dateConfig: {
  day: number;
  month: number;
  hour: number;
  minute: number;
  year: number;
}) => moment(dateConfig);
