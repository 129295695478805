import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BlogPostCreationScreen, NotFoundScreen } from '@pages/index';
import {
  blogApiService,
  facebookApiService,
  instagramApiService,
  publicRouteService,
} from '@services/service-register';
import { BlogPostModel, BlogPostStage, LocalLocation, SubscriptionCreditUsage } from '@core/models';
import { LoadableContainer, SubscriptionPackageCard } from '@components/index';
import SplashScreen from '@components/SplashScreen';
import { buildBase64DataUrl } from '@utils/string.util';
import { Optional } from '@utils/type.util';
import { MIDDLE_STYLE } from '@constants/responsive';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME } from '@constants/brand';
import { Button } from 'antd';
import { buildBlogPageUrl } from '@utils/blog.util';
import { useAppDispatch } from '@core/configureStore';
import { enqueueNotification, SnackbarItem, integrateManyToService } from '@core/features';
import { useSubscriptionDispatcher } from '@core/hooks';
import { CreditCardOutlined } from '@ant-design/icons';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

const CREDIT_GROUP_KEY: keyof SubscriptionCreditUsage = 'manual_blog_post_published';

const BlogPostApprovalCallbackScreen: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { checkPassiveTriggerActivated } = useSubscriptionDispatcher();
  const { blogPostId } = useParams<{ blogPostId: string }>();
  const [blogPost, setBlogPost] = useState<Optional<BlogPostModel>>(undefined);
  const [currentLocation, setCurrentLocation] = useState<Optional<LocalLocation>>(undefined);
  const [noSubscription, setNoSubscription] = useState(false);
  const [loading, setLoading] = useState(false);

  const RenderNoPlanActivationContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <CreditCardOutlined style={{ fontSize: 50, color: GLOBAL_THEME_COLOR.$highlight_color }} />
        <div style={{ margin: '30px 0px 20px 0px', textAlign: 'center' }}>
          <h1>Amazely Pro is not activated.</h1>
          <p>This feature requires an Amazely Pro licence.</p>
        </div>
        <SubscriptionPackageCard
          onClick={async () => {
            await publicRouteService.sendUserActivitySlackNotification(
              currentLocation?.title || 'Unknown',
              '[✅ Activate Clicked]'
            );
            window.open('https://app.amazely.co/');
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const _blogPost = await blogApiService.getBlogPostById(blogPostId);
      if (!_blogPost) return;
      _blogPost.contentBody = await blogApiService.fetchBlogContentBody(_blogPost.contentBodyUrl);
      const base64Data = await blogApiService.fetchThumbnailData(_blogPost.thumbnail);
      _blogPost.thumbnail = buildBase64DataUrl(base64Data);
      setBlogPost(_blogPost);

      if (_blogPost.location) {
        const location = _blogPost.location;
        setCurrentLocation(location);
        // Fetching the social accounts of the location
        const facebookPages = await facebookApiService.getFacebookPagesByLocationId(
          location.location_id
        );
        dispatch(
          integrateManyToService({
            platform: 'facebook_pages',
            profiles: facebookPages,
          })
        );
        const instagramProfiles = await instagramApiService.getInstagramProfilesByLocationId(
          location.location_id
        );
        dispatch(
          integrateManyToService({
            platform: 'instagram_profiles',
            profiles: instagramProfiles,
          })
        );
        // Notify admin that user has opened this page
        publicRouteService.sendUserActivitySlackNotification(
          location.title,
          'Customer open Blog post approval email'
        );
      }
      setLoading(false);
    };
    init();
  }, [blogPostId]);

  return (
    <div
      style={{
        overflow: 'auto',
        maxHeight: '100vh',
      }}>
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', padding: '20px 20px' }}>
        <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 50 }} />
        <Button href="https://app.amazely.co" type="primary">
          🎉 Access to all features
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <div style={{ maxWidth: 1400 }}>
          {noSubscription ? (
            <RenderNoPlanActivationContent />
          ) : (
            <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
              {!blogPost || !currentLocation ? (
                <NotFoundScreen />
              ) : (
                <BlogPostCreationScreen
                  currentLocation={currentLocation}
                  existingBlogPost={blogPost}
                  onUpdateErrorHandler={error => {
                    dispatch(
                      enqueueNotification({
                        name: `ERROR: ${error.message}`,
                        type: 'Error',
                      } as SnackbarItem)
                    );
                    if (!checkPassiveTriggerActivated(currentLocation, CREDIT_GROUP_KEY)) {
                      setNoSubscription(true);
                    }
                  }}
                  onBlogPostUpdated={updatedBlogPost => {
                    if (updatedBlogPost.status === BlogPostStage.Published) {
                      window.location.href = buildBlogPageUrl(
                        currentLocation?.slug,
                        updatedBlogPost.slug
                      );
                    }
                  }}
                />
              )}
            </LoadableContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogPostApprovalCallbackScreen;
