import { ActionReducerMapBuilder, AsyncThunk, CaseReducers } from '@reduxjs/toolkit';

export const getSideEffectState = <R, A>(thunk: AsyncThunk<R, A, any>) => {
  return [thunk.fulfilled.toString(), thunk.pending.toString(), thunk.rejected.toString()];
};

export type ExtraReducer<T> =
  | CaseReducers<T, any>
  | ((builder: ActionReducerMapBuilder<T>) => void)
  | undefined;
