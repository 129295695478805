import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Checkbox, Col, Empty, Row } from 'antd';
import { LoadableContainer, SocialPostListItem } from '@components/index';
import React, { useMemo } from 'react';
import { SocialPost } from '@core/models';
import { useAuthState } from '@core/configureStore';
import { useBreakpoint } from '@core/hooks';
import SocialPostMonthContainerLayout from '@components/SocialPostTab/SocialPostMonthContainerLayout';

interface Props {
  month: string;
  socialPosts: SocialPost[];
}

const SocialPostMonthListContainer: React.FC<Props> = ({ socialPosts, month }) => {
  const { isTablet } = useBreakpoint();
  const { user } = useAuthState();
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);
  return (
    <SocialPostMonthContainerLayout month={month} socialPosts={socialPosts}>
      {({
        socialPosts: currentSocialPosts,
        allChecked,
        setAllChecked,
        setSelectedSocialPost,
        selectedSocialPost,
      }) => (
        <React.Fragment>
          {currentSocialPosts.length > 0 && !isTablet && (
            <div
              style={{
                ...MIDDLE_STYLE,
                width: '100%',
                margin: '10px 0px 5px 0px',
                paddingBottom: 5,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              }}>
              {!isTablet && (
                <Checkbox
                  checked={allChecked}
                  style={{ marginRight: 20 }}
                  onClick={() => setAllChecked(!allChecked)}
                />
              )}
              <Row style={{ width: '100%' }}>
                <Col span={2} style={{ ...MIDDLE_STYLE }}>
                  <span style={{ fontSize: '12px' }}>Photo</span>
                </Col>
                <Col span={3} style={{ ...MIDDLE_STYLE }}>
                  <span style={{ fontSize: '12px' }}>Status</span>
                </Col>
                <Col span={16}>
                  <span style={{ fontSize: '12px' }}>Content</span>
                </Col>
              </Row>
            </div>
          )}
          <div style={{ height: '100%' }}>
            <LoadableContainer
              isLoading={currentSocialPosts.length === 0}
              loadComponent={
                <div style={{ ...MIDDLE_STYLE }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No social posts found</span>}
                  />
                </div>
              }>
              {currentSocialPosts.map(socialPost => (
                <React.Fragment>
                  {locationId && (
                    <React.Fragment>
                      <div
                        style={{ ...MIDDLE_STYLE }}
                        onClick={() =>
                          setSelectedSocialPost({
                            ...selectedSocialPost,
                            [socialPost.id]: !selectedSocialPost[socialPost.id.toString()],
                          })
                        }>
                        {!isTablet && (
                          <Checkbox
                            checked={selectedSocialPost[socialPost.id.toString()]}
                            style={{ marginRight: 20 }}
                          />
                        )}
                        <SocialPostListItem socialPost={socialPost} locationId={locationId} />
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </LoadableContainer>
          </div>
        </React.Fragment>
      )}
    </SocialPostMonthContainerLayout>
  );
};

export default SocialPostMonthListContainer;
