import { PhotoImageDataItem } from '@core/features';
import {
  GoogleReview,
  LocalLocation,
  SquareProductItemCatalogObject,
  SocialPost,
  SocialPostType,
  StudioPhoto,
  StudioPilotSetting,
  StudioPhotoSource,
} from '@core/models';
import axios from 'axios';

export default class SocialStudioApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/studio`;

  public async checkIfEnoughGalleryImages(): Promise<void | undefined> {
    try {
      const response = await axios.get(`${this.url}/photos/check`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getStudioSchedules(
    startDate: number,
    endDate: number,
    currentDate: number
  ): Promise<{
    showcaseSchedules: number[];
    socialPostSchedules: number[];
  }> {
    try {
      const response = await axios.get(
        `${this.url}/schedules?startDate=${startDate}&endDate=${endDate}&currentDate=${currentDate}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async removeImagesFromAlbum(studioPhotoIds: number[]): Promise<void | undefined> {
    try {
      const response = await axios.post(
        `${this.url}/photos/delete`,
        {
          studioPhotoIds,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateStudioPhoto(
    mediaId: string,
    labels: string[],
    description: string
  ): Promise<StudioPhoto | undefined> {
    try {
      const response = await axios.put(
        `${this.url}/photos/${mediaId.replaceAll('/', '%2F')}`,
        {
          labels,
          description,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationSocialPosts(): Promise<SocialPost[]> {
    try {
      const response = await axios.get(`${this.url}/posts`, {
        withCredentials: true,
      });
      return response.data || [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getStudioPhotoByMediaId(mediaId: string): Promise<StudioPhoto | null> {
    try {
      const response = await axios.get(
        `${this.url}/photos/media/${mediaId.replaceAll('/', '%2F')}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getSocialPostById(socialPostId: number): Promise<SocialPost> {
    try {
      const response = await axios.get(`${this.url}/posts/${socialPostId}`, {
        withCredentials: true,
      });
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getSocialPostByEncryptedId(encryptedSocialPostId: string): Promise<SocialPost> {
    try {
      const response = await axios.get(`${this.url}/posts/encrypted/${encryptedSocialPostId}`);
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getPostApprovalByPostId(socialPostId: string): Promise<SocialPost> {
    try {
      const response = await axios.get(`${this.url}/post-approvals/${socialPostId}`);
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async openPostApprovalByPostId(socialPostId: string) {
    try {
      const response = await axios.post(`${this.url}/post-approvals/${socialPostId}/open`, {});
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getStudioPhotoById(studioPhotoId: number): Promise<StudioPhoto> {
    try {
      const response = await axios.get(`${this.url}/photos/${studioPhotoId}`, {
        withCredentials: true,
      });
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async removeSocialPost(socialPostId: number): Promise<void> {
    try {
      await axios.delete(`${this.url}/posts/${socialPostId}`, {
        withCredentials: true,
      });
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async createSocialPost({
    scheduleLaterEnabled,
    caption,
    fileImageItems,
    scheduleTime,
    profileIds,
    type,
    topic,
  }: {
    scheduleLaterEnabled: boolean;
    caption: string;
    fileImageItems: PhotoImageDataItem[];
    scheduleTime?: number;
    profileIds: string[];
    type?: SocialPostType;
    topic?: string;
  }): Promise<SocialPost> {
    try {
      const response = await axios.post(
        `${this.url}/posts`,
        {
          scheduleLaterEnabled,
          caption,
          fileImageItems,
          scheduleTime,
          profileIds,
          type,
          topic,
        },
        {
          withCredentials: true,
        }
      );
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  public async generateShowcaseReviewContent({
    location,
    reviewerName,
  }: {
    location: LocalLocation;
    reviewerName: string;
  }): Promise<string> {
    try {
      const response = await axios.post(
        `${this.url}/posts/showcase`,
        {
          location,
          reviewerName,
        },
        {
          withCredentials: true,
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateSocialPostContent({
    location,
    imageDescription,
    topic,
    wordLimit,
    imageSourceIfExist,
  }: {
    location: LocalLocation;
    imageDescription?: string;
    topic?: string;
    wordLimit?: number;
    imageSourceIfExist?: StudioPhotoSource;
  }): Promise<string> {
    try {
      const response = await axios.post(
        `${this.url}/posts/generate`,
        {
          location,
          imageDescription,
          topic,
          wordLimit,
          imageSourceIfExist,
        },
        {
          withCredentials: true,
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /** Provide photos => generate social posts */
  public async batchGenerateSocialPosts(studioPhotos: StudioPhoto[]) {
    try {
      const response = await axios.post(
        `${this.url}/posts/batch-generate`,
        {
          studioPhotos,
        },
        {
          withCredentials: true,
        }
      );
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  /** Provide photos => generate social posts */
  public async batchGenerateShowcaseReviews(googleReviews: GoogleReview[]) {
    try {
      const response = await axios.post(
        `${this.url}/reviews/batch-generate`,
        {
          googleReviews,
        },
        {
          withCredentials: true,
        }
      );
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async batchInsertSocialPosts(
    payload: {
      caption: string;
      fileImageItems: PhotoImageDataItem[];
      scheduledAt: number;
      type: SocialPostType;
    }[]
  ) {
    try {
      const response = await axios.post(
        `${this.url}/posts/batch-insert`,
        {
          socialPostPayload: payload,
        },
        {
          withCredentials: true,
        }
      );
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async publishSocialPost(
    socialPostId: number,
    profileIds: string[]
  ): Promise<StudioPhoto | undefined> {
    try {
      const response = await axios.post(
        `${this.url}/posts/${socialPostId}/publish/`,
        {
          profileIds,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async publishSocialPostUnsafe(
    locationId: string,
    socialPostId: number,
    profileIds: string[]
  ): Promise<StudioPhoto | undefined> {
    try {
      const response = await axios.post(
        `${this.url}/${locationId}/posts/${socialPostId}/publish/`,
        {
          profileIds,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateSocialPost(
    socialPostId: number,
    date: number,
    content: string,
    topic?: string
  ): Promise<void> {
    try {
      const response = await axios.put(
        `${this.url}/posts/${socialPostId}`,
        {
          date,
          content,
          topic,
        },
        {
          withCredentials: true,
        }
      );
      return response.data || [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async applyTemplateToShowcaseReviewPost(showcaseReviewPostId: number): Promise<void> {
    try {
      const response = await axios.post(
        `${this.url}/reviews/${showcaseReviewPostId}/apply`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data || [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getStudioPhotosByLocation(): Promise<StudioPhoto[]> {
    try {
      const response = await axios.get(`${this.url}/photos`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getUnsplashPhotos(query: string) {
    try {
      const response = await axios.get(`${this.url}/photos/unsplash?query=${query}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getPexelsPhotos(query: string) {
    try {
      const response = await axios.get(`${this.url}/photos/pexels?query=${query}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLocationStudioPilot(studioPilotSetting: Partial<StudioPilotSetting>) {
    try {
      const response = await axios.put(
        `${this.url}/pilot/`,
        { ...studioPilotSetting },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async listSquareProductObjects(): Promise<SquareProductItemCatalogObject[]> {
    try {
      const response = await axios.get(`${this.url}/square/objects`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
