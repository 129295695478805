import React from 'react';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Rate } from 'antd';

const GoogleMapLocationItem = ({
  name,
  totalRating,
  rating,
  style,
  children,
}: {
  name: React.ReactNode;
  rating: number;
  totalRating: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        width: 'fit-content',
        padding: '10px 20px',
        zIndex: 10,
        borderRadius: 5,
        ...MIDDLE_STYLE,
        ...style,
      }}>
      <div>
        <div>{name}</div>
        <Rate style={{ fontSize: 15 }} defaultValue={0} disabled value={rating} /> ({totalRating})
      </div>
      {children}
    </div>
  );
};

export default GoogleMapLocationItem;
