import React from 'react';
import { LoadableContainer, SocialIconButton } from '@components/index';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import { Checkbox, Divider, Space } from 'antd';
import { useBusinessState } from '@core/configureStore';
import { GoogleLocalLocation, SocialPlatformStr } from '@core/models';
import { MIDDLE_STYLE } from '@constants/responsive';

interface Props {
  selectedAccounts: Record<string, boolean>;
  setSelectedAccounts: (selectedAccounts: Record<string, boolean>) => void;
  disabledPlatforms?: SocialPlatformStr[];
}

const GoogleBusinessProfileButtonList: React.FC<{
  googleBusinessProfiles: GoogleLocalLocation[];
  selectedAccounts: Record<string, boolean>;
  setSelectedAccounts: (selectedAccounts: Record<string, boolean>) => void;
}> = ({ googleBusinessProfiles, setSelectedAccounts, selectedAccounts }) => {
  const [selectedProfiles, notSelectedProfiles]: GoogleLocalLocation[][] = [[], []];
  for (const googleBusinessProfile of googleBusinessProfiles) {
    if (selectedAccounts[googleBusinessProfile.location_id]) {
      selectedProfiles.push(googleBusinessProfile);
    } else {
      notSelectedProfiles.push(googleBusinessProfile);
    }
  }
  return (
    <Space
      direction={'vertical'}
      style={{ padding: '10px 10px', maxHeight: 600, overflowY: 'auto' }}>
      {[...selectedProfiles, ...notSelectedProfiles].map((account, index) => (
        <React.Fragment>
          <Space
            size={'large'}
            style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '100%' }}>
            <Space style={{ ...MIDDLE_STYLE }}>
              <p style={{ margin: 0 }}>{account.title}</p>
            </Space>
            <Checkbox
              checked={selectedAccounts[account.location_id]}
              onChange={e => {
                setSelectedAccounts({
                  ...selectedAccounts,
                  [account.location_id]: e.target.checked,
                });
              }}
            />
          </Space>
          {index !== googleBusinessProfiles.length - 1 && <Divider style={{ margin: '0px 0px' }} />}
        </React.Fragment>
      ))}
    </Space>
  );
};

const GoogleBusinessButtonListContainer: React.FC<Props> = ({
  setSelectedAccounts,
  selectedAccounts,
  disabledPlatforms = [],
}) => {
  const { locations, googleLocations } = useBusinessState();
  return (
    <LoadableContainer isLoading={locations.length === 0} loadComponent={<SkeletonAvatar active />}>
      <React.Fragment>
        {!disabledPlatforms.includes('google') && (
          <SocialIconButton
            title="businesses"
            tooltipContent={
              <GoogleBusinessProfileButtonList
                googleBusinessProfiles={googleLocations}
                selectedAccounts={selectedAccounts}
                setSelectedAccounts={setSelectedAccounts}
              />
            }
            platform="google"
            connectedAccounts={
              googleLocations.filter(googleProfile => selectedAccounts[googleProfile.location_id])
                .length
            }
          />
        )}
      </React.Fragment>
    </LoadableContainer>
  );
};

export default GoogleBusinessButtonListContainer;
