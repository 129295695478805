import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { Carousel } from 'antd';
import React from 'react';
import { ReviewWidget } from '..';
import { GoogleReview } from '@core/models';

type Props = {
  reviews: GoogleReview[];
  device: 'mobile' | 'desktop';
};

const WidgetCarouselContainer = ({ reviews, device }: Props) => {
  const { isMobile } = useBreakpoint();
  const BACKGROUND_COLOR = '#fafafa';

  return (
    <div
      style={{
        backgroundColor: BACKGROUND_COLOR,
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        width: isMobile || device === 'mobile' ? '280px' : '350px',
        borderRadius: '3rem',
        padding: isMobile || device === 'mobile' ? '5px 10px 5px 10px' : '10px 20px 10px 10px',
        margin: '20px 0px 20px 0px',
      }}>
      <Carousel
        autoplay
        dots={false}
        effect="fade"
        style={{ backgroundColor: BACKGROUND_COLOR, borderRadius: '2rem' }}>
        {reviews.map(item => (
          <ReviewWidget key={item.reviewId} review={item} isUIMobile={device === 'mobile'} />
        ))}
      </Carousel>
    </div>
  );
};

export default WidgetCarouselContainer;
