import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAuthState, useModalState } from '@core/configureStore';
import { closeModal, enqueueNotification } from '@core/features';
import { Button, Divider, Input, Modal, Select, Space } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { ReviewResponderProfile, TextLength } from '@core/models';
import { heroApiService } from '@services/service-register';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { BiReset } from 'react-icons/bi';
import { useBreakpoint } from '@core/hooks';
import { TONE_OF_VOICES } from '@constants/brand';
import { ExpandableSuggestionContainer } from '@components/index';

const MAX_VISIBLE_TONE_OF_VOICES = 3;

const CustomizeAiResponderModal = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { isTablet } = useBreakpoint();
  const { customizeAiResponderModal } = useModalState();
  const selectedLocation = useMemo(() => user?.selected_location, [user]);
  const [reviewResponderProfile, setReviewResponderProfile] = useState<
    ReviewResponderProfile | undefined
  >(undefined);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'customizeAiResponderModal',
      })
    );
  };

  const handleUpdateReviewResponder = (field: keyof ReviewResponderProfile, value: any) => {
    setReviewResponderProfile({
      ...reviewResponderProfile,
      [field]: value,
    } as any);
    setUpdated(true);
  };

  const handleDiscard = () => {
    if (selectedLocation)
      setReviewResponderProfile({
        ...selectedLocation?.review_responder_profile,
      });
    setUpdated(false);
  };

  const handleSaveChanges = async () => {
    if (!reviewResponderProfile) return;
    try {
      setUpdating(true);
      await heroApiService.updateLocationReviewResponder(reviewResponderProfile);
      await dispatch(fetchSelectedLocation());
      setUpdated(false);
      dispatch(
        enqueueNotification({
          name: 'Successfully update AI responder profile',
          description: 'Successfully update AI responder profile',
          type: 'Success',
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update AI responder profile',
          description: 'Failed to update AI responder profile',
          type: 'Error',
        })
      );
    }
    setUpdating(false);
  };

  useEffect(() => {
    setReviewResponderProfile(selectedLocation?.review_responder_profile);
  }, [selectedLocation, customizeAiResponderModal]);

  return (
    <Modal
      width={isTablet ? '100%' : 700}
      title={'Customize AI Responder'}
      open={customizeAiResponderModal}
      footer={
        updated
          ? [
              <Button onClick={handleModalClose}>Cancel</Button>,
              <Button loading={updating} danger onClick={handleDiscard}>
                <BiReset /> Discard
              </Button>,
              <Button
                loading={updating}
                type={'primary'}
                className={'success-btn'}
                onClick={handleSaveChanges}>
                Save changes
              </Button>,
            ]
          : [
              <Button onClick={handleModalClose}>Cancel</Button>,
              <Button loading={updating} danger onClick={handleDiscard}>
                <BiReset /> Discard
              </Button>,
            ]
      }
      onCancel={handleModalClose}
      closable>
      <Divider />
      {reviewResponderProfile && (
        <Space size={'large'} direction={'vertical'} style={{ width: '100%' }}>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Tone of voice <br />
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Select a voice of your AI review responder
              </span>
            </div>
            <Input
              onChange={e => handleUpdateReviewResponder('tone_of_voice', e.target.value)}
              placeholder={'Enter AI tone of voice'}
              value={reviewResponderProfile.tone_of_voice}
            />
          </Space>
          <div
            style={{
              backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
              padding: '10px 10px',
              borderRadius: 10,
            }}>
            <ExpandableSuggestionContainer
              limit={MAX_VISIBLE_TONE_OF_VOICES}
              topics={TONE_OF_VOICES.map(toneOfVoice => toneOfVoice.tone)}
              onTopicClicked={tone_of_voice =>
                handleUpdateReviewResponder('tone_of_voice', tone_of_voice)
              }
            />
          </div>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Length of generated response <br />{' '}
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Description for the length of the response
              </span>
            </div>
            <Select
              style={{ width: 150 }}
              value={reviewResponderProfile.response_length}
              onChange={value => handleUpdateReviewResponder('response_length', value)}
              options={[
                {
                  value: TextLength.VeryShort,
                  label: 'Very Short',
                },
                {
                  value: TextLength.Short,
                  label: 'Short',
                },
                {
                  value: TextLength.Medium,
                  label: 'Medium',
                },
                {
                  value: TextLength.Long,
                  label: 'Long',
                },
              ]}></Select>
          </Space>
          <Divider />
        </Space>
      )}
    </Modal>
  );
};

export default CustomizeAiResponderModal;
