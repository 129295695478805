import { RootState } from '@core/configureStore';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';

export const activateAutopilot = createAsyncThunk(
  'business/activateAutopilot',
  async (_, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('No user found');
      const locationId = user.selected_location.location_id;
      const result = await heroApiService.updateAutoPilot(
        locationId,
        true,
        user.selected_location.auto_request_activated
      );
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to activate auto pilot',
          description: 'There was error fetching locations. Please refresh the page',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(activateAutopilot);

export const activateAutopilotReducer = {
  [fulfilled]: (state, action) => {
    state.isAutopilotActivating = false;
  },
  [rejected]: (state, action) => {
    state.isAutopilotActivating = false;
  },
  [pending]: (state, action) => {
    state.isAutopilotActivating = true;
  },
} as ExtraReducer<LocationReducerProps>;
