import React, { useMemo } from 'react';
import { Button, Form, Space } from 'antd';
import { useLocation } from 'react-router';
import './index.css';
import { BRAND_LOGO, BRAND_NAME, JUMBOTRON } from '@constants/brand';
import Icon from '@ant-design/icons/lib/components/Icon';
import { CreditCardFilled, FileFilled, MailFilled, MessageFilled } from '@ant-design/icons';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { getAmazelyGoogleUrl } from '@constants/auth';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

const LoginForm: React.FC = () => {
  const { isTablet } = useBreakpoint();
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
  };

  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || '/';
  const GOOGLE_OAUTH_REDIRECT = process.env.REACT_APP_AMAZELY_GOOGLE_OAUTH_REDIRECT as string;

  const Style = useMemo(
    () => ({
      splitSection: {
        width: isTablet ? '100vw' : '50vw',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        height: !isTablet ? '400px' : '100vh',
      },
    }),
    [isTablet]
  );

  return (
    <div>
      <div
        style={{
          width: isTablet ? '100vw' : '1000px',
          backgroundColor: GLOBAL_THEME_COLOR.$background_color,
          justifyContent: 'space-between',
          display: 'flex',
          borderRadius: isTablet ? 0 : '20px',
          overflow: 'hidden',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        }}>
        {!isTablet && (
          <div style={Style.splitSection}>
            <img
              src="/jumbotron/builder-background-purple.webp"
              alt="banner"
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
            <div
              style={{
                position: 'absolute',
                zIndex: 1,
                color: 'white',
              }}>
              <h1>Get started</h1>
              <p>
                <CreditCardFilled style={{ marginRight: 20 }} /> No credit card required
              </p>
              <p>
                <MessageFilled style={{ marginRight: 20 }} /> In-app chat
              </p>
              <p>
                <MailFilled style={{ marginRight: 20 }} /> team@amazely.co
              </p>
              <p>
                <FileFilled style={{ marginRight: 20 }} /> User guides
              </p>
            </div>
          </div>
        )}
        <div style={Style.splitSection}>
          <div>
            <Space direction="vertical" style={{ flexDirection: 'column', ...MIDDLE_STYLE }}>
              <div
                style={{ margin: '10px 0px 20px 0px', justifyContent: 'center', display: 'flex' }}>
                <img src={BRAND_LOGO} alt={BRAND_NAME} style={{ width: 100 }} />
              </div>
              <h2>{JUMBOTRON.LOGIN.title}</h2>
              <p>Grow your reviews and online presence at lightning speed.</p>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}>
                <Form.Item>
                  <Button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '14px',
                    }}
                    htmlType="submit"
                    className="login-form-button"
                    shape="round"
                    icon={
                      <Icon
                        component={() => (
                          <img
                            alt="google-icon"
                            src="/google.png"
                            style={{
                              width: 18,
                              height: 18,
                            }}
                          />
                        )}
                      />
                    }
                    href={getAmazelyGoogleUrl(from, GOOGLE_OAUTH_REDIRECT)}>
                    Sign in with Google
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        <img src="/gdpr.png" height={80} alt="GDPR" />
      </div>
    </div>
  );
};

export default LoginForm;
