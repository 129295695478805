import { Optional } from '@utils/type.util';
import { LocalLocation } from '@core/models/user';

export interface ReviewResponse {
  reviews: GoogleReview[];
  averageRating: number;
  totalReviewCount: number;
  nextPageToken?: string;
}

export interface GoogleReview {
  name: string;
  reviewId: string;
  reviewer: Reviewer;
  starRating: StarRating;
  comment: string;
  createTime: string;
  updateTime: string;
  reviewReply: Optional<ReviewReply>;
}

export interface Reviewer {
  profilePhotoUrl: string;
  displayName: string;
  emailAddress?: string;
  isAnonymous: boolean;
}

export enum StarRating {
  STAR_RATING_UNSPECIFIED = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export interface ReviewReply {
  comment: string;
  updateTime: string;
}

export type Review = {
  id: number;
  location_id: string;
  review_id: string;
  reviewer: Reviewer;
  rating: StarRating;
  comment: string;
  created_date: string;
  updated_date: string;
  accessed_at: string;

  is_responded: boolean;
  generated_response: string;
  generated_times: number;
  responded_date: string;

  // UI variable only
  response?: string;
};

export type PartialShowcasedReview = Pick<Review, 'reviewer' | 'comment' | 'rating'>;
export type ReviewTemplatePayload = {
  backgroundImageUrl?: string;
  backgroundColor?: string;
  review: Optional<PartialShowcasedReview>;
  location: LocalLocation | undefined;
};

export interface EmbeddedWallOfLoveSetting {
  cardColor: string;
  cardTitleColor: string;
  maxContainerSize: number;
  minStarRating: number;
  hideNodeCommentReviews: boolean;
  maxReviews: number;
  maxReviewsMobile: number;
}

export interface ReviewSetting {
  id: string;
  wol_embedded_setting: EmbeddedWallOfLoveSetting;
  watermark_visible: boolean;
}
