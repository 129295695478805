import { RootState } from '@core/configureStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { addReviews, setStartDate } from '../store';
import { ReviewReducerProps } from '../type';

export const fetchReviewsSilent = createAsyncThunk(
  'review/fetchReviewsSilent',
  async (_, thunkApi) => {
    const {
      auth: { user },
    } = thunkApi.getState() as RootState;
    if (!user) throw new Error('No user found');
    const reviewInfo = await heroApiService.getLocationAllGoogleReviews(
      user.selected_location.location_id,
      reviews => {
        thunkApi.dispatch(addReviews(reviews));
      }
    );
    const reviews = reviewInfo.reviews;
    thunkApi.dispatch(setStartDate(reviews[reviews.length - 1]?.createTime));
    return reviewInfo;
  }
);

const [fulfilled, _, rejected] = getSideEffectState(fetchReviewsSilent);

export const fetchReviewsSilentReducer = {
  [fulfilled]: (state, action) => {
    // state.reviewResponse = action.payload;
  },
  [rejected]: (state, action) => {
    state.error = '';
    state.reviewResponse = { reviews: [], averageRating: 0, totalReviewCount: 0 };
  },
} as ExtraReducer<ReviewReducerProps>;
