/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { SocialPlatformStr } from '@core/models';
import { Button, Popover } from 'antd';
import { SocialIcon } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {
  onClick?: any;
  platform: SocialPlatformStr;
  connectedAccounts: number;
  title?: string;
  tooltipContent?: React.ReactNode;
};

const SocialIconButton = ({
  onClick,
  platform,
  tooltipContent,
  title = 'accounts',
  connectedAccounts,
}: Props) => {
  return (
    <Popover placement="bottom" content={tooltipContent}>
      <Button onClick={onClick} style={{ ...MIDDLE_STYLE }}>
        <SocialIcon platform={platform} />{' '}
        <span style={{ marginLeft: 10 }}>
          {connectedAccounts} {title}
        </span>
      </Button>
    </Popover>
  );
};

export default SocialIconButton;
