import { CheckCircleFilled, CheckOutlined } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useUserOnboarding } from '@core/hooks';
import { OnboardingStage } from '@core/models';
import { Button } from 'antd';
import React from 'react';

type Props = {
  stage: OnboardingStage;
};

const CompleteSection = () => {
  return (
    <div style={{ ...MIDDLE_STYLE, color: GLOBAL_THEME_COLOR.$success_color }}>
      <h3 style={{ color: GLOBAL_THEME_COLOR.$success_color }}>Completed</h3>
      <CheckCircleFilled style={{ margin: 0, marginLeft: 10 }} />
    </div>
  );
};

const OnboardingMarkCompeteButton = ({ stage }: Props) => {
  const { handleMarkAsComplete, stageChecked } = useUserOnboarding();
  return (
    <React.Fragment>
      {stageChecked(stage) ? (
        <CompleteSection />
      ) : (
        <Button
          onClick={() => handleMarkAsComplete([stage])}
          className="success-btn"
          type="primary"
          style={{ ...MIDDLE_STYLE }}>
          Mark as complete <CheckOutlined style={{ marginLeft: 10, marginRight: 0 }} />
        </Button>
      )}
    </React.Fragment>
  );
};

export default OnboardingMarkCompeteButton;
