import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ExtraReducer,
  convertBackgroundEventToBlogPost,
  getSideEffectState,
  maybeEmptyArray,
} from '@utils/.';
import { BlogReducerProps } from '../type';
import { backgroundEventApiService, blogApiService } from '@services/service-register';
import { BlogPostStage } from '@core/models';

export const fetchLocationBlogPostsSilent = createAsyncThunk(
  'blog/fetchLocationBlogPostsSilent',
  async (_, thunkApi) => {
    try {
      const backgroundEvents = await backgroundEventApiService.getBlogGenerateEvents();
      const blogPosts = await blogApiService.getLocationBlogPosts();
      return [
        ...maybeEmptyArray(blogPosts).filter(
          blogPost => blogPost.status !== BlogPostStage.ApprovalRequested
        ),
        ...backgroundEvents.map(convertBackgroundEventToBlogPost),
      ];
    } catch (error: any) {
      return undefined;
    }
  }
);

const [fulfilled, _, rejected] = getSideEffectState(fetchLocationBlogPostsSilent);

export const fetchLocationBlogPostsSilentReducer = {
  [fulfilled]: (state, action) => {
    state.blogPosts = action.payload;
  },
  [rejected]: (state, action) => {
    state.blogPosts = [];
  },
} as ExtraReducer<BlogReducerProps>;
