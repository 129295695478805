import { Button, Popover } from 'antd';
import React from 'react';
import LanguageLocales from './locales.json';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { GlobalOutlined } from '@ant-design/icons';

type Props = {
  selectedLanguage: string;
  setSelectedLanguage: any;
  handleUpdated: any;
};

const CountryLanguageSelector = ({
  selectedLanguage,
  setSelectedLanguage,
  handleUpdated,
}: Props) => {
  const PrimaryDialects: any = LanguageLocales['primary-dialects'];
  const LanguageNames: any = LanguageLocales['language-names'];

  const handleSelect = (dialect: keyof (typeof LanguageLocales)['primary-dialects']) => {
    setSelectedLanguage(dialect);
    handleUpdated();
  };

  return (
    <div>
      <Popover
        content={
          <div style={{ maxHeight: 300, overflow: 'scroll' }}>
            {Object.keys(PrimaryDialects)
              .filter(dialect => LanguageNames[PrimaryDialects[dialect]]?.[1])
              .map(dialect => (
                <div
                  className="hover-to-highlight"
                  style={{
                    cursor: 'pointer',
                    padding: '5px 0px 5px 10px',
                    backgroundColor:
                      dialect === selectedLanguage
                        ? GLOBAL_THEME_COLOR.$highlight_color
                        : GLOBAL_THEME_COLOR.$primary_color,
                    color:
                      dialect === selectedLanguage
                        ? GLOBAL_THEME_COLOR.$text_color_contrast
                        : GLOBAL_THEME_COLOR.$text_color,
                  }}
                  onClick={() => handleSelect(dialect as any)}>
                  {LanguageNames[PrimaryDialects[dialect]]?.[1]}
                </div>
              ))}
          </div>
        }>
        <Button type="default">
          <GlobalOutlined />
          {LanguageNames[PrimaryDialects[selectedLanguage]]?.[1]}
        </Button>
      </Popover>
    </div>
  );
};

export default CountryLanguageSelector;
