/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { RequestEmailSMSTable, ReviewRequestDrawer } from '../components';
import { useAppDispatch, useReviewRequestState } from '../core';
import { fetchReviewRequests } from '../core/features/review-request/thunks';

type Props = {};

const RequestEmailSMSScreen = (props: Props) => {
  const { isMutating } = useReviewRequestState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchReviewRequests());
    };
    init();
  }, [isMutating]);

  return (
    <div style={{ paddingBottom: 100 }}>
      <RequestEmailSMSTable />
      <ReviewRequestDrawer />
    </div>
  );
};

export default RequestEmailSMSScreen;
