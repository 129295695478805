import { StarFilled } from '@ant-design/icons';
import React from 'react';

type Props = {
  rating: number;
  style?: React.CSSProperties;
};

const ReviewStarsContainer = ({ rating, style }: Props) => {
  return (
    <div style={{ ...style, textAlign: 'end' }}>
      {new Array(rating).fill('⭐').map((_, index) => {
        return (
          <span key={index}>
            <StarFilled style={{ color: 'gold' }} />
          </span>
        );
      })}
    </div>
  );
};

export default ReviewStarsContainer;
