import React from 'react';
import CreateSocialPostContainer from '@components/CreateSocialPostContainer';
import { SocialPostType } from '@core/models';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

type Props = {};

const SocialPostCreationScreen = (props: Props) => {
  return (
    <div
      style={{
        height: 'fit-content',
        backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
      }}>
      <CreateSocialPostContainer type={SocialPostType.Default} />
    </div>
  );
};

export default SocialPostCreationScreen;
