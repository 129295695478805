import { ArrowRightOutlined, CheckCircleFilled } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { SnackbarItem, closeModal, enqueueNotification } from '@core/features';
import { subscriptionApiService } from '@services/service-register';
import { SubscriptionCancellationReason } from '@services/subscription-api.service';
import { Button, Checkbox, Modal, Select, Space } from 'antd';
import { delay } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IoPricetag } from 'react-icons/io5';

enum CancellationStage {
  Offer = 'Offer',
  Confirmation = 'Confirmation',
}

const CancellationReasonText: Record<SubscriptionCancellationReason, string> = {
  customer_service: 'Customer Service',
  low_quality: 'Low Quality',
  missing_features: 'Missing Feature',
  switched_service: 'Switched Service',
  too_complex: 'Too Complex',
  unused: 'Unused',
  too_expensive: 'Too Expensive',
  other: 'Other',
};

const SubscriptionCancellationModal = () => {
  const dispatch = useAppDispatch();
  const { subscriptionCancellationModal } = useModalState();
  const [stage, setStage] = useState<CancellationStage>(CancellationStage.Confirmation);
  const [acknowledged, setAcknowledged] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [applyingOffer, setApplyingOffer] = useState(false);
  const [cancellationReason, setCancellationReason] = useState<
    SubscriptionCancellationReason | undefined
  >(undefined);
  const [applyOfferSuccess, setApplyOfferSuccess] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      if (!cancellationReason) throw new Error('No reason selected');
      setCancelling(true);
      await subscriptionApiService.cancelSubscription(cancellationReason);
      dispatch(
        enqueueNotification({
          name: `Your subscription has been cancelled.`,
          type: 'Success',
        } as SnackbarItem)
      );
    } catch (error: any) {
      dispatch(
        enqueueNotification({
          name: error.message || 'Failed to cancel subscription',
          type: 'Error',
        } as SnackbarItem)
      );
    }
    setCancelling(false);
  };

  const handleApplyOffer = async () => {
    try {
      setApplyingOffer(true);
      await subscriptionApiService.applySubscriptionOffer();
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to apply offer',
          type: 'Error',
        } as SnackbarItem)
      );
    }
    setApplyingOffer(false);
    setApplyOfferSuccess(true);
    delay(() => setApplyOfferSuccess(false), 1000);
  };

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'subscriptionCancellationModal',
      })
    );
  };

  useEffect(() => {
    setAcknowledged(false);
    setApplyOfferSuccess(false);
    setStage(CancellationStage.Confirmation);
  }, [subscriptionCancellationModal]);

  return (
    <Modal
      title={'Subscription Cancellation'}
      open={subscriptionCancellationModal}
      footer={[]}
      onCancel={handleModalClose}
      closable>
      <Space direction="vertical">
        {stage === CancellationStage.Confirmation && (
          <p style={{ margin: '10px 0px 20px 0px' }}>
            Before you cancel, please confirm and acknowledge:
            <div
              style={{
                margin: '10px 0px',
                padding: '50px 30px',
                borderRadius: 10,
                backgroundColor: GLOBAL_THEME_COLOR.$dark_primary_color,
              }}>
              <ul>
                <li>
                  Your blog site will be{' '}
                  <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>
                    deleted immediately.
                  </span>
                </li>
                <li>
                  Your SEO contents will be{' '}
                  <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>
                    deleted immediately.
                  </span>
                </li>
                <li>
                  Negative review defender will{' '}
                  <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>
                    no longer be active.
                  </span>
                </li>
                <li>
                  {' '}
                  Review collections will{' '}
                  <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>stop working</span>
                </li>
                <li>
                  {' '}
                  Automatic review response will{' '}
                  <span style={{ color: GLOBAL_THEME_COLOR.$highlight_color }}>stop working</span>
                </li>
              </ul>
            </div>
            <Checkbox
              onChange={({ target }) => setAcknowledged(target.checked)}
              style={{ marginRight: 10 }}
            />{' '}
            I have read the information above and want to proceed with cancelling my subscription.
            <Space direction="vertical" style={{ marginTop: 20, width: '100%' }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Select the cancellation reason..."
                onChange={key => setCancellationReason(key)}
                value={cancellationReason}
                options={Object.keys(CancellationReasonText).map(reasonKey => ({
                  label: (CancellationReasonText as any)[reasonKey],
                  value: reasonKey,
                }))}></Select>
              <Button
                disabled={!acknowledged || !cancellationReason}
                type="primary"
                loading={cancelling}
                onClick={() => {
                  setCancelling(true);
                  delay(() => {
                    setStage(CancellationStage.Offer);
                    setCancelling(false);
                  }, 3000);
                }}
                style={{ ...MIDDLE_STYLE, width: '100%' }}>
                Cancel subscription
              </Button>
              {cancelling && (
                <Button style={{ width: '100%' }} onClick={handleModalClose}>
                  Continue using Amazely Pro
                </Button>
              )}
            </Space>
          </p>
        )}
        {stage === CancellationStage.Offer && (
          <p style={{ margin: '10px 0px 20px 0px' }}>
            Before you go.
            <br />
            We know that Amazely can make a significant impact on your business, just like how other
            1200+ businesses have used Amazely to grow. On that note, we want to offer you a
            discount so you can continue to benefit from the full product features. Get{' '}
            <b>40% off your current subscription plan</b> if you decide to continue within the next
            7 days.
            <div
              style={{
                margin: '10px 0px',
                padding: '50px 30px',
                borderRadius: 10,
                backgroundColor: GLOBAL_THEME_COLOR.$dark_primary_color,
                ...MIDDLE_STYLE,
              }}>
              <Space size={20}>
                <h1
                  className="airbnb-font"
                  style={{
                    fontSize: 26,
                    textDecoration: 'line-through',
                    color: GLOBAL_THEME_COLOR.$dark_text_color,
                  }}>
                  49$ / month
                </h1>
                <div
                  style={{
                    backgroundColor: GLOBAL_THEME_COLOR.$success_color,
                    width: 'fit-content',
                    color: GLOBAL_THEME_COLOR.$text_color_contrast,
                    fontSize: 'larger',
                    padding: '5px 10px',
                    borderRadius: 10,
                  }}>
                  <h1 className="airbnb-font">29$ / month</h1>
                </div>
              </Space>
            </div>
            It’s our way of saying thank you for being with us, and we’d love for you to continue
            enjoying all the perks at a reduced rate.
            <Space direction="vertical" style={{ marginTop: 20, width: '100%' }}>
              <Button
                loading={applyingOffer}
                onClick={handleApplyOffer}
                type="primary"
                size="large"
                style={{ ...MIDDLE_STYLE, width: '100%' }}>
                {applyOfferSuccess ? (
                  <React.Fragment>
                    <CheckCircleFilled style={{ color: GLOBAL_THEME_COLOR.$success_color }} />{' '}
                    Request has been sent. This might take a few days.
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <IoPricetag /> Keep your subscription and save 40%
                  </React.Fragment>
                )}
              </Button>
              <Button
                loading={cancelling}
                onClick={handleCancelSubscription}
                style={{ ...MIDDLE_STYLE, width: '100%' }}>
                Cancel anyway <ArrowRightOutlined style={{ marginLeft: 10 }} />
              </Button>
            </Space>
          </p>
        )}
      </Space>
    </Modal>
  );
};

export default SubscriptionCancellationModal;
