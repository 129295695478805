import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { Card } from 'antd';
import React from 'react';
import { AppIntegrationStatusBadge, CardSectionHeader } from '..';
import { AppIntegrationKey } from '@core/features';

export type AppIntegration = {
  appId: AppIntegrationKey;
  icon: React.ReactNode;
  title: string;
  description: string;
};

type Props = {
  app: AppIntegration;
  onClick?: any;
};

const AppIntegrationCard = ({ app, onClick }: Props) => {
  const { icon, title, description, appId } = app;
  return (
    <Card
      onClick={onClick}
      hoverable
      style={{
        maxWidth: 400,
        minWidth: 250,
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
        marginBottom: '10px',
        padding: 0,
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ height: 40, width: 40, marginBottom: 20 }}>{icon}</div>
        <AppIntegrationStatusBadge appId={appId} />
      </div>
      <div>
        <CardSectionHeader title={title} />
        <p>{description}</p>
      </div>
    </Card>
  );
};

export default AppIntegrationCard;
