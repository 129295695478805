/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useParams } from 'react-router';
import {
  FacebookPage,
  InstagramProfile,
  LocalLocation,
  SocialPost,
  SocialPostStage,
  SubscriptionCreditUsage,
} from '@core/models';
import {
  facebookApiService,
  instagramApiService,
  socialStudioApiService,
} from '@services/service-register';
import {
  CheckCircleFilled,
  CheckOutlined,
  CreditCardOutlined,
  LoadingOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Button, Divider, Skeleton, Space } from 'antd';
import SocialAccountButtonListContainer from '@components/SocialAccountButtonListContainer';
import SocialPostCard from '@components/SocialPostCard';
import LoadableContainer from '@components/LoadableContainer';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from '@core/features';
import { usePublicRouteService, useSubscriptionDispatcher } from '@core/hooks';
import { countExistentialObject } from '@utils/array.util';
import SubscriptionPackageCard from '@components/SubscriptionPackageCard';

type Props = {};

const CREDIT_GROUP_KEY: keyof SubscriptionCreditUsage = 'manual_social_post_published';

const ReviewPostApprovalCallbackScreen = (props: Props) => {
  const { checkPassiveTriggerActivated } = useSubscriptionDispatcher();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const { socialPostId } = useParams<{ socialPostId: string }>();
  const [facebookPages, setFacebookPages] = useState<FacebookPage[]>([]);
  const [loading, setLoading] = useState(true);
  const [mutating, setMutating] = useState(false);
  const publicRouteService = usePublicRouteService();
  const [instagramProfiles, setInstagramProfiles] = useState<InstagramProfile[]>([]);
  const [socialPost, setSocialPost] = useState<SocialPost | undefined>(undefined);
  const [location, setLocation] = useState<LocalLocation | undefined>(undefined);
  const [sentTo, setSentTo] = useState<Record<string, boolean>>({});

  const handleFetchSocialPost = async () => {
    const socialPost = await socialStudioApiService.getSocialPostByEncryptedId(socialPostId);
    setSocialPost(socialPost);
    return socialPost;
  };

  const handleApproveToPublish = async () => {
    if (!socialPost || !location) return;
    setMutating(true);
    try {
      if (checkPassiveTriggerActivated(location, CREDIT_GROUP_KEY)) {
        const selectedAccount = Object.keys(sentTo).filter(key => sentTo[key]);
        await socialStudioApiService.publishSocialPostUnsafe(
          location.location_id,
          socialPost.id,
          selectedAccount
        );
        dispatch(
          enqueueNotification({
            name: 'Social post is published',
            description: 'Successfully published social post',
            type: 'Success',
          })
        );
        await handleFetchSocialPost();
      } else {
        publicRouteService.sendUserActivitySlackNotification(
          location.title,
          '💳 User has not activated the PRO plan'
        );
      }
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to publish social post',
          description: 'Could not publish social post',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const RenderNoPlanActivationContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <CreditCardOutlined style={{ fontSize: 50, color: GLOBAL_THEME_COLOR.$highlight_color }} />
        <div style={{ margin: '30px 0px 20px 0px', textAlign: 'center' }}>
          <h1>Amazely Pro is not activated.</h1>
          <p>This feature requires an Amazely Pro licence.</p>
        </div>
        <SubscriptionPackageCard
          onClick={async () => {
            await publicRouteService.sendUserActivitySlackNotification(
              location?.title || 'Unknown',
              '[✅ Activate Clicked]'
            );
            window.open('https://app.amazely.co/');
          }}
        />
      </div>
    );
  };

  const PostApprovedSection: React.FC<any> = () => {
    return (
      <div>
        <Space direction="vertical" style={{ width: '100%' }} size={20}>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <h2 style={{ color: GLOBAL_THEME_COLOR.$text_color, fontSize: 17 }}>
              <CheckCircleFilled
                style={{ color: GLOBAL_THEME_COLOR.$success_color, marginRight: 10 }}
              />
              Post is shared to {socialPost?.published_accounts?.length} channels already.
            </h2>
          </div>
        </Space>
      </div>
    );
  };

  const handleOpenPostApproval = async (socialPostId: string) => {
    try {
      await socialStudioApiService.openPostApprovalByPostId(socialPostId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!socialPostId) return;
      setLoading(true);
      try {
        const socialPost = await handleFetchSocialPost();
        const socialPostId = socialPost.id.toString();
        const approvalDetail = await socialStudioApiService.getPostApprovalByPostId(socialPostId);

        if (!approvalDetail) return;
        await handleOpenPostApproval(socialPostId);

        if (socialPost.location) {
          setLocation(socialPost.location);
          // Fetching the social accounts of the location
          const facebookPages = await facebookApiService.getFacebookPagesByLocationId(
            socialPost.location.location_id
          );
          setFacebookPages(facebookPages);
          const instagramProfiles = await instagramApiService.getInstagramProfilesByLocationId(
            socialPost.location.location_id
          );
          setInstagramProfiles(instagramProfiles);
          // Selecting the fetched social accounts
          const selectedEntities: Record<string, boolean> = {};
          for (const entity of [...facebookPages, ...instagramProfiles]) {
            selectedEntities[entity.id] = true;
          }
          setSentTo(selectedEntities);

          publicRouteService.sendUserActivitySlackNotification(
            socialPost.location.title,
            'Customer open Review post approval email'
          );
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    init();
  }, [socialPostId]);

  return (
    <div
      style={{
        ...MIDDLE_STYLE,
        flexDirection: 'column',
        backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
        minHeight: '100vh',
      }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px 30px',
          borderRadius: 10,
          maxWidth: 700,
          overflow: 'auto',
          maxHeight: '90vh',
          height: isMobile ? '100vh' : 'auto',
          paddingBottom: isMobile ? 50 : 20,
          overflowY: 'scroll',
          width: '100%',
          border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          boxShadow: STRIPE_BOX_SHADOW,
        }}>
        <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
          {location && socialPost ? (
            <React.Fragment>
              {location && checkPassiveTriggerActivated(location, CREDIT_GROUP_KEY) ? (
                <LoadableContainer
                  isLoading={socialPost.status === SocialPostStage.Published}
                  loadComponent={<PostApprovedSection />}>
                  <Space direction="vertical" style={{ width: '100%' }} size={20}>
                    <div
                      style={{
                        ...MIDDLE_STYLE,
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}>
                      <h2 style={{ color: GLOBAL_THEME_COLOR.$text_color, fontSize: 17 }}>
                        <SendOutlined rotate={-30} style={{ marginRight: 10 }} /> Share Review to
                        Social
                      </h2>
                      {isMobile ? (
                        <Divider />
                      ) : (
                        <div
                          style={{
                            width: 1,
                            height: 50,
                            backgroundColor: GLOBAL_THEME_COLOR.$border_color,
                          }}></div>
                      )}
                      <div>
                        <p style={{ fontWeight: 'bold' }}>{location?.title}</p>
                        <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                          {location?.full_address}
                        </p>
                      </div>
                    </div>
                    <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                    {facebookPages.length === 0 && instagramProfiles.length === 0 ? (
                      <React.Fragment>
                        <p>
                          Oops, you have not connected your Facebook page with Amazely. Please sign
                          in and connect your Facebook pages.
                        </p>
                        <Button
                          style={{ marginTop: 10 }}
                          type="primary"
                          onClick={() => window.open('https://app.amazely.co')}>
                          Sign in to Amazely
                        </Button>
                      </React.Fragment>
                    ) : (
                      <Space
                        style={{
                          justifyContent: 'space-between',
                          width: '100%',
                          flexWrap: 'wrap',
                        }}>
                        <Button
                          loading={mutating}
                          disabled={countExistentialObject(sentTo) === 0}
                          type="primary"
                          onClick={handleApproveToPublish}>
                          <CheckOutlined /> Approve to Publish
                        </Button>
                        <SocialAccountButtonListContainer
                          selectedAccounts={sentTo}
                          setSelectedAccounts={setSentTo}
                          defaultIntegrations={{
                            facebook_pages: facebookPages,
                            instagram_profiles: instagramProfiles,
                          }}
                        />
                      </Space>
                    )}
                    <div
                      style={{
                        ...MIDDLE_STYLE,
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
                        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
                        backgroundSize: '20px 20px',
                        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                        padding: '30px 10px',
                      }}>
                      <SocialPostCard
                        locationId={location.location_id}
                        socialPost={socialPost}
                        hideOverlay
                        onClickDisabled
                      />
                    </div>
                  </Space>
                </LoadableContainer>
              ) : (
                <RenderNoPlanActivationContent />
              )}
            </React.Fragment>
          ) : (
            <div style={{ textAlign: 'center', fontSize: 30 }}>
              <LoadingOutlined style={{ fontSize: 'larger' }} />
              <h2 className="airbnb-font" style={{ fontSize: 25, marginTop: 30 }}>
                Loading social post...
              </h2>
            </div>
          )}
        </LoadableContainer>
      </div>
    </div>
  );
};

export default ReviewPostApprovalCallbackScreen;
