/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { App, Button, Card, notification, Skeleton, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { BusinessSocialPost, GoogleSmartPhoto } from '@core/models';
import { useGenerationDataStore } from '@core/hooks';
import LoadableContainer from '@components/LoadableContainer';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { formatTimestamp } from '@utils/date.util';
import { getAmazelyGoogleUrl } from '@constants/auth';
import { useLocation } from 'react-router';

export type SocialPlatformType = 'facebook' | 'instagram' | 'pinterest';

type Props = {
  socialPost: BusinessSocialPost;
  type: SocialPlatformType;
  hidePlatform?: boolean;
  style?: React.CSSProperties;
};

const GOOGLE_OAUTH_REDIRECT = process.env.REACT_APP_AMAZELY_GOOGLE_OAUTH_REDIRECT as string;

const ImageBanner = ({ item, showButton }: { item: GoogleSmartPhoto; showButton?: boolean }) => {
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || '/';
  const [api, contextHolder] = notification.useNotification();

  const notify = () => {
    api.open({
      message: 'Please sign in to setup your business first.',
    });
    window.scrollTo(0, 0);
  };
  return (
    <div
      style={{
        ...MIDDLE_STYLE,
        objectFit: 'cover',
        fontWeight: 'bold',
        position: 'relative',
        overflow: 'hidden',
      }}>
      <img width={'100%'} style={{ aspectRatio: '1/1', objectFit: 'cover' }} src={item.data} />
      {showButton && (
        <span>
          {contextHolder}
          <Button
            onClick={notify}
            size="large"
            type="primary"
            style={{ position: 'absolute', bottom: 10, right: 10, ...MIDDLE_STYLE }}>
            <EditOutlined /> Edit photo gallery
          </Button>
          <div
            style={{
              position: 'absolute',
              backgroundColor: '#575756e0',
              transform: 'rotate(45deg)',
              padding: '5px',
              left: -76,
              fontWeight: 600,
              color: 'white',
              bottom: -10,
              height: 65,
              width: 200,
              textAlign: 'center',
              fontSize: 'small',
            }}>
            Sample <br />
            only
          </div>
        </span>
      )}
    </div>
  );
};

const BusinessSocialPostCard = ({ style, socialPost, hidePlatform, type }: Props) => {
  const { businessData } = useGenerationDataStore();
  return (
    <Card
      hoverable
      className="no-padding"
      style={{
        maxWidth: 450,
        minWidth: 350,
        width: '32%',
        marginRight: 10,
        marginTop: 40,
        ...style,
      }}>
      <div style={{ padding: '25px 20px 20px 20px' }}>
        <LoadableContainer
          isLoading={socialPost.caption.length === 0}
          loadComponent={<Skeleton active />}>
          <React.Fragment>
            {!hidePlatform && (
              <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                <h3 style={{ margin: '10px 5px' }}>{businessData.name}</h3>
                <img src={`social-logo/${type}_logo.webp`} width={30} />
              </div>
            )}
            <div
              style={{
                top: -10,
                position: 'absolute',
                backgroundColor: 'grey',
                width: 'fit-content',
                color: 'white',
                padding: '5px 10px',
                borderRadius: 5,
                fontWeight: 600,
              }}>
              Scheduled for {formatTimestamp(socialPost.scheduledAt)}
            </div>
            <p style={{ marginTop: 20, marginBottom: 5 }}>{socialPost.caption || ''}</p>
          </React.Fragment>
        </LoadableContainer>
      </div>
      <ImageBanner showButton={!hidePlatform} item={socialPost.photo} />
      {!hidePlatform && (
        <Space
          size={40}
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            padding: '15px 15px',
          }}>
          <p
            style={{
              color: GLOBAL_THEME_COLOR.$dark_text_color,
              ...MIDDLE_STYLE,
              textAlign: 'center',
            }}>
            <img width={45} src={`social_emo_icon/${type}-icon.png`} />{' '}
            <span style={{ marginLeft: 10 }}>{Math.floor(Math.random() * 100) + 100} likes</span>
          </p>
          <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            {Math.floor(Math.random() * 10) + 10} comments
          </p>
        </Space>
      )}
    </Card>
  );
};

export default BusinessSocialPostCard;
