/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAuthState, useReviewState } from '@core/configureStore';
import { fetchReviews } from '@core/features/review/thunks';
import { Button, Card, Divider, Empty, Rate, Space } from 'antd';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { makeid, shortenString } from '@utils/string.util';
import {
  formatRelativeUtcDate,
  formatUtcTimestamp,
  getSocialPostRangeSchedules,
} from '@utils/date.util';
import { MIDDLE_STYLE } from '@constants/responsive';
import {
  BusinessSocialPost,
  GoogleReview,
  OnboardingStage,
  SocialPost,
  StarRating,
} from '@core/models';
import { ArrowRightOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';
import { socialStudioApiService, socialStudioStreamApiService } from '@services/service-register';
import { starRatingToNumber } from '@utils/type.util';
import { SocialPlatformType } from '@components/BusinessSocialPostCard';
import Marquee from 'react-fast-marquee';
import { addSocialPost, enqueueNotification } from '@core/features';
import AnimatedComponent from '@components/AnimatedComponent';
import {
  useAppNavigation,
  useBreakpoint,
  useSocialPostDispatcher,
  useSocialPosts,
  useStreamingTaskStore,
  useUserOnboarding,
} from '@core/hooks';
import moment from 'moment/moment';
import SyncedReviewSocialPost from '@components/SyncedReviewSocialPost';
import { MonthlyGenerateParams } from '@services/stream-api.service';

type Props = {};

enum ShowcaseSocialReviewsOnboardingStage {
  ListReviews = 0,
  SocialPostPreview = 1,
}

const ShowcaseSocialReviewsOnboardingScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { getLatestPublishedDate } = useSocialPostDispatcher();
  const { handleMarkAsComplete } = useUserOnboarding();
  const { navigate } = useAppNavigation();
  const { user } = useAuthState();
  const refArrays = useRef<(HTMLDivElement | null)[]>([]);
  const { reviewResponse, isLoading: isReviewLoading } = useReviewState();
  const { showcaseReviewSocialPosts } = useSocialPosts();
  const [loading, setLoading] = useState<boolean>(true);
  const [mutating, setMutating] = useState<boolean>(false);
  const [bestReviews, setBestReviews] = useState<GoogleReview[]>([]);
  const [showcaseSchedules, setShowcaseSchedules] = useState<number[]>([]);
  const [reviewSocialPosts, setReviewSocialPosts] = useState<
    { socialPostData: BusinessSocialPost; review: GoogleReview }[]
  >([]);
  const [stage, setStage] = useState<ShowcaseSocialReviewsOnboardingStage>(
    ShowcaseSocialReviewsOnboardingStage.ListReviews
  );
  const { addTask } = useStreamingTaskStore();

  useEffect(() => {
    const getBestReviews = async () => {
      if (isReviewLoading) return;
      const { showcaseSchedules: _showcaseSchedules } = await getSocialPostRangeSchedules(
        moment().startOf('month'),
        4
      );
      setShowcaseSchedules(_showcaseSchedules);
      const _bestReviews = reviewResponse.reviews
        .filter(review => starRatingToNumber(review.starRating) >= 4)
        .filter(review => review.comment?.length > 100)
        .slice(0, _showcaseSchedules.length);
      setBestReviews(_bestReviews);
      setLoading(false);
    };
    getBestReviews();
  }, [isReviewLoading, reviewResponse]);

  const handleBatchInsertSocialPosts = async () => {
    addTask(
      {
        id: makeid(5),
        name: 'Create showcase review posts',
        payload: {
          startDate: moment().unix(),
          endDate: moment().add(4, 'months').unix(),
          currentDate: moment().unix(),
        } as MonthlyGenerateParams,
        apiMethod: socialStudioStreamApiService.streamGenerateShowcaseReviews,
        onDecodedData(data: SocialPost) {
          dispatch(addSocialPost(data));
        },
        async onFinishedCallback() {
          dispatch(
            enqueueNotification({
              name: 'Successfully approve showcase reviews',
              type: 'Success',
            })
          );
          await handleMarkAsComplete([OnboardingStage.ShareReviewToSocial]);
        },
      },
      showcaseSchedules.length
    );
    navigate('studio');
  };

  const handleApproveSocialPosts = async () => {
    try {
      setMutating(true);
      await handleBatchInsertSocialPosts();
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to approve social posts',
          description: 'Approve social posts failed',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const handleSkipApproval = async () => {
    await handleMarkAsComplete([OnboardingStage.ShareReviewToSocial]);
    navigate('getting-started');
  };

  const handleShowcaseBestReviews = async () => {
    if (!user?.selected_location) return;
    setStage(ShowcaseSocialReviewsOnboardingStage.SocialPostPreview);
    setMutating(true);

    /** Get the latest published date of the showcase review social post **/
    const latestScheduledDate = getLatestPublishedDate(showcaseReviewSocialPosts, moment().unix());
    const { showcaseSchedules } = await getSocialPostRangeSchedules(
      moment.unix(latestScheduledDate).add(1, 'week'),
      4
    );
    const showcaseScheduleIterator = showcaseSchedules[Symbol.iterator]();

    const _reviewSocialPosts: { socialPostData: BusinessSocialPost; review: GoogleReview }[] = [];
    for (let index = 0; index < bestReviews.length; index++) {
      const review = bestReviews[index];
      const reviewerName =
        review.reviewer.displayName.split(' ')?.[0] || review.reviewer.displayName;
      const content = await socialStudioApiService.generateShowcaseReviewContent({
        location: user?.selected_location,
        reviewerName,
      });
      const data = {
        review: review,
        socialPostData: {
          caption: content,
          id: makeid(10),
          photo: undefined as any,
          scheduledAt: showcaseScheduleIterator.next().value,
        },
      };
      setReviewSocialPosts([..._reviewSocialPosts, data]);
      _reviewSocialPosts.push(data);
    }
    setMutating(false);
  };

  const handleCollectCustomerReviews = () => {
    navigate('generate-reviews');
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await dispatch(fetchReviews());
    };
    init();
  }, []);

  return (
    <div
      style={{
        padding: '0px 50px',
        backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
        paddingTop: 50,
      }}>
      <div
        style={{
          boxShadow: STRIPE_BOX_SHADOW,
          backgroundColor: GLOBAL_THEME_COLOR.$background_color,
          position: 'relative',
          padding: '30px 50px',
          border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          height: 'fit-content',
          borderRadius: 30,
        }}>
        {stage === ShowcaseSocialReviewsOnboardingStage.ListReviews && (
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <h1 className="airbnb-font" style={{ fontSize: 25, fontWeight: 'bold' }}>
                Best Reviews of {user?.selected_location?.title}
              </h1>
              {loading || mutating ? (
                <LoadingOutlined />
              ) : (
                <Space style={{ flexWrap: 'wrap', ...(isTablet ? { margin: '20px 0px' } : {}) }}>
                  <Button onClick={handleSkipApproval}>Skip this stage</Button>
                  {bestReviews.length === 0 ? (
                    <Button
                      loading={mutating}
                      style={{ ...MIDDLE_STYLE }}
                      onClick={() => navigate('studio')}
                      type="primary">
                      Setup Later
                      <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
                    </Button>
                  ) : (
                    <Button
                      loading={mutating}
                      style={{ ...MIDDLE_STYLE }}
                      onClick={handleShowcaseBestReviews}
                      type="primary">
                      ⭐️ Showcase
                    </Button>
                  )}
                </Space>
              )}
            </div>
            <p>
              Amazely cherry-pick top-notch reviews from your business to showcase on social
              channels
            </p>
            <Divider />
            <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
              <LoadableContainer
                isLoading={bestReviews.length === 0}
                loadComponent={
                  <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>No reviews found</span>}
                    />
                    <Button type={'primary'} onClick={handleCollectCustomerReviews}>
                      Collect Customer Reviews ⭐️
                    </Button>
                  </div>
                }>
                <div
                  className="card-list"
                  style={isTablet ? { columnCount: 1 } : { columnCount: 3 }}>
                  {bestReviews.map(review => (
                    <Card
                      className="no-padding card"
                      style={{
                        borderRadius: 15,
                        cursor: 'pointer',
                        fontSize: 12,
                      }}>
                      <div
                        style={{
                          padding: '15px 25px',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            ...MIDDLE_STYLE,
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                          <div style={{ ...MIDDLE_STYLE }}>
                            <img
                              referrerPolicy="no-referrer"
                              style={{ width: 40, height: 40, marginRight: 10 }}
                              src={review.reviewer.profilePhotoUrl}
                              alt={review.reviewer.displayName}
                            />
                            <div>
                              <div style={{ ...MIDDLE_STYLE, flexWrap: 'wrap' }}>
                                <h3 style={{ margin: 0 }}>{review.reviewer.displayName}</h3>
                                <p
                                  style={{
                                    margin: '0px 10px',
                                    color: GLOBAL_THEME_COLOR.$dark_text_color,
                                  }}>
                                  {formatRelativeUtcDate(review.updateTime)}
                                </p>
                              </div>
                              <Rate
                                style={{ fontSize: 15 }}
                                defaultValue={0}
                                disabled
                                value={parseInt(StarRating[review.starRating.valueOf()])}
                              />
                            </div>
                          </div>
                          <div>
                            <img src="/social-logo/google_logo.webp" alt="Google Logo" width={30} />
                          </div>
                        </div>
                        <p
                          style={{
                            color: GLOBAL_THEME_COLOR.$dark_text_color,
                            marginTop: 20,
                            width: '100%',
                          }}>
                          {shortenString(review.comment, 300)}
                        </p>
                      </div>
                    </Card>
                  ))}
                </div>
              </LoadableContainer>
            </LoadableContainer>
          </React.Fragment>
        )}
        {stage === ShowcaseSocialReviewsOnboardingStage.SocialPostPreview && (
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <h1 className="airbnb-font" style={{ fontSize: 25, fontWeight: 'bold' }}>
                Showcase Reviews on Social Channels
              </h1>
              {loading || mutating ? (
                <LoadingOutlined />
              ) : (
                <Button
                  size="large"
                  loading={mutating}
                  style={{
                    ...MIDDLE_STYLE,
                    ...(isTablet ? { width: '100%', margin: '20px 0px' } : {}),
                  }}
                  onClick={handleApproveSocialPosts}
                  type="primary">
                  Approve
                  <CheckOutlined style={{ margin: 0, marginLeft: 10 }} />
                </Button>
              )}
            </div>
            <p>Amazely crafts captivating social posts for your busines top-notch reviews</p>
            <Divider />
            {mutating && (
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 100,
                  fontFamily: 'Rubik',
                  flexDirection: 'column',
                  ...MIDDLE_STYLE,
                }}>
                <div
                  style={{
                    ...MIDDLE_STYLE,
                    border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                    flexDirection: 'column',
                    boxShadow: STRIPE_BOX_SHADOW,
                    backgroundColor: 'white',
                    borderRadius: 35,
                    padding: '20px 50px',
                  }}>
                  <div style={{ ...MIDDLE_STYLE }}>
                    <img src="jumbotron/showcase-social-reviews.webp" height={'300'} />
                  </div>
                  <h3 style={{ color: 'black', fontSize: 22, marginTop: 20 }}>
                    Crafting social posts...
                  </h3>
                </div>
              </div>
            )}
            <LoadableContainer
              isLoading={reviewSocialPosts.length === 0 && mutating}
              loadComponent={<SplashScreen />}>
              <Marquee style={{ filter: mutating ? 'blur(10px)' : 'unset' }}>
                {reviewSocialPosts.map(({ review, socialPostData }, index) => (
                  <AnimatedComponent.OpacityFadeInDiv delay={200}>
                    <SyncedReviewSocialPost
                      innerRef={(el: HTMLDivElement) => (refArrays.current[index] = el)}
                      type={
                        (['facebook', 'instagram', 'pinterest'] as SocialPlatformType[])[index % 3]
                      }
                      socialPost={socialPostData}
                      review={{
                        author_name: review.reviewer.displayName,
                        author_url: review.reviewer.profilePhotoUrl,
                        generated_response: '',
                        profile_photo_url: review.reviewer.profilePhotoUrl,
                        rating: starRatingToNumber(review.starRating),
                        text: review.comment,
                        time: formatUtcTimestamp(review.updateTime),
                      }}
                      businessName={user?.selected_location?.title || ''}
                    />
                  </AnimatedComponent.OpacityFadeInDiv>
                ))}
              </Marquee>
            </LoadableContainer>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ShowcaseSocialReviewsOnboardingScreen;
