import { create } from 'zustand';
import {
  GenerationData,
  GenerationDataStore,
  GeneratorEngineStatus,
  AutoReviewGeneratorEngineStatus,
  PlaceData,
  QrCodeGeneratorEngineStatus,
} from '../models';

export const useGenerationDataStore = create<GenerationDataStore>()(set => ({
  stageLoading: {},
  engineStatus: GeneratorEngineStatus.NotStarted,
  autoReviewEngineStatus: AutoReviewGeneratorEngineStatus.NotStarted,
  qrCodeEngineStatus: QrCodeGeneratorEngineStatus.NotStarted,
  nearbyBusinesses: [],
  businessData: {
    latitude: 0,
    longtitude: 0,
    name: '',
    location: '',
    type: '',
    blogPost: '',
    socialPosts: [],
    reviews: [],
    showcaseReviews: [],
    showcaseReviewSocialPosts: [],
  },
  setStageLoading(stage: string, loading: boolean) {
    set(state => ({
      stageLoading: {
        ...state.stageLoading,
        [stage]: loading,
      },
    }));
  },
  setEngineState(engineStatus: GeneratorEngineStatus) {
    set(() => ({
      engineStatus,
    }));
  },
  setAutoReviewEngineState(engineStatus: AutoReviewGeneratorEngineStatus) {
    set(() => ({
      autoReviewEngineStatus: engineStatus,
    }));
  },
  setQrCodeEngineState(engineStatus: QrCodeGeneratorEngineStatus) {
    set(() => ({
      qrCodeEngineStatus: engineStatus,
    }));
  },
  setNearbyBusinesses: (nearbyBusinesses: PlaceData[]) => {
    set(state => ({
      ...state,
      nearbyBusinesses,
    }));
  },
  setGenerationData: (updatedData: Partial<GenerationData>) => {
    set(generationData => ({
      ...generationData,
      businessData: {
        ...generationData.businessData,
        ...updatedData,
      },
    }));
  },
}));
