import { GENERATE_REVIEW_RESPONSES } from '@constants/pitch-business';
import { useBreakpoint, useGenerationDataStore } from '@core/hooks';
import { Skeleton } from 'antd';
import React from 'react';
import { BusinessReviewCard, LoadableContainer } from '..';

type Props = {};

const PitchAutoReviewResponseSection = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const { businessData, stageLoading } = useGenerationDataStore();
  return (
    <React.Fragment>
      <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
        <h2 className="pitch_title">AI-powered responses</h2>
        <div className="pitch_subtitle">
          Amazely drafts responses to reviews using SEO optimized keywords. Answer reviews builds
          trust and boosts your Google profile ranking.
        </div>
      </div>
      <LoadableContainer
        isLoading={businessData.reviews.length === 0 || stageLoading[GENERATE_REVIEW_RESPONSES]}
        loadComponent={<Skeleton active style={{ marginTop: 20 }} />}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding: '0px 20px',
          }}>
          {businessData.reviews.map(review => (
            <div
              style={{
                margin: isMobile ? '0px' : '10px',
                width: '100%',
                maxWidth: 475,
              }}>
              <BusinessReviewCard review={review} />
            </div>
          ))}
        </div>
      </LoadableContainer>
    </React.Fragment>
  );
};

export default PitchAutoReviewResponseSection;
