import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';

export const fetchSquareMerchant = createAsyncThunk(
  'business/fetchSquareMerchant',
  async (_, thunkApi) => {
    try {
      const merchantInfo = await heroApiService.getLocationSquareMerchantInfo();
      return merchantInfo;
    } catch (error: any) {
      return undefined;
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchSquareMerchant);

export const fetchSquareMerchantReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.integration.square = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.integration.square = undefined;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
