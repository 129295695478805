import FacebookApiService from './facebook-api.service';
import HeroApiService from './hero-api.service';
import InstagramApiService from './instagram-api.service';
import PublicRouteService from './public-route.service';
import SocialStudioApiService from './social-studio-api.service';
import MockDataService from './mock-data.service';
import SocialStudioStreamService from '@services/social-studio-stream.service';
import EmailSubscriptionService from '@services/email-subscription.service';
import ReservationApiService from '@services/reservation-api.service';
import BlogApiService from '@services/blog-api.service';
import BlogApiStreamService from '@services/blog-stream-api.service';
import BackgroundEventApiService from './background-event-api.service';
import ServerSignalService from './server-signal-service';
import ReviewApiService from './review-api-service';
import SubscriptionApiService from './subscription-api.service';

export const reviewApiService = new ReviewApiService();
export const heroApiService = new HeroApiService();
export const facebookApiService = new FacebookApiService();
export const instagramApiService = new InstagramApiService();
export const publicRouteService = new PublicRouteService();
export const mockDataService = new MockDataService();
export const socialStudioApiService = new SocialStudioApiService();
export const socialStudioStreamApiService = new SocialStudioStreamService();
export const emailSubscriptionService = new EmailSubscriptionService();
export const reservationApiService = new ReservationApiService();
export const blogApiService = new BlogApiService();
export const blogApiStreamService = new BlogApiStreamService();
export const backgroundEventApiService = new BackgroundEventApiService();
export const serverSignalService = new ServerSignalService();
export const subscriptionApiService = new SubscriptionApiService();
