import React, { useMemo, useRef, useState } from 'react';
import { BlogAddFeaturedImageButton, BlogTargetKeywordsEditor } from '..';
import TextArea from 'antd/es/input/TextArea';
import { Button, Divider } from 'antd';
import ReactQuill from 'react-quill';
import { PhotoImageDataItem, SnackbarItem, enqueueNotification } from '@core/features';
import { blogApiStreamService, socialStudioApiService } from '@services/service-register';
import { useFileUploader, useStreamApi } from '@core/hooks';
import { BlogContentGenerateParams } from '@services/blog-stream-api.service';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { BlogCreationTourIdRegistry } from '@utils/blog.util';
import { DEFAULT_UNKNOWN_ADDRESS } from '@constants/brand';
import { formatUrl } from '@utils/string.util';
import _ from 'lodash';

type EditableProps = {
  mode: 'editable';
  title: string;
  featuredImage?: (PhotoImageDataItem & { _existing: boolean }) | undefined;
  subtitle: string;
  contentBody: string;
  keywords: string[];
  setTitle: (value: string) => void;
  setFeaturedImage: (value: (PhotoImageDataItem & { _existing: boolean }) | undefined) => void;
  setSubtitle: (value: string) => void;
  setContentBody: (value: string) => void;
  setKeywords: (keywords: string[]) => void;
};

type ReadOnlyProps = {
  mode: 'readonly';
  title: string;
  featuredImage?: (PhotoImageDataItem & { _existing: boolean }) | undefined;
  subtitle: string;
  contentBody: string;
  keywords: string[];
  setTitle?: (value: string) => void;
  setFeaturedImage?: (value: (PhotoImageDataItem & { _existing: boolean }) | undefined) => void;
  setSubtitle?: (value: string) => void;
  setContentBody?: (value: string) => void;
  setKeywords?: (keywords: string[]) => void;
};

type Props = (EditableProps | ReadOnlyProps) & {
  style?: React.CSSProperties;
  existing?: boolean;
  onValueChanged?: () => void;
};

const BlogPostContentEditor = ({
  mode,
  style,
  title,
  setTitle,
  featuredImage,
  setFeaturedImage,
  subtitle,
  setSubtitle,
  contentBody,
  setContentBody,
  keywords,
  setKeywords,
  existing,
  onValueChanged,
}: Props) => {
  const dispatch = useAppDispatch();
  const { converPexelDataToPhotoImage } = useFileUploader(true);
  const bottomAnchor = useRef<HTMLDivElement | undefined>();
  const [generatingContent, setGeneratingContent] = useState(false);
  const [generated, setGenerated] = useState(false);
  const { executeStreamApi } = useStreamApi();
  const { user } = useAuthState();
  const isReadonly = useMemo(() => mode === 'readonly', [mode]);

  const metadata = useMemo(
    () =>
      `<br/><h2>About Business</h2><br/>${user?.selected_location?.title}📍 ${
        user?.selected_location.full_address
      }${
        user?.selected_location.website_url
          ? `<br/>Learn more about us at: <a href="${
              user.selected_location.website_url
            }">${formatUrl(user?.selected_location.website_url)}</b>`
          : ''
      }`,
    [user?.selected_location]
  );

  const handleGenerateBlogPost = async () => {
    const location = user?.selected_location;
    if (!location || mode === 'readonly') return;
    setGeneratingContent(true);
    setGenerated(true);

    const data = await socialStudioApiService.getPexelsPhotos(title);
    if (data?.photos?.length > 0) {
      const randomPhotoIndex = _.random(0, data.photos.length, false);
      const featuredPhotoImageData = await converPexelDataToPhotoImage(
        data.photos[randomPhotoIndex]
      );
      setFeaturedImage({
        ...featuredPhotoImageData,
        _existing: false,
      });
    }

    try {
      const streamingSubtitleChunks: string[] = [];
      const streamingContentBodyChunks: string[] = [];
      setContentBody('');
      await executeStreamApi<
        { type: 'contentBody' | 'subtitle'; value: string },
        BlogContentGenerateParams
      >({
        apiMethod: blogApiStreamService.streamGenerateBlogPostContent,
        payload: {
          title: location.title,
          primary_category: location.primary_category,
          topic: title,
          keywords: keywords,
        },
        onDecodedData: async data => {
          switch (data.type) {
            case 'subtitle':
              streamingSubtitleChunks.push(data.value);
              break;
            case 'contentBody':
              streamingContentBodyChunks.push(data.value);
              break;
          }
          setSubtitle(streamingSubtitleChunks.join(''));
          setContentBody(streamingContentBodyChunks.join(''));
          bottomAnchor.current?.scrollIntoView({ behavior: 'smooth' });
        },
        onFinishedCallback: async () => {
          setContentBody(
            location.full_address !== DEFAULT_UNKNOWN_ADDRESS
              ? streamingContentBodyChunks.concat(metadata).join('')
              : streamingContentBodyChunks.join('')
          );
          dispatch(
            enqueueNotification({
              name: `Successfully generate posts`,
              type: 'Success',
            } as SnackbarItem)
          );
        },
      });
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to generate blog content',
          type: 'Error',
        })
      );
    }
    setGeneratingContent(false);
  };

  return (
    <div style={style}>
      <BlogAddFeaturedImageButton
        mediaItems={featuredImage ? [featuredImage] : []}
        onMediaUploaded={mediaItems => {
          if (setFeaturedImage) {
            setFeaturedImage({
              ...mediaItems[0],
              _existing: false,
            });
            onValueChanged && onValueChanged();
          }
        }}
        selectPhotoDisabled={isReadonly}
      />
      <TextArea
        bordered={false}
        autoSize
        autoFocus
        rows={1}
        value={title}
        onChange={e => {
          if (setTitle && !isReadonly) {
            setTitle(e.target.value);
            onValueChanged && onValueChanged();
          }
        }}
        className={'create-blog-title-input'}
        style={{
          width: '100%',
          border: 'unset',
          fontSize: 34,
          fontWeight: '800',
          height: 'fit-content',
        }}
        placeholder={'Enter blog post title'}
      />
      <div style={{ padding: '0px 10px', marginTop: 20, width: '100%' }}>
        <BlogTargetKeywordsEditor
          keywords={keywords}
          setKeywords={value => {
            if (setKeywords) {
              setKeywords(value);
              onValueChanged && onValueChanged();
            }
          }}
          disabled={isReadonly}
        />
      </div>
      <Divider />
      {!isReadonly && (
        <Button
          id={BlogCreationTourIdRegistry.GenerateBlogContent}
          style={{ borderRadius: '20px', margin: '10px 0px 20px 0px' }}
          onClick={handleGenerateBlogPost}
          loading={generatingContent}
          disabled={!title}>
          ✨{' '}
          <span style={{ marginLeft: 10 }}>
            {generatingContent
              ? 'Generating blog content...'
              : subtitle.length > 0 || contentBody.length > 0
                ? 'Re-generate blog content'
                : 'Generate blog content'}
          </span>
        </Button>
      )}
      {title && generated && (
        <TextArea
          bordered={false}
          autoSize
          autoFocus
          rows={1}
          value={subtitle}
          disabled={!generated && !existing}
          onChange={e => {
            if (setSubtitle && !isReadonly) {
              setSubtitle(e.target.value);
              onValueChanged && onValueChanged();
            }
          }}
          className={'create-blog-title-input'}
          maxLength={200}
          style={{
            width: '100%',
            border: 'unset',
            fontSize: 17,
            height: 'fit-content',
            fontWeight: '400',
            marginBottom: 50,
            overflow: 'visible',
          }}
          placeholder={'Write a blog post subtitle here...'}
        />
      )}
      <Divider />
      <ReactQuill
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean'],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        style={{ marginTop: 20, width: '100%' }}
        placeholder={'Begin writing your post...'}
        value={contentBody}
        readOnly={(!generated && !existing) || isReadonly}
        onChange={value => {
          if (setContentBody && !isReadonly) {
            setContentBody(value);
            onValueChanged && onValueChanged();
          }
        }}
      />
      {bottomAnchor && <div ref={bottomAnchor as any}></div>}
    </div>
  );
};

export default BlogPostContentEditor;
