import { LocalLocation } from '@core/models';

export const buildBlogSiteUrl = (businessSlug: string | undefined) => {
  return process.env.NODE_ENV === 'production'
    ? `https://blogspots.co/@${businessSlug}/index.html`
    : `https://amazely-business-development.s3.amazonaws.com/@${businessSlug}/index.html`;
};

export const buildBlogPageUrl = (
  businessSlug: string | undefined,
  blogPostSlug: string | undefined
) => {
  return process.env.NODE_ENV === 'production'
    ? `https://blogspots.co/@${businessSlug}/${blogPostSlug}.html`
    : `https://amazely-business-development.s3.amazonaws.com/@${businessSlug}/${blogPostSlug}.html`;
};

export const BlogCreationTourIdRegistry = {
  UploadPhotoSection: 'upload-photo-section',
  TopicSuggestionSection: 'topic-suggestion-section',
  AddKeywordTextField: 'add-keyword-text-field',
  GenerateBlogContent: 'generate-blog-content',
};

export const getDefaultBlogProfileKeywords = (location: LocalLocation) => {
  const addressParts = location.full_address.split(',');
  if (addressParts.length >= 2) {
    return [
      location.primary_category,
      addressParts[addressParts.length - 1],
      addressParts[addressParts.length - 2],
    ];
  } else {
    return [location.primary_category, location.full_address];
  }
};
