/* eslint-disable react-hooks/exhaustive-deps */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { RateConversion } from '@constants/rate-conversion';
import { useAppDispatch, useAuthState, useReviewState } from '@core/.';
import { GoogleReview } from '@core/models';
import { Button, Card, Col, Divider, Empty, Row, Skeleton, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { heroApiService } from '@services/service-register';
import { Widget, WidgetConfig } from '@core/models/widget';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import {
  CheckOutlined,
  CloseOutlined,
  DesktopOutlined,
  MobileOutlined,
  SaveFilled,
} from '@ant-design/icons';
import LoadableContainer from '@components/LoadableContainer';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import WidgetCarouselContainer from '@components/WidgetCarouselContainer';
import { MIDDLE_STYLE } from '@constants/responsive';
import { CardSectionHeader } from '..';
import { cloneArray } from '@utils/array.util';
import { enqueueNotification, SnackbarItem } from '@core/features';
import ReviewWidgetEmbeddedScriptSection from './ReviewWidgetEmbeddedScriptSection';

const MAX_REVIEWS = 100;

const processFeaturedReviews = async (
  config: WidgetConfig,
  reviews: GoogleReview[]
): Promise<GoogleReview[]> => {
  // Filter to get latest & highest star review
  let filteredReviews = reviews.filter(
    review => RateConversion[review.starRating] >= config.minStarRating
  );
  if (config.hideNoCommentReviews) {
    filteredReviews = filteredReviews.filter(review => review.comment);
  }
  const featuredReviews = filteredReviews.slice(0, MAX_REVIEWS);
  const sortedFeaturedReviews = cloneArray(featuredReviews).sort((reviewA, reviewB) =>
    moment(reviewA.updateTime).unix() > moment(reviewB.updateTime).unix() ? -1 : 1
  );
  return sortedFeaturedReviews;
};

const saveWidget = async (locationId: string, reviews: GoogleReview[], config: WidgetConfig) => {
  return heroApiService.saveWidget(locationId, reviews, config);
};

const fetchWidgetData = async (locationId: string): Promise<Widget> => {
  return heroApiService.getWidgetByLocationID(locationId);
};

const ReviewWidgetContainer = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { isMobile } = useBreakpoint();
  const { reviewResponse, isLoading, isReviewLoading } = useReviewState();
  const [widget, setWidget] = useState<Widget>();
  const [device, setDevice] = useState('desktop');
  const [isLoadingWidget, setIsLoadingWidget] = useState(true);
  const [widgetConfigUpdating, setWidgetConfigUpdating] = useState(false);
  const [widgetConfigUpdated, setWidgetConfigUpdated] = useState(false);
  const selectedLocation = useMemo(() => user?.selected_location, [user]);
  const isReviewFullyLoaded = useMemo(
    () =>
      // Must have user data loaded.
      !!user &&
      // Must have location data loaded.
      !!selectedLocation &&
      // Not review action is happening.
      !isLoading &&
      // Google profile reviews is fully loaded
      !isReviewLoading,
    [user, selectedLocation, isLoading, isLoadingWidget, isReviewLoading]
  );
  // The core logic is ready to be handled
  const isFullyLoaded = useMemo(
    () =>
      // Widget is fully loaded.
      !isLoadingWidget && isReviewFullyLoaded,
    [isLoadingWidget, isReviewFullyLoaded]
  );

  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>({
    minStarRating: 4,
    hideNoCommentReviews: false,
  });

  const updateWidgetState = (widget: Widget) => {
    setWidget(widget);
    if (widget.config) setWidgetConfig(widget.config);
  };

  const overwriteWidgetData = async () => {
    // Fetch the business reviews and update widget data
    const reviews = await processFeaturedReviews(widgetConfig, reviewResponse.reviews);
    // We only update the featured reviews if there are reviews features
    const updatedWidget = await saveWidget(selectedLocation!.location_id, reviews, widgetConfig);
    setWidget(updatedWidget);
  };

  const hanldeUpdateWidgetConfig = (config: WidgetConfig) => {
    setWidgetConfig(config);
    setWidgetConfigUpdated(true);
    processFeaturedReviews(config, reviewResponse.reviews);
  };

  const handleSaveWidgetConfig = async () => {
    try {
      setWidgetConfigUpdating(true);
      if (!selectedLocation) throw new Error('No location found');
      await overwriteWidgetData();
      // Update the state of widget on the UI side with the remote one.
      const widgetData = await fetchWidgetData(selectedLocation.location_id);
      updateWidgetState(widgetData);
      dispatch(
        enqueueNotification({
          type: 'Success',
          name: `Widget setting updated`,
        } as SnackbarItem)
      );
    } catch (error: any) {
      dispatch(
        enqueueNotification({
          type: 'Error',
          name: `Failed to save widget settings: ${error.message}`,
        } as SnackbarItem)
      );
    } finally {
      setWidgetConfigUpdated(false);
      setWidgetConfigUpdating(false);
    }
  };

  const onChangeDevice = async (e: RadioChangeEvent) => {
    setDevice(e.target.value);
  };

  const onChangeFilterRating = (e: RadioChangeEvent) => {
    hanldeUpdateWidgetConfig({
      ...widgetConfig,
      minStarRating: e.target.value,
    });
  };

  useEffect(() => {
    const init = async () => {
      if (isReviewFullyLoaded) {
        setIsLoadingWidget(true);
        try {
          // Get the data of the widget in the remote database
          const loadedWidget: Widget = await fetchWidgetData(selectedLocation!.location_id);
          if (loadedWidget) {
            // Update the UI state of the widget if it is configured
            updateWidgetState(loadedWidget);
          } else {
            await overwriteWidgetData();
          }
        } catch (error: any) {
          dispatch(
            enqueueNotification({
              type: 'Error',
              name: `Failed to load widget data: ${error.message}`,
            } as SnackbarItem)
          );
        }
        setIsLoadingWidget(false);
      }
    };
    init();
  }, [isReviewFullyLoaded]);

  return (
    <Card
      style={{
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
        padding: 0,
      }}>
      <LoadableContainer isLoading={!isFullyLoaded} loadComponent={<Skeleton />}>
        {widget && selectedLocation ? (
          <Row>
            <Col sm={24} xl={11}>
              <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                <CardSectionHeader title="Preview" />
                <Radio.Group onChange={onChangeDevice} value={device}>
                  <Radio.Button value="mobile">
                    <MobileOutlined />
                  </Radio.Button>
                  <Radio.Button value="desktop">
                    <DesktopOutlined />
                  </Radio.Button>
                </Radio.Group>
              </div>
              <Divider />
              <Row style={{ marginInline: 0, gap: '10px' }}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>Star ratings:</h4>
                </Col>
                <Col>
                  <Radio.Group onChange={onChangeFilterRating} value={widgetConfig.minStarRating}>
                    <Radio value={4}>Include 4-star reviews</Radio>
                    <Radio value={5}>5-star reviews only</Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row style={{ marginInline: 0, gap: '10px' }}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>Hide reviews without comment:</h4>
                </Col>
                <Col>
                  <Switch
                    size="small"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={widgetConfig.hideNoCommentReviews}
                    onChange={() =>
                      hanldeUpdateWidgetConfig({
                        ...widgetConfig,
                        hideNoCommentReviews: !widgetConfig.hideNoCommentReviews,
                      })
                    }
                  />
                </Col>
              </Row>
              <LoadableContainer isLoading={isLoadingWidget} loadComponent={<Skeleton />}>
                <div
                  style={{
                    ...MIDDLE_STYLE,
                    flexDirection: 'column',
                  }}>
                  {widget.reviews.length === 0 ? (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>No featured review</span>}
                    />
                  ) : (
                    <WidgetCarouselContainer reviews={widget.reviews} device={device as any} />
                  )}
                  {widgetConfigUpdated && (
                    <Button
                      loading={widgetConfigUpdating}
                      onClick={handleSaveWidgetConfig}
                      className="success-btn"
                      style={{ color: 'white' }}>
                      Save widget settings <SaveFilled style={{ marginLeft: 10, marginRight: 0 }} />
                    </Button>
                  )}
                </div>
              </LoadableContainer>
            </Col>
            {!isMobile && (
              <Col sm={24} xl={1} style={{ textAlign: 'center' }}>
                <Divider type="vertical" style={{ height: '100%' }} />
              </Col>
            )}
            <Col sm={24} xl={12}>
              <ReviewWidgetEmbeddedScriptSection locationId={selectedLocation.location_id} />
            </Col>
          </Row>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No widget data found</span>}
          />
        )}
      </LoadableContainer>
    </Card>
  );
};

export default ReviewWidgetContainer;
