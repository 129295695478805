import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService, UpdateLocationOwnerPayload } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { RootState } from '@core/configureStore';
import { fetchCurrentUser } from '@core/features/auth/thunks';

export const updateOwner = createAsyncThunk(
  'business/updateOwner',
  async (ownerDetails: UpdateLocationOwnerPayload, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('No user found');
      const selectedLocation = user.selected_location;

      await heroApiService.updateOwner(selectedLocation.location_id, ownerDetails);
      await thunkApi.dispatch(fetchCurrentUser());
      thunkApi.dispatch(
        enqueueNotification({
          name: `Update owner of location ${selectedLocation.title}`,
          description: 'Successfully update owner contact',
          type: 'Success',
        } as SnackbarItem)
      );
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to update owner',
          description: 'There was error updating owner. Please try again',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(updateOwner);

export const updateOwnerReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
