import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { formatUtcDate } from '@utils/date.util';
import { shortenString } from '@utils/string.util';
import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import { useAppNavigation, useBreakpoint } from '@core/hooks';
import { BlogPostModel, BlogPostStage } from '@core/models';

interface Props {
  blogPost: BlogPostModel;
}

const SmallBlogPostCard: React.FC<Props> = ({ blogPost }) => {
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const handleViewBlogPost = (blogPostId: string) => {
    navigate<string>(`blogs/${blogPostId}`);
  };

  return (
    <div
      onClick={() => handleViewBlogPost(blogPost.id)}
      style={{ width: '100%', margin: '10px 0px 20px 0px' }}>
      <div
        style={{
          ...MIDDLE_STYLE,
          width: '100%',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}>
        <div style={{ display: 'flex', flexWrap: isTablet ? 'wrap' : 'unset' }}>
          {blogPost.thumbnail.length > 0 ? (
            <img
              src={blogPost.thumbnail}
              style={{
                height: isTablet ? '200px' : '50px',
                maxWidth: isTablet ? '100%' : '70px',
                width: '100%',
                objectFit: 'cover',
                marginRight: isTablet ? 0 : 20,
                marginBottom: isTablet ? 20 : 0,
                borderRadius: 5,
              }}
              alt={'thumbnail'}
            />
          ) : (
            <Skeleton.Image
              active
              style={{
                height: isTablet ? '200px' : '50px',
                width: 70,
                marginRight: isTablet ? 0 : 20,
                marginBottom: isTablet ? 20 : 0,
              }}
            />
          )}
          <div style={{ maxWidth: 1000 }}>
            <Tooltip title={blogPost.title}>
              <h3
                style={{
                  fontSize: '13px',
                  fontWeight: 600,
                }}>
                {shortenString(blogPost.title, 100)}
              </h3>
            </Tooltip>
            <p
              style={{
                marginTop: 5,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                fontSize: '11px',
                marginRight: 20,
              }}>
              <span style={{ fontWeight: 'bold' }}>
                {blogPost.status === BlogPostStage.Published && blogPost.publish_at
                  ? `Published at ${formatUtcDate(blogPost.publish_at)}`
                  : `Updated at ${formatUtcDate(blogPost.updated_date)}`}
              </span>{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallBlogPostCard;
