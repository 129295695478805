import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { fetchInquiriesReducer, openInquiryReducer, respondInquiryReducer } from './thunks';
import { InquiryReducerProps } from './type';

const inquirySlice = createSlice<
  InquiryReducerProps,
  SliceCaseReducers<InquiryReducerProps>,
  string
>({
  name: SliceName.Inquiry,
  initialState: {
    isLoading: false,
    isDetailLoading: false,
    isMutating: false,
    isSelected: false,
    isOpening: false,
    inquiries: [],
    inquiry: null,
    error: '',
  },
  reducers: {},
  extraReducers: {
    ...fetchInquiriesReducer,
    ...openInquiryReducer,
    ...respondInquiryReducer,
  },
});

export const inquiryReducer = inquirySlice.reducer;
