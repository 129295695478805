/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useAppDispatch, useAuthState, useBatchAppDispatch } from '..';
import { fetchReviews } from '@core/features/review/thunks';
import { fetchReviewRequests } from '@core/features/review-request/thunks';
import { fetchSocialPosts } from '@core/features/studio/thunks';
import { fetchSquareMerchant } from '@core/features/business/thunks';
import { setReviews } from '@core/features';

const usePreloadAppMount = () => {
  const { user } = useAuthState();
  const dispatch = useAppDispatch();
  const batchDispatch = useBatchAppDispatch();
  useEffect(() => {
    const init = async () => {
      dispatch(setReviews([]));
      batchDispatch([
        fetchReviews(),
        fetchSocialPosts(),
        fetchReviewRequests(),
        fetchSquareMerchant(),
      ]);
    };
    init();
  }, [user?.id, user?.selected_location]);
};

export default usePreloadAppMount;
