import { EmbeddedBlogSetting, EmbeddedBlogViewMode, EmbeddedWallOfLoveSetting } from '@core/models';

export const DEFAULT_EMBEDDED_BLOG_SETTING: EmbeddedBlogSetting = {
  cardColor: 'white',
  cardMaxSize: 350,
  cardTitleColor: 'black',
  cardPerPage: 4,
  maxContainerSize: 1500,
  displayMode: EmbeddedBlogViewMode.ListView,
};

export const DEFAULT_EMBEDDED_WOL_SETTING: EmbeddedWallOfLoveSetting = {
  cardColor: 'white',
  cardTitleColor: 'black',
  maxContainerSize: 1500,
  minStarRating: 4,
  hideNodeCommentReviews: true,
  maxReviews: 20,
  maxReviewsMobile: 6,
};
