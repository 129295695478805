import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewReducerProps } from '../type';
import { heroApiService } from '@services/service-register';
import { RootState } from '@core/configureStore';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';

type Props = {
  message: string;
};

export const respondReview = createAsyncThunk(
  'review/respondReview',
  async ({ message }: Props, thunkApi) => {
    try {
      const {
        auth: { user },
        review: { review },
      } = thunkApi.getState() as RootState;
      if (!user || !review) throw new Error('No user or selected review found');

      await heroApiService.upsertReply(
        user.selected_location.location_id,
        review.review_id,
        message
      );
      thunkApi.dispatch(
        enqueueNotification({
          name: `Successfully respond review`,
          description: 'Successfully respond review',
          type: 'Success',
        } as SnackbarItem)
      );
      const _review = await heroApiService.getLocalReviewByGoogleId(review.review_id);
      return _review;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to respond review',
          description: 'There was error respond review. Please try again',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(respondReview);

export const respondReviewReducer = {
  [fulfilled]: (state, action) => {
    state.isReviewLoading = false;
    if (state.isSelected && state.review) {
      state.review.responded_date = new Date().toISOString();
      state.review.is_responded = true;
      state.review.generated_response = action.payload.comment;
    }
    state.isReviewRefreshing = action.payload.id;
  },
  [rejected]: (state, action) => {
    state.isReviewLoading = false;
    state.isReviewRefreshing = undefined;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isReviewLoading = true;
    state.isReviewRefreshing = undefined;
  },
} as ExtraReducer<ReviewReducerProps>;
