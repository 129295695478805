import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { SubscriptionReducerProps } from '../type';

export const manageSubscription = createAsyncThunk(
  'subscription/manageSubscription',
  async (_, thunkApi) => {
    return await heroApiService.createCustomerPortalSession();
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(manageSubscription);

export const manageSubscriptionReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    window.location.replace(action.payload);
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<SubscriptionReducerProps>;
