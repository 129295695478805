/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAuthState, useDrawerState } from '@core/configureStore';
import { closeDrawer } from '@core/features';
import { useSocialPostDispatcher } from '@core/hooks';
import { Empty, Skeleton } from 'antd';
import { LoadableContainer } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import BrowsePhotoDrawerLayout from '@components/BrowsePhotoDrawerLayout';

type Props = {
  singleFile?: boolean;
  onLoad: (pics: any[]) => Promise<void>;
};

type BusinessPhotoItem = {
  data: string;
  id: string;
};

const BrowseBusinessPhotoDrawer = ({ singleFile, onLoad }: Props) => {
  const { user } = useAuthState();
  const [loading, setLoading] = useState<boolean>(true);
  const [photos, setPhotos] = useState<BusinessPhotoItem[]>([]);
  const dispatch = useAppDispatch();
  const { businessPhotoBrowseDrawer } = useDrawerState();
  const [selectedPics, setSelectedPics] = useState<Record<string, boolean>>({});
  const { fetchGoogleBusinessPhotos } = useSocialPostDispatcher();

  const handleOnClose = () => {
    dispatch(
      closeDrawer({
        drawerName: 'businessPhotoBrowseDrawer',
      })
    );
  };

  const handleUpload = () => {
    onLoad(photos.filter(pic => selectedPics[pic.id]));
    handleOnClose();
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const _photos = await fetchGoogleBusinessPhotos(user?.selected_location);
        setPhotos(_photos);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (businessPhotoBrowseDrawer) {
      init();
    }
  }, [businessPhotoBrowseDrawer]);

  return (
    <BrowsePhotoDrawerLayout
      title={'Google Profile Photos'}
      handleUpload={handleUpload}
      handleOnClose={handleOnClose}
      open={businessPhotoBrowseDrawer}>
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        <LoadableContainer
          isLoading={photos.length === 0}
          loadComponent={
            <div style={{ ...MIDDLE_STYLE }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No images found</span>}
              />
            </div>
          }>
          <div className="card-list">
            {photos.map(photo => (
              <div
                onClick={() =>
                  setSelectedPics(
                    singleFile
                      ? {
                          [photo.id]: !selectedPics[photo.id],
                        }
                      : {
                          ...selectedPics,
                          [photo.id]: !selectedPics[photo.id],
                        }
                  )
                }
                className="card"
                key={photo.id}
                style={{
                  borderRadius: 20,
                  padding: selectedPics[photo.id] ? '5px' : '0px',
                  cursor: 'pointer',
                  ...(selectedPics[photo.id]
                    ? { border: `6px solid ${GLOBAL_THEME_COLOR.$highlight_color}` }
                    : {}),
                }}>
                <LazyLoadImage
                  loading="lazy"
                  className="card--image"
                  src={`data:image/jpeg;base64,${photo.data}`}
                  width="50%"
                  height="50%"
                />
              </div>
            ))}
          </div>
        </LoadableContainer>
      </LoadableContainer>
    </BrowsePhotoDrawerLayout>
  );
};

export default BrowseBusinessPhotoDrawer;
