/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { FacebookPage, InstagramProfile, SocialPlatformStr } from '@core/models';
import { Popover } from 'antd';
import { SocialIcon } from '..';

type Props = {
  onClick?: () => void;
  picture: string;
  pictureType: 'base64' | 'url';
  account: FacebookPage | InstagramProfile;
  platform: SocialPlatformStr;
  connected: boolean;
  tooltipContent?: React.ReactNode;
};

const SocialProfileButton = ({
  onClick,
  picture,
  platform,
  account,
  tooltipContent,
  connected,
  pictureType,
}: Props) => {
  const ContentWrapper = ({ children }: { children: any }) =>
    tooltipContent ? (
      <Popover
        trigger={'click'}
        placement="left"
        content={
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <img
                width={30}
                height={30}
                style={{ borderRadius: 50 }}
                src={pictureType === 'base64' ? `data:image/jpeg;base64,${picture}` : `${picture}`}
              />
              <div style={{ marginLeft: 20 }}>
                <h3 style={{ margin: 0 }}>{account.name}</h3>
                <p style={{ margin: 0 }}>{(account as InstagramProfile).username}</p>
              </div>
            </div>
            {tooltipContent}
          </React.Fragment>
        }>
        <div>{children}</div>
      </Popover>
    ) : (
      children
    );

  return (
    <ContentWrapper>
      <div
        onClick={onClick}
        style={{
          borderRadius: 500,
          border: connected
            ? `5px solid ${GLOBAL_THEME_COLOR.$success_color}`
            : `5px solid ${GLOBAL_THEME_COLOR.$dark_secondary_color}`,
          padding: '3px',
          position: 'relative',
          cursor: 'pointer',
          ...MIDDLE_STYLE,
        }}>
        <img
          width={30}
          height={30}
          style={{ borderRadius: 50 }}
          src={pictureType === 'base64' ? `data:image/jpeg;base64,${picture}` : `${picture}`}
        />
        <div
          style={{
            position: 'absolute',
            color: GLOBAL_THEME_COLOR.$highlight_color,
            fontSize: 'larger',
            bottom: -15,
          }}>
          <SocialIcon width={18} platform={platform} />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default SocialProfileButton;
