/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  MailOutlined,
  QrcodeOutlined,
  ShareAltOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import AnimatedComponent from '@components/AnimatedComponent';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { useAppDispatch } from '@core/configureStore';
import { AppIntegrationKey, INTEGRATION_APP_REGISTRY, openDrawer } from '@core/features';
import { useAppNavigation } from '@core/hooks';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { AppRoutes } from '@utils/route.util';
import { Card, Col, Row } from 'antd';
import { useIntercom } from 'react-use-intercom';
import { TbApps } from 'react-icons/tb';
import AppIntegrationStatusBadge from '@components/AppIntegrationStatusBadge';

type Props = {};

const GenerateReviewScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const intercom = useIntercom();
  const { navigate } = useAppNavigation();
  const { isTablet } = useBreakpoint();

  const onAppIntegrationClicked = (appId: AppIntegrationKey) => {
    dispatch(
      openDrawer({
        drawerName: 'appIntegrationDrawer',
        extraParams: {
          appId,
        },
      })
    );
  };

  return (
    <div
      style={{
        ...(!isTablet ? MIDDLE_STYLE : { marginBottom: 90 }),
        backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
      }}>
      <div
        style={{
          ...MIDDLE_STYLE,
          flexDirection: 'column',
          padding: '20px 20px',
        }}>
        <h1 className="airbnb-font">Collect Customer Reviews</h1>
        <p>Asking for reviews doesn't have to be manual & tedious.</p>
        <Row style={{ maxWidth: 900, marginTop: 20 }}>
          {[
            {
              key: 'requestEmailSMS' as AppRoutes,
              icon: <MailOutlined style={{ marginRight: 10 }} />,
              title: 'Email & SMS',
              description:
                'Solicit and gather reviews seamlessly from customers through Email and SMS requests.',
            },
            {
              key: 'requestQrScanner' as AppRoutes,
              icon: <QrcodeOutlined style={{ marginRight: 10 }} />,
              title: 'QR Code',
              description:
                'Generate QR Codes to effortlessly gather more reviews. Collect feedback with ease.',
            },
            {
              key: 'manualSharing' as AppRoutes,
              icon: <ShareAltOutlined style={{ marginRight: 10 }} />,
              title: 'Manual Sharing',
              description: 'Share the link to customers to initiate the review generation process',
            },
            {
              key: 'autoRequest' as AppRoutes,
              icon: <ThunderboltOutlined style={{ marginRight: 10 }} />,
              title: 'Auto Request',
              description:
                'Auto send a review request to your customer when you BCC us in your invoice email.',
            },
            {
              key: 'square',
              icon: (
                <img
                  alt="Square logo"
                  src="square-inc.png"
                  width={20}
                  style={{
                    marginRight: 10,
                  }}
                />
              ),
              title: 'Square Integration',
              description: 'Automatically send post-purchase review invitations to customers.',
              onClick: () => onAppIntegrationClicked('square'),
            },
            {
              key: 'quickbooks',
              icon: (
                <img
                  alt="QuickBooks logo"
                  src="quickbooks.png"
                  width={20}
                  style={{
                    marginRight: 10,
                  }}
                />
              ),
              title: 'QuickBooks Integration',
              description: 'Automatically send post-purchase review invitations to customers.',
            },
            {
              key: 'jobber',
              icon: (
                <img
                  alt="Jobber logo"
                  src="jobber.png"
                  width={20}
                  style={{
                    marginRight: 10,
                  }}
                />
              ),
              title: 'Jobber Integration',
              description: 'Automatically send post-purchase review invitations to customers.',
              onClick: () => onAppIntegrationClicked('jobber'),
            },
            {
              key: 'xero',
              icon: (
                <img
                  alt="Xero logo"
                  src="xero.png"
                  width={20}
                  style={{
                    marginRight: 10,
                  }}
                />
              ),
              title: 'Xero Integration',
              description: 'Automatically send post-purchase review invitations to customers.',
              onClick: () => onAppIntegrationClicked('xero'),
            },
            {
              key: 'opendental',
              icon: (
                <img
                  alt="Open Dental logo"
                  src="open-dental.jpg"
                  width={20}
                  style={{
                    marginRight: 10,
                  }}
                />
              ),
              title: 'Open Dental Integration',
              description: 'Automatically send post-appointment review invitations to patients.',
              onClick: () => onAppIntegrationClicked('openDental'),
            },
            {
              key: 'customIntegration',
              icon: <TbApps style={{ marginRight: 20 }} />,
              title: 'Custom Integration',
              description: 'Send a request to Amazely team for the custom integration',
              onClick: () => intercom.show(),
            },
          ].map((item, index) => (
            <Col span={isTablet ? 24 : 12} style={{ ...MIDDLE_STYLE }}>
              <AnimatedComponent.OpacityFadeInDiv delay={50 * index}>
                <Card
                  className="no-padding"
                  onClick={() => (item.onClick ? item.onClick() : navigate(item.key))}
                  style={{
                    cursor: 'pointer',
                    maxWidth: 400,
                    borderRadius: 10,
                    boxShadow: STRIPE_BOX_SHADOW,
                    margin: '10px 10px',
                  }}>
                  <div style={{ padding: '15px 20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3
                        className="airbnb-font"
                        style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start' }}>
                        {item.icon}
                        {item.title}
                      </h3>
                      <div>
                        {Object.keys(INTEGRATION_APP_REGISTRY).includes(item.key) && (
                          <AppIntegrationStatusBadge key={item.key} appId={item.key as any} />
                        )}
                      </div>
                    </div>
                    <p style={{ marginTop: 10, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                      {item.description}
                    </p>
                  </div>
                </Card>
              </AnimatedComponent.OpacityFadeInDiv>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default GenerateReviewScreen;
