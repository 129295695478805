export enum ReservationStage {
  Pending = 0,
  Resolved = 1,
}
export type Reservation = {
  id: number;
  location_id: string;
  contact: string | undefined | null;
  phone: string | undefined | null;
  fullName: string;
  numberOfPeople: number;
  stage: ReservationStage;
  created_date: number;
  start_date: string;
};
