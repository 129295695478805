/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from 'react';
import { Card, Skeleton, Space } from 'antd';
import { SocialPlatformType } from '../BusinessSocialPostCard';
import LoadableContainer from '@components/LoadableContainer';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { BusinessSocialPost, GoogleSmartReview } from '@core/models';
import { formatTimestamp } from '@utils/date.util';
import { NewlineText } from '..';
import HtmlReviewCardFromTemplate from '@components/ReviewSocialPostEditor/HtmlReviewCardFromTemplate';
import { starRatingFromNumber } from '@utils/type.util';

type Props = {
  innerRef: any;
  socialPost: BusinessSocialPost;
  businessName: string;
  type: SocialPlatformType;
  review: GoogleSmartReview;
};

const SyncedReviewSocialPost = ({ innerRef, socialPost, businessName, review, type }: Props) => {
  return (
    <Card
      hoverable
      className="no-padding"
      style={{
        maxWidth: 400,
        minWidth: 330,
        width: '100%',
        marginRight: 10,
        marginTop: 40,
        boxShadow: 'unset',
      }}>
      <div style={{ padding: '20px' }}>
        <LoadableContainer isLoading={false} loadComponent={<Skeleton active />}>
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <h3 style={{ margin: '10px 5px' }}>{businessName}</h3>
              <img src={`social-logo/${type}_logo.webp`} width={30} />
            </div>
            <div
              style={{
                top: -20,
                position: 'absolute',
                backgroundColor: GLOBAL_THEME_COLOR.$success_color,
                width: 'fit-content',
                color: 'white',
                padding: '5px 10px',
                borderRadius: 5,
                fontWeight: 600,
              }}>
              Scheduled for {socialPost && formatTimestamp(socialPost.scheduledAt)}
            </div>
            <NewlineText text={socialPost?.caption || ''} />
          </React.Fragment>
        </LoadableContainer>
      </div>
      <div ref={innerRef} style={{ ...MIDDLE_STYLE, flexDirection: 'column', maxHeight: '400px' }}>
        <HtmlReviewCardFromTemplate
          ref={null}
          scale={0.5}
          review={{
            comment: review.text,
            rating: starRatingFromNumber(review.rating),
            reviewer: {
              displayName: review.author_name,
              profilePhotoUrl: review.profile_photo_url,
              isAnonymous: false,
            },
          }}
        />
      </div>
      <Space
        size={40}
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'space-between',
          padding: '20px 10px',
        }}>
        <p
          style={{
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            ...MIDDLE_STYLE,
            textAlign: 'center',
          }}>
          <img width={45} src={`social_emo_icon/${type}-icon.png`} />{' '}
          <span style={{ marginLeft: 10 }}>{Math.floor(Math.random() * 100) + 100} likes</span>
        </p>
        <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
          {Math.floor(Math.random() * 10) + 10} comments
        </p>
      </Space>
    </Card>
  );
};

export default SyncedReviewSocialPost;
