/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useBusinessState } from '@core/configureStore';
import { openModal } from '@core/features';
import { fetchFacebookSelectedPages } from '@core/features/business/thunks';
import { FacebookPage } from '@core/models';
import { Button } from 'antd';
import { SocialIcon } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {};

const ConnectSocialAccountsLocation = (props: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { integration } = useBusinessState();
  const selectedPages = useMemo<FacebookPage[]>(() => integration.facebook_pages, [integration]);

  const handleFetchSelectedPages = async () => {
    setLoading(true);
    try {
      await dispatch(fetchFacebookSelectedPages());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSelectPage = () => {
    dispatch(
      openModal({
        modalName: 'selectSocialAccountsModal',
      })
    );
  };

  useEffect(() => {
    const init = async () => {
      await handleFetchSelectedPages();
    };
    init();
  }, []);

  return selectedPages?.length && selectedPages.length > 0 ? (
    <Button loading={loading} type="primary" onClick={handleSelectPage} style={{ ...MIDDLE_STYLE }}>
      <SocialIcon width={20} platform="facebook" style={{ marginRight: 10 }} />
      <SocialIcon width={20} platform="instagram" style={{ marginRight: 10 }} />
      {[...integration.facebook_pages, ...integration.instagram_profiles].length} pages connected
    </Button>
  ) : (
    <Button loading={loading} onClick={handleSelectPage} style={{ ...MIDDLE_STYLE }}>
      <SocialIcon platform="facebook" width={20} style={{ margin: '0px 3px' }} />{' '}
      <SocialIcon platform="instagram" width={20} style={{ margin: '0px 3px' }} />{' '}
      <span style={{ marginLeft: 5 }}>Select social pages</span>
    </Button>
  );
};

export default ConnectSocialAccountsLocation;
