export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const MIDDLE_STYLE = {
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
};
