import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { GoogleReview } from '@core/models';
import { formatRelativeUtcDate } from '@utils/date.util';
import { shortenString } from '@utils/string.util';
import { Rate } from 'antd';
import React from 'react';

type Props = {
  review: GoogleReview;
  backgroundColor: string;
  titleColor: string;
};

const ReviewWallOfLoveCard = ({ review, backgroundColor, titleColor }: Props) => {
  return (
    <div
      className="no-padding card"
      style={{
        borderRadius: 15,
        cursor: 'pointer',
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        backgroundColor: backgroundColor,
        fontSize: 12,
      }}>
      <div
        style={{
          padding: '15px 25px',
          width: '100%',
        }}>
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <div style={{ ...MIDDLE_STYLE }}>
            <img
              referrerPolicy="no-referrer"
              style={{ width: 40, height: 40, marginRight: 10 }}
              src={review.reviewer.profilePhotoUrl}
              alt={review.reviewer.displayName}
            />
            <div>
              <div style={{ ...MIDDLE_STYLE, flexWrap: 'wrap' }}>
                <h3 style={{ margin: 0, color: titleColor }}>{review.reviewer.displayName}</h3>
                <p
                  style={{
                    margin: '0px 10px',
                    color: GLOBAL_THEME_COLOR.$dark_text_color,
                  }}>
                  {formatRelativeUtcDate(review.updateTime)}
                </p>
              </div>
              <Rate style={{ fontSize: 15 }} defaultValue={0} disabled value={review.starRating} />
            </div>
          </div>
          <div>
            <img
              src="https://cdn-icons-png.flaticon.com/512/720/720255.png"
              alt={'google-icon'}
              style={{
                width: 25,
                height: 25,
              }}
            />
          </div>
        </div>
        <p
          style={{
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            marginTop: 20,
            width: '100%',
          }}>
          {shortenString(review.comment, 400)}
        </p>
      </div>
    </div>
  );
};

export default ReviewWallOfLoveCard;
