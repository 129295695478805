import { Reservation } from '@core/models';
import axios from 'axios';

export default class ReservationApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/reservations`;

  async createNewReservation(
    locationId: string,
    payload: {
      startDate: number;
      fullName: string;
      contact: string | undefined;
      phone: string | undefined;
      numberOfPeople: number;
    }
  ): Promise<Reservation> {
    console.log(payload);
    try {
      const response = await axios.post(`${this.url}/${locationId}`, payload, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async resolveReservation(reservationId: number): Promise<Reservation> {
    try {
      const response = await axios.post(
        `${this.url}/${reservationId}/resolve`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getLocationReservations(): Promise<Reservation[]> {
    try {
      const response = await axios.get(`${this.url}/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
