/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Divider, Popover, Row, Space, Switch } from 'antd';
import {
  CardSectionHeader,
  ReviewSocialPostEditor,
  ReviewWidgetContainer,
  WrapOrNotContainer,
} from '@components/index';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import React, { useEffect, useMemo, useState } from 'react';
import { heroApiService, socialStudioApiService } from '@services/service-register';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { enqueueNotification } from '@core/features';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { ArrowRightOutlined } from '@ant-design/icons';
import { AssistantProfile, OnboardingStage, StarRating } from '@core/models';
import { useBreakpoint, useUserOnboarding } from '@core/hooks';
import { BiReset } from 'react-icons/bi';

type Props = {};

const ShowcaseReviewScreen = (_: Props) => {
  const { user } = useAuthState();
  const dispatch = useAppDispatch();
  const { isMobile } = useBreakpoint();
  const { stageChecked } = useUserOnboarding();
  const [updatingAutoShowcase, setUpdatingAutoShowcase] = useState(false);
  const selectedLocation = useMemo(() => user?.selected_location, [user]);
  const [assistantProfile, setAssistantProfile] = useState<AssistantProfile | undefined>(undefined);
  const [updated, setUpdated] = useState(false);
  const autoShowcaseReviewsEnabled = useMemo(
    () => selectedLocation?.studio_pilot_setting.autoGeneratedShowcaseReviews,
    [selectedLocation?.studio_pilot_setting]
  );

  const handleDiscard = () => {
    if (selectedLocation)
      setAssistantProfile({
        ...selectedLocation?.assistant_profile,
      });
    setUpdated(false);
  };

  const handleSaveChanges = async () => {
    if (!assistantProfile) return;
    try {
      setUpdatingAutoShowcase(true);
      await heroApiService.updateLocationAssistantProfile(assistantProfile);
      await dispatch(fetchSelectedLocation());
      setUpdated(false);
      dispatch(
        enqueueNotification({
          name: 'Successfully update assistant profile',
          description: 'Successfully update assistant profile',
          type: 'Success',
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update assistant profile',
          description: 'Failed to update assistant profile',
          type: 'Error',
        })
      );
    }
    setUpdatingAutoShowcase(false);
  };

  const handleUpdateAutoGeneratedShowcaseReviews = async () => {
    if (!user?.selected_location.studio_pilot_setting) return;
    const autoGeneratedShowcaseReviews =
      user.selected_location.studio_pilot_setting.autoGeneratedShowcaseReviews;
    try {
      setUpdatingAutoShowcase(true);
      await socialStudioApiService.updateLocationStudioPilot({
        autoGeneratedShowcaseReviews: !autoGeneratedShowcaseReviews,
      });
      await dispatch(fetchSelectedLocation());
      dispatch(
        enqueueNotification({
          name: 'Successfully update auto showcase reviews',
          description: 'Successfully update assistant profile',
          type: 'Success',
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update auto showcase reviews',
          description: 'Failed to update assistant profile',
          type: 'Error',
        })
      );
    }
    setUpdatingAutoShowcase(false);
  };

  useEffect(() => {
    setAssistantProfile(selectedLocation?.assistant_profile);
  }, [selectedLocation]);

  return (
    <div className="screen-card">
      <h3 className="airbnb-font">Embed widget on website</h3>
      <Divider />
      <ReviewWidgetContainer />
      <br />
      <h3 className="airbnb-font">Showcase on social platform</h3>
      <Divider />
      <Card
        style={{
          backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        }}>
        <Row>
          <Col sm={24} xl={11}>
            <CardSectionHeader title="Setting" />
            <Divider />
            <Space direction={'vertical'} size={20} style={{ width: '100%', flexWrap: 'wrap' }}>
              <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div>
                  Auto Showcase Incoming Reviews
                  <br />
                  <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                    Enable to automatically generate social posts for incoming reviews
                  </span>
                </div>
                <WrapOrNotContainer
                  wrappedCondition={!stageChecked(OnboardingStage.ShareReviewToSocial)}
                  wrapper={children => (
                    <Popover
                      title={'You need to finish the onboarding stage first.'}
                      content={
                        <Button type="primary" style={{ width: '100%' }}>
                          Visit onboarding screen <ArrowRightOutlined />
                        </Button>
                      }>
                      {children}
                    </Popover>
                  )}>
                  <Switch
                    loading={updatingAutoShowcase}
                    disabled={!stageChecked(OnboardingStage.ShareReviewToSocial)}
                    onChange={handleUpdateAutoGeneratedShowcaseReviews}
                    style={{ marginLeft: 20 }}
                    checked={autoShowcaseReviewsEnabled}
                  />
                </WrapOrNotContainer>
              </div>
            </Space>
            {updated && (
              <Space style={{ marginTop: 20 }}>
                <Button loading={updatingAutoShowcase} danger onClick={handleDiscard}>
                  <BiReset /> Discard
                </Button>
                <Button
                  loading={updatingAutoShowcase}
                  type={'primary'}
                  className={'success-btn'}
                  onClick={handleSaveChanges}>
                  Save changes
                </Button>
              </Space>
            )}
          </Col>
          {!isMobile && (
            <Col sm={24} xl={1} style={{ textAlign: 'center' }}>
              <Divider type="vertical" style={{ height: '100%' }} />
            </Col>
          )}
          <Col sm={24} xl={12}>
            <CardSectionHeader title="Showcase Review Template" />
            <Divider />
            <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginBottom: 15 }}>
              This template will be applied for all future showcase review post
            </p>
            <ReviewSocialPostEditor
              settingMode
              style={{
                height: 'fit-content',
              }}
              innerStyle={{
                transform: 'scale(0.8)',
              }}
              review={{
                reviewer: {
                  profilePhotoUrl: '',
                  isAnonymous: false,
                  emailAddress: '',
                  displayName: `${user?.given_name} ${user?.family_name}` || 'Example User',
                },
                rating: StarRating.FIVE,
                comment:
                  "The service was impressively prompt, and the staff's friendliness added a wonderful touch to the overall experience. The welcoming atmosphere made me feel right at home.",
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ShowcaseReviewScreen;
