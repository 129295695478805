import { create } from 'zustand';
import { StudioTabKey } from '@core/models';

export interface StudioTourStore {
  selectedTabKey: StudioTabKey;
  setSelectedTabKey: (key: StudioTabKey) => void;
  running: boolean;
  setRunning: (state: boolean) => void;
}

export const useStudioTourStore = create<StudioTourStore>()((set: any) => ({
  selectedTabKey: StudioTabKey.ImageGallery,
  setSelectedTabKey(tabKey) {
    set(() => ({
      selectedTabKey: tabKey,
    }));
  },
  running: false,
  setRunning(running) {
    set(() => ({
      running: running,
    }));
  },
}));
