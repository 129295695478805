import { RootState } from '@core/configureStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { InquiryReducerProps } from '../type';

export const fetchInquiries = createAsyncThunk('inquiry/fetchInquiries', async (_, thunkApi) => {
  try {
    const {
      auth: { user },
    } = thunkApi.getState() as RootState;
    if (!user) throw new Error('Not user found');

    const result = await heroApiService.getAllInquiries(user.selected_location?.location_id);
    return result;
  } catch (error: any) {
    console.log(error);
    throw new Error(error);
  }
});

const [fulfilled, pending, rejected] = getSideEffectState(fetchInquiries);

export const fetchInquiriesReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.inquiries = action.payload.items;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.error = '';
    state.inquiries = [];
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<InquiryReducerProps>;
