import React from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { PhotoImageDataItem } from '@core/features';
import { FacebookPostSizeMode, LocalLocation, PartialShowcasedReview } from '@core/models';
import { shortenString } from '@utils/string.util';
import { Optional } from '@utils/type.util';
import { StarFilled } from '@ant-design/icons';

const ReviewSocialPostCard = ({
  ref,
  backgroundImage,
  backgroundColor,
  imageWidth,
  imageHeight,
  sizeMode,
  review,
  location,
}: {
  ref?: React.MutableRefObject<HTMLDivElement | null>;
  backgroundImage: PhotoImageDataItem | undefined;
  backgroundColor: string;
  imageWidth: number;
  imageHeight: number;
  sizeMode: FacebookPostSizeMode;
  review: Optional<PartialShowcasedReview>;
  location: LocalLocation | undefined;
}) => {
  const textColor = 'white';
  const darkTextColor = 'rgb(80, 80, 80)';
  const starColor = '#F7CA17';
  return (
    <div
      ref={ref}
      className="no-styles"
      style={{
        ...MIDDLE_STYLE,
        boxSizing: 'border-box',
        fontFamily: 'Arial, Helvetica, sans-serif',
        background: backgroundImage
          ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage.url})`
          : backgroundColor,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        aspectRatio: imageWidth / imageHeight,
        overflow: 'hidden',
        flexDirection: 'column',
        cursor: 'pointer',
      }}>
      {sizeMode === FacebookPostSizeMode.Square && (
        <div style={{ textAlign: 'center', marginBottom: 30 }}>
          <p style={{ color: textColor }}>Best review from</p>
          <h1 style={{ color: textColor, fontSize: 35, fontWeight: 'bold', margin: '10px 0px' }}>
            {location?.title}
          </h1>
          <p style={{ color: textColor }}>{location?.full_address}</p>
        </div>
      )}
      <div
        style={{
          ...MIDDLE_STYLE,
          flexDirection: 'column',
          width: '80%',
          backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
          padding: '15px 25px 25px 25px',
          borderRadius: 20,
          transform: 'rotate(-1deg)',
          boxShadow: STRIPE_BOX_SHADOW,
          display: 'flex',
        }}>
        <div style={{ fontSize: 30, color: starColor }}>
          {new Array(review?.rating).fill(<StarFilled />).map(rating => rating)}
        </div>
        <h4 style={{ marginTop: 15 }}>{review?.reviewer.displayName}</h4>
        <p
          style={{
            marginTop: 20,
            fontSize: 13,
            color: darkTextColor,
          }}>
          <span>"</span>
          {shortenString(
            review?.comment || 'N/A',
            sizeMode === FacebookPostSizeMode.Landscape ? 300 : 500
          )}
          <span>"</span>
        </p>
      </div>
    </div>
  );
};

export default ReviewSocialPostCard;
