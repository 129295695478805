import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import {
  ExtraReducer,
  buildCachePayload,
  getRevalidatedJsonData,
  getSideEffectState,
} from '@utils/.';
import { LocationReducerProps } from '../type';
import { AuthUser, GoogleLocalLocation, Location } from '@core/models';
import { addGoogleLocation } from '../store';

const GMB_LOCATIONS_CACHE_EXPIRATION_MS = 60 * 24 * 60 * 1000 * 10; // 10 days

const fetchGoogleBusinesses = async (
  locations: Location[],
  onLocalLocationFetched: (localLocation: GoogleLocalLocation) => void
) => {
  const values: GoogleLocalLocation[] = [];
  for (const gmbLocation of locations) {
    try {
      const { location: localLocation, place } = await heroApiService.getGoogleLocationProfile(
        gmbLocation.accountId || '',
        gmbLocation.name
      );
      if (localLocation) {
        const value = {
          ...localLocation,
          placeResult: place,
          gmbLocation,
        };
        onLocalLocationFetched(value);
        values.push(value);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return values;
};

export const fetchAllGoogleBusinessLocations = createAsyncThunk(
  'business/fetchAllGoogleBusinesLocations',
  async (
    {
      user,
      locations,
      forceRefetch,
    }: { user: AuthUser; locations: Location[]; forceRefetch?: boolean },
    thunkApi
  ) => {
    try {
      const localLocations = await getRevalidatedJsonData<GoogleLocalLocation[]>(
        `${user.id}:google-business-locations`,
        async () => {
          const values = await fetchGoogleBusinesses(
            locations,
            async (localLocation: GoogleLocalLocation) => {
              await thunkApi.dispatch(
                addGoogleLocation({
                  location: localLocation,
                })
              );
            }
          );
          return buildCachePayload(values, GMB_LOCATIONS_CACHE_EXPIRATION_MS);
        },
        {
          forceRefetch,
        }
      );
      return localLocations || [];
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchAllGoogleBusinessLocations);

export const fetchAllGoogleBusinesLocationsReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.googleLocations = action.payload;
    state.error = undefined;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.googleLocations = [];
    state.error = 'MISSING_PERMISSION';
  },
  [pending]: (state, action) => {
    state.isLoading = true;
    state.error = undefined;
  },
} as ExtraReducer<LocationReducerProps>;
