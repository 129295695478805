/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { SocialPost } from '@core/models';
import { Modal, Skeleton } from 'antd';
import LoadableContainer from '@components/LoadableContainer';
import { CreateSocialPostContainer } from '..';
import { closeModal } from '@core/features';
import { useBreakpoint } from '@core/hooks';

type Props = {};

const SocialPostDetailModal = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { socialPostModal, extraParams } = useModalState();
  const socialPost = useMemo<SocialPost | undefined>(() => extraParams?.socialPost, [extraParams]);

  const handleCloseModal = async () => {
    dispatch(
      closeModal({
        modalName: 'socialPostModal',
      })
    );
  };

  return (
    <Modal
      open={!!socialPost && socialPostModal}
      width={isTablet ? '100%' : 1400}
      className="snap-action-modal"
      onCancel={handleCloseModal}
      footer={[]}
      closable={true}>
      <LoadableContainer isLoading={!socialPost} loadComponent={<Skeleton />}>
        <CreateSocialPostContainer
          existingSocialPost={socialPost}
          type={(socialPost as any)?.type}
          onPublished={handleCloseModal}
          onCreated={handleCloseModal}
        />
      </LoadableContainer>
    </Modal>
  );
};

export default SocialPostDetailModal;
