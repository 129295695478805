import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import LoginForm from '../components/LoginForm';
import { useAuthState } from '../core';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';

type Props = {};

const AuthScreen = (props: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { user, isAuthLoading } = useAuthState();
  useEffect(() => {
    setIsAuthenticated(!!user);
  }, [user]);

  return (
    <LoadableContainer isLoading={isAuthLoading} loadComponent={<SplashScreen />}>
      <React.Fragment>
        {isAuthenticated ? (
          <Redirect to={'/app'} />
        ) : (
          <div
            style={{
              ...MIDDLE_STYLE,
              height: '100vh',
              backgroundColor: GLOBAL_THEME_COLOR.$background_color,
              backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
              backgroundSize: '20px 20px',
            }}>
            <LoginForm />
          </div>
        )}
      </React.Fragment>
    </LoadableContainer>
  );
};

export default AuthScreen;
