/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { Modal } from 'antd';
import { CreateSocialPostContainer } from '..';
import { closeModal } from '@core/features';
import { base64ToImageItem } from '@utils/file.util';
import { SocialPostType } from '@core/models';
import { useBreakpoint } from '@core/hooks';

type Props = {};

const SocialPostCreationModal = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { socialPostCreationModal, extraParams } = useModalState();
  const photoData = useMemo<string | undefined>(() => extraParams?.photoData, [extraParams]);
  const caption = useMemo<string | undefined>(() => extraParams?.caption, [extraParams]);
  const topic = useMemo<string | undefined>(() => extraParams?.topic, [extraParams]);
  const selectPhotoDisabled = useMemo<boolean>(
    () => extraParams?.selectPhotoDisabled || false,
    [extraParams]
  );
  const selectedAccounts = useMemo<Record<string, boolean>>(
    () => extraParams?.selectedAccounts || {},
    [extraParams]
  );
  const type = useMemo<SocialPostType>(
    () => extraParams?.type || SocialPostType.Default,
    [extraParams]
  );

  const handleCloseModal = async () => {
    dispatch(
      closeModal({
        modalName: 'socialPostCreationModal',
      })
    );
  };

  return (
    <Modal
      open={socialPostCreationModal}
      width={isTablet ? '100%' : type === SocialPostType.GoogleBusinessPost ? 800 : 1400}
      className="snap-action-modal"
      onCancel={handleCloseModal}
      footer={[]}
      closable={true}>
      <CreateSocialPostContainer
        onCreated={handleCloseModal}
        onPublished={handleCloseModal}
        selectPhotoDisabled={selectPhotoDisabled}
        defaultTopic={topic}
        defaultPhotoFile={photoData ? base64ToImageItem(photoData as any) : undefined}
        defaultCaption={caption}
        defaultSelectedAccounts={selectedAccounts}
        allowScheduleLater={type !== SocialPostType.GoogleBusinessPost}
        allowUploadPhoto={type !== SocialPostType.GoogleBusinessPost}
        type={type}
      />
    </Modal>
  );
};

export default SocialPostCreationModal;
