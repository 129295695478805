import { BRAND_LOGO, BRAND_NAME } from '@constants/brand';
import React from 'react';
import { animated, Spring } from 'react-spring';

type Props = {};

const AnimatedBrandLogo = (props: Props) => {
  return (
    <Spring
      loop
      delay={100}
      from={{
        opacity: 0,
        transform: 'rotate(0deg)',
      }}
      to={{
        opacity: 1.0,
        transform: ` rotate(${180}deg)`,
      }}>
      {(styles: any) => (
        <animated.img style={styles} src={BRAND_LOGO} alt={BRAND_NAME} width={'80px'} />
      )}
    </Spring>
  );
};

export default AnimatedBrandLogo;
