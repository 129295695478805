import React from 'react';
import { useGenerationDataStore } from '@core/hooks';
import { AutoReviewGeneratorEngineStatus } from '@core/models';
import loadable from '@loadable/component';
import AutoReviewProcessingStep from '@components/AutoReviewProcessingStep';

const AutoReviewGenerateStatusOverlay = loadable(
  () => import('@components/AutoReviewGenerateStatusOverlay')
);
const AutoReviewGenerateScreen = loadable(() => import('@pages/AutoReviewGenerateScreen'));
const InfoCollectorScreen = loadable(() => import('@pages/InfoCollectorScreen'));

type Props = {};

const AutoReviewBuilderScreen = (props: Props) => {
  const { autoReviewEngineStatus } = useGenerationDataStore();
  return (
    <div>
      {autoReviewEngineStatus === AutoReviewGeneratorEngineStatus.NotStarted ? (
        <InfoCollectorScreen>
          <AutoReviewProcessingStep />
        </InfoCollectorScreen>
      ) : (
        <AutoReviewGenerateScreen />
      )}
      <AutoReviewGenerateStatusOverlay />
    </div>
  );
};

export default AutoReviewBuilderScreen;
