import React from 'react';
import { Button } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import { BiImage } from 'react-icons/bi';
import { openDrawer } from '@core/features';
import { useAppDispatch } from '@core/configureStore';

interface Props {
  style?: React.CSSProperties;
}

const BrowsePhotoGallery: React.FC<Props> = ({ style }) => {
  const dispatch = useAppDispatch();
  const handleBrowsePhotoGallery = () => {
    dispatch(
      openDrawer({
        drawerName: 'photoGalleryBrowseDrawer',
      })
    );
  };
  return (
    <Button onClick={handleBrowsePhotoGallery} style={{ ...MIDDLE_STYLE, ...style }}>
      <BiImage />
      <span style={{ marginLeft: 10 }}>Browse Photo Gallery</span>
    </Button>
  );
};

export default BrowsePhotoGallery;
