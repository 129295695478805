/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { Button, Divider, Layout, Popover, Space, theme } from 'antd';
import { useAppDispatch, useAuthState, useDigitalCreatorMode } from '@core/.';
import { logout } from '@core/features/auth/thunks';
import {
  CheckCircleFilled,
  LoadingOutlined,
  LogoutOutlined,
  MenuOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { AuthUser } from '@core/models';
import { ConnectFacebookButton, ManageLicenseButton, SubscriptionCancelButton } from '..';
import { useParams } from 'react-router';
import { AppRoutes } from '@utils/route.util';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import usePreloadAppMount from '@core/hooks/usePreloadAppMount';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME } from '@constants/brand';
import { MIDDLE_STYLE } from '@constants/responsive';
import { openDrawer } from '@core/features';

type Props = {};

const AppBar = (props: Props) => {
  usePreloadAppMount();
  const { isTablet } = useBreakpoint();
  const { user } = useAuthState();
  const dispatch = useAppDispatch();
  const { handleSwitchDigitalCreatorMode } = useDigitalCreatorMode();
  const { tabName } = useParams<{
    tabName: AppRoutes;
  }>();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleLogout = () => {
    dispatch(logout());
  };

  const hoverContent = (user: AuthUser) => (
    <div>
      <h3>{`${user.given_name} ${user.family_name}`}</h3>
      <p>{`${user.email}`}</p>
      <Space direction="vertical" style={{ marginTop: 20 }}>
        <ConnectFacebookButton />
        <ManageLicenseButton />
        <Divider style={{ margin: '3px 0px' }} />
        <Button style={{ width: '100%' }} onClick={() => handleSwitchDigitalCreatorMode(location)}>
          {user.selected_location?.freelancer_mode && (
            <CheckCircleFilled style={{ color: GLOBAL_THEME_COLOR.$highlight_color }} />
          )}
          Freelancer Mode{' '}
        </Button>
        {/* <ConnectInstagramButton /> */}
        {(user.no_licenses || 0) > 0 && <SubscriptionCancelButton />}
        <Button style={{ width: '100%', color: 'red' }} onClick={handleLogout}>
          <LogoutOutlined />
          Logout
        </Button>
      </Space>
    </div>
  );

  return (
    <Layout.Header
      style={{
        padding: '0px 40px',
        background: colorBgContainer,
        display: 'flex',
        height: 80,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div style={{ ...MIDDLE_STYLE }}>
        {!isTablet ? (
          <h2
            className="airbnb-font"
            style={{
              fontWeight: 'normal',
              fontSize: 20,
              color: GLOBAL_THEME_COLOR.$text_color,
            }}>
            {tabName === 'help-center' && 'Help Center'}
            {tabName === 'getting-started' && 'Getting Started'}
            {tabName === 'home' && 'Home Dashboard'}
            {tabName === 'integrations' && 'Integrations'}
            {(['studio', 'blogs'] as AppRoutes[]).includes(tabName) && (
              <span className="airbnb-font">Grow Online Presence</span>
            )}
            {(
              [
                'requestEmailSMS',
                'requestQrScanner',
                'manualSharing',
                'autoRequest',
                'reviews',
                'widget',
                'inquiries',
              ] as AppRoutes[]
            ).includes(tabName) && (
              <span className="airbnb-font">
                <StarOutlined style={{ marginRight: 20 }} />
                Grow Reputation
              </span>
            )}
            {tabName === 'studio-onboard' && 'Getting Started: Set up social studio'}
            {tabName === 'showcase-reviews-onboard' &&
              'Getting Started: Set up sharing reviews on social channels'}
          </h2>
        ) : (
          <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 40 }} />
        )}
      </div>
      <div style={{ ...MIDDLE_STYLE }}>
        {user ? (
          <React.Fragment>
            <div></div>
            <Popover content={hoverContent(user)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}>
                <img
                  referrerPolicy="no-referrer"
                  style={{ width: 35, borderRadius: 50 }}
                  src={user.picture}
                  alt={user.picture}
                />
                {!isTablet && (
                  <div style={{ marginLeft: 10 }}>
                    <h4>{`${user.given_name} ${user.family_name}`}</h4>
                  </div>
                )}
              </div>
            </Popover>
          </React.Fragment>
        ) : (
          <LoadingOutlined />
        )}
        {isTablet && (
          <MenuOutlined
            style={{ cursor: 'pointer', margin: '0px 0px 0px 20px' }}
            onClick={() =>
              dispatch(
                openDrawer({
                  drawerName: 'navigationDrawer',
                })
              )
            }
          />
        )}
      </div>
    </Layout.Header>
  );
};

export default AppBar;
