import { Button } from 'antd';
import React from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useAppDispatch, useBusinessState } from '@core/configureStore';
import { openDrawer } from '@core/features';
import { ConnectSquareIntegrationButton } from '..';

type Props = {
  style?: React.CSSProperties;
};

const BrowseSquareIntegrationPhotoButton = ({ style }: Props) => {
  const dispatch = useAppDispatch();
  const {
    integration: { square: squareIntegration },
  } = useBusinessState();
  const handleBrowseBusinessPhoto = () => {
    dispatch(
      openDrawer({
        drawerName: 'squareIntegrationPhotoBrowseDrawer',
      })
    );
  };

  return squareIntegration ? (
    <Button disabled={!squareIntegration} onClick={handleBrowseBusinessPhoto}>
      <div style={{ ...MIDDLE_STYLE, ...style }}>
        <img alt="Square" src="square-inc.png" width={20} height={20} />{' '}
        <span style={{ marginLeft: 10 }}>Browse Square Photo</span>
      </div>
    </Button>
  ) : (
    <ConnectSquareIntegrationButton />
  );
};

export default BrowseSquareIntegrationPhotoButton;
