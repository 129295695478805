import { getAmazelyGoogleUrl } from '@constants/auth';
import { MAX_GENERATED_POST_DESKTOP, MAX_GENERATED_POST_MOBILE } from '@constants/pitch-business';
import { isFacebookApp } from '@constants/social';
import { useBreakpoint, useGenerationDataStore, usePublicRouteService } from '@core/hooks';
import {
  BusinessSocialPost,
  GooglePlaceDetailData,
  GoogleSmartReview,
  PlaceData,
} from '@core/models';
import { publicRouteService } from '@services/service-register';
import { makeid } from '@utils/string.util';
import moment from 'moment';
import { useLocation } from 'react-router';
import { timeout } from '../../utils/date.util';

const GOOGLE_OAUTH_REDIRECT = process.env.REACT_APP_AMAZELY_GOOGLE_OAUTH_REDIRECT as string;

export const usePitchDispatcher = () => {
  const location = useLocation();
  const { isTablet } = useBreakpoint();
  const salePitchService = usePublicRouteService();
  const { businessData, setNearbyBusinesses, setGenerationData } = useGenerationDataStore();
  let from = ((location.state as any)?.from?.pathname as string) || '/';

  const handleActivate = async ({ facebookAppHandler }: { facebookAppHandler?: any }) => {
    try {
      await publicRouteService.sendSlackNotification(
        `${businessData?.name} [✅ Activate Clicked]`,
        businessData?.types?.[0] || ''
      );
    } catch (error) {
      console.log(error);
    }

    if (isFacebookApp() && facebookAppHandler) facebookAppHandler();
    else {
      window.location.href = getAmazelyGoogleUrl(from, GOOGLE_OAUTH_REDIRECT);
    }
  };

  const startCollectShowcaseReviews = async (
    selectedPlace: PlaceData,
    reviews: GoogleSmartReview[]
  ) => {
    const showcaseReviews: GoogleSmartReview[] = reviews;
    await timeout(1000);
    const showcaseReviewsSocialPosts: BusinessSocialPost[] = [];
    let index = 1;
    for (const review of showcaseReviews) {
      const content = await salePitchService.generateSocialPostContent({
        businessName: selectedPlace.name,
        businessIndustry: selectedPlace.types[0],
        topic: `Thank you ${review.author_name} for a ${review.rating} star review in one sentence. Keep it simple, no cringy.`,
      });
      showcaseReviewsSocialPosts.push({
        id: review.time.toString(),
        caption: content,
        photo: undefined as any,
        scheduledAt: moment()
          .add(index * 2, 'week')
          .unix(),
      });
      index++;
    }
    setGenerationData({
      showcaseReviews,
      showcaseReviewSocialPosts: showcaseReviewsSocialPosts,
    });
  };

  const startGenerateSocialPosts = async (
    selectedPlace: PlaceData,
    socialPosts: BusinessSocialPost[]
  ) => {
    const generated_captions: string[] = [];
    const currentSocialPosts = socialPosts;
    for (
      let socialPostCounter = 0;
      socialPostCounter < currentSocialPosts.length;
      socialPostCounter++
    ) {
      const content: string = await salePitchService.generateSocialPostContent({
        businessName: selectedPlace.name,
        businessIndustry:
          selectedPlace?.editorial_summary?.overview || selectedPlace.types.toString(),
        prevOutputs: generated_captions,
      });
      generated_captions.push(content);
      /** Update the list of social posts with generated captions */
      const updatedSocialPosts: BusinessSocialPost[] = [];
      let index = 0;
      for (const socialPost of currentSocialPosts) {
        if (index <= socialPostCounter) {
          updatedSocialPosts.push({
            ...socialPost,
            caption: generated_captions[index],
          });
        } else {
          updatedSocialPosts.push(socialPost);
        }
        index++;
      }
      setGenerationData({
        socialPosts: updatedSocialPosts,
      });
    }
  };

  const startRankingBusinessesInLocal = async (selectedPlace: PlaceData) => {
    let nearByBusinesses: PlaceData[] = [selectedPlace];
    nearByBusinesses.unshift({
      ...selectedPlace,
      user_ratings_total: selectedPlace.user_ratings_total + 62,
      rating: 4,
      place_id: '1',
    });
    nearByBusinesses.unshift({
      ...selectedPlace,
      user_ratings_total: selectedPlace.user_ratings_total + 97,
      rating: 4.2,
      place_id: '2',
    });
    nearByBusinesses.unshift({
      ...selectedPlace,
      user_ratings_total: selectedPlace.user_ratings_total + 150,
      rating: 4.5,
      place_id: '3',
    });
    nearByBusinesses.unshift({
      ...selectedPlace,
      user_ratings_total: selectedPlace.user_ratings_total + 212,
      rating: 4.7,
      place_id: '4',
    });
    setNearbyBusinesses(nearByBusinesses);
    await timeout(1000);
  };

  const buildSocialPostsFromPhotos = async (placeData: GooglePlaceDetailData) => {
    const socialPosts: BusinessSocialPost[] = [];
    const photoChunks = (placeData.result.photos || []).slice(
      0,
      isTablet ? MAX_GENERATED_POST_MOBILE : MAX_GENERATED_POST_DESKTOP
    );
    let index = 1;
    for (const photo of photoChunks) {
      const photoData = await salePitchService.getGooglePhotoData(
        photo.photo_reference,
        photo.width,
        photo.height
      );
      socialPosts.push({
        id: makeid(5),
        caption: '',
        scheduledAt: moment().add(index, 'week').unix(),
        photo: {
          data: `data:image/jpeg;base64,${photoData}`,
          description: '',
          tags: [],
        },
      });
      index++;
    }
    return socialPosts;
  };

  const notifySlack = async (placeData: GooglePlaceDetailData) => {
    try {
      let uniqueIdentifier = localStorage.getItem('uniqueIdentifier');
      if (!uniqueIdentifier) {
        const randomID = makeid(10);
        localStorage.setItem('uniqueIdentifier', randomID);
        uniqueIdentifier = localStorage.getItem('uniqueIdentifier') as string;
      }

      await publicRouteService.sendSlackNotification(
        `QRCodeGenerator | ${placeData.result.name} | ${placeData.result.formatted_address} | Source: ${document.referrer} | ID: ${uniqueIdentifier}`,
        placeData.result.types?.[0]
      );
    } catch (error) {
      console.log(error);
    }
  };
  return {
    startRankingBusinessesInLocal,
    startCollectShowcaseReviews,
    startGenerateSocialPosts,
    buildSocialPostsFromPhotos,
    notifySlack,
    handleActivate,
  };
};
