import LoadableContainer from '@components/LoadableContainer';
import { Empty, Space } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import React, { useMemo } from 'react';
import { AnimatedComponent, FacebookPostCard } from '@components/index';
import { useAuthState } from '@core/configureStore';
import { FacebookPost } from '@core/models';

interface Props {
  facebookPosts: FacebookPost[];
}

const FacebookPostGridViewTab: React.FC<Props> = ({ facebookPosts }) => {
  const { user } = useAuthState();
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);
  return (
    <LoadableContainer
      isLoading={facebookPosts.length === 0}
      loadComponent={
        <div style={{ ...MIDDLE_STYLE }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No page posts found</span>}
          />
        </div>
      }>
      <Space style={{ ...MIDDLE_STYLE, flexWrap: 'wrap', marginTop: 20 }}>
        <React.Fragment>
          {facebookPosts.map(facebookPost => (
            <AnimatedComponent.OpacityFadeInDiv delay={200}>
              <FacebookPostCard facebookPost={facebookPost} locationId={locationId as any} />
            </AnimatedComponent.OpacityFadeInDiv>
          ))}
        </React.Fragment>
      </Space>
    </LoadableContainer>
  );
};

export default FacebookPostGridViewTab;
