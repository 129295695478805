import { EmailSubscriptionType } from '@core/models/email';
import { TemplateSettingType } from '@core/models/social-post';
import { GooglePlaceDetailData } from './google-place';
import { Location } from './location';
import { BlogSetting } from './blog';

export enum LicenseType {
  Starter,
  Pro,
}
export enum OnboardingStage {
  SocialStudio = 'social-onboarding',
  CollectReviews = 'collect-reviews',
  BusinessBlogSite = 'business-blog-site',
  ShowcaseReviews = 'showcase-reviews',
  ShareReviewToSocial = 'share-review-to-social',
  AutomaticResponse = 'automatic-response',
  SocialAccountConnect = 'social-account-connect',
  SmartQrCode = 'smart-qr-code',
}

export type AccountLocation = {
  id: number;
  location_id: string;
  account_id: string;
  google_sub: string;
  email_subscription: Record<EmailSubscriptionType, boolean>;
};

export type AuthUser = {
  id: number;
  google_sub: number;
  email: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
  access_token: string;
  refresh_token: string;
  token_expiry_date: string | null;
  last_login_date: string;
  created_date: string | null;
  last_modified_date: string;
  no_licenses: number | null;
  subscription_end: string | null;
  subscription_renewable: boolean;
  selected_location: LocalLocation;
  selected_account_location: AccountLocation;
};

export type AccountContact = {
  given_name: string;
  family_name: string;
  email: string;
};

export enum DefendResponseType {
  NONE,
  AI_RESPONSE,
  RECTIFICATION,
}

export type AssistantProfile = {
  // list of cron expressions
  schedules: string[];
  // list of cron expressions for showcase reviews
  showcase_schedules: string[];
  content_word_limit?: number;
  tone_of_voice: string;
  autoSocialSuggestHashtag: boolean;
  min_number_of_media: number;
  max_number_of_media: number;
};

export type BlogAssistantProfile = {
  autoGeneratedBlogContent: boolean;
  autoShareToSocial: boolean;
  schedules: string[];
  tone_of_voice: string;
  keywords: string[];
  content_word_limit: number;
};

const DEFAULT_AI_PROFILE_TONE_OF_VOICE = 'Casual and Conversational';
const DEFAULT_SCHEDULE_CRON_TAB = '0 9 */1 * MON';
export const BlogAssistantProfileDefault: BlogAssistantProfile = {
  autoGeneratedBlogContent: false,
  autoShareToSocial: false,
  schedules: [DEFAULT_SCHEDULE_CRON_TAB],
  tone_of_voice: DEFAULT_AI_PROFILE_TONE_OF_VOICE,
  content_word_limit: 1000,
  keywords: [],
};

export enum TextLength {
  Short = 'Short',
  VeryShort = 'VeryShort',
  Long = 'Long',
  Medium = 'Medium',
}

export type ReviewResponderProfile = {
  response_length: TextLength;
  tone_of_voice: string;
};

export type StudioPilotSetting = {
  autoGeneratedShowcaseReviews: boolean;
  negativeReviewResponse?: string;
};

export type SubscriptionCreditUsage = {
  // A credits of number of social posts published manually
  manual_social_post_published: number;
  // credits of number of social post automatically published
  auto_social_post_published: number;
  // credits of number of review response approved
  review_response_approved: number;
  // credits of number of review request created & reviewed
  review_request_created: number;
  review_request_reviewed: number;
  // credits of number of review replied manually
  manual_review_replied: number;
  // credits of number of blog post published automatically
  auto_blog_post_published: number;
  // creadits of number of blog post manually published
  manual_blog_post_published: number;
};

export type RequestTemplate = {
  invitation_email: string;
  followup_email: string;
  invitation_sms: string;
  brand_logo?: string;
  brand_banner?: string;
};

export type LocalLocation = {
  id: string;
  location_id: string;
  title: string;
  full_address: string;
  primary_category: string;
  owner_contact: AccountContact;
  created_date: string | null;
  last_modified_date: string;
  auto_pilot_activated: boolean;
  auto_request_activated: boolean;
  defend_response_type: DefendResponseType;
  place_id: string;
  album_id: string;
  onboarding_stages?: OnboardingStage[];
  assistant_profile: AssistantProfile;
  business_description: string;
  blog_assistant_profile: BlogAssistantProfile;
  review_responder_profile: ReviewResponderProfile;
  studio_pilot_setting: StudioPilotSetting;
  review_template_setting: TemplateSettingType;
  timezone?: string;
  language: string;
  license_type: LicenseType;
  license_owner_id: number;
  subscription_credit_usage: SubscriptionCreditUsage;
  review_url?: string;
  website_url?: string;
  request_template?: RequestTemplate;
  freelancer_mode: boolean;
  slug: string | undefined;
  blog_setting: BlogSetting;
  suspended: boolean;
};

export type GoogleLocalLocation = LocalLocation & {
  placeResult: GooglePlaceDetailData;
  gmbLocation: Location;
};
