import { CalendarOutlined } from '@ant-design/icons';
import { US_DATE_LAYOUT } from '@utils/date.util';
import { Button, Space, TimePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';

export type DateTimeEditorPayload = {
  disabled?: boolean;
  date: number;
  time: {
    hour: number;
    minute: number;
  };
  handleSetScheduleTime: (value: dayjs.Dayjs | null, dateString: string | string[]) => void;
  handleSchedulePost: () => void;
};

const DateTimeEditor = ({
  disabled,
  date,
  time,
  handleSetScheduleTime,
  handleSchedulePost,
}: DateTimeEditorPayload) => {
  return (
    <Space
      style={{
        flexWrap: 'wrap',
      }}>
      <TimePicker
        disabled={disabled}
        use12Hours
        format="h:mm a"
        value={dayjs.unix(
          moment({
            hour: time.hour,
            minute: time.minute,
          }).unix()
        )}
        onChange={handleSetScheduleTime}
      />
      on
      <Button disabled={disabled} onClick={handleSchedulePost}>
        <React.Fragment>
          <CalendarOutlined /> {moment.unix(date).format(US_DATE_LAYOUT)}
        </React.Fragment>
      </Button>
    </Space>
  );
};

export default DateTimeEditor;
