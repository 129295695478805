import { PhotoImageDataItem } from '@core/features';
import {
  DefendResponseType,
  GooglePhotoAlbum,
  GoogleReview,
  Inquiry,
  LocalLocation,
  Review,
  ReviewRequest,
  OnboardingStage,
  AssistantProfile,
  ReviewResponderProfile,
  TemplateSettingType,
  PartialShowcasedReview,
  SubscriptionCreditUsage,
  BlogAssistantProfile,
  Location,
  ReviewSetting,
} from '@core/models';
import { Widget, WidgetConfig } from '@core/models/widget';
import axios from 'axios';

export type AskForReviewRequestPayload = {
  contact: string;
  content: string;
  phone?: string;
  followUpContent: string;
  smsContent: string;
  fullName: string;
  isDraft?: boolean;
};

export type UpsertAnonymousReviewPayload = {
  locationId: string;
  comment: string;
  fullName: string;
  emailAddress: string;
  rating: number;
};

export type UpdateLocationOwnerPayload = {
  ownerGivenName: string;
  ownerFamilyName: string;
  ownerEmail: string;
};

export type UpdateLocationRequestTemplatePayload = {
  invitationEmail: string;
  followupEmail: string;
  invitationSms: string;
  brandLogoPayload?: PhotoImageDataItem;
  brandBannerPayload?: PhotoImageDataItem;
};

export default class HeroApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}`;

  public async navigatePlayground() {
    try {
      const response = await axios.get(`${this.url}/auth/playground`, {
        withCredentials: false,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateResponse(reviewId: number, numberOfCompletions: number) {
    try {
      const response = await axios.post(
        `${this.url}/reviews/${reviewId}/generate`,
        {
          numberOfCompletions,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateInquiryResponse(reviewId: number) {
    try {
      const response = await axios.post(`${this.url}/reviews/${reviewId}/generateInquiry`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getCurrentUser() {
    try {
      const response = await axios.get(`${this.url}/users/me`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async assignLicenseToLocation(locationId: string) {
    try {
      const response = await axios.post(
        `${this.url}/users/license/${locationId}/assign`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async revokeLicenseFromLocation(locationId: string) {
    try {
      const response = await axios.post(
        `${this.url}/users/license/${locationId}/revoke`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async logout() {
    try {
      const response = await axios.get(`${this.url}/auth/logout`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async checkSupportSession() {
    try {
      const response = await axios.get(`${this.url}/auth/checkSupportSession`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getAllLocations(): Promise<Location> {
    try {
      const response = await axios.get(`${this.url}/business/locations`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getGoogleLocationProfile(
    accountId: string,
    locationName: string
  ): Promise<{ location: LocalLocation; place: any }> {
    try {
      const response = await axios.post(
        `${this.url}/business/googleLocations`,
        { accountId, locationName },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationById(locationId: string): Promise<LocalLocation> {
    try {
      const response = await axios.get(`${this.url}/location/${locationId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationByPlaceId(placeId: string): Promise<LocalLocation> {
    try {
      const response = await axios.get(`${this.url}/location/by-place-id/${placeId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationPlaceId(locationId: string) {
    try {
      const response = await axios.get(`${this.url}/location/${locationId}/placeId`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateAutoPilot(locationId: string, autoEngage: boolean, autoRequest: boolean) {
    try {
      const response = await axios.put(
        `${this.url}/location/${locationId}/pilot`,
        {
          autoEngage: autoEngage,
          autoRequest: autoRequest,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getGoogleReviewsByLocation(
    locationId: string,
    pageSize?: number,
    pageToken?: string,
    orderBy?: 'updateTime desc' | 'rating desc' | 'updateTime' | 'rating'
  ) {
    try {
      const response = await axios.get(
        `${this.url}/reviews/locations/${locationId}?pageSize=${pageSize || 10}&pageToken=${
          pageToken || ''
        }&orderBy=${orderBy}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getGoogleReviewById(locationId: string, reviewId: string) {
    try {
      const response = await axios.get(`${this.url}/reviews/locations/${locationId}/${reviewId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
  public async getLocalReviewByGoogleId(reviewId: string): Promise<Review> {
    try {
      const response = await axios.get(`${this.url}/reviews/${reviewId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async saveReview(review: GoogleReview | Review, existed: boolean) {
    try {
      const response = await axios.post(
        `${this.url}/reviews/save`,
        { review: review, existed: existed },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async saveReviewAnonymous({
    comment,
    emailAddress,
    fullName,
    locationId,
    rating,
  }: UpsertAnonymousReviewPayload) {
    const response = await axios.post(
      `${this.url}/reviews/locations/${locationId}/anonymous/save`,
      {
        comment,
        emailAddress,
        fullName,
        rating,
      }
    );
    return response.data;
  }

  public async getAllAnonymousReviews(locationId: string) {
    try {
      const response = await axios.post(`${this.url}/reviews/locations/${locationId}/anonymous/`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateOwner(locationId: string, payload: UpdateLocationOwnerPayload) {
    try {
      const response = await axios.post(`${this.url}/location/${locationId}/contact`, payload, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateRequestTemplate(
    locationId: string,
    payload: UpdateLocationRequestTemplatePayload
  ) {
    try {
      const response = await axios.post(
        `${this.url}/location/${locationId}/request-template`,
        payload,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLocation(locationId: string, locationUpdatePayload: Partial<LocalLocation>) {
    try {
      const response = await axios.put(
        `${this.url}/location/${locationId}`,
        locationUpdatePayload,
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateTimezone(locationId: string, timezone: string) {
    try {
      const response = await axios.post(
        `${this.url}/location/${locationId}/timezone`,
        { timezone },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLanguage(locationId: string, language: string) {
    try {
      const response = await axios.post(
        `${this.url}/location/${locationId}/language`,
        { language },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateBusinessDescription(locationId: string, businessDescription: string) {
    try {
      const response = await axios.post(
        `${this.url}/location/${locationId}/description`,
        { businessDescription },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateReviewUrl(locationId: string, reviewUrl: string) {
    try {
      const response = await axios.post(
        `${this.url}/location/${locationId}/reviewUrl`,
        { reviewUrl },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async openRequestEmail(reviewId: number) {
    try {
      const response = await axios.post(`${this.url}/requests/${reviewId}/open`, {});
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async respondRequest(requestId: number, rate: number, feedback: string | undefined) {
    try {
      const response = await axios.post(`${this.url}/requests/${requestId}/respond`, {
        rate,
        feedback,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async upsertReply(locationId: string, reviewId: string, message: string) {
    try {
      const response = await axios.post(
        `${this.url}/reviews/locations/${locationId}/${reviewId}/reply`,
        { message: message },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async checkIfEnoughGoodReviews() {
    try {
      const response = await axios.get(`${this.url}/reviews/check`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getReviewRequestById(requestId: number): Promise<ReviewRequest> {
    try {
      const response = await axios.get(`${this.url}/requests/${requestId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async deleteRequestById(requestId: number) {
    try {
      const response = await axios.delete(`${this.url}/requests/${requestId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getAllReviewRequests(locationId: string) {
    try {
      const response = await axios.get(`${this.url}/requests/locations/${locationId}/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async askForReviewRequest(locationId: string, payload: AskForReviewRequestPayload) {
    try {
      const response = await axios.post(`${this.url}/requests/locations/${locationId}/`, payload, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async resendEmail(requestId: number, requestType: string) {
    try {
      const response = await axios.post(
        `${this.url}/requests/${requestId}/resend/${requestType}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async publishDraft(requestId: number) {
    try {
      const response = await axios.post(
        `${this.url}/requests/${requestId}/publishDraft`,
        {},
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async requestAgain(requestId: number) {
    try {
      const response = await axios.post(
        `${this.url}/requests/${requestId}/requestAgain`,
        {},
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async selectLocation(accountId: string, locationName: string) {
    try {
      const response = await axios.post(
        `${this.url}/business/selectLocation`,
        { accountId, locationName },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async switchDigitalCreatorMode(businessName?: string | undefined) {
    try {
      const response = await axios.post(
        `${this.url}/business/switchDigitalCreatorMode`,
        {
          businessName,
        },
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getDigitalCreatorModeBusiness(): Promise<LocalLocation | undefined> {
    try {
      const response = await axios.get(`${this.url}/business/digitalCreatorMode`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getSelectedLocation() {
    try {
      const response = await axios.get(`${this.url}/location/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getAllLocalLocations(): Promise<LocalLocation[]> {
    try {
      const response = await axios.get(`${this.url}/location/all`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async saveWidget(locationId: string, reviews: GoogleReview[], config: WidgetConfig) {
    try {
      const response = await axios.post(
        `${this.url}/widget/save`,
        { location_id: locationId, reviews: JSON.stringify(reviews), config: config },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getWidgetByLocationID(locationId: string): Promise<Widget> {
    try {
      const response = await axios.get(`${this.url}/widget/${locationId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async taggingImage(
    dataImageURI: string
  ): Promise<{ tags: string[]; description: string }> {
    try {
      const response = await axios.post(`${this.url}/studio/image-captioning`, {
        dataImageURI,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getGooglePhotoAlbum(albumId: string): Promise<GooglePhotoAlbum> {
    try {
      const response = await axios.get(`${this.url}/studio/album/${albumId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationSquareMerchantInfo(): Promise<string | undefined> {
    try {
      const response = await axios.get(`${this.url}/location/square`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async unintegrateSquareFromLocation(): Promise<void | undefined> {
    try {
      const response = await axios.delete(`${this.url}/location/square/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationQuickBooksInfo(): Promise<string | undefined> {
    try {
      const response = await axios.get(`${this.url}/location/quickbooks`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async unintegrateQuickBooksFromLocation(): Promise<void | undefined> {
    try {
      const response = await axios.delete(`${this.url}/location/quickbooks/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationJobberInfo(): Promise<string | undefined> {
    try {
      const response = await axios.get(`${this.url}/location/jobber`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async unintegrateJobberFromLocation(): Promise<void | undefined> {
    try {
      const response = await axios.delete(`${this.url}/location/jobber/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationXeroInfo(): Promise<string | undefined> {
    try {
      const response = await axios.get(`${this.url}/location/xero`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async unintegrateXeroFromLocation(): Promise<void | undefined> {
    try {
      const response = await axios.delete(`${this.url}/location/xero/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async squareOauthLogin(): Promise<string> {
    try {
      const response = await axios.get(`${this.url}/auth/square/login`, {
        withCredentials: true,
      });
      return response.data || undefined;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  // Inquiry method
  public async updateDefendResponse(locationId: string, defendResponseType: DefendResponseType) {
    try {
      const response = await axios.put(
        `${this.url}/location/${locationId}/defend`,
        {
          defendResponseType,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async openInquiryEmail(inquiryId: string) {
    try {
      const response = await axios.post(`${this.url}/inquiry/${inquiryId}/open`, {});
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async respondInquiry(inquiryId: string, email: string, phone: string | undefined) {
    try {
      const response = await axios.post(`${this.url}/inquiry/${inquiryId}/respond`, {
        email,
        phone,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getInquiryById(inquiryId: string): Promise<Inquiry> {
    try {
      const response = await axios.get(`${this.url}/inquiry/${inquiryId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getAllInquiries(locationId: string) {
    try {
      const response = await axios.get(`${this.url}/inquiry/locations/${locationId}/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async batchCreateInquiries(locationId: string, reviewIds: string[]) {
    try {
      const response = await axios.post(
        `${this.url}/inquiry/locations/${locationId}/batchCreate`,
        { reviewIds },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async approveReviewResponse(googleReviewId: string) {
    try {
      const response = await axios.post(`${this.url}/reviews/reply/${googleReviewId}/approve`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async createCheckoutSession(noLicenses: number): Promise<string | undefined> {
    try {
      const response = await axios.post(
        `${this.url}/subscription/createCheckoutSession`,
        { noLicenses },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async createCustomerPortalSession(): Promise<string | undefined> {
    try {
      const response = await axios.post(
        `${this.url}/subscription/createCustomerPortalSession`,
        {},
        { withCredentials: true }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLocationOnboarding(stages: OnboardingStage[]) {
    try {
      const response = await axios.post(
        `${this.url}/location/onboard/`,
        { onboardingStages: stages },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLocationAssistantProfile(assistantProfile: Partial<AssistantProfile>) {
    try {
      const response = await axios.put(
        `${this.url}/location/assistant/`,
        { ...assistantProfile },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLocationBlogAssistantProfile(assistantProfile: Partial<BlogAssistantProfile>) {
    try {
      const response = await axios.put(
        `${this.url}/location/blog-assistant/`,
        { ...assistantProfile },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateLocationReviewResponder(
    reviewResponderProfile: Partial<ReviewResponderProfile>
  ) {
    try {
      const response = await axios.put(
        `${this.url}/location/responder/`,
        { ...reviewResponderProfile },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getOnceLocationGoogleReviews(locationId: string) {
    const result: any = await this.getGoogleReviewsByLocation(
      locationId,
      40,
      undefined,
      'updateTime'
    );
    return result?.reviews || [];
  }

  public async getLocationAllGoogleReviews(
    locationId: string,
    onReviewLoad?: (reviews: GoogleReview[]) => void,
    batchLimit?: number
  ) {
    let limit = batchLimit as number;
    let reviewInfo = undefined;
    let reviews: GoogleReview[] = [];
    let nextPageToken = undefined;
    while (batchLimit && batchLimit > 0 ? limit > 0 : true) {
      const result: any = await this.getGoogleReviewsByLocation(
        locationId,
        40,
        nextPageToken,
        'updateTime'
      );
      reviewInfo = result;
      nextPageToken = result.nextPageToken;
      if (result?.reviews) {
        onReviewLoad && onReviewLoad(result.reviews);
        reviews = reviews.concat(result?.reviews);
      }
      if (result.nextPageToken === undefined) break;
      if (limit) limit--;
    }
    reviewInfo.reviews = reviews;
    return reviewInfo;
  }

  public async retrieveReviewTemplates(review: PartialShowcasedReview): Promise<string[]> {
    try {
      const response = await axios.post(
        `${this.url}/reviews/templates`,
        { review },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async showcaseReviewWithTemplateId(
    review: PartialShowcasedReview,
    templateId: number
  ): Promise<string> {
    try {
      const response = await axios.post(`${this.url}/reviews/templates/${templateId}`, review, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateReviewTemplateSetting(reviewTemplateSetting: Partial<TemplateSettingType>) {
    try {
      const response = await axios.put(
        `${this.url}/location/review-template/`,
        { ...reviewTemplateSetting },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateCreditGroupUsage(
    locationId: string,
    groupName: keyof SubscriptionCreditUsage
  ) {
    try {
      const response = await axios.post(`${this.url}/location/increaseCreditUsage/${locationId}`, {
        groupName,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationReviewSetting(locationId: string): Promise<ReviewSetting | undefined> {
    try {
      const response = await axios.get<ReviewSetting>(
        `${this.url}/reviews/location/${locationId}/setting`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
