import { StarRating } from '@core/models';

export type Optional<T> = T | undefined | null;

export const starRatingToNumber = (starRating: StarRating): number =>
  parseInt(StarRating[starRating.valueOf()]);

export const starRatingFromNumber = (starRating: number): StarRating => {
  return [
    StarRating.STAR_RATING_UNSPECIFIED,
    StarRating.ONE,
    StarRating.TWO,
    StarRating.THREE,
    StarRating.FOUR,
    StarRating.FIVE,
  ][starRating];
};
