import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { StudioReducerProps } from '../type';
import { socialStudioApiService } from '@services/service-register';
import { StudioPhoto, StudioPhotoSource } from '@core/models';

export const photoIsInGallery = (source: StudioPhotoSource) => {
  return source === StudioPhotoSource.Image || source === StudioPhotoSource.SquareProductPhoto;
};

export const fetchGalleryImages = createAsyncThunk(
  'studio/fetchGalleryImages',
  async (_, thunkApi) => {
    try {
      const studioPhotos: StudioPhoto[] = await socialStudioApiService.getStudioPhotosByLocation();
      return studioPhotos.filter(photo => photoIsInGallery(photo.source));
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchGalleryImages);

export const fetchGalleryImagesReducer = {
  [fulfilled]: (state, action: PayloadAction<StudioPhoto[]>) => {
    state.studioPhotos = action.payload;
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.studioPhotos = [];
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<StudioReducerProps>;
