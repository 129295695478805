import { MIDDLE_STYLE } from '@constants/responsive';
import StatusBadge from '@components/StatusBadge';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { formatUtcDate } from '@utils/date.util';
import { shortenString } from '@utils/string.util';
import { Button, Col, Row, Skeleton, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useAppNavigation, useBreakpoint } from '@core/hooks';
import { BlogPostModel, BlogPostStage } from '@core/models';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SkeletonImage from 'antd/es/skeleton/Image';

interface Props {
  blogPost: BlogPostModel;
}

const BlogPostCard: React.FC<Props> = ({ blogPost }) => {
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const handleViewBlogPost = (blogPostId: string) => {
    navigate<string>(`blogs/${blogPostId}`);
  };

  const isReadonly = useMemo(() => blogPost.status === BlogPostStage.Pending, [blogPost]);

  return (
    <div
      onClick={() => !isReadonly && handleViewBlogPost(blogPost.id)}
      style={{ width: '100%', cursor: 'pointer', margin: '10px 0px' }}>
      <Row style={{ width: '100%', ...MIDDLE_STYLE }}>
        <Col span={isTablet ? 24 : 2} style={{ ...MIDDLE_STYLE }}>
          {blogPost.thumbnail.length > 0 ? (
            <LazyLoadImage
              loading="lazy"
              placeholder={<SkeletonImage active />}
              src={blogPost.thumbnail}
              style={{
                height: isTablet ? '200px' : '60px',
                width: '100%',
                objectFit: 'cover',
              }}
              alt={'thumbnail'}
            />
          ) : (
            <Skeleton.Image
              active
              style={{
                height: isTablet ? '200px' : '60px',
                width: isTablet ? 1000 : 100,
              }}
            />
          )}
        </Col>
        <Col
          span={isTablet ? 24 : 3}
          style={{
            ...MIDDLE_STYLE,
            margin: isTablet ? '20px 0px' : '0px',
            justifyContent: isTablet ? 'flex-start' : 'center',
          }}>
          <StatusBadge status={blogPost.status} />
        </Col>
        <Col span={isTablet ? 24 : 16}>
          <div style={{ maxWidth: 1000 }}>
            <Tooltip title={blogPost.title}>
              <h3
                style={{
                  fontSize: '13px',
                  fontWeight: 600,
                }}>
                {shortenString(blogPost.title, 100)}
              </h3>
            </Tooltip>
            <p
              style={{
                marginTop: 5,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                fontSize: '11px',
              }}>
              <span style={{ fontWeight: 'bold' }}>
                {blogPost.publish_at
                  ? `Published at ${formatUtcDate(blogPost.publish_at)}`
                  : `Updated at ${formatUtcDate(blogPost.updated_date)}`}
              </span>{' '}
              &#x2022;
              <span style={{ marginLeft: 5 }}>
                {shortenString(blogPost.subtitle || 'No subtitle', 200)}
              </span>
            </p>
          </div>
        </Col>
        <Col span={isTablet ? 24 : 3}>
          {!isReadonly && (
            <div
              style={{
                ...MIDDLE_STYLE,
                width: '100%',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}>
              <div style={{ display: 'flex', flexWrap: isTablet ? 'wrap' : 'unset' }}></div>
              {!isTablet ? (
                <Button onClick={() => handleViewBlogPost(blogPost.id)}>
                  <EditOutlined />
                </Button>
              ) : (
                <Button
                  style={{ width: '100%', margin: '20px 0px' }}
                  onClick={() => handleViewBlogPost(blogPost.id)}>
                  <EditOutlined /> Edit blog post
                </Button>
              )}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BlogPostCard;
