import React from 'react';
import UploadImageModal from '@components/UploadImageModal';
import { PhotoImageDataItem, openModal } from '@core/features';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { TbPhoto } from 'react-icons/tb';
import { useAppDispatch } from '@core/configureStore';

const UploadModalInstanceName = 'BrandBannerUploadModal';

interface Props {
  mediaItems: PhotoImageDataItem[];
  onBannerUploaded: (photoImageDataItem: PhotoImageDataItem) => void;
  selectPhotoDisabled?: boolean;
}

const UploadBrandBannerButton: React.FC<Props> = ({
  mediaItems,
  onBannerUploaded,
  selectPhotoDisabled,
}) => {
  const dispatch = useAppDispatch();
  const handleSelectPhotoFromGallery = () => {
    if (selectPhotoDisabled) return;
    dispatch(
      openModal({
        modalName: 'uploadImageModal',
        extraParams: {
          forComponent: UploadModalInstanceName,
        },
      })
    );
  };

  return (
    <React.Fragment>
      {mediaItems.length > 0 ? (
        <React.Fragment>
          <div
            onClick={handleSelectPhotoFromGallery}
            style={{
              backgroundImage: `url(${mediaItems[0].url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: 200,
              width: '100%',
            }}
          />
        </React.Fragment>
      ) : (
        <div
          onClick={handleSelectPhotoFromGallery}
          style={{
            ...MIDDLE_STYLE,
            width: '100%',
            maxHeight: 100,
            padding: '20px 0px',
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            cursor: 'pointer',
          }}>
          <TbPhoto style={{ fontSize: 30, marginRight: 10 }} />
          <div style={{ fontSize: 15 }}>Click to upload a banner for your brand</div>
        </div>
      )}
      <UploadImageModal
        actionTitle="Upload brand banner"
        forComponent={UploadModalInstanceName}
        maxNumberOfPhotos={1}
        singleFile
        existingMedias={mediaItems}
        onUpload={([photoImageItem]) => {
          onBannerUploaded(photoImageItem);
        }}
        disabledSquarePhotoSource={true}
      />
    </React.Fragment>
  );
};

export default UploadBrandBannerButton;
