import { EditOutlined } from '@ant-design/icons';
import WrapOrNotcontainer from '@components/WrapOrNotContainer';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { shortenString } from '@utils/string.util';
import { Popover } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const AnnotatedImageItemCard = ({
  url,
  description,
  style,
}: {
  url: string;
  description: string | undefined;
  style?: React.CSSProperties;
}) => {
  return (
    <WrapOrNotcontainer
      wrappedCondition={!!description}
      wrapper={children => (
        <Popover
          title={'Image Annotation'}
          content={<p style={{ maxWidth: 400 }}>{description}</p>}>
          {children}
        </Popover>
      )}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <LazyLoadImage
          loading="lazy"
          className="card--image"
          src={url}
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover',
            ...style,
          }}
        />
        {!!description && (
          <div
            style={{
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
            }}>
            <div
              style={{
                backgroundColor: GLOBAL_THEME_COLOR.$background_color,
                width: '100%',
                border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                padding: '10px 10px',
                fontSize: 'smaller',
                ...MIDDLE_STYLE,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}>
              <span
                style={{
                  fontWeight: 'bold',
                  marginRight: 5,
                  color: GLOBAL_THEME_COLOR.$highlight_color,
                }}>
                <EditOutlined /> Annotation
              </span>
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                {description.length > 0
                  ? shortenString(description, 100)
                  : 'No Annotation. Click to annotate.'}
              </span>
            </div>
          </div>
        )}
      </div>
    </WrapOrNotcontainer>
  );
};
