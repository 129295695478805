import { PhotoImageDataItem } from '@core/features';
import { SocialPostType, StudioPhotoCollections } from '@core/models';
import {
  MonthlyGenerateParams,
  StreamApiMethod,
  callStreamApi,
} from '@services/stream-api.service';

const BACKEND_URL = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/studio`;

export type StreamInsertSocialPostsParams = {
  caption: string;
  fileImageItems: PhotoImageDataItem[];
  scheduledAt: number;
  type: SocialPostType;
  topic?: string;
}[];

export type MonthlyGenerateSocialPostsParams = MonthlyGenerateParams & {
  studioPhotoCollections: StudioPhotoCollections;
};

export default class SocialStudioStreamService {
  public streamUploadImagesToS3Bucket: StreamApiMethod<PhotoImageDataItem[]> = (
    fileImageItems,
    abortSignal
  ) => {
    return callStreamApi(`${BACKEND_URL}/photos/stream`, 'POST', { fileImageItems }, abortSignal);
  };

  public streamImportSquarePhotosToS3Bucket: StreamApiMethod<PhotoImageDataItem[]> = (
    _,
    abortSignal
  ) => {
    return callStreamApi(`${BACKEND_URL}/square/photos/stream`, 'POST', {}, abortSignal);
  };

  public streamInsertSocialPosts: StreamApiMethod<StreamInsertSocialPostsParams> = (
    payload,
    abortSignal
  ) => {
    return callStreamApi(
      `${BACKEND_URL}/posts/batch-insert/stream`,
      'POST',
      {
        socialPostPayload: payload,
      },
      abortSignal
    );
  };

  public streamGenerateSocialPosts: StreamApiMethod<MonthlyGenerateSocialPostsParams> = (
    params,
    abortSignal
  ) => {
    return callStreamApi(`${BACKEND_URL}/posts/batch-generate/stream`, 'POST', params, abortSignal);
  };

  public streamGenerateShowcaseReviews: StreamApiMethod<MonthlyGenerateParams> = (
    params,
    abortSignal
  ) => {
    return callStreamApi(
      `${BACKEND_URL}/reviews/batch-generate/stream`,
      'POST',
      params,
      abortSignal
    );
  };

  public streamApplyTemplateToShowcaseReviewPosts: StreamApiMethod<{ exludedIds: string[] }> = (
    params,
    abortSignal
  ) => {
    return callStreamApi(`${BACKEND_URL}/reviews/batch-apply/stream`, 'POST', params, abortSignal);
  };
}
