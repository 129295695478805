import React from 'react';
import { useAppDispatch, useDrawerState } from '@core/configureStore';
import { closeDrawer } from '@core/features';
import { Drawer } from 'antd';
import { LeftSiderInner } from '@components/LeftSider';

type Props = {};

const NavigationDrawer = (props: Props) => {
  const dispatch = useAppDispatch();
  const { navigationDrawer } = useDrawerState();
  return (
    <Drawer
      onClose={() =>
        dispatch(
          closeDrawer({
            drawerName: 'navigationDrawer',
          })
        )
      }
      open={navigationDrawer}>
      <LeftSiderInner />
    </Drawer>
  );
};

export default NavigationDrawer;
