import React from 'react';
import GoogleMapReact from 'google-map-react';
import LoadableContainer from '@components/LoadableContainer';
import { Button, notification, Skeleton } from 'antd';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { PlaceData } from '@core/models';
import GoogleMapLocationItem from '@components/GoogleMapLocationItem';
import GoogleMapLocationMaker from '@components/GoogleMapLocationMarker';
import { shortenString } from '@utils/string.util';
import { MIDDLE_STYLE } from '@constants/responsive';
import { SendOutlined } from '@ant-design/icons/lib';
import { BRAND_NAME } from '@constants/brand';
import { useLocation } from 'react-router';

type Props = {
  latitude: number;
  longtitude: number;
  businessData: PlaceData;
  nearbyBusinesses: PlaceData[];
};

const GOOGLE_MAP_API_KEY = 'AIzaSyAYHEWqGrICZyXKvxOL6T4rTSFxo3vFVwU';

const GoogleMapRenderer: React.FC<Props> = ({
  nearbyBusinesses,
  businessData,
  latitude,
  longtitude,
}: Props) => {
  const location = useLocation();
  const { isMobile } = useBreakpoint();
  const defaultProps = {
    zoom: 14,
    center: {
      lat: latitude,
      lng: longtitude,
    },
  };

  const [api, contextHolder] = notification.useNotification();

  const notify = () => {
    api.open({
      message: 'Please sign in to setup your business first.',
    });
  };
  return (
    <div style={{ height: 600, width: '100vw', position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: 1,
          top: 0,
          right: isMobile ? -70 : 50,
          bottom: 0,
          margin: 'auto 0px',
          borderRadius: 30,
          boxShadow: STRIPE_BOX_SHADOW,
          height: 'fit-content',
        }}>
        <GoogleMapLocationItem
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            backgroundColor: GLOBAL_THEME_COLOR.$highlight_color,
            color: GLOBAL_THEME_COLOR.$text_color_contrast,
            borderRadius: '10px',
            fontWeight: 'bold',
            border: 'none',
            scale: '1.1',
            margin: '10px 0px ',
            paddingRight: 150,
          }}
          totalRating={(businessData.user_ratings_total || 0) + 350}
          name={shortenString(businessData.name, 60)}
          rating={5}
        />
        {nearbyBusinesses.map(nearbyBusiness => (
          <div style={{ position: 'relative' }}>
            <GoogleMapLocationItem
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                ...(nearbyBusiness.place_id === businessData.place_id &&
                  ({
                    backgroundColor: GLOBAL_THEME_COLOR.$dark_text_color,
                    borderRadius: 'none',
                    color: 'white',
                  } as React.CSSProperties)),
              }}
              totalRating={nearbyBusiness.user_ratings_total || 0}
              name={
                nearbyBusiness.place_id === businessData.place_id ? (
                  shortenString(nearbyBusiness.name, 60)
                ) : (
                  <Skeleton.Input style={{ height: '10px', width: '100%' }} />
                )
              }
              rating={nearbyBusiness.rating || 0}>
              {nearbyBusiness.place_id === businessData.place_id && (
                <img
                  src={'/with-amazely.png'}
                  alt={BRAND_NAME}
                  style={{ width: 60, marginTop: -350, marginLeft: 20 }}
                />
              )}
            </GoogleMapLocationItem>
          </div>
        ))}
      </div>
      <LoadableContainer isLoading={nearbyBusinesses.length === 0} loadComponent={<Skeleton />}>
        <GoogleMapReact
          options={{
            zoomControl: false,
          }}
          defaultZoom={defaultProps.zoom}
          defaultCenter={defaultProps.center}
          draggable={false}
          bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}>
          {nearbyBusinesses.map(business => (
            <GoogleMapLocationMaker
              isSpecial={business.name === businessData.name}
              lat={business.geometry.location.lat}
              lng={business.geometry.location.lng}>
              {business.name === businessData.name && (
                <GoogleMapLocationItem
                  style={{
                    boxShadow: STRIPE_BOX_SHADOW,
                  }}
                  totalRating={businessData.user_ratings_total || 0}
                  name={businessData.name}
                  rating={businessData.rating || 0}
                />
              )}
            </GoogleMapLocationMaker>
          ))}
        </GoogleMapReact>
      </LoadableContainer>
      {contextHolder}
      <Button
        onClick={notify}
        size="large"
        type="primary"
        style={{
          margin: 'auto',
          width: 250,
          backgroundColor: 'rgb(78, 56, 246)',
          marginTop: -64,
          ...MIDDLE_STYLE,
        }}>
        <SendOutlined />
        Enable review collection
      </Button>
    </div>
  );
};

export default GoogleMapRenderer;
