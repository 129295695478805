import React, { useMemo } from 'react';
import { Button, Col, Divider, Modal, Row, Table, Tag } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import UploadFileDropzone from '../UploadFileDropzone';
import { CSVLink } from 'react-csv';
import { handleBulkRequestFile } from '@utils/file.util';
import { closeModal, enqueueNotification } from '@core/features';
import { useAppDispatch, useModalState } from '@core/configureStore';

export type BulkRequestCsvInfo = {
  index: number;
  fullName: string;
  emailAddress: string;
  phoneCode: string;
  phoneNumber: string;
};

const actionSpanStyle: React.CSSProperties = {
  fontWeight: 'bold',
  cursor: 'pointer',
  color: '#2474ff',
};

const columns = [
  {
    title: 'Index',
    dataIndex: 'index',
    width: 100,
    key: 'index',
  },
  {
    title: 'Email',
    dataIndex: 'emailAddress',
    key: 'emailAddress',
  },
  {
    title: 'Full name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Phone code',
    dataIndex: 'phoneCode',
    key: 'phoneCode',
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
];

const UploadCSVModal = () => {
  const dispatch = useAppDispatch();
  const { uploadBulkRequestCsvModal, extraParams } = useModalState();
  const [bulkRequests, setBulkRequests] = React.useState<BulkRequestCsvInfo[]>([]);

  const handleUploadCsv = () => {
    // Handle upload CSV here
    extraParams.setRequests(bulkRequests);
    handleCloseCsvModal();
  };

  const handleLoadCsvData = async (data: string) => {
    try {
      const bulkRequests = handleBulkRequestFile(data);
      if (bulkRequests.length === 0) {
        await dispatch(
          enqueueNotification({
            name: 'Reading file process failed',
            description: 'There is no record of contacts found in the uploaded CSV file',
            type: 'Error',
          })
        );
      } else {
        setBulkRequests(bulkRequests);
      }
    } catch (error) {
      await dispatch(
        enqueueNotification({
          name: 'Invalid CSV file structure',
          description: 'Please upload a valid structure CSV file',
          type: 'Error',
        })
      );
    }
  };

  const handleCloseCsvModal = () => {
    handleCloseModal();
    setTimeout(() => {
      setBulkRequests([]);
    }, 500);
  };

  const handleCloseModal = async () => {
    await dispatch(
      closeModal({
        modalName: 'uploadBulkRequestCsvModal',
      })
    );
  };

  const validUpload = useMemo(() => bulkRequests.length > 0, [bulkRequests]);

  return (
    <React.Fragment>
      <Modal
        width={validUpload ? 1400 : 600}
        title={<div className="iconAndText">Upload CSV</div>}
        visible={uploadBulkRequestCsvModal}
        onCancel={handleCloseModal}
        footer={[
          <Button onClick={handleCloseCsvModal}>Cancel</Button>,
          <Button
            disabled={!validUpload}
            type="primary"
            htmlType="submit"
            onClick={handleUploadCsv}>
            <UploadOutlined /> Upload
          </Button>,
        ]}>
        <Row gutter={24}>
          <Col span={validUpload ? 10 : 24}>
            <div>
              Upload an existing CSV file to create review requests to a number of contacts
              simultaneously. The CSV must be in the following structure:
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px' }}>
              {['Email Address', 'Name', 'Phone code', 'Phone number'].map(field => (
                <Tag style={{ marginRight: 10 }}>{field}</Tag>
              ))}
            </div>
            <div>
              <CSVLink
                filename={'review-circle-request-template.csv'}
                style={actionSpanStyle}
                data={[
                  ['Email Address', 'Name', 'Phone code (Optional)', 'Phone number (Optional)'],
                  ['exampleA@gmail.com', 'Kevin', '', ''],
                  ['exampleB@gmail.com', 'Andy', '+1', '234 567 890'],
                  ['exampleC@gmail.com', 'Jeremy', ''],
                ]}>
                Download a Template CSV File
              </CSVLink>
            </div>
            <UploadFileDropzone
              onLoadFileResult={handleLoadCsvData}
              title={
                <div>
                  <span style={actionSpanStyle}>Select a CSV file</span> to upload or drag and drop
                  it here
                </div>
              }
            />
          </Col>
          {validUpload && (
            <React.Fragment>
              <Col span={1}>
                <Divider type="vertical" style={{ height: '100%' }} />
              </Col>
              <Col span={13}>
                Preview data of{' '}
                <span style={{ fontWeight: 'bold' }}>{bulkRequests.length} requests</span>
                <Table
                  style={{ marginTop: 20 }}
                  pagination={false}
                  scroll={{ y: 300 }}
                  dataSource={bulkRequests}
                  columns={columns}
                />
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default UploadCSVModal;
