import { CalendarOutlined, SyncOutlined } from '@ant-design/icons';
import { useModalState } from '@core/configureStore';
import { closeModal, openModal } from '@core/features';
import { Button, Divider, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import NumericInput from '@components/NumericInput';
import { RecurringType } from '@utils/social-studio.util';

type Props = {};

const MIDDLE_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const CalendarItemDetailModal = (props: Props) => {
  const dispatch = useDispatch();
  const [eventName, setEventName] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs.default());
  const [recurringValue, setRecurringValue] = useState<string>('1');
  const [recurringType, setRecurringType] = useState<RecurringType>(RecurringType.Week);
  const { calendarItemDetailModal } = useModalState();

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'calendarItemDetailModal',
      })
    );
  };

  const handleSelectStartFrom = () => {
    dispatch(
      openModal({
        modalName: 'calendarModal',
        extraParams: {
          onSelected: (selectedDate: dayjs.Dayjs) => {
            setSelectedDate(selectedDate);
          },
          title: 'Event start from',
        },
      })
    );
  };

  const handleCreateEvent = () => {
    handleCloseModal();
  };

  return (
    <React.Fragment>
      <Modal
        title="Event"
        centered
        open={calendarItemDetailModal}
        closable
        onCancel={handleCloseModal}
        destroyOnClose
        footer={[
          <Button onClick={handleCloseModal}>Cancel</Button>,
          <Button onClick={handleCreateEvent} type="primary" disabled={eventName === ''}>
            Create event
          </Button>,
        ]}
        style={{ minWidth: '400px', maxWidth: '700px' }}
        width={'100%'}>
        <Divider />
        <h4>Event series name</h4>
        <Input
          value={eventName}
          onChange={e => setEventName(e.target.value)}
          style={{ marginTop: 10 }}
          placeholder="Enter event name"></Input>
        <Divider />
        <div style={{ ...MIDDLE_STYLE }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CalendarOutlined style={{ fontSize: 20, marginRight: 20 }} />
            <div>
              <h4>Start from</h4>
              <p style={{ color: 'gray' }}>When new event in the series created</p>
            </div>
          </div>
          <Button onClick={handleSelectStartFrom}>{selectedDate.format('DD/MM/YYYY')}</Button>
        </div>
        <div
          style={{
            ...MIDDLE_STYLE,
            marginTop: 10,
          }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SyncOutlined style={{ fontSize: 20, marginRight: 20 }} />
              <div>
                <h4>New post every</h4>
                <p style={{ color: 'gray' }}>Schedule to auto upload a social post</p>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <NumericInput
              style={{ width: 80 }}
              prefixIcon={<></>}
              status={''}
              onChange={value => setRecurringValue(value)}
              placeholder={'N/A'}
              value={recurringValue}
            />
            <Select
              size="large"
              style={{ marginLeft: 10 }}
              onChange={(value: RecurringType) => setRecurringType(value)}
              defaultValue={recurringType}
              value={recurringType}>
              {Object.values(RecurringType).map((type, index) => (
                <Select.Option key={type} value={Object.keys(RecurringType)[index]}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <Divider />
      </Modal>
    </React.Fragment>
  );
};

export default CalendarItemDetailModal;
