/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { message } from 'antd';
import { useAppDispatch, useSnackbarState } from '@core/configureStore';
import { dequeueNotification } from '@core/features';
import { delay } from '@utils/date.util';

type Props = {
  children: React.ReactElement;
};

const GLOBAL_SNACKBAR_DELAY = 300;

const SnackbarWrapper = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { front, lastModifiedDate } = useSnackbarState();
  const [api, contextHolder] = message.useMessage();

  useEffect(() => {
    const handleNotification = async () => {
      dispatch(dequeueNotification({}));
      await delay(GLOBAL_SNACKBAR_DELAY);
    };
    handleNotification();
  }, [lastModifiedDate]);

  useEffect(() => {
    if (!!front) {
      let method = api.open;
      switch (front.type) {
        case 'Error':
          method = api.error;
          break;
        case 'Info':
          method = api.info;
          break;
        case 'Success':
          method = api.success;
          break;
      }
      method({
        content: front.name,
      });
    }
  }, [front]);

  return (
    <React.Fragment>
      {contextHolder}
      {children}
    </React.Fragment>
  );
};

export default SnackbarWrapper;
