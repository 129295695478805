import { MIDDLE_STYLE } from '@constants/responsive';
import { Button, Divider, Input, Tooltip } from 'antd';
import { ExpandableSuggestionContainer, LoadableContainer } from '@components/index';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { LoadingOutlined } from '@ant-design/icons';
import { BiRefresh } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import { publicRouteService } from '@services/service-register';
import { useAuthState } from '@core/configureStore';
import { SocialPost, SocialPostStage } from '@core/models';
import { cloneArray } from '@utils/array.util';

interface Props {
  topic: string;
  loading: boolean;
  onTopicChanged: (topic: string) => void;
  existingSocialPost: SocialPost | undefined;
  onGenerateButtonClicked: () => void;
}

const MAX_VISIBLE_TOPIC_SUGGESTIONS = 3;

const DEFAULT_TOPIC_SUGGESTIONS = [
  'Introduce your business',
  'Congrats special event',
  'Throwback a memorable moment',
  'Express gratitude to the customers',
];

const SocialPostTopicEditor: React.FC<Props> = ({
  existingSocialPost,
  loading,
  onGenerateButtonClicked,
  topic,
  onTopicChanged,
}) => {
  const { user } = useAuthState();
  const [generatingTopicSuggestions, setGeneratingTopicSuggestions] = useState<boolean>(true);
  const [topicSuggestions, setTopicSuggestions] = useState<string[]>(DEFAULT_TOPIC_SUGGESTIONS);

  const generateTopicSuggestions = async () => {
    try {
      setGeneratingTopicSuggestions(true);
      if (user && user?.selected_location) {
        const promptTopicSuggestions = (numberOfTopics: number, industry: string) =>
          `Generate ${numberOfTopics} social post topics for the ${industry} industry in a marketing way that is easy to read and can get the attention of the readers. Please return the output in a string array ["topic 1", "topic 2", "topic 3",...] of topic titles. Only return the string array without redundant information. Don't include the number index like "1.", "2."`;

        const response = await publicRouteService.askGPT(
          promptTopicSuggestions(7, user?.selected_location.primary_category as any)
        );
        const topics: string[] = JSON.parse(response);
        setTopicSuggestions(cloneArray(topics));
      }
    } catch (e) {
      setTopicSuggestions(DEFAULT_TOPIC_SUGGESTIONS);
    }
    setGeneratingTopicSuggestions(false);
  };

  useEffect(() => {
    if (existingSocialPost?.status !== SocialPostStage.Published) {
      generateTopicSuggestions();
    }
  }, [existingSocialPost?.status]);

  return (
    <div>
      <div style={{ ...MIDDLE_STYLE, marginBottom: 20 }}>
        <Input
          placeholder="What is the social post about?"
          value={topic}
          onChange={e => onTopicChanged(e.target.value)}></Input>
        <Button
          onClick={onGenerateButtonClicked}
          type="primary"
          loading={loading}
          style={{ marginLeft: 10 }}>
          Generate ✨
        </Button>
      </div>
      <LoadableContainer
        isLoading={generatingTopicSuggestions}
        loadComponent={
          <div style={{ ...MIDDLE_STYLE, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            <LoadingOutlined style={{ marginRight: 10 }} />
            Suggesting topics for your social post...
          </div>
        }>
        <div
          style={{
            padding: '10px 15px',
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            borderRadius: 10,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
          }}>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <h4 style={{ fontSize: 'smaller' }}>⚡️ Topic suggestions</h4>
            <Tooltip title={'Re-generate suggestions'}>
              <BiRefresh style={{ cursor: 'pointer' }} onClick={generateTopicSuggestions} />
            </Tooltip>
          </div>
          <div style={{ margin: '10px 0px 0px 0px' }}>
            <ExpandableSuggestionContainer
              limit={MAX_VISIBLE_TOPIC_SUGGESTIONS}
              topics={topicSuggestions}
              onTopicClicked={onTopicChanged}
            />
          </div>
        </div>
      </LoadableContainer>
      <Divider />
    </div>
  );
};

export default SocialPostTopicEditor;
