import React, { useEffect, useMemo } from 'react';
import { Alert, Progress, Space, Tabs, TabsProps } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useAppDispatch, useAuthState, useBlogState } from '@core/configureStore';
import {
  BlogPostSettingTab,
  BlogPostTab,
  CardSectionHeader,
  LoadableContainer,
  SubscriptionActivateButton,
} from '@components/index';
import { fetchLocationBlogPosts } from '@core/features/blog/thunks/fetchLocationBlogPosts.thunk';
import { BlogPageTabKey, LocalLocation } from '@core/models';
import { useBlogPageTourStore } from '@core/hooks/useBlogPageTourStore';
import { TbStack3 } from 'react-icons/tb';
import SplashScreen from '@components/SplashScreen';
import { openModal } from '@core/features';
import { useBlogOnboardingState, useSubscriptionDispatcher } from '@core/hooks';
import { getJsonData } from '@utils/cache.util';

const BlogPostsScreen: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useBlogState();
  const { user } = useAuthState();
  const {
    finishedOnboardingStages,
    maxOnboardingStages,
    isCheckingOnboardingState,
    belowOnboardingStages,
  } = useBlogOnboardingState();
  const { blogPosts } = useBlogState();
  const { selectedTabKey, setSelectedTabKey } = useBlogPageTourStore();
  const { checkBlogStudioNotActivated } = useSubscriptionDispatcher();
  const location = useMemo<LocalLocation | undefined>(() => user?.selected_location, [user]);

  const handleOnTabChanged = (key: string) => {
    setSelectedTabKey(key as any);
  };

  const handleSetupClicked = () => {
    dispatch(
      openModal({
        modalName: 'blogOnboardingModal',
      })
    );
  };

  const tabs: TabsProps['items'] = [
    {
      key: BlogPageTabKey.BlogPosts,
      label: (
        <div style={{ ...MIDDLE_STYLE }}>
          <TbStack3 style={{ marginRight: 10 }} /> Posts
        </div>
      ),
      children: <BlogPostTab />,
    },
    {
      key: BlogPageTabKey.Setting,
      label: (
        <div style={{ ...MIDDLE_STYLE }}>
          <SettingOutlined style={{ marginRight: 10 }} /> Embed Setting
        </div>
      ),
      children: <BlogPostSettingTab />,
    },
  ];

  useEffect(() => {
    dispatch(fetchLocationBlogPosts());
  }, []);

  useEffect(() => {
    if (
      !isCheckingOnboardingState &&
      !!location &&
      belowOnboardingStages &&
      !getJsonData<boolean>(`${location.location_id}:blog-onboarding`)
    ) {
      dispatch(
        openModal({
          modalName: 'blogOnboardingModal',
        })
      );
    }
  }, [belowOnboardingStages, location, isCheckingOnboardingState]);

  return (
    <React.Fragment>
      <div className="screen-card">
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            marginBottom: 20,
            flexWrap: 'wrap',
          }}>
          <h2 className="airbnb-font">Business Blogs</h2>
          <Space onClick={handleSetupClicked} style={{ cursor: 'pointer' }}>
            <CardSectionHeader title="Setup"></CardSectionHeader>
            <Progress
              type="line"
              percent={(finishedOnboardingStages / maxOnboardingStages) * 100}
              steps={maxOnboardingStages}
              trailColor="rgba(0, 0, 0, 0.06)"
              strokeWidth={10}
            />
          </Space>
        </div>
        {checkBlogStudioNotActivated(blogPosts) && (
          <Alert
            showIcon
            type="warning"
            message={
              <span style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                You're almost there! Activate the free trial before Amazely can publish your blogs
                <SubscriptionActivateButton />
              </span>
            }
          />
        )}
        <LoadableContainer isLoading={isLoading} loadComponent={<SplashScreen />}>
          <Tabs
            defaultActiveKey={selectedTabKey}
            activeKey={selectedTabKey}
            items={tabs}
            onChange={handleOnTabChanged}
          />
        </LoadableContainer>
      </div>
    </React.Fragment>
  );
};

export default BlogPostsScreen;
