/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { useAppDispatch } from '@core/configureStore';
import { openRequestEmail, respondRequest } from '@core/features/review-request/thunks';
import { useParams } from 'react-router';
import { LocalLocation } from '@core/models';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';
import { LoadingOutlined } from '@ant-design/icons';
import { heroApiService } from '@services/service-register';
import { usePublicRouteService } from '@core/hooks';
import ReviewSubmissionForm from '@components/ReviewSubmissionForm';
import ReviewScreenLayout from '@components/ReviewScreenLayout';

type Props = {};

const RequestCallbackScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState<LocalLocation | null>(null);
  const publicRouteService = usePublicRouteService();
  const [isFetchingRequest, setIsFetchingRequest] = useState<boolean>(false);
  const [isFetchingLocation, setIsFetchingLocation] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<string | null>(null);
  const { requestId } = useParams<{ requestId: string }>();
  const [placeId, setPlaceId] = useState('');
  const [reviewUrl, setReviewUrl] = useState('');

  const handleOnReviewSubmitted = async ({
    feedback,
    selectedRate,
  }: {
    feedback: string;
    selectedRate: number;
  }) => {
    if (!requestId) throw new Error('No request ID found');
    await dispatch(
      respondRequest({
        requestId: parseInt(requestId),
        rate: selectedRate,
        feedback,
      })
    );
  };

  useEffect(() => {
    const loadRequest = async () => {
      setIsFetchingRequest(true);
      if (!requestId) return;

      try {
        const res = await heroApiService.getReviewRequestById(parseInt(requestId));
        setLocationId(res.location_id);

        publicRouteService.sendUserActivitySlackNotification(
          `LocationId: ${res.location_id}`,
          'Customer open request callback screen'
        );
      } catch (error) {
        console.log(error);
      }
      setIsFetchingRequest(false);
    };
    loadRequest();
  }, [requestId]);

  useEffect(() => {
    const initLocation = async () => {
      setIsFetchingLocation(true);
      if (locationId) {
        const res = await heroApiService.getLocationById(locationId);
        if (res && (res.review_url || res.place_id)) {
          setReviewUrl(res.review_url || '');
          setPlaceId(res.place_id || '');
        } else {
          const placeId = await heroApiService.getLocationPlaceId(locationId);
          setPlaceId(placeId);
        }
        setLocation(res);
      }
      setIsFetchingLocation(false);
    };
    initLocation();
  }, [locationId]);

  useEffect(() => {
    if (locationId && requestId) {
      dispatch(
        openRequestEmail({
          requestId: parseInt(requestId),
        })
      );
    }
  }, [locationId, requestId]);

  return (
    <html>
      <head>
        <title>{location?.title}</title>
      </head>
      <ReviewScreenLayout locationId={locationId}>
        <LoadableContainer
          isLoading={!location || isFetchingRequest}
          loadComponent={<SplashScreen />}>
          <Space direction="vertical" style={{ justifyContent: 'center' }}>
            <LoadableContainer
              isLoading={isFetchingLocation}
              loadComponent={
                <div style={{ textAlign: 'center', fontSize: 30 }}>
                  <LoadingOutlined />
                </div>
              }>
              {location && (
                <ReviewSubmissionForm
                  shieldFeature={true}
                  locationName={location.title}
                  onReviewSubmitted={handleOnReviewSubmitted}
                  onPostReviewSubmitted={() => {
                    if (reviewUrl) {
                      window.location.replace(`${reviewUrl}`);
                    } else {
                      window.location.replace(
                        `https://search.google.com/local/writereview?placeid=${placeId}`
                      );
                    }
                  }}
                  placeId={placeId}
                />
              )}
            </LoadableContainer>
          </Space>
        </LoadableContainer>
      </ReviewScreenLayout>
    </html>
  );
};

export default RequestCallbackScreen;
