import { MIDDLE_STYLE } from '@constants/responsive';
import { Button } from 'antd';
import React from 'react';
import { SocialIcon } from '..';
import { useAppDispatch } from '@core/configureStore';
import { openDrawer } from '@core/features';

type Props = {
  style?: React.CSSProperties;
};

const BrowseUnsplashPhotos = ({ style }: Props) => {
  const dispatch = useAppDispatch();
  const handleBrowseUnsplash = () => {
    dispatch(
      openDrawer({
        drawerName: 'unsplashPhotoBrowseDrawer',
      })
    );
  };
  return (
    <Button onClick={handleBrowseUnsplash} style={{ ...MIDDLE_STYLE, ...style }}>
      <SocialIcon platform="unsplash" width={20} />
      <span style={{ marginLeft: 10 }}>Browse Stock Photos</span>
    </Button>
  );
};

export default BrowseUnsplashPhotos;
