/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { PhotoImageDataItem, enqueueNotification } from '@core/features';
import {
  useAppNavigation,
  useSocialPostDispatcher,
  useSocialPosts,
  useStreamingTaskStore,
  useUserOnboarding,
} from '@core/hooks';
import { BusinessSocialPost, SocialPostType, OnboardingStage } from '@core/models';
import moment from 'moment';
import { makeid } from '@utils/string.util';
import { AnimatedComponent, BusinessSocialPostCard, LoadableContainer } from '..';
import { Button, Space } from 'antd';
import Marquee from 'react-fast-marquee';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { ArrowRightOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import SplashScreen from '@components/SplashScreen';
import { socialStudioStreamApiService } from '@services/service-register';
import { base64ToImageItem } from '@utils/file.util';
import { StreamInsertSocialPostsParams } from '@services/social-studio-stream.service';

type Props = { preparedPhotos: PhotoImageDataItem[]; onNextButtonClicked: any };

const OnboardingPostPreviewStage = ({ preparedPhotos, onNextButtonClicked }: Props) => {
  const dispatch = useAppDispatch();
  const { photoSocialPosts: existingSocialPosts, loading: existingSocialPostLoading } =
    useSocialPosts();
  const { generateClientSideSocialPosts, getLatestPublishedDate } = useSocialPostDispatcher();
  const { handleMarkAsComplete } = useUserOnboarding();
  const { navigate } = useAppNavigation();
  const [loading, setLoading] = useState<boolean>(true);
  const [socialPosts, setSocialPosts] = useState<BusinessSocialPost[]>([]);
  const { user } = useAuthState();
  const { addTask } = useStreamingTaskStore();

  const handleBatchInsertSocialPosts = async () => {
    const payload: StreamInsertSocialPostsParams = [];

    for (let index = 0; index < socialPosts.length; index++) {
      const socialPost = socialPosts[index];
      const photo = base64ToImageItem(socialPost.photo.data);
      payload.push({
        fileImageItems: [photo],
        topic: '',
        scheduledAt: socialPost.scheduledAt,
        caption: socialPost.caption,
        type: SocialPostType.Default,
      });
    }

    addTask(
      {
        id: makeid(5),
        name: 'Create social posts',
        payload,
        apiMethod: socialStudioStreamApiService.streamInsertSocialPosts,
        async onFinishedCallback() {
          dispatch(
            enqueueNotification({
              name: 'Successfully approve showcase reviews',
              type: 'Success',
            })
          );
          await handleMarkAsComplete([OnboardingStage.SocialStudio]);
        },
      },
      payload.length
    );
    navigate('studio');
  };

  const handleApproveSocialPosts = async () => {
    try {
      setLoading(true);
      await handleBatchInsertSocialPosts();
      dispatch(
        enqueueNotification({
          name: 'Successfully approve social posts',
          description: 'Approve social posts',
          type: 'Success',
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to approve social posts',
          description: 'Approve social posts failed',
          type: 'Error',
        })
      );
    }
    setLoading(false);
  };

  const handleGenerateSocialPosts = async () => {
    if (!user?.selected_location) return;
    setLoading(true);
    const latestScheduledDate = getLatestPublishedDate(existingSocialPosts, moment().unix());
    await generateClientSideSocialPosts(
      moment.unix(latestScheduledDate),
      preparedPhotos,
      (totalSocialPosts, createdSocialPost) =>
        setSocialPosts([...totalSocialPosts, createdSocialPost])
    );
    setLoading(false);
  };

  const handleSkipApproval = async () => {
    await handleMarkAsComplete([OnboardingStage.SocialStudio]);
    navigate('getting-started');
  };

  useEffect(() => {
    const init = async () => {
      if (!existingSocialPostLoading) {
        await handleGenerateSocialPosts();
      }
    };
    init();
  }, [preparedPhotos, user, existingSocialPosts, existingSocialPostLoading]);

  return (
    <React.Fragment>
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', padding: '0px 30px' }}>
        <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Preview Social Posts</h1>
        <p
          style={{
            maxWidth: 400,
            textAlign: 'center',
            color: GLOBAL_THEME_COLOR.$dark_text_color,
          }}>
          Amazely crafted beautiful social posts using photos you provided. Click{' '}
          <span style={{ fontWeight: 'bold' }}>Approve</span> button to use these social posts
        </p>
        <br />
        <LoadableContainer
          loadComponent={<SplashScreen />}
          isLoading={socialPosts.length === 0 && loading}>
          {loading ? (
            <LoadingOutlined />
          ) : (
            <Space>
              <Button style={{ margin: '20px 0px 0px 0px' }} onClick={handleSkipApproval}>
                Skip <ArrowRightOutlined style={{ marginRight: 0, marginLeft: 10 }} />
              </Button>
              <Button
                type="primary"
                style={{ margin: '20px 0px 0px 0px' }}
                onClick={handleApproveSocialPosts}>
                Approve social posts <CheckOutlined style={{ marginRight: 0, marginLeft: 10 }} />
              </Button>
            </Space>
          )}
          <Marquee speed={100} style={{ marginTop: 20, height: 'fit-content' }}>
            {socialPosts.map(socialPost => (
              <AnimatedComponent.OpacityFadeInDiv delay={200}>
                <BusinessSocialPostCard
                  style={{
                    minWidth: 400,
                    fontSize: 'smaller',
                  }}
                  hidePlatform
                  socialPost={socialPost}
                  type={'facebook'}
                />
              </AnimatedComponent.OpacityFadeInDiv>
            ))}
          </Marquee>
        </LoadableContainer>
      </div>
    </React.Fragment>
  );
};

export default OnboardingPostPreviewStage;
