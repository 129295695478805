/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Layout, Progress, Space } from 'antd';
import {
  StreamingTask,
  isTaskCancellable,
  useAppDispatch,
  useAppNavigation,
  useAuthState,
  useBusinessState,
  useGlobalEventEmitter,
  useNavigationItems,
  useStreamingTaskStore,
} from '@core/.';
import { AppRoutes, FREELANCER_MODE_PROTECTED_ROUTES } from '@utils/.';
import AppBar from '../AppBar';
import LeftSider from '../LeftSider';
import ModalContainer from '@components/ModalContainer';
import { useParams } from 'react-router';
import React, { useEffect } from 'react';
import { fetchReviewRequests } from '@core/features/review-request/thunks';
import { openModal } from '@core/features';
import SplashScreen from '@components/SplashScreen';
import { fetchLocationData } from '@core/features/business/thunks';
import { fetchInquiries } from '@core/features/inquiry/thunks';
import SocialStudioOnboardingScreen from '@pages/SocialStudioOnboardingScreen';
import { MAX_ONBOARDING_STAGE } from '@constants/pitch-business';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import NavigationDrawer from '@components/NavigationDrawer';
import loadable from '@loadable/component';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { LoadingOutlined, PauseCircleFilled } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useIntercom, DataAttributes } from 'react-use-intercom';
import ProtectedFreelancerModeRoute from '@components/ProtectedFreelancerModeRoute';
import { JoyrideContainer } from '..';
import { emitCloseStream } from '@components/EventEmitterProvider';
import ReviewWallOfLoveScreen from '@pages/ReviewWallOfLoveScreen';

const BlogAppRouter = loadable(() => import('@pages/blogs/BlogAppRouter'));
const RequestCreationScreen = loadable(() => import('@pages/RequestCreationScreen'));
const RequestEmailSMSScreen = loadable(() => import('@pages/RequestEmailSMSScreen'));
const AutoRequestScreen = loadable(() => import('@pages/AutoRequestScreen'));
const RequestsQrCodeScreen = loadable(() => import('@pages/RequestsQrCodeScreen'));
const SocialStudioScreen = loadable(() => import('@pages/SocialStudioScreen'));
const ReviewsScreen = loadable(() => import('@pages/ReviewsScreen'));
const SettingsScreen = loadable(() => import('@pages/SettingsScreen'));
const ShowcaseReviewScreen = loadable(() => import('@pages/ShowcaseReviewScreen'));
const HomeScreen = loadable(() => import('@pages/HomeScreen'));
const HelpCenterScreen = loadable(() => import('@pages/HelpCenterScreen'));
const ManualSharingScreen = loadable(() => import('@pages/ManualSharingScreen'));
const InquiryScreen = loadable(() => import('@pages/InquiryScreen'));
const AppIntegrationScreen = loadable(() => import('@pages/AppIntegrationScreen'));
const SubscriptionScreen = loadable(() => import('@pages/SubscriptionScreen'));
const SocialPostCreationScreen = loadable(() => import('@pages/SocialPostCreationScreen'));
const OnboardingScreen = loadable(() => import('@pages/OnboardingScreen'));
const GenerateReviewScreen = loadable(() => import('@pages/GenerateReviewScreen'));
const ShowcaseSocialReviewsOnboardingScreen = loadable(
  () => import('@pages/ShowcaseSocialReviewsOnboardingScreen')
);
const BookingManagementScreen = loadable(() => import('@pages/BookingManagementScreen'));

type Props = {};

const screens: Record<AppRoutes, any> = {
  'getting-started': <OnboardingScreen />,
  home: <HomeScreen />,
  reviews: <ReviewsScreen />,
  inquiries: <InquiryScreen />,
  integrations: <AppIntegrationScreen />,
  studio: <SocialStudioScreen />,
  settings: <SettingsScreen />,
  'generate-reviews': <GenerateReviewScreen />,
  requestCreation: <RequestCreationScreen />,
  socialPostCreation: <SocialPostCreationScreen />,
  manualSharing: <ManualSharingScreen />,
  widget: <ShowcaseReviewScreen />,
  requestEmailSMS: <RequestEmailSMSScreen />,
  requestQrScanner: <RequestsQrCodeScreen />,
  autoRequest: <AutoRequestScreen />,
  'studio-onboard': <SocialStudioOnboardingScreen />,
  'showcase-reviews-onboard': <ShowcaseSocialReviewsOnboardingScreen />,
  subscription: <SubscriptionScreen />,
  requests: <GenerateReviewScreen />,
  'grow-reputation': <></>,
  'grow-social-presence': <></>,
  'help-center': <HelpCenterScreen />,
  booking: <BookingManagementScreen />,
  blogs: <BlogAppRouter />,
  'wall-of-love': <ReviewWallOfLoveScreen />,
};

const { Content } = Layout;

const DashboardLayout: React.FC = (props: Props) => {
  const dispatch = useAppDispatch();
  const { inner } = useGlobalEventEmitter();
  const {
    GeneralNavigationItems,
    OtherNavigationItems,
    GrowSocialPresenceNavigationItems,
    GrowReputationNavigationItems,
  } = useNavigationItems();
  const { navigate } = useAppNavigation();
  const { tasks, taskMetadata } = useStreamingTaskStore();
  const { isTablet } = useBreakpoint();
  const { tabName } = useParams<{ tabName: AppRoutes }>();
  const { error } = useBusinessState();
  const { user, isAuthLoading: isLoadingUser, isSupportSession } = useAuthState();
  const { update } = useIntercom();

  const handlePauseTask = (task: StreamingTask<any, any>) => {
    if (inner) inner.emit(emitCloseStream(task.id));
  };

  useEffect(() => {
    if (!screens[tabName]) {
      if (user?.selected_location?.onboarding_stages?.length === MAX_ONBOARDING_STAGE)
        navigate('home');
      else navigate('getting-started');
    }
  }, [tabName]);

  useEffect(() => {
    // If there is no location selected or no permission granted for NOT freelancer_mode location
    if (
      !user?.selected_location ||
      (!user?.selected_location?.freelancer_mode && error === 'MISSING_PERMISSION')
    ) {
      dispatch(
        openModal({
          modalName: 'welcomeLocationModal',
        })
      );
    }
  }, [error, user]);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchLocationData());
      await dispatch(fetchReviewRequests());
      await dispatch(fetchInquiries());
    };
    init();
  }, []);

  useEffect(() => {
    if (user && !isLoadingUser && !isSupportSession) {
      let intercomData: DataAttributes = {
        email: user.email,
        userId: user.id.toString(),
        name: `${user.given_name} ${user.family_name}`,
      };

      if (user.selected_location) {
        intercomData = {
          ...intercomData,
          customAttributes: {
            Location: user.selected_location.title,
          },
        };
      }
      update(intercomData);
    }
  }, [user]);

  return user && !isLoadingUser ? (
    <Layout style={{ height: '100vh' }}>
      {!isTablet && <LeftSider />}
      <Layout className="site-layout">
        <div
          className={'stripe-box-shadow'}
          style={{
            visibility: tasks.length === 0 ? 'hidden' : 'visible',
            position: 'absolute',
            margin: '10px',
            fontSize: '18px',
            bottom: 0,
            backgroundColor: GLOBAL_THEME_COLOR.$background_color,
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            padding: '10px 20px 10px 30px',
            borderRadius: 10,
            zIndex: 3,
          }}>
          {tasks.map(task => (
            <Space>
              {isTaskCancellable(task) ? (
                <PauseCircleFilled
                  style={{
                    color: GLOBAL_THEME_COLOR.$highlight_color,
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                  onClick={() => handlePauseTask(task)}
                />
              ) : (
                <LoadingOutlined />
              )}
              <span>{task.name}</span>
              <Progress
                style={{ marginTop: 5 }}
                trailColor={GLOBAL_THEME_COLOR.$background_color}
                strokeColor={GLOBAL_THEME_COLOR.$highlight_color}
                size={'default'}
                steps={Math.min(taskMetadata[task.id].totalProgress, 20)}
                percent={parseFloat(
                  (
                    (taskMetadata[task.id].progress / taskMetadata[task.id].totalProgress) *
                    100
                  ).toFixed(1)
                )}
              />
            </Space>
          ))}
        </div>
        {isTablet && (
          <Alert
            showIcon
            banner
            type="info"
            message={'For an enhanced experience, try Amazely on desktop.'}
          />
        )}
        <AppBar />
        <Content style={{ height: '95vh', overflow: 'auto', paddingBottom: 100 }}>
          {FREELANCER_MODE_PROTECTED_ROUTES[tabName] ? (
            <ProtectedFreelancerModeRoute>
              {screens[tabName] || <React.Fragment>No route found</React.Fragment>}
            </ProtectedFreelancerModeRoute>
          ) : (
            screens[tabName] || <React.Fragment>No route found</React.Fragment>
          )}
          {isTablet && (
            <div
              style={{
                position: 'absolute',
                ...MIDDLE_STYLE,
                justifyContent: 'space-evenly',
                width: '100%',
                zIndex: 10,
                bottom: 0,
                padding: '20px 80px 20px 20px',
                boxShadow: STRIPE_BOX_SHADOW,
                borderTop: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                backgroundColor: GLOBAL_THEME_COLOR.$background_color,
              }}>
              {[
                ...GeneralNavigationItems,
                ...[GrowReputationNavigationItems[0]],
                ...GrowSocialPresenceNavigationItems,
                ...OtherNavigationItems,
              ].map((item: any) => (
                <div
                  onClick={() => navigate(item.key)}
                  style={{
                    ...MIDDLE_STYLE,
                    margin: 0,
                    padding: 0,
                    fontSize: '20px',
                    color:
                      item.key === tabName
                        ? GLOBAL_THEME_COLOR.$highlight_color
                        : GLOBAL_THEME_COLOR.$dark_text_color,
                  }}>
                  {item.icon}
                </div>
              ))}
            </div>
          )}
        </Content>
        <ModalContainer />
        <JoyrideContainer />
        <NavigationDrawer />
      </Layout>
    </Layout>
  ) : (
    <SplashScreen />
  );
};

export default DashboardLayout;
