import { LocalLocation } from '@core/models/user';
import { SocialAccountContact } from './social-post';

type HTMLString = string;
type UrlString = string;

export enum BlogPostStage {
  Draft = 0,
  Pending = 1,
  Scheduled = 2,
  Queued = 3,
  Published = 4,
  ApprovalRequested = 99,
}

export enum BlogPostMadeBy {
  User = 0,
  System = 1,
}

export type BlogPostModel = {
  id: string;
  title: string;
  subtitle?: string;
  contentBodyUrl: string;
  contentBody: HTMLString;
  thumbnail: UrlString;
  location: LocalLocation;
  created_date: string;
  updated_date: string;
  publish_at: string | null;
  status: BlogPostStage;
  notified_at?: Date;
  made_by?: BlogPostMadeBy;
  slug: string;
  keywords?: string[];
  isBackgroundEvent?: boolean;
  published_accounts?: SocialAccountContact[];
  shared_at?: Date;
};

export enum BlogPageTabKey {
  BlogPosts = 'blog-posts',
  Setting = 'setting',
}

export enum EmbeddedBlogViewMode {
  ListView = 'ListView',
  Marquee = 'Marquee',
}

export interface EmbeddedBlogSetting {
  cardColor: string;
  cardTitleColor: string;
  cardMaxSize: number;
  cardPerPage: number;
  maxContainerSize: number;
  displayMode: EmbeddedBlogViewMode;
}

export interface BlogSetting {
  id: string;
  embedded_setting: EmbeddedBlogSetting;
}
