import { FacebookPostSizeMode, SocialPost } from '@core/models';
import { formatUtcTimestamp } from './date.util';

export const getSocialPostSizeMode = (sizeMode: FacebookPostSizeMode) => {
  switch (sizeMode) {
    case FacebookPostSizeMode.Landscape:
      return [1080, 566];
    case FacebookPostSizeMode.Square:
      return [1080, 1080];
  }
};

export const sortSocialPostsByPublishedDate =
  (order: 'oldestToNewest' | 'newestToOldest') =>
  (socialPostA: SocialPost, socialPostB: SocialPost) =>
    (formatUtcTimestamp(socialPostA.publish_at) - formatUtcTimestamp(socialPostB.publish_at)) *
    (order === 'oldestToNewest' ? -1 : 1);
