/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { useAuthState } from '@core/configureStore';
import { useReactToPrint } from 'react-to-print';
import { Button, Card, Col, Divider, Input, Row, Space } from 'antd';
import { useFormik } from 'formik';
import QRCode from 'react-qr-code';
import AnonymousReviewContainer from '@components/AnonymousReviewContainer';
import LoadableContainer from '@components/LoadableContainer';
import NoBusinessFound from '@components/NoBusinessFound';
import { buildCallbackURL } from '@utils/string.util';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { MIDDLE_STYLE } from '@constants/responsive';
import { STRIPE_BOX_SHADOW } from '@constants/theme';
import { TbClick } from 'react-icons/tb';

type Props = {};

type QRCodeValues = {
  message: string;
  incentives: string;
};

const buildMessage = (businessName: string) => {
  return `Dear customers, \nWe are grateful for your support to ${businessName}. We would love it if you could take a few seconds to give us a quick Google review. \nThis helps us to continue providing the best experience possible for you, and helps others to find us.`;
};

const buildOfferIcentives = () => {
  return `And by the way, please let us know about your review at the counter and take 5% off the bill, on us.`;
};

const RequestsQrCodeScreen = (props: Props) => {
  const { user } = useAuthState();
  const { isTablet } = useBreakpoint();
  const componentRef = React.useRef(null);
  const message = user?.selected_location ? user?.selected_location?.title : '';
  const formik = useFormik<QRCodeValues>({
    initialValues: {
      incentives: buildOfferIcentives(),
      message: buildMessage(message),
    },
    onSubmit: () => {},
  });

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Amazely',
    removeAfterPrint: true,
  });

  const handleViewPreview = () => {
    window.open(callbackURL);
  };

  const callbackURL = useMemo(
    () =>
      user && user.selected_location ? buildCallbackURL(user.selected_location.location_id) : '',
    [user]
  );

  return (
    <div className="screen-card">
      <h3>Generate Reviews from QR Code</h3>
      <LoadableContainer isLoading={!user?.selected_location} loadComponent={<NoBusinessFound />}>
        <div style={{ padding: isTablet ? 0 : '30px 30px', maxWidth: 1300 }}>
          <Row gutter={70}>
            <Col span={isTablet ? 24 : 12}>
              <Card style={{ borderRadius: 10 }}>
                <h3 style={{ marginBottom: 10 }}>Message</h3>
                <Divider />
                <Input.TextArea
                  id="message"
                  autoSize={{ minRows: 5 }}
                  value={formik.values.message}
                  maxLength={500}
                  showCount
                  name="message"
                  placeholder="Enter message"
                  onChange={formik.handleChange}
                />
                <Space style={{ marginTop: 50, float: 'right' }}>
                  <Button onClick={handleViewPreview} type="primary">
                    <TbClick /> Preview QR Code
                  </Button>
                  <Button onClick={handlePrint}>Print</Button>
                </Space>
              </Card>
            </Col>
            <Col span={isTablet ? 24 : 12} ref={componentRef}>
              <Card style={{ padding: '20px 20px', borderRadius: 10 }}>
                <div style={{ marginBottom: 30 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formik.values.message.replace('\n', '<br/>'),
                    }}
                  />
                </div>
                <div
                  style={{
                    background: "url('images/qrcode-background.jpg')",
                    padding: '30px',
                    borderRadius: '10px',
                    ...MIDDLE_STYLE,
                  }}>
                  <div
                    style={{
                      padding: '20px',
                      boxShadow: STRIPE_BOX_SHADOW,
                      borderRadius: 15,
                      backgroundColor: 'white',
                      height: 'fit-content',
                      width: 'fit-content',
                      ...MIDDLE_STYLE,
                    }}>
                    <QRCode
                      style={{ width: isTablet ? 150 : 200, height: isTablet ? 150 : 200 }}
                      value={callbackURL}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <AnonymousReviewContainer />
        </div>
      </LoadableContainer>
    </div>
  );
};

export default RequestsQrCodeScreen;
