import { Space, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { EmailSubscriptionType } from '@core/models/email';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { emailSubscriptionService } from '@services/service-register';
import { enqueueNotification } from '@core/features';
import { fetchCurrentUser } from '@core/features/auth/thunks';

const EmailSubscriptionContainer: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { user, isAuthLoading } = useAuthState();

  const handeUpdateEmailSubscriptionStatus = async (
    emailType: EmailSubscriptionType,
    status: boolean
  ) => {
    try {
      const accountLocation = user?.selected_account_location;
      if (accountLocation) {
        await emailSubscriptionService.handleUpdateEmailSubscription(
          accountLocation.google_sub,
          accountLocation.location_id,
          emailType,
          status
        );
        await dispatch(fetchCurrentUser());
        dispatch(
          enqueueNotification({
            name: 'Email subscription status updated',
            description: 'Email subscription status updated',
            type: 'Success',
          })
        );
      }
    } catch (err: any) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update email subscription status',
          description: 'Failed to update email subscription status',
          type: 'Error',
        })
      );
    }
  };

  return (
    <div style={{ padding: '20px 0px' }}>
      {[
        {
          key: EmailSubscriptionType.StudioNotificationSubscribed,
          title: 'Studio email notification',
          description: 'Receive notification via email when there are new social posts published',
        },
        {
          key: EmailSubscriptionType.ReviewNotificationSubscribed,
          title: 'Review email notification',
          description: 'Receive notification via email when there are new reviews',
        },
        {
          key: EmailSubscriptionType.RequestNotificationSubscribed,
          title: 'Request email notification',
          description: 'Request notification via email to receive a request summary',
        },
        {
          key: EmailSubscriptionType.ShowcaseReviewNotificationSubscribed,
          title: 'Showcase review email notification',
          description: 'Receive email for approval whenever a new review post generated',
        },
        {
          key: EmailSubscriptionType.BlogNotificationSubscribed,
          title: 'Blog email notification',
          description: 'Receive notification via email when there are new blogs published',
        },
      ].map(item => (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              {item.title}
              <br />
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>{item.description}</p>
            </div>
            {user?.selected_account_location && !isAuthLoading ? (
              <Space>
                <Switch
                  checked={user?.selected_account_location.email_subscription[item.key]}
                  onChange={isChecked => handeUpdateEmailSubscriptionStatus(item.key, isChecked)}
                />
              </Space>
            ) : (
              <LoadingOutlined />
            )}
          </div>
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export default EmailSubscriptionContainer;
