import { create } from 'zustand';

export type OnboardingBusinessInfo = {
  name: string | undefined;
  location: string | undefined;
};

export interface OnboardingStepStore {
  businessInfo: {
    name: string | undefined;
  };
  setBusinessInfo: (updatedBusinessInfo: Partial<OnboardingBusinessInfo>) => void;
}

export const useOnboardingStepStore = create<OnboardingStepStore>()((set: any) => ({
  businessInfo: {
    name: undefined,
  },
  setBusinessInfo: (updatedBusinessInfo: Partial<OnboardingBusinessInfo>) => {
    set(({ businessInfo }: any) => ({
      businessInfo: { ...businessInfo, ...updatedBusinessInfo },
    }));
  },
}));
