import { Button, Checkbox, Col, Divider, List, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardSectionHeader } from '..';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { GoogleLocalLocation, Location, SocialPostType } from '@core/models';
import {
  useAppDispatch,
  useAuthState,
  useBusinessState,
  useModalState,
} from '@core/configureStore';
import { CheckCircleOutlined, LoadingOutlined, SendOutlined, StarFilled } from '@ant-design/icons';
import { selectLocation } from '@core/features/business/thunks/selectLocation.thunk';
import { useAppNavigation } from '@core/hooks';
import { MAX_ONBOARDING_STAGE } from '@constants/pitch-business';
import { MIDDLE_STYLE } from '@constants/responsive';
import { cloneArray, countExistentialObject } from '@utils/array.util';
import { openModal } from '@core/features';
import { fetchAmazelyLocalLocations } from '@core/features/business/thunks/fetchAmazelyLocalLocations';

type Props = {};

const DEFAULT_PAGE_SIZE = 10;

const MultipleLocationView = (props: Props) => {
  const { user } = useAuthState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPageSize, setSelectedPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<Record<string, boolean>>({});
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { locationModal } = useModalState();
  const { locations, googleLocations } = useBusinessState();

  const sortedByNameLocations = useMemo(
    () =>
      cloneArray(locations).sort((locationA, locationB) =>
        locationA.name > locationB.name ? 1 : -1
      ),
    [locations]
  );

  const renderDescription = (item: Location) => {
    const address = item?.storefrontAddress;
    return address
      ? `${address.addressLines?.[0]}, ${address.locality}, ${address.regionCode}`
      : 'No Storefront Address';
  };

  const onSelectLocation = async (locationName: string) => {
    try {
      await dispatch(selectLocation(locationName));
      if (user?.selected_location?.onboarding_stages?.length === MAX_ONBOARDING_STAGE)
        navigate('home');
      else navigate('getting-started');
    } catch (error) {
      console.log(error);
    }
  };

  const isLocationSelected = useCallback(
    (locationItem: Location) => {
      return locationItem.metadata.placeId
        ? locationItem.metadata.placeId === user?.selected_location.place_id
        : locationItem.title === user?.selected_location?.title;
    },
    [user]
  );

  const handleSelectLocation = (locationId: string, checked: boolean) => {
    setSelectedLocations({
      ...selectedLocations,
      [locationId]: checked,
    });
  };

  const handlePublishGoogleBusinessPost = () => {
    dispatch(
      openModal({
        modalName: 'socialPostCreationModal',
        extraParams: {
          photoData: undefined,
          topic: undefined,
          caption: undefined,
          type: SocialPostType.GoogleBusinessPost,
          selectedAccounts: selectedLocations,
          selectPhotoDisabled: false,
        },
      })
    );
  };

  const selectedLocationCount = useMemo(
    () => countExistentialObject(selectedLocations),
    [selectedLocations]
  );

  useEffect(() => {
    const _selectedLocations: Record<string, boolean> = {};
    for (const location of googleLocations) {
      _selectedLocations[location.location_id] = allChecked;
    }
    setSelectedLocations(_selectedLocations);
  }, [allChecked, locationModal]);

  useEffect(() => {
    dispatch(fetchAmazelyLocalLocations());
  }, []);

  return (
    <React.Fragment>
      <div>
        <Button
          disabled={selectedLocationCount === 0}
          type="primary"
          onClick={handlePublishGoogleBusinessPost}>
          <SendOutlined /> Publish Google Business Post
        </Button>
        {selectedLocationCount > 0 && (
          <p style={{ marginTop: 15, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            Post will be published to {selectedLocationCount} businesses
          </p>
        )}
      </div>
      <Row style={{ fontSize: '15px', marginTop: 25 }}>
        <Col span={1}>
          <Checkbox checked={allChecked} onChange={e => setAllChecked(e.target.checked)} />
        </Col>
        <Col span={6}>
          <CardSectionHeader style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }} title="Title" />
        </Col>
        <Col span={6}>
          <CardSectionHeader
            style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}
            title="Full Address"
          />
        </Col>
        <Col span={3}>
          <CardSectionHeader
            style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}
            title="Total Rating"
          />
        </Col>
        <Col span={3}>
          <CardSectionHeader
            style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}
            title="Total Reviews"
          />
        </Col>
        <Col span={3}>
          <CardSectionHeader
            style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}
            title="Phone Number"
          />
        </Col>
        <Col span={2}></Col>
      </Row>
      <Divider style={{ margin: '5px 0px' }} />
      <List
        dataSource={sortedByNameLocations}
        pagination={{
          onShowSizeChange: (_, pageSize) => {
            setSelectedPageSize(pageSize);
          },
          onChange: setCurrentPage,
          hideOnSinglePage: false,
          pageSize: selectedPageSize,
        }}
        renderItem={(item: Location, index) => {
          const googleLocation: GoogleLocalLocation | undefined =
            googleLocations[(currentPage - 1) * selectedPageSize + index];
          return (
            <div>
              <Row
                style={{
                  ...MIDDLE_STYLE,
                  fontSize: '15px',
                  paddingBottom: 10,
                  paddingTop: 10,
                  borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                }}>
                <Col span={1}>
                  {googleLocation ? (
                    <Checkbox
                      checked={selectedLocations[googleLocation?.location_id]}
                      onChange={e =>
                        handleSelectLocation(googleLocation.location_id, e.target.checked)
                      }
                    />
                  ) : (
                    <LoadingOutlined />
                  )}
                </Col>
                <Col span={6}>
                  <h4>{item.title}</h4>
                </Col>
                <Col span={6}>{renderDescription(item)}</Col>
                <Col span={3}>
                  {googleLocation ? (
                    <React.Fragment>
                      {new Array(
                        parseInt(googleLocation.placeResult?.result?.rating?.toString() || '0')
                      )
                        .fill(
                          <StarFilled style={{ color: GLOBAL_THEME_COLOR.$processing_color }} />
                        )
                        .map(rating => rating)}
                      <span style={{ marginLeft: 10 }}>
                        {googleLocation.placeResult?.result?.rating}
                      </span>
                    </React.Fragment>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Col>
                <Col span={3}>
                  {googleLocation ? (
                    <React.Fragment>
                      {googleLocation.placeResult?.result?.user_ratings_total}
                    </React.Fragment>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Col>
                <Col span={3}>
                  {googleLocation ? (
                    <React.Fragment>
                      {googleLocation.placeResult?.result?.formatted_phone_number}
                    </React.Fragment>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Col>
                <Col span={2}>
                  {!!user && !isLocationSelected(item) ? (
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => onSelectLocation(item.name)}>
                      Select
                    </Button>
                  ) : (
                    <CheckCircleOutlined style={{ fontSize: 20 }} />
                  )}
                </Col>
              </Row>
            </div>
          );
        }}
      />
      <div style={{ ...MIDDLE_STYLE }}>
        {googleLocations.length !== sortedByNameLocations.length && (
          <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            <LoadingOutlined style={{ marginRight: 10 }} /> Loading your businessess. Please don't
            close this window...
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default MultipleLocationView;
