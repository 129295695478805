/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactConfetti from 'react-confetti';
import {
  BRAND_LOGO_CONSTRAST,
  BRAND_NAME,
  GLOBAL_THEME_COLOR,
  MIDDLE_STYLE,
} from '../../constants';
import { Button, Divider, Layout, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useGenerationDataStore, usePitchDispatcher } from '@core/hooks';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { QrCodeGeneratorEngineStatus } from '@core/models';
import FacebookInAppHandler from '@components/FacebookInAppHandler';
import { publicRouteService } from '@services/service-register';
import { HiChevronDoubleDown } from 'react-icons/hi2';
import {
  PitchAutoReviewResponseSection,
  PitchLocalReviewRankingMap,
  PitchQrCodeCard,
  PitchReverseNegativeReviews,
  PitchReviewMarketingSection,
  PitchSocialPostsSection,
  PitchSummaryToActionSection,
} from '@components/pitch';

type Props = {};

const QrCodeGenerateResultScreen = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { isTablet, isMobile } = useBreakpoint();
  const { businessData, qrCodeEngineStatus } = useGenerationDataStore();
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const confettiRef = useRef<HTMLDivElement | null>(null);
  const { handleActivate } = usePitchDispatcher();

  const handleActivateWithHandler = () => {
    handleActivate({
      facebookAppHandler: () => setOpen(true),
    });
  };

  const handleLearnMoreAboutAmazely = async () => {
    try {
      await publicRouteService.sendSlackNotification(
        `${businessData?.name} [👀 Learning about Amazely...]`,
        businessData?.types?.[0] || ''
      );
    } catch (error) {
      console.log(error);
    }
    window.open('https://amazely.co/');
  };

  useEffect(() => {
    if (!confettiRef.current) return;
    setHeight(confettiRef.current.clientHeight);
    setWidth(confettiRef.current.clientWidth);
  }, [confettiRef]);

  useEffect(() => {
    if (qrCodeEngineStatus === QrCodeGeneratorEngineStatus.Finished) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  }, [qrCodeEngineStatus]);

  return (
    <FacebookInAppHandler open={open} setOpen={setOpen}>
      <div
        ref={confettiRef}
        style={{
          backgroundColor: '#f0f2f5',
          maxHeight: '100vh',
          overflow: 'auto',
        }}>
        {showConfetti && <ReactConfetti numberOfPieces={1000} width={width} height={height} />}
        <Layout>
          <Layout.Header
            style={{
              position: 'fixed',
              zIndex: 100,
              width: '100%',
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
            }}>
            <div style={{ ...MIDDLE_STYLE }}>
              <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 40 }} />{' '}
              {!isTablet && (
                <span
                  style={{
                    color: GLOBAL_THEME_COLOR.$text_color,
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginLeft: 10,
                  }}>
                  {BRAND_NAME.toUpperCase()}
                </span>
              )}
            </div>
            <Space style={{ ...MIDDLE_STYLE }}>
              <Button
                onClick={handleLearnMoreAboutAmazely}
                size="large"
                style={{ cursor: 'pointer', ...MIDDLE_STYLE }}>
                Learn more
              </Button>
              <Button
                onClick={handleActivateWithHandler}
                type="primary"
                size="large"
                style={{ cursor: 'pointer', ...MIDDLE_STYLE }}>
                {!isMobile && 'Getting started'}
                <ArrowRightOutlined style={{ marginLeft: isMobile ? 0 : 10 }} />
              </Button>
            </Space>
          </Layout.Header>
          <Layout.Content style={{ overflowX: 'hidden' }}>
            <div
              id="generate-screen"
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                padding: '100px 20px 40px 20px',
                width: '100vw',
                overflow: 'hidden',
                position: 'relative',
                background: 'url("jumbotron/builder-background-purple.webp")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}>
              <h1
                className="airbnb-font"
                style={{
                  ...MIDDLE_STYLE,
                  marginBottom: 30,
                  fontWeight: 'normal',
                  color: GLOBAL_THEME_COLOR.$text_color_contrast,
                }}>
                Scroll down to view more{' '}
                <HiChevronDoubleDown style={{ margin: 0, marginLeft: 10 }} />
              </h1>
              <PitchQrCodeCard version={1} />
            </div>
            <div
              id="generate-screen"
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                padding: '50px 30px 0px 30px',
                width: '100vw',
              }}>
              <div style={{ fontSize: 'larger', marginBottom: 20 }}>
                <h1 className="pitch_title airbnb-font">
                  Not just QR code, Amazely also grows and manages reviews on your behalf.
                </h1>
              </div>
              <Divider />
              <PitchLocalReviewRankingMap />
            </div>
            <div
              id="generate-screen"
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                padding: '30px 30px 50px 30px',
                width: '100vw',
              }}>
              <div>
                <PitchAutoReviewResponseSection />
                <Divider />
                <PitchReverseNegativeReviews />
                <Divider />
                <PitchSocialPostsSection />
                <Divider />
                <PitchReviewMarketingSection reviews={businessData.showcaseReviews.slice(0, 3)} />
                <Divider />
                {/* <PitchReviewWallOfLoveSection />
                <Divider /> */}
                <PitchSummaryToActionSection handleActivate={handleActivateWithHandler} />
              </div>
            </div>
          </Layout.Content>
        </Layout>
      </div>
    </FacebookInAppHandler>
  );
};

export default QrCodeGenerateResultScreen;
