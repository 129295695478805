/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { usePublicRouteService } from '@core/hooks';
import { useGenerationDataStore } from '@core/hooks';
import {
  AutoReviewGeneratorEngineStatus,
  GooglePlaceDetailData,
  GoogleSmartReview,
  PlaceData,
} from '@core/models';
import { makeid } from '@utils/string.util';
import { useURLQuery } from '@core/hooks/useUrlQuery';
import { useParams } from 'react-router';
import { publicRouteService } from '@services/service-register';
import SplashScreen from '@components/SplashScreen';

let notified = false;
const AutoReviewProcessingStep = () => {
  const salePitchService = usePublicRouteService();
  const query = useURLQuery();
  const businessName = useMemo<string>(() => query.get('name') || '', [query]);
  const pitchId = useMemo<string>(() => query.get('pitchId') || '', [query]);
  const { locationPlaceId } = useParams() as { locationPlaceId: string };
  const { getPlaceDetail } = usePublicRouteService();
  const { setGenerationData, setAutoReviewEngineState } = useGenerationDataStore();

  const startRetrieveBusinessData = async (
    placeData: GooglePlaceDetailData
  ): Promise<GoogleSmartReview[]> => {
    if (!placeData) throw new Error('No place found');
    if (placeData.result.reviews?.length === 0 || placeData.result.photos?.length === 0)
      throw new Error('No data found for this location');
    setGenerationData({
      ...placeData.result,
      location: placeData.result.formatted_address,
      reviews: placeData.result.reviews,
    });
    return placeData.result.reviews;
  };

  const startGenerateReviewResponse = async (
    selectedPlace: PlaceData,
    reviews: GoogleSmartReview[]
  ) => {
    const generated_responses: string[] = [];
    const currentReviews: GoogleSmartReview[] = reviews;
    for (let reviewCounter = 0; reviewCounter < currentReviews.length; reviewCounter++) {
      const reviewData = currentReviews[reviewCounter];
      const content = await salePitchService.generateReviewResponse(
        selectedPlace.name,
        selectedPlace.types[0],
        reviewData.rating,
        reviewData.text,
        reviewData.author_name
      );
      generated_responses.push(content);
      /** Update the list of social posts with generated captions */
      const updatedReviews: GoogleSmartReview[] = [];
      let index = 0;
      for (const review of currentReviews) {
        if (index <= reviewCounter) {
          updatedReviews.push({
            ...review,
            generated_response: generated_responses[index],
          });
        } else {
          updatedReviews.push(review);
        }
        index++;
      }
      setGenerationData({
        reviews: updatedReviews,
      });
    }
  };

  const handleGenerateFromName = async (selectedPlace: PlaceData) => {
    setAutoReviewEngineState(AutoReviewGeneratorEngineStatus.Running);
    const placeData = await getPlaceDetail(selectedPlace.place_id);
    await processGeneratingData(placeData);
  };

  const processGeneratingData = async (placeData: GooglePlaceDetailData) => {
    setAutoReviewEngineState(AutoReviewGeneratorEngineStatus.Running);

    // Exclude point_of_interest from types
    let placeDataTypes = placeData.result.types;
    placeDataTypes = placeDataTypes?.filter(
      type => type !== 'point_of_interest' && type !== 'establishment'
    );
    if (placeDataTypes.length === 0) placeDataTypes = placeData.result.types;

    const reviews = await startRetrieveBusinessData(placeData);
    if (reviews.length === 0) {
      return setAutoReviewEngineState(AutoReviewGeneratorEngineStatus.NotEnoughDataNotify);
    }

    try {
      setAutoReviewEngineState(AutoReviewGeneratorEngineStatus.GenerateReviewResponse);
      await startGenerateReviewResponse(placeData.result, reviews);
    } catch (e) {
      console.log('GENERATE REVIEW RESPONSE: ', e);
    }
    setAutoReviewEngineState(AutoReviewGeneratorEngineStatus.Finished);

    if (notified) return;
    try {
      if (pitchId)
        await publicRouteService.updateMarketingPlaceData(pitchId, {
          pitch_generated_date: new Date(),
        });

      let uniqueIdentifier = localStorage.getItem('uniqueIdentifier');
      if (!uniqueIdentifier) {
        const randomID = makeid(10);
        localStorage.setItem('uniqueIdentifier', randomID);
        uniqueIdentifier = localStorage.getItem('uniqueIdentifier') as string;
      }

      await publicRouteService.sendSlackNotification(
        `${placeData.result.name} | ${placeData.result.formatted_address} | Source: ${document.referrer} | ID: ${uniqueIdentifier}`,
        placeData.result.types?.[0]
      );
    } catch (error) {
      console.log(error);
    }
    notified = true;
  };

  useEffect(() => {
    const loadInit = async () => {
      if (locationPlaceId) {
        const placeData = await getPlaceDetail(locationPlaceId);
        await processGeneratingData(placeData);
      }
    };
    loadInit();
  }, [businessName, locationPlaceId]);

  useEffect(() => {
    const initMarketingPitch = async () => {
      try {
        const data = await publicRouteService.getMarketingPlaceByPitchId(pitchId);
        await handleGenerateFromName(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (pitchId) {
      initMarketingPitch();
    }
  }, [pitchId]);

  return <SplashScreen />;
};

export default AutoReviewProcessingStep;
