import { BlogPostModel, BlogPostStage, BlogSetting, EmbeddedBlogSetting } from '@core/models';
import axios from 'axios';
import { PhotoImageDataItem } from '@core/features';

export default class BlogApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/blogs`;

  public async newDeploymentReady() {
    try {
      const response = await axios.get(`${this.url}/ready`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async isDeploying() {
    try {
      const response = await axios.get(`${this.url}/deploying`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async fetchThumbnailData(url: string): Promise<string> {
    try {
      const response = await axios.post(
        `${this.url}/thumbnail`,
        {
          thumbnailUrl: url,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async fetchBlogContentBody(url: string) {
    try {
      const response = await axios.post(
        `${this.url}/contentBody`,
        {
          contentBodyUrl: url,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async publishBlogSite() {
    try {
      const response = await axios.post(
        `${this.url}/publish-site`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async publishBlogPostPage(blogPostId: string) {
    try {
      const response = await axios.post(
        `${this.url}/${blogPostId}/deploy`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async createManualBlogPost(payload: {
    title: string;
    subtitle?: string;
    status: BlogPostStage;
    contentBody: string;
    featuredFileImageItem: PhotoImageDataItem;
    scheduleLaterEnabled: boolean;
    scheduleTime?: number;
    keywords: string[];
    profileIds: string[];
  }): Promise<BlogPostModel> {
    try {
      const response = await axios.post(`${this.url}`, payload, {
        withCredentials: true,
      });
      return response.data || [];
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  public async getBlogPostSchedules(
    startDate: number,
    endDate: number,
    currentDate: number
  ): Promise<number[]> {
    try {
      const response = await axios.get(
        `${this.url}/schedules?startDate=${startDate}&endDate=${endDate}&currentDate=${currentDate}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateBlogTopics(
    limit: number,
    industry: string,
    businessDescription?: string
  ): Promise<string[]> {
    try {
      const response = await axios.post(
        `${this.url}/topics`,
        {
          industry,
          limit,
          businessDescription,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateBlogPost(
    locationId: string,
    blogPostId: string,
    payload: Partial<{
      title: string;
      subtitle?: string;
      contentBody: string;
      status: BlogPostStage;
      published_at: number;
      featuredFileImageItem: PhotoImageDataItem;
      keywords: string[];
      profileIds: string[];
    }>
  ): Promise<BlogPostModel> {
    try {
      const response = await axios.put(
        `${this.url}/${blogPostId}?locationId=${locationId}`,
        payload,
        {
          withCredentials: true,
        }
      );
      return response.data || [];
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }

  public async removeBlogPostById(blogPostId: string): Promise<void> {
    try {
      const response = await axios.delete(`${this.url}/${blogPostId}`, {
        withCredentials: true,
      });
      return response.data || [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationBlogPosts(): Promise<BlogPostModel[]> {
    try {
      const response = await axios.get(`${this.url}/`, {
        withCredentials: true,
      });
      return response.data || [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getBlogPostById(blogPostId: string): Promise<BlogPostModel | null> {
    try {
      const response = await axios.get(`${this.url}/${blogPostId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async shareBlogPostToSocial(blogPostId: string, profileIds: string[]): Promise<void> {
    try {
      const response = await axios.post(
        `${this.url}/${blogPostId}/share`,
        {
          profileIds,
        },
        {
          withCredentials: true,
        }
      );
      return response.data || [];
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationBlogSetting(): Promise<BlogSetting | null> {
    try {
      const response = await axios.get(`${this.url}/location/setting`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateEmbeddedBlogSetting(
    embeddedSetting: Partial<EmbeddedBlogSetting>
  ): Promise<void> {
    try {
      const response = await axios.put(
        `${this.url}/embedded-setting`,
        {
          ...embeddedSetting,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
