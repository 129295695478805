export const MAX_GENERATED_POST_DESKTOP = 3;
export const MAX_GENERATED_POST_MOBILE = 3;
export const MAX_REVIEW_SHOWCASE_DESKTOP = 3;
export const MAX_REVIEW_SHOWCASE_MOBILE = 2;
export const MAX_GENERATED_REVIEWS = 2;

export const MAX_ONBOARDING_STAGE = 8;

// QR Code Pitch Stage
export const COLLECT_SHOWCASE_REVIEWS = 'COLLECT_SHOWCASE_REVIEWS';
export const RANKING_BUSINESS_IN_LOCAL = 'RANKING_BUSINESS_IN_LOCAL';
export const GENERATE_SOCIAL_POSTS = 'GENERATE_SOCIAL_POSTS';
export const GENERATE_REVIEW_RESPONSES = 'GENERATE_REVIEW_RESPONSES';
