/* eslint-disable react-hooks/exhaustive-deps */
import { NEGATIVE_RATE_CEILING } from '@constants/rate-conversion';
import { useReviewState } from '@core/.';
import { GoogleReview, StarRating } from '@core/models';
import { MONTH_LIST } from '@utils/date.util';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';

export const useReviewStatistics = () => {
  const { reviewResponse } = useReviewState();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());
  const [selectedMonths, setSelectedMonths] = useState<string[]>(MONTH_LIST);
  const lambdaByYear = (item: GoogleReview) => moment(item.updateTime, 'YYYY-MM-DD').format('YYYY');
  const lambdaByMonth = (item: GoogleReview) => moment(item.updateTime, 'YYYY-MM-DD').format('MMM');
  const currentMonth = new Date().getMonth();
  const groupedByYear = useMemo(
    () => (reviewResponse ? _(reviewResponse.reviews).groupBy(lambdaByYear).value() : {}),
    [reviewResponse, selectedYear]
  );

  const groupedByMonth = useMemo(
    () =>
      groupedByYear
        ? _(groupedByYear[selectedYear])
            .groupBy(lambdaByMonth)
            .mapValues(items => _.map(items, 'starRating'))
            .value()
        : {},
    [groupedByYear]
  );

  const monthlyReviewsRating = useMemo(
    () =>
      selectedMonths.map(month => {
        return (groupedByMonth[month] || []).map<number>(value => StarRating[value] as any);
      }),
    [groupedByMonth, selectedMonths]
  );

  const monthlyPositiveReviews = useMemo(
    () =>
      monthlyReviewsRating.map(
        ratings => (ratings.filter(rating => rating > NEGATIVE_RATE_CEILING) || []).length
      ),
    [monthlyReviewsRating]
  );

  const monthlyNegativeReviews = useMemo(
    () =>
      monthlyReviewsRating.map(
        ratings => (ratings.filter(rating => rating <= NEGATIVE_RATE_CEILING) || []).length
      ),
    [monthlyReviewsRating]
  );

  const totalReviews = useMemo(
    () => monthlyPositiveReviews.sumArray(monthlyNegativeReviews),
    [monthlyPositiveReviews, monthlyNegativeReviews]
  );

  const monthlyChanges = useMemo(() => {
    const prevMonth = currentMonth - 1;
    if (prevMonth < 1) return 0;
    const [totalReviewThisMonth, totalReviewLastMonth] = [
      totalReviews[currentMonth],
      totalReviews[prevMonth],
    ];
    const finalValue = (totalReviewThisMonth - totalReviewLastMonth) / (totalReviewLastMonth || 1);
    return parseFloat((finalValue * 100 || 0).toFixed(2));
  }, [totalReviews]);

  return {
    totalReviews,
    monthlyChanges,
    groupedByYear,
    groupedByMonth,
    monthlyReviewsRating,
    monthlyPositiveReviews,
    monthlyNegativeReviews,
    selectedMonths,
    selectedYear,
    setSelectedYear,
    setSelectedMonths,
  };
};
