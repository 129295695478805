import { Button, Divider, Radio, Skeleton, Space, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import DescriptionItem from '@components/DescriptionItem';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { LoadableContainer, SolidColorSettingButton } from '@components/index';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { TbPhoto } from 'react-icons/tb';
import UploadImageModal from '@components/UploadImageModal';
import { enqueueNotification, openModal, PhotoImageDataItem } from '@core/features';
import { heroApiService } from '@services/service-register';
import {
  BackgroundAsset,
  BackgroundAssetVariant,
  BackgroundColorMode,
  BackgroundType,
  PartialShowcasedReview,
  TemplateSettingType,
} from '@core/models';
import { palettes } from '@constants/gradient-pallete';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import _ from 'lodash';

interface Props {
  templateSetting: TemplateSettingType;
  setTemplateSetting: (templateSetting: TemplateSettingType) => void;
  mockReview: PartialShowcasedReview;
}

const Gradient = (props: any) => {
  const { angle = 0, from, to } = props;

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(${angle}deg, ${from}, ${to})`,
        width: '50px',
        height: '30px',
        borderRadius: 5,
        backgroundSize: '100%',
      }}></div>
  );
};

const Palette = (props: { from: string; to: string; angle: number }) => {
  return <Gradient {...props} />;
};

const TemplateEditContainer: React.FC<Props> = ({
  templateSetting,
  mockReview,
  setTemplateSetting,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const [colorMode, setColorMode] = useState(BackgroundColorMode.Solid);
  const [currentSolidColor, setCurrentSolidColor] = useState(GLOBAL_THEME_COLOR.$highlight_color);
  const [templateLoading, setTemplateLoading] = useState(true);
  const [templateUpdating, setTemplateUpdating] = useState(false);
  const [reviewTemplateUrls, setReviewTemplateUrls] = useState<string[]>([]);

  const baseReviewTemplateSetting = useMemo(
    () => user?.selected_location.review_template_setting,
    [user]
  );
  const updated = useMemo(
    () => !_.isEqual(baseReviewTemplateSetting, templateSetting),
    [baseReviewTemplateSetting, templateSetting]
  );

  function handleUpdateTemplateSetting(field: keyof TemplateSettingType, value: any) {
    setTemplateSetting({
      ...templateSetting,
      [field]: value,
    });
  }

  const SettingAccordContainer = ({
    accordKey,
    header,
    body,
  }: {
    accordKey: string;
    header: React.ReactNode;
    body: React.ReactNode;
  }) => {
    return (
      <div key={accordKey}>
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', cursor: 'pointer' }}>
          {header}
        </div>
        <React.Fragment>
          <Divider style={{ margin: '5px 0px 10px 0px' }} />
          {body}
        </React.Fragment>
      </div>
    );
  };

  const handleOnUpload = async (uploadedItems: PhotoImageDataItem[]) => {
    handleUpdateTemplateSetting(
      'backgroundAssets',
      templateSetting.backgroundAssets.concat(
        uploadedItems.map(item => ({
          value: item.url,
          variant: BackgroundAssetVariant.Image,
        }))
      )
    );
  };

  const handleAddColor = (color: string) => {
    const added = templateSetting.backgroundAssets.some(asset => asset.value === color);
    if (templateSetting.backgroundAssets.length === 0 || !added) {
      handleUpdateTemplateSetting(
        'backgroundAssets',
        templateSetting.backgroundAssets.concat({
          value: color,
          variant: BackgroundAssetVariant.Color,
        })
      );
    }
  };

  const handleRemoveBackgroundAsset = (asset: BackgroundAsset) => {
    handleUpdateTemplateSetting(
      'backgroundAssets',
      templateSetting.backgroundAssets.filter(_asset => _asset.value !== asset.value)
    );
  };

  useEffect(() => {
    const init = async () => {
      setTemplateLoading(true);
      try {
        const templates: string[] = await heroApiService.retrieveReviewTemplates(mockReview);
        setReviewTemplateUrls(templates);
      } catch (e) {
        console.log(e);
      }
      setTemplateLoading(false);
    };
    init();
  }, [mockReview]);

  const BackgroundAssetList = ({ assetVariant }: { assetVariant: BackgroundAssetVariant }) => {
    const assets = templateSetting.backgroundAssets.filter(asset => asset.variant === assetVariant);
    return (
      <LoadableContainer
        isLoading={assets.length === 0}
        loadComponent={
          <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>No item found</span>
        }>
        <Space style={{ flexWrap: 'wrap' }}>
          {assets.map(asset => (
            <div
              style={{
                ...(assetVariant === BackgroundAssetVariant.Color
                  ? {
                      background: asset.value,
                    }
                  : {
                      background: `url(${asset.value})`,
                      backgroundSize: 'cover',
                    }),
                width: 35,
                height: 35,
                position: 'relative',
                borderRadius: 5,
              }}>
              {assets.length > 1 && (
                <Tooltip title={'Remove'}>
                  <div
                    onClick={() => handleRemoveBackgroundAsset(asset)}
                    style={{
                      position: 'absolute',
                      top: -10,
                      right: -5,
                      background: 'white',
                      border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                      borderRadius: '50%',
                      height: 15,
                      width: 15,
                      cursor: 'pointer',
                      ...MIDDLE_STYLE,
                    }}>
                    <CloseOutlined style={{ fontSize: 8, margin: 0 }} />
                  </div>
                </Tooltip>
              )}
            </div>
          ))}
        </Space>
      </LoadableContainer>
    );
  };

  const handleSaveChanges = async () => {
    if (!templateSetting) return;
    try {
      setTemplateUpdating(true);
      await heroApiService.updateReviewTemplateSetting(templateSetting);
      await dispatch(fetchSelectedLocation());
      setTemplateUpdating(false);
      dispatch(
        enqueueNotification({
          name: 'Successfully update review template',
          description: 'Successfully update review template',
          type: 'Success',
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update review template',
          description: 'Failed to update review template',
          type: 'Error',
        })
      );
    }
    setTemplateLoading(false);
  };

  return (
    <Space direction={'vertical'} size={'large'} style={{ width: '100%' }}>
      <SettingAccordContainer
        accordKey={'background-accord'}
        header={
          <DescriptionItem
            title={'Background'}
            style={{ ...MIDDLE_STYLE, margin: 0, justifyContent: 'space-between' }}
            content={
              <Radio.Group
                onChange={e => handleUpdateTemplateSetting('backgroundType', e.target.value)}
                value={templateSetting.backgroundType}>
                <Radio value={BackgroundType.FixedImage}>Fixed Image</Radio>
                <Radio value={BackgroundType.RandomImage}>Random Image</Radio>
                <Radio value={BackgroundType.FixedColor}>Fixed Color</Radio>
                <Radio value={BackgroundType.RandomColor}>Random Color</Radio>
              </Radio.Group>
            }
          />
        }
        body={
          <React.Fragment>
            {templateSetting.backgroundType === BackgroundType.RandomColor && (
              <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Color of the showcase review post background will be automatically generated. Please
                update to <span style={{ fontWeight: 'bold' }}>Fixed Color</span> mode to specify
                the background color for your showcase review post.
              </div>
            )}
            {templateSetting.backgroundType === BackgroundType.RandomImage && (
              <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Background image of the showcase review post will be automatically selected from the
                media library. Please update to{' '}
                <span style={{ fontWeight: 'bold' }}>Fixed Image</span> mode to specify the
                background image for your showcase review post.
              </div>
            )}
            {templateSetting.backgroundType === BackgroundType.FixedColor && (
              <div>
                <DescriptionItem
                  title={'Color mode'}
                  titleStyle={{ fontWeight: 'normal' }}
                  style={{ justifyContent: 'space-between' }}
                  content={
                    <Radio.Group onChange={e => setColorMode(e.target.value)} value={colorMode}>
                      <Radio value={BackgroundColorMode.Solid}>Solid</Radio>
                      <Radio value={BackgroundColorMode.Gradient}>Gradient</Radio>
                    </Radio.Group>
                  }
                />
                {colorMode === BackgroundColorMode.Solid && (
                  <DescriptionItem
                    title={'Background color'}
                    titleStyle={{ fontWeight: 'normal' }}
                    style={{ justifyContent: 'space-between' }}
                    content={
                      <Space>
                        <SolidColorSettingButton
                          value={currentSolidColor}
                          onValueChanged={setCurrentSolidColor}
                        />
                        <Button onClick={() => handleAddColor(currentSolidColor)}>Add color</Button>
                      </Space>
                    }
                  />
                )}
                {colorMode === BackgroundColorMode.Gradient && (
                  <div
                    style={{
                      ...MIDDLE_STYLE,
                      justifyContent: 'flex-start',
                      flexWrap: 'wrap',
                      paddingBottom: 10,
                    }}>
                    {palettes.map((pallete, _) => (
                      <div
                        onClick={() =>
                          handleAddColor(
                            `linear-gradient(${135}deg, ${pallete.from}, ${pallete.to})`
                          )
                        }
                        style={{ margin: '5px 5px', cursor: 'pointer' }}>
                        <Palette angle={135} from={pallete.from} to={pallete.to} />
                      </div>
                    ))}
                  </div>
                )}
                <DescriptionItem
                  title={'Added color set'}
                  titleStyle={{ fontWeight: 'normal' }}
                  style={{ justifyContent: 'space-between' }}
                  content={<BackgroundAssetList assetVariant={BackgroundAssetVariant.Color} />}
                />
              </div>
            )}
            {templateSetting.backgroundType === BackgroundType.FixedImage && (
              <div>
                <DescriptionItem
                  title={'Images'}
                  titleStyle={{ fontWeight: 'normal' }}
                  style={{ justifyContent: 'space-between' }}
                  content={
                    <Space>
                      <Button
                        onClick={() =>
                          dispatch(
                            openModal({
                              modalName: 'uploadImageModal',
                            })
                          )
                        }
                        style={{ ...MIDDLE_STYLE }}>
                        <TbPhoto /> Add background image
                      </Button>
                    </Space>
                  }
                />
                <DescriptionItem
                  title={'Added image set'}
                  titleStyle={{ fontWeight: 'normal' }}
                  style={{ justifyContent: 'space-between' }}
                  content={<BackgroundAssetList assetVariant={BackgroundAssetVariant.Image} />}
                />
              </div>
            )}
          </React.Fragment>
        }
      />
      <SettingAccordContainer
        accordKey={'template-accord'}
        header={
          <DescriptionItem
            title={'Template'}
            style={{ ...MIDDLE_STYLE, margin: 0, justifyContent: 'space-between' }}
          />
        }
        body={
          <div style={{ marginTop: 20 }}>
            <LoadableContainer isLoading={templateLoading} loadComponent={<Skeleton />}>
              <Space>
                {reviewTemplateUrls.map((reviewTemplateUrl, index) => (
                  <div
                    onClick={() => handleUpdateTemplateSetting('templateId', index)}
                    style={{
                      cursor: 'pointer',
                      border:
                        templateSetting.templateId === index
                          ? `5px solid ${GLOBAL_THEME_COLOR.$highlight_color}`
                          : 'unset',
                      background: `url(${reviewTemplateUrl})`,
                      backgroundSize: 'cover',
                      height: 150,
                      width: 150,
                      position: 'relative',
                    }}>
                    {templateSetting.templateId === index && (
                      <div
                        style={{
                          border: `5px solid ${GLOBAL_THEME_COLOR.$highlight_color}`,
                          position: 'absolute',
                          top: -10,
                          left: 10,
                          backgroundColor: GLOBAL_THEME_COLOR.$highlight_color,
                          padding: '2px 10px',
                          borderRadius: 5,
                          fontSize: 10,
                          color: GLOBAL_THEME_COLOR.$text_color_contrast,
                          fontWeight: 'bold',
                        }}>
                        Selected
                      </div>
                    )}
                  </div>
                ))}
              </Space>
            </LoadableContainer>
          </div>
        }
      />
      <Space style={{ width: '100%', flexWrap: 'wrap' }}>
        <Button style={{ width: '100%' }} danger>
          Discard
        </Button>
        {updated && (
          <Button
            loading={templateUpdating}
            onClick={handleSaveChanges}
            style={{ width: '100%' }}
            type={'primary'}
            className={'success-btn'}>
            <SaveOutlined /> Update template
          </Button>
        )}
      </Space>
      <UploadImageModal
        actionTitle="Add background image to template"
        onUpload={handleOnUpload}
        disabledSquarePhotoSource
      />
    </Space>
  );
};

export default TemplateEditContainer;
