/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { DefendResponseType } from '@core/models';
import { Button, Input, Radio, Space, Switch } from 'antd';
import { activateAutopilot } from '@core/features/business/thunks/activateAutopilot.thunk';
import { deactivateAutopilot } from '@core/features/business/thunks/deactivateAutopilot.thunk';
import { updateDefendResponse } from '@core/features/business/thunks/updateDefendResponse.thunk';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { socialStudioApiService } from '@services/service-register';
import { SnackbarItem, enqueueNotification } from '@core/features';
import { fetchCurrentUser } from '@core/features/auth/thunks';

type Props = {};

const EnableAutomaticResponseContainer = (props: Props) => {
  const dispatch = useAppDispatch();
  const { user, isAuthLoading } = useAuthState();
  const selectedLocation = useMemo(() => user?.selected_location, [user]);
  const [enablePositivePilot, setEnablePositivePilot] = useState(false);
  const [enableNegativePilot, setEnableNegativePilot] = useState(false);
  const [negativePilotOption, setNegativePilotOption] = useState(DefendResponseType.AI_RESPONSE);
  const [negativeReviewResponse, setNegativeReviewResponse] = useState('');

  const handleUpdateNegativeReviewResponse = async () => {
    try {
      await socialStudioApiService.updateLocationStudioPilot({
        negativeReviewResponse,
      });
      await dispatch(fetchCurrentUser());
    } catch (error: any) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update negative review response',
          type: 'Error',
        } as SnackbarItem)
      );
    }
  };

  const handleActivateAutopilot = async () => {
    await dispatch(activateAutopilot());
    setEnablePositivePilot(true);
  };

  const handleUpdateDefendResponseClient = (type: DefendResponseType) => {
    setEnableNegativePilot(type !== DefendResponseType.NONE);
    setNegativePilotOption(type);
  };

  const handleUpdateDefendResponse = async (type: DefendResponseType) => {
    await dispatch(updateDefendResponse(type));
    handleUpdateDefendResponseClient(type);
  };

  const handleToggleDefend = async (isChecked: boolean) => {
    if (isChecked) {
      await handleUpdateDefendResponse(DefendResponseType.AI_RESPONSE);
    } else {
      await handleUpdateDefendResponse(DefendResponseType.NONE);
    }
  };

  const handleDeactivateAutopilot = async () => {
    await dispatch(deactivateAutopilot());
    setEnablePositivePilot(false);
  };

  useEffect(() => {
    if (selectedLocation) {
      const positiveAutoPilot = selectedLocation.auto_pilot_activated;
      const locationDefendResponseType = selectedLocation.defend_response_type;
      setEnablePositivePilot(positiveAutoPilot || false);
      handleUpdateDefendResponseClient(locationDefendResponseType);
      const _negativeReviewResponse = selectedLocation.studio_pilot_setting.negativeReviewResponse;
      if (_negativeReviewResponse) setNegativeReviewResponse(_negativeReviewResponse);
    }
  }, [selectedLocation]);

  return (
    <div style={{ padding: '20px 0px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div>Enable automatic response for positive reviews (3-5 stars)</div>
        {user?.selected_location && !isAuthLoading ? (
          <Space>
            <Switch
              defaultChecked={user?.selected_location.auto_pilot_activated}
              checked={enablePositivePilot}
              onChange={isChecked => {
                !isChecked ? handleDeactivateAutopilot() : handleActivateAutopilot();
              }}
            />
          </Space>
        ) : (
          <LoadingOutlined />
        )}
      </div>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div>Enable automatic response for negative reviews (1-2 stars)</div>
        {selectedLocation && !isAuthLoading ? (
          <Space>
            <Switch
              defaultChecked={selectedLocation.defend_response_type !== DefendResponseType.NONE}
              checked={enableNegativePilot}
              onChange={isChecked => handleToggleDefend(isChecked)}
            />
          </Space>
        ) : (
          <LoadingOutlined />
        )}
      </div>
      <div>
        <Radio.Group
          onChange={e => handleUpdateDefendResponse(e.target.value)}
          value={negativePilotOption}>
          <Radio value={DefendResponseType.AI_RESPONSE} disabled={!enableNegativePilot}>
            AI Generated Response
          </Radio>
          <Radio value={DefendResponseType.RECTIFICATION} disabled={!enableNegativePilot}>
            Fixed Response
          </Radio>
        </Radio.Group>
        {selectedLocation && negativePilotOption === DefendResponseType.RECTIFICATION && (
          <div>
            <Input.TextArea
              style={{ marginTop: 20 }}
              onChange={e => setNegativeReviewResponse(e.target.value)}
              value={negativeReviewResponse}></Input.TextArea>
            {negativeReviewResponse !==
              selectedLocation.studio_pilot_setting.negativeReviewResponse && (
              <Button
                style={{ marginTop: 10 }}
                type="primary"
                onClick={handleUpdateNegativeReviewResponse}>
                <EditOutlined /> Update a Fixed Response
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EnableAutomaticResponseContainer;
