import { useAppNavigation } from '@core/hooks/useAppNavigation';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import {
  BlogPostModel,
  LicenseType,
  LocalLocation,
  SocialPost,
  SubscriptionCreditUsage,
} from '@core/models';
import { useCallback, useMemo } from 'react';
import {
  LICENSE_ACTIVATION_THRESHOLD,
  SCHEDULED_SOCIAL_POSTS_THRESHOLD,
} from '@constants/subscription';
import { resetAllModal } from '@core/features';
import { heroApiService } from '@services/service-register';

export const useSubscriptionDispatcher = () => {
  const { navigate } = useAppNavigation();
  const dispatch = useAppDispatch();
  const { user, integration: social } = useAuthState();
  const { integration } = useBusinessState();

  const selectedLocation = useMemo(() => user?.selected_location, [user]);

  const isCurrentLocationSubscribed = useMemo(
    () => selectedLocation?.license_type === LicenseType.Pro,
    [selectedLocation]
  );

  // PASSIVE TRIGGERS: Subscribed or below credit usage
  const checkPassiveTriggerActivated = (
    location: LocalLocation,
    actionGroupName: keyof SubscriptionCreditUsage
  ) => {
    const threshold = LICENSE_ACTIVATION_THRESHOLD[actionGroupName];
    const currentCreditUsage = location.subscription_credit_usage[actionGroupName] || 0;
    return (
      location.license_type === LicenseType.Pro || (threshold > 0 && currentCreditUsage < threshold)
    );
  };

  const checkSocialStudioNotActivated = useCallback(
    (socialPosts: SocialPost[]) => {
      // ACTIVE TRIGGERS: After the users connect social accounts and have at least 2 posts in schedule, show the message as below:
      const reachedScheduledSocialPosts = socialPosts.length >= SCHEDULED_SOCIAL_POSTS_THRESHOLD;
      const selectedFacebookPages = integration.facebook_pages?.length || 0;
      const selectedInstagramProfiles = integration.instagram_profiles?.length || 0;
      return (
        !isCurrentLocationSubscribed &&
        reachedScheduledSocialPosts &&
        // No facebook account connected and no pages selected
        (social.facebook || selectedFacebookPages + selectedInstagramProfiles > 0)
      );
    },
    [
      integration.facebook_pages,
      integration.instagram_profiles,
      isCurrentLocationSubscribed,
      social.facebook,
    ]
  );

  const checkBlogStudioNotActivated = useCallback(
    (blogPosts: BlogPostModel[]) => {
      // ACTIVE TRIGGERS: After the users connect social accounts and have at least 2 posts in schedule, show the message as below:
      const reachedTotalBlogPosts = blogPosts.length >= SCHEDULED_SOCIAL_POSTS_THRESHOLD;
      return !isCurrentLocationSubscribed && reachedTotalBlogPosts;
    },
    [isCurrentLocationSubscribed]
  );

  const handleActivateNow = () => {
    navigate('subscription');
    dispatch(resetAllModal({}));
  };

  const handleUpdateCreditUsage = (locationId: string, groupName: keyof SubscriptionCreditUsage) =>
    heroApiService.updateCreditGroupUsage(locationId, groupName);

  const getLicenseDetails = (location: LocalLocation | undefined) => {
    switch (location?.license_type) {
      case LicenseType.Starter:
        return 'Starter';
      case LicenseType.Pro:
        return 'Pro';
    }
  };

  return {
    handleActivateNow,
    handleUpdateCreditUsage,
    isCurrentLocationSubscribed,
    checkSocialStudioNotActivated,
    checkPassiveTriggerActivated,
    checkBlogStudioNotActivated,
    getLicenseDetails,
  };
};
