import { useEffect, useState } from 'react';
import { useBusinessState } from '..';

export const useSocialIntegrationState = () => {
  const [selectedSocialChannels, setSelectedSocialChannels] = useState<Record<string, boolean>>({});

  const { integration } = useBusinessState();
  useEffect(() => {
    if (integration.facebook_pages && integration.instagram_profiles) {
      const socialChannels: Record<string, boolean> = {};
      for (const page of [...integration.facebook_pages, ...integration.instagram_profiles]) {
        socialChannels[page.id] = true;
      }
      setSelectedSocialChannels(socialChannels);
    }
  }, [integration.facebook_pages, integration.instagram_profiles]);

  return {
    selectedSocialChannels,
    setSelectedSocialChannels,
  };
};
