/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DashboardScreen from './DashboardScreen';

type Props = {};

const HomeScreen = (props: Props) => {
  return <DashboardScreen />;
};

export default HomeScreen;
