import React from 'react';
import { renderStatusColor, renderStatusIcon, renderStatusStr } from '@utils/social-studio.util';
import { BlogPostStage, SocialPostStage } from '@core/models';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';

const StatusBadge: React.FC<{ status: SocialPostStage | BlogPostStage }> = ({ status }) => {
  return (
    <Tag
      bordered
      style={{
        backgroundColor: renderStatusColor(status),
        border: `1px solid ${renderStatusColor(status)}`,
        width: 'fit-content',
        color: 'white',
        padding: '1px 7px',
        fontSize: '11px',
        borderRadius: 5,
        cursor: 'pointer',
        fontWeight: 600,
      }}>
      {status === SocialPostStage.Pending || status === BlogPostStage.Pending ? (
        <span>
          <LoadingOutlined style={{ marginRight: 10 }} /> Pending
        </span>
      ) : (
        <div style={{ ...MIDDLE_STYLE }}>
          {renderStatusStr(status)}{' '}
          {renderStatusIcon(status) &&
            React.createElement(renderStatusIcon(status), { style: { marginLeft: 5 } })}{' '}
        </div>
      )}
    </Tag>
  );
};

export default StatusBadge;
