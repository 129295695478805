import { GoogleReview, Review } from '@core/models';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewReducerProps } from '../type';

type Props = {
  review: GoogleReview | Review;
  existed: boolean;
};

export const saveReview = createAsyncThunk(
  'review/saveReview',
  async ({ review, existed }: Props) => {
    const result = await heroApiService.saveReview(review, existed);
    return result;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(saveReview);

export const saveReviewReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.review = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<ReviewReducerProps>;
