import { CheckCircleFilled } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useBusinessState } from '@core/configureStore';
import { AppIntegrationKey, INTEGRATION_APP_REGISTRY, IntegrationType } from '@core/features';
import React from 'react';

type Props = {
  appId: AppIntegrationKey;
  style?: React.CSSProperties;
};

const AppIntegrationStatusBadge = ({ appId, style }: Props) => {
  const { integration } = useBusinessState();
  return (
    <div style={{ ...style }}>
      {INTEGRATION_APP_REGISTRY[appId] === IntegrationType.Activation && (
        <React.Fragment>
          {integration[appId] && (
            <div
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                backgroundColor: GLOBAL_THEME_COLOR.$success_color,
                borderRadius: 20,
                padding: '0px 10px',
                height: 30,
              }}>
              <div style={{ color: GLOBAL_THEME_COLOR.$text_color_contrast, fontWeight: 'bold' }}>
                <CheckCircleFilled style={{ marginRight: 10 }} /> Connected
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default AppIntegrationStatusBadge;
