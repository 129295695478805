import React from 'react';
import { StudioPhotoCollection } from '@core/models';
import { Card } from 'antd';
import moment from 'moment';
import { DATE_FORMAT, getMomentFromDateConfig } from '@utils/date.util';
import MediaItemGridLayout from '@components/MediaItemGridLayout';
import { TbReplace } from 'react-icons/tb';
import { STRIPE_BOX_SHADOW } from '@constants/theme';

type Props = {
  payload: StudioPhotoCollection;
  handlePhotoPayloadClicked: any;
};

const PreviewPostPayloadItem = ({ payload, handlePhotoPayloadClicked }: Props) => {
  return (
    <Card
      className="no-padding"
      style={{
        maxWidth: 300,
        minWidth: 250,
        marginRight: 10,
        marginTop: 5,
        borderRadius: 10,
        boxShadow: STRIPE_BOX_SHADOW,
      }}>
      <React.Fragment>
        <h3 style={{ fontSize: 13, padding: '15px 15px 0px 15px' }}>
          Scheduled at {moment(getMomentFromDateConfig(payload)).format(DATE_FORMAT)}
        </h3>
        <MediaItemGridLayout
          onClickEach={handlePhotoPayloadClicked}
          renderOverlay={() => <TbReplace />}
          images={payload.items.map(item => item.url)}
        />
      </React.Fragment>
    </Card>
  );
};

export default PreviewPostPayloadItem;
