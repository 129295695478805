import { RootState } from '@core/configureStore';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { DefendResponseType } from '@core/models';
import { fetchCurrentUser } from '@core/features/auth/thunks';

export const updateDefendResponse = createAsyncThunk(
  'business/updateDefendResponse',
  async (payload: DefendResponseType, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('No user found');
      const locationId = user.selected_location.location_id;
      const result = await heroApiService.updateDefendResponse(locationId, payload);
      await thunkApi.dispatch(fetchCurrentUser());
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to update auto defend',
          description: 'There was error updating auto defend. Please refresh the page',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(updateDefendResponse);

export const updateDefendResponseReducer = {
  [fulfilled]: (state, action) => {
    state.defendResponseType = action.payload.defend_response_type;
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.isLoading = true;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
