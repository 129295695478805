/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useMemo, useState } from 'react';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useAuthState } from '@core/configureStore';
import { buildCallbackURL } from '@utils/string.util';
import { Button, Col, Divider, Row } from 'antd';
import { BRAND_NAME } from '@constants/brand';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { ArrowRightOutlined } from '@ant-design/icons/lib';

type Props = {};

const ManualSharingScreen = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const [copied, setCopied] = useState(false);
  const { user } = useAuthState();
  const callbackURL = useMemo(
    () =>
      user && user.selected_location ? buildCallbackURL(user.selected_location.location_id) : '',
    [user]
  );
  const handleCopyURL = () => {
    navigator.clipboard.writeText(callbackURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <div>
      <Row>
        <Col span={isMobile ? 24 : 8}>
          <div
            className="screen-card"
            style={{ borderRight: `1px solid ${GLOBAL_THEME_COLOR.$border_color}` }}>
            <h3>Manual Sharing</h3>
            <Divider />
            <p>Share the link below to anyone to collect new reviews</p>
            <Button
              icon={
                copied ? (
                  <CheckOutlined
                    style={{ color: GLOBAL_THEME_COLOR.$success_color, fontWeight: 'bold' }}
                  />
                ) : (
                  <CopyOutlined />
                )
              }
              onClick={handleCopyURL}
              style={{
                width: '100%',
                wordBreak: 'break-all',
                overflow: 'hidden',
                marginTop: 30,
                marginBottom: 30,
              }}>
              {callbackURL}
            </Button>
            <br />
            <p>
              {BRAND_NAME} defends your brands by only allowing positive reviews to be made public.
              Negative reviews will be routed to you privately.
            </p>
            <br />
            <Button type="primary" href={callbackURL} target={'_blank'}>
              Test Your Review Page <ArrowRightOutlined style={{ marginLeft: 10 }} />
            </Button>
          </div>
        </Col>
        <Col span={isMobile ? 24 : 16}>
          <h3 style={{ marginLeft: 40, fontWeight: 'normal' }}>
            Your review page automatically filters negative reviews.
          </h3>
          <img src={'/images/defend.png'} />
        </Col>
      </Row>
    </div>
  );
};

export default ManualSharingScreen;
