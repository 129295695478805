/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuthState, useInquiryState, useReviewState } from '@core/configureStore';
import { List, Pagination, Row } from 'antd';
import { AnimatedComponent, CenteredCardColumn, ReviewCard } from '..';
import { ReviewFilterStatus, ReviewSortOrder } from '@core/features';
import { GoogleReview } from '@core/models';
import moment from 'moment';
import { RateConversion } from '@constants/rate-conversion';
import SplashScreen from '@components/SplashScreen';
import { heroApiService } from '@services/service-register';
import { cloneArray } from '@utils/array.util';

const REVIEWS_PER_PAGE = 15;

type Props = {
  reviews: Array<GoogleReview>;
  style?: React.CSSProperties;
  size: 'small' | 'large';
  partition?: {
    start: number;
    end: number;
  };
};

const ReviewTable = (props: Props) => {
  const { size } = props;
  const { isAuthLoading: isLoadingUser } = useAuthState();
  const {
    reviewResponse,
    isLoading: isLoadingReview,
    filterStatus,
    sortBy,
    sortOrder,
  } = useReviewState();
  const { inquiries } = useInquiryState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(REVIEWS_PER_PAGE);
  const [filterReviews, setFilterReviews] = React.useState<GoogleReview[]>(props.reviews || []);
  const [sortedReviews, setSortedReviews] = React.useState<GoogleReview[]>(props.reviews || []);

  useEffect(() => {
    if (props.partition) setPageSize(props.partition.end - props.partition.start);
  }, [props.partition]);

  useEffect(() => {
    const invokeFilterReviews = async () => {
      if (props.reviews) {
        switch (filterStatus) {
          case ReviewFilterStatus.Generated:
            const result: GoogleReview[] = [];
            for (let review of props.reviews) {
              const localReview = await heroApiService.getLocalReviewByGoogleId(review.reviewId);
              if (localReview && localReview.generated_times > 0) {
                result.push(review);
              }
            }
            setFilterReviews(result);
            break;
          case ReviewFilterStatus.Responded:
            setFilterReviews(props.reviews.filter(review => review.reviewReply !== undefined));
            break;
          case ReviewFilterStatus.NotResponded:
            setFilterReviews(props.reviews.filter(review => review.reviewReply === undefined));
            break;
          case ReviewFilterStatus.All:
          default:
            setFilterReviews(props.reviews);
            break;
        }
      }
    };
    invokeFilterReviews();
  }, [filterStatus, props.reviews]);

  useEffect(() => {
    const order = sortOrder === ReviewSortOrder.DESC ? 1 : -1;
    switch (sortBy) {
      case 'date':
        setSortedReviews(
          cloneArray(filterReviews).sort(
            (reviewA, reviewB) =>
              (moment(reviewA.updateTime).unix() > moment(reviewB.updateTime).unix() ? -1 : 1) *
              order
          )
        );
        break;
      case 'rating':
        setSortedReviews(
          cloneArray(filterReviews).sort(
            (reviewA, reviewB) =>
              (RateConversion[reviewA.starRating] > RateConversion[reviewB.starRating] ? -1 : 1) *
              order
          )
        );
        break;
      case 'name':
        setSortedReviews(
          cloneArray(filterReviews).sort((reviewA, reviewB) => {
            return (
              (reviewA.reviewer.displayName.toUpperCase() <
              reviewB.reviewer.displayName.toUpperCase()
                ? -1
                : 1) * order
            );
          })
        );
        break;
      default:
        setSortedReviews(filterReviews);
        break;
    }
  }, [sortBy, sortOrder, filterReviews]);

  return (
    <div style={props.style}>
      {reviewResponse.totalReviewCount === 0 && (isLoadingUser || isLoadingReview) ? (
        <SplashScreen />
      ) : (
        <React.Fragment>
          <Row style={{ marginBottom: 20 }}>
            <CenteredCardColumn span={size === 'small' ? 3 : 2} />
            <CenteredCardColumn span={9}>
              <h4>Customer name</h4>
            </CenteredCardColumn>
            <CenteredCardColumn span={size === 'small' ? 5 : 4}>
              <h4>Status</h4>
            </CenteredCardColumn>
            <CenteredCardColumn span={size === 'small' ? 5 : 4}>
              <h4>Rating</h4>
            </CenteredCardColumn>
            {inquiries && size !== 'small' && (
              <CenteredCardColumn span={3}>
                <h4>Contact</h4>
              </CenteredCardColumn>
            )}
          </Row>
          {props.size === 'small' ? (
            <List
              dataSource={
                props.partition ? (props.reviews || []).slice(0, pageSize) : props.reviews
              }
              renderItem={item => (
                <ReviewCard
                  size={props.size}
                  inquiry={inquiries.find(inquiry => inquiry.review_id === item.reviewId)}
                  item={item}
                  key={item.reviewId}
                />
              )}
            />
          ) : (
            <List
              dataSource={sortedReviews.slice((page - 1) * pageSize, page * pageSize)}
              renderItem={(item, index) => (
                <AnimatedComponent.OpacityFadeInDiv delay={200 + index * 50}>
                  <ReviewCard
                    size={props.size}
                    item={item}
                    inquiry={inquiries.find(inquiry => inquiry.review_id === item.reviewId)}
                    key={item.reviewId}
                  />
                </AnimatedComponent.OpacityFadeInDiv>
              )}
            />
          )}
        </React.Fragment>
      )}
      {props.size !== 'small' && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
          <Pagination
            onChange={(page: number) => setPage(page)}
            defaultCurrent={page}
            pageSize={pageSize}
            onShowSizeChange={(_, pageSize) => {
              setPageSize(pageSize);
            }}
            total={sortedReviews.length}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewTable;
