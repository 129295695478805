import { RootState } from '@core/configureStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequestReducerProps } from '../type';

export const fetchReviewRequests = createAsyncThunk(
  'review-request/fetchReviewRequests',
  async (_, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('Not user found');

      const result = await heroApiService.getAllReviewRequests(user.selected_location?.location_id);
      return result;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchReviewRequests);

export const fetchReviewRequestsReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.requests = action.payload.items;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.error = '';
    state.requests = [];
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
