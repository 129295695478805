import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { ModalReducerProps } from './type';

const defaultModalState: ModalReducerProps = {
  welcomeLocationModal: false,
  selectSocialAccountsModal: false,
  locationModal: false,
  customizeAiProfileModal: false,
  customizeBlogAiProfileModal: false,
  customizeAiResponderModal: false,
  calendarModal: false,
  socialPostModal: false,
  manageLicenseModal: false,
  uploadBulkRequestCsvModal: false,
  calendarItemDetailModal: false,
  facebookPostCreationModal: false,
  pitchGenerateOverlay: false,
  assistantScheduleSettingModal: false,
  socialPostCreationModal: false,
  previewGenerateScheduleModal: false,
  previewPhotoPickerModal: false,
  blogOnboardingModal: false,
  shareToSocialChannelsModal: false,
  subscriptionCancellationModal: false,
  freelancerModeSetupModal: false,
  socialPostSelectionModal: false,
  uploadImageModal: false,
  createNewReviewRequestModal: false,
  extraParams: {},
};

const modalSlice = createSlice<ModalReducerProps, SliceCaseReducers<ModalReducerProps>, string>({
  name: SliceName.Modal,
  initialState: defaultModalState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        modalName: keyof ModalReducerProps;
        extraParams: Record<string, string>;
      }>
    ) => {
      state[action.payload.modalName] = true as any;
      state.extraParams = {
        ...state.extraParams,
        ...action.payload.extraParams,
      };
      return state;
    },
    closeModal: (
      state,
      action: PayloadAction<{
        modalName: keyof ModalReducerProps;
      }>
    ) => {
      state[action.payload.modalName] = false as any;
      return state;
    },
    resetAllModal: state => {
      state = defaultModalState;
      return state;
    },
  },
});

export const { openModal, closeModal, resetAllModal } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
