import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Modal, Skeleton } from 'antd';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { closeModal, openModal } from '@core/features';
import { SocialPostType } from '@core/models';
import { getBlogPostRangeSchedules, getSocialPostRangeSchedules } from '@utils/date.util';
import moment from 'moment/moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { LoadableContainer } from '@components/index';

const PreviewGenerateScheduleModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { previewGenerateScheduleModal, extraParams } = useModalState();
  const onGenerate = useMemo<(schedules: number[]) => any | undefined>(
    () => extraParams?.onGenerate,
    [extraParams]
  );
  const month = useMemo<string | undefined>(() => extraParams?.month, [extraParams]);
  const [loading, setLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<number[]>([]);
  const socialPostType = useMemo<(SocialPostType & 'blog') | undefined>(
    () => extraParams?.postType,
    [extraParams]
  );

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'previewGenerateScheduleModal',
      })
    );
  };

  const handleGenerate = () => {
    handleCloseModal();
    onGenerate && onGenerate(schedules);
  };

  const handleViewSchedule = () => {
    handleCloseModal();
    dispatch(
      openModal({
        modalName: 'customizeAiProfileModal',
      })
    );
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      if (month && socialPostType !== undefined) {
        try {
          const currentMonth = moment(month, MONTH_KEY_DATE_FORMAT);
          if (socialPostType === 'blog') {
            const schedules = await getBlogPostRangeSchedules(currentMonth.startOf('month'), 0);
            setSchedules(schedules);
          } else {
            const { socialPostSchedules, showcaseSchedules } = await getSocialPostRangeSchedules(
              currentMonth.startOf('month'),
              0
            );
            if (socialPostType === SocialPostType.ShowcaseReview) {
              setSchedules(showcaseSchedules);
            } else {
              setSchedules(socialPostSchedules);
            }
          }
        } catch (e) {
          console.log(e);
        }
        setLoading(false);
      }
    };
    init();
  }, [month, socialPostType, previewGenerateScheduleModal]);

  return (
    <Modal
      title={`Preview Schedules`}
      centered
      open={previewGenerateScheduleModal}
      onCancel={handleCloseModal}
      width={'100%'}
      style={{ maxWidth: 600, overflow: 'hidden' }}
      footer={[
        <Button onClick={handleCloseModal}>Cancel</Button>,
        <Button disabled={schedules.length === 0} type={'primary'} onClick={handleGenerate}>
          {socialPostType === SocialPostType.ShowcaseReview ? 'Showcase Reviews' : 'Generate Posts'}
        </Button>,
      ]}>
      {schedules.length > 0 ? (
        <div>Amazely will schedule {schedules.length} posts for this month on the dates below.</div>
      ) : (
        <div>
          No remaining posts for this month based on your{' '}
          <span style={{ fontWeight: 'bold' }}>selected schedule</span>. Try generating posts for
          the next month or updating the schedule.
        </div>
      )}
      <Divider />
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        {schedules.length > 0 && (
          <React.Fragment>
            <ul>
              {schedules.map(schedule => (
                <li>{moment.unix(schedule).format('DD MMMM YYYY')}</li>
              ))}
            </ul>
            <Divider />
          </React.Fragment>
        )}
        <div style={{ marginTop: 20 }}>
          The dates are selected based on{' '}
          <span
            style={{
              fontWeight: 'bold',
              color: GLOBAL_THEME_COLOR.$highlight_color,
              cursor: 'pointer',
            }}
            onClick={handleViewSchedule}>
            your schedule.
          </span>{' '}
          {socialPostType === SocialPostType.Default &&
            'Amazely will select photos from your gallery.'}
        </div>
      </LoadableContainer>
    </Modal>
  );
};

export default PreviewGenerateScheduleModal;
