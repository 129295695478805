import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { AuthReducerProps, AuthState } from '../type';

export const logout = createAsyncThunk('auth/logout', async () => {
  let result = await heroApiService.logout();
  return result;
});

const [fulfilled, pending, rejected] = getSideEffectState(logout);

export const logoutReducer = {
  [fulfilled]: (state, _) => {
    state.isAuthLoading = false;
    state.authState = AuthState.Unauthenticated;
    state.user = undefined;
    state.isSupportSession = false;
  },
  [pending]: (state, _) => {
    state.isAuthLoading = true;
  },
  [rejected]: (state, _) => {
    state.isAuthLoading = false;
    state.authState = AuthState.None;
  },
} as ExtraReducer<AuthReducerProps>;
