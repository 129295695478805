import { ThirdPartyOauthType } from '@core/models/social-account';
import { LocalLocation } from './user';

export enum SocialPostStage {
  Draft = 0,
  Pending = 1,
  Scheduled = 2,
  Queued = 3,
  Published = 4,
}

export enum SocialPostType {
  Default = 0,
  ShowcaseReview = 1,
  BlogPost = 2,

  GoogleBusinessPost = 999,
}

export type SocialAccountContact = {
  name: string;
  profile_picture_url: string | undefined;
  profile_id: string;
  type: ThirdPartyOauthType;
};

export enum SocialPostMadeBy {
  User = 0,
  System = 1,
}

export type SocialPost = {
  id: number;
  content: string;
  mediaIds: string[];
  created_date: string;
  updated_date: string;
  publish_at: string | null;
  status: SocialPostStage;
  published_accounts: SocialAccountContact[] | null;
  type: SocialPostType;
  made_by: SocialPostMadeBy;
  topic?: string | undefined;
  location?: LocalLocation;
};

export enum SocialPlatform {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
}

export type SocialPlatformStr = 'facebook' | 'instagram' | 'google' | 'unsplash' | 'pexels';

export enum FacebookPostSizeMode {
  Landscape = 'Landscape',
  Square = 'Square',
}

export enum InstagramPostSizeMode {
  Square = 'Square',
}

export type FacebookPost = {
  created_time: string;
  full_picture: string;
  id: string;
  message: string;
};

export enum BackgroundType {
  FixedImage,
  FixedColor,
  RandomColor,
  RandomImage,
}

export enum BackgroundColorMode {
  Solid = 'SOLID',
  Gradient = 'GRADIENT',
}

export enum BackgroundAssetVariant {
  Image,
  Color,
}

export type BackgroundAsset = {
  value: string;
  variant: BackgroundAssetVariant;
};

export type TemplateSettingType = {
  templateId: number;
  backgroundType: BackgroundType;
  backgroundAssets: BackgroundAsset[];
};
