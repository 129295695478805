import { Typography } from 'antd';

type Props = {
  title: string;
  children: string;
};
const TitleWithText = ({ title, children }: Props) => {
  return (
    <div>
      <Typography.Text strong>{title}: </Typography.Text>
      <Typography.Text>{children}</Typography.Text>
      <br />
    </div>
  );
};

export default TitleWithText;
