import { Pagination, Space } from 'antd';
import { BlogPostModel, EmbeddedBlogSetting, EmbeddedBlogViewMode } from '@core/models/blog';
import BlogPostVerticalCard from './EmbeddedBlogPostVerticalCard';
import Marquee from 'react-fast-marquee';
import React, { useState } from 'react';

function EmbeddedBlogListViewContainer({
  embeddedBlogSetting,
  blogPosts,
}: {
  embeddedBlogSetting: EmbeddedBlogSetting;
  blogPosts: BlogPostModel[];
}) {
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <div>
      <Space
        style={{
          flexWrap: 'wrap',
          maxWidth: embeddedBlogSetting.maxContainerSize,
          justifyContent: 'space-around',
        }}>
        {blogPosts
          .slice(
            (currentPage - 1) * embeddedBlogSetting.cardPerPage,
            currentPage * embeddedBlogSetting.cardPerPage
          )
          .map(blogPost => (
            <div style={{ margin: '10px 0px' }}>
              <BlogPostVerticalCard
                key={blogPost.id}
                blogPost={blogPost}
                setting={embeddedBlogSetting}
              />
            </div>
          ))}
      </Space>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Pagination
          current={currentPage}
          pageSize={embeddedBlogSetting.cardPerPage}
          total={blogPosts.length}
          onChange={setCurrentPage}
          hideOnSinglePage={true}
          showSizeChanger={false}></Pagination>
      </div>
    </div>
  );
}

export default function EmbeddedBlogViewContainer({
  embeddedBlogSetting,
  blogPosts,
}: {
  embeddedBlogSetting: EmbeddedBlogSetting;
  blogPosts: BlogPostModel[];
}) {
  switch (embeddedBlogSetting.displayMode) {
    case EmbeddedBlogViewMode.ListView:
      return (
        <EmbeddedBlogListViewContainer
          embeddedBlogSetting={embeddedBlogSetting}
          blogPosts={blogPosts}
        />
      );
    case EmbeddedBlogViewMode.Marquee:
      return (
        <Marquee autoFill style={{ maxWidth: embeddedBlogSetting.maxContainerSize }}>
          {blogPosts.map(blogPost => (
            <div style={{ margin: '10px 5px' }}>
              <BlogPostVerticalCard
                key={blogPost.id}
                blogPost={blogPost}
                setting={embeddedBlogSetting}
              />
            </div>
          ))}
        </Marquee>
      );
    default:
      return <></>;
  }
}
