import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtraReducer, formatUtcTimestamp, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { heroApiService } from '@services/service-register';
import { LocalLocation } from '@core/models';

export const sortLocationByCreatedDate =
  (order: 'oldestToNewest' | 'newestToOldest') =>
  (locationA: LocalLocation, locationB: LocalLocation) =>
    (formatUtcTimestamp(locationB.created_date || new Date().toString()) -
      formatUtcTimestamp(locationA.created_date || new Date().toString())) *
    (order === 'oldestToNewest' ? -1 : 1);

export const sortLocationsByAlphabet = (locationA: LocalLocation, locationB: LocalLocation) => {
  if (locationA.title[0] > locationB.title[0]) return 1;
  if (locationA.title[0] < locationB.title[0]) return -1;
  return sortLocationByCreatedDate('newestToOldest')(locationA, locationB);
};

export const fetchAmazelyLocalLocations = createAsyncThunk(
  'business/fetchAmazelyLocalLocations',
  async _ => {
    try {
      const localLocations = await heroApiService.getAllLocalLocations();
      const sortedByAlphabetLocations = localLocations.sort(sortLocationsByAlphabet);
      const completeLocalLocationResult: LocalLocation[] = [];
      let prevLocation: LocalLocation | undefined = undefined;
      for (const localLocation of sortedByAlphabetLocations) {
        if (prevLocation && prevLocation.place_id === localLocation.place_id) {
          localLocation.suspended = true;
        }
        completeLocalLocationResult.push(localLocation);
        prevLocation = localLocation;
      }
      return completeLocalLocationResult;
    } catch (error: any) {
      return undefined;
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchAmazelyLocalLocations);

export const fetchAmazelyLocalLocationsReducer = {
  [fulfilled]: (state, action) => {
    state.isLocalLocationLoading = false;
    state.localLocations = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLocalLocationLoading = false;
    state.localLocations = [];
  },
  [pending]: (state, action) => {
    state.isLocalLocationLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
