import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { getMonthKey } from '@utils/date.util';
import React from 'react';

type Props = {
  upcomingMonths: number[];
  label: string;
  records: Record<string, any[]>;
  selectedMonth: string | undefined;
  setSelectedMonth: (month: string | undefined) => void;
};

const MonthHorizontalSelector = ({
  records,
  label,
  upcomingMonths,
  selectedMonth,
  setSelectedMonth,
}: Props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'auto',
      }}>
      {upcomingMonths.map(monthMoment => (
        <div
          style={{
            ...MIDDLE_STYLE,
            flexDirection: 'column',
            width: '100%',
            padding: '0px 5px',
          }}>
          <div
            onClick={() =>
              setSelectedMonth(
                getMonthKey(monthMoment) === selectedMonth ? undefined : getMonthKey(monthMoment)
              )
            }
            className={`hover-to-border-highlight ${
              getMonthKey(monthMoment) === selectedMonth ? 'hovered' : ''
            }`}
            style={{
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              textAlign: 'left',
              borderRadius: 10,
              width: '100%',
              padding: '10px',
              cursor: 'pointer',
            }}>
            <div style={{ fontSize: '15px' }}>{getMonthKey(monthMoment)}</div>
            <div
              className={'subtitle'}
              style={{
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                fontSize: 'smaller',
              }}>
              {records[getMonthKey(monthMoment)]?.length || 0} {label}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MonthHorizontalSelector;
