import { BulkRequestCsvInfo } from '@components/UploadCSVModal';
import { PhotoImageDataItem } from '@core/features';

export const handleBulkRequestFile = (csvStr: string) => {
  try {
    const FIELD_REQUIRED = 4;
    const result: BulkRequestCsvInfo[] = [];
    const sanitizedStr = csvStr.replace('"', '');
    const tab: string[] = sanitizedStr.split(/\r\n|\n/);
    for (let row = 0; row < tab.length; row++) {
      if (row > 0) {
        const rowItems = tab[row].split(',');
        if (rowItems.length === FIELD_REQUIRED) {
          const info: BulkRequestCsvInfo = {
            index: row,
            emailAddress: rowItems[0].replace(/"/g, '').trim(),
            fullName: rowItems[1].replace(/"/g, '').trim(),
            phoneCode: rowItems[2].replace(/"/g, '').trim(),
            phoneNumber: rowItems[3].replace(/"/g, '').trim(),
          };
          result.push(info);
        }
      }
    }

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export function base64ToImageItem(base64DataUrl: string): PhotoImageDataItem {
  const dataPrefix = 'data:image/';
  const [header, data] = base64DataUrl.split(';base64,');
  const extension = header.substring(dataPrefix.length);
  const id = new Date().getTime(); // You can use any appropriate ID generation logic
  const name = `image_${id}.${extension}`;
  const arrayBuffer = Uint8Array.from(atob(data), c => c.charCodeAt(0)).buffer;

  return {
    id,
    url: base64DataUrl,
    extension,
    name,
    data: arrayBuffer,
  };
}
