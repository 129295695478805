import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { AccountContact } from '@core/models';

type Props = {
  owner: AccountContact;
  handleInputChange: any;
  handleUpdateOwner: any;
};
const OwnerSettingRow = ({ owner, handleInputChange, handleUpdateOwner }: Props) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Form form={form} name="owner_form" onFinish={handleUpdateOwner}>
      <Row gutter={5}>
        <Col lg={12} md={24}>
          <Form.Item
            name="given_name"
            initialValue={owner.given_name}
            rules={[{ required: true, message: "Missing owner's first name" }]}>
            <Input name="given_name" addonBefore="First Name" onChange={handleInputChange} />
          </Form.Item>
        </Col>
        <Col lg={12} md={24}>
          <Form.Item
            name="family_name"
            initialValue={owner.family_name}
            rules={[{ required: true, message: "Missing owner's last name" }]}>
            <Input name="family_name" addonBefore="Last Name" onChange={handleInputChange} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={5}>
        <Col lg={18} md={24}>
          <Form.Item
            name="email"
            initialValue={owner.email}
            rules={[
              { required: true, message: "Missing owner's email" },
              {
                message: 'Invalid Email',
                validator: (_, value) => {
                  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Invalid Email');
                  }
                },
              },
            ]}>
            <Input name="email" addonBefore="Email" onChange={handleInputChange} />
          </Form.Item>
        </Col>
        <Col lg={6} md={24}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !(
                    form.isFieldTouched('email') ||
                    form.isFieldTouched('fname') ||
                    form.isFieldTouched('lname')
                  ) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }>
                Save
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OwnerSettingRow;
