import { useHistory } from 'react-router';
import { AppRoutes } from '@utils/route.util';

export const useAppNavigation = () => {
  const history = useHistory();

  function navigate<T extends AppRoutes | string>(route: T) {
    history.push(`/app/${route}`);
  }

  return { navigate };
};
