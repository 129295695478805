import { CreditCardOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useAppDispatch } from '@core/configureStore';
import { openModal } from '@core/features';

const SubscriptionCancelButton = () => {
  const dispatch = useAppDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(
          openModal({
            modalName: 'subscriptionCancellationModal',
          })
        );
      }}
      size={'middle'}>
      <CreditCardOutlined style={{ margin: 0, marginLeft: 10 }} />
      Cancel Subscription
    </Button>
  );
};

export default SubscriptionCancelButton;
