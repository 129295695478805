import React, { useEffect, useMemo, useState } from 'react';
import { StudioPhoto } from '@core/models';
import StudioImageListItem from '@components/StudioImageListItem';
import { useAppDispatch } from '@core/configureStore';
import { enqueueNotification } from '@core/features';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Button, Checkbox, Col, Popconfirm, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AnimatedComponent from '@components/AnimatedComponent';
import { deleteStudioPhotos, fetchGalleryImages } from '@core/features/studio/thunks';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

interface Props {
  studioPhotos: StudioPhoto[];
  onItemClicked: (studioPhoto: StudioPhoto) => void;
}

const ImageGalleryListView: React.FC<Props> = ({ studioPhotos, onItemClicked }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState<Record<string, boolean>>({});
  const checkedPhotos = useMemo(
    () => Object.keys(selectedPhotos).filter(socialPostId => selectedPhotos[socialPostId]),
    [selectedPhotos]
  );

  const handleResetSelectedPhotos = () => {
    setSelectedPhotos({});
  };

  const handleBatchRemovePhotos = async () => {
    try {
      setLoading(true);
      await dispatch(
        deleteStudioPhotos({ studioPhotos: studioPhotos.filter(photo => selectedPhotos[photo.id]) })
      );
      await dispatch(fetchGalleryImages());
      handleResetSelectedPhotos();
      setLoading(false);
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to remove social posts',
          description: 'Failed to remove social posts',
          type: 'Error',
        })
      );
    }
  };

  useEffect(() => {
    const checkedPhotos: Record<number, boolean> = {};
    for (const photo of studioPhotos) {
      checkedPhotos[photo.id] = allChecked;
    }
    setSelectedPhotos(checkedPhotos);
  }, [allChecked, studioPhotos]);

  return (
    <div>
      {checkedPhotos.length > 0 && (
        <div style={{ margin: '20px 0px', ...MIDDLE_STYLE }}>
          <Popconfirm
            onConfirm={handleBatchRemovePhotos}
            title="Delete selected posts"
            description="Are you sure to delete this post?"
            okButtonProps={{
              size: 'middle',
            }}
            cancelButtonProps={{
              size: 'middle',
            }}
            okText="Yes"
            cancelText="No">
            <Button danger loading={loading}>
              <DeleteOutlined /> Remove {checkedPhotos.length} selected photos
            </Button>
          </Popconfirm>
        </div>
      )}
      <Row
        gutter={20}
        style={{
          color: GLOBAL_THEME_COLOR.$dark_text_color,
          borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          fontSize: 'smaller',
          paddingBottom: 10,
        }}>
        <Col span={1} style={{ ...MIDDLE_STYLE }}>
          <Checkbox
            onChange={e => setAllChecked(e.target.checked)}
            checked={allChecked}
            style={{ marginRight: 20 }}
          />
        </Col>
        <Col span={2} style={{ ...MIDDLE_STYLE }}>
          Photo
        </Col>
        <Col span={6} style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start' }}>
          Annotation
        </Col>
        <Col span={13} style={{ ...MIDDLE_STYLE }}>
          Created at
        </Col>
      </Row>
      {studioPhotos.map(studioPhoto => (
        <AnimatedComponent.OpacityFadeInDiv delay={200}>
          <div
            style={{ ...MIDDLE_STYLE }}
            onClick={() =>
              setSelectedPhotos({
                ...selectedPhotos,
                [studioPhoto.id]: !selectedPhotos[studioPhoto.id.toString()],
              })
            }>
            <Checkbox
              checked={selectedPhotos[studioPhoto.id.toString()]}
              style={{ marginRight: 20 }}
            />
            <StudioImageListItem onClick={item => onItemClicked(item)} item={studioPhoto} />
          </div>
        </AnimatedComponent.OpacityFadeInDiv>
      ))}
    </div>
  );
};

export default ImageGalleryListView;
