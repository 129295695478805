/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '../constants';
import { useBreakpoint } from '@core/hooks/useBreakpoint';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const InfoCollectorScreen = ({ children }: Props) => {
  const { isTablet } = useBreakpoint();
  const contentStyle: React.CSSProperties = {
    ...MIDDLE_STYLE,
    flexDirection: 'column',
    marginTop: 16,
  };
  return (
    <div
      style={{
        position: 'relative',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        ...MIDDLE_STYLE,
      }}>
      <img
        src="/jumbotron/builder-background-purple.webp"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
        }}
      />
      <div
        onClick={() => window.open('https://www.amazely.co')}
        style={
          isTablet
            ? { position: 'absolute', top: 10, left: 0, right: 0, ...MIDDLE_STYLE }
            : { position: 'absolute', top: 20, left: 20, ...MIDDLE_STYLE }
        }>
        <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 50 }} />
        <span
          style={{
            color: GLOBAL_THEME_COLOR.$text_color_contrast,
            fontWeight: 'bold',
            fontSize: 16,
            marginLeft: 10,
          }}>
          {BRAND_NAME.toUpperCase()}
        </span>
      </div>
      {!isTablet && (
        <div
          style={{
            color: 'white',
            position: 'absolute',
            bottom: 10,
            fontSize: 15,
            ...MIDDLE_STYLE,
          }}>
          Made by{' '}
          <div style={{ ...MIDDLE_STYLE, marginLeft: 5 }}>
            <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 30 }} />{' '}
            <span
              style={{
                color: 'white',
                fontWeight: 'bold',
                marginLeft: 5,
              }}>
              {BRAND_NAME}
            </span>
          </div>
        </div>
      )}
      <div style={{ padding: isTablet ? '10px 0px' : '50px 0px', marginTop: 50 }}>
        <div style={contentStyle}>{children}</div>
      </div>
    </div>
  );
};

export default InfoCollectorScreen;
