import React from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { AuthWidget, SnackbarWrapper, StreamingEventObserver } from './components';
import { store } from './core';
import GlobalEventEmitterProvider from '@components/EventEmitterProvider';

type Props = {
  children: React.ReactNode;
};

const INTERCOM_APP_ID = 'jwv658j6';

const AppProvider = ({ children }: Props) => {
  return (
    <Provider store={store}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <GlobalEventEmitterProvider>
          <StreamingEventObserver>
            <SnackbarWrapper>
              <AuthWidget>{children}</AuthWidget>
            </SnackbarWrapper>
          </StreamingEventObserver>
        </GlobalEventEmitterProvider>
      </IntercomProvider>
    </Provider>
  );
};

export default AppProvider;
