import React from 'react';
import { useAppDispatch } from '@core/configureStore';
import { openModal } from '@core/features';
import { SocialPost, SocialPostStage } from '@core/models';
import { renderStatusColor, renderStatusStr } from '@utils/social-studio.util';
import { Card } from 'antd';
import { NewlineText, SocialPostImageGrid } from '@components/index';
import moment from 'moment';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

type Props = {
  locationId: string;
  socialPost: SocialPost;
  hideOverlay?: boolean;
  onClickDisabled?: boolean;
  style?: React.CSSProperties;
  CustomPhotoRenderer?: React.FC<any>;
};

const SocialPostCard = ({
  locationId,
  onClickDisabled,
  hideOverlay,
  socialPost,
  style,
  CustomPhotoRenderer,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleItemClicked = async (socialPost: SocialPost) => {
    if (onClickDisabled) return;
    dispatch(
      openModal({
        modalName: 'socialPostModal',
        extraParams: {
          socialPost,
          locationId,
        },
      })
    );
  };

  return (
    <Card
      onClick={() => handleItemClicked(socialPost)}
      hoverable
      className="no-padding"
      style={{
        maxWidth: 350,
        minWidth: 300,
        marginRight: 10,
        marginTop: 20,
        borderRadius: 15,
        ...style,
      }}>
      <React.Fragment>
        {socialPost.status !== SocialPostStage.Draft && (
          <div
            style={{
              top: -10,
              left: 20,
              position: 'absolute',
              backgroundColor: renderStatusColor(socialPost.status),
              width: 'fit-content',
              color: 'white',
              padding: '5px 10px',
              borderRadius: 5,
              cursor: 'pointer',
              fontWeight: 600,
              fontSize: 'smaller',
            }}>
            {socialPost.status === SocialPostStage.Pending ? (
              'Pending'
            ) : (
              <React.Fragment>
                {renderStatusStr(socialPost.status)}{' '}
                {socialPost.status === SocialPostStage.Scheduled ? 'for' : 'at'}{' '}
                {socialPost.publish_at && (
                  <span>{moment(socialPost.publish_at).format('DD/MM/YYYY, HH:mm A')}</span>
                )}
              </React.Fragment>
            )}
          </div>
        )}
        <div style={{ marginBottom: 10 }}>
          <p style={{ marginTop: 10, fontSize: 12, padding: '20px 20px' }}>
            {socialPost.content.length > 0 ? (
              <NewlineText text={socialPost.content || ''} />
            ) : (
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                No social post content found
              </span>
            )}
          </p>
          {!CustomPhotoRenderer && locationId && (
            <SocialPostImageGrid
              width={'100%'}
              mediaIds={socialPost?.mediaIds || []}
              hideOverlay={hideOverlay}
            />
          )}
          {CustomPhotoRenderer && <CustomPhotoRenderer />}
        </div>
      </React.Fragment>
    </Card>
  );
};

export default SocialPostCard;
