import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Empty, Space } from 'antd';
import moment from 'moment';
import {
  CheckCircleFilled,
  CheckOutlined,
  ClockCircleFilled,
  UserOutlined,
} from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { iterateObject } from '@utils/array.util';
import { Reservation, ReservationStage } from '@core/models';
import { useAuthState } from '@core/configureStore';
import { reservationApiService } from '@services/service-register';
import { LoadableContainer } from '@components/index';
import SplashScreen from '@components/SplashScreen';
import { formatUtcDate, formatUtcTimestamp } from '@utils/date.util';

const DATE_KEY_FORMAT = 'DD MMMM YYYY';

const BookingManagementScreen: React.FC<any> = () => {
  const { user } = useAuthState();
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [loading, setLoading] = useState(false);
  const renderDateKey = (date: string) => {
    return date === moment().format(DATE_KEY_FORMAT) ? 'Today' : date;
  };
  const groupedReservations = useMemo<Record<string, Reservation[]>>(() => {
    const dailyReservations: Record<string, Reservation[]> = {};
    const sortedReservations = reservations.sort(
      (reservationA, reservationB) =>
        formatUtcTimestamp(reservationA.start_date) - formatUtcTimestamp(reservationB.start_date)
    );
    for (const reservation of sortedReservations) {
      const dateKey = formatUtcDate(reservation.start_date, DATE_KEY_FORMAT);
      dailyReservations[dateKey] = (dailyReservations[dateKey] || []).concat([reservation]);
    }
    return dailyReservations;
  }, [reservations]);

  const handleResolve = async (reservationId: number) => {
    await reservationApiService.resolveReservation(reservationId);
    await fetchReservations();
  };

  const fetchReservations = async () => {
    try {
      setLoading(true);
      const reservations = await reservationApiService.getLocationReservations();
      setReservations(reservations);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReservations();
  }, [user]);

  return (
    <div className="screen-card">
      <h3>Customer Reservations</h3>
      <Divider />
      <LoadableContainer isLoading={loading} loadComponent={<SplashScreen />}>
        <LoadableContainer
          isLoading={reservations.length === 0}
          loadComponent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>No reservation found</span>}
            />
          }>
          <div style={{ ...MIDDLE_STYLE, width: '100%' }}>
            <Space direction={'vertical'} style={{ width: '100%', maxWidth: 500 }}>
              {iterateObject(groupedReservations, (date, bookings) => (
                <React.Fragment>
                  <div
                    style={{
                      ...MIDDLE_STYLE,
                      justifyContent: 'space-between',
                      borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                      paddingBottom: 10,
                      marginBottom: 10,
                    }}>
                    <h4>{renderDateKey(date)}</h4>
                    <p>Number of reservations: {reservations.length}</p>
                  </div>
                  {reservations.map(reservation => (
                    <div
                      style={{
                        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                        padding: '15px 20px',
                        borderRadius: 10,
                      }}>
                      <div>
                        <div
                          style={{
                            ...MIDDLE_STYLE,
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                          <div>
                            <h3>
                              <UserOutlined /> {reservation.fullName}
                            </h3>
                            <p style={{ fontSize: 'smaller' }}>
                              Reserved for {reservation.numberOfPeople} people
                            </p>
                          </div>
                          <div style={{ textAlign: 'right' }}>
                            <h2>
                              <ClockCircleFilled /> {formatUtcDate(reservation.start_date, 'HH:mm')}
                            </h2>
                            <p style={{ fontSize: 'smaller' }}>
                              {formatUtcDate(reservation.start_date, DATE_KEY_FORMAT)}
                            </p>
                          </div>
                        </div>
                        <Divider style={{ margin: '10px 0px' }} />
                        <p style={{ fontSize: 'smaller' }}>Contact email: {reservation.contact}</p>
                        {reservation.stage !== ReservationStage.Resolved ? (
                          <Button
                            type={'primary'}
                            style={{ ...MIDDLE_STYLE, marginTop: 10 }}
                            onClick={() => handleResolve(reservation.id)}
                            size={'small'}>
                            <CheckOutlined /> Resolve
                          </Button>
                        ) : (
                          <React.Fragment>
                            <CheckCircleFilled
                              style={{ color: GLOBAL_THEME_COLOR.$success_color }}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </Space>
          </div>
        </LoadableContainer>
      </LoadableContainer>
    </div>
  );
};

export default BookingManagementScreen;
