import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequest } from '@core/models';
import { ReviewRequestReducerProps } from '../type';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { RootState } from '@core/configureStore';

export const requestAgain = createAsyncThunk(
  'review-request/requestAgain',
  async (id: number, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('Not user found');

      const result: ReviewRequest = await heroApiService.requestAgain(id);
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Successfully re-schedule the request',
          description: `Review request has been re-scheduled for ${result.contact}`,
          type: 'Success',
        } as SnackbarItem)
      );
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to update the request',
          description: `Review request re-schedule failed. Please try again`,
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(requestAgain);

export const requestAgainReducer = {
  [fulfilled]: (state, action: PayloadAction<ReviewRequest>) => {
    state.isMutating = false;
    state.requests.push(action.payload);
  },
  [rejected]: (state, action) => {
    state.isMutating = false;
    state.error = action.payload;
  },
  [pending]: (state, action) => {
    state.isMutating = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
