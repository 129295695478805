import { BlogPostMadeBy, BlogPostModel, BlogPostStage, SocialPost } from '@core/models';
import { cloneArray } from '@utils/array.util';
import { formatUtcTimestamp } from '@utils/date.util';

export const useBlogPostDispatcher = () => {
  const isSystemMadeBlogPost = (blogPost: BlogPostModel) =>
    blogPost.made_by === BlogPostMadeBy.System;

  const preCheckedGenerate = async (blogPosts: BlogPostModel[]) => {
    const systemMadeBlogPosts = blogPosts.filter(isSystemMadeBlogPost);

    const hasSystemMadeBlogPosts = systemMadeBlogPosts.length > 0;
    return {
      hasSystemMadeBlogPosts,
    };
  };

  const getLatestPublishedDate = (blogPosts: SocialPost[], defaultNextPublishDate: number) => {
    /** The latest published date retrieved from the list of provided social posts */
    let latestPublishDate = defaultNextPublishDate;
    for (const post of blogPosts) {
      if (post.publish_at) {
        const publishAtValue = formatUtcTimestamp(post.publish_at);
        /** Find the max value of published at (latest published date) */
        if (publishAtValue > latestPublishDate) {
          latestPublishDate = publishAtValue;
        }
      }
    }
    return latestPublishDate;
  };

  const sorteBlogPostsByPublishedDate = (blogPosts: BlogPostModel[]) =>
    cloneArray(blogPosts).sort(
      (postA, postB) => formatUtcTimestamp(postB.publish_at) - formatUtcTimestamp(postA.publish_at)
    );

  const filterEmbeddedBlogPosts = (blogPosts: BlogPostModel[]) =>
    cloneArray(blogPosts).filter(blogPost => blogPost.status === BlogPostStage.Published);

  return {
    isSystemMadeBlogPost,
    preCheckedGenerate,
    getLatestPublishedDate,
    sorteBlogPostsByPublishedDate,
    filterEmbeddedBlogPosts,
  };
};
