export type StreamApiMethod<T> = (
  params: T,
  abortSignal?: AbortSignal
) => Promise<ReadableStreamDefaultReader<Uint8Array>>;

export type MonthlyGenerateParams = {
  startDate: number;
  endDate: number;
  currentDate?: number;
};

export async function callStreamApi<T>(
  apiRoute: string,
  method: 'POST' | 'GET',
  payload?: T,
  abortSignal?: AbortSignal
) {
  const sharedPayload: Pick<RequestInit, 'signal'> = {
    signal: abortSignal,
  };
  const response = await fetch(apiRoute, {
    method: method,
    ...(payload
      ? {
          body: JSON.stringify(payload),
          ...sharedPayload,
        }
      : sharedPayload),
    credentials: 'include',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  if (!response.body) throw new Error('No streaming response body found');
  return response.body.getReader();
}
