import { DateRange, Optional } from '@utils/.';
import { Moment } from 'moment';
import { ReviewResponse, Review } from '../../models';

export enum ReviewFilterStatus {
  All,
  Generated,
  Responded,
  NotResponded,
}

export enum ReviewSortOrder {
  DESC,
  ASC,
}

export type ReviewReducerProps = {
  isLoading: boolean;
  isReviewLoading: boolean;
  isReviewRefreshing: Optional<string>;
  isGenerating: boolean;
  error: Optional<string>;
  isSelected: boolean;
  dateRange: Optional<DateRange<Moment>>;
  review: Optional<Review>;
  startDate: Optional<string>;
  reviewResponse: ReviewResponse;
  sortBy: 'date' | 'rating' | 'name';
  sortOrder: ReviewSortOrder;
  filterStatus: ReviewFilterStatus;
};
