/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DisconnectOutlined } from '@ant-design/icons';
import TitleWithText from '@components/TitleWithText';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import { fetchXeroInfo } from '@core/features/business/thunks/fetchXeroInfo.thunk';
import { heroApiService } from '@services/service-register';

type Props = {};

const ConnectXeroIntegrationButton = (props: Props) => {
  const { user } = useAuthState();
  const dispatch = useAppDispatch();
  const { integration } = useBusinessState();
  const [loading, setLoading] = useState<boolean>(false);
  const [mount, setMount] = useState<boolean>(false);
  const handleConnect = async () => {
    if (!user) return;
    const rootUrl = `https://login.xero.com/identity/connect/authorize`;
    const options = {
      redirect_uri: process.env.REACT_APP_XERO_CALLBACK_URL as string,
      client_id: process.env.REACT_APP_XERO_APPLICATION_ID as string,
      response_type: 'code',
      scope: 'offline_access openid accounting.contacts.read',
      state: user.google_sub as unknown as string,
    };

    const qs = new URLSearchParams(options);
    window.open(`${rootUrl}?${qs.toString()}`);
  };

  const handleRefresh = async () => {
    if (!user) return;

    try {
      await dispatch(fetchXeroInfo());
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogoutXero = async () => {
    if (!user) return;
    setLoading(true);
    try {
      await heroApiService.unintegrateXeroFromLocation();
      await handleRefresh();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      setMount(false);
      await handleRefresh();
      setMount(true);
      setLoading(false);
    };
    init();
  }, []);

  return mount && integration.xero ? (
    <div
      style={{
        ...MIDDLE_STYLE,
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}>
      <TitleWithText title="Connected Account">{integration.xero}</TitleWithText>
      <br />
      <Button icon={<DisconnectOutlined />} onClick={handleLogoutXero}>
        Disconnect Xero Integration
      </Button>
    </div>
  ) : (
    <Button loading={loading} onClick={handleConnect} style={{ ...MIDDLE_STYLE }}>
      <img alt="Xero" src="xero.png" width={25} height={25} style={{ marginRight: 10 }} /> Connect
      to Xero
    </Button>
  );
};

export default ConnectXeroIntegrationButton;
