import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService, UpdateLocationRequestTemplatePayload } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { RootState } from '@core/configureStore';
import { fetchCurrentUser } from '@core/features/auth/thunks';

export const updateRequestTemplate = createAsyncThunk(
  'business/updateRequestTemplate',
  async (requestTemplate: UpdateLocationRequestTemplatePayload, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('No user found');
      const selectedLocation = user.selected_location;

      await heroApiService.updateRequestTemplate(selectedLocation.location_id, requestTemplate);
      await thunkApi.dispatch(fetchCurrentUser());
      thunkApi.dispatch(
        enqueueNotification({
          name: `Update request template for location ${selectedLocation.title}`,
          description: 'Successfully updated request template',
          type: 'Success',
        } as SnackbarItem)
      );
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to update request template',
          description: 'There was an error updating request template. Please try again',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(updateRequestTemplate);

export const updateRequestTemplateReducer = {
  [fulfilled]: (state, action) => {
    state.isRequestTemplateUpdating = false;
  },
  [rejected]: (state, action) => {
    state.isRequestTemplateUpdating = false;
  },
  [pending]: (state, action) => {
    state.isRequestTemplateUpdating = true;
  },
} as ExtraReducer<LocationReducerProps>;
