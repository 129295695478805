import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { instagramApiService } from '@services/service-register';

export const fetchInstagramSelectedProfiles = createAsyncThunk(
  'business/fetchSelectedInstagramProfiles',
  async (_, thunkApi) => {
    try {
      const selected_profiles = await instagramApiService.getLocationInstagramSelectedProfiles();
      return selected_profiles;
    } catch (error: any) {
      return undefined;
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchInstagramSelectedProfiles);

export const fetchInstagramSelectedProfilesReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.integration.instagram_profiles = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.integration.instagram_profiles = [];
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
