import { RootState } from '@core/configureStore';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewReducerProps } from '../type';
import { LocalLocation } from '@core/models';

type Props = {
  location: LocalLocation;
};

export const generateInquiryResponse = createAsyncThunk(
  'review/generateInquiry',
  async ({ location }: Props, thunkApi) => {
    try {
      const {
        review: { review },
      } = thunkApi.getState() as RootState;
      if (!review) throw new Error('No selected review found');
      const result = await heroApiService.generateInquiryResponse(review.id);
      thunkApi.dispatch(
        enqueueNotification({
          name: `Generated response successfully`,
          description: 'Successfully generate new response',
          type: 'Success',
        } as SnackbarItem)
      );
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to generate response',
          description: 'There was error generate response. Please try again',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(generateInquiryResponse);

export const generateInquiryReducer = {
  [fulfilled]: (state, action) => {
    state.isGenerating = false;
    if (state.isSelected && state.review) {
      const message: string = action.payload;
      state.review.generated_response = message.trim();
    }
  },
  [rejected]: (state, action) => {
    state.isGenerating = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isGenerating = true;
  },
} as ExtraReducer<ReviewReducerProps>;
