import { useSubscriptionDispatcher } from '@core/hooks';
import { LocalLocation } from '@core/models';
import { Space, Tag } from 'antd';
import React, { useMemo } from 'react';

type Props = {
  location: LocalLocation | undefined;
};

const LocationLicenseBadge = ({ location }: Props) => {
  const { getLicenseDetails } = useSubscriptionDispatcher();
  const licenseDetails = useMemo(() => getLicenseDetails(location), [location]);
  return (
    <Space style={{ margin: '3px 0px' }}>
      {location?.suspended && <Tag color="red">Suspended</Tag>}
      <Tag color={licenseDetails === 'Pro' ? 'green' : 'blue'}>{getLicenseDetails(location)}</Tag>
    </Space>
  );
};

export default LocationLicenseBadge;
