import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { InputRef, Space } from 'antd';
import { Input, Tag, theme } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import { BlogCreationTourIdRegistry } from '@utils/blog.util';

const BlogTargetKeywordsEditor: React.FC<{
  keywords: string[];
  setKeywords?: (keywords: string[]) => void;
  disabled?: boolean;
}> = ({ keywords, setKeywords, disabled }) => {
  const { token } = theme.useToken();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);

  const handleClose = (removedTag: string) => {
    const updatedKeywords = keywords.filter(keyword => keyword !== removedTag);
    setKeywords && setKeywords(updatedKeywords);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && keywords.indexOf(inputValue) === -1) {
      setKeywords && setKeywords([...keywords, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          borderRadius: 10,
        }}>
        <Space
          style={{
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}>
          <h3 style={{ fontSize: '15px' }} id={BlogCreationTourIdRegistry.AddKeywordTextField}>
            <span style={{ marginRight: 10 }}>Target keywords: </span>
            {!disabled && (
              <React.Fragment>
                {inputVisible ? (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{ width: 100, height: 30 }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                ) : (
                  <Tag color="blue" onClick={showInput} style={tagPlusStyle}>
                    <PlusOutlined /> New keyword
                  </Tag>
                )}
              </React.Fragment>
            )}
          </h3>
          {keywords.length > 0 && (
            <Space style={{ flexWrap: 'wrap' }}>
              {keywords.map(keyword => (
                <Tag
                  onClose={() => handleClose(keyword)}
                  color="green"
                  closable={!disabled}
                  style={{ width: '100%', ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                  {keyword}
                </Tag>
              ))}
            </Space>
          )}
        </Space>
      </div>
    </React.Fragment>
  );
};

export default BlogTargetKeywordsEditor;
