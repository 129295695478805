/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CheckSquareOutlined } from '@ant-design/icons';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';
import TitleWithText from '@components/TitleWithText';
import { BRAND_NAME } from '@constants/brand';
import { useAppDispatch, useAuthState, useSubscriptionState } from '@core/configureStore';
import { checkout } from '@core/features/subscription/thunks/checkout.thunk';
import { manageSubscription } from '@core/features/subscription/thunks/manageSubscription.thunk';
import { formatUtcDate } from '@utils/.';
import { Button, Divider, Space } from 'antd';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useBreakpoint, useSubscriptionDispatcher } from '@core/hooks';
import { useLocation } from 'react-router-dom';
import SubscriptionPackageCard from '@components/SubscriptionPackageCard';
type Props = {};

const SubscriptionScreen = (props: Props) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { isLoading } = useSubscriptionState();
  const { getLicenseDetails } = useSubscriptionDispatcher();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checkout_action = searchParams.get('checkout_action');

  const getSubscriptionText = () => {
    if (!user) return '';
    if (user.subscription_renewable) {
      return 'Next Billing Date';
    }
    return user.no_licenses && user.no_licenses > 0
      ? 'Subscription End Date'
      : 'Subscription Ended On';
  };

  const handleManageSubscription = async () => {
    if (!user) return;
    if (user.no_licenses && user.no_licenses > 0) {
      await dispatch(manageSubscription());
    } else {
      // Create new subcription if previous license has been cancelled
      await dispatch(checkout(1));
    }
  };

  return (
    <div className="screen-card" style={{ padding: isMobile ? '10px 20px' : 'auto' }}>
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
        <h3>Billings</h3>
        <TitleWithText title="License Type">{`${getLicenseDetails(
          user?.selected_location
        )}`}</TitleWithText>
      </div>
      <Divider />
      <div
        style={{
          ...MIDDLE_STYLE,
          flexDirection: 'column',
          padding: isMobile ? '0px' : '20px 0px',
          backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
          backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
          backgroundSize: '20px 20px',
          borderRadius: 20,
        }}>
        <LoadableContainer isLoading={isLoading} loadComponent={<SplashScreen />}>
          <React.Fragment>
            {!isMobile && !user?.no_licenses && (
              <div
                style={{
                  ...MIDDLE_STYLE,
                  fontSize: '20px',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}>
                <span>You've ran out of starter credits.</span>
                <span>
                  Activate{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                      borderBottom: `2px solid ${GLOBAL_THEME_COLOR.$highlight_color}`,
                      margin: '0px 3px',
                    }}>
                    Amazely Pro
                  </span>{' '}
                  to continue.
                </span>
              </div>
            )}
            {!user?.subscription_end ? (
              <SubscriptionPackageCard />
            ) : (
              <div
                className="tablespace"
                style={{
                  width: '100%',
                  maxWidth: 450,
                  border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                  padding: isMobile ? '20px 15px' : '30px 35px',
                  borderRadius: 10,
                  backgroundColor: 'white',
                  marginTop: isMobile ? 0 : 20,
                }}>
                <div style={{ padding: '0px 20px' }}>
                  {checkout_action === 'success' && (
                    <div>
                      Thank you for using {BRAND_NAME}. An email will be sent to you once the order
                      is processed. Please send us a message if you have any further question.
                    </div>
                  )}
                  {checkout_action === 'cancelled' && (
                    <div>
                      Your checkout is not being proccessed. Please send us a message if you need
                      any further assistance
                    </div>
                  )}

                  {user?.subscription_end && (
                    <div style={{ paddingBottom: '10px' }}>
                      <TitleWithText title="Number of licenses">
                        {`${user.no_licenses}`}
                      </TitleWithText>
                      <TitleWithText title={getSubscriptionText()}>
                        {formatUtcDate(user.subscription_end)}
                      </TitleWithText>{' '}
                      <br />
                      <Button
                        type="primary"
                        htmlType="button"
                        icon={<CheckSquareOutlined />}
                        onClick={() => handleManageSubscription()}>
                        {user?.subscription_renewable
                          ? 'Manage Subscription'
                          : 'Renew Subscription'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        </LoadableContainer>
      </div>
    </div>
  );
};

export default SubscriptionScreen;
