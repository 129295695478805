import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequestReducerProps } from '../type';

type OpenRequestPayload = {
  requestId: number;
};

export const openRequestEmail = createAsyncThunk(
  'review-request/openRequestEmail',
  async ({ requestId }: OpenRequestPayload, thunkApi) => {
    const result = await heroApiService.openRequestEmail(requestId);
    return result;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(openRequestEmail);

export const openRequestEmailReducer = {
  [fulfilled]: (state, action) => {
    state.isOpening = false;
    state.request = action.payload;
  },
  [rejected]: (state, action) => {
    state.isOpening = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isOpening = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
