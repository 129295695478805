import React, { useCallback, useEffect, useState } from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Alert, Avatar, Button, Col, Divider, message, Row, Space, Tooltip } from 'antd';
import 'react-quill/dist/quill.snow.css';
import {
  DeleteOutlined,
  GlobalOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  SaveOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useAppNavigation, useBreakpoint, useSocialIntegrationState } from '@core/hooks';
import { useAppDispatch } from '@core/configureStore';
import { enqueueNotification, openModal, PhotoImageDataItem, SnackbarItem } from '@core/features';
import { blogApiService } from '@services/service-register';
import { BlogPostModel, BlogPostStage, EmbeddedBlogSetting, LocalLocation } from '@core/models';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import StatusBadge from '@components/StatusBadge';
import {
  BlogPostContentEditor,
  BlogPostPublishModal,
  BlogPostTopicEditor,
  SocialIcon,
} from '@components/index';
import moment from 'moment/moment';
import { formatUtcDate } from '@utils/date.util';
import { buildBlogPageUrl, getDefaultBlogProfileKeywords } from '@utils/blog.util';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { DEFAULT_EMBEDDED_BLOG_SETTING } from '@constants/defaults';

interface Props {
  currentLocation?: LocalLocation;
  existingBlogPost?: BlogPostModel;
  schedulable?: boolean;
  deletable?: boolean;
  onBlogPostUpdated?: (updatedbBlogPost: BlogPostModel) => void;
  onUpdateErrorHandler?: (error: Error) => void;
}

const BlogPostCreationScreen: React.FC<Props> = ({
  currentLocation,
  existingBlogPost,
  onBlogPostUpdated,
  onUpdateErrorHandler,
  schedulable,
  deletable,
}) => {
  const today = moment();
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [mutating, setMutating] = useState(false);
  const [contentBody, setContentBody] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [title, setTitle] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const { selectedSocialChannels, setSelectedSocialChannels } = useSocialIntegrationState();
  const [deploying, setDeploying] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [featuredImage, setFeaturedImage] = useState<
    (PhotoImageDataItem & { _existing: boolean }) | undefined
  >(undefined);
  const [scheduleLaterEnabled, setScheduleLaterEnabled] = useState<boolean>(false);
  const [date, setDate] = useState<number>(today.unix());
  const [time, setTime] = useState<{
    hour: number;
    minute: number;
  }>({
    hour: today.hour(),
    minute: today.minute(),
  });
  const [isUpdated, setIsUpdated] = useState(false);
  const [embeddedSetting, setEmbeddedSetting] = useState<EmbeddedBlogSetting>(
    DEFAULT_EMBEDDED_BLOG_SETTING
  );

  const handleUpdateBlogPost = async (
    payload: Partial<{
      title: string;
      subtitle?: string;
      contentBody: string;
      status: BlogPostStage;
      published_at: number;
      featuredFileImageItem: PhotoImageDataItem;
      keywords: string[];
    }>
  ) => {
    if (!existingBlogPost || !currentLocation) return;
    try {
      setMutating(true);
      const updatedBlogPost = await blogApiService.updateBlogPost(
        currentLocation.location_id,
        existingBlogPost.id,
        payload
      );
      if (updatedBlogPost) {
        messageApi.open({
          type: 'success',
          content: (
            <a onClick={() => handleViewBlogPost(updatedBlogPost as any)}>
              Blog post is updated. View it here!
              <GlobalOutlined style={{ marginLeft: 5 }} />
            </a>
          ),
          duration: 10,
        });
      }
      onBlogPostUpdated && onBlogPostUpdated(updatedBlogPost);
      dispatch(
        enqueueNotification({
          name: 'Updated a blog post',
          description: 'Updated a blog post',
          type: 'Success',
        })
      );
    } catch (e: any) {
      onUpdateErrorHandler && onUpdateErrorHandler(e);
      dispatch(
        enqueueNotification({
          name: 'Failed to update a blog post',
          description: 'Failed to update a blog post',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const handleDeleteBlogPost = async () => {
    if (!existingBlogPost) return;
    try {
      setMutating(true);
      await blogApiService.removeBlogPostById(existingBlogPost.id);
      navigate('blogs');
      dispatch(
        enqueueNotification({
          name: 'Removed a blog post',
          description: 'Removed a blog post',
          type: 'Success',
        })
      );
    } catch (e: any) {
      onUpdateErrorHandler && onUpdateErrorHandler(e);
      dispatch(
        enqueueNotification({
          name: 'Failed to remove a blog post',
          description: 'Failed to remove a blog post',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const getScheduledTime = () => {
    const scheduledDate = moment.unix(date);
    return moment({
      hour: time.hour,
      minute: time.minute,
      day: scheduledDate.get('date'),
      month: scheduledDate.get('month'),
    }).unix();
  };

  const handleViewBlogPost = (blogPost: BlogPostModel) => {
    window.open(buildBlogPageUrl(currentLocation?.slug, blogPost.slug));
  };

  const handleShareToSocialChannels = () => {
    dispatch(
      openModal({
        modalName: 'shareToSocialChannelsModal',
        extraParams: {
          blogPost: existingBlogPost,
        },
      })
    );
  };

  const handleCreateBlogPost = async (status: BlogPostStage) => {
    try {
      if (!currentLocation) throw new Error('No location found');
      const accountDestinations = Object.keys(selectedSocialChannels).filter(
        sentToAccount => selectedSocialChannels[sentToAccount]
      );

      let currentBlogPost: BlogPostModel | undefined = undefined;
      setMutating(true);
      if (!existingBlogPost) {
        if (!featuredImage) return;
        currentBlogPost = await blogApiService.createManualBlogPost({
          contentBody,
          title,
          featuredFileImageItem: featuredImage,
          status,
          subtitle,
          scheduleLaterEnabled,
          scheduleTime: getScheduledTime(),
          keywords,
          profileIds: accountDestinations,
        });
      } else {
        const isPublished =
          existingBlogPost.status !== BlogPostStage.Published && status === BlogPostStage.Published;
        currentBlogPost = await blogApiService.updateBlogPost(
          currentLocation.location_id,
          existingBlogPost.id,
          {
            contentBody,
            title,
            status,
            subtitle,
            published_at: getScheduledTime(),
            ...(isPublished ? { profileIds: accountDestinations } : {}),
            ...(!featuredImage?._existing ? { featuredFileImageItem: featuredImage } : {}),
          }
        );
      }
      if (currentBlogPost) {
        navigate(`blogs/${currentBlogPost.id}`);
        if (currentBlogPost.status === BlogPostStage.Published) {
          messageApi.open({
            type: 'success',
            content: (
              <a onClick={() => handleViewBlogPost(currentBlogPost as any)}>
                View your published blog post <GlobalOutlined style={{ marginLeft: 5 }} />
              </a>
            ),
            duration: 10,
          });
        }
      }
      onBlogPostUpdated && onBlogPostUpdated(currentBlogPost);
      setPublishModalOpen(false);
    } catch (error: any) {
      onUpdateErrorHandler && onUpdateErrorHandler(error);
      dispatch(
        enqueueNotification({
          name: error.message,
          description: 'Failed to create a blog post',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const handleSetBaseValue = useCallback(() => {
    if (existingBlogPost) {
      if (existingBlogPost.status === BlogPostStage.Scheduled) {
        setScheduleLaterEnabled(true);
      }
      if (existingBlogPost.publish_at) {
        const existingPublishDate = moment(new Date(existingBlogPost.publish_at));
        setDate(existingPublishDate.unix());
        setTime({
          hour: existingPublishDate.hour(),
          minute: existingPublishDate.minute(),
        });
      }
      setTitle(existingBlogPost.title);
      if (existingBlogPost.subtitle) setSubtitle(existingBlogPost.subtitle);
      setContentBody(existingBlogPost.contentBody);
      setFeaturedImage({
        id: 0,
        data: undefined,
        name: '',
        extension: '',
        url: existingBlogPost.thumbnail,
        _existing: true,
      });
      setKeywords(existingBlogPost.keywords || []);
    } else if (currentLocation) {
      const blogProfileKeywords = currentLocation.blog_assistant_profile.keywords;
      if (blogProfileKeywords?.length > 0) {
        setKeywords(blogProfileKeywords);
      } else {
        setKeywords(getDefaultBlogProfileKeywords(currentLocation));
      }
    }
  }, [existingBlogPost, currentLocation]);

  const handleLearnMore = () => {
    navigate<string>('blogs/new?guide=new');
  };

  const handlePublishBlogPage = async () => {
    if (!existingBlogPost) return;
    try {
      setDeploying(true);
      await blogApiService.publishBlogPostPage(existingBlogPost.id);
      await dispatch(fetchSelectedLocation());
      dispatch(
        enqueueNotification({
          name: 'Successfully published blog page',
          type: 'Success',
        } as SnackbarItem)
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to publish blog page. Try again.',
          type: 'Error',
        } as SnackbarItem)
      );
    }
    setDeploying(false);
  };

  useEffect(() => {
    handleSetBaseValue();
  }, [handleSetBaseValue]);

  useEffect(() => {
    const init = async () => {
      if (currentLocation?.location_id) {
        const blogSetting = await blogApiService.getLocationBlogSetting();
        if (blogSetting) {
          const _embeddedSetting = blogSetting.embedded_setting;
          await setEmbeddedSetting(_embeddedSetting);
        }
      }
    };
    init();
  }, [currentLocation]);

  return (
    <div className="screen-card">
      {contextHolder}
      <div
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'space-between',
          marginBottom: 20,
          flexWrap: 'wrap',
        }}>
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            width: '100%',
            flexWrap: 'wrap',
          }}>
          <Space>
            {existingBlogPost && <StatusBadge status={existingBlogPost.status} />}
            <h3>{existingBlogPost ? 'Edit Blog Post' : 'New Blog Post'}</h3>
            {deletable && (
              <Tooltip title={'Delete a blog post'}>
                <DeleteOutlined
                  onClick={handleDeleteBlogPost}
                  style={{
                    color: GLOBAL_THEME_COLOR.$error_color,
                    cursor: 'pointer',
                    margin: '0px 10px',
                  }}
                />
              </Tooltip>
            )}
            {existingBlogPost?.status === BlogPostStage.Published && (
              <React.Fragment>
                {deploying ? (
                  <LoadingOutlined />
                ) : (
                  <UploadOutlined onClick={handlePublishBlogPage} />
                )}
              </React.Fragment>
            )}
          </Space>
          <Space style={{ flexWrap: 'wrap' }}>
            {existingBlogPost ? (
              <React.Fragment>
                <Button
                  style={{ color: GLOBAL_THEME_COLOR.$error_color }}
                  onClick={handleSetBaseValue}>
                  <RedoOutlined /> Discard
                </Button>
                {existingBlogPost.status === BlogPostStage.Published && (
                  <Button onClick={handleShareToSocialChannels} style={{ ...MIDDLE_STYLE }}>
                    <SocialIcon platform="facebook" width={17} style={{ marginRight: 10 }} /> Share
                    to social{' '}
                    {(existingBlogPost.published_accounts || []).length > 0 && (
                      <Avatar
                        size={17}
                        style={{
                          fontSize: 10,
                          marginLeft: 10,
                          backgroundColor: GLOBAL_THEME_COLOR.$highlight_color,
                        }}>
                        {existingBlogPost.published_accounts?.length || 1}
                      </Avatar>
                    )}
                  </Button>
                )}
                {existingBlogPost.status === BlogPostStage.Published && (
                  <Button onClick={() => handleViewBlogPost(existingBlogPost as any)}>
                    <GlobalOutlined /> View published blog
                  </Button>
                )}
                {isUpdated && (
                  <Button
                    disabled={!featuredImage || !subtitle || !title || !contentBody}
                    style={{ width: '100%' }}
                    onClick={() =>
                      handleUpdateBlogPost({
                        title: title,
                        subtitle: subtitle,
                        contentBody: contentBody,
                        keywords: keywords,
                        ...(!featuredImage?._existing
                          ? { featuredFileImageItem: featuredImage }
                          : {}),
                      })
                    }
                    loading={mutating}
                    type={'primary'}>
                    <SaveOutlined /> Update a post
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Tooltip title="Learn more">
                  <QuestionCircleOutlined
                    onClick={handleLearnMore}
                    style={{
                      cursor: 'pointer',
                      color: GLOBAL_THEME_COLOR.$dark_text_color,
                    }}
                  />
                </Tooltip>
                <Button
                  disabled={!featuredImage || !subtitle || !title || !contentBody}
                  style={{ width: '100%' }}
                  onClick={() => handleCreateBlogPost(BlogPostStage.Draft)}
                  loading={mutating}>
                  ✏️ <span style={{ marginLeft: 10 }}>Save draft</span>
                </Button>
              </React.Fragment>
            )}
            {existingBlogPost?.status !== BlogPostStage.Published && (
              <Button
                style={{ width: '100%' }}
                onClick={() => setPublishModalOpen(true)}
                loading={mutating}
                className={'success-btn'}
                type={'primary'}>
                <SendOutlined rotate={-30} /> Publish a post
              </Button>
            )}
          </Space>
        </div>
        {existingBlogPost && (
          <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, fontSize: 'smaller' }}>
            {existingBlogPost.publish_at
              ? `Published at ${formatUtcDate(existingBlogPost.publish_at)}`
              : `Updated at ${formatUtcDate(existingBlogPost.updated_date)}`}
          </div>
        )}
        <Divider style={{ margin: '15px 0px 10px 0px' }} />
        <Row style={{ width: '100%' }}>
          <Col span={isTablet ? 24 : 18} style={{ width: '100%' }}>
            <div style={{ ...MIDDLE_STYLE, width: '100%' }}>
              <div style={{ width: isTablet ? '100%' : '90%', padding: '20px 0px' }}>
                {mutating && (
                  <Alert
                    showIcon
                    style={{ marginBottom: 20 }}
                    type={mutating ? 'success' : 'info'}
                    message={
                      mutating && existingBlogPost
                        ? 'Blog post is being updated...'
                        : 'Creating a new blog post...'
                    }
                  />
                )}
                <BlogPostContentEditor
                  mode="editable"
                  title={title}
                  subtitle={subtitle}
                  contentBody={contentBody}
                  featuredImage={featuredImage}
                  keywords={keywords}
                  setContentBody={setContentBody}
                  setSubtitle={setSubtitle}
                  setTitle={setTitle}
                  setFeaturedImage={setFeaturedImage}
                  setKeywords={setKeywords}
                  existing={!!existingBlogPost}
                  onValueChanged={() => setIsUpdated(true)}
                />
              </div>
            </div>
          </Col>
          <Col span={isTablet ? 24 : 6}>
            <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', padding: '20px 0px' }}>
              <BlogPostTopicEditor
                existingBlogPost={existingBlogPost}
                limit={10}
                topic={title}
                onTopicChanged={topic => setTitle(topic)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <BlogPostPublishModal
        open={publishModalOpen}
        schedulable={!!schedulable}
        setOpen={setPublishModalOpen}
        location={currentLocation}
        mutated={mutating}
        onUpdated={() => setIsUpdated(true)}
        blogPostData={{
          title,
          subtitle,
          content: contentBody,
          thumbnail: featuredImage?.url,
        }}
        embeddedSetting={embeddedSetting}
        onCreated={handleCreateBlogPost}
        existingBlogPost={existingBlogPost}
        selectedSocialChannels={selectedSocialChannels}
        setSelectedSocialChannels={setSelectedSocialChannels}
      />
    </div>
  );
};

export default BlogPostCreationScreen;
