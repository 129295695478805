import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Button, Drawer, Modal, Popconfirm, Space, Steps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { BackgroundEvent, BlogGenerateBackgroundEvent, BlogPostModel } from '@core/models';
import {
  backgroundEventApiService,
  blogApiService,
  blogApiStreamService,
} from '@services/service-register';
import { enqueueNotification, openModal, SnackbarItem } from '@core/features';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useBlogPostDispatcher, useBreakpoint, useStreamApi } from '@core/hooks';
import moment from 'moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import { formatUtcTimestamp } from '@utils/date.util';
import SmallBlogPostCard from '@components/BlogPostCard/small';
import { convertBackgroundEventToBlogPost } from '@utils/background-event.util';
import { MonthlyGenerateBlogPostsParams } from '@services/blog-stream-api.service';
import { BlogPreviewTopicsModal } from '..';
import { fetchLocationBlogPostsSilent } from '@core/features/blog/thunks/fetchLocationBlogPostsSilent.thunk';

interface Props {
  month: string;
  blogPosts: BlogPostModel[];
  children: React.FC<{
    blogPosts: BlogPostModel[];
    allChecked: boolean;
    setAllChecked: (allChecked: boolean) => void;
    setSelectedBlogPosts: any;
    selectedBlogPosts: Record<string, boolean>;
  }>;
}

enum GenerationEvent {
  Idle = '0',
  GenerateBlogPosts = 'GenerateBlogPosts',
  Finished = 'Finished',
}

type StreamGenerateEvent = {
  startEventType: GenerationEvent;
  endEventType: GenerationEvent;
  actionMethod: any;
};

const BlogPostMonthContainerLayout: React.FC<Props> = ({ blogPosts, month, children }) => {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const [blogTopicPreviewModal, setBlogTopicPreviewModal] = useState(false);
  const { preCheckedGenerate, isSystemMadeBlogPost } = useBlogPostDispatcher();
  const { executeStreamApi } = useStreamApi();
  const { user } = useAuthState();
  const [currentBlogPosts, setCurrentBlogPosts] = useState<BlogPostModel[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedBlogPosts, setSelectedBlogPosts] = useState<Record<string, boolean>>({});
  const [generateTopicsParam, setGenerateTopicsParam] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [generationEvent, setGenerationEvent] = useState<GenerationEvent>(GenerationEvent.Idle);
  const [blogPostIdMap, setBlogPostIdMap] = useState<Record<string, BlogPostModel>>({});
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);
  const checkedBlogPosts = useMemo(
    () => Object.keys(selectedBlogPosts).filter(blogPostId => selectedBlogPosts[blogPostId]),
    [selectedBlogPosts]
  );
  const splittedMonthKey = useMemo(() => month.split(' '), [month]);
  const monthInThePast = useMemo(
    () => moment(month, MONTH_KEY_DATE_FORMAT).isBefore(moment().subtract(1, 'month')),
    [month]
  );

  const handleResetSelectedBlogPosts = () => {
    setSelectedBlogPosts({});
  };

  const handleBatchRemoveBlogPosts = async (blogPostIds: string[]) => {
    try {
      setLoading(true);
      const promises: Promise<any>[] = [];
      for (const blogPostId of blogPostIds) {
        const removedBlogPost = blogPostIdMap[blogPostId];
        if (removedBlogPost.isBackgroundEvent) {
          promises.push(backgroundEventApiService.removeBackgroundEventById(removedBlogPost.id));
        } else {
          promises.push(blogApiService.removeBlogPostById(blogPostId));
        }
      }
      await Promise.all(promises);
      await dispatch(fetchLocationBlogPostsSilent());
      handleResetSelectedBlogPosts();

      const remainingBlogPosts: BlogPostModel[] = [];
      for (const blogPost of blogPosts) {
        if (!blogPostIds.some(id => id === blogPost.id.toString()))
          remainingBlogPosts.push(blogPost);
      }
      setCurrentBlogPosts(remainingBlogPosts);
      setAllChecked(false);
      setLoading(false);
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to remove blog posts',
          description: 'Failed to remove blog posts',
          type: 'Error',
        })
      );
    }
  };

  const handlePreviewSchedule = (onGenerate: (params: any) => any) => {
    dispatch(
      openModal({
        modalName: 'previewGenerateScheduleModal',
        extraParams: {
          onGenerate,
          month,
          postType: 'blog',
        },
      })
    );
  };

  const handlePreCheckedGeneratePosts = async () => {
    if (!locationId) return;
    const systemMadeBlogPosts = currentBlogPosts.filter(isSystemMadeBlogPost);
    const renderedSystemMadePosts = systemMadeBlogPosts.map(blogPost => (
      <SmallBlogPostCard blogPost={blogPost} />
    ));
    const { hasSystemMadeBlogPosts } = await preCheckedGenerate(currentBlogPosts);

    if (hasSystemMadeBlogPosts) {
      Modal.warning({
        title: 'Blog posts are generated',
        content: (
          <div>
            There are blog posts generated for {month}. Please remove these below blog posts first
            for regeneration.
            <div style={{ marginTop: 20 }}>{renderedSystemMadePosts}</div>
          </div>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {},
        okText: `Remove ${systemMadeBlogPosts.length} blog posts`,
        onOk: async () => {
          Modal.destroyAll();
          const systemMadeblogPostIds = systemMadeBlogPosts.map(blogPost => blogPost.id.toString());
          await handleBatchRemoveBlogPosts(systemMadeblogPostIds);
        },
      });
      return;
    }
    handlePreviewSchedule(() => setBlogTopicPreviewModal(true));
  };

  const executeGenerateEvents = async (events: StreamGenerateEvent[]) => {
    const currentMonth = moment(month, MONTH_KEY_DATE_FORMAT);
    let _streamBlogPosts: BlogPostModel[] = currentBlogPosts;
    for (const event of events) {
      setGenerationEvent(event.startEventType);
      await executeStreamApi<
        BackgroundEvent<BlogGenerateBackgroundEvent>,
        MonthlyGenerateBlogPostsParams
      >({
        apiMethod: event.actionMethod,
        payload: {
          startDate: currentMonth.startOf('month').unix(),
          endDate: currentMonth.endOf('month').unix(),
          topics: generateTopicsParam,
        },
        // eslint-disable-next-line no-loop-func
        onDecodedData: async data => {
          _streamBlogPosts = _streamBlogPosts.concat([convertBackgroundEventToBlogPost(data)]);
          setTimeout(() => {
            setCurrentBlogPosts(_streamBlogPosts);
          }, 500);
        },
        onFinishedCallback: async () => {
          setGenerationEvent(event.endEventType);
          dispatch(
            enqueueNotification({
              name: `Successfully generate posts`,
              description: 'Successfully generate posts',
              type: 'Success',
            } as SnackbarItem)
          );
        },
      });
    }
  };

  const handleGenerateMonthPosts = async () => {
    const events: StreamGenerateEvent[] = [
      {
        startEventType: GenerationEvent.GenerateBlogPosts,
        endEventType: GenerationEvent.Finished,
        actionMethod: blogApiStreamService.streamGenerateBlogPosts,
      },
    ];
    await executeGenerateEvents(events);
  };

  useEffect(() => {
    const checkedBlogPosts: Record<string, boolean> = {};
    for (const blogPost of currentBlogPosts) {
      checkedBlogPosts[blogPost.id] = allChecked;
    }
    setSelectedBlogPosts(checkedBlogPosts);
  }, [currentBlogPosts, allChecked]);

  useEffect(() => {
    setCurrentBlogPosts(blogPosts);
    const idMap: Record<string, BlogPostModel> = {};
    for (const blogPost of blogPosts) {
      idMap[blogPost.id] = blogPost;
    }
    setBlogPostIdMap(idMap);
  }, [blogPosts]);

  useEffect(() => {
    if (generationEvent === GenerationEvent.Finished)
      setTimeout(() => {
        setGenerationEvent(GenerationEvent.Idle);
      }, 3000);
  }, [generationEvent]);

  return (
    <div
      id={`blog-month-container`}
      style={{
        margin: '20px 0px',
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
        ...(isTablet
          ? {
              borderTop: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            }
          : {
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            }),
        position: 'relative',
        padding: '10px 20px',
        borderRadius: 10,
        minHeight: '300px',
        width: '100%',
      }}>
      <Drawer
        open={generationEvent !== GenerationEvent.Idle}
        getContainer={false}
        closable={false}
        mask={false}
        width={isTablet ? '100%' : '30%'}
        style={{
          height: '100%',
        }}>
        <div id={'drawer-step-container'}>
          <Steps
            size={'small'}
            direction="vertical"
            current={
              (
                {
                  [GenerationEvent.GenerateBlogPosts]: 0,
                  [GenerationEvent.Finished]: 1,
                } as any
              )[generationEvent as any]
            }
            items={[
              {
                title: 'Generate blog posts...',
                description: 'Craft blog posts from generated topic',
              },
              {
                title: 'Finished',
                description: 'Successfully generating blog posts',
              },
            ]}
          />
        </div>
      </Drawer>
      <div
        id={`blog-month-container-${month}`}
        style={{
          ...MIDDLE_STYLE,
          width: '100%',
          flexDirection: isTablet ? 'column' : 'row',
          justifyContent: 'space-between',
        }}>
        <div style={{ marginBottom: 20, ...MIDDLE_STYLE }}>
          {generationEvent !== GenerationEvent.Idle && (
            <LoadingOutlined style={{ marginRight: 10 }} />
          )}
          <Space>
            {splittedMonthKey.map((value, index) =>
              index === 0 ? <h3>{value}</h3> : <div>/ {value}</div>
            )}
          </Space>
          {!isTablet && (
            <span style={{ marginLeft: 10, fontWeight: 'normal' }}>
              ({currentBlogPosts.length} blog posts)
            </span>
          )}
        </div>
        <Space
          style={{ width: isTablet ? '100%' : 'fit-content' }}
          direction={isTablet ? 'vertical' : 'horizontal'}>
          {checkedBlogPosts.length > 0 && (
            <div style={{ ...MIDDLE_STYLE }}>
              <Popconfirm
                onConfirm={() => handleBatchRemoveBlogPosts(checkedBlogPosts)}
                title="Delete selected posts"
                description="Are you sure to delete these posts?"
                okButtonProps={{
                  size: 'middle',
                }}
                cancelButtonProps={{
                  size: 'middle',
                }}
                okText="Yes"
                cancelText="No">
                <Button
                  id={'remove-posts-button'}
                  style={{ width: isTablet ? '100%' : 'fit-content' }}
                  danger
                  loading={loading}>
                  <DeleteOutlined /> Remove {checkedBlogPosts.length} selected posts
                </Button>
              </Popconfirm>
            </div>
          )}
          {!monthInThePast && (
            <Button
              id={'generate-blog-posts-button'}
              loading={generationEvent !== GenerationEvent.Idle}
              type={'primary'}
              style={{ width: isTablet ? '100%' : 'fit-content' }}
              onClick={handlePreCheckedGeneratePosts}>
              ✨ Generate blog posts
            </Button>
          )}
        </Space>
      </div>
      {children({
        blogPosts: currentBlogPosts.sort(
          (postA, postB) =>
            formatUtcTimestamp(postA.publish_at) - formatUtcTimestamp(postB.publish_at)
        ),
        allChecked,
        selectedBlogPosts,
        setSelectedBlogPosts,
        setAllChecked,
      })}
      <BlogPreviewTopicsModal
        open={blogTopicPreviewModal}
        setOpen={setBlogTopicPreviewModal}
        month={month}
        onGenerate={handleGenerateMonthPosts}
        topics={generateTopicsParam}
        setTopics={setGenerateTopicsParam}
      />
    </div>
  );
};

export default BlogPostMonthContainerLayout;
