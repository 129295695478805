import { ReviewRequestReducerProps } from '@core/features';
import { ReviewRequest, ReviewRequestStatus } from '@core/models';
import { useMemo } from 'react';
import { useReviewRequestState } from '..';
import moment from 'moment';
import { cloneArray } from '@utils/array.util';

export type ExtendedRequests = ReviewRequestReducerProps & {
  all: ReviewRequest[];
  openedRequests: ReviewRequest[];
  pendingRequests: ReviewRequest[];
  reviewedRequests: ReviewRequest[];
  draftedRequests: ReviewRequest[];
  followUpRequests: ReviewRequest[];
};

export const useExtendedRequests = (): ExtendedRequests => {
  const reviewRequestState = useReviewRequestState();
  const { requests } = reviewRequestState;
  const sortedRequests = useMemo(
    () =>
      cloneArray(requests).sort(
        (requestA, requestB) =>
          moment(requestB.created_date).unix() - moment(requestA.created_date).unix()
      ),
    [requests]
  );

  const { openedRequests, followUpRequests, reviewedRequests, draftedRequests, pendingRequests } =
    useMemo<Record<string, ReviewRequest[]>>(() => {
      const followUpRequests: ReviewRequest[] = [];
      const openedRequests: ReviewRequest[] = [];
      const pendingRequests: ReviewRequest[] = [];
      const reviewedRequests: ReviewRequest[] = [];
      const draftedRequests: ReviewRequest[] = [];
      for (const request of sortedRequests) {
        if (request.stage === ReviewRequestStatus.Reviewed) {
          reviewedRequests.push(request);
        } else if (request.number_of_email_open > 0) {
          openedRequests.push(request);
        } else if (request.stage === ReviewRequestStatus.Pending) {
          pendingRequests.push(request);
        } else if (request.stage === ReviewRequestStatus.Draft) {
          draftedRequests.push(request);
        }

        if (request.followUpCount > 0) {
          followUpRequests.push(request);
        }
      }
      return {
        openedRequests,
        reviewedRequests,
        draftedRequests,
        pendingRequests,
        followUpRequests,
      };
    }, [sortedRequests]);

  return {
    ...reviewRequestState,
    all: sortedRequests,
    followUpRequests,
    openedRequests,
    pendingRequests,
    reviewedRequests,
    draftedRequests,
  };
};
