import React from 'react';

const GoogleMapLocationMaker: any = ({
  isSpecial,
  children,
}: {
  isSpecial?: boolean;
  children?: React.ReactNode;
}) => (
  <div style={{ position: 'relative' }}>
    <div
      style={{
        color: 'white',
        fontSize: 15,
        padding: '10px 20px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        zIndex: 10,
      }}>
      {isSpecial ? (
        <img src={'/map-icon/special-maker.png'} width={50} alt={'Special Maker'} />
      ) : (
        <img src={'/map-icon/normal-maker.png'} width={35} alt={'Normal Maker'} />
      )}
    </div>
    <div style={{ position: 'absolute', width: '500px', left: -90, top: -90 }}>{children}</div>
  </div>
);

export default GoogleMapLocationMaker;
