import React, { useState } from 'react';
import { LoadableContainer } from '@components/index';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Divider } from 'antd';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { LoadingOutlined } from '@ant-design/icons';
import { useStudioState } from '@core/configureStore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { StudioPhoto } from '@core/models';

interface Props {
  onPhotosChanged: (photos: StudioPhoto[]) => void;
}

const PhotoGallerySuggestionList: React.FC<Props> = ({ onPhotosChanged }) => {
  const [selectedPhotos, setSelectedPhotos] = useState<Record<string, boolean>>({});
  const { studioPhotos, isLoading } = useStudioState();

  const handleSelectPhoto = (studioPhoto: StudioPhoto) => {
    const newSelectedPhotos: Record<string, boolean> = {
      ...selectedPhotos,
      [studioPhoto.id]: !selectedPhotos[studioPhoto.id],
    };
    setSelectedPhotos(newSelectedPhotos);
    onPhotosChanged(studioPhotos.filter(studioPhoto => newSelectedPhotos[studioPhoto.id]));
  };

  return (
    <div>
      <LoadableContainer
        isLoading={isLoading}
        loadComponent={
          <div style={{ ...MIDDLE_STYLE, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            <LoadingOutlined style={{ marginRight: 10 }} />
            Loading images of your business...
          </div>
        }>
        <div
          style={{
            padding: '10px 15px',
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            borderRadius: 10,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
          }}>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <h4 style={{ fontSize: 'smaller' }}>🏞️ Photo suggestions</h4>
            <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, fontSize: 'smaller' }}>
              Select photo from your gallery
            </p>
          </div>
          <Divider style={{ margin: `10px 0px 0px 0px` }} />
          <div style={{ margin: '10px 0px 0px 0px' }}>
            <div style={{ display: 'flex', margin: '10px 0px', overflowX: 'auto' }}>
              {studioPhotos.map(studioPhoto => (
                <LazyLoadImage
                  onClick={() => handleSelectPhoto(studioPhoto)}
                  loading="lazy"
                  className="card--image"
                  src={studioPhoto.url}
                  style={{
                    maxWidth: 80,
                    margin: '0px 5px 0px 0px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    border: selectedPhotos[studioPhoto.id]
                      ? `4px solid ${GLOBAL_THEME_COLOR.$highlight_color}`
                      : 'unset',
                  }}
                  width={80}
                  height={80}
                />
              ))}
            </div>
          </div>
        </div>
      </LoadableContainer>
      <Divider />
    </div>
  );
};

export default PhotoGallerySuggestionList;
