import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { InquiryReducerProps } from '../type';

type SubmitInquiryPayload = {
  inquiryId: string;
  email: string;
  phone: string | undefined;
};

export const respondInquiry = createAsyncThunk(
  'inquiry/respondInquiry',
  async ({ inquiryId, email, phone }: SubmitInquiryPayload, thunkApi) => {
    await heroApiService.respondInquiry(inquiryId, email, phone);
    const data = await heroApiService.getInquiryById(inquiryId);
    return data;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(respondInquiry);

export const respondInquiryReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.inquiry = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isOpening = true;
  },
} as ExtraReducer<InquiryReducerProps>;
