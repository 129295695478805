import { useAppDispatch, useReviewState } from '@core/configureStore';
import { filterReviewStatus, ReviewFilterStatus } from '@core/features';
import { Select, Space } from 'antd';
import React from 'react';

type Props = {};

const ReviewFilterButton = (props: Props) => {
  const dispatch = useAppDispatch();
  const { filterStatus } = useReviewState();
  const onFilterStatusChange = (filter: ReviewFilterStatus) => {
    dispatch(filterReviewStatus(filter));
  };

  return (
    <React.Fragment>
      <Space style={{ display: 'flex' }}>
        <span style={{ fontWeight: 'normal', fontSize: 15 }}>Filter:</span>
        <Select
          style={{ width: 150, marginRight: 20 }}
          defaultValue={filterStatus}
          onChange={onFilterStatusChange}
          options={[
            {
              value: ReviewFilterStatus.All,
              label: 'All',
            },
            {
              value: ReviewFilterStatus.Generated,
              label: 'Generated',
            },
            {
              value: ReviewFilterStatus.NotResponded,
              label: 'Not Responded',
            },
            {
              value: ReviewFilterStatus.Responded,
              label: 'Responded',
            },
          ]}
        />
      </Space>
    </React.Fragment>
  );
};

export default ReviewFilterButton;
