import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { AuthReducerProps, AuthState } from '../type';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { AuthUser } from '@core/models';

export const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async (_, thunkApi) => {
  let result: AuthUser = await heroApiService.getCurrentUser();
  if (result.selected_location) {
    await thunkApi.dispatch(fetchSelectedLocation());
  }
  return result;
});

const [fulfilled, pending, rejected] = getSideEffectState(fetchCurrentUser);

export const fetchCurrentUserReducer = {
  [fulfilled]: (state, action) => {
    state.isAuthLoading = false;
    state.authState = AuthState.Authenticated;
    state.user = action.payload;
  },
  [rejected]: (state, _) => {
    state.isAuthLoading = false;
    state.authState = AuthState.Unauthenticated;
    state.user = undefined;
  },
  [pending]: (state, _) => {
    state.isAuthLoading = true;
    state.authState = AuthState.Authenticating;
  },
} as ExtraReducer<AuthReducerProps>;
