import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';

export const fetchLocationData = createAsyncThunk(
  'business/fetchLocationData',
  async (_, thunkApi) => {
    try {
      const result = await heroApiService.getAllLocations();
      return result;
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchLocationData);

export const fetchLocationDataReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.locations = action.payload;
    state.error = undefined;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.locations = [];
    state.error = 'MISSING_PERMISSION';
  },
  [pending]: (state, action) => {
    state.isLoading = true;
    state.error = undefined;
  },
} as ExtraReducer<LocationReducerProps>;
