import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useReviewStatistics } from './useReviewStatistics';

const annualLineChart = (
  monthlyPositiveReviews: number[],
  monthlyNegativeReviews: number[],
  months: string[]
) => ({
  series: [
    {
      name: 'Positive Reviews',
      data: monthlyPositiveReviews,
      offsetY: 0,
    },
    {
      name: 'Negative Reviews',
      data: monthlyNegativeReviews,
      offsetY: 0,
    },
  ],

  options: {
    chart: {
      width: '100%',
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
    },

    legend: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },

    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: ['#8c8c8c'],
        },
      },
    },
    colors: [GLOBAL_THEME_COLOR.$highlight_color, '#d9d9d9'],
    xaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: [
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
          ],
        },
      },
      categories: months,
    },

    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  },
});

export const useReviewsLineChart = () => {
  const {
    selectedMonths,
    monthlyNegativeReviews,
    monthlyPositiveReviews,
    setSelectedYear,
    selectedYear,
  } = useReviewStatistics();
  return {
    config: annualLineChart(monthlyPositiveReviews, monthlyNegativeReviews, selectedMonths) as any,
    setSelectedYear,
    selectedYear,
  };
};
