import React from 'react';
import { useGenerationDataStore } from '@core/hooks';
import { GeneratorEngineStatus } from '@core/models';
import loadable from '@loadable/component';
import BusinessInquiryStep from '@components/BusinessInquiryStep';

const GenerateStatusOverlay = loadable(() => import('@components/GenerateStatusOverlay'));
const GenerateScreen = loadable(() => import('@pages/GenerateScreen'));
const InfoCollectorScreen = loadable(() => import('@pages/InfoCollectorScreen'));

type Props = {};

const BusinessBuilderScreen = (props: Props) => {
  const { engineStatus } = useGenerationDataStore();
  return (
    <div>
      {engineStatus === GeneratorEngineStatus.NotStarted ? (
        <InfoCollectorScreen>
          <BusinessInquiryStep />
        </InfoCollectorScreen>
      ) : (
        <GenerateScreen />
      )}
      <GenerateStatusOverlay />
    </div>
  );
};

export default BusinessBuilderScreen;
