import { Divider, Modal } from 'antd';
import { useAuthState, useModalState } from '@core/.';
import { BRAND_NAME } from '@constants/brand';
import WelcomeSelectLocationModalContent from './WelcomSelectLocationModalContent';

type Props = {};

const WelcomeSelectLocationModal = (_: Props) => {
  const { welcomeLocationModal } = useModalState();
  const { user } = useAuthState();

  function capitalizeFirstLetter(s: String) {
    if (!s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  return (
    <Modal
      title={
        <h2 className="airbnb-font" style={{ fontSize: 22, fontWeight: '500' }}>
          Welcome,{' '}
          <span className={'capitalize-first'}>
            {capitalizeFirstLetter(user?.given_name as String)}
          </span>
          ! <br />
          Let's help you achieve 5x more Google reviews.
        </h2>
      }
      centered
      closable={false}
      open={welcomeLocationModal}
      footer={[]}
      width={550}>
      <Divider style={{ margin: '18px 0' }} />
      <WelcomeSelectLocationModalContent />
    </Modal>
  );
};

export default WelcomeSelectLocationModal;
