import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Modal, Popover, Skeleton, Space } from 'antd';
import { useAppDispatch, useAuthState, useModalState, useStudioState } from '@core/configureStore';
import { closeModal, openDrawer, openModal } from '@core/features';
import { StudioPhoto, StudioPhotoCollection, StudioPhotoCollections } from '@core/models';
import { getGenerationQueuedImages, getMediaCountInRange } from '@utils/social-studio.util';
import { BrowsePhotoGalleryDrawer, LoadableContainer, PreviewPostPayloadItem } from '..';
import moment from 'moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import { getDateConfigFromMoment, getSocialPostRangeSchedules } from '@utils/date.util';
import { useBreakpoint } from '@core/hooks';
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { fetchGalleryImages } from '@core/features/studio/thunks';

const convertCollectionToRecord = (collection: StudioPhotoCollection) => {
  const record: Record<string, boolean> = {};
  for (const item of collection.items) {
    record[item.id] = true;
  }
  return record;
};

const PreviewPhotoPickerModal: React.FC<any> = () => {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { studioPhotos } = useStudioState();
  const { user } = useAuthState();
  const { previewPhotoPickerModal, extraParams } = useModalState();
  const [selectedStudioPhotoCollectionIndx, setSelectedStudioPhotoCollectionIndx] = useState<
    number | undefined
  >(undefined);
  const month = useMemo<string | undefined>(() => extraParams?.month, [extraParams]);
  const onPhotoCollectionsSelected = useMemo<
    (studioPhotos: StudioPhotoCollections) => any | undefined
  >(() => extraParams?.onPhotoCollectionsSelected, [extraParams]);
  const [studioPhotoCollections, setStudioPhotoCollections] = useState<StudioPhotoCollections>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<number[]>([]);
  const limitNumberOfItems = useMemo<number>(() => schedules?.length, [schedules]);

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'previewPhotoPickerModal',
      })
    );
  };

  const handleUploadPhotoGallery = async (updatedStudioPhotos: StudioPhoto[]) => {
    const updatedStudioPhotoCollections = studioPhotoCollections.map<StudioPhotoCollection>(
      (studioPhotoCollection, index) =>
        index === selectedStudioPhotoCollectionIndx
          ? {
              ...studioPhotoCollection,
              items: updatedStudioPhotos,
            }
          : studioPhotoCollection
    );
    setStudioPhotoCollections(updatedStudioPhotoCollections);
    setSelectedStudioPhotoCollectionIndx(undefined);
  };

  const handlePhotoCollectionClicked = (index: number) => {
    const studioPhotoCollection = studioPhotoCollections[index];
    setSelectedStudioPhotoCollectionIndx(index);
    dispatch(
      openDrawer({
        drawerName: 'photoGalleryBrowseDrawer',
        extraParams: {
          defaultSelectedPics: studioPhotoCollection
            ? convertCollectionToRecord(studioPhotoCollection)
            : {},
        },
      })
    );
  };

  const handleSelectPhotos = () => {
    onPhotoCollectionsSelected && onPhotoCollectionsSelected(studioPhotoCollections);
    handleCloseModal();
  };

  const handleRemoveItem = (indx: number) => {
    setStudioPhotoCollections(
      collections => (collections = collections.filter((_, index) => index !== indx))
    );
  };

  const handleViewSchedule = () => {
    handleCloseModal();
    dispatch(
      openModal({
        modalName: 'customizeAiProfileModal',
      })
    );
  };

  useEffect(() => {
    if (!user?.selected_location || !schedules || schedules.length <= 0) return;
    let currenStudioPhotos = getGenerationQueuedImages(studioPhotos),
      currentSelectedStudioPhotos: StudioPhotoCollections = [];
    const scheduleIterator = schedules[Symbol.iterator]();
    while (
      currentSelectedStudioPhotos.length <= limitNumberOfItems &&
      currenStudioPhotos.length > 0
    ) {
      const [, , randomMediaCount] = getMediaCountInRange(user.selected_location, true);
      const usedStudioPhotos = currenStudioPhotos.slice(0, randomMediaCount);
      currenStudioPhotos = currenStudioPhotos.slice(usedStudioPhotos.length);
      const scheduleValue = scheduleIterator.next().value;
      if (!scheduleValue) break;
      const scheduleDate = moment.unix(scheduleValue);
      currentSelectedStudioPhotos = currentSelectedStudioPhotos.concat({
        items: usedStudioPhotos,
        description:
          usedStudioPhotos?.[0]?.description || `Introduce about ${user?.selected_location?.title}`,
        ...getDateConfigFromMoment(scheduleDate),
      });
    }
    setStudioPhotoCollections(currentSelectedStudioPhotos);
  }, [studioPhotos, schedules, user, limitNumberOfItems, previewPhotoPickerModal]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      if (month) {
        try {
          await dispatch(fetchGalleryImages());
          const currentMonth = moment(month, MONTH_KEY_DATE_FORMAT);
          const { socialPostSchedules } = await getSocialPostRangeSchedules(
            currentMonth.startOf('month'),
            0
          );
          setSchedules(socialPostSchedules);
        } catch (e) {
          console.log(e);
        }
        setLoading(false);
      }
    };
    init();
  }, [month, previewPhotoPickerModal]);

  return (
    <React.Fragment>
      <Modal
        title={<h3 className="airbnb-font">Prepare photos to generate</h3>}
        centered
        open={previewPhotoPickerModal}
        onCancel={handleCloseModal}
        width={'100%'}
        style={{ maxWidth: 600, overflow: 'hidden' }}
        footer={[]}>
        {schedules.length > 0 ? (
          <div>
            Amazely will schedule {schedules.length} posts for this month on the dates below.
          </div>
        ) : (
          <div>
            No remaining posts for this month based on your{' '}
            <span style={{ fontWeight: 'bold' }}>selected schedule</span>. Try generating posts for
            the next month or updating the schedule.
          </div>
        )}
        <Divider />
        <div style={{ ...MIDDLE_STYLE }}>
          <Space style={{ margin: '0px 0px 20px 0px' }}>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              disabled={limitNumberOfItems === 0}
              type={'primary'}
              onClick={handleSelectPhotos}>
              Generate Posts
            </Button>
          </Space>
        </div>
        <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
          {schedules.length > 0 && (
            <React.Fragment>
              <div style={{ marginBottom: 15, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                <ArrowRightOutlined /> Click on the photos below to swap them with others from your
                gallery.
              </div>
              <div
                style={{ maxHeight: 500, marginBottom: 50, overflow: 'auto', padding: '0px 15px' }}>
                <div
                  className="card-list"
                  style={{ flexWrap: 'wrap', columnCount: isTablet ? 1 : 2, paddingBottom: 50 }}>
                  {studioPhotoCollections.map((studioPhotoCollection, index) => (
                    <div className="card">
                      {schedules.length > 1 && (
                        <Popover content="Remove">
                          <div
                            onClick={() => handleRemoveItem(index)}
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              zIndex: 10,
                              right: -8,
                              width: 30,
                              height: 30,
                              padding: '5px 5px',
                              borderRadius: '50%',
                              color: GLOBAL_THEME_COLOR.$text_color_contrast,
                              backgroundColor: GLOBAL_THEME_COLOR.$error_color,
                              ...MIDDLE_STYLE,
                            }}>
                            <DeleteOutlined style={{ margin: 0 }} />
                          </div>
                        </Popover>
                      )}
                      <Popover content="Replace photos">
                        <PreviewPostPayloadItem
                          payload={studioPhotoCollection}
                          handlePhotoPayloadClicked={() => handlePhotoCollectionClicked(index)}
                        />
                      </Popover>
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                The dates are selected based on{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: GLOBAL_THEME_COLOR.$highlight_color,
                    cursor: 'pointer',
                  }}
                  onClick={handleViewSchedule}>
                  your schedule.
                </span>
                . Amazely will select photos from your gallery.
              </div>
            </React.Fragment>
          )}
        </LoadableContainer>
        <BrowsePhotoGalleryDrawer singleFile={false} onLoad={handleUploadPhotoGallery} />
      </Modal>
    </React.Fragment>
  );
};

export default PreviewPhotoPickerModal;
