import React from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAppNavigation } from '@core/hooks';

type Props = {};

const AddRequestButton = (props: Props) => {
  const { navigate } = useAppNavigation();
  const addNewRequest = () => {
    navigate('requestCreation');
  };
  return (
    <React.Fragment>
      <Button type="primary" onClick={addNewRequest}>
        <UserAddOutlined /> Create new request
      </Button>
    </React.Fragment>
  );
};

export default AddRequestButton;
