import { useState } from 'react';
import { CallBackProps, STATUS } from 'react-joyride';

// Hooks for Joyride utilities
export const useJoyrideHelpers = () => {
  const [guideTourRunning, setGuideTourRunning] = useState(false);
  const [guideTourStepIndex, setGuideTourStepIndex] = useState(0);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setGuideTourRunning(false);
    }
  };

  return {
    guideTourRunning,
    setGuideTourRunning,
    guideTourStepIndex,
    setGuideTourStepIndex,
    handleJoyrideCallback,
  };
};
