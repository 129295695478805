import React, { useMemo } from 'react';
import { Button, Col, Divider, Drawer, Input, Rate, Row, Skeleton, Tag } from 'antd';
import { useAppDispatch, useBreakpoint, useReviewRequestState } from '@core/.';
import { deselectRequest } from '@core/features';
import { ReviewRequestStatus } from '@core/models';
import { formatUtcDate } from '@utils/.';
import RequestStageSection from '../RequestStageSection';
import { publishDraft, selectRequest } from '@core/features/review-request/thunks';
import { DescriptionItem } from '@components/index';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

type Props = {};

const ReviewRequestDrawer = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const { request, isDetailLoading, isSelected } = useReviewRequestState();
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(deselectRequest({}));
  };

  const createRequest = async () => {
    if (!request) return;
    await dispatch(publishDraft(request.id));
    await dispatch(selectRequest(request.id));
  };

  const isReviewed = useMemo(
    () => request && request.stage === ReviewRequestStatus.Reviewed,
    [request]
  );
  const isDrafted = useMemo(
    () => request && request.stage === ReviewRequestStatus.Draft,
    [request]
  );

  return (
    <Drawer
      width={isTablet ? '100%' : '50%'}
      placement="right"
      closable={true}
      onClose={close}
      open={isSelected}>
      {isDetailLoading ? (
        <Skeleton />
      ) : !!request ? (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DescriptionItem
              title="Status"
              content={
                (isReviewed && <Tag color="success">Reviewed</Tag>) ||
                (isDrafted && <Tag color="default">Draft</Tag>) || (
                  <Tag color="processing">Pending</Tag>
                )
              }
            />
            {isDrafted && <Button onClick={createRequest}>Create request from draft</Button>}
          </div>
          <Row>
            <Col span={isTablet ? 24 : 12}>
              <DescriptionItem title="Full Name" content={request.fullName} />
            </Col>
            <Col span={isTablet ? 24 : 12}>
              <DescriptionItem title="Contact email" content={request.contact} />
            </Col>
            <Col span={isTablet ? 24 : 12}>
              <DescriptionItem
                title="Followed up"
                content={
                  request.followUpCount > 0 ? (
                    <CheckCircleFilled style={{ color: GLOBAL_THEME_COLOR.$success_color }} />
                  ) : (
                    <LoadingOutlined />
                  )
                }
              />
            </Col>
          </Row>
          <Input.TextArea contentEditable={false} value={request.content} autoSize />
          <Divider />
          <RequestStageSection />
          <Divider />
          <h3>Customer Review</h3>
          {request.stage === ReviewRequestStatus.Reviewed ? (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <DescriptionItem
                    title="Rating"
                    content={
                      <Rate
                        disabled
                        style={{ marginLeft: 10 }}
                        defaultValue={1}
                        value={request.rating}
                      />
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Reviewed on"
                    content={formatUtcDate(request.last_modified_date)}
                  />
                </Col>
              </Row>
              <Input.TextArea
                placeholder="N/A"
                contentEditable={false}
                value={request.feedback}
                autoSize
              />
            </React.Fragment>
          ) : (
            <div>Customer does not review yet</div>
          )}
        </React.Fragment>
      ) : (
        <div>No request found</div>
      )}
    </Drawer>
  );
};

export default ReviewRequestDrawer;
