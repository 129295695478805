import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { StudioReducerProps } from './type';
import { SocialPost, StudioPhoto } from '@core/models';
import {
  fetchGalleryImagesReducer,
  deleteStudioPhotosReducer,
  updateStudioPhotoReducer,
  fetchSocialPostsReducer,
  fetchSocialPostsSlientReducer,
} from './thunks';

const studioSlice = createSlice<StudioReducerProps, SliceCaseReducers<StudioReducerProps>, string>({
  name: SliceName.Studio,
  initialState: {
    socialPosts: [],
    studioPhotos: [],
    isLoading: false,
    isPhotoUpdating: false,
  },
  reducers: {
    addSocialPost(state, action: PayloadAction<SocialPost>) {
      state.socialPosts = state.socialPosts.concat(action.payload);
    },
    setSocialPosts(state, action: PayloadAction<SocialPost[]>) {
      state.socialPosts = action.payload;
    },
    setStudioPhotos(state, action: PayloadAction<StudioPhoto[]>) {
      state.studioPhotos = action.payload;
    },
    updateStudioPhotoById(state, action: PayloadAction<{ id: number; data: StudioPhoto }>) {
      const updatedStudioPhotos: StudioPhoto[] = [];
      for (const studioPhoto of state.studioPhotos) {
        if (studioPhoto.id === action.payload.id) {
          updatedStudioPhotos.push(action.payload.data);
        } else {
          updatedStudioPhotos.push(studioPhoto);
        }
      }
      state.studioPhotos = updatedStudioPhotos;
    },
  },
  extraReducers: {
    ...fetchSocialPostsReducer,
    ...fetchSocialPostsSlientReducer,
    ...fetchGalleryImagesReducer,
    ...deleteStudioPhotosReducer,
    ...updateStudioPhotoReducer,
  },
});

export const { updateStudioPhotoById, setStudioPhotos, addSocialPost, setSocialPosts } =
  studioSlice.actions;
export const studioReducer = studioSlice.reducer;
