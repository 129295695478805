/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEventHandler, useMemo, useState } from 'react';
import { Button, Col, Divider, Drawer, Input, Rate, Row, Skeleton, Tag, Tooltip } from 'antd';
import { useAppDispatch, useAuthState, useReviewState, useSubscriptionDispatcher } from '@core/.';
import { generateResponse } from '@core/features/review/thunks/generateResponse.thunk';
import { deselectReview, editUnfinishedResponse } from '@core/features';
import { generateInquiryResponse, respondReview, saveReview } from '@core/features/review/thunks';
import { formatUtcDate } from '@utils/date.util';
import { MAXIMUM_GENERATION_PER_USER } from '@constants/openai-config';
import { MIDDLE_STYLE } from '@constants/responsive';
import { FacebookOutlined } from '@ant-design/icons';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { DescriptionItem, LoadableContainer, ReviewSocialPostEditor } from '..';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';

type Props = {};

const ReviewDrawer = (props: Props) => {
  const { user } = useAuthState();
  const { isTablet } = useBreakpoint();
  const [socialPostGenerate, setSocialPostGenerate] = useState<boolean>(false);
  const { review, isReviewLoading, isGenerating, isSelected } = useReviewState();
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();
  const location = useMemo(() => user?.selected_location, [user]);
  const { handleUpdateCreditUsage, checkPassiveTriggerActivated, handleActivateNow } =
    useSubscriptionDispatcher();

  const handleGenerateResponse = async (isInquiry: boolean) => {
    if (review && user?.selected_location) {
      isInquiry
        ? await dispatch(
            generateInquiryResponse({
              location: user.selected_location,
            })
          )
        : await dispatch(
            generateResponse({
              location: user.selected_location,
              message: review.comment || `${review.rating} rating`,
              numberOfCompletion: review.generated_times,
            })
          );
    }
  };

  const handleEditResponse = async () => {
    setIsEditing(true);
  };

  const handleSaveDraft = async () => {
    if (review) {
      await dispatch(
        saveReview({
          review: review,
          existed: true,
        })
      );
    }
    setIsEditing(false);
  };

  const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
    setIsEditing(true);
    if (review) {
      dispatch(
        editUnfinishedResponse({
          message: e.target.value,
        })
      );
    }
  };

  const handleShareToFacebook = () => {
    setSocialPostGenerate(true);
  };

  const handleRespond = async () => {
    if (review) {
      await dispatch(
        respondReview({
          message: review.generated_response,
        })
      );
    }
    if (user?.selected_location) {
      await handleUpdateCreditUsage(user.selected_location.location_id, 'manual_review_replied');
      await dispatch(fetchSelectedLocation());
    }
    setIsEditing(false);
    close();
  };

  const close = () => {
    dispatch(deselectReview({}));
    setSocialPostGenerate(false);
  };

  return (
    <Drawer
      width={socialPostGenerate || isTablet ? '95%' : 640}
      placement="right"
      closable={true}
      style={{ overflowX: 'hidden' }}
      onClose={close}
      open={isSelected}>
      <LoadableContainer isLoading={isReviewLoading} loadComponent={<Skeleton />}>
        <LoadableContainer isLoading={!review} loadComponent={<div>No review found</div>}>
          <Row gutter={50}>
            <Col span={!isTablet && socialPostGenerate ? 12 : 24}>
              {review && (
                <div style={{ maxWidth: 640 }}>
                  <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                    <div style={{ ...MIDDLE_STYLE }}>
                      <img
                        referrerPolicy="no-referrer"
                        style={{ width: 80, marginRight: 20 }}
                        src={review.reviewer.profilePhotoUrl}
                        alt={review.reviewer.profilePhotoUrl}
                      />
                      <h2>{review.reviewer.displayName}</h2>
                    </div>
                    {!socialPostGenerate && (
                      <Button onClick={handleShareToFacebook} type="primary">
                        <FacebookOutlined /> Showcase Review
                      </Button>
                    )}
                  </div>
                  <Divider />
                  <h3>Customer Review</h3>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem
                        title="Rating"
                        content={<Rate value={review.rating} disabled />}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title="Reviewed on"
                        content={formatUtcDate(review.created_date)}
                      />
                      <DescriptionItem
                        title="Showcased on"
                        content={
                          review.accessed_at
                            ? formatUtcDate(review.accessed_at)
                            : 'Not showcased yet'
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem
                        title="Status"
                        content={
                          !review.is_responded ? (
                            <Tag color="red">Not responded</Tag>
                          ) : (
                            <Tag color="success">Responded</Tag>
                          )
                        }
                      />
                    </Col>
                  </Row>
                  <p>{review.comment || 'N/A'}</p>
                  <Divider />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 10,
                    }}>
                    <h3>Response</h3>
                    {review.is_responded && !isEditing ? (
                      <>
                        {!isEditing && <Button onClick={handleEditResponse}>Edit response</Button>}
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: 10,
                          alignItems: 'center',
                        }}>
                        {review.rating < 3 && (
                          <>
                            <Tooltip title="Generate a reply as a form for contact submission">
                              <Button
                                type="default"
                                disabled={review.generated_times === MAXIMUM_GENERATION_PER_USER}
                                onClick={() => handleGenerateResponse(true)}>
                                Ask for contact
                              </Button>
                            </Tooltip>
                            <span>or</span>
                          </>
                        )}
                        <Tooltip title="AI generated response for reply">
                          <Button
                            type="primary"
                            disabled={review.generated_times === MAXIMUM_GENERATION_PER_USER}
                            onClick={() => handleGenerateResponse(false)}>
                            Generate response
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {isGenerating ? (
                    <Skeleton />
                  ) : (
                    <Input.TextArea
                      contentEditable={isEditing}
                      showCount
                      onChange={handleTextChange}
                      placeholder="Respond to the review"
                      value={review.generated_response?.replace('\n\n', '')}
                      autoSize
                    />
                  )}
                  {isEditing && (
                    <Button style={{ marginTop: 10 }} onClick={handleSaveDraft}>
                      Save Draft
                    </Button>
                  )}
                  <Divider />
                  <div style={{ float: 'right' }}>
                    {!review.is_responded || isEditing ? (
                      <React.Fragment>
                        {location &&
                        checkPassiveTriggerActivated(location, 'manual_review_replied') ? (
                          <Button type={'primary'} onClick={handleRespond}>
                            Respond
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleActivateNow();
                              close();
                            }}
                            type={'primary'}>
                            Respond
                          </Button>
                        )}
                      </React.Fragment>
                    ) : (
                      `Responded at ${formatUtcDate(review.responded_date)}`
                    )}
                  </div>
                </div>
              )}
            </Col>
            {socialPostGenerate && (
              <Col
                style={{
                  ...MIDDLE_STYLE,
                  flexDirection: 'column',
                  backgroundColor: GLOBAL_THEME_COLOR.$background_color,
                  borderRight: 'none',
                  borderRadius: 20,
                  height: '100%',
                }}
                span={isTablet ? 24 : 12}>
                <ReviewSocialPostEditor
                  review={review}
                  onClose={() => setSocialPostGenerate(false)}
                />
              </Col>
            )}
          </Row>
        </LoadableContainer>
      </LoadableContainer>
    </Drawer>
  );
};

export default ReviewDrawer;
