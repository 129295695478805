import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { StudioReducerProps } from '../type';
import { socialStudioApiService } from '@services/service-register';

export const fetchSocialPostsSilent = createAsyncThunk(
  'studio/fetchSocialPostsSilent',
  async (_, thunkApi) => {
    try {
      const socialPosts = await socialStudioApiService.getLocationSocialPosts();
      return socialPosts;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

const [fulfilled, _, rejected] = getSideEffectState(fetchSocialPostsSilent);

export const fetchSocialPostsSlientReducer = {
  [fulfilled]: (state, action: PayloadAction<any[]>) => {
    state.socialPosts = action.payload;
  },
  [rejected]: (state, action) => {
    state.socialPosts = [];
  },
} as ExtraReducer<StudioReducerProps>;
