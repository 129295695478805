export const defaultInvitationEmail = (businessName: string, ownerName: string) => [
  `Hi [RECIPIENT],\n\nIt's ${ownerName}, the owner of ${businessName} and I just wanted to say thank you for your support to our business.\n\nTo help this small business grow, I would really appreciate any feedback you have by leaving a review for ${businessName} on Google. It will only take a minute, I promise!\n\nYour feedback would be helpful to others and would mean a lot to us.\n\nWe appreciate your support!\n\n${ownerName}`,
];

export const defaultFollowupEmail = (businessName: string, ownerName: string) => [
  `Hi [RECIPIENT],\n\nI'm ${ownerName}, the owner of ${businessName}.\n\nI hope you had a good experience with our service.\nCould you take a moment to share your thoughts on Google? Your feedback is essential for us.\n\nAgain, thank you for your time and we look forward to serving you again in the future,\n\n${ownerName}`,
];

export const defaultInvitationSms = (businessName: string, ownerName: string) => [
  `Hi [RECIPIENT], Thank you for supporting ${businessName}. I wonder if you could take a moment to review us at [REVIEW-URL]. It would mean the world to me and my team. ${ownerName}`,
];

export const generateInquiryFromTemplate = (businessName: string, ownerName: string) => [
  `Hi,
  
  It's ${ownerName}, the owner of ${businessName}.
  
  Firstly, we sincerely appreciate the time you took to provide us with your feedback. We truly value your opinion and believe in continuously improving our services to ensure the utmost satisfaction of our valued customers.
  We would be grateful if you could provide us with your contact information, including your email address and phone number. Having direct communication with you will enable us to address your concerns in a more personalized manner and work towards a resolution that ensures your complete satisfaction. We genuinely care about your experience and want to take the necessary steps to make it right.
  
  Rest assured that your contact information will be treated with the utmost confidentiality and will only be used for the purpose of improving your experience with us. We respect your privacy and adhere to the highest standards of data protection.
  Once we receive your contact details, a member of our management team will reach out to you promptly to discuss your feedback in greater detail and find the best way to address your concerns. Your opinion matters greatly to us, and we are committed to making your future visits to our restaurant truly exceptional.
  
  Thank you once again for bringing your concerns to our attention. We look forward to hearing from you and having the opportunity to make amends. If you have any further questions or require immediate assistance, please do not hesitate to reach out to us.
  
  ${ownerName}`,
];
