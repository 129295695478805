import {
  ClockCircleOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LaptopOutlined,
  MessageOutlined,
  QrcodeOutlined,
  SelectOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { AnimatedComponent, SelectBusinessButton } from '@components/index';
import { BRAND_NAME } from '@constants/brand';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useAuthState } from '@core/configureStore';
import { Card, Divider, Popover, Space, Timeline } from 'antd';
import React from 'react';

type Props = {};

const ICON_FONT_SIZE = 25;

const HelpCenterScreen = (props: Props) => {
  const { user } = useAuthState();
  return (
    <AnimatedComponent.OpacityFadeInDiv delay={300}>
      <div className="screen-card">
        <div style={{ padding: '10px 20px' }}>
          <h1 style={{ fontSize: 30, color: 'black' }}>
            Welcome to {BRAND_NAME}, {user?.given_name}! 🎉
          </h1>
          <p>
            {BRAND_NAME}'s features can help your business to become more visible to potential
            customers.
          </p>
          <Divider />
          <div style={{ fontSize: 20 }}>
            <span style={{ fontWeight: 'bold' }}>Ready to go?</span> Start managing your business
            using our core products
          </div>
          <Space size={30} style={{ marginTop: 30, display: 'flex', flexWrap: 'wrap' }}>
            {[
              {
                icon: (
                  <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
                    <SelectOutlined />
                  </Space>
                ),
                steps: [
                  'Click a button on the App Bar ',
                  <SelectBusinessButton />,
                  'Select your business location',
                ],
                title: 'Switch business location',
                description: 'Easily manage multiple Google Business locations on Amazely.',
              },
              {
                icon: (
                  <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
                    <ClockCircleOutlined />
                    /
                    <MessageOutlined />
                  </Space>
                ),
                steps: [
                  'Go to Settings tab',
                  'Find Auto Engage section',
                  'Turn on the auto engage mode',
                  'Click on Activate button',
                ],
                title: 'Answer customer reviews',
                description:
                  'Automate the process of responding to customer feedback in a timely and efficient manner.',
              },
              {
                icon: (
                  <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
                    <StarOutlined />
                    +
                    <LaptopOutlined />
                  </Space>
                ),
                steps: [
                  'Go to "Showcase Reviews" tab',
                  'Select star ratings options',
                  'Turn on / off hide reviews without comment',
                  'Copy script',
                  'Add widget to website',
                ],
                title: 'Showcase reviews on website',
                description:
                  'Puts all your previously hard-earned reviews to work by elegantly showcasing the very best 4+ stars reviews on your website',
              },
              {
                icon: (
                  <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
                    <MessageOutlined />
                    +
                    <QrcodeOutlined />
                  </Space>
                ),
                steps: [
                  'Got to "Generate Reviews > QR Code"',
                  'Edit message and incentives option',
                  'View preview',
                  'Click Print button to print in PDF format',
                ],
                title: 'Collect reviews via QR code',
                description:
                  'Create a feedback form and generate a QR code for the form. You can then print or display the QR code in a visible location.',
              },

              {
                icon: (
                  <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
                    <FacebookOutlined />
                    <InstagramOutlined />
                    +
                    <UserOutlined />
                  </Space>
                ),
                steps: [
                  'Go to Studio tab',
                  'Click "Upload Image" button',
                  'Upload your business images',
                  'Wait for content to be generated',
                ],
                title: 'Generate social posts',
                description: `Leveraging generative AI, ${BRAND_NAME} automatically crafts beautiful social posts that will resonate with your wider audiences.`,
              },
              {
                icon: (
                  <Space style={{ display: 'flex', fontSize: ICON_FONT_SIZE }}>
                    <StarOutlined />
                    +
                    <UserOutlined />
                  </Space>
                ),
                steps: [
                  'Go to "Requests > Email & SMS"',
                  'Click "Create new request" button',
                  'Edit invitation and follow up email',
                  'Or upload CSV',
                  'Add contact information (Email / Name / Phone number)',
                  'Click "Add" button',
                ],
                title: 'Send requests to multiple customers',
                description:
                  'Create a list of customers that you want to send review requests to with branded customizable template.',
              },
            ].map(item => (
              <Popover
                placement="left"
                content={
                  <div style={{ padding: '10px 20px' }}>
                    <Timeline
                      style={{ marginTop: 20, fontWeight: 'normal' }}
                      items={item.steps.map(step => ({
                        children: step,
                      }))}
                    />
                  </div>
                }
                title={
                  <div style={{ padding: '10px 20px', maxWidth: 350 }}>
                    <h2>Learn</h2>
                    <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
                      <div
                        style={{
                          color: '#1890ff',
                          margin: '20px 0px',
                        }}>
                        {item.icon}
                      </div>
                    </div>
                    <p>{item.title}</p>
                  </div>
                }
                trigger="hover">
                <Card hoverable style={{ width: 350, height: 280, padding: '0px 10px' }}>
                  <div
                    style={{
                      color: '#1890ff',
                    }}>
                    {item.icon}
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <h4 style={{ fontSize: 'larger' }}>{item.title}</h4>
                    <p style={{ marginTop: 10 }}>{item.description}</p>
                  </div>
                </Card>
              </Popover>
            ))}
          </Space>
        </div>
      </div>
    </AnimatedComponent.OpacityFadeInDiv>
  );
};

export default HelpCenterScreen;
