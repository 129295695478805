/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Modal } from 'antd';
import { useGenerationDataStore } from '@core/hooks';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { GeneratorEngineStatus } from '@core/models';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME } from '@constants/brand';
import { useHistory } from 'react-router';

type Props = {};

const GenerateStatusOverlay = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const history = useHistory();
  const { engineStatus } = useGenerationDataStore();

  return (
    <Modal
      width={'100%'}
      className="snap-action-modal"
      footer={[]}
      maskStyle={{
        backdropFilter:
          engineStatus === GeneratorEngineStatus.NotEnoughDataNotify ? 'blur(50px)' : 'blur(10px)',
      }}
      closeIcon={<></>}
      open={
        engineStatus !== GeneratorEngineStatus.NotStarted &&
        engineStatus !== GeneratorEngineStatus.Finished
      }>
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
        <div style={{ fontSize: 150 }}>
          {engineStatus === GeneratorEngineStatus.RankingBusinessInLocalArea && '🏬'}
          {engineStatus === GeneratorEngineStatus.NotEnoughDataNotify && '😥'}
          {engineStatus === GeneratorEngineStatus.GenerateSocialPost && '🎉'}
          {engineStatus === GeneratorEngineStatus.CollectBusinessReviews && '🤩'}
          {engineStatus === GeneratorEngineStatus.GenerateReviewResponse && '💬'}
          {engineStatus === GeneratorEngineStatus.GenerateBlogPost && '📰'}
          {engineStatus === GeneratorEngineStatus.CollectShowcaseReviews && '🌟'}
          {engineStatus === GeneratorEngineStatus.Running && (
            <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 150 }} />
          )}
        </div>
        <h2
          style={{
            fontSize: isTablet ? 25 : 50,
            margin: 0,
            color: GLOBAL_THEME_COLOR.$text_color_contrast,
          }}>
          {engineStatus !== GeneratorEngineStatus.NotEnoughDataNotify && (
            <LoadingOutlined style={{ marginRight: 20 }} />
          )}
          {engineStatus === GeneratorEngineStatus.RankingBusinessInLocalArea &&
            'Retrieving other local businesses information'}
          {engineStatus === GeneratorEngineStatus.GenerateSocialPost && 'Generating social posts'}
          {engineStatus === GeneratorEngineStatus.CollectBusinessReviews &&
            'Collecting business reviews'}
          {engineStatus === GeneratorEngineStatus.GenerateReviewResponse && 'Responding to reviews'}
          {engineStatus === GeneratorEngineStatus.GenerateBlogPost && 'Generating blog posts'}
          {engineStatus === GeneratorEngineStatus.CollectShowcaseReviews && 'Showcasing reviews'}
          {engineStatus === GeneratorEngineStatus.Running && 'Gathering business information'}
          {engineStatus === GeneratorEngineStatus.NotEnoughDataNotify && 'Not Enough Business Data'}
        </h2>
        <p
          style={{
            fontSize: isTablet ? 15 : 20,
            textAlign: 'center',
            margin: 0,
            color: GLOBAL_THEME_COLOR.$text_color_contrast,
          }}>
          {engineStatus === GeneratorEngineStatus.RankingBusinessInLocalArea &&
            'Amazely is visualizing your business position in the local area...'}
          {engineStatus === GeneratorEngineStatus.GenerateSocialPost &&
            'Amazely is crafting your business social posts ...'}
          {engineStatus === GeneratorEngineStatus.CollectBusinessReviews &&
            'Amazely is collecting reviews of your business...'}
          {engineStatus === GeneratorEngineStatus.GenerateReviewResponse &&
            'Amazely is drafting response to your business reviews on Google...'}
          {engineStatus === GeneratorEngineStatus.GenerateBlogPost &&
            'Amazely is writing blog post to introduce your business...'}
          {engineStatus === GeneratorEngineStatus.CollectShowcaseReviews &&
            'Amazely is choosing reviews to showcase on social media...'}
          {engineStatus === GeneratorEngineStatus.Running &&
            'Amazely is gathering your business information...'}
          {engineStatus === GeneratorEngineStatus.NotEnoughDataNotify &&
            'Select another Google business for demo or sign in the app to start collecting reviews.'}
        </p>
        {engineStatus === GeneratorEngineStatus.NotEnoughDataNotify && (
          <Button
            style={{
              cursor: 'pointer',
              padding: 24,
              borderRadius: 50,
              marginTop: 50,
              ...MIDDLE_STYLE,
            }}
            size="large"
            type="primary"
            onClick={() => history.goBack()}>
            <ArrowLeftOutlined /> Select Another Business for Demo
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default GenerateStatusOverlay;
