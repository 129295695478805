import React from 'react';
import CalendarItemCard from '../CalendarItemCard';
import { useCalendarEvents } from '@core/hooks';
import { SocialPost } from '@core/models';

type Props = {
  socialPosts: SocialPost[];
  day?: number;
  month?: number;
  year?: number;
};

const CalendarEventsPopover = ({ socialPosts, day, month, year }: Props) => {
  const { events } = useCalendarEvents(socialPosts, {
    day,
    month,
    year,
  });
  return (
    <React.Fragment>
      <div style={{ maxHeight: 300, overflow: 'auto' }}>
        {events.map(event => (
          <CalendarItemCard item={event} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default CalendarEventsPopover;
