import React, { useMemo, useState } from 'react';
import { LoadableContainer } from '..';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Col, Input, Rate, Row, Space } from 'antd';
import { NEGATIVE_RATE_CEILING } from '@constants/rate-conversion';
import { useAppDispatch } from '@core/configureStore';
import { SnackbarItem, enqueueNotification } from '@core/features';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useBreakpoint, usePublicRouteService } from '@core/hooks';
import SubscriptionPackageCard from '@components/SubscriptionPackageCard';

const RenderFinishedContent = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <CheckOutlined style={{ fontSize: 50, color: 'green' }} />
      <div style={{ marginTop: 30, textAlign: 'center', width: '70%' }}>
        <h1>Submitted</h1>
        <p>Thank you for spending your time to leave us a review</p>
      </div>
    </div>
  );
};

type Props = {
  locationName: string;
  shieldFeature: boolean;
  negativeReviewButtonText?: string;
  placeId: string;
  onReviewSubmitted?: (params: {
    feedback: string;
    email: string;
    name: string;
    selectedRate: number;
  }) => Promise<void>;
  onPostReviewSubmitted: () => void;
};

const ReviewSubmissionForm = ({
  shieldFeature,
  negativeReviewButtonText,
  locationName,
  onReviewSubmitted,
  onPostReviewSubmitted,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useBreakpoint();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [submittedWithoutLicense, setSubmittedWithoutLicense] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [selectedRate, setSelectedRate] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const publicRouteService = usePublicRouteService();

  const isNegativeReview = useMemo(
    () => selectedRate !== 0 && selectedRate <= NEGATIVE_RATE_CEILING,
    [selectedRate]
  );

  const submitReview = async () => {
    try {
      setLoading(true);
      if (onReviewSubmitted) {
        await onReviewSubmitted({
          feedback,
          email,
          name,
          selectedRate,
        });
      }
      // setIsFinished(true); marking this as true would be premature in the case of positive ratings
      // doing so would discourage customers from submitting Google reviews since they thought it's done.
      return true;
    } catch (error: any) {
      dispatch(
        enqueueNotification({
          name: `Failed to submit review: ${error.message}`,
          type: 'Error',
        } as SnackbarItem)
      );
      setLoading(false);
      setSubmittedWithoutLicense(true);
      return false;
    }
  };

  const submitNegativeFeedback = async () => {
    if (await submitReview()) {
      setIsFinished(true);
      setLoading(false);
    }
  };

  const negativeReviewSubmitDisabled = useMemo(
    () => selectedRate === 0 || name.length === 0 || feedback.length === 0,
    [selectedRate, name, feedback]
  );

  return (
    <LoadableContainer isLoading={isFinished} loadComponent={<RenderFinishedContent />}>
      <div style={{ textAlign: 'center' }}>
        <h2 className="airbnb-font">Review request from</h2>
        <span style={{ fontSize: 30 }} className="fraunces-font">
          {locationName}
        </span>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Rate style={{ fontSize: 40 }} onChange={setSelectedRate} value={selectedRate} />
        </div>
        <Space
          direction="vertical"
          style={{ justifyContent: 'center', marginTop: 30, textAlign: 'left', width: '100%' }}>
          {shieldFeature && isNegativeReview && (
            <Row gutter={10}>
              <Col span={isMobile ? 24 : 12}>
                <h4 style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                  Name<span style={{ color: 'red' }}>*</span>
                </h4>
                <Input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Enter your name"
                />
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <h4 style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>Email address</h4>
                <Input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
              </Col>
              <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
                <h4 style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                  Feedback <span style={{ color: 'red' }}>*</span>
                </h4>
                <Input.TextArea
                  autoSize={{ minRows: 3 }}
                  contentEditable={true}
                  value={feedback}
                  onChange={e => setFeedback(e.target.value)}
                  placeholder="Enter your feedback"
                />
              </Col>
            </Row>
          )}
          {submittedWithoutLicense ? (
            <React.Fragment>
              <p
                style={{
                  border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                  padding: '5px 10px',
                  borderRadius: 10,
                }}>
                ⚠️ You’ve exceeded the free tier usage. Please{' '}
                <a href="https://app.amazely.co/#/app/subscription">activate Amazely</a> for
                unlimited review requests.
                <SubscriptionPackageCard
                  onClick={async () => {
                    await publicRouteService.sendUserActivitySlackNotification(
                      locationName ? locationName : 'Unknown',
                      '[✅ Activate Clicked]'
                    );
                    window.open('https://app.amazely.co/#/app/subscription');
                  }}
                />
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {shieldFeature && isNegativeReview ? (
                <React.Fragment>
                  <Button
                    loading={loading}
                    disabled={negativeReviewSubmitDisabled}
                    onClick={submitNegativeFeedback}
                    type={'primary'}
                    style={{ width: '100%' }}>
                    {negativeReviewButtonText || 'Submit review'}
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  loading={loading}
                  disabled={!selectedRate}
                  onClick={async () => {
                    if (await submitReview()) onPostReviewSubmitted();
                  }}
                  type={'primary'}
                  style={{ width: '100%' }}>
                  Continue
                </Button>
              )}
            </React.Fragment>
          )}
        </Space>
      </div>
    </LoadableContainer>
  );
};

export default ReviewSubmissionForm;
