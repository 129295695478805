import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { DrawerReducerProps } from './type';

const drawerSlice = createSlice<DrawerReducerProps, SliceCaseReducers<DrawerReducerProps>, string>({
  name: SliceName.Drawer,
  initialState: {
    appIntegrationDrawer: false,
    businessPhotoBrowseDrawer: false,
    unsplashPhotoBrowseDrawer: false,
    photoGalleryBrowseDrawer: false,
    facebookAlbumBrowseDrawer: false,
    pexelsPhotoBrowseDrawer: false,
    navigationDrawer: false,
    reviewAttributeDrawer: false,
    squareIntegrationPhotoBrowseDrawer: false,
    extraParams: {},
  },
  reducers: {
    openDrawer: (
      state,
      action: PayloadAction<{
        drawerName: keyof DrawerReducerProps;
        extraParams: Record<string, string>;
      }>
    ) => {
      state[action.payload.drawerName] = true as any;
      state.extraParams = action.payload.extraParams;
      return state;
    },
    closeDrawer: (
      state,
      action: PayloadAction<{
        drawerName: keyof DrawerReducerProps;
      }>
    ) => {
      state[action.payload.drawerName] = false as any;
      state.extraParams = {};
      return state;
    },
  },
  extraReducers: {},
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export const drawerReducer = drawerSlice.reducer;
