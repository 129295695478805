export type SocialAcccount = {
  id: string;
  name: string;
};

export const FACEBOOK_STUDIO_OAUTH = 'facebook:studio';
export const FACEBOOK_STUDIO_PAGE_OAUTH = 'facebook-page:studio';
export const INSTAGRAM_STUDIO_OAUTH = 'instagram:studio';
export const INSTAGRAM_STUDIO_BUSINESS_OAUTH = 'instagram-business:studio';
export const SQUARE_INTEGRATION_OAUTH = 'square:integration';
export const GOOGLE_BUSINESS_LOCATION_OAUTH = 'google-business-location';

export type ThirdPartyOauthType =
  | typeof FACEBOOK_STUDIO_OAUTH
  | typeof FACEBOOK_STUDIO_PAGE_OAUTH
  | typeof INSTAGRAM_STUDIO_OAUTH
  | typeof INSTAGRAM_STUDIO_BUSINESS_OAUTH
  | typeof SQUARE_INTEGRATION_OAUTH
  | typeof GOOGLE_BUSINESS_LOCATION_OAUTH;

export type OauthProfilePayload = {
  accessToken: string;
  refreshToken: string;
  token_expiry_date: Date | undefined;
  profileId: string;
  platform: ThirdPartyOauthType;
};
