import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { closeModal } from '@core/features';
import { Divider, Modal, Select, Space, Tag, TimePicker } from 'antd';
import { formatTimeNumber, getWeekdays } from '@utils/date.util';
import { MIDDLE_STYLE } from '@constants/responsive';
import cronstrue from 'cronstrue';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

type TimePoint = {
  hour: number;
  minute: number;
};

const AssistantScheduleSettingModal = () => {
  const dispatch = useAppDispatch();
  const { assistantScheduleSettingModal, extraParams } = useModalState();

  const MONDAY_INDEX_VALUE = '1';
  const TIME_POINT_DEFAULT_VALUE: TimePoint = {
    hour: 9,
    minute: 0,
  };
  const DEFAULT_TIME_POINTS = [TIME_POINT_DEFAULT_VALUE];
  const DEFAULT_WEEK_DAYS = [MONDAY_INDEX_VALUE];
  const [timePoints, setTimePoints] = useState<TimePoint[]>(DEFAULT_TIME_POINTS);
  const [weekdays, setWeekdays] = useState(DEFAULT_WEEK_DAYS);

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'assistantScheduleSettingModal',
      })
    );
  };

  const formatTimePoint = (timePoint: TimePoint) =>
    `${formatTimeNumber(timePoint.hour)}:${formatTimeNumber(timePoint.minute)}`;
  const getWeekdayStr = (weekdayIndex: number) => moment().day(weekdayIndex).weekday();

  const cronExprWeekdays = useMemo(
    () => weekdays.map(weekday => getWeekdayStr(parseInt(weekday))),
    [weekdays]
  );

  const cronExpressions = useMemo(
    () =>
      timePoints.map(
        timePoint =>
          `${timePoint.minute} ${timePoint.hour} * * ${
            weekdays.length > 0 ? cronExprWeekdays : '*'
          }`
      ),
    [cronExprWeekdays, timePoints, weekdays]
  );

  const handleRemoveWeekday = (value: string) =>
    setWeekdays(weekdays.filter(weekday => weekday !== value));

  const handleRemoveTimePoint = (timePoint: TimePoint) => {
    const updatedTimePoints = timePoints.filter(value => {
      return formatTimePoint(timePoint) !== formatTimePoint(value);
    });
    console.log(updatedTimePoints);
    setTimePoints(updatedTimePoints);
  };

  const handleOk = () => {
    extraParams?.onScheduleAdd(cronExpressions);
    handleModalClose();
  };

  useEffect(() => {
    setTimePoints(DEFAULT_TIME_POINTS);
    setWeekdays(DEFAULT_WEEK_DAYS);
  }, [assistantScheduleSettingModal]);

  return (
    <Modal
      title={'Add a new schedule'}
      open={assistantScheduleSettingModal}
      onOk={handleOk}
      onCancel={handleModalClose}
      closable>
      <Divider />
      <Space direction={'vertical'} size={10} style={{ width: '100%' }}>
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
          <div style={{ marginRight: 30 }}>Week days</div>
          <Select
            mode="multiple"
            size={'middle'}
            maxTagCount="responsive"
            placeholder="Weekdays"
            allowClear={true}
            tagRender={({ label, value }) => (
              <Tag color={'green'} closable onClose={() => handleRemoveWeekday(value)}>
                {label}
              </Tag>
            )}
            value={weekdays as any}
            onChange={setWeekdays}
            style={{ width: '100%', height: 'fit-content' }}
            options={getWeekdays().map((weekday, index) => ({
              label: weekday,
              value: index.toString(),
            }))}
          />
        </div>

        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', whiteSpace: 'nowrap' }}>
          <Space>
            <div style={{ marginRight: 20 }}>Time</div>
            {timePoints.map(timePoint => (
              <Tag color={'blue'}>
                {formatTimePoint(timePoint)}{' '}
                <DeleteOutlined onClick={() => handleRemoveTimePoint(timePoint)} />
              </Tag>
            ))}
          </Space>
          <TimePicker
            onOk={value =>
              setTimePoints([
                ...timePoints,
                {
                  hour: value.hour(),
                  minute: value.minute(),
                },
              ])
            }
            showNow={false}
            showSecond={false}
          />
        </div>
        {timePoints.length > 0 && (
          <React.Fragment>
            <Divider />
            <ul>
              {cronExpressions.map(cronExpression => (
                <li style={{ fontWeight: 500 }}>{cronstrue.toString(cronExpression)}</li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </Space>
    </Modal>
  );
};

export default AssistantScheduleSettingModal;
