import { RequestServiceStage } from '@core/models';
import { Tag } from 'antd';
import React from 'react';

type Props = {
  status: RequestServiceStage;
};

const ServiceStatusTag = ({ status }: Props) => {
  switch (status) {
    case RequestServiceStage.Pending:
      return <Tag color="processing">Pending</Tag>;
    case RequestServiceStage.Open:
      return <Tag color="green">Open</Tag>;
    case RequestServiceStage.Reviewed:
      return <Tag color="green-inverse">Reviewed</Tag>;
    case RequestServiceStage.Failed:
      return <Tag color="red">Failed</Tag>;
    case RequestServiceStage.Sent:
      return <Tag color="cyan">Sent</Tag>;
    default:
      return <></>;
  }
};

export default ServiceStatusTag;
