import { LocalLocation } from './user';

export interface FileImageItem {
  imageName: string;
  imageDescription?: string;
  imageData: string;
}

export interface GooglePhotoAlbum {
  id: string;
  title: string;
  productUrl: string;
  isWriteable: boolean;
  shareInfo: {
    shareableUrl: string;
    shareToken: string;
    isJoined: boolean;
    isOwned: boolean;
    isJoinable: boolean;
  };
  mediaItemsCount: string;
  coverPhotoBaseUrl: string;
  coverPhotoMediaItemId: string;
}

export enum StudioPhotoSource {
  Image = 'image',
  Review = 'review',
  SquareProductPhoto = 'SquareProductPhoto',
  // Flag enabled if the studio photo is not visible to the user
  PhantomAsset = 'PhantomAsset',
}

export type StudioPhoto = {
  id: number;
  media_id: string;
  location: LocalLocation;
  url: string;
  name: string;
  labels: string[];
  created_date: string;
  updated_date: string;
  accessed_at: string | undefined;
  publish_at: string | undefined;
  is_live: boolean;
  extension: string;
  description: string;
  source: StudioPhotoSource;
};

export enum StudioPhotoStage {
  Processing = 'Processing',
  Published = 'Published',
  Scheduled = 'Scheduled',
  Queued = 'In Queue',
  Draft = 'Draft',
}

export type StudioPhotoCollection = {
  items: StudioPhoto[];
  description: string;
  day: number;
  month: number;
  hour: number;
  minute: number;
  year: number;
};

export type StudioPhotoCollections = StudioPhotoCollection[];
