/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { usePitchDispatcher, usePublicRouteService } from '@core/hooks';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { useGenerationDataStore } from '@core/hooks';
import {
  BusinessSocialPost,
  GeneratorEngineStatus,
  GooglePlaceDetailData,
  GoogleSmartReview,
  PlaceData,
} from '@core/models';
import {
  MAX_GENERATED_REVIEWS,
  MAX_REVIEW_SHOWCASE_DESKTOP,
  MAX_REVIEW_SHOWCASE_MOBILE,
} from '@constants/pitch-business';
import { makeid } from '@utils/string.util';
import { timeout } from '@utils/date.util';
import { useURLQuery } from '@core/hooks/useUrlQuery';
import { useParams } from 'react-router';
import { publicRouteService } from '@services/service-register';
import SplashScreen from '@components/SplashScreen';

let notified = false;
const BusinessInquiryStep = () => {
  const salePitchService = usePublicRouteService();
  const { isTablet } = useBreakpoint();
  const query = useURLQuery();
  const businessName = useMemo<string>(() => query.get('name') || '', [query]);
  const pitchId = useMemo<string>(() => query.get('pitchId') || '', [query]);
  const { locationPlaceId } = useParams() as { locationPlaceId: string };
  const { getPlaceDetail, searchForGooglePlaces } = usePublicRouteService();
  const { setGenerationData, setEngineState } = useGenerationDataStore();
  const {
    startRankingBusinessesInLocal,
    startGenerateSocialPosts,
    buildSocialPostsFromPhotos,
    startCollectShowcaseReviews,
  } = usePitchDispatcher();

  const processBestReviews = (placeData: GooglePlaceDetailData) =>
    (placeData.result.reviews || [])
      .filter(review => review.text.length > 20 && review.rating >= 4)
      .sort(
        (reviewA: GoogleSmartReview, reviewB: GoogleSmartReview) =>
          reviewB.text.length - reviewA.text.length
      )
      .map((review: any) => ({
        ...review,
        generated_response: '',
      }));

  const startRetrieveBusinessData = async (
    placeData: GooglePlaceDetailData
  ): Promise<[BusinessSocialPost[], GoogleSmartReview[]]> => {
    if (!placeData) throw new Error('No place found');
    if (placeData.result.reviews?.length === 0 || placeData.result.photos?.length === 0)
      throw new Error('No data found for this location');
    const socialPosts = await buildSocialPostsFromPhotos(placeData);
    const bestReviews = processBestReviews(placeData);

    setGenerationData({
      ...placeData.result,
      location: placeData.result.formatted_address,
      name: placeData.result.name,
      type: placeData.result.types[0],
      longtitude: placeData.result.geometry.location.lng,
      latitude: placeData.result.geometry.location.lat,
      reviews: bestReviews,
    });
    return [socialPosts, bestReviews];
  };

  const startGenerateBlogPost = async (selectedPlace: PlaceData, topic: string) => {
    const content = await salePitchService.generatePitchBlogPost(
      selectedPlace.name,
      selectedPlace.editorial_summary?.overview || selectedPlace.types[0],
      topic
    );
    setGenerationData({
      blogPost: content,
    });
  };

  const startGenerateReviewResponse = async (
    selectedPlace: PlaceData,
    reviews: GoogleSmartReview[]
  ) => {
    const generated_responses: string[] = [];
    const currentReviews: GoogleSmartReview[] = reviews;
    for (let reviewCounter = 0; reviewCounter < currentReviews.length; reviewCounter++) {
      const reviewData = currentReviews[reviewCounter];
      const content = await salePitchService.generateReviewResponse(
        selectedPlace.name,
        selectedPlace.types[0],
        reviewData.rating,
        reviewData.text,
        reviewData.author_name
      );
      generated_responses.push(content);
      /** Update the list of social posts with generated captions */
      const updatedReviews: GoogleSmartReview[] = [];
      let index = 0;
      for (const review of currentReviews) {
        if (index <= reviewCounter) {
          updatedReviews.push({
            ...review,
            generated_response: generated_responses[index],
          });
        } else {
          updatedReviews.push(review);
        }
        index++;
      }
      setGenerationData({
        reviews: updatedReviews,
      });
    }
  };

  const handleGenerateFromName = async (selectedPlace: PlaceData) => {
    setEngineState(GeneratorEngineStatus.Running);
    const placeData = await getPlaceDetail(selectedPlace.place_id);
    await processGeneratingData(placeData);
  };

  const processGeneratingData = async (placeData: GooglePlaceDetailData) => {
    const maxReviewsResponsive = isTablet
      ? MAX_REVIEW_SHOWCASE_MOBILE
      : MAX_REVIEW_SHOWCASE_DESKTOP;
    setEngineState(GeneratorEngineStatus.Running);

    // Exclude point_of_interest from types
    let placeDataTypes = placeData.result.types;
    placeDataTypes = placeDataTypes?.filter(
      type => type !== 'point_of_interest' && type !== 'establishment'
    );
    if (placeDataTypes.length === 0) placeDataTypes = placeData.result.types;

    const bestReviews = processBestReviews(placeData);
    if (bestReviews.length < maxReviewsResponsive) {
      return setEngineState(GeneratorEngineStatus.NotEnoughDataNotify);
    }
    const [socialPosts, reviews] = await startRetrieveBusinessData(placeData);

    startGenerateBlogPost(placeData.result, 'Introduce about the business');
    startCollectShowcaseReviews(placeData.result, reviews.slice(0, maxReviewsResponsive));
    try {
      setEngineState(GeneratorEngineStatus.RankingBusinessInLocalArea);
      await startRankingBusinessesInLocal(placeData.result);
    } catch (e) {
      console.log('RANKING BUSINESS IN LOCAL: ', e);
    }

    try {
      setEngineState(GeneratorEngineStatus.GenerateSocialPost);
      await startGenerateSocialPosts(placeData.result, socialPosts);
    } catch (e) {
      console.log('GENERATE SOCIAL POSTS: ', e);
    }

    try {
      setEngineState(GeneratorEngineStatus.GenerateReviewResponse);
      await startGenerateReviewResponse(placeData.result, reviews.slice(0, MAX_GENERATED_REVIEWS));
    } catch (e) {
      console.log('GENERATE REVIEW RESPONSE: ', e);
    }
    setEngineState(GeneratorEngineStatus.CollectShowcaseReviews);
    await timeout(1000);
    setEngineState(GeneratorEngineStatus.GenerateBlogPost);
    await timeout(1000);
    setEngineState(GeneratorEngineStatus.Finished);

    if (notified) return;
    try {
      if (pitchId)
        await publicRouteService.updateMarketingPlaceData(pitchId, {
          pitch_generated_date: new Date(),
        });

      let uniqueIdentifier = localStorage.getItem('uniqueIdentifier');
      if (!uniqueIdentifier) {
        const randomID = makeid(10);
        localStorage.setItem('uniqueIdentifier', randomID);
        uniqueIdentifier = localStorage.getItem('uniqueIdentifier') as string;
      }

      await publicRouteService.sendSlackNotification(
        `${placeData.result.name} | ${placeData.result.formatted_address} | Source: ${document.referrer} | ID: ${uniqueIdentifier}`,
        placeData.result.types?.[0]
      );
    } catch (error) {
      console.log(error);
    }
    notified = true;
  };

  /** Start generating social posts and google reviews from the data */
  const handleGenerate = async (name: string, address: string) => {
    try {
      const placeResponse: { results: PlaceData[]; status: string } = await searchForGooglePlaces(
        name,
        address
      );
      const selectedPlace = placeResponse.results[0];

      if (!selectedPlace) throw new Error('No place is selected');

      if ((window as any).gtag) {
        (window as any).gtag('event', 'pitch_gen', {
          event_category: 'pitch_gen',
          event_label: selectedPlace.name,
        });
      }

      await handleGenerateFromName(selectedPlace);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loadInit = async () => {
      if (locationPlaceId) {
        const placeData = await getPlaceDetail(locationPlaceId);
        await processGeneratingData(placeData);
      } else if (businessName) {
        const [name, ...rest] = businessName.split(',');
        const address = rest.slice(rest.length - 1, rest.length).join(',');
        await handleGenerate(`${name}`, `${address}`);
      }
    };
    loadInit();
  }, [businessName, locationPlaceId]);

  useEffect(() => {
    const initMarketingPitch = async () => {
      try {
        const data = await publicRouteService.getMarketingPlaceByPitchId(pitchId);
        await handleGenerateFromName(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (pitchId) {
      initMarketingPitch();
    }
  }, [pitchId]);

  return <SplashScreen />;
};

export default BusinessInquiryStep;
