import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { RequestSource } from '@core/models';
import React from 'react';

type Props = {
  source: RequestSource;
};

const RenderRequestSourceIcon = ({ source }: Props) => {
  switch (source) {
    case RequestSource.Square:
      return <img alt="Square logo" src="square-inc.png" style={{ width: 20 }} />;
    case RequestSource.OpenDental:
      return <img alt="Open Dental logo" src="open-dental.jpg" style={{ width: 20 }} />;
    case RequestSource.QuickBooks:
      return <img alt="QuickBooks logo" src="quickbooks.png" style={{ width: 20 }} />;
    case RequestSource.Jobber:
      return <img alt="Jobber logo" src="jobber.png" style={{ width: 20 }} />;
    case RequestSource.Xero:
      return <img alt="Jobber logo" src="xero.png" style={{ width: 20 }} />;
    case RequestSource.Email:
      return <MailOutlined />;
    case RequestSource.Manual:
      return <UserOutlined />;
  }
};

export default RenderRequestSourceIcon;
