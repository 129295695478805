import { BRAND_NAME, BRAND_URL } from '@constants/brand';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { useBreakpoint } from '@core/hooks';
import { heroApiService } from '@services/service-register';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadableContainer } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {
  // If location ID is not provided, the watermark is always visible
  locationId?: string | null;
  children: React.ReactElement;
};

const ReviewScreenLayout = ({ children, locationId }: Props) => {
  const { isMobile } = useBreakpoint();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [watermarkVisible, setWatermarkVisible] = useState<boolean>(true);

  const checkWatermarkVisible = async (locationId: string): Promise<boolean> => {
    const reviewSetting = await heroApiService.getLocationReviewSetting(locationId);
    return !!reviewSetting?.watermark_visible;
  };

  useEffect(() => {
    const init = async () => {
      if (locationId) {
        setIsLoading(true);
        const isWatermarkVisible = await checkWatermarkVisible(locationId);
        setWatermarkVisible(isWatermarkVisible);
        setIsLoading(false);
      }
    };
    init();
  }, [locationId]);

  return (
    <div
      style={{
        background: "url('jumbotron/builder-background-blue.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'center',
        padding: isMobile ? '0px' : '50px 0px',
      }}>
      <div
        style={{
          borderRadius: isMobile ? 0 : 20,
          boxShadow: STRIPE_BOX_SHADOW,
          border: `1.5px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          backgroundColor: 'white',
          padding: '35px 30px',
          minHeight: isMobile ? '100%' : 'fit-content',
          height: isMobile ? '100%' : 'fit-content',
          width: isMobile ? '100%' : 400,
          overflow: 'auto',
          ...(isMobile
            ? {}
            : {
                justifyContent: 'center',
                display: 'flex',
              }),
        }}>
        <Space direction="vertical" style={{ ...MIDDLE_STYLE }}>
          <img
            alt="google-logo"
            height={20}
            style={{ marginBottom: 10 }}
            src="social-logo/google_logo_full.png"
          />
          {children}
          <LoadableContainer isLoading={!!locationId && isLoading} loadComponent={<></>}>
            {watermarkVisible && (
              <a
                href={BRAND_URL}
                style={{ color: '#1677ff', marginTop: 20 }}
                target="_blank"
                rel="noreferrer">
                Powered by {BRAND_NAME}
              </a>
            )}
          </LoadableContainer>
        </Space>
      </div>
    </div>
  );
};

export default ReviewScreenLayout;
