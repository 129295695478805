import { useMemo } from 'react';
import moment from 'moment';
import { CalendarEventSourceType, CalendarItem, CalendarItemType } from '@utils/social-studio.util';
import { SocialPost } from '@core/models';
import { cloneArray } from '@utils/array.util';

export const useCalendarEvents = (
  socialPosts: SocialPost[],
  {
    day,
    month,
    year,
  }: {
    day?: number;
    month?: number;
    year?: number;
  }
): { events: CalendarItem[] } => {
  const events = useMemo<CalendarItem[]>(() => {
    const socialPostSchedules = socialPosts
      .filter(socialPost => socialPost)
      .map<CalendarItem>(socialPost => {
        const publishAt = socialPost.publish_at as any;
        const publishedDate = moment(publishAt);
        return {
          date: publishAt,
          date_day: publishedDate.date(),
          date_month: publishedDate.month(),
          date_year: publishedDate.year(),
          description: socialPost.content,
          name: `${socialPost.content}`,
          social_post_id: socialPost.id,
          source_type: CalendarEventSourceType.SocialPost,
          type: CalendarItemType.OnceTimeOff,
          extra: socialPost,
        };
      });

    let _events: CalendarItem[] = cloneArray(socialPostSchedules);
    if (month) {
      _events = _events.filter(event => event.date_month === month);
    }
    if (day) {
      _events = _events.filter(event => event.date_day === day);
    }
    if (year) {
      _events = _events.filter(event => event.date_year === year);
    }
    return _events;
  }, [socialPosts, day, month, year]);
  return { events };
};
