import React from 'react';

type ChildComponent = React.ReactElement | React.ReactNode | React.ReactNode[];

type Props = {
  wrappedCondition: boolean;
  wrapper: (children: ChildComponent) => React.ReactElement;
  children: ChildComponent;
};

const WrapOrNotcontainer = ({ wrapper, wrappedCondition, children }: Props) => {
  return !wrappedCondition ? <React.Fragment>{children}</React.Fragment> : wrapper(children);
};

export default WrapOrNotcontainer;
