import { EmbeddedWallOfLoveSetting, ReviewSetting } from '@core/models';
import axios from 'axios';

export default class ReviewApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/reviews`;

  public async getLocationReviewSetting(): Promise<ReviewSetting | null> {
    try {
      const response = await axios.get(`${this.url}/setting`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateEmbeddedWallOfLoveSetting(
    embeddedSetting: Partial<EmbeddedWallOfLoveSetting>
  ): Promise<void> {
    try {
      const response = await axios.put(
        `${this.url}/wall-of-love`,
        {
          ...embeddedSetting,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async toggleWatermarkVisibility(): Promise<void> {
    try {
      const response = await axios.put(
        `${this.url}/watermark`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
