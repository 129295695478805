import { BlogPostModel, BlogPostStage } from '@core/models';
import { getMonthKey, groupObjectArrayByMonth } from '@utils/date.util';
import { getUpcomingXMonths } from '@utils/string.util';
import React, { useMemo } from 'react';
import { BlogPostMonthListContainer } from '..';
import moment from 'moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';

type Props = {
  blogPosts: BlogPostModel[];
  stage: BlogPostStage | string;
  selectedMonth?: string;
};

const BlogPostListView = ({ blogPosts, stage, selectedMonth }: Props) => {
  const blogPostsGroupedByMonth = useMemo(() => {
    const result: Record<string, BlogPostModel[]> = {};
    for (const monthUnix of getUpcomingXMonths(6)) {
      const key = getMonthKey(monthUnix);
      result[key] = [];
    }
    return {
      ...(!selectedMonth
        ? result
        : {
            [selectedMonth]: [],
          }),
      ...groupObjectArrayByMonth<BlogPostModel>(
        blogPosts,
        stage !== BlogPostStage.Draft ? 'publish_at' : 'created_date'
      ),
    };
  }, [blogPosts, selectedMonth, stage]);
  return (
    <React.Fragment>
      {Object.keys(blogPostsGroupedByMonth)
        .sort(
          (monthKeyA, monthKeyB) =>
            (moment(monthKeyA, MONTH_KEY_DATE_FORMAT).unix() -
              moment(monthKeyB, MONTH_KEY_DATE_FORMAT).unix()) *
            (stage === 'ALL' ? -1 : 1)
        )
        .map(month => (
          <BlogPostMonthListContainer month={month} blogPosts={blogPostsGroupedByMonth[month]} />
        ))}
    </React.Fragment>
  );
};

export default BlogPostListView;
