/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthState } from '@core/.';
import { AuthState } from '@core/features/auth/type';
import SplashScreen from '@components/SplashScreen';

type Props = {
  children: React.ReactElement;
};

const ProtectedRoute = ({ children }: Props) => {
  const history = useHistory();
  const { isAuthLoading, authState } = useAuthState();

  useEffect(() => {
    if (!isAuthLoading && authState === AuthState.Unauthenticated) {
      history.push('/');
    }
  }, [authState, isAuthLoading]);

  return isAuthLoading || authState === AuthState.Authenticating ? <SplashScreen /> : children;
};

export default ProtectedRoute;
