import React from 'react';
import { Button } from 'antd';
import { TbLicense } from 'react-icons/tb';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { openModal } from '@core/features';

const ManageLicenseButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();

  const handleManageLicense = () => {
    dispatch(
      openModal({
        modalName: 'manageLicenseModal',
      })
    );
  };

  return (
    <Button onClick={handleManageLicense} style={{ width: '100%' }}>
      <TbLicense /> Manage license ({user?.no_licenses})
    </Button>
  );
};

export default ManageLicenseButton;
