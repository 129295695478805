import React, { useMemo } from 'react';
import {
  ConnectFacebookButton,
  ConnectSocialAccountsLocation,
  LoadableContainer,
  SocialIconButton,
} from '@components/index';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import { Checkbox, Divider, Space } from 'antd';
import { useAuthState, useBusinessState } from '@core/configureStore';
import { FacebookPage, InstagramProfile, SocialPlatformStr } from '@core/models';
import { MIDDLE_STYLE } from '@constants/responsive';

const DEFAULT_INTEGRATIONS = {
  facebook_pages: [],
  instagram_profiles: [],
};

interface Props {
  selectedAccounts: Record<string, boolean>;
  setSelectedAccounts: (selectedAccounts: Record<string, boolean>) => void;
  disabledPlatforms?: SocialPlatformStr[];
  defaultIntegrations?: Partial<{
    facebook_pages: FacebookPage[];
    instagram_profiles: InstagramProfile[];
  }>;
}

const InstagramProfileButtonList: React.FC<{
  instagramProfiles: InstagramProfile[];
  selectedAccounts: Record<string, boolean>;
  setSelectedAccounts: (selectedAccounts: Record<string, boolean>) => void;
}> = ({ instagramProfiles, setSelectedAccounts, selectedAccounts }) => {
  return (
    <Space
      direction={'vertical'}
      style={{ padding: '10px 15px', maxHeight: 600, overflowY: 'auto' }}>
      {instagramProfiles.map((profile, index) => (
        <React.Fragment>
          <Space
            size={'large'}
            style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '100%' }}>
            <Space style={{ ...MIDDLE_STYLE }}>
              <img
                width={30}
                height={30}
                alt={profile.name}
                style={{ borderRadius: 50 }}
                src={profile.profile_picture_url}
              />
              <p style={{ margin: 0 }}>{profile.name}</p>
            </Space>
            <Checkbox
              checked={selectedAccounts[profile.id]}
              onChange={e => {
                setSelectedAccounts({
                  ...selectedAccounts,
                  [profile.id]: e.target.checked,
                });
              }}
            />
          </Space>
          {index !== instagramProfiles.length - 1 && <Divider style={{ margin: '0px 0px' }} />}
        </React.Fragment>
      ))}
    </Space>
  );
};

const FacebookPageProfileButtonList: React.FC<{
  facebookPages: FacebookPage[];
  selectedAccounts: Record<string, boolean>;
  setSelectedAccounts: (selectedAccounts: Record<string, boolean>) => void;
}> = ({ facebookPages, setSelectedAccounts, selectedAccounts }) => {
  return (
    <Space
      direction={'vertical'}
      style={{ padding: '10px 10px', maxHeight: 600, overflowY: 'auto' }}>
      {facebookPages.map((account, index) => (
        <React.Fragment>
          <Space
            size={'large'}
            style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '100%' }}>
            <Space style={{ ...MIDDLE_STYLE }}>
              <img
                width={30}
                height={30}
                alt={account.name}
                style={{ borderRadius: 50 }}
                src={`data:image/jpeg;base64,${account.picture}`}
              />
              <p style={{ margin: 0 }}>{account.name}</p>
            </Space>
            <Checkbox
              checked={selectedAccounts[account.id]}
              onChange={e => {
                setSelectedAccounts({
                  ...selectedAccounts,
                  [account.id]: e.target.checked,
                });
              }}
            />
          </Space>
          {index !== facebookPages.length - 1 && <Divider style={{ margin: '0px 0px' }} />}
        </React.Fragment>
      ))}
    </Space>
  );
};

const SocialAccountButtonListContainer: React.FC<Props> = ({
  setSelectedAccounts,
  selectedAccounts,
  disabledPlatforms = [],
  defaultIntegrations = DEFAULT_INTEGRATIONS,
}) => {
  const {
    integration: { facebook },
  } = useAuthState();
  const { integration } = useBusinessState();

  function arrayEmptyLeftOrRight<T>(left: T[], right: T[]): T[] {
    return left.length === 0 ? right : left;
  }

  const integrationFacebookPages = useMemo(
    () =>
      arrayEmptyLeftOrRight(defaultIntegrations.facebook_pages || [], integration.facebook_pages),
    [defaultIntegrations.facebook_pages, integration.facebook_pages]
  );

  const integrationInstagramProfiles = useMemo(
    () =>
      arrayEmptyLeftOrRight(
        defaultIntegrations.instagram_profiles || [],
        integration.instagram_profiles
      ),
    [defaultIntegrations.instagram_profiles, integration.instagram_profiles]
  );

  return (
    <LoadableContainer
      isLoading={!integrationFacebookPages || !integrationInstagramProfiles}
      loadComponent={<SkeletonAvatar active />}>
      <Space>
        {integrationFacebookPages.length > 0 || integrationInstagramProfiles.length > 0 ? (
          <React.Fragment>
            {!disabledPlatforms.includes('facebook') && (
              <SocialIconButton
                tooltipContent={
                  <FacebookPageProfileButtonList
                    facebookPages={integrationFacebookPages}
                    selectedAccounts={selectedAccounts}
                    setSelectedAccounts={setSelectedAccounts}
                  />
                }
                platform="facebook"
                connectedAccounts={
                  integrationFacebookPages.filter(
                    facebook_page => selectedAccounts[facebook_page.id]
                  ).length
                }
              />
            )}
            {!disabledPlatforms.includes('instagram') && (
              <SocialIconButton
                tooltipContent={
                  <InstagramProfileButtonList
                    instagramProfiles={integrationInstagramProfiles}
                    selectedAccounts={selectedAccounts}
                    setSelectedAccounts={setSelectedAccounts}
                  />
                }
                platform="instagram"
                connectedAccounts={
                  integrationInstagramProfiles.filter(
                    instagram_profile => selectedAccounts[instagram_profile.id]
                  ).length
                }
              />
            )}
          </React.Fragment>
        ) : facebook ? (
          <ConnectSocialAccountsLocation />
        ) : (
          <ConnectFacebookButton />
        )}
      </Space>
    </LoadableContainer>
  );
};

export default SocialAccountButtonListContainer;
