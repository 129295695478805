import React, { useEffect, useState } from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { Button, Col, Divider, Input, InputNumber, Row, Skeleton, Space, TimePicker } from 'antd';
import { useParams } from 'react-router';
import { LocalLocation } from '@core/models';
import { heroApiService, reservationApiService } from '@services/service-register';
import { CalendarModal, LoadableContainer } from '@components/index';
import {
  ArrowRightOutlined,
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import * as dayjs from 'dayjs';
import { BRAND_NAME, BRAND_URL } from '@constants/brand';
import { useBreakpoint } from '@core/hooks';
import { useAppDispatch } from '@core/configureStore';
import { enqueueNotification, openModal } from '@core/features';
import { useFormik } from 'formik';
import * as yup from 'yup';

type ReservationPayload = {
  name: string;
  emailContact: string;
  phone: string;
  numberOfPeople: number;
  date: number;
  time: {
    hour: number;
    minute: number;
  };
};

const validationSchema = {
  name: yup.string().max(20, 'Invalid name length').required('Please provide your name'),
  emailContact: yup.string().email('Enter a valid email'),
  phone: yup.string().max(13, 'Invalid phone length'),
};

const BookingAppScreen: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { isMobile } = useBreakpoint();
  const [inputError, setInputError] = useState<Record<keyof ReservationPayload, string>>({
    date: '',
    emailContact: '',
    name: '',
    numberOfPeople: '',
    time: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [mutating, setMutating] = useState(false);
  const [location, setLocation] = useState<LocalLocation | undefined>(undefined);
  const today = moment();
  const formik = useFormik<ReservationPayload>({
    initialValues: {
      name: '',
      emailContact: '',
      phone: '',
      numberOfPeople: 1,
      date: moment().unix(),
      time: {
        hour: today.hour(),
        minute: today.minute(),
      },
    },
    onSubmit: async values => {
      try {
        setMutating(true);
        await reservationApiService.createNewReservation(locationId, {
          numberOfPeople: values.numberOfPeople,
          contact: values.emailContact,
          fullName: values.name,
          phone: values.phone,
          startDate: values.date,
        });
        dispatch(
          enqueueNotification({
            name: 'Your reservation is successfully recorded',
            description: 'Your reservation is successfully recorded',
            type: 'Success',
          })
        );
      } catch (e) {
        dispatch(
          enqueueNotification({
            name: 'Failed to add reservation',
            description: 'Failed to add reservation',
            type: 'Error',
          })
        );
      }
      setMutating(false);
    },
  });

  const handleSelectDate = () => {
    dispatch(
      openModal({
        modalName: 'calendarModal',
      })
    );
  };

  const handleInputChange = async (fieldName: keyof ReservationPayload, value: any) => {
    await formik.setFieldValue(fieldName, value);
  };

  useEffect(() => {
    const handleInputError = async () => {
      for (const fieldName of Object.keys(validationSchema)) {
        let updatedInputError = inputError;
        try {
          const value = (formik.values as any)[fieldName];
          await (validationSchema as any)[fieldName].validate(value);
          // @ts-ignore
          updatedInputError[fieldName] = '';
        } catch (error: any) {
          // @ts-ignore
          updatedInputError[fieldName] = error.message;
        }
        setInputError(updatedInputError);
      }
    };
    handleInputError();
  }, [formik.values]);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const _location = await heroApiService.getLocationById(locationId);
        setLocation(_location);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, [locationId]);

  return (
    <div
      style={{
        ...MIDDLE_STYLE,
        flexDirection: 'column',
        backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
        minHeight: '100vh',
      }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px 30px',
          borderRadius: 10,
          maxWidth: 600,
          height: isMobile ? '100vh' : 'auto',
          paddingBottom: isMobile ? 50 : 20,
          overflowY: 'scroll',
          width: '100%',
          border: `2px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          boxShadow: STRIPE_BOX_SHADOW,
        }}>
        <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <h2>Booking Portal</h2>
            {!isMobile && (
              <div
                style={{
                  width: 1,
                  height: 50,
                  backgroundColor: GLOBAL_THEME_COLOR.$border_color,
                }}></div>
            )}
            <div>
              <p style={{ fontSize: 'larger' }}>{location?.title}</p>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>{location?.full_address}</p>
            </div>
          </div>
        </LoadableContainer>
        <Divider />
        <Space size={15} direction={'vertical'} style={{ width: '100%' }}>
          <div>
            <div style={{ marginTop: 10 }}>
              <label>Name</label>
              <Input
                required
                id={'name'}
                name={'name'}
                status={!!inputError.name ? 'error' : ''}
                value={formik.values.name}
                onChange={e => handleInputChange('name', e.target.value)}
                style={{ width: '100%', marginTop: 10 }}
                placeholder={'Enter your name'}
                prefix={<UserOutlined />}
              />
              {!!inputError.name && (
                <p style={{ marginTop: 10, color: GLOBAL_THEME_COLOR.$error_color }}>
                  {inputError.name}
                </p>
              )}
            </div>
            <div style={{ marginTop: 10 }}>
              <label>Number of people</label>
              <InputNumber
                id={'numberOfPeople'}
                name={'numberOfPeople'}
                required
                min={1}
                max={100}
                value={formik.values.numberOfPeople}
                status={!!inputError.phone ? 'error' : ''}
                style={{ width: '100%', height: 50, ...MIDDLE_STYLE, marginTop: 10 }}
                size={'large'}
                onChange={value => handleInputChange('numberOfPeople', value || 1)}
                placeholder={'Enter number of people'}
                prefix={<UsergroupAddOutlined />}
              />
              {!!inputError.phone && (
                <p style={{ marginTop: 10, color: GLOBAL_THEME_COLOR.$error_color }}>
                  {inputError.phone}
                </p>
              )}
            </div>
          </div>
          <Row gutter={15}>
            <Col span={isMobile ? 24 : 16}>
              <label>Date</label>
              <div style={{ marginTop: 10 }}>
                <Button onClick={handleSelectDate} style={{ width: '100%' }}>
                  <React.Fragment>
                    <CalendarOutlined /> {moment.unix(formik.values.date).format('DD MMMM YYYY')}
                  </React.Fragment>
                </Button>
              </div>
            </Col>
            <Col span={isMobile ? 24 : 8} style={{ marginTop: isMobile ? 10 : 0 }}>
              <label>Time</label>
              <div style={{ marginTop: 10 }}>
                <TimePicker
                  use12Hours
                  size={'large'}
                  style={{ width: '100%' }}
                  format="h:mm a"
                  value={dayjs.unix(moment(formik.values.time).unix())}
                  showNow={false}
                  showSecond={false}
                  onChange={async value => {
                    if (value)
                      await handleInputChange('phone', {
                        hour: value.hour(),
                        minute: value.minute(),
                      });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Divider>
            <span
              style={{
                backgroundColor: GLOBAL_THEME_COLOR.$background_color,
                fontSize: 'smaller',
              }}>
              Leave your personal information
            </span>
          </Divider>
          <Space style={{ display: isMobile ? 'block' : 'flex', width: '100%' }}>
            <div style={{ marginTop: 10 }}>
              <label>Email contact</label>
              <Input
                value={formik.values.emailContact}
                type={'email'}
                status={!!inputError.emailContact ? 'error' : ''}
                onChange={e => handleInputChange('emailContact', e.target.value)}
                style={{ width: '100%', marginTop: 10 }}
                placeholder={'Enter your email address'}
                prefix={<MailOutlined />}
              />
              {!!inputError.emailContact && (
                <p style={{ marginTop: 10, color: GLOBAL_THEME_COLOR.$error_color }}>
                  {inputError.emailContact}
                </p>
              )}
            </div>
            <div style={{ marginTop: 10 }}>
              <label>Phone</label>
              <Input
                type={'phone'}
                value={formik.values.phone}
                onChange={e => handleInputChange('phone', e.target.value)}
                style={{ width: '100%', marginTop: 10 }}
                placeholder={'Enter your phone number'}
                prefix={<PhoneOutlined />}
              />
            </div>
          </Space>
          <Button
            type={'primary'}
            size={'large'}
            loading={mutating}
            disabled={!!inputError['name'] || !!inputError['emailContact']}
            onClick={() => formik.handleSubmit()}
            style={{ width: '100%', ...MIDDLE_STYLE, padding: '20px 0px' }}>
            Book Now <ArrowRightOutlined style={{ marginLeft: 10 }} />
          </Button>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-end' }}>
            <a href={BRAND_URL} style={{ color: '#1677ff' }} target="_blank" rel="noreferrer">
              powered by {BRAND_NAME}
            </a>
          </div>
        </Space>
      </div>
      <CalendarModal onSelected={date => handleInputChange('date', date.unix())} />
    </div>
  );
};

export default BookingAppScreen;
