import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { subscriptionThunkReducers } from './thunks';
import { SubscriptionReducerProps } from './type';

const subscriptionSlice = createSlice<
  SubscriptionReducerProps,
  SliceCaseReducers<SubscriptionReducerProps>,
  string
>({
  name: SliceName.Subscription,
  initialState: {
    isLoading: false,
  },
  reducers: {},
  extraReducers: subscriptionThunkReducers,
});

export const subscriptionReducer = subscriptionSlice.reducer;
