import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Divider, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { CardSectionHeader, HighlightedSubText } from '..';

type Props = {
  locationId: string;
};

const ReviewWidgetEmbeddedScriptSection = ({ locationId }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const getEmbeddedScript = (locationId: string) =>
    `<div class="amazely-widget-${locationId}" />\n<script src="https://widget.amazely.co/index.js" defer></script>`;

  const handleCopy = () => {
    navigator.clipboard.writeText(getEmbeddedScript(locationId));
    setIsCopied(true);
  };

  return (
    <React.Fragment>
      <CardSectionHeader title="Add to your web page" />
      <p>
        Copy and add this script to your web page. You{' '}
        <HighlightedSubText content="only need to copy it once." />
      </p>
      <Divider />
      <React.Fragment>
        <TextArea
          style={{ marginTop: '10px' }}
          contentEditable={false}
          value={getEmbeddedScript(locationId)}
          autoSize={{ minRows: 2 }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
            marginBottom: '10px',
          }}>
          <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
            <Button
              type="link"
              size="small"
              icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
              onClick={handleCopy}>
              {isCopied ? 'Copied' : 'Copy'}
            </Button>
          </Tooltip>
        </div>
        <br />
        <div>
          <i>
            <a
              target={'_blank'}
              href="https://docs.amazely.co/en/articles/8319404-review-widget-showcase-customer-reviews">
              Having issue with the widget? Visit our help page.
            </a>
          </i>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default ReviewWidgetEmbeddedScriptSection;
