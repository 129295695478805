import { ArrowRightOutlined } from '@ant-design/icons';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useJoyrideHelpers } from '@core/hooks';
import { useURLQuery } from '@core/hooks/useUrlQuery';
import { Button } from 'antd';
import React, { useEffect, useMemo } from 'react';
import Joyride from 'react-joyride';

const BlogPostsScreenTourRegister = () => {
  const {
    guideTourRunning,
    guideTourStepIndex,
    handleJoyrideCallback,
    setGuideTourRunning,
    setGuideTourStepIndex,
  } = useJoyrideHelpers();
  const query = useURLQuery();
  const guideMode = useMemo(() => query.get('guide'), [query]);

  useEffect(() => {
    if (guideMode === 'generate') {
      setGuideTourRunning(true);
      setGuideTourStepIndex(0);
    }
  }, [guideMode]);

  return (
    <Joyride
      continuous
      disableCloseOnEsc
      disableScrollParentFix
      run={guideTourRunning}
      callback={handleJoyrideCallback}
      stepIndex={guideTourStepIndex}
      hideCloseButton
      disableOverlay={!guideTourRunning}
      steps={[
        {
          content: (
            <div>
              <h3>Personalize your brand voice with AI</h3>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                Provide information about your business to personalize post content.
              </p>
              <Button
                type={'primary'}
                style={{ width: '100%', marginTop: 20 }}
                onClick={() => {
                  const generatePostsButton = document.getElementById('generate-blog-posts-button');
                  if (generatePostsButton) generatePostsButton.scrollIntoView();
                  setGuideTourStepIndex(step => step + 1);
                }}>
                Next <ArrowRightOutlined style={{ marginLeft: 10 }} />
              </Button>
            </div>
          ),
          hideFooter: true,
          target: '#customize-blog-ai-profile',
        },
        {
          content: (
            <div>
              <h3>Generate blog posts for a month</h3>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                To craft multiple blog posts for a month easily, you can click on the Generate
                button.
              </p>
              <Button
                type={'primary'}
                style={{ width: '100%', marginTop: 20 }}
                onClick={async () => {
                  const generatePostsButton = document.getElementById('generate-blog-posts-button');
                  if (generatePostsButton) generatePostsButton.click();
                  setGuideTourRunning(false);
                  setGuideTourStepIndex(step => step + 1);
                }}>
                Generate blog posts
              </Button>
            </div>
          ),
          disableScrolling: false,
          hideFooter: true,
          target: '#generate-blog-posts-button',
        },
      ]}
      disableScrolling
      styles={{
        overlay: { height: '100%' },
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default BlogPostsScreenTourRegister;
