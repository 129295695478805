import { SubscriptionCreditUsage } from '@core/models';

export const SCHEDULED_SOCIAL_POSTS_THRESHOLD = 1;
export const TOTAL_BLOG_POSTS_THRESHOLD = 1;

export const LICENSE_ACTIVATION_THRESHOLD: SubscriptionCreditUsage = {
  auto_social_post_published: 1,
  manual_social_post_published: 3,
  review_request_created: 4,
  review_request_reviewed: 4,
  review_response_approved: 4,
  manual_review_replied: 3,
  manual_blog_post_published: 2,
  auto_blog_post_published: 0,
};
