export const TIMEZONES = [
  { label: 'AD - Europe/Andorra', value: 'Europe/Andorra' },
  { label: 'AE - Asia/Dubai', value: 'Asia/Dubai' },
  { label: 'AR - America/Argentina/Buenos_Aires', value: 'America/Argentina/Buenos_Aires' },
  { label: 'AT - Europe/Vienna', value: 'Europe/Vienna' },
  { label: 'AU - Australia/Lord_Howe', value: 'Australia/Lord_Howe' },
  { label: 'AU - Antarctica/Macquarie', value: 'Antarctica/Macquarie' },
  { label: 'AU - Australia/Hobart', value: 'Australia/Hobart' },
  { label: 'AU - Australia/Melbourne', value: 'Australia/Melbourne' },
  { label: 'AU - Australia/Sydney', value: 'Australia/Sydney' },
  { label: 'AU - Australia/Broken_Hill', value: 'Australia/Broken_Hill' },
  { label: 'AU - Australia/Brisbane', value: 'Australia/Brisbane' },
  { label: 'AU - Australia/Lindeman', value: 'Australia/Lindeman' },
  { label: 'AU - Australia/Adelaide', value: 'Australia/Adelaide' },
  { label: 'AU - Australia/Darwin', value: 'Australia/Darwin' },
  { label: 'AU - Australia/Perth', value: 'Australia/Perth' },
  { label: 'AU - Australia/Eucla', value: 'Australia/Eucla' },
  { label: 'AW - America/Aruba', value: 'America/Aruba' },
  { label: 'AX - Europe/Mariehamn', value: 'Europe/Mariehamn' },
  { label: 'BE - Europe/Brussels', value: 'Europe/Brussels' },
  { label: 'BG - Europe/Sofia', value: 'Europe/Sofia' },
  { label: 'BH - Asia/Bahrain', value: 'Asia/Bahrain' },
  { label: 'BR - America/Sao_Paulo', value: 'America/Sao_Paulo' },
  { label: 'CA - America/Toronto', value: 'America/Toronto' },
  { label: 'CA - America/Whitehorse', value: 'America/Whitehorse' },
  { label: 'CA - America/Vancouver', value: 'America/Vancouver' },
  { label: 'CH - Europe/Zurich', value: 'Europe/Zurich' },
  { label: 'CL - America/Santiago', value: 'America/Santiago' },
  { label: 'CN - Asia/Shanghai', value: 'Asia/Shanghai' },
  { label: 'CZ - Europe/Prague', value: 'Europe/Prague' },
  { label: 'DE - Europe/Berlin', value: 'Europe/Berlin' },
  { label: 'DE - Europe/Busingen', value: 'Europe/Busingen' },
  { label: 'DK - Europe/Copenhagen', value: 'Europe/Copenhagen' },
  { label: 'EG - Africa/Cairo', value: 'Africa/Cairo' },
  { label: 'ES - Europe/Madrid', value: 'Europe/Madrid' },
  { label: 'FI - Europe/Helsinki', value: 'Europe/Helsinki' },
  { label: 'FJ - Pacific/Fiji', value: 'Pacific/Fiji' },
  { label: 'FR - Europe/Paris', value: 'Europe/Paris' },
  { label: 'GB - Europe/London', value: 'Europe/London' },
  { label: 'GR - Europe/Athens', value: 'Europe/Athens' },
  { label: 'GS - Atlantic/South_Georgia', value: 'Atlantic/South_Georgia' },
  { label: 'GU - Pacific/Guam', value: 'Pacific/Guam' },
  { label: 'HK - Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
  { label: 'HU - Europe/Budapest', value: 'Europe/Budapest' },
  { label: 'ID - Asia/Jakarta', value: 'Asia/Jakarta' },
  { label: 'IE - Europe/Dublin', value: 'Europe/Dublin' },
  { label: 'IL - Asia/Jerusalem', value: 'Asia/Jerusalem' },
  { label: 'IT - Europe/Rome', value: 'Europe/Rome' },
  { label: 'JP - Asia/Tokyo', value: 'Asia/Tokyo' },
  { label: 'KR - Asia/Seoul', value: 'Asia/Seoul' },
  { label: 'LU - Europe/Luxembourg', value: 'Europe/Luxembourg' },
  { label: 'MC - Europe/Monaco', value: 'Europe/Monaco' },
  { label: 'MO - Asia/Macau', value: 'Asia/Macau' },
  { label: 'MV - Indian/Maldives', value: 'Indian/Maldives' },
  { label: 'MX - America/Mexico_City', value: 'America/Mexico_City' },
  { label: 'MY - Asia/Kuala_Lumpur', value: 'Asia/Kuala_Lumpur' },
  { label: 'NL - Europe/Amsterdam', value: 'Europe/Amsterdam' },
  { label: 'NO - Europe/Oslo', value: 'Europe/Oslo' },
  { label: 'NP - Asia/Kathmandu', value: 'Asia/Kathmandu' },
  { label: 'NZ - Pacific/Auckland', value: 'Pacific/Auckland' },
  { label: 'PL - Europe/Warsaw', value: 'Europe/Warsaw' },
  { label: 'PT - Europe/Lisbon', value: 'Europe/Lisbon' },
  { label: 'QA - Asia/Qatar', value: 'Asia/Qatar' },
  { label: 'RO - Europe/Bucharest', value: 'Europe/Bucharest' },
  { label: 'RU - Europe/Moscow', value: 'Europe/Moscow' },
  { label: 'SE - Europe/Stockholm', value: 'Europe/Stockholm' },
  { label: 'SG - Asia/Singapore', value: 'Asia/Singapore' },
  { label: 'TH - Asia/Bangkok', value: 'Asia/Bangkok' },
  { label: 'TR - Europe/Istanbul', value: 'Europe/Istanbul' },
  { label: 'TW - Asia/Taipei', value: 'Asia/Taipei' },
  { label: 'US - America/New_York', value: 'America/New_York' },
  { label: 'US - America/Detroit', value: 'America/Detroit' },
  { label: 'US - America/Kentucky/Louisville', value: 'America/Kentucky/Louisville' },
  { label: 'US - America/Kentucky/Monticello', value: 'America/Kentucky/Monticello' },
  { label: 'US - America/Indiana/Indianapolis', value: 'America/Indiana/Indianapolis' },
  { label: 'US - America/Indiana/Vincennes', value: 'America/Indiana/Vincennes' },
  { label: 'US - America/Indiana/Winamac', value: 'America/Indiana/Winamac' },
  { label: 'US - America/Indiana/Marengo', value: 'America/Indiana/Marengo' },
  { label: 'US - America/Indiana/Petersburg', value: 'America/Indiana/Petersburg' },
  { label: 'US - America/Indiana/Vevay', value: 'America/Indiana/Vevay' },
  { label: 'US - America/Chicago', value: 'America/Chicago' },
  { label: 'US - America/Indiana/Tell_City', value: 'America/Indiana/Tell_City' },
  { label: 'US - America/Indiana/Knox', value: 'America/Indiana/Knox' },
  { label: 'US - America/Menominee', value: 'America/Menominee' },
  { label: 'US - America/North_Dakota/Center', value: 'America/North_Dakota/Center' },
  { label: 'US - America/North_Dakota/New_Salem', value: 'America/North_Dakota/New_Salem' },
  { label: 'US - America/North_Dakota/Beulah', value: 'America/North_Dakota/Beulah' },
  { label: 'US - America/Denver', value: 'America/Denver' },
  { label: 'US - America/Boise', value: 'America/Boise' },
  { label: 'US - America/Phoenix', value: 'America/Phoenix' },
  { label: 'US - America/Los_Angeles', value: 'America/Los_Angeles' },
  { label: 'US - America/Anchorage', value: 'America/Anchorage' },
  { label: 'US - America/Juneau', value: 'America/Juneau' },
  { label: 'US - America/Sitka', value: 'America/Sitka' },
  { label: 'US - America/Metlakatla', value: 'America/Metlakatla' },
  { label: 'US - America/Yakutat', value: 'America/Yakutat' },
  { label: 'US - America/Nome', value: 'America/Nome' },
  { label: 'US - America/Adak', value: 'America/Adak' },
  { label: 'US - Pacific/Honolulu', value: 'Pacific/Honolulu' },
  { label: 'UY - America/Montevideo', value: 'America/Montevideo' },
  { label: 'VA - Europe/Vatican', value: 'Europe/Vatican' },
  { label: 'VN - Asia/Ho_Chi_Minh', value: 'Asia/Ho_Chi_Minh' },
  { label: 'ZA - Africa/Johannesburg', value: 'Africa/Johannesburg' },
];
