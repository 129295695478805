import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { closeModal } from '@core/features';
import { Calendar, Modal } from 'antd';
import * as dayjs from 'dayjs';

type Props = {
  onSelected?: (date: dayjs.Dayjs) => void;
};

const CalendarModal = ({ onSelected }: Props) => {
  const dispatch = useAppDispatch();
  const { calendarModal, extraParams } = useModalState();
  const defaultSelectedDate = useMemo(() => extraParams?.selectedDate, [extraParams]);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs.default());
  const disabledDateBeforeToday = (current: dayjs.Dayjs) =>
    current.isBefore(dayjs.default().subtract(1, 'day'));
  const onCalendarSelected = useMemo<(day: dayjs.Dayjs) => void | undefined>(
    () => extraParams?.onCalendarSelected,
    [extraParams]
  );

  const onPanelChange = (value: dayjs.Dayjs) => {
    setSelectedDate(value);
  };

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'calendarModal',
      })
    );
  };

  const handleOk = () => {
    onSelected && onSelected(selectedDate);
    onCalendarSelected && onCalendarSelected(selectedDate);
    handleModalClose();
  };

  useEffect(() => {
    setSelectedDate(defaultSelectedDate);
  }, [defaultSelectedDate]);

  return (
    <Modal
      title={extraParams?.title}
      open={calendarModal}
      onOk={handleOk}
      onCancel={handleModalClose}
      closable>
      <Calendar
        disabledDate={disabledDateBeforeToday}
        value={selectedDate}
        fullscreen={false}
        onSelect={onPanelChange}
      />
    </Modal>
  );
};

export default CalendarModal;
