import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAuthState, useDrawerState } from '@core/configureStore';
import { closeDrawer } from '@core/features';
import { Button, Divider, Empty, Input, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LoadableContainer, SocialIcon } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { socialStudioApiService } from '@services/service-register';
import BrowsePhotoDrawerLayout from '@components/BrowsePhotoDrawerLayout';

type Props = {
  singleFile?: boolean;
  onLoad: (pics: any[]) => Promise<void>;
};

const BrowsePexelsPhotoDrawer = ({ singleFile, onLoad }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const [searchInput, setSearchInput] = useState<string>('');
  const [pics, setPics] = useState<any[]>([]);
  const [selectedPics, setSelectedPics] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { pexelsPhotoBrowseDrawer } = useDrawerState();

  const handleOnClose = () => {
    dispatch(
      closeDrawer({
        drawerName: 'pexelsPhotoBrowseDrawer',
      })
    );
  };

  const handleUpload = () => {
    onLoad(pics.filter(pic => selectedPics[pic.id]));
    handleOnClose();
  };

  const handleSearchPhotos = async () => {
    setLoading(true);
    try {
      const data = await socialStudioApiService.getPexelsPhotos(searchInput);
      setPics(data?.photos || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setSearchInput(user?.selected_location.primary_category || '');
  }, [user?.selected_location]);

  return (
    <BrowsePhotoDrawerLayout
      title={'Pexels - Stock Photos'}
      handleUpload={handleUpload}
      handleOnClose={handleOnClose}
      open={pexelsPhotoBrowseDrawer}>
      <Input
        prefix={<SearchOutlined />}
        suffix={
          <Button disabled={!searchInput} onClick={handleSearchPhotos} type="primary">
            Search Photos
          </Button>
        }
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        placeholder="Search by keywords. i.e. Restaurants, Gym, Food..."
      />
      <div style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
        <span>
          Powered by <span style={{ fontWeight: 'bold' }}>Pexels</span>{' '}
        </span>
        <SocialIcon platform="pexels" width={15} style={{ marginLeft: 5 }} />
      </div>
      <Divider />
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        <LoadableContainer
          isLoading={pics.length === 0}
          loadComponent={
            <div style={{ ...MIDDLE_STYLE }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No images found</span>}
              />
            </div>
          }>
          <div className="card-list">
            {pics.map(pic => (
              <div
                onClick={() =>
                  setSelectedPics(
                    !singleFile
                      ? {
                          ...selectedPics,
                          [pic.id]: !selectedPics[pic.id],
                        }
                      : {
                          [pic.id]: !selectedPics[pic.id],
                        }
                  )
                }
                className="card"
                key={pic.id}
                style={{
                  borderRadius: 20,
                  padding: selectedPics[pic.id] ? '5px' : '0px',
                  cursor: 'pointer',
                  ...(selectedPics[pic.id]
                    ? { border: `6px solid ${GLOBAL_THEME_COLOR.$highlight_color}` }
                    : {}),
                }}>
                <LazyLoadImage
                  loading="eager"
                  className="card--image"
                  alt={pic.alt}
                  src={pic.src.original}
                  placeholderSrc={'/main-background.webp'}
                  effect="blur"
                  width="100%"
                  height="50%"
                />
              </div>
            ))}
          </div>
        </LoadableContainer>
      </LoadableContainer>
    </BrowsePhotoDrawerLayout>
  );
};

export default BrowsePexelsPhotoDrawer;
