import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Button, Drawer, Modal, Popconfirm, Space, Steps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { SocialPost, SocialPostType, StudioPhotoCollections, StudioTabKey } from '@core/models';
import { socialStudioApiService, socialStudioStreamApiService } from '@services/service-register';
import { fetchSocialPostsSilent } from '@core/features/studio/thunks';
import { enqueueNotification, openModal, SnackbarItem } from '@core/features';
import { useAppDispatch, useAuthState, useStudioState } from '@core/configureStore';
import { DeleteOutlined, LoadingOutlined, WarningFilled } from '@ant-design/icons';
import {
  useAppNavigation,
  useBreakpoint,
  useSocialPostDispatcher,
  useStreamApi,
  useStudioTourStore,
} from '@core/hooks';
import moment from 'moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import confirm from 'antd/es/modal/confirm';
import SmallSocialPostCard from '@components/SocialPostCard/small';
import { formatUtcTimestamp } from '@utils/date.util';
import { MonthlyGenerateParams } from '@services/stream-api.service';
import { AppRoutes } from '@utils/route.util';

interface Props {
  month: string;
  socialPosts: SocialPost[];
  children: React.FC<{
    socialPosts: SocialPost[];
    allChecked: boolean;
    setAllChecked: (allChecked: boolean) => void;
    setSelectedSocialPost: any;
    selectedSocialPost: Record<string, boolean>;
    containerLoading: boolean;
  }>;
}

enum GenerationEvent {
  Idle = '0',
  Prechecking = 'Prechecking',
  GenerateSocialPosts = 'GenerateSocialPosts',
  GenerateShowcaseReviews = 'GenerateShowcaseReviews',
  Finished = 'Finished',
}

type StreamGenerateEvent<T = any> = {
  startEventType: GenerationEvent;
  endEventType: GenerationEvent;
  actionMethod: any;
  extraParams?: T;
};

const SocialPostMonthContainerLayout: React.FC<Props> = ({ socialPosts, month, children }) => {
  const { isTablet } = useBreakpoint();
  const { setSelectedTabKey } = useStudioTourStore();
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { preCheckedGenerate, isSystemMadeSocialPost, isSystemMadeShowcaseReviews } =
    useSocialPostDispatcher();
  const { executeStreamApi } = useStreamApi();
  const { user } = useAuthState();
  const { studioPhotos } = useStudioState();
  const [currentSocialPosts, setCurrentSocialPosts] = useState<SocialPost[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedSocialPost, setSelectedSocialPost] = useState<Record<string, boolean>>({});
  const [containerLoading, setContainerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generationEvent, setGenerationEvent] = useState<GenerationEvent>(GenerationEvent.Idle);
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);
  const checkedSocialPosts = useMemo(
    () => Object.keys(selectedSocialPost).filter(socialPostId => selectedSocialPost[socialPostId]),
    [selectedSocialPost]
  );
  const monthInThePast = useMemo(
    () => moment(month, MONTH_KEY_DATE_FORMAT).isBefore(moment().subtract(1, 'month')),
    [month]
  );

  const handleResetSelectedSocialPosts = () => {
    setSelectedSocialPost({});
  };

  const handlePreviewGenerateShowcaseReviews = () => {
    handlePreviewSchedule(
      (_schedules: number[]) => handleGenerateMonthShowcaseReviews(),
      SocialPostType.ShowcaseReview
    );
  };

  const handlePreviewGenerateSocialPosts = () => {
    handlePreviewPhotoPicker((studioPhotoCollections: StudioPhotoCollections) => {
      handleGenerateMonthPosts(studioPhotoCollections);
    });
  };

  const handleBatchRemoveSocialPosts = async (socialPostIds: string[]) => {
    try {
      setLoading(true);
      const promises: Promise<any>[] = [];
      for (const socialPostId of socialPostIds) {
        promises.push(socialStudioApiService.removeSocialPost(parseInt(socialPostId)));
      }
      await Promise.all(promises);
      await dispatch(fetchSocialPostsSilent());
      handleResetSelectedSocialPosts();

      // update current state with the filtered remaining social posts
      const remainingSocialPosts: SocialPost[] = [];
      for (const socialPost of socialPosts) {
        if (!socialPostIds.some(id => id === socialPost.id.toString()))
          remainingSocialPosts.push(socialPost);
      }
      setCurrentSocialPosts(remainingSocialPosts);
      setAllChecked(false);
      setLoading(false);
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to remove social posts',
          description: 'Failed to remove social posts',
          type: 'Error',
        })
      );
    }
  };

  const handlePreCheckedShowcaseReviews = async () => {
    if (!locationId) return;
    setGenerationEvent(GenerationEvent.Prechecking);
    const systemMadeShowcaseReviews = currentSocialPosts.filter(isSystemMadeShowcaseReviews);
    const renderSystemMadeShowcaseReviews = systemMadeShowcaseReviews.map(showcaseReviewPost => (
      <SmallSocialPostCard item={showcaseReviewPost} />
    ));
    const { isGoodReviewSufficient, hasSystemMadeShowcaseReviews } =
      await preCheckedGenerate(currentSocialPosts);
    if (hasSystemMadeShowcaseReviews) {
      Modal.warning({
        title: 'Showcased review post are generated',
        content: (
          <div>
            There are showcased review posts generated for {month}. Please remove these below posts
            first for regeneration.
            <div style={{ marginTop: 20 }}>{renderSystemMadeShowcaseReviews}</div>
          </div>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {
          setGenerationEvent(GenerationEvent.Idle);
        },
        okText: `Remove ${systemMadeShowcaseReviews.length} showcased review posts`,
        onOk: async () => {
          Modal.destroyAll();
          const systemMadeShowcaseReviewPostIds = systemMadeShowcaseReviews.map(
            showcasedReviewPost => showcasedReviewPost.id.toString()
          );
          await handleBatchRemoveSocialPosts(systemMadeShowcaseReviewPostIds);
          setGenerationEvent(GenerationEvent.Idle);
        },
      });
      return;
    }

    if (!isGoodReviewSufficient) {
      confirm({
        icon: <div style={{ fontSize: 40 }}>🌇🌃🌁</div>,
        content: (
          <Space direction={'vertical'}>
            {!isGoodReviewSufficient && (
              <React.Fragment>
                <h4>No good reviews to showcase</h4>
                <div>
                  To showcase reviews on your social pages, please collect more good reviews.
                </div>
              </React.Fragment>
            )}
          </Space>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {
          setGenerationEvent(GenerationEvent.Idle);
        },
        okText: studioPhotos.length > 0 ? 'Generate anyway' : 'Ok',
        onOk: async () => {
          Modal.destroyAll();
          handlePreviewGenerateShowcaseReviews();
        },
      });
      return;
    }
    handlePreviewGenerateShowcaseReviews();
  };

  const handlePreCheckedGeneratePosts = async () => {
    if (!locationId) return;
    setGenerationEvent(GenerationEvent.Prechecking);
    const systemMadeSocialPosts = currentSocialPosts.filter(isSystemMadeSocialPost);
    const renderedSystemMadePosts = systemMadeSocialPosts.map(socialPost => (
      <SmallSocialPostCard item={socialPost} />
    ));
    const { isPhotoSufficient, hasSystemMadeSocialPosts, hasNoStudioPhotos } =
      await preCheckedGenerate(currentSocialPosts);

    if (hasSystemMadeSocialPosts) {
      Modal.warning({
        title: 'Social posts are generated',
        content: (
          <div>
            There are social posts generated for {month}. Please remove these below social posts
            first for regeneration.
            <div style={{ marginTop: 20 }}>{renderedSystemMadePosts}</div>
          </div>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {
          setGenerationEvent(GenerationEvent.Idle);
        },
        okText: `Remove ${systemMadeSocialPosts.length} social posts`,
        onOk: async () => {
          Modal.destroyAll();
          const systemMadeSocialPostIds = systemMadeSocialPosts.map(socialPost =>
            socialPost.id.toString()
          );
          await handleBatchRemoveSocialPosts(systemMadeSocialPostIds);
          setGenerationEvent(GenerationEvent.Idle);
        },
      });
      return;
    }

    if (hasNoStudioPhotos) {
      confirm({
        icon: (
          <WarningFilled
            style={{ color: GLOBAL_THEME_COLOR.$processing_color, fontSize: 22, marginBottom: 20 }}
          />
        ),
        content: (
          <Space direction={'vertical'}>
            <React.Fragment>
              {!isPhotoSufficient && (
                <React.Fragment>
                  <h4>No photos in gallery</h4>
                  <div>
                    You have not uploaded any photos to your gallery yet. Please upload more photos
                    to generate social posts.
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Space>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {
          setGenerationEvent(GenerationEvent.Idle);
        },
        okText: 'Upload more photos',
        onOk: async () => {
          Modal.destroyAll();
          navigate<AppRoutes>('studio');
          setGenerationEvent(GenerationEvent.Idle);
          setSelectedTabKey(StudioTabKey.ImageGallery);
        },
      });
      return;
    } else if (!isPhotoSufficient) {
      confirm({
        icon: <div style={{ fontSize: 40 }}>🌇🌃🌁</div>,
        content: (
          <Space direction={'vertical'}>
            <React.Fragment>
              {!isPhotoSufficient && (
                <React.Fragment>
                  <h4>Low number of photos</h4>
                  <div>
                    You have a low number of photos in your gallery. For more diverse content, we
                    encourage that you add more photos to the gallery
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Space>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {
          setGenerationEvent(GenerationEvent.Idle);
        },
        okText: 'Generate anyway',
        onOk: async () => {
          Modal.destroyAll();
          handlePreviewGenerateSocialPosts();
        },
      });
      return;
    }
    handlePreviewGenerateSocialPosts();
  };

  async function executeGenerateEvents<T = any>(events: StreamGenerateEvent<T>[]) {
    const currentMonth = moment(month, MONTH_KEY_DATE_FORMAT);
    let _streamedSocialPosts: SocialPost[] = currentSocialPosts;
    for (const event of events) {
      setGenerationEvent(event.startEventType);
      await executeStreamApi<SocialPost, MonthlyGenerateParams>({
        apiMethod: event.actionMethod,
        payload: {
          startDate: currentMonth.startOf('month').unix(),
          endDate: currentMonth.endOf('month').unix(),
          ...event.extraParams,
        },
        // eslint-disable-next-line no-loop-func
        onDecodedData: async data => {
          _streamedSocialPosts = _streamedSocialPosts.concat([data]);
          setTimeout(() => {
            setCurrentSocialPosts(_streamedSocialPosts);
          }, 300);
        },
        onFinishedCallback: async () => {
          setGenerationEvent(event.endEventType);
          dispatch(
            enqueueNotification({
              name: `Successfully generate posts`,
              description: 'Successfully generate posts',
              type: 'Success',
            } as SnackbarItem)
          );
        },
      });
    }
  }

  const handleGenerateMonthShowcaseReviews = async () => {
    setContainerLoading(true);
    const events: StreamGenerateEvent[] = [
      {
        startEventType: GenerationEvent.GenerateShowcaseReviews,
        endEventType: GenerationEvent.Finished,
        actionMethod: socialStudioStreamApiService.streamGenerateShowcaseReviews,
      },
    ];
    await executeGenerateEvents(events);
    await dispatch(fetchSocialPostsSilent());
    setContainerLoading(false);
  };

  const handleGenerateMonthPosts = async (studioPhotoCollections: StudioPhotoCollections) => {
    setContainerLoading(true);
    const events: StreamGenerateEvent<{
      studioPhotoCollections: StudioPhotoCollections;
    }>[] = [
      {
        startEventType: GenerationEvent.GenerateSocialPosts,
        endEventType: GenerationEvent.Finished,
        actionMethod: socialStudioStreamApiService.streamGenerateSocialPosts,
        extraParams: {
          studioPhotoCollections,
        },
      },
    ];
    await executeGenerateEvents(events);
    await dispatch(fetchSocialPostsSilent());
    setContainerLoading(false);
  };

  const handlePreviewSchedule = (
    onGenerate: (schedules: number[]) => any,
    postType: SocialPostType
  ) => {
    dispatch(
      openModal({
        modalName: 'previewGenerateScheduleModal',
        extraParams: {
          onGenerate,
          month,
          postType,
        },
      })
    );
    setGenerationEvent(GenerationEvent.Idle);
  };

  const handlePreviewPhotoPicker = (
    onPhotoCollectionsSelected: (studioPhotos: StudioPhotoCollections) => any
  ) => {
    dispatch(
      openModal({
        modalName: 'previewPhotoPickerModal',
        extraParams: {
          month,
          onPhotoCollectionsSelected,
        },
      })
    );
    setGenerationEvent(GenerationEvent.Idle);
  };

  useEffect(() => {
    const checkedSocialPosts: Record<string, boolean> = {};
    for (const socialPost of currentSocialPosts) {
      checkedSocialPosts[socialPost.id] = allChecked;
    }
    setSelectedSocialPost(checkedSocialPosts);
  }, [currentSocialPosts, allChecked]);

  useEffect(() => {
    setCurrentSocialPosts(socialPosts);
  }, [socialPosts]);

  useEffect(() => {
    if (generationEvent === GenerationEvent.Finished)
      setTimeout(() => {
        setGenerationEvent(GenerationEvent.Idle);
      }, 3000);
  }, [generationEvent]);

  return (
    <div
      id={`social-month-container`}
      style={{
        margin: '30px 0px',
        backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
        ...(isTablet
          ? {
              borderTop: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            }
          : {
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            }),
        position: 'relative',
        padding: '10px 20px',
        borderRadius: 10,
        minHeight: '200px',
      }}>
      <Drawer
        open={generationEvent !== GenerationEvent.Idle}
        getContainer={false}
        closable={false}
        mask={false}
        width={isTablet ? '100%' : '30%'}
        style={{
          height: '100%',
        }}>
        <div id={'drawer-step-container'}>
          <Steps
            size={'small'}
            direction="vertical"
            current={
              (
                {
                  [GenerationEvent.GenerateSocialPosts]: 0,
                  [GenerationEvent.GenerateShowcaseReviews]: 0,
                  [GenerationEvent.Finished]: 1,
                } as any
              )[generationEvent as any]
            }
            items={
              generationEvent === GenerationEvent.GenerateSocialPosts
                ? [
                    {
                      title: 'Generate social posts...',
                      description: 'Craft social posts from gallery images',
                    },
                    {
                      title: 'Finished',
                      description: 'Successfully generating social posts',
                    },
                  ]
                : [
                    {
                      title: 'Generate showcase reviews...',
                      description: 'Craft showcase review posts from existing reviews',
                    },
                    {
                      title: 'Finished',
                      description: 'Successfully generating social posts',
                    },
                  ]
            }
          />
        </div>
      </Drawer>
      <div
        id={`social-month-container-${month}`}
        style={{
          ...MIDDLE_STYLE,
          width: '100%',
          flexDirection: isTablet ? 'column' : 'row',
          justifyContent: 'space-between',
        }}>
        <div style={{ marginBottom: 20, ...MIDDLE_STYLE }}>
          {generationEvent !== GenerationEvent.Idle && (
            <LoadingOutlined style={{ marginRight: 10 }} />
          )}
          <Space>
            {month
              .split(' ')
              .map((value, index) =>
                index === 0 ? <h3 style={{ fontSize: 18 }}>{value}</h3> : <div>/ {value}</div>
              )}
          </Space>
          {!isTablet && (
            <span
              style={{
                marginLeft: 10,
                fontWeight: 'normal',
                color: GLOBAL_THEME_COLOR.$dark_text_color,
              }}>
              ({currentSocialPosts.length} social posts)
            </span>
          )}
        </div>
        <Space
          style={{ width: isTablet ? '100%' : 'fit-content' }}
          direction={isTablet ? 'vertical' : 'horizontal'}>
          {checkedSocialPosts.length > 0 && (
            <div style={{ ...MIDDLE_STYLE }}>
              <Popconfirm
                onConfirm={() => handleBatchRemoveSocialPosts(checkedSocialPosts)}
                title="Delete selected posts"
                description="Are you sure to delete these posts?"
                okButtonProps={{
                  size: 'middle',
                }}
                cancelButtonProps={{
                  size: 'middle',
                }}
                okText="Yes"
                cancelText="No">
                <Button
                  id={'remove-posts-button'}
                  style={{ width: isTablet ? '100%' : 'fit-content' }}
                  danger
                  loading={loading}>
                  <DeleteOutlined /> Remove {checkedSocialPosts.length} selected posts
                </Button>
              </Popconfirm>
            </div>
          )}
          {!monthInThePast && (
            <React.Fragment>
              <Button
                id={'showcase-review-button'}
                loading={generationEvent !== GenerationEvent.Idle}
                style={{ width: isTablet ? '100%' : 'fit-content' }}
                onClick={handlePreCheckedShowcaseReviews}>
                🌟 Showcase Reviews
              </Button>
              <Button
                id={'generate-posts-button'}
                loading={generationEvent !== GenerationEvent.Idle}
                type={'primary'}
                style={{ width: isTablet ? '100%' : 'fit-content' }}
                onClick={handlePreCheckedGeneratePosts}>
                ✨ Generate Photo Posts
              </Button>
            </React.Fragment>
          )}
        </Space>
      </div>
      {children({
        socialPosts: currentSocialPosts.sort(
          (postA, postB) =>
            formatUtcTimestamp(postA.publish_at) - formatUtcTimestamp(postB.publish_at)
        ),
        allChecked,
        selectedSocialPost,
        setSelectedSocialPost,
        setAllChecked,
        containerLoading,
      })}
    </div>
  );
};

export default SocialPostMonthContainerLayout;
