import { CheckCircleFilled } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { useAppDispatch } from '@core/configureStore';
import { checkout } from '@core/features/subscription/thunks/checkout.thunk';
import { useBreakpoint } from '@core/hooks';
import { Button, Checkbox, Divider } from 'antd';
import React from 'react';

type Props = {
  onClick?: any;
};

const SubscriptionPackageCard = ({ onClick }: Props) => {
  const { isMobile } = useBreakpoint();
  const dispatch = useAppDispatch();
  const handleCheckout = async () => {
    if (onClick) {
      onClick();
    } else {
      await dispatch(checkout(1));
    }
  };
  return (
    <div
      className="tablespace"
      style={{
        width: '100%',
        maxWidth: 450,
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        padding: isMobile ? '20px 15px' : '30px 35px',
        borderRadius: 10,
        backgroundColor: 'white',
        marginTop: isMobile ? 0 : 20,
        boxShadow: STRIPE_BOX_SHADOW,
        textAlign: 'left',
      }}>
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
        <div
          style={{
            ...MIDDLE_STYLE,
            width: '100%',
            flexDirection: 'column',
          }}>
          <p style={{ fontSize: '18px' }}>Experience the full power of Amazely Pro for free.</p>
          <Divider />
          <div>
            <h1
              className="airbnb-font"
              style={{ color: GLOBAL_THEME_COLOR.$highlight_color, fontSize: '30px' }}>
              $<span style={{ fontSize: '50px', fontWeight: 'bold' }}>0</span>
            </h1>
          </div>
        </div>
        <p style={{ textAlign: 'center' }}>
          For the next 30 days. <br />
          Then cancel or continue for $29/month
        </p>
        <Button
          type="primary"
          htmlType="submit"
          className="success-btn"
          size={'large'}
          onClick={handleCheckout}
          style={{
            width: isMobile ? '100%' : '',
            marginTop: 15,
            marginBottom: 15,
            ...MIDDLE_STYLE,
          }}>
          <CheckCircleFilled />
          <span className="airbnb-font">Activate for Free</span>
        </Button>
      </div>
      <Divider style={{ margin: 0, marginBottom: 15, marginTop: 10 }} />
      <h4 style={{ marginBottom: 10 }}>What you'll get:</h4>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Grow your Google reviews automatically (unlimited)
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Respond to Google reviews on our behalf (unlimited)
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Grow your social channels (unlimited)
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Shield your business from negative reviews
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Unlimited AI-powered social posts
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Automatic streams of 5-star reviews on Facebook and Instagram
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Reverse negative reviews
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Generate organic blogs to boost your search ranking
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Unlimited email/sms review invites
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Integrate review requests into your existing workflows{' '}
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Send review invitations automatically for every new customers
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Seamless integration with Square
      </div>
      <div>
        <Checkbox checked style={{ marginRight: 10 }} />
        Premium support{' '}
      </div>
    </div>
  );
};

export default SubscriptionPackageCard;
