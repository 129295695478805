import React, { useMemo } from 'react';
import { Button, Divider } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { DescriptionItem } from '@components/index';
import { BlogPostModel, BlogPostStage, SocialPost, SocialPostStage } from '@core/models';
import DateTimeEditor, { DateTimeEditorPayload } from './DateTimeEditor';

interface Props extends DateTimeEditorPayload {
  existingPost?: SocialPost | BlogPostModel;
  scheduleLaterEnabled: boolean;
  handleEnableScheduleLater: () => void;
}

const DateTimeScheduleEditor: React.FC<Props> = ({
  time,
  date,
  scheduleLaterEnabled,
  existingPost,
  handleEnableScheduleLater,
  handleSetScheduleTime,
  handleSchedulePost,
}) => {
  const isPublished = useMemo(
    () =>
      existingPost?.status === SocialPostStage.Published ||
      existingPost?.status === BlogPostStage.Published,
    [existingPost]
  );
  return (
    <React.Fragment>
      {!existingPost && (
        <Button onClick={handleEnableScheduleLater} style={{ marginTop: 20, width: '100%' }}>
          <ClockCircleOutlined />{' '}
          {scheduleLaterEnabled ? 'Deactivate schedule later' : 'Activate schedule later'}
        </Button>
      )}
      <Divider />
      {(existingPost || scheduleLaterEnabled) && (
        <DescriptionItem
          title="Published Date"
          style={{ justifyContent: 'space-between', marginTop: 25 }}
          content={
            <DateTimeEditor
              date={date}
              handleSchedulePost={handleSchedulePost}
              handleSetScheduleTime={handleSetScheduleTime}
              time={time}
              disabled={isPublished}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export default DateTimeScheduleEditor;
