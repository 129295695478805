import { MIDDLE_STYLE } from '@constants/responsive';
import { useGenerationDataStore } from '@core/hooks';
import { GoogleReview } from '@core/models';
import { unixtoString } from '@utils/date.util';
import { starRatingFromNumber } from '@utils/type.util';
import React from 'react';
import { ReviewWallOfLove } from '..';

type Props = {};

const PitchReviewWallOfLoveSection = (props: Props) => {
  const { businessData } = useGenerationDataStore();
  return (
    <React.Fragment>
      <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
        <h2 className="pitch_title">Showcase reviews on your website with no coding!</h2>
        <div className="pitch_subtitle">
          Embedding Amazely "Review Wall of Love" on your website to showcase the reputation of the
          business.
        </div>
        <div style={{ marginTop: 50, ...MIDDLE_STYLE }}>
          <ReviewWallOfLove
            reviews={businessData.showcaseReviews.map<GoogleReview>(review => ({
              comment: review.text,
              name: review.author_name,
              reviewer: {
                displayName: review.author_name,
                profilePhotoUrl: review.profile_photo_url,
                isAnonymous: false,
                emailAddress: '',
              },
              reviewId: '',
              reviewReply: undefined,
              starRating: starRatingFromNumber(review.rating),
              createTime: unixtoString(review.time),
              updateTime: unixtoString(review.time),
            }))}
            embeddedSetting={{
              cardColor: 'white',
              cardTitleColor: 'black',
              maxContainerSize: 1400,
              maxReviews: 10,
              hideNodeCommentReviews: false,
              maxReviewsMobile: 5,
              minStarRating: 4,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PitchReviewWallOfLoveSection;
