/* eslint-disable no-extend-native */
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import AppRouter from './AppRouter';
import { ConfigProvider } from 'antd';
import { ANT_DESIGN_THEME, BRAND_NAME } from './constants';

declare global {
  interface Array<T> {
    sumArray: (arr: T[]) => T[];
    unique: () => T[];
  }
}

Array.prototype.sumArray = function (arr: any[]) {
  let sum = [];
  if (arr != null && this.length === arr.length) {
    for (let i = 0; i < arr.length; i++) {
      sum.push(this[i] + arr[i]);
    }
  }
  return sum;
};

Array.prototype.unique = function () {
  const a = this.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <title>{BRAND_NAME}</title>
        </Helmet>
        <ConfigProvider theme={ANT_DESIGN_THEME}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
