import {
  BackgroundEvent,
  BlogGenerateBackgroundEvent,
  BlogPostMadeBy,
  BlogPostModel,
  BlogPostStage,
} from '@core/models';
import moment from 'moment';

export const convertBackgroundEventToBlogPost = (
  data: BackgroundEvent<BlogGenerateBackgroundEvent>
): BlogPostModel => {
  return {
    id: data.id,
    location: data.location,
    title: data.extra.title,
    status: BlogPostStage.Pending,
    created_date: moment().toDate().toString(),
    publish_at: moment(data.extra.scheduledDate).toString(),
    slug: '',
    thumbnail: '',
    subtitle: 'Blog post is being crafted. Please wait for a few minutes...',
    updated_date: moment().toDate().toString(),
    made_by: BlogPostMadeBy.System,
    contentBody: '',
    contentBodyUrl: '',
    isBackgroundEvent: true,
    published_accounts: [],
  };
};
