import React, { useState } from 'react';
import TopicSuggestionListContainer from '@components/TopicSuggestionListContainer/index';
import { Button, Popover } from 'antd';

interface Props {
  topics: string[];
  onTopicClicked: (topic: string) => void;
  limit: number;
}

const ExpandableTopicSuggestionListContainer: React.FC<Props> = ({
  topics,
  limit,
  onTopicClicked,
}) => {
  const [topicSuggestionsExpanded, setTopicSuggestionExpanded] = useState(false);
  return (
    <React.Fragment>
      <TopicSuggestionListContainer
        topics={topics.slice(0, limit)}
        onTopicClicked={onTopicClicked}
      />
      {topics.length > limit && (
        <Popover
          open={topicSuggestionsExpanded}
          trigger={'click'}
          content={
            <React.Fragment>
              <TopicSuggestionListContainer
                topics={topics}
                onTopicClicked={topic => {
                  onTopicClicked(topic);
                  setTopicSuggestionExpanded(false);
                }}
              />
            </React.Fragment>
          }>
          <Button
            onClick={() => setTopicSuggestionExpanded(!topicSuggestionsExpanded)}
            size={'middle'}
            style={{ width: '100%' }}>
            View more suggestions
          </Button>
        </Popover>
      )}
    </React.Fragment>
  );
};

export default ExpandableTopicSuggestionListContainer;
