import { useEffect, useMemo, useState } from 'react';
import { BlogOnboardingStage, useBlogPageTourStore } from './useBlogPageTourStore';
import { useAuthState, useBlogPostDispatcher, useBlogState } from '..';
import { BlogPostStage } from '@core/models';
import { backgroundEventApiService } from '@services/service-register';

export const useBlogOnboardingState = () => {
  const { blogPosts, isLoading } = useBlogState();
  const { user } = useAuthState();
  const { onboardingStages, addOnboardingStage } = useBlogPageTourStore();
  const { isSystemMadeBlogPost } = useBlogPostDispatcher();
  const location = useMemo(() => user?.selected_location, [user]);
  const maxOnboardingStages = Object.keys(BlogOnboardingStage).length;
  const finishedOnboardingStages = useMemo(() => onboardingStages.length, [onboardingStages]);
  const [isCheckingOnboardingState, setIsCheckingOnboardingState] = useState<boolean>(true);
  const belowOnboardingStages = useMemo(
    () => finishedOnboardingStages < maxOnboardingStages,
    [finishedOnboardingStages, maxOnboardingStages]
  );

  const fetchBlogGenerateEvents = async () => {
    try {
      const backgroundEvents = await backgroundEventApiService.getBlogGenerateEvents();
      return backgroundEvents;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const init = async () => {
      const backgroundEvents = await fetchBlogGenerateEvents();
      if (isLoading) return;
      if (location && location.blog_assistant_profile.autoShareToSocial)
        addOnboardingStage(BlogOnboardingStage.AutoShareBlogPostToSocial);
      if (blogPosts.length > 0) addOnboardingStage(BlogOnboardingStage.CreateYourFirstBlog);
      const publishedBlogPosts = blogPosts.filter(
        blogPost => blogPost.status === BlogPostStage.Published
      );
      if (publishedBlogPosts.length > 0) addOnboardingStage(BlogOnboardingStage.PublishBlogPost);
      if (blogPosts.some(isSystemMadeBlogPost) || backgroundEvents.length > 0)
        addOnboardingStage(BlogOnboardingStage.GenerateBlogPost);
      setIsCheckingOnboardingState(false);
    };
    init();
  }, [blogPosts, isLoading, location]);

  useEffect(() => {
    if (user?.selected_location?.website_url) {
      addOnboardingStage(BlogOnboardingStage.AddBusinesWebsite);
    }
  }, [user]);

  return {
    maxOnboardingStages,
    finishedOnboardingStages,
    onboardingStages,
    isCheckingOnboardingState,
    belowOnboardingStages,
  };
};
