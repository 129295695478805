import { OnboardingStage } from '@core/models';
import { useAppDispatch, useAuthState } from '..';
import { fetchCurrentUser } from '@core/features/auth/thunks';
import { heroApiService } from '@services/service-register';

export const useUserOnboarding = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();

  const stageChecked = (stage: OnboardingStage) => {
    return !!user?.selected_location?.onboarding_stages?.includes(stage);
  };

  const handleMarkAsComplete = async (stages: OnboardingStage[]) => {
    await heroApiService.updateLocationOnboarding(stages);
    await dispatch(fetchCurrentUser());
  };

  return {
    stageChecked,
    handleMarkAsComplete,
  };
};
