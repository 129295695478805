import { fetchCurrentUser } from '@core/features/auth/thunks';
import { heroApiService } from '@services/service-register';
import { useAppDispatch, useAppNavigation } from '..';
import { SnackbarItem, closeModal, enqueueNotification, openModal } from '@core/features';

export const useDigitalCreatorMode = () => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();

  const switchDigitalCreatorMode = async (businessName?: string) => {
    await heroApiService.switchDigitalCreatorMode(businessName);
    await dispatch(fetchCurrentUser());
    dispatch(
      enqueueNotification({
        name: 'Setup a feelancer mode business successfully',
        description: 'Successfully select location',
        type: 'Success',
      } as SnackbarItem)
    );
    dispatch(
      closeModal({
        modalName: 'freelancerModeSetupModal',
      })
    );
    navigate('studio');
  };

  const handleSwitchDigitalCreatorMode = async (location: any) => {
    const digitalCreatorModeBusiness = await getDigitalCreatorMode();
    if (digitalCreatorModeBusiness) {
      await switchDigitalCreatorMode();
    } else {
      dispatch(
        openModal({
          modalName: 'freelancerModeSetupModal',
        })
      );
    }
  };

  const getDigitalCreatorMode = () => {
    try {
      return heroApiService.getDigitalCreatorModeBusiness();
    } catch (error) {
      return undefined;
    }
  };

  return {
    getDigitalCreatorMode,
    switchDigitalCreatorMode,
    handleSwitchDigitalCreatorMode,
  };
};
