import { useEffect, useState } from 'react';
import { Button, Form, Space } from 'antd';
import { CountryLanguageSelector } from '..';

type Props = {
  language: string;
  handleLanguageChange: any;
  handleUpdateLanguage: any;
};
const LanguageSettingRow = ({ language, handleLanguageChange, handleUpdateLanguage }: Props) => {
  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Form form={form} name="language_form" onFinish={handleUpdateLanguage}>
      <Space>
        <CountryLanguageSelector
          selectedLanguage={language}
          setSelectedLanguage={handleLanguageChange}
          handleUpdated={() => setUpdated(true)}
        />
        <Button type="primary" htmlType="submit" disabled={!updated}>
          Save
        </Button>
      </Space>
    </Form>
  );
};

export default LanguageSettingRow;
