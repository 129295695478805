import { Button, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import { DescriptionItem, PublishedAccountsContainer, SocialAccountButtonListContainer } from '..';
import { countExistentialObject } from '@utils/array.util';
import { SendOutlined } from '@ant-design/icons';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { closeModal, enqueueNotification } from '@core/features';
import { BlogPostModel } from '@core/models';
import { useSocialIntegrationState } from '@core/hooks';
import { blogApiService } from '@services/service-register';

type Props = {};

const ShareBlogPostToSocialModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { shareToSocialChannelsModal, extraParams } = useModalState();
  const { selectedSocialChannels, setSelectedSocialChannels } = useSocialIntegrationState();
  const [loading, setLoading] = useState(false);
  const blogPost = useMemo<BlogPostModel | undefined>(() => extraParams?.blogPost, [extraParams]);

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'shareToSocialChannelsModal',
      })
    );
  };

  const handleShareBlogPostToSocial = async () => {
    if (!blogPost) return;
    try {
      setLoading(true);
      const accountDestinations = Object.keys(selectedSocialChannels).filter(
        sentToAccount => selectedSocialChannels[sentToAccount]
      );
      await blogApiService.shareBlogPostToSocial(blogPost.id, accountDestinations);
      dispatch(
        enqueueNotification({
          name: 'Successfully share a blog post',
          type: 'Success',
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to share a blog post',
          type: 'Error',
        })
      );
    }
    setLoading(false);
  };

  return (
    <Modal
      title={'Share a Blog Post'}
      open={shareToSocialChannelsModal}
      footer={[]}
      onCancel={handleCloseModal}>
      <DescriptionItem
        title="Shared to"
        style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
        content={
          <PublishedAccountsContainer publishedAccounts={blogPost?.published_accounts || []} />
        }
      />
      <DescriptionItem
        title="Select social channels to share"
        style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
        content={
          <SocialAccountButtonListContainer
            selectedAccounts={selectedSocialChannels}
            setSelectedAccounts={setSelectedSocialChannels}
            disabledPlatforms={['instagram']}
          />
        }
      />
      <Button
        disabled={countExistentialObject(selectedSocialChannels) === 0}
        onClick={handleShareBlogPostToSocial}
        type={'primary'}
        loading={loading}
        style={{ width: '100%', marginTop: 15 }}
        className={'success-btn'}>
        <span>
          <SendOutlined rotate={-30} /> Share
        </span>
      </Button>
    </Modal>
  );
};

export default ShareBlogPostToSocialModal;
