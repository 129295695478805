/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAuthState, useReviewState } from '@core/configureStore';
import React, { useEffect } from 'react';
import { fetchReviews } from '@core/features/review/thunks';
import { Button, Divider, Space, Switch } from 'antd';
import ReviewTable from '@components/ReviewTable';
import ReviewFilterButton from '@components/ReviewFilterButton';
import ReviewSortByButton from '@components/ReviewSortbyButton';
import ReviewOrderbyButton from '@components/ReviewOrderbyButton';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import FlexDatePicker from '@components/FlexDatePicker';
import moment, { Moment } from 'moment';
import { selectDateRange } from '@core/features';
import { DefendResponseType, GoogleReview } from '@core/models';
import LoadableContainer from '@components/LoadableContainer';
import { RateConversion } from '@constants/rate-conversion';
import { MIDDLE_STYLE } from '@constants/responsive';
import SplashScreen from '@components/SplashScreen';
import confirm from 'antd/es/modal/confirm';
import Lottie from 'lottie-react';
import { ChatBotLottieFile } from '@components/animations';
import { updateDefendResponse } from '@core/features/business/thunks/updateDefendResponse.thunk';
import BusinessReviewCard from '@components/BusinessReviewCard';
import { starRatingToNumber } from '@utils/type.util';
import { formatUtcTimestamp } from '@utils/date.util';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
const { RangePicker } = FlexDatePicker;

const InquiryScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { user, isAuthLoading } = useAuthState();
  const { reviewResponse, dateRange, isLoading: isLoadingReview } = useReviewState();
  const [rangedReviews, setRangedReviews] = React.useState<GoogleReview[]>([]);
  const [enableRectification, setEnableRectification] = React.useState(false);
  const handleRefresh = () => {
    dispatch(fetchReviews());
  };

  const handleCalendarChange = (value: Moment[]) => {
    const [startDate, endDate] = value;
    dispatch(
      selectDateRange({
        startDate,
        endDate,
      })
    );
  };

  const handleReset = () => {
    dispatch(selectDateRange(undefined));
    handleRefresh();
  };

  const handleActivateRectification = async () => {
    confirm({
      title: 'Activate auto defend mode',
      icon: <ExclamationCircleFilled />,
      content: (
        <div style={{ paddingRight: 20, margin: '0px 0px 20px 0px' }}>
          <Lottie
            style={{
              height: 200,
            }}
            animationData={ChatBotLottieFile}
          />
          Activate auto rectification mode for your location? This feature automatically responds to
          negative reviews (1 or 2 stars) with a short form asking for the reviewers' contacts.
        </div>
      ),
      cancelText: 'Cancel',
      okCancel: true,
      onCancel: () => {},
      okText: 'Activate',
      onOk: () => {
        dispatch(updateDefendResponse(DefendResponseType.RECTIFICATION));
        setEnableRectification(true);
      },
    });
  };

  const handleDeactivateRectification = async () => {
    await dispatch(updateDefendResponse(DefendResponseType.NONE));
    setEnableRectification(false);
  };

  useEffect(() => {
    let localReviews: GoogleReview[] =
      reviewResponse?.reviews.filter(review => RateConversion[review.starRating] < 3) || [];

    if (dateRange) {
      localReviews = localReviews.filter(review =>
        moment(review.updateTime).isBetween(dateRange.startDate, dateRange.endDate)
      );
    }
    setRangedReviews(localReviews);
  }, [dateRange, reviewResponse]);

  useEffect(() => {
    handleRefresh();
  }, [dateRange]);

  useEffect(() => {
    const currentDefendType = user?.selected_location.defend_response_type;
    setEnableRectification(currentDefendType === DefendResponseType.RECTIFICATION);
  }, [user?.selected_location]);

  return (
    <React.Fragment>
      <LoadableContainer isLoading={isLoadingReview} loadComponent={<SplashScreen />}>
        <div className="screen-card">
          <h3>Reputation Defender</h3>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <div>Engage with your customers to resolve negative reviews</div>

            {user?.selected_location && !isAuthLoading ? (
              <Space>
                <Switch
                  defaultChecked={
                    user?.selected_location.defend_response_type ===
                    DefendResponseType.RECTIFICATION
                  }
                  onChange={isChecked => {
                    !isChecked ? handleDeactivateRectification() : handleActivateRectification();
                  }}
                  checked={enableRectification}
                />
              </Space>
            ) : (
              <LoadingOutlined />
            )}
          </div>

          <Divider />

          <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'space-between' }}>
            <ReviewFilterButton />
            <ReviewSortByButton />
            <ReviewOrderbyButton />
            <Space style={{ display: 'flex' }}>
              <span style={{ fontWeight: 'normal', fontSize: 15 }}>Date Range:</span>
              <RangePicker
                allowClear
                {...(!dateRange && { defaultValue: undefined })}
                onCalendarChange={handleCalendarChange as any}
              />
              <Button onClick={handleReset}>Reset</Button>
            </Space>
          </div>
          {isTablet ? (
            <React.Fragment>
              {rangedReviews.map(review => (
                <BusinessReviewCard
                  inApp
                  review={{
                    author_url: review.reviewer.profilePhotoUrl,
                    profile_photo_url: review.reviewer.profilePhotoUrl,
                    author_name: review.reviewer.displayName,
                    generated_response: review.reviewReply?.comment || '',
                    rating: starRatingToNumber(review.starRating),
                    text: review.comment,
                    time: formatUtcTimestamp(review.createTime),
                  }}
                />
              ))}
            </React.Fragment>
          ) : (
            <ReviewTable reviews={rangedReviews} size="large" style={{ padding: '20px 0px' }} />
          )}
        </div>
      </LoadableContainer>
    </React.Fragment>
  );
};

export default InquiryScreen;
