import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Input, Modal, Skeleton, Tag } from 'antd';
import { useAuthState } from '@core/configureStore';
import { getBlogPostRangeSchedules } from '@utils/date.util';
import moment from 'moment/moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import { LoadableContainer } from '@components/index';
import { blogApiService } from '@services/service-register';
import { MIDDLE_STYLE } from '@constants/responsive';

const BlogPreviewTopicsModal: React.FC<{
  topics: string[];
  setTopics: (topics: string[]) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onGenerate: () => any;
  month: string;
}> = ({ topics, setTopics, month, onGenerate, open, setOpen }) => {
  const { user } = useAuthState();
  const [loading, setLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<number[]>([]);

  const blogTopicValues = useMemo<
    {
      topic: string;
      schedule: number;
    }[]
  >(() => {
    const scheduleIterator = schedules[Symbol.iterator]();
    const scheduledTopics = topics.map(topic => ({
      topic,
      schedule: scheduleIterator.next().value,
    }));
    return scheduledTopics;
  }, [schedules, topics]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleGenerate = () => {
    handleCloseModal();
    onGenerate && onGenerate();
  };

  const handleTopicChanged = (index: number, value: string) => {
    let currentIndex = 0;
    const updatedTopics: string[] = [];
    for (const topic of topics) {
      if (currentIndex === index) updatedTopics.push(value);
      else updatedTopics.push(topic);
      currentIndex++;
    }
    setTopics(updatedTopics);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const currentMonth = moment(month, MONTH_KEY_DATE_FORMAT);
        const schedules = await getBlogPostRangeSchedules(currentMonth.startOf('month'), 0);
        setSchedules(schedules);

        const location = user?.selected_location;
        if (!location) throw new Error('No location found');
        const generatedTopics = await blogApiService.generateBlogTopics(
          schedules.length,
          location.primary_category,
          location.business_description
        );

        setTopics(generatedTopics);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (open) {
      init();
    }
  }, [month, open, user?.selected_location]);

  return (
    <Modal
      title={`Preview Topics`}
      centered
      open={open}
      onCancel={handleCloseModal}
      width={'100%'}
      style={{ maxWidth: 600, overflow: 'hidden' }}
      footer={[
        <Button onClick={handleCloseModal}>Cancel</Button>,
        <Button disabled={blogTopicValues.length === 0} type={'primary'} onClick={handleGenerate}>
          Next
        </Button>,
      ]}>
      <div>
        Amazely will craft blog posts for this month using these topics {blogTopicValues.length}{' '}
        below.
      </div>
      <Divider />
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        {blogTopicValues.length > 0 && (
          <React.Fragment>
            {blogTopicValues.map((topicValue, index) => (
              <div style={{ ...MIDDLE_STYLE, margin: '5px 0px' }}>
                <Input
                  onChange={e => handleTopicChanged(index, e.target.value)}
                  prefix={
                    <Tag color="blue">
                      {moment.unix(topicValue.schedule).format('DD MMMM YYYY')}{' '}
                    </Tag>
                  }
                  value={topicValue.topic}></Input>
              </div>
            ))}
            <Divider />
          </React.Fragment>
        )}
      </LoadableContainer>
    </Modal>
  );
};

export default BlogPreviewTopicsModal;
