import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';

type Props = {
  reviewUrl: string;
  handleReviewUrlChange: any;
  handleUpdateReviewUrl: any;
};
const ReviewUrlSettingRow = ({
  reviewUrl,
  handleReviewUrlChange,
  handleUpdateReviewUrl,
}: Props) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Form form={form} name="review_url_form" onFinish={handleUpdateReviewUrl}>
      <Row gutter={5}>
        <Col lg={18} md={24}>
          <Form.Item name="review_url" initialValue={reviewUrl}>
            <Input
              name="review_url"
              addonBefore="Review URL"
              onChange={e => handleReviewUrlChange(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col lg={6} md={24}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldTouched('review_url') ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }>
                Save
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ReviewUrlSettingRow;
