import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { Button, Checkbox, Col, Popconfirm, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AnimatedComponent, SocialPostListItem } from '..';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { socialStudioApiService } from '@services/service-register';
import { fetchSocialPosts } from '@core/features/studio/thunks';
import { enqueueNotification } from '@core/features';
import { useBreakpoint } from '@core/hooks';
import { SocialPost } from '@core/models';
import { filterObject } from '@utils/array.util';
import { DeleteOutlined } from '@ant-design/icons';

type Props = {
  socialPosts: SocialPost[];
};

const SocialPostListContainer = ({ socialPosts }: Props) => {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const [allChecked, setAllChecked] = useState(false);
  const [selectedSocialPost, setSelectedSocialPost] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);
  const { user } = useAuthState();

  const locationId = useMemo(() => user?.selected_location.location_id, [user]);

  const handleResetSelectedSocialPosts = () => {
    setSelectedSocialPost({});
  };

  const handleBatchRemoveSocialPosts = async (socialPostIds: string[]) => {
    try {
      setLoading(true);
      const promises: Promise<any>[] = [];
      for (const socialPostId of socialPostIds) {
        promises.push(socialStudioApiService.removeSocialPost(parseInt(socialPostId)));
      }
      await Promise.all(promises);
      await dispatch(fetchSocialPosts());
      handleResetSelectedSocialPosts();
      setAllChecked(false);
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to remove social posts',
          type: 'Error',
        })
      );
    }
    setLoading(false);
  };

  const checkedSocialPosts = useMemo<string[]>(
    () => filterObject(selectedSocialPost, isSelected => isSelected),
    [selectedSocialPost, socialPosts]
  );

  useEffect(() => {
    const checkedSocialPosts: Record<string, boolean> = {};
    for (const socialPost of socialPosts) {
      checkedSocialPosts[socialPost.id] = allChecked;
    }
    setSelectedSocialPost(checkedSocialPosts);
  }, [socialPosts, allChecked]);

  return (
    <React.Fragment>
      {checkedSocialPosts.length > 0 && (
        <div style={{ ...MIDDLE_STYLE }}>
          <Popconfirm
            onConfirm={() => handleBatchRemoveSocialPosts(checkedSocialPosts)}
            title="Delete selected posts"
            description="Are you sure to delete these posts?"
            okButtonProps={{
              size: 'middle',
            }}
            cancelButtonProps={{
              size: 'middle',
            }}
            okText="Yes"
            cancelText="No">
            <Button
              id={'remove-posts-button'}
              style={{ width: isTablet ? '100%' : 'fit-content' }}
              danger
              loading={loading}>
              <DeleteOutlined /> Remove {checkedSocialPosts.length} selected posts
            </Button>
          </Popconfirm>
        </div>
      )}
      {socialPosts.length > 0 && (
        <div
          style={{
            ...MIDDLE_STYLE,
            width: '100%',
            margin: '10px 0px 5px 0px',
            paddingBottom: 5,
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          }}>
          {!isTablet && (
            <Checkbox
              checked={allChecked}
              style={{ marginRight: 20 }}
              onClick={() => setAllChecked(!allChecked)}
            />
          )}
          <Row style={{ width: '100%' }}>
            <Col span={2} style={{ ...MIDDLE_STYLE }}>
              <span style={{ fontSize: '12px' }}>Photo</span>
            </Col>
            <Col span={3} style={{ ...MIDDLE_STYLE }}>
              <span style={{ fontSize: '12px' }}>Status</span>
            </Col>
            <Col span={16}>
              <span style={{ fontSize: '12px' }}>Content</span>
            </Col>
          </Row>
        </div>
      )}
      {socialPosts.map(socialPost => (
        <AnimatedComponent.OpacityFadeInDiv delay={200}>
          {locationId && (
            <React.Fragment>
              <div
                style={{ ...MIDDLE_STYLE }}
                onClick={() =>
                  setSelectedSocialPost({
                    ...selectedSocialPost,
                    [socialPost.id]: !selectedSocialPost[socialPost.id.toString()],
                  })
                }>
                {!isTablet && (
                  <Checkbox
                    checked={selectedSocialPost[socialPost.id.toString()]}
                    style={{ marginRight: 20 }}
                  />
                )}
                <SocialPostListItem socialPost={socialPost} locationId={locationId} />
              </div>
            </React.Fragment>
          )}
        </AnimatedComponent.OpacityFadeInDiv>
      ))}
    </React.Fragment>
  );
};

export default SocialPostListContainer;
