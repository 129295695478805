import { Optional } from '@utils/.';

export enum ReviewRequestStatus {
  Pending = 0,
  Reviewed = 1,
  Draft = 2,
}

export enum RequestServiceStage {
  Pending = 0,
  Sent = 1,
  Open = 2,
  Reviewed = 3,
  Failed = 4,
  Scheduled = 5,
  NA = 6,
}

export enum ScheduleDuration {
  None = 0,
  Day = 86400,
  Month = 2592000,
}

export enum RequestSource {
  Manual = 0,
  Square = 1,
  Email = 2,
  OpenDental = 3,
  QuickBooks = 4,
  Jobber = 5,
  Xero = 6,
}

export type ReviewRequest = {
  recurring?: boolean;
  contact: string;
  content: string;
  phone: string;
  followUpContent: string;
  followUpCount: number;
  stage: ReviewRequestStatus;
  request_source: RequestSource;
  schedule_duration: ScheduleDuration;
  /* Email service */
  email_status: RequestServiceStage;
  email_sent_date: string;
  email_open_date: string;

  number_of_email_sent: number;
  number_of_email_open: number;
  /* SMS service */
  sms_status: RequestServiceStage;
  sms_sent_date: string;
  sms_open_date: string;

  fullName: string;
  review_id: Optional<string>;
  rating: number;
  feedback: string;
  id: number;
  created_date: string;
  last_modified_date: string;
  location_id: string;
};

export type ReviewRequestPayload = Pick<ReviewRequest, 'contact' | 'fullName' | 'phone'> & {
  phoneCode: string;
};
