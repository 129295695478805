import React, { useEffect, useMemo, useState } from 'react';
import { Drawer } from 'antd';
import { useAppDispatch, useAuthState, useDrawerState } from '@core/configureStore';
import TemplateEditContainer from '@components/ReviewSocialPostEditor/TemplateEditContainer';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { closeDrawer } from '@core/features';
import {
  BackgroundAssetVariant,
  BackgroundType,
  PartialShowcasedReview,
  TemplateSettingType,
} from '@core/models';

const DEFAULT_TEMPLATE_SETTING: TemplateSettingType = {
  templateId: 0,
  backgroundType: BackgroundType.RandomColor,
  backgroundAssets: [
    {
      value: GLOBAL_THEME_COLOR.$highlight_color,
      variant: BackgroundAssetVariant.Color,
    },
  ],
};
const ReviewAttributeDrawer: React.FC<{
  mockReview: PartialShowcasedReview;
}> = ({ mockReview }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const reviewTemplateSetting = useMemo(
    () => user?.selected_location.review_template_setting,
    [user]
  );
  const { reviewAttributeDrawer } = useDrawerState();
  const [templateSetting, setTemplateSetting] =
    useState<TemplateSettingType>(DEFAULT_TEMPLATE_SETTING);

  const onClose = () => {
    dispatch(
      closeDrawer({
        drawerName: 'reviewAttributeDrawer',
      })
    );
  };

  useEffect(() => {
    if (reviewTemplateSetting) {
      setTemplateSetting({
        ...reviewTemplateSetting,
      });
    }
  }, [reviewTemplateSetting]);

  return (
    <Drawer
      width={'100%'}
      title={'Edit Template'}
      placement="right"
      getContainer={false}
      closable={true}
      style={{ overflowX: 'hidden' }}
      onClose={onClose}
      open={reviewAttributeDrawer}>
      <TemplateEditContainer
        mockReview={mockReview}
        templateSetting={templateSetting}
        setTemplateSetting={setTemplateSetting}
      />
    </Drawer>
  );
};

export default ReviewAttributeDrawer;
