import axios from 'axios';

export type SubscriptionCancellationReason =
  | 'customer_service'
  | 'low_quality'
  | 'missing_features'
  | 'other'
  | 'switched_service'
  | 'too_complex'
  | 'too_expensive'
  | 'unused';

export default class SubscriptionApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}`;

  public async getFacebookProfile(): Promise<any> {
    try {
      const response = await axios.get(`${this.url}/users/facebook/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async cancelSubscription(reason: SubscriptionCancellationReason): Promise<boolean> {
    try {
      const response = await axios.post(
        `${this.url}/users/cancel-subscription`,
        {
          cancellationReason: reason,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async applySubscriptionOffer(): Promise<void> {
    try {
      const response = await axios.post(
        `${this.url}/users/apply-subscription-offer`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
