import { useAppDispatch, useReviewState } from '@core/configureStore';
import { sortReviewBy } from '@core/features';
import { Select, Space } from 'antd';
import React from 'react';

type Props = {};

const ReviewSortByButton = (props: Props) => {
  const dispatch = useAppDispatch();
  const { sortBy } = useReviewState();

  const onSelectChange = (value: string) => {
    dispatch(sortReviewBy(value));
  };
  return (
    <React.Fragment>
      <Space style={{ display: 'flex' }}>
        <span style={{ fontWeight: 'normal', fontSize: 15 }}>Sort by:</span>
        <Select
          style={{ width: 120 }}
          placeholder="Select sort"
          defaultValue={sortBy}
          onChange={onSelectChange}
          options={[
            {
              value: 'date',
              label: 'Date',
            },
            {
              value: 'rating',
              label: 'Rating',
            },
            {
              value: 'name',
              label: 'Name',
            },
          ]}
        />
      </Space>
    </React.Fragment>
  );
};

export default ReviewSortByButton;
