import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { InquiryReducerProps } from '../type';

type OpenInquiryPayload = {
  inquiryId: string;
};

export const openInquiryEmail = createAsyncThunk(
  'inquiry/openInquiryEmail',
  async ({ inquiryId }: OpenInquiryPayload, thunkApi) => {
    const result = await heroApiService.openInquiryEmail(inquiryId);
    return result;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(openInquiryEmail);

export const openInquiryReducer = {
  [fulfilled]: (state, action) => {
    state.isOpening = false;
    state.inquiry = action.payload;
  },
  [rejected]: (state, action) => {
    state.isOpening = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isOpening = true;
  },
} as ExtraReducer<InquiryReducerProps>;
