import React from 'react';
import { StudioPhoto } from '@core/models';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Col, Row, Tag } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { shortenString } from '@utils/string.util';
import { CopyOutlined } from '@ant-design/icons';
import { formatUtcDate } from '@utils/date.util';

type ImageCardItemProps = {
  item: StudioPhoto;
  isSelected?: boolean;
  loading?: boolean;
  onClick: (item: StudioPhoto) => void;
};

const StudioImageListItem = ({ item, onClick }: ImageCardItemProps) => {
  return (
    <div
      onClick={async e => {
        e.stopPropagation();
        onClick(item);
      }}
      className={'hover-to-highlight'}
      style={{
        width: '100%',
        padding: `10px 0px`,
        borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        cursor: 'pointer',
      }}>
      <Row gutter={20} style={{ padding: '0px 10px' }}>
        <Col span={2} style={{ ...MIDDLE_STYLE }}>
          <LazyLoadImage loading="lazy" className="card--image" src={item.url} width="50px" />
        </Col>
        <Col span={6} style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start' }}>
          {shortenString(item.description || 'No Annotation', 100)}{' '}
          <CopyOutlined style={{ margin: '0px 10px' }} />
        </Col>
        <Col span={14} style={{ ...MIDDLE_STYLE }}>
          <Tag color={'orange'}>{formatUtcDate(item.created_date)}</Tag>
        </Col>
      </Row>
    </div>
  );
};

export default StudioImageListItem;
