import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { Checkbox, Progress } from 'antd';
import React from 'react';

export type OnboardingStageComponent<T> = {
  stage: T;
  title: string;
};

type Props<T> = {
  containerTitle: string;
  containerDescription: React.ReactNode;
  currentOnboardingStages: number;
  maxOnboardingStages: number;
  stageComponents: OnboardingStageComponent<T>[];
  onStageComponentClicked: (component: T) => void;
  onStageChecked: (stage: T) => boolean;
};

function OnboardingChecklistContainer<T>({
  containerTitle,
  containerDescription,
  currentOnboardingStages,
  maxOnboardingStages,
  stageComponents,
  onStageComponentClicked,
  onStageChecked,
}: Props<T>) {
  return (
    <React.Fragment>
      <h1 className="airbnb-font" style={{ fontSize: 25 }}>
        {containerTitle}
      </h1>
      <p style={{ fontSize: 'larger', color: GLOBAL_THEME_COLOR.$dark_text_color }}>
        {containerDescription}
      </p>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <span style={{ width: 70 }}>
          {currentOnboardingStages} of {maxOnboardingStages}
        </span>
        <Progress
          percent={(currentOnboardingStages / maxOnboardingStages) * 100}
          showInfo={false}
        />
      </div>
      <br />
      {stageComponents.map(tabItem => (
        <React.Fragment>
          <div
            onClick={() => onStageComponentClicked(tabItem.stage)}
            style={{
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
              cursor: 'pointer',
              borderTop: `1px solid ${GLOBAL_THEME_COLOR.$dark_secondary_color}`,
              padding: '15px 0px',
              fontWeight: 'normal',
              color: GLOBAL_THEME_COLOR.$text_color,
            }}>
            <div style={{ fontSize: 15 }}>{tabItem.title}</div>
            <Checkbox checked={onStageChecked(tabItem.stage)} />
          </div>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default OnboardingChecklistContainer;
