import axios from 'axios';

const BACKEND_URL = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/signal`;

export default class ServerSignalService {
  public async interruptStream() {
    try {
      await axios.post(
        `${BACKEND_URL}/interrupt-stream`,
        {},
        {
          withCredentials: true,
        }
      );
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
