import { MIDDLE_STYLE } from '@constants/responsive';
import { useBreakpoint } from '@core/hooks';
import { Col, Row } from 'antd';
import React from 'react';

type Props = {};

const PitchReverseNegativeReviews = (props: Props) => {
  const { isTablet } = useBreakpoint();
  return (
    <Row gutter={50} style={{ maxWidth: 1500 }}>
      <Col span={isTablet ? 24 : 12} style={{ ...MIDDLE_STYLE }}>
        <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
          <h2 className="pitch_title">Reverse 1-star reviews</h2>
          <div className="pitch_subtitle">
            While you strive to provide top-notch service, occasional unfavorable customer reviews
            are inevitable. But don't fret, with the right tools and responses at your disposal, you
            have the opportunity to transform dissatisfied customers into loyal advocates.
            <br />
            <br />
            Using Amazely reputation defender, you can enable automatic AI that responds to negative
            reviews in almost real-time, allowing you to quickly establish the first point of
            contact with your customers. We then provide tools that help you engage with your
            customers toward reversing negative reviews.
          </div>
        </div>
      </Col>
      <Col span={isTablet ? 24 : 12} style={{ ...MIDDLE_STYLE }}>
        <img style={{ objectFit: 'cover', width: '100%' }} src="/jumbotron/rectify.png" />
      </Col>
    </Row>
  );
};

export default PitchReverseNegativeReviews;
