import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { shortenString } from '@utils/string.util';
import React from 'react';
import { SocialPost } from '@core/models';
import moment from 'moment';
import { DATE_FORMAT } from '@utils/date.util';
import { MIDDLE_STYLE } from '@constants/responsive';
import { SocialPostImageGrid } from '..';

const SmallSocialPostCard: React.FC<{ item: SocialPost }> = ({ item }) => {
  return (
    <div
      style={{
        marginBottom: 10,
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        padding: '5px',
        borderRadius: 5,
        ...MIDDLE_STYLE,
      }}>
      <SocialPostImageGrid width={120} mediaIds={item?.mediaIds || []} hideOverlay={true} />
      <div style={{ marginLeft: 10 }}>
        <h3 style={{ fontSize: 10 }}>{moment(item.publish_at).format(DATE_FORMAT)}</h3>
        <span style={{ fontSize: 10 }}>{shortenString(item.content, 80)}</span>
      </div>
    </div>
  );
};

export default SmallSocialPostCard;
