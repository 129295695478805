import React, { useState } from 'react';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { closeModal, openModal } from '@core/features';
import { Button, Divider, Input, Modal, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useDigitalCreatorMode } from '@core/hooks';

const FreelancerModeSetupModal = () => {
  const dispatch = useAppDispatch();
  const { freelancerModeSetupModal } = useModalState();
  const [businessName, setBusinessName] = useState<string>('');
  const { switchDigitalCreatorMode } = useDigitalCreatorMode();

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'freelancerModeSetupModal',
      })
    );
  };

  const handleConnectGoogleBusinessLocation = () => {
    dispatch(
      openModal({
        modalName: 'welcomeLocationModal',
      })
    );
  };

  const handleSetup = async () => {
    if (!businessName) return;
    await switchDigitalCreatorMode(businessName);
  };

  const MIN_NAME_LENGTH = 3;
  const MAX_NAME_LENGTH = 40;

  return (
    <Modal
      title={'Setup a Freelancer Mode Business'}
      open={freelancerModeSetupModal}
      onOk={handleSetup}
      okText="Setup Business"
      okButtonProps={{
        disabled: businessName.length <= MIN_NAME_LENGTH || businessName.length >= MAX_NAME_LENGTH,
      }}
      onCancel={handleModalClose}
      closable>
      <Space direction="vertical">
        <p style={{ margin: '10px 0px 20px 0px' }}>
          Freelancer Mode business has a limited access to feature. To maximize the capabilities of
          Amazely, please connect a Google Business location.
        </p>
        <Button onClick={handleConnectGoogleBusinessLocation} style={{ ...MIDDLE_STYLE }}>
          <img src="/google.png" width="20px" style={{ marginRight: 10 }} />
          Connect a Google Business location
        </Button>
        <Divider />
        <Input
          prefix={<SearchOutlined />}
          maxLength={MAX_NAME_LENGTH}
          minLength={MIN_NAME_LENGTH}
          showCount
          value={businessName}
          onChange={e => setBusinessName(e.target.value)}
          placeholder="Enter a business name"
        />
        <p style={{ margin: 0, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
          Business name must be between {MIN_NAME_LENGTH} and {MAX_NAME_LENGTH}
        </p>
      </Space>
    </Modal>
  );
};

export default FreelancerModeSetupModal;
