import axios from 'axios';

export default class MockDataService {
  public async getMockUser() {
    try {
      const response = await axios.get('https://randomuser.me/api/');
      const data = response.data.results[0];
      return {
        firstName: data.name.first,
        lastName: data.name.last,
        profilePictureUrl: data.picture.medium,
      };
    } catch (error) {
      console.log(error);
    }
  }
}
