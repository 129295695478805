import React from 'react';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

function NewlineText(props: { text: string }) {
  const text = props.text;
  const hashtagRegex = /#(\w+)/g;
  return (
    <React.Fragment>
      {text.split('\n').map(str => (
        <div
          dangerouslySetInnerHTML={{
            __html: str.replaceAll(
              hashtagRegex,
              `<span style="color: ${GLOBAL_THEME_COLOR.$hash_tag_color};">#$1</span>`
            ),
          }}></div>
      ))}
    </React.Fragment>
  );
}

export default NewlineText;
