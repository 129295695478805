import React, { useMemo, useState } from 'react';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { closeModal } from '@core/features';
import { Button, Divider, Input, Modal, Select, Space } from 'antd';
import { MailOutlined, PhoneOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { ReviewRequestPayload } from '@core/models';
import { CardSectionHeader, NumericInput } from '..';
import * as yup from 'yup';
import { PhoneCountryCodes } from '@constants/phone-country-codes';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

type Props = {};

const getDefaultValue = (): ReviewRequestPayload => {
  return {
    contact: '',
    fullName: '',
    phoneCode: '+61',
    phone: '',
  };
};

const validationSchema: Record<keyof ReviewRequestPayload, any> = {
  contact: yup.string().email('Enter a valid email'),
  fullName: yup.string().max(20, 'Invalid name length'),
  phone: yup.string().max(13, 'Invalid phone length'),
  phoneCode: yup.string(),
};

const CreateNewReviewRequestModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { createNewReviewRequestModal, extraParams } = useModalState();
  const onReviewRequestCreated = useMemo<(payload: ReviewRequestPayload) => void>(
    () => extraParams?.onReviewRequestCreated,
    [extraParams]
  );
  const [rowError, setRowError] = useState<ReviewRequestPayload>(getDefaultValue());
  const [reviewRequestPayload, setReviewRequestPayload] = useState<
    ReviewRequestPayload | undefined
  >(undefined);

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'createNewReviewRequestModal',
      })
    );
  };

  const handleReviewRequestPayload = async (field: keyof ReviewRequestPayload, value: any) => {
    const newError = rowError;
    try {
      await validationSchema[field].validate(value);
    } catch (error: any) {
      newError[field] = error.message;
    }
    setRowError(newError);
    setReviewRequestPayload({
      ...reviewRequestPayload,
      [field]: value,
    } as any);
  };

  const handleCreateReviewRequest = () => {
    if (onReviewRequestCreated && reviewRequestPayload) {
      onReviewRequestCreated(reviewRequestPayload);
      setReviewRequestPayload(getDefaultValue());
      handleModalClose();
    }
  };

  const handleEmailChange = async (value: string) => {
    handleReviewRequestPayload('contact', value);
  };

  const handleFirstNameChange = async (value: string) => {
    handleReviewRequestPayload('fullName', value);
  };

  const handlePhoneNumberChange = async (value: string) => {
    handleReviewRequestPayload('phone', value);
  };

  const handlePhoneCodeChange = async (value: string) => {
    handleReviewRequestPayload('phoneCode', value);
  };

  return (
    <Modal
      width={'100%'}
      title={'New Review Request'}
      open={createNewReviewRequestModal}
      footer={[
        <Button type={'primary'} className={'success-btn'} onClick={handleCreateReviewRequest}>
          <PlusOutlined /> Add request
        </Button>,
      ]}
      onCancel={handleModalClose}
      closable>
      <Divider />
      <Space direction="vertical" style={{ width: '100%' }} size={20}>
        <CardSectionHeader title="Email" />
        <Input
          required
          prefix={<MailOutlined />}
          status={!!rowError?.contact ? 'error' : ''}
          onChange={e => handleEmailChange(e.target.value)}
          placeholder={'Enter email'}
          value={reviewRequestPayload?.contact || ''}
        />
        {!!rowError?.contact && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_color }}>{rowError?.contact}</p>
        )}
        <CardSectionHeader title="Name" />
        <Input
          prefix={<UserOutlined />}
          status={!!rowError?.fullName ? 'error' : ''}
          onChange={e => handleFirstNameChange(e.target.value)}
          placeholder={'Enter customer name'}
          value={reviewRequestPayload?.fullName || ''}
        />
        {!!rowError?.fullName && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_color }}>{rowError?.fullName}</p>
        )}
        <CardSectionHeader title="Phone Code" />
        <Select
          defaultValue={'+61'}
          size="large"
          value={reviewRequestPayload?.phoneCode}
          style={{ width: '100%', marginTop: 5 }}
          onChange={value => handlePhoneCodeChange(value)}
          options={PhoneCountryCodes.map(phoneCode => ({
            value: phoneCode.dial_code,
            label: `${phoneCode.name} (${phoneCode.dial_code})`,
          }))}
        />
        <CardSectionHeader title="Phone" />
        <NumericInput
          prefixIcon={<PhoneOutlined />}
          status={!!rowError?.phone ? 'error' : ''}
          onChange={value => handlePhoneNumberChange(value)}
          placeholder={'Enter phone number'}
          value={reviewRequestPayload?.phone || ''}
        />
        {!!rowError.phone && (
          <p style={{ color: GLOBAL_THEME_COLOR.$error_color }}>{rowError?.phone}</p>
        )}
      </Space>
      {!reviewRequestPayload?.phone && !reviewRequestPayload?.contact && (
        <p style={{ color: GLOBAL_THEME_COLOR.$error_color }}>
          You must enter either email or SMS.
        </p>
      )}
    </Modal>
  );
};

export default CreateNewReviewRequestModal;
