import { StreamApiMethod } from '@services/stream-api.service';
import { create } from 'zustand';

export type StreamingPayload<T = any, P = any> = {
  apiMethod: StreamApiMethod<P>;
  payload: P;
  abortSignal?: AbortSignal | undefined;
  onFinishedCallback: () => Promise<void>;
  onDecodedData?: (data: T) => void;
  onInterrupted?: () => any;
};

export type StreamingTask<T = any, P = any> = StreamingPayload<T, P> & {
  id: string;
  name: string;
};

export enum StreamingTaskStatus {
  Pending = 'pending',
  InProgress = 'in-progress',
  Finished = 'finished',
  Failed = 'failed',
}

export const STREAM_UPLOAD_IMAGE_TASK = 'STREAM_UPLOAD_IMAGE_TASK';
export const STREAM_IMPORT_SQUARE_PRODUCTS_TASK = 'STREAM_IMPORT_SQUARE_PRODUCTS_TASK';

export const isTaskCancellable = (task: StreamingTask) =>
  [STREAM_UPLOAD_IMAGE_TASK].some(taskId => taskId === task.id);

export type StreamingMetadata = {
  status: StreamingTaskStatus;
  progress: number;
  totalProgress: number;
};

export interface StreamingTaskStore {
  tasks: StreamingTask[];
  taskMetadata: Record<string, StreamingMetadata>;
  incrTaskProgress: (taskId: string) => void;
  setTasks: (tasks: StreamingTask[]) => void;
  addTask: (task: StreamingTask, totalProgress: number) => void;
  setTaskMetadata: (taskMetadata: Record<string, StreamingMetadata>) => void;
  removeTask: (taskId: string) => void;
}

export const useStreamingTaskStore = create<StreamingTaskStore>()(set => ({
  tasks: [],
  taskMetadata: {},
  removeTask(taskId) {
    set(state => {
      delete state.taskMetadata[taskId];
      return {
        ...state,
        tasks: state.tasks.filter(task => task.id !== taskId),
        taskMetadata: {
          ...state.taskMetadata,
        },
      };
    });
  },
  addTask(task, totalProgress) {
    set(state => ({
      ...state,
      tasks: state.tasks.concat(task),
      taskMetadata: {
        ...state.taskMetadata,
        [task.id]: {
          status: StreamingTaskStatus.Pending,
          progress: 0,
          totalProgress,
        },
      },
    }));
  },
  incrTaskProgress(taskId: string) {
    set(state => ({
      ...state,
      taskMetadata: {
        ...state.taskMetadata,
        [taskId]: {
          ...state.taskMetadata[taskId],
          progress: state.taskMetadata[taskId].progress + 1,
        },
      },
    }));
  },
  setTaskMetadata(taskMetadata) {
    set(state => ({ ...state, taskMetadata }));
  },
  setTasks(tasks: StreamingTask[]) {
    set(state => ({ ...state, tasks }));
  },
}));
