import { create } from 'zustand';
import { BlogPageTabKey } from '@core/models';

export enum BlogOnboardingStage {
  AddBusinesWebsite = 'AddBusinesWebsite',
  CreateYourFirstBlog = 'CreateYourFirstBlog',
  PublishBlogPost = 'PublishBlogPost',
  GenerateBlogPost = 'GenerateBlogPost',
  AutoShareBlogPostToSocial = 'AutoShareBlogPostToSocial',
}

export interface BlogPageTourStore {
  onboardingStages: BlogOnboardingStage[];
  setOnboardingStages: (stages: BlogOnboardingStage[]) => void;
  addOnboardingStage: (stage: BlogOnboardingStage) => void;
  selectedTabKey: BlogPageTabKey;
  setSelectedTabKey: (key: BlogPageTabKey) => void;
}

export const useBlogPageTourStore = create<BlogPageTourStore>()(set => ({
  onboardingStages: [],
  setOnboardingStages(stages: BlogOnboardingStage[]) {
    set(() => ({
      onboardingStages: stages,
    }));
  },
  addOnboardingStage(stage: BlogOnboardingStage) {
    set(state => {
      if (state.onboardingStages.includes(stage)) return state;
      return {
        onboardingStages: state.onboardingStages.concat(stage),
      };
    });
  },
  selectedTabKey: BlogPageTabKey.BlogPosts,
  setSelectedTabKey(tabKey) {
    set(() => ({
      selectedTabKey: tabKey,
    }));
  },
}));
