export const PhoneCountryCodes = [
  // {
  //   name: 'Argentina',
  //   dial_code: '+54',
  //   code: 'AR',
  // },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'AU',
  },
  // {
  //   name: 'Austria',
  //   dial_code: '+43',
  //   code: 'AT',
  // },
  // {
  //   name: 'Bahamas',
  //   dial_code: '+1242',
  //   code: 'BS',
  // },
  // {
  //   name: 'Belgium',
  //   dial_code: '+32',
  //   code: 'BE',
  // },
  // {
  //   name: 'Brazil',
  //   dial_code: '+55',
  //   code: 'BR',
  // },
  {
    name: 'Canada & US',
    dial_code: '+1',
    code: 'CA',
  },
  // {
  //   name: 'Cambodia',
  //   dial_code: '+855',
  //   code: 'KH',
  // },
  // {
  //   name: 'China',
  //   dial_code: '+86',
  //   code: 'CN',
  // },
  // {
  //   name: 'Denmark',
  //   dial_code: '+45',
  //   code: 'DK',
  // },
  // {
  //   name: 'Egypt',
  //   dial_code: '+20',
  //   code: 'EG',
  // },
  // {
  //   name: 'Fiji',
  //   dial_code: '+679',
  //   code: 'FJ',
  // },
  // {
  //   name: 'Finland',
  //   dial_code: '+358',
  //   code: 'FI',
  // },
  // {
  //   name: 'France',
  //   dial_code: '+33',
  //   code: 'FR',
  // },
  // {
  //   name: 'Germany',
  //   dial_code: '+49',
  //   code: 'DE',
  // },
  // {
  //   name: 'Greece',
  //   dial_code: '+30',
  //   code: 'GR',
  // },
  // {
  //   name: 'Hong Kong',
  //   dial_code: '+852',
  //   code: 'HK',
  // },
  // {
  //   name: 'India',
  //   dial_code: '+91',
  //   code: 'IN',
  // },
  // {
  //   name: 'Indonesia',
  //   dial_code: '+62',
  //   code: 'ID',
  // },
  // {
  //   name: 'Ireland',
  //   dial_code: '+353',
  //   code: 'IE',
  // },
  // {
  //   name: 'Israel',
  //   dial_code: '+972',
  //   code: 'IL',
  // },
  // {
  //   name: 'Italy',
  //   dial_code: '+39',
  //   code: 'IT',
  // },
  // {
  //   name: 'Japan',
  //   dial_code: '+81',
  //   code: 'JP',
  // },
  // {
  //   name: 'Korea, Republic of South Korea',
  //   dial_code: '+82',
  //   code: 'KR',
  // },
  // {
  //   name: 'Liechtenstein',
  //   dial_code: '+423',
  //   code: 'LI',
  // },
  // {
  //   name: 'Luxembourg',
  //   dial_code: '+352',
  //   code: 'LU',
  // },
  // {
  //   name: 'Macao',
  //   dial_code: '+853',
  //   code: 'MO',
  // },
  // {
  //   name: 'Malaysia',
  //   dial_code: '+60',
  //   code: 'MY',
  // },
  // {
  //   name: 'Maldives',
  //   dial_code: '+960',
  //   code: 'MV',
  // },
  // {
  //   name: 'Malta',
  //   dial_code: '+356',
  //   code: 'MT',
  // },
  // {
  //   name: 'Mexico',
  //   dial_code: '+52',
  //   code: 'MX',
  // },
  // {
  //   name: 'Monaco',
  //   dial_code: '+377',
  //   code: 'MC',
  // },
  // {
  //   name: 'Netherlands',
  //   dial_code: '+31',
  //   code: 'NL',
  // },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'NZ',
  },
  // {
  //   name: 'Norway',
  //   dial_code: '+47',
  //   code: 'NO',
  // },
  // {
  //   name: 'Panama',
  //   dial_code: '+507',
  //   code: 'PA',
  // },
  // {
  //   name: 'Philippines',
  //   dial_code: '+63',
  //   code: 'PH',
  // },
  // {
  //   name: 'Poland',
  //   dial_code: '+48',
  //   code: 'PL',
  // },
  // {
  //   name: 'Portugal',
  //   dial_code: '+351',
  //   code: 'PT',
  // },
  // {
  //   name: 'Qatar',
  //   dial_code: '+974',
  //   code: 'QA',
  // },
  // {
  //   name: 'Russia',
  //   dial_code: '+7',
  //   code: 'RU',
  // },
  // {
  //   name: 'Saudi Arabia',
  //   dial_code: '+966',
  //   code: 'SA',
  // },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'SG',
  },
  // {
  //   name: 'South Africa',
  //   dial_code: '+27',
  //   code: 'ZA',
  // },
  // {
  //   name: 'Spain',
  //   dial_code: '+34',
  //   code: 'ES',
  // },
  // {
  //   name: 'Sweden',
  //   dial_code: '+46',
  //   code: 'SE',
  // },
  // {
  //   name: 'Switzerland',
  //   dial_code: '+41',
  //   code: 'CH',
  // },
  // {
  //   name: 'Taiwan',
  //   dial_code: '+886',
  //   code: 'TW',
  // },
  // {
  //   name: 'Thailand',
  //   dial_code: '+66',
  //   code: 'TH',
  // },
  // {
  //   name: 'Turkey',
  //   dial_code: '+90',
  //   code: 'TR',
  // },
  // {
  //   name: 'United Arab Emirates',
  //   dial_code: '+971',
  //   code: 'AE',
  // },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'GB',
  },
  // {
  //   name: 'Vietnam',
  //   dial_code: '+84',
  //   code: 'VN',
  // },
];
