/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Space, Tabs, TabsProps } from 'antd';
import ImageGalleryTab from '../components/ImageGalleryTab';
import SocialPostsTab from '../components/SocialPostTab';
import { MIDDLE_STYLE } from '@constants/responsive';
import ConnectSocialAccountsLocation from '@components/ConnectFacebookLocation';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import { FacebookPage, InstagramProfile, StudioTabKey } from '@core/models';
import { TbPhoto, TbStack } from 'react-icons/tb';
import ConnectFacebookButton from '@components/ConnectFacebookButton';
import LoadableContainer from '@components/LoadableContainer';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import SocialProfileButton from '@components/SocialProfileButton';
import { enqueueNotification, integrateToService, openModal } from '@core/features';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { deduplicateArrayByField, maybeEmptyArray } from '@utils/array.util';
import { facebookApiService, instagramApiService } from '@services/service-register';
import { fetchFacebookSelectedPages } from '@core/features/business/thunks';
import { fetchInstagramSelectedProfiles } from '@core/features/business/thunks/fetchInstagramSelectedProfiles.thunk';
import SocialIconButton from '@components/SocialIconButton';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import {
  useSocialAccountDispatcher,
  useSocialPostDispatcher,
  useSocialPosts,
  useStudioTourStore,
  useSubscriptionDispatcher,
} from '@core/hooks';
import { SubscriptionActivateButton } from '@components/index';

const SocialStudioScreen = () => {
  const dispatch = useAppDispatch();
  const { socialPosts } = useSocialPosts();
  const { running, setRunning, selectedTabKey, setSelectedTabKey } = useStudioTourStore();
  const { integration: social } = useAuthState();
  const { integration } = useBusinessState();
  const [facebookPageList, setFacebookPageList] = useState<FacebookPage[]>([]);
  const [instagramPageList, setInstagramPageList] = useState<InstagramProfile[]>([]);
  const { handleLoadFacebookConnect } = useSocialAccountDispatcher();
  const [loading, setLoading] = useState<boolean>(true);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const { preCheckedGenerate } = useSocialPostDispatcher();
  const { checkSocialStudioNotActivated } = useSubscriptionDispatcher();

  const handleOnTabChanged = (key: string) => {
    setSelectedTabKey(key as any);
  };

  const handleLogoutFacebookPage = async (profileId: string) => {
    setLoading(true);
    try {
      await facebookApiService.unselectFacebookPage(profileId);
      dispatch(
        integrateToService({
          platform: 'facebook_pages',
          profile: integration.facebook_pages.filter(page => page.id !== profileId),
        })
      );
      dispatch(
        enqueueNotification({
          name: 'Successfully disconnect page',
          description: 'Disconnect page sucessfully',
          type: 'Success',
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to disconnect page',
          description: 'Disconenct page unsucessfully',
          type: 'Error',
        })
      );
    }
    setLoading(false);
  };

  const handleDisconnectInstagramProfile = async (profileId: string) => {
    setLoading(true);
    try {
      await instagramApiService.unselectInstagramProfile(profileId);
      dispatch(
        integrateToService({
          platform: 'instagram_profiles',
          profile: integration.instagram_profiles.filter(profile => profile.id !== profileId),
        })
      );
      dispatch(
        enqueueNotification({
          name: 'Successfully disconnect profile',
          description: 'Disconnect profile sucessfully',
          type: 'Success',
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to disconnect profile',
          description: 'Disconenct profile unsucessfully',
          type: 'Error',
        })
      );
    }
    setLoading(false);
  };

  const tabs: TabsProps['items'] = [
    {
      key: StudioTabKey.ImageGallery,
      label: (
        <div style={{ ...MIDDLE_STYLE }}>
          <TbPhoto style={{ marginRight: 10 }} /> Image Gallery
        </div>
      ),
      children: <ImageGalleryTab />,
    },
    {
      key: StudioTabKey.UpcomingPosts,
      label: (
        <div style={{ ...MIDDLE_STYLE }}>
          <TbStack style={{ marginRight: 10 }} /> Posts
        </div>
      ),
      children: <SocialPostsTab />,
    },
  ];

  const isFacebookPageSelected = useCallback(
    (page: FacebookPage) => {
      return !!integration.facebook_pages?.find(_page => _page.id === page.id);
    },
    [integration.facebook_pages]
  );

  const isInstagramPageSelected = useCallback(
    (page: InstagramProfile) => {
      return !!integration.instagram_profiles?.find(_page => _page.id === page.id);
    },
    [integration.instagram_profiles]
  );

  const openConnectModal = () => {
    dispatch(
      openModal({
        modalName: 'selectSocialAccountsModal',
      })
    );
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunning(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const { facebookPages, instagramProfiles } = await facebookApiService.getFacebookPages();
        const currentFacebookPages = integration.facebook_pages || [];
        const mergedFacebookPages = [...facebookPages, ...currentFacebookPages];
        setFacebookPageList(deduplicateArrayByField<FacebookPage>(mergedFacebookPages, 'id'));

        const currentInstagramProfiles = integration.instagram_profiles || [];
        const mergedInstagramProfiles = [...instagramProfiles, ...currentInstagramProfiles];
        setInstagramPageList(
          deduplicateArrayByField<InstagramProfile>(mergedInstagramProfiles, 'id')
        );

        await handleLoadFacebookConnect();
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (integration) {
      init();
    }
  }, []);

  const mergedInstagramProfiles = useMemo(() => {
    return deduplicateArrayByField(
      [...maybeEmptyArray(instagramPageList), ...maybeEmptyArray(integration.instagram_profiles)],
      'id'
    );
  }, [instagramPageList, integration.instagram_profiles]);

  const mergedFacebookProfiles = useMemo(() => {
    return deduplicateArrayByField(
      [...maybeEmptyArray(facebookPageList), ...maybeEmptyArray(integration.facebook_pages)],
      'id'
    );
  }, [facebookPageList, integration.facebook_pages]);

  const totalNumberOfSocialAccounts = useMemo(() => {
    return mergedFacebookProfiles.length + mergedInstagramProfiles.length;
  }, [mergedFacebookProfiles, mergedInstagramProfiles]);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchFacebookSelectedPages());
      await dispatch(fetchInstagramSelectedProfiles());
    };
    init();
  }, []);

  return (
    <React.Fragment>
      <div className="screen-card">
        <div
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            marginBottom: 20,
            flexWrap: 'wrap',
          }}>
          <h2 className="airbnb-font">Social Studio</h2>
          <LoadableContainer isLoading={loading} loadComponent={<LoadingOutlined />}>
            {social.facebook ||
            maybeEmptyArray(integration.facebook_pages).length > 0 ||
            maybeEmptyArray(integration.instagram_profiles).length > 0 ? (
              <LoadableContainer
                isLoading={totalNumberOfSocialAccounts >= 6}
                loadComponent={
                  <Space>
                    {
                      <React.Fragment>
                        <SocialIconButton
                          onClick={() => {
                            openConnectModal();
                          }}
                          platform="facebook"
                          connectedAccounts={integration.facebook_pages.length}
                        />
                        <SocialIconButton
                          platform="instagram"
                          onClick={() => {
                            openConnectModal();
                          }}
                          connectedAccounts={integration.instagram_profiles.length}
                        />
                      </React.Fragment>
                    }
                  </Space>
                }>
                <Space>
                  {mergedFacebookProfiles.length > 0 || mergedInstagramProfiles.length > 0 ? (
                    <React.Fragment>
                      {mergedFacebookProfiles.map(facebookPage => (
                        <SocialProfileButton
                          pictureType="base64"
                          onClick={() => {
                            if (!isFacebookPageSelected(facebookPage)) openConnectModal();
                          }}
                          connected={isFacebookPageSelected(facebookPage)}
                          account={facebookPage}
                          platform="facebook"
                          picture={facebookPage.picture}
                          tooltipContent={
                            isFacebookPageSelected(facebookPage) && (
                              <div>
                                <Button
                                  style={{ width: '100%', marginTop: 10 }}
                                  onClick={() => handleLogoutFacebookPage(facebookPage.id)}>
                                  <span style={{ color: GLOBAL_THEME_COLOR.$error_color }}>
                                    Disconnect {facebookPage.name}
                                  </span>
                                </Button>
                              </div>
                            )
                          }
                        />
                      ))}
                      {mergedInstagramProfiles.map(instagramPage => (
                        <SocialProfileButton
                          pictureType="url"
                          onClick={() => {
                            if (!isInstagramPageSelected(instagramPage)) openConnectModal();
                          }}
                          account={instagramPage}
                          connected={isInstagramPageSelected(instagramPage)}
                          platform="instagram"
                          picture={instagramPage.profile_picture_url}
                          tooltipContent={
                            isInstagramPageSelected(instagramPage) && (
                              <React.Fragment>
                                <div>
                                  <Button
                                    style={{ width: '100%', marginTop: 10 }}
                                    onClick={() =>
                                      handleDisconnectInstagramProfile(instagramPage.id)
                                    }>
                                    <span style={{ color: GLOBAL_THEME_COLOR.$error_color }}>
                                      Disconnect {instagramPage.name}
                                    </span>
                                  </Button>
                                </div>
                              </React.Fragment>
                            )
                          }
                        />
                      ))}
                    </React.Fragment>
                  ) : (
                    <ConnectSocialAccountsLocation />
                  )}
                </Space>
              </LoadableContainer>
            ) : (
              <ConnectFacebookButton />
            )}
          </LoadableContainer>
        </div>
        {checkSocialStudioNotActivated(socialPosts) && (
          <Alert
            showIcon
            type="warning"
            message={
              <span style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                You're almost there! Activate the free trial before Amazely can publish your posts
                <SubscriptionActivateButton />
              </span>
            }
          />
        )}
        <Tabs
          defaultActiveKey={selectedTabKey}
          activeKey={selectedTabKey}
          items={tabs}
          onChange={handleOnTabChanged}
        />
      </div>
      <Joyride
        continuous
        run={running}
        callback={handleJoyrideCallback}
        stepIndex={currentStepIndex}
        hideCloseButton
        disableOverlay={!running}
        steps={[
          {
            content: (
              <div>
                <h3>
                  Photos uploaded!
                  <br /> Let's generate new social posts
                </h3>
                <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                  After uploading photos to the gallery, go to <span>Posts</span> tab to generate
                  new social posts
                </p>
                <Button
                  type={'primary'}
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={() => {
                    handleOnTabChanged(StudioTabKey.UpcomingPosts);
                    setTimeout(() => {
                      setCurrentStepIndex(step => step + 1);
                    }, 500);
                  }}>
                  Go to Posts tab <ArrowRightOutlined style={{ marginLeft: 10 }} />
                </Button>
                <Button
                  style={{ width: '100%', marginTop: 10 }}
                  onClick={() => {
                    setRunning(false);
                  }}>
                  Generate later
                </Button>
              </div>
            ),
            hideFooter: true,
            placement: 'center',
            target: 'body',
          },
          {
            content: (
              <div>
                <h3>Personalize your brand voice with AI</h3>
                <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                  Provide information about your business to personalize post content.
                </p>
                <Button
                  type={'primary'}
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={() => {
                    const generatePostsButton = document.getElementById('generate-posts-button');
                    if (generatePostsButton) generatePostsButton.scrollIntoView();
                    setCurrentStepIndex(step => step + 1);
                  }}>
                  Next <ArrowRightOutlined style={{ marginLeft: 10 }} />
                </Button>
              </div>
            ),
            hideFooter: true,
            target: '#customize-ai-profile',
          },
          {
            content: (
              <div>
                <h3>Generate social posts for a month</h3>
                <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                  To craft multiple social posts for a month easily, you can click on the Generate
                  button.
                </p>
                <Button
                  type={'primary'}
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={async () => {
                    const generatePostsButton = document.getElementById('generate-posts-button');
                    const { isPhotoSufficient, isGoodReviewSufficient, hasSystemMadeSocialPosts } =
                      await preCheckedGenerate(socialPosts);
                    if (generatePostsButton) generatePostsButton.click();
                    if (isPhotoSufficient && isGoodReviewSufficient && !hasSystemMadeSocialPosts) {
                      setCurrentStepIndex(step => step + 1);
                    } else {
                      setRunning(false);
                    }
                  }}>
                  Generate social posts
                </Button>
              </div>
            ),
            hideFooter: true,
            target: '#generate-posts-button',
          },
          {
            content: (
              <div>
                <h3>Your social posts are being generated. Please wait!</h3>
                <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                  After all the steps are processed, your social posts will be generated and
                  scheduled for this month.
                </p>
                <Button
                  type={'primary'}
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={() => {
                    setCurrentStepIndex(step => step + 1);
                  }}>
                  Next
                </Button>
              </div>
            ),
            hideFooter: true,
            target: `#social-month-container`,
          },
        ]}
        disableScrolling
        styles={{
          overlay: { height: '100%' },
          options: {
            zIndex: 10000,
          },
        }}
      />
    </React.Fragment>
  );
};

export default SocialStudioScreen;
