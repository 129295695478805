import { FacebookPage, FacebookPost, InstagramProfile } from '@core/models';
import axios from 'axios';

export default class FacebookApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}`;

  public async getFacebookProfile(): Promise<any> {
    try {
      const response = await axios.get(`${this.url}/users/facebook/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getFacebookPages(): Promise<{
    facebookPages: FacebookPage[];
    instagramProfiles: InstagramProfile[];
  }> {
    try {
      const response = await axios.get(`${this.url}/users/facebook/pages`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getFacebookPagePosts(pageId: string): Promise<FacebookPost[]> {
    try {
      const response = await axios.get(`${this.url}/location/facebook/pages/${pageId}/posts`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getLocationFacebookSelectedPages(): Promise<FacebookPage[]> {
    try {
      const response = await axios.get(`${this.url}/location/facebook/pages`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getFacebookPagesByLocationId(locationId: string): Promise<FacebookPage[]> {
    try {
      const response = await axios.get(`${this.url}/location/${locationId}/facebook/pages`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async selectFacebookPage(page: FacebookPage): Promise<any> {
    try {
      const response = await axios.post(`${this.url}/location/facebook/pages`, page, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async unselectFacebookPage(profileId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.url}/location/facebook/pages/${profileId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async logoutFacebook(): Promise<void | undefined> {
    try {
      const response = await axios.post(
        `${this.url}/auth/facebook/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
