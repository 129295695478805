import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { RootState } from '@core/configureStore';
import { fetchCurrentUser } from '@core/features/auth/thunks';

export const selectLocation = createAsyncThunk(
  'business/selectLocation',
  async (locationName: string, thunkApi) => {
    try {
      const state: RootState = thunkApi.getState() as RootState;
      const selectedLocation = state.business.locations.find(l => l.name === locationName);
      if (selectedLocation && state.auth.user) {
        await heroApiService.selectLocation(
          selectedLocation.accountId ?? '',
          selectedLocation.name
        );
        await thunkApi.dispatch(fetchCurrentUser());
        thunkApi.dispatch(
          enqueueNotification({
            name: `Location ${selectedLocation.title} selected`,
            description: 'Successfully select location',
            type: 'Success',
          } as SnackbarItem)
        );
      }
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to select location',
          description: 'There was error selecting locations. Please try again',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(selectLocation);

export const selectLocationReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
