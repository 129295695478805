import React from 'react';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { ArrowRightOutlined } from '@ant-design/icons';

interface Props {
  topics: string[];
  onTopicClicked: (topic: string) => void;
}

const TopicSuggestionListContainer: React.FC<Props> = ({ topics, onTopicClicked }) => {
  return (
    <React.Fragment>
      {topics.map((suggestion, index) => (
        <div
          className={'topic-suggestion'}
          onClick={() => onTopicClicked(suggestion)}
          color={GLOBAL_THEME_COLOR.$light_highlight_color}
          style={{
            cursor: 'pointer',
            margin: '5px',
            padding: '5px 0px',
            borderBottom:
              index === topics.length - 1
                ? 'unset'
                : `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            fontSize: 'smaller',
          }}>
          <ArrowRightOutlined
            style={{
              color: GLOBAL_THEME_COLOR.$highlight_color,
              marginRight: 10,
            }}
          />{' '}
          {suggestion}
        </div>
      ))}
    </React.Fragment>
  );
};

export default TopicSuggestionListContainer;
