/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  BRAND_LOGO_CONSTRAST,
  BRAND_NAME,
  GLOBAL_THEME_COLOR,
  MIDDLE_STYLE,
  STRIPE_BOX_SHADOW,
} from '../constants';
import { Button, Divider, Layout, notification, Skeleton, Space } from 'antd';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import moment from 'moment';
import ReactConfetti from 'react-confetti';
import LoadableContainer from '../components/LoadableContainer';
import { useGenerationDataStore, usePitchDispatcher } from '@core/hooks';
import { BusinessSocialPostCard, ShowcaseReviewSocialPost } from '@components/.';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { GeneratorEngineStatus } from '@core/models';
import { SocialPlatformType } from '@components/BusinessSocialPostCard';
import { DATE_FORMAT } from '@utils/date.util';
import FacebookInAppHandler from '@components/FacebookInAppHandler';
import { publicRouteService } from '@services/service-register';
import {
  PitchAutoReviewResponseSection,
  PitchLocalReviewRankingMap,
  PitchSocialPostsSection,
  PitchSummaryToActionSection,
} from '@components/pitch';

type Props = {};

const GenerateScreen = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { isTablet, isMobile } = useBreakpoint();
  const { businessData, engineStatus } = useGenerationDataStore();
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const confettiRef = useRef<HTMLDivElement | null>(null);
  const { handleActivate } = usePitchDispatcher();
  let isDemo = window.location.href.toString().includes('demo');

  const handleActivateWithHandler = () => {
    handleActivate({
      facebookAppHandler: () => setOpen(true),
    });
  };

  const handleLearnMoreAboutAmazely = async () => {
    try {
      await publicRouteService.sendSlackNotification(
        `${businessData?.name} [👀 Learning about Amazely...]`,
        businessData?.types?.[0] || ''
      );
    } catch (error) {
      console.log(error);
    }
    window.open('https://amazely.co/');
  };

  useEffect(() => {
    const section = (() => {
      switch (engineStatus) {
        case GeneratorEngineStatus.GenerateBlogPost:
          return document.getElementById('generated-blog');
        case GeneratorEngineStatus.GenerateReviewResponse:
          return document.getElementById('generated-reviews');
        case GeneratorEngineStatus.GenerateSocialPost:
          return document.getElementById('generated-social-post');
        case GeneratorEngineStatus.Finished:
          return document.getElementById('generate-screen');
        case GeneratorEngineStatus.CollectShowcaseReviews:
          return document.getElementById('generate-review-showcase');
        default:
          return undefined;
      }
    })();
    section?.scrollIntoView({ behavior: 'smooth' });
  }, [engineStatus, businessData]);

  useEffect(() => {
    if (!confettiRef.current) return;
    setHeight(confettiRef.current.clientHeight);
    setWidth(confettiRef.current.clientWidth);
  }, [confettiRef]);

  useEffect(() => {
    if (engineStatus === GeneratorEngineStatus.Finished) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  }, [engineStatus]);
  const [api, contextHolder] = notification.useNotification();

  const notify = () => {
    api.open({
      message: 'Please sign in to setup your business first.',
    });
  };
  return (
    <FacebookInAppHandler open={open} setOpen={setOpen}>
      <div
        ref={confettiRef}
        style={{
          backgroundColor: '#f0f2f5',
          maxHeight: '100vh',
          overflow: 'auto',
        }}>
        {showConfetti && <ReactConfetti numberOfPieces={1000} width={width} height={height} />}
        <Layout>
          <Layout.Header
            style={{
              position: 'fixed',
              zIndex: 100,
              width: '100%',
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
            }}>
            <div style={{ ...MIDDLE_STYLE }}>
              <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 40 }} />{' '}
              {!isTablet && (
                <span
                  style={{
                    color: GLOBAL_THEME_COLOR.$text_color,
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginLeft: 10,
                  }}>
                  {BRAND_NAME.toUpperCase()}
                </span>
              )}
            </div>
            <Space style={{ ...MIDDLE_STYLE }}>
              <Button
                onClick={handleLearnMoreAboutAmazely}
                size="large"
                style={{ cursor: 'pointer', ...MIDDLE_STYLE }}>
                Learn more
              </Button>
              <Button
                onClick={handleActivateWithHandler}
                type="primary"
                size="large"
                style={{ cursor: 'pointer', ...MIDDLE_STYLE }}>
                {!isMobile && 'Getting started'}
                <ArrowRightOutlined style={{ marginLeft: isMobile ? 0 : 10 }} />
              </Button>
            </Space>
          </Layout.Header>
          <Layout.Content style={{ overflowX: 'hidden' }}>
            <div
              id="generate-screen"
              className="section-glassy"
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                padding: '150px 50px 40px 50px',

                width: '100vw',
                overflow: 'hidden',
                position: 'relative',
              }}>
              <img
                src="jumbotron/builder-background-purple.webp"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                }}
              />
              <div
                style={{
                  zIndex: 10,
                  ...MIDDLE_STYLE,
                  flexDirection: 'column',
                  textAlign: 'center',
                  color: GLOBAL_THEME_COLOR.$text_color_contrast,
                }}>
                <h1
                  style={{
                    margin: 0,
                    fontSize: isTablet ? '1.5rem' : '3.5rem',
                    fontFamily: 'Rubik, sans-serif',
                  }}>
                  {businessData.name}
                </h1>
                <h2
                  style={{
                    fontFamily: 'Rubik, sans-serif',
                    margin: '20px 0px',
                    color: GLOBAL_THEME_COLOR.$text_color_contrast,
                    fontWeight: 'normal',
                    fontSize: isTablet ? '1rem' : '1.5rem',
                    borderBottom: '1px dashed #ffffff80',
                  }}>
                  {/*{businessData.location}*/}
                  {/*This is just a high level demo.*/}
                  This is just a glimpse of Amazely features.
                </h2>
                <Button
                  onClick={handleActivateWithHandler}
                  type="primary"
                  size="large"
                  style={{ cursor: 'pointer', padding: 24, borderRadius: 50, ...MIDDLE_STYLE }}>
                  <img
                    src={BRAND_LOGO_CONSTRAST}
                    alt={BRAND_NAME}
                    style={{ width: 30, marginRight: 6 }}
                  />
                  {/*Setup your business for free &nbsp;*/}
                  Sign in for a full app experience &nbsp;
                  <ArrowRightOutlined />
                </Button>
                <br />
                <br />

                {!isDemo && (
                  <span>
                    <div style={{ fontWeight: 'bold', fontSize: 15 }}>Trusted by</div>
                    <div
                      style={{
                        borderBottom: '1px solid orange',
                        fontWeight: 'bold',
                        fontSize: 18,
                      }}>
                      800+ businesses globally
                    </div>
                    <br /> <br />
                  </span>
                )}
                {isDemo && (
                  <div
                    style={{
                      color: 'white',
                      // fontWeight:'bold',
                      marginTop: 20,
                      fontSize: 15,
                      ...MIDDLE_STYLE,
                    }}>
                    Made by
                    <div style={{ ...MIDDLE_STYLE, marginLeft: 5 }}>
                      <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 30 }} />{' '}
                      <span
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginLeft: 5,
                        }}>
                        {BRAND_NAME}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                ...MIDDLE_STYLE,
                padding: isMobile ? '50px 20px' : '30px 10%',
                flexDirection: 'column',
              }}>
              <PitchLocalReviewRankingMap />
              <LoadableContainer
                isLoading={
                  businessData.socialPosts.length === 0 &&
                  engineStatus === GeneratorEngineStatus.Finished
                }
                loadComponent={<></>}>
                <PitchSocialPostsSection />
                <Divider />
              </LoadableContainer>
              <LoadableContainer
                isLoading={
                  businessData.reviews.length === 0 &&
                  engineStatus === GeneratorEngineStatus.Finished
                }
                loadComponent={<></>}>
                <div id="generated-reviews">
                  <PitchAutoReviewResponseSection />
                </div>
                <Divider />
              </LoadableContainer>
              <LoadableContainer
                isLoading={
                  businessData.showcaseReviewSocialPosts.length === 0 &&
                  engineStatus === GeneratorEngineStatus.Finished
                }
                loadComponent={<></>}>
                <div id="generate-review-showcase">
                  <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
                    <h2 className="pitch_title">Review Marketing</h2>
                    <div className="pitch_subtitle">
                      Amazely boosts sales & increases customer trust by showcasing the best 5-star
                      reviews on your website and social channels, every week.
                    </div>
                  </div>
                  <LoadableContainer
                    isLoading={businessData.showcaseReviewSocialPosts.length === 0}
                    loadComponent={<Skeleton />}>
                    <div
                      style={{
                        flexWrap: 'wrap',
                        marginTop: 20,
                        ...MIDDLE_STYLE,
                      }}>
                      {businessData.showcaseReviews.map((review, index) => (
                        <ShowcaseReviewSocialPost
                          type={
                            (['facebook', 'instagram', 'pinterest'] as SocialPlatformType[])[
                              index % 3
                            ]
                          }
                          socialPost={businessData.showcaseReviewSocialPosts[index]}
                          imageHeight={1000}
                          imageWidth={1000}
                          review={review}
                          businessName={businessData.name}
                        />
                      ))}
                    </div>
                  </LoadableContainer>
                </div>
              </LoadableContainer>
              <Divider />
              <div id="generated-blog">
                <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
                  <h2 className="pitch_title">Automated Blogging SEO</h2>
                  <div className="pitch_subtitle">
                    Amazely automatically crafts SEO optimized contents to drive up your search
                    ranking and attract more organic leads for your business.
                  </div>
                </div>
                <div style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
                  <div
                    className="blog-section"
                    style={{ position: 'relative', paddingBottom: 100 }}>
                    {contextHolder}
                    <Button
                      onClick={notify}
                      size="large"
                      type="primary"
                      style={{ position: 'absolute', bottom: 10, right: 10, ...MIDDLE_STYLE }}>
                      <EditOutlined /> Edit / Approve
                    </Button>
                    <div
                      style={{
                        top: -15,
                        position: 'absolute',
                        backgroundColor: GLOBAL_THEME_COLOR.$success_color,
                        width: 'fit-content',
                        color: 'white',
                        padding: '5px 10px',
                        borderRadius: 5,
                        fontWeight: 600,
                      }}>
                      Scheduled for {moment().add('1', 'month').format(DATE_FORMAT)}
                    </div>
                    <div
                      className="reactMarkdown"
                      style={{ overflow: 'auto', lineBreak: 'normal' }}>
                      <ReactMarkdown>{businessData.blogPost}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <PitchSummaryToActionSection handleActivate={handleActivateWithHandler} />
          </Layout.Content>
        </Layout>
      </div>
    </FacebookInAppHandler>
  );
};

export default GenerateScreen;
