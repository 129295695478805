import {
  BusinessSocialPost,
  LocalLocation,
  SocialPost,
  SocialPostMadeBy,
  SocialPostType,
  StudioPhotoSource,
} from '@core/models';
import { usePublicRouteService } from './usePublicRouteService';
import { makeid } from '@utils/string.util';
import {
  heroApiService,
  publicRouteService,
  socialStudioApiService,
} from '@services/service-register';
import { Buffer } from 'buffer';
import { useAuthState, useReviewState } from '@core/configureStore';
import React from 'react';
import { PhotoImageDataItem } from '@core/features';
import moment, { Moment } from 'moment';
import { starRatingToNumber } from '@utils/type.util';
import { getSocialPostRangeSchedules } from '@utils/date.util';

type BusinessPhotoItem = {
  data: string;
  id: string;
};

export const useSocialPostDispatcher = () => {
  const { user } = useAuthState();
  const { reviewResponse } = useReviewState();
  const { getPlaceDetail, getGooglePhotoData } = usePublicRouteService();

  const getNextScheduleDate = async (type: SocialPostType): Promise<number | undefined> => {
    const { showcaseSchedules, socialPostSchedules } = await getSocialPostRangeSchedules(
      moment(),
      1
    );
    return type === SocialPostType.Default
      ? socialPostSchedules[Symbol.iterator]().next().value
      : showcaseSchedules[Symbol.iterator]().next().value;
  };

  const generateClientSideSocialPosts = React.useCallback(
    async (
      startDate: Moment,
      photos: PhotoImageDataItem[],
      onPostGeneratedCallback?: (
        totalSocialPosts: BusinessSocialPost[],
        createdSocialPost: BusinessSocialPost
      ) => void
    ) => {
      if (!user?.selected_location) return;
      const _socialPosts: BusinessSocialPost[] = [];

      const { socialPostSchedules } = await getSocialPostRangeSchedules(startDate, 2);
      const scheduleIterator = socialPostSchedules[Symbol.iterator]();

      for (const photo of photos) {
        const socialPostContent: string = await publicRouteService.generateSocialPostContent({
          businessName: user.selected_location.title,
          businessIndustry: user.selected_location.primary_category,
        });
        const createdSocialPost: BusinessSocialPost = {
          id: makeid(5),
          caption: socialPostContent,
          photo: {
            data: photo.data
              ? `data:image/jpeg;base64,${Buffer.from(photo.data).toString('base64')}`
              : '',
            description: '',
            tags: [],
          },
          scheduledAt: scheduleIterator.next().value,
        };
        onPostGeneratedCallback && onPostGeneratedCallback(_socialPosts, createdSocialPost);
        _socialPosts.push(createdSocialPost);
      }
      return _socialPosts;
    },
    [user]
  );

  const fetchGoogleBusinessPhotos = async (location: LocalLocation | undefined) => {
    if (!location) return [];
    const placeId = await heroApiService.getLocationPlaceId(location?.location_id);
    if (!placeId) throw new Error('No place ID found');
    const placeData = await getPlaceDetail(placeId);
    if (!placeData) throw new Error('No place found');
    if (placeData.result?.reviews?.length === 0 || placeData.result?.photos?.length === 0)
      throw new Error('No data found for this location');
    const photoChunks = placeData.result.photos || [];
    const fetchedPhotos: BusinessPhotoItem[] = [];
    for (const photo of photoChunks) {
      const photoData = await getGooglePhotoData(photo.photo_reference, photo.width, photo.height);
      fetchedPhotos.push({
        data: photoData,
        id: makeid(10),
      });
    }
    return fetchedPhotos;
  };

  const isSystemMadeSocialPost = (socialPost: SocialPost) =>
    socialPost.made_by === SocialPostMadeBy.System && socialPost.type === SocialPostType.Default;

  const isSystemMadeShowcaseReviews = (socialPost: SocialPost) =>
    socialPost.made_by === SocialPostMadeBy.System &&
    socialPost.type === SocialPostType.ShowcaseReview;

  const preCheckedGenerate = async (socialPosts: SocialPost[]) => {
    const systemMadeSocialPosts = socialPosts.filter(isSystemMadeSocialPost);
    const systemMadeShowcaseReviews = socialPosts.filter(isSystemMadeShowcaseReviews);

    const isGoodReviewSufficient = reviewResponse.reviews.some(
      review => starRatingToNumber(review.starRating) >= 4 && review.comment?.length > 40
    );
    const isPhotoSufficient = await socialStudioApiService.checkIfEnoughGalleryImages();
    const studioPhotos = await socialStudioApiService.getStudioPhotosByLocation();

    const hasNoStudioPhotos =
      studioPhotos.filter(
        studioPhoto =>
          studioPhoto.source === StudioPhotoSource.SquareProductPhoto ||
          studioPhoto.source === StudioPhotoSource.Image
      ).length === 0;
    const hasSystemMadeSocialPosts = systemMadeSocialPosts.length > 0;
    const hasSystemMadeShowcaseReviews = systemMadeShowcaseReviews.length > 0;
    return {
      isGoodReviewSufficient,
      hasSystemMadeSocialPosts,
      hasSystemMadeShowcaseReviews,
      hasNoStudioPhotos,
      systemMadeShowcaseReviews,
      isPhotoSufficient,
    };
  };

  const getLatestPublishedDate = (socialPosts: SocialPost[], defaultNextPublishDate: number) => {
    /** The latest published date retrieved from the list of provided social posts */
    let latestPublishDate = defaultNextPublishDate;
    for (const post of socialPosts) {
      if (post.publish_at) {
        const publishAtValue = moment(post.publish_at).unix();
        /** Find the max value of published at (latest published date) */
        if (publishAtValue > latestPublishDate) {
          latestPublishDate = publishAtValue;
        }
      }
    }
    return latestPublishDate;
  };

  return {
    isSystemMadeSocialPost,
    isSystemMadeShowcaseReviews,
    preCheckedGenerate,
    fetchGoogleBusinessPhotos,
    generateClientSideSocialPosts,
    getNextScheduleDate,
    getLatestPublishedDate,
  };
};
