import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { AuthReducerProps } from '../type';

export const checkSupportSession = createAsyncThunk('auth/checkSupportSession', async () => {
  const result = await heroApiService.checkSupportSession();
  return result;
});

const [fulfilled, pending, rejected] = getSideEffectState(checkSupportSession);

export const checkSupportSessionReducer = {
  [fulfilled]: (state, action) => {
    state.isAuthLoading = false;
    state.isSupportSession = action.payload;
  },
  [rejected]: (state, action) => {
    state.isAuthLoading = false;
  },
  [pending]: (state, action) => {
    state.isAuthLoading = true;
  },
} as ExtraReducer<AuthReducerProps>;
