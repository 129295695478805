import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { facebookApiService } from '@services/service-register';

export const fetchFacebookSelectedPages = createAsyncThunk(
  'business/fetchFacebookSelectedPages',
  async (_, thunkApi) => {
    try {
      return facebookApiService.getLocationFacebookSelectedPages();
    } catch (error: any) {
      return undefined;
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchFacebookSelectedPages);

export const fetchFacebookSelectedPagesReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.integration.facebook_pages = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.integration.facebook_pages = [];
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
