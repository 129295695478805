/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const getAmazelyStudioBucket = () => `amazely-studio-${process.env.NODE_ENV}`;

const SocialPostImage = ({ mediaId, style }: { mediaId: string; style?: React.CSSProperties }) => {
  return (
    <img
      loading="lazy"
      style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover', ...style }}
      src={`https://s3.amazonaws.com/${getAmazelyStudioBucket()}/${mediaId}`}
      alt={mediaId}
    />
  );
};

export default SocialPostImage;
