import { Card, CardProps } from 'antd';
import React from 'react';

type Props = CardProps & {
  children: React.ReactElement | React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
};

const ContainerCard = ({ children, style, ...cardProps }: Props) => {
  return (
    <Card
      className="tablespace"
      style={{
        padding: '20px 30px',
        height: 'fit-content',
        minHeight: '100vh',
        borderRadius: 15,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        ...style,
      }}
      {...cardProps}>
      {children}
    </Card>
  );
};

export default ContainerCard;
