import { PhotoImageDataItem } from '@core/features';
import { useState } from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';
import { SquarePhotoImageData, StudioPhoto } from '@core/models';
import { base64ToImageItem } from '@utils/file.util';
import { publicRouteService } from '@services/service-register';

export const useFileUploader = (singleFile: boolean) => {
  const [fileImageItems, setFileImageItems] = useState<PhotoImageDataItem[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleSetFileImageItem = (imageItem: PhotoImageDataItem) => {
    if (singleFile) {
      setFileImageItems([imageItem]);
    } else {
      setFileImageItems(urls => urls.concat([imageItem]));
    }
  };

  const onGoogleBusinessPhotosUploaded = (photos: { id: string; data: string }[]) => {
    setUploading(true);
    for (let i = 0; i < photos.length; i++) {
      const pic = photos[i];
      const fileImageItem = base64ToImageItem(`data:image/jpeg;base64,${pic.data}`);
      handleSetFileImageItem(fileImageItem);
    }
    setUploading(false);
  };

  const onSquareIntegrationPhotosUploaded = async (photos: SquarePhotoImageData[]) => {
    setUploading(true);
    for (let i = 0; i < photos.length; i++) {
      const pic = photos[i];
      const response = await publicRouteService.getPhotoBase64Data(pic.url);
      const fileImageItem = base64ToImageItem(`data:image/jpeg;base64,${response}`);
      handleSetFileImageItem({
        ...fileImageItem,
        description: pic.description,
      });
    }
    setUploading(false);
  };

  const onUnsplashPhotoUploaded = async (pics: any[]) => {
    setUploading(true);
    for (let i = 0; i < pics.length; i++) {
      const pic = pics[i];
      const response = await axios.get<ArrayBuffer>(pic.urls.full, {
        responseType: 'arraybuffer',
      });
      const fileImageItem = base64ToImageItem(
        `data:image/jpeg;base64,${Buffer.from(response.data).toString('base64')}`
      );
      handleSetFileImageItem(fileImageItem);
    }
    setUploading(false);
  };

  const converPexelDataToPhotoImage = async (pic: any) => {
    const response = await axios.get<ArrayBuffer>(pic.src.original, {
      responseType: 'arraybuffer',
    });
    const fileImageItem = base64ToImageItem(
      `data:image/jpeg;base64,${Buffer.from(response.data).toString('base64')}`
    );
    return fileImageItem;
  };

  const onPexelsPhotoUploaded = async (pics: any[]) => {
    setUploading(true);
    for (let i = 0; i < pics.length; i++) {
      const pic = pics[i];
      const fileImageItem = await converPexelDataToPhotoImage(pic);
      handleSetFileImageItem(fileImageItem);
    }
    setUploading(false);
  };

  const onPhotoGalleryUploaded = async (photos: StudioPhoto[]) => {
    setUploading(true);
    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      handleSetFileImageItem({
        url: photo.url,
        data: undefined,
        name: photo.name,
        id: photo.id,
        extension: photo.extension,
        _studioPhotoId: photo.id,
      });
    }
    setUploading(false);
  };

  return {
    uploading,
    setUploading,
    fileImageItems,
    setFileImageItems,
    handleSetFileImageItem,
    onGoogleBusinessPhotosUploaded,
    onPhotoGalleryUploaded,
    onUnsplashPhotoUploaded,
    onPexelsPhotoUploaded,
    onSquareIntegrationPhotosUploaded,
    converPexelDataToPhotoImage,
  };
};
