import { BusinessSocialPost, GoogleSmartReview, PlaceData } from './google-place';

export enum GeneratorEngineStatus {
  NotStarted,
  Running,
  RankingBusinessInLocalArea,
  GenerateSocialPost,
  GenerateReviewResponse,
  CollectBusinessReviews,
  CollectShowcaseReviews,
  GenerateBlogPost,
  Finished,
  NotEnoughDataNotify,
}

export enum AutoReviewGeneratorEngineStatus {
  NotStarted,
  Running,
  CollectBusinessReviews,
  GenerateReviewResponse,
  Finished,
  NotEnoughDataNotify,
}

export enum QrCodeGeneratorEngineStatus {
  NotStarted,
  Running,
  GenerateQrCode,
  RankingBusinessInLocalArea,
  GenerateReviewResponse,
  GenerateSocialPosts,
  Finished,
}

export interface GenerationData extends Partial<PlaceData> {
  name: string;
  latitude: number;
  longtitude: number;
  location: string;
  type: string;
  reviews: GoogleSmartReview[];
  showcaseReviews: GoogleSmartReview[];
  blogPost: string;
  socialPosts: BusinessSocialPost[];
  showcaseReviewSocialPosts: BusinessSocialPost[];
}

export interface GenerationDataStore {
  stageLoading: Record<string, boolean>;
  engineStatus: GeneratorEngineStatus;
  autoReviewEngineStatus: AutoReviewGeneratorEngineStatus;
  qrCodeEngineStatus: QrCodeGeneratorEngineStatus;
  nearbyBusinesses: PlaceData[];
  businessData: GenerationData;
  setStageLoading: (stage: string, loading: boolean) => void;
  setNearbyBusinesses: (nearbyBusinesses: PlaceData[]) => void;
  setEngineState: (engineState: GeneratorEngineStatus) => void;
  setAutoReviewEngineState: (engineState: AutoReviewGeneratorEngineStatus) => void;
  setQrCodeEngineState: (engineState: QrCodeGeneratorEngineStatus) => void;
  setGenerationData: (updatedData: Partial<GenerationData>) => void;
}
