import EventEmitter from 'events';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const emitCloseStream = (taskId: string) => {
  return `${taskId}::CLOSE_STREAM`;
};

export const GlobalEventEmitterContext = React.createContext<{
  inner: EventEmitter | undefined;
}>({
  inner: undefined,
});

const GlobalEventEmitterProvider = ({ children }: Props) => {
  return (
    <GlobalEventEmitterContext.Provider
      value={{
        inner: new EventEmitter(),
      }}>
      {children}
    </GlobalEventEmitterContext.Provider>
  );
};

export default GlobalEventEmitterProvider;
