import React from 'react';

interface DescriptionItemProps {
  title: string;
  content?: React.ReactNode;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
}

const DescriptionItem = ({ title, content, style, titleStyle }: DescriptionItemProps) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 20,
      ...style,
    }}>
    <p style={{ fontWeight: 'bold', marginRight: 10, ...titleStyle }}>{title}:</p>
    {content}
  </div>
);

export default DescriptionItem;
