/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Col, Divider, Input, Row, Select, Skeleton, Space, Table } from 'antd';
import {
  useAppDispatch,
  useAppNavigation,
  useAuthState,
  useBreakpoint,
  useExtendedRequests,
} from '@core/.';
import {
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  SaveOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { askForReview, fetchReviewRequests } from '@core/features/review-request/thunks';
import {
  defaultInvitationEmail,
  defaultFollowupEmail,
  defaultInvitationSms,
} from '@constants/request-template';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { LoadableContainer } from '..';
import { enqueueNotification, openModal, SnackbarItem } from '@core/features';
import NumericInput from '@components/NumericInput';
import { BulkRequestCsvInfo } from '@components/UploadCSVModal';
import { PhoneCountryCodes } from '@constants/phone-country-codes';
import ContainerCard from '@components/ContainerCard';
import { ReviewRequestPayload } from '@core/models';

type CreatedColumn = 'email' | 'name' | 'phone' | 'phoneCode' | 'action';
type RequestCreationColumnType = {
  title: string;
  dataIndex: CreatedColumn;
  key: CreatedColumn;
  width?: string | number;
};

type RequestCreationColumnValue = Record<CreatedColumn, any>;

const validationSchema: RequestRow = {
  email: yup.string().email('Enter a valid email'),
  name: yup.string().max(20, 'Invalid name length'),
  phone: yup.string().max(13, 'Invalid phone length'),
};

const columns: RequestCreationColumnType[] = [
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email',
    width: '32%',
  },
  {
    title: 'NAME (Optional)',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'PHONE CODE (Optional)',
    key: 'phoneCode',
    dataIndex: 'phoneCode',
  },
  {
    title: 'PHONE NUMBER (Optional)',
    key: 'phone',
    dataIndex: 'phone',
  },
  {
    title: 'ACTIONS',
    key: 'action',
    dataIndex: 'action',
  },
];

type RequestRow = Partial<RequestCreationColumnValue>;

const getDefaultValue = () => {
  return {
    email: '',
    name: '',
    phoneCode: '+61',
    phone: '',
  };
};

function RequestCreationTable() {
  const { isTablet } = useBreakpoint();
  const dispatch = useAppDispatch();
  const inputRef = useRef(null);
  const { requests } = useExtendedRequests();
  const { user } = useAuthState();
  const { navigate } = useAppNavigation();
  const [loading, setLoading] = useState(false);
  const formik = useFormik<{
    invitationContent: string;
    followUpContent: string;
    smsContent: string;
  }>({
    initialValues: {
      followUpContent: '',
      invitationContent: '',
      smsContent: '',
    },
    onSubmit: () => {},
  });
  const [rows, setRows] = useState<RequestRow[]>(isTablet ? [] : [getDefaultValue()]);
  const [rowErrors, setRowErrors] = useState<RequestRow[]>(isTablet ? [] : [getDefaultValue()]);

  const rowRequestData = useMemo(() => rows.slice(0, rows.length + +!isTablet), [rows]);

  const handleSaveDraft = () => handleSubmit(true);

  const handleSubmit = async (isDraft: boolean = false) => {
    if (user) {
      if (rowRequestData.length == 0 && !isDraft) {
        enqueueNotification({
          name: 'Failed to ask for review',
          description: `You must add at least 1 contact to send requests.`,
          type: 'Error',
        } as SnackbarItem);
        return;
      }
      setLoading(true);
      for (const request of rowRequestData) {
        const payload = {
          phone: `${request.phoneCode}${request.phone}`,
          contact: request.email,
          content: formik.values.invitationContent,
          followUpContent: formik.values.followUpContent,
          smsContent: formik.values.smsContent,
          fullName: request.name,
          isDraft: isDraft,
        };
        let sufficientData =
          (payload.contact && payload.contact.length > 0) ||
          (payload.phone && payload.phone.length > 3);
        if (sufficientData) {
          await dispatch(askForReview(payload));
        }
      }
      navigate('requestEmailSMS');
      setLoading(false);
    }
  };

  const handleAddRow = (request: RequestRow) => {
    let newRows: RequestRow[] = [];
    let newRowErrors: RequestRow[] = [];
    // Handle row values
    if (rows.length === 1) {
      setRowErrors([getDefaultValue(), getDefaultValue()]);
      setRows([request, getDefaultValue()]);
    } else {
      const newRequest = request;
      newRows = rows;
      newRowErrors = rowErrors;
      // Handle row errors
      newRows[newRows.length - 1] = newRequest;
      setRows(newRows.concat(getDefaultValue()));

      newRowErrors[newRowErrors.length - 1] = getDefaultValue();
      setRowErrors(newRowErrors.concat(getDefaultValue()));
    }
    (inputRef.current as any)?.focus();
  };

  const handleDeleteRow = async (index: number) => {
    setRows(state => (state = rows.filter((_, indx) => indx !== index)));
  };

  const handleInputChange = async (
    fieldName: keyof RequestCreationColumnValue,
    index: number,
    value: string
  ) => {
    const newValues = rows.map((row, i) => {
      if (i === index) {
        row[fieldName] = value;
      }
      return row;
    });

    const newErrors = await Promise.all(
      rowErrors.map(async (rowError, i) => {
        if (i === index) {
          try {
            await validationSchema[fieldName].validate(value);
            rowError[fieldName] = '';
            const getRow = (key: keyof RequestCreationColumnValue) => (rows[i] as any)[key];
            if (requests.some(req => req.contact === getRow('email'))) {
              rowError[fieldName] = 'Email is requested already';
            }
          } catch (error: any) {
            rowError[fieldName] = error.message;
          }
        }
        return rowError;
      })
    );
    setRowErrors(newErrors);
    setRows(newValues);
  };

  const handleAddCreateNewRequestModal = () => {
    dispatch(
      openModal({
        modalName: 'createNewReviewRequestModal',
        extraParams: {
          onReviewRequestCreated: (payload: ReviewRequestPayload) => {
            const newRows = rows.concat({
              name: payload.fullName,
              email: payload.contact,
              phone: payload.phone,
              phoneCode: payload.phoneCode,
            });
            setRows(newRows);
            setRowErrors(rowErrors.concat(getDefaultValue()));
          },
        },
      })
    );
  };

  const handleEmailChange = async (index: number, value: string) => {
    await handleInputChange('email', index, value);
  };

  const handleFirstNameChange = async (index: number, value: string) => {
    await handleInputChange('name', index, value);
  };

  const handlePhoneNumberChange = async (index: number, value: string) => {
    await handleInputChange('phone', index, value);
  };

  const handlePhoneCodeChange = async (index: number, value: string) => {
    await handleInputChange('phoneCode', index, value);
  };

  const handleUploadCSV = () => {
    dispatch(
      openModal({
        modalName: 'uploadBulkRequestCsvModal',
        extraParams: {
          setRequests: async (requests: BulkRequestCsvInfo[]) => {
            const mappedRequests = requests.map(req => ({
              email: req.emailAddress,
              name: req.fullName,
              phoneCode: req.phoneCode,
              phone: req.phoneNumber,
            }));
            setRows([...mappedRequests, getDefaultValue()]);
            setRowErrors(new Array(mappedRequests.length + 1).fill(getDefaultValue()));
          },
        },
      })
    );
  };

  const hasError = (rowError: RequestRow) => {
    const getRowError = (key: any) => (rowError as any)[key];
    return ['email', 'name', 'phone'].some(key => {
      return !!getRowError(key);
    });
  };

  const renderedMobileRowList = (data: RequestRow[]) => {
    return (
      <div>
        {data.map((requestPayload, index) => (
          <div>
            <Space size={20}>
              <DeleteOutlined onClick={() => handleDeleteRow(index)} />
              <UserOutlined />
              <h3>{requestPayload.name}</h3>
            </Space>
            <Space>
              <p>{requestPayload.email}</p>
              <span>
                {requestPayload.phoneCode} {requestPayload.phone}
              </span>
            </Space>
            <Divider />
          </div>
        ))}
      </div>
    );
  };

  const renderedTableData = (data: RequestRow[]): Record<CreatedColumn, any>[] => {
    return data.map((request, index) => {
      const noDataProvided = !request.email && !request.phone;
      const isError = hasError(rowErrors[index]) || noDataProvided;
      const divHeight = isError ? { height: 80 } : {};
      const isInput = index === data.length - 1;
      return {
        email: (
          <div style={divHeight}>
            <Input
              required
              disabled={!isInput}
              prefix={<MailOutlined />}
              ref={isInput ? inputRef : null}
              status={!!rowErrors[index]?.email ? 'error' : ''}
              onChange={e => handleEmailChange(index, e.target.value)}
              placeholder={isInput ? 'Enter email' : 'N/A'}
              value={request?.email || ''}
            />
            {!!rowErrors[index].email && <p style={{ marginTop: 10 }}>{rowErrors[index]?.email}</p>}
            {noDataProvided && <p style={{ marginTop: 10 }}>You must enter either Email or SMS.</p>}
          </div>
        ),
        name: (
          <div style={divHeight}>
            <Input
              disabled={!isInput}
              prefix={<UserOutlined />}
              status={!!rowErrors[index]?.name ? 'error' : ''}
              onChange={e => handleFirstNameChange(index, e.target.value)}
              placeholder={isInput ? 'Enter first name' : 'N/A'}
              value={request?.name || ''}
            />
            {!!rowErrors[index].name && <p style={{ marginTop: 10 }}>{rowErrors[index]?.name}</p>}
          </div>
        ),
        phoneCode: (
          <div style={divHeight}>
            <Select
              defaultValue={'+61'}
              disabled={!isInput}
              size="large"
              value={request?.phoneCode}
              style={{ width: '100%', marginTop: 5 }}
              onChange={value => handlePhoneCodeChange(index, value)}
              options={PhoneCountryCodes.map(phoneCode => ({
                value: phoneCode.dial_code,
                label: `${phoneCode.name} (${phoneCode.dial_code})`,
              }))}
            />
          </div>
        ),
        phone: (
          <div style={divHeight}>
            <NumericInput
              prefixIcon={<PhoneOutlined />}
              disabled={!isInput}
              status={!!rowErrors[index]?.phone ? 'error' : ''}
              onChange={value => handlePhoneNumberChange(index, value)}
              placeholder={isInput ? 'Enter phone number' : 'N/A'}
              value={request?.phone || ''}
            />
            {!!rowErrors[index].phone && <p style={{ marginTop: 10 }}>{rowErrors[index]?.phone}</p>}
          </div>
        ),
        action: (
          <div style={divHeight}>
            {isInput ? (
              <Button
                style={{ marginTop: 5 }}
                disabled={isError}
                type="primary"
                onClick={() => handleAddRow(request)}>
                <PlusOutlined /> Add
              </Button>
            ) : (
              <Button style={{ marginTop: 5 }} onClick={() => handleDeleteRow(index)}>
                <DeleteOutlined /> Remove
              </Button>
            )}
          </div>
        ),
      };
    });
  };

  useEffect(() => {
    handleInputChange('email', 0, '');
  }, []);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchReviewRequests());
    };
    init();
  }, []);

  useEffect(() => {
    const initContent = async () => {
      if (!user) return;
      setLoading(true);
      const location = user.selected_location;
      const owner = location.owner_contact ? location.owner_contact.given_name : user.given_name;
      await formik.setFieldValue(
        'invitationContent',
        location.request_template?.invitation_email ??
          defaultInvitationEmail(location.title, owner)[0]
      );
      await formik.setFieldValue(
        'followUpContent',
        location.request_template?.followup_email ?? defaultFollowupEmail(location.title, owner)[0]
      );
      await formik.setFieldValue(
        'smsContent',
        location.request_template?.invitation_sms ?? defaultInvitationSms(location.title, owner)[0]
      );
      setLoading(false);
    };
    initContent();
  }, [user]);

  return (
    <ContainerCard
      style={{ marginTop: 30 }}
      title="Create New Requests"
      extra={
        <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
          <Button onClick={handleSaveDraft}>
            <SaveOutlined /> Save draft
          </Button>
          <Button onClick={handleUploadCSV}>
            <UploadOutlined /> Upload CSV
          </Button>
        </Space>
      }>
      {rowRequestData.length === 0 && (
        <Alert
          type="info"
          showIcon
          message="You must add at least one contact to create a request"
        />
      )}
      <Divider />
      <Row style={{ padding: '10px 0px 20px 0px' }} gutter={30}>
        <Col span={isTablet ? 24 : 12}>
          <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
            <React.Fragment>
              <h3 style={{ marginBottom: 10 }}>Invitation Email</h3>
              <Input.TextArea
                id="invitationContent"
                autoSize={{ minRows: 5 }}
                value={formik.values.invitationContent}
                maxLength={500}
                showCount
                name="invitationContent"
                placeholder="Enter invitation email content"
                onChange={formik.handleChange}
              />
            </React.Fragment>
          </LoadableContainer>
        </Col>
        <Col span={isTablet ? 24 : 12}>
          <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
            <React.Fragment>
              <h3 style={{ marginBottom: 10, marginTop: isTablet ? 20 : 0 }}>
                Follow up (after 7 days of no response)
              </h3>
              <Input.TextArea
                id="followUpContent"
                autoSize={{ minRows: 5 }}
                value={formik.values.followUpContent}
                name="followUpContent"
                maxLength={500}
                showCount
                placeholder="Enter follow up email content"
                onChange={formik.handleChange}
              />
            </React.Fragment>
          </LoadableContainer>
        </Col>
      </Row>
      <Row style={{ padding: '10px 0px 20px 0px' }} gutter={30}>
        <Col span={isTablet ? 24 : 18}>
          <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
            <React.Fragment>
              <h3 style={{ marginBottom: 10 }}>Invitation SMS</h3>
              <Input.TextArea
                id="smsContent"
                autoSize={{ minRows: 2 }}
                value={formik.values.smsContent}
                name="smsContent"
                maxLength={200}
                showCount
                placeholder="Enter invitation SMS content"
                onChange={formik.handleChange}
              />
            </React.Fragment>
          </LoadableContainer>
        </Col>
      </Row>
      <p>[RECIPIENT] will be replaced by the name of the recipient</p>
      <p>For SMS: [REVIEW-URL] will be replaced by the link to your business review page</p>
      <p>For Email: the review button will be added at the end of the email</p>
      <Divider />
      {!isTablet ? (
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={renderedTableData(rows)}
            pagination={false}
            className="ant-border-space"
          />
        </div>
      ) : (
        <React.Fragment>
          <div style={{ marginBottom: 20 }}>{renderedMobileRowList(rows)}</div>
          <Button type="primary" onClick={handleAddCreateNewRequestModal}>
            <PlusOutlined /> Add contact
          </Button>
        </React.Fragment>
      )}

      <br />
      <br />
      {rowRequestData.length > 0 && (
        <Button
          type="primary"
          className="success-btn"
          loading={loading}
          disabled={
            rowRequestData.length === 0 ||
            (!isTablet && rowErrors.some(rowError => hasError(rowError)))
          }
          onClick={() => handleSubmit(false)}>
          <UserAddOutlined />
          Send Requests
        </Button>
      )}
    </ContainerCard>
  );
}

export default RequestCreationTable;
