import SkeletonImage from 'antd/es/skeleton/Image';
import React, { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BlogPostModel, EmbeddedBlogSetting } from '@core/models/blog';
import { Card } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { shortenString } from '@utils/string.util';
import { formatRelativeUtcDate } from '@utils/date.util';
import { buildBlogPageUrl } from '@utils/blog.util';
import { useAuthState } from '@core/configureStore';

type Props = { blogPost: BlogPostModel; setting: EmbeddedBlogSetting; clickedDisabled?: boolean };

const EmbeddedBlogPostVerticalCard = ({ blogPost, setting, clickedDisabled }: Props) => {
  const { user } = useAuthState();
  const blogSubtitle = useMemo(
    () => shortenString(blogPost.subtitle || 'View more in the blog post...', 50),
    [blogPost]
  );
  return (
    <Card
      hoverable
      className="no-padding"
      onClick={() =>
        !clickedDisabled &&
        window.open(buildBlogPageUrl(user?.selected_location.slug, blogPost.slug))
      }
      style={{
        width: '100%',
        maxWidth: setting.cardMaxSize,
      }}
      cover={
        <LazyLoadImage
          loading="lazy"
          placeholder={<SkeletonImage active />}
          src={blogPost.thumbnail}
          style={{
            maxWidth: '100%',
            aspectRatio: '4/3',
            objectFit: 'cover',
          }}
          alt={'thumbnail'}
        />
      }>
      <div
        style={{
          padding: '12px 20px',
          backgroundColor: setting.cardColor,
          borderRadius: '0px 0px 10px 10px',
        }}>
        <p style={{ margin: 0, marginBottom: 10, color: setting.cardTitleColor }}>
          <CalendarOutlined style={{ marginRight: 10 }} />
          {blogPost.publish_at && formatRelativeUtcDate(blogPost.publish_at)}
        </p>
        <h4 style={{ color: setting.cardTitleColor }}>{blogPost.title}</h4>
        <p style={{ color: setting.cardTitleColor }}>{blogSubtitle}</p>
      </div>
    </Card>
  );
};

export default EmbeddedBlogPostVerticalCard;
