import React from 'react';
import { openModal } from '@core/features';
import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAppDispatch } from '@core/configureStore';

const CustomizeAiResponderButton = () => {
  const dispatch = useAppDispatch();
  const handleCustomizeAiResponder = () => {
    dispatch(
      openModal({
        modalName: 'customizeAiResponderModal',
      })
    );
  };

  return (
    <Button onClick={handleCustomizeAiResponder}>
      <SettingOutlined /> Customize AI Responder
    </Button>
  );
};

export default CustomizeAiResponderButton;
