import React from 'react';
import { AnimatedBrandLogo } from '..';

const SplashScreen = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '80vh',
      width: '100%',
      flexDirection: 'column',
    }}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <AnimatedBrandLogo />
    </div>
  </div>
);

export default SplashScreen;
