/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  useAppDispatch,
  useReviewRequestState,
  useReviewState,
  useStudioState,
} from '@core/configureStore';
import {
  RequestServiceStage,
  RequestSource,
  ReviewRequest,
  ReviewRequestStatus,
  SocialPost,
  SocialPostStage,
  StarRating,
} from '@core/models';
import { Button, Card, Col, Divider, Empty, List, Progress, Row, Space, Tag } from 'antd';
import LineChart from '@components/Chart/LineChart';
import { formatUtcDate, isAfterDate, isInTimeWindow } from '@utils/date.util';
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import ServiceStatusTag from '@components/ServiceStatusTag';
import ReviewTable from '@components/ReviewTable';
import AnimatedComponent from '@components/AnimatedComponent';
import { useAppNavigation } from '@core/hooks';
import NumberOfReviewsWidget from '@components/NumberOfReviewsWidget';
import AverageRatingWidget from '@components/AverageRatingWidget';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { renderStatusColor, renderStatusStr } from '@utils/social-studio.util';
import { shortenString } from '@utils/string.util';
import { openModal } from '@core/features';
import { formatUtcTimestamp } from '@utils/date.util';
import LoadableContainer from '@components/LoadableContainer';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import BusinessReviewCard from '@components/BusinessReviewCard';
import { starRatingToNumber } from '@utils/type.util';
import {
  HighlightedSubText,
  RenderRequestSourceIcon,
  SocialPostImageGrid,
} from '@components/index';
import CardSectionHeader from '@components/CardSectionHeader';
import { AppRoutes } from '@utils/route.util';

const MAX_VISIBLE_REVIEWS = 5;
const MAX_VISIBLE_REVIEW_REQUESTS = 10;

type Props = {};

const isWeeklyReviewRequest = (req: ReviewRequest) => {
  // Check if it is neither sent via email or sms
  const emailSentYesterday =
    req.email_sent_date &&
    isInTimeWindow(moment(req.email_sent_date).toDate(), {
      unit: 'day',
      value: 7,
    });
  const smsSentYesterday =
    req.sms_sent_date &&
    isInTimeWindow(moment(req.sms_sent_date).toDate(), {
      unit: 'day',
      value: 7,
    });
  // Both sms and email is not sent yet
  if (!emailSentYesterday && !smsSentYesterday) return false;
  return true;
};

const DashboardScreen = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const dispatch = useAppDispatch();
  const { reviewResponse, isLoading: isLoadingReview } = useReviewState();
  const { requests, isLoading: isLoadingRequest } = useReviewRequestState();
  const { socialPosts } = useStudioState();

  const reviewStarRatings: number[] = useMemo(
    () =>
      (reviewResponse.reviews || []).map(
        review => StarRating[review?.starRating.valueOf()] as any
      ) || [],
    [reviewResponse]
  );

  const reviewsByStars = useMemo(
    () =>
      new Array(5)
        .fill(0)
        .map((_, i) => reviewStarRatings.filter(starRating => starRating === i + 1).length),
    [reviewStarRatings]
  );

  const moreThan4StarReviews: number[] = useMemo(
    () => reviewStarRatings.filter(starRating => starRating >= 4),
    [reviewStarRatings]
  );

  const sentRequests = useMemo(
    () =>
      requests.filter(
        (req: ReviewRequest) =>
          req.email_status !== RequestServiceStage.Pending ||
          req.sms_status !== RequestServiceStage.Pending
      ),
    [requests]
  );

  const repliedRequests = useMemo(
    () => requests.filter((req: ReviewRequest) => req.stage === ReviewRequestStatus.Reviewed),
    [requests]
  );

  const requestRatings = useMemo(() => repliedRequests.map(req => req.rating), [repliedRequests]);
  const averageRequestRating = useMemo(() => {
    return requestRatings?.length > 0
      ? requestRatings.reduce((rA: any, rB: any) => rA + rB) / (requestRatings?.length || 1)
      : 0;
  }, [requestRatings]);
  const totalSentRequest = useMemo(() => sentRequests?.length, [sentRequests]);
  const totalRepliedRequest = useMemo(() => repliedRequests?.length, [repliedRequests]);
  const socialPostsPublishedLastMonth = useMemo(
    () =>
      socialPosts.filter(
        socialPost =>
          socialPost.status === SocialPostStage.Published && isAfterDate(socialPost.publish_at, 1)
      ),
    [socialPosts]
  );

  const reviewRequestActivity = useMemo(() => {
    const activityFeed: {
      status: RequestServiceStage;
      name: string;
      date: string;
      contact: string;
      source: RequestSource;
    }[] = [];
    const weeklyReviewRequests: ReviewRequest[] = [];
    if (requests) {
      for (let request of requests) {
        activityFeed.push({
          status: request.email_status,
          name: request.contact,
          date: request.last_modified_date,
          contact: request.contact,
          source: request.request_source,
        });
        if (isWeeklyReviewRequest(request)) weeklyReviewRequests.push(request);
      }
    }

    const sortedAcitvityFeed = activityFeed.sort(
      (serviceA, serviceB) => moment(serviceB.date).unix() - moment(serviceA.date).unix()
    );

    return {
      feed: sortedAcitvityFeed,
      weeklySummary: weeklyReviewRequests,
    };
  }, [requests]);

  const handleViewMoreReviews = () => {
    navigate<AppRoutes>('reviews');
  };

  const handleViewMoreRequests = () => {
    navigate<AppRoutes>('requestEmailSMS');
  };

  const handleItemClicked = async (socialPost: SocialPost) => {
    dispatch(
      openModal({
        modalName: 'socialPostModal',
        extraParams: {
          socialPost,
        },
      })
    );
  };

  const requestsFromSource = useCallback(
    (source: RequestSource, stage: ReviewRequestStatus) => {
      return requests.filter(
        request => request.request_source === source && request.stage === stage
      );
    },
    [requests]
  );

  return (
    <div style={{ padding: '10px 20px 100px 20px' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row gutter={10}>
          <Col span={isTablet ? 24 : 6}>
            <AnimatedComponent.OpacityFadeInDiv delay={200}>
              <NumberOfReviewsWidget />
            </AnimatedComponent.OpacityFadeInDiv>
          </Col>
          <Col span={isTablet ? 24 : 6}>
            <AnimatedComponent.OpacityFadeInDiv delay={200}>
              <AverageRatingWidget />
            </AnimatedComponent.OpacityFadeInDiv>
          </Col>
          {!isTablet && (
            <Col span={12}>
              <AnimatedComponent.OpacityFadeInDiv delay={200}>
                <Card style={{ padding: '0px' }}>
                  <Row gutter={15}>
                    <Col span={6}>
                      <CardSectionHeader title="Request sent" />
                      <div style={{ marginTop: 10 }}>
                        {isLoadingReview || isLoadingRequest ? (
                          <LoadingOutlined />
                        ) : (
                          <div>{totalSentRequest} requests</div>
                        )}
                      </div>
                    </Col>
                    <Col span={5}>
                      <CardSectionHeader title="Reached" />
                      <div style={{ marginTop: 10 }}>
                        {isLoadingReview || isLoadingRequest ? (
                          <LoadingOutlined />
                        ) : (
                          <div>{totalRepliedRequest} requests</div>
                        )}
                      </div>
                    </Col>
                    <Col span={6}>
                      <CardSectionHeader title="Avg. Rating" />
                      <div style={{ marginTop: 10 }}>
                        {isLoadingReview || isLoadingRequest ? (
                          <LoadingOutlined />
                        ) : (
                          <div>{averageRequestRating.toFixed(2)}</div>
                        )}
                      </div>
                    </Col>
                    <Col span={7}>
                      <CardSectionHeader title="Haven't replied" />
                      <div style={{ marginTop: 10 }}>
                        {isLoadingReview || isLoadingRequest ? (
                          <LoadingOutlined />
                        ) : (
                          <div>{totalSentRequest - totalRepliedRequest} requests</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </AnimatedComponent.OpacityFadeInDiv>
            </Col>
          )}
        </Row>
        <Row gutter={10}>
          <Col span={isTablet ? 24 : 16}>
            {socialPosts.length > 0 && (
              <AnimatedComponent.OpacityFadeInDiv delay={250}>
                <Card style={{ padding: '0px' }}>
                  <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                    <div>
                      <CardSectionHeader title="UPCOMING SOCIAL POSTS" />
                      <p>
                        There are{' '}
                        <HighlightedSubText
                          content={`${socialPostsPublishedLastMonth.length} social posts`}
                        />{' '}
                        published last month. See your upcoming posts below.
                      </p>
                    </div>
                    <Button onClick={() => navigate('studio')}>
                      <ArrowRightOutlined /> Visit studio
                    </Button>
                  </div>
                  <Divider />
                  <LoadableContainer
                    isLoading={socialPosts.length === 0}
                    loadComponent={
                      <div style={{ ...MIDDLE_STYLE }}>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>No social posts found</span>}
                        />
                      </div>
                    }>
                    <Space style={{ overflowX: 'auto', width: '100%' }}>
                      {socialPosts
                        .filter(socialPost => socialPost.publish_at)
                        .filter(socialPost => socialPost.status === SocialPostStage.Scheduled)
                        .sort(
                          (socialPostA: any, socialPostB: any) =>
                            formatUtcTimestamp(socialPostA.publish_at) -
                            formatUtcTimestamp(socialPostB.publish_at)
                        )
                        .slice(0, 4)
                        .map((socialPost: SocialPost) => (
                          <div
                            onClick={() => handleItemClicked(socialPost)}
                            style={{
                              width: 450,
                              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                              padding: '10px 10px',
                              margin: '0px 5px',
                              borderRadius: 10,
                              cursor: 'pointer',
                            }}>
                            <Space style={{ ...MIDDLE_STYLE }} size="large">
                              <SocialPostImageGrid
                                width={100}
                                hideOverlay
                                mediaIds={socialPost?.mediaIds || []}
                              />
                              <div>
                                <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                                  <Tag color={renderStatusColor(socialPost.status)}>
                                    {renderStatusStr(socialPost?.status)}
                                  </Tag>
                                  {formatUtcDate(socialPost?.publish_at as any)}
                                </div>
                                <p style={{ marginTop: 10, fontSize: 11 }}>
                                  {shortenString(socialPost.content, 100)}
                                </p>
                              </div>
                            </Space>
                          </div>
                        ))}
                    </Space>
                  </LoadableContainer>
                </Card>
              </AnimatedComponent.OpacityFadeInDiv>
            )}
            <AnimatedComponent.OpacityFadeInDiv delay={250}>
              <Card style={{ marginTop: 10, padding: '0px' }}>
                <LineChart />
                {!isTablet && (
                  <Space
                    style={{
                      width: '100%',
                      marginTop: 10,
                      justifyContent: 'space-evenly',
                      flexWrap: 'wrap',
                    }}>
                    {[
                      {
                        name: 'Square',
                        source: RequestSource.Square,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                      {
                        name: 'QuickBooks',
                        source: RequestSource.QuickBooks,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                      {
                        name: 'Open Dental',
                        source: RequestSource.OpenDental,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                      {
                        name: 'Jobber',
                        source: RequestSource.Jobber,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                      {
                        name: 'Xero',
                        source: RequestSource.Xero,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                      {
                        name: 'Email',
                        source: RequestSource.Email,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                      {
                        name: 'Manual',
                        source: RequestSource.Manual,
                        stage: ReviewRequestStatus.Reviewed,
                      },
                    ].map(({ name, source, stage }) => (
                      <div
                        style={{
                          ...MIDDLE_STYLE,
                          maxWidth: '200px',
                          justifyContent: 'space-between',
                          border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                          padding: '5px 15px',
                        }}>
                        <div style={{ ...MIDDLE_STYLE, marginRight: 15 }}>
                          <RenderRequestSourceIcon source={source} />
                          <h4 style={{ fontWeight: 'normal', marginLeft: 10, fontSize: 'smaller' }}>
                            {name}
                          </h4>
                        </div>
                        <p
                          style={{
                            color: GLOBAL_THEME_COLOR.$dark_text_color,
                            fontSize: 'smaller',
                          }}>
                          {requestsFromSource(source, stage).length} reviews
                        </p>
                      </div>
                    ))}
                  </Space>
                )}
              </Card>
            </AnimatedComponent.OpacityFadeInDiv>
            <AnimatedComponent.OpacityFadeInDiv delay={300}>
              <Card className="no-padding" style={{ marginTop: 10 }}>
                <div style={{ padding: '20px 15px' }}>
                  {isTablet ? (
                    <React.Fragment>
                      {reviewResponse?.reviews.slice(0, 3).map(review => (
                        <BusinessReviewCard
                          inApp
                          review={{
                            author_url: review.reviewer.profilePhotoUrl,
                            profile_photo_url: review.reviewer.profilePhotoUrl,
                            author_name: review.reviewer.displayName,
                            generated_response: review.reviewReply?.comment || '',
                            rating: starRatingToNumber(review.starRating),
                            text: review.comment,
                            time: formatUtcTimestamp(review.createTime),
                          }}
                        />
                      ))}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ReviewTable
                        reviews={reviewResponse?.reviews || []}
                        size="small"
                        partition={{ start: 0, end: MAX_VISIBLE_REVIEWS }}
                      />
                    </React.Fragment>
                  )}
                  {reviewResponse.reviews?.length > MAX_VISIBLE_REVIEWS && !isLoadingReview && (
                    <Button
                      style={{ marginTop: 10, width: '100%' }}
                      onClick={handleViewMoreReviews}>
                      View more reviews
                    </Button>
                  )}
                </div>
              </Card>
            </AnimatedComponent.OpacityFadeInDiv>
          </Col>
          <Col span={isTablet ? 24 : 8}>
            <AnimatedComponent.OpacityFadeInDiv delay={250}>
              <Card style={{ padding: '0px' }}>
                <CardSectionHeader title="SATISFACTION RATE" />
                <p>
                  Indicator of <HighlightedSubText content="customer satisfaction" /> with your
                  service.
                </p>
                <Row style={{ marginTop: 30 }}>
                  <Col span={12}>
                    {isLoadingReview ? (
                      <LoadingOutlined />
                    ) : (
                      <Progress
                        style={{ marginTop: 10, width: '100%' }}
                        type="dashboard"
                        percent={parseFloat(
                          ((moreThan4StarReviews.length / reviewStarRatings.length) * 100).toFixed(
                            1
                          )
                        )}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    <div>
                      {new Array(5).fill(0).map((_, star) => {
                        const revStar = 5 - star - 1;
                        return (
                          <Space>
                            <p>{revStar + 1}</p>
                            <Progress
                              showInfo={false}
                              strokeColor="#fadb14"
                              style={{ width: '100px' }}
                              percent={
                                (reviewsByStars[revStar] / reviewResponse.totalReviewCount) * 100
                              }
                            />
                            <p>{reviewsByStars[revStar]}</p>
                          </Space>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Card>
            </AnimatedComponent.OpacityFadeInDiv>
            <AnimatedComponent.OpacityFadeInDiv delay={300}>
              <Card
                style={{
                  marginTop: 10,
                  padding: '0px',
                }}>
                <div
                  style={{
                    ...MIDDLE_STYLE,
                    justifyContent: 'space-between',
                  }}>
                  <div>
                    <CardSectionHeader title="Weekly Requests Sent" />
                    <p>Total number of sent requests sent this week</p>
                  </div>
                  <div>
                    <h3>{reviewRequestActivity.weeklySummary.length}</h3>
                  </div>
                </div>
              </Card>
              <Card style={{ marginTop: 10, padding: '0px' }}>
                <CardSectionHeader title="Review Request Activity" />
                <p>Tracking progress of request email and SMS</p>
                <Divider />
                <div style={{ marginTop: 20 }}>
                  {isLoadingRequest ? (
                    <LoadingOutlined />
                  ) : (
                    <List
                      itemLayout="horizontal"
                      dataSource={reviewRequestActivity.feed.slice(0, MAX_VISIBLE_REVIEW_REQUESTS)}
                      renderItem={(activity, index) => (
                        <List.Item key={index}>
                          <List.Item.Meta
                            avatar={<RenderRequestSourceIcon source={activity.source} />}
                            title={activity.name}
                            description={
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>{formatUtcDate(activity.date)}</div>
                                <ServiceStatusTag status={activity.status} />
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                  {reviewRequestActivity.feed.length > MAX_VISIBLE_REVIEW_REQUESTS &&
                    !isLoadingRequest && (
                      <Button
                        style={{ marginTop: 10, width: '100%' }}
                        onClick={handleViewMoreRequests}>
                        View more requests
                      </Button>
                    )}
                </div>
              </Card>
            </AnimatedComponent.OpacityFadeInDiv>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default DashboardScreen;
