/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { SocialPost, SocialPostStage } from '@core/models';
import { getMonthKey, groupObjectArrayByMonth } from '@utils/date.util';
import SocialPostMonthListContainer from './SocialPostMonthListContainer';
import { getUpcomingXMonths } from '@utils/string.util';
import { useAppDispatch } from '@core/configureStore';
import { fetchSocialPosts } from '@core/features/studio/thunks';
import { useStudioTourStore } from '@core/hooks';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import moment from 'moment';
import SocialPostListContainer from './SocialPostListContainer';

interface Props {
  socialPosts: SocialPost[];
  stage: SocialPostStage | string;
  selectedMonth?: string;
}

const SocialPostListViewTab: React.FC<Props> = ({ socialPosts, stage, selectedMonth }) => {
  const dispatch = useAppDispatch();
  const { selectedTabKey } = useStudioTourStore();

  const socialPostGroupedByMonth = useMemo(() => {
    const result: Record<string, SocialPost[]> = {};
    for (const monthUnix of getUpcomingXMonths(6)) {
      const key = getMonthKey(monthUnix);
      result[key] = [];
    }
    return {
      ...(!selectedMonth
        ? result
        : {
            [selectedMonth]: [],
          }),
      ...groupObjectArrayByMonth<SocialPost>(
        socialPosts,
        stage === SocialPostStage.Scheduled || stage === SocialPostStage.Published
          ? 'publish_at'
          : 'created_date'
      ),
    };
  }, [socialPosts, selectedMonth, stage]);

  useEffect(() => {
    dispatch(fetchSocialPosts());
  }, [selectedTabKey]);

  return (
    <React.Fragment>
      {stage === 'ALL' ? (
        <SocialPostListContainer socialPosts={socialPosts} />
      ) : (
        <React.Fragment>
          {Object.keys(socialPostGroupedByMonth)
            .sort(
              (monthKeyA, monthKeyB) =>
                moment(monthKeyA, MONTH_KEY_DATE_FORMAT).unix() -
                moment(monthKeyB, MONTH_KEY_DATE_FORMAT).unix()
            )
            .map(month => (
              <SocialPostMonthListContainer
                month={month}
                socialPosts={socialPostGroupedByMonth[month]}
              />
            ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SocialPostListViewTab;
