/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import { useAuthState } from '@core/configureStore';
import { facebookApiService } from '@services/service-register';
import { OAUTH_ROUTE } from '@constants/social';
import { SocialIcon } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';
import { CheckCircleFilled } from '@ant-design/icons';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useSocialAccountDispatcher } from '@core/hooks';

type Props = {};

const ConnectFacebookButton = (props: Props) => {
  const { user, integration: social } = useAuthState();
  const { handleLoadFacebookConnect } = useSocialAccountDispatcher();
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogOutFacebook = async () => {
    setLoading(true);
    try {
      await facebookApiService.logoutFacebook();
      await handleLoadFacebookConnect();
      location.reload();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        await handleLoadFacebookConnect();
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    init();
  }, [user]);

  return social.facebook ? (
    <Popover trigger={'click'} content={<Button onClick={handleLogOutFacebook}>Disconnect</Button>}>
      <Button loading={loading} style={{ ...MIDDLE_STYLE, width: '100%' }}>
        <SocialIcon platform="facebook" />
        <span style={{ marginLeft: 10, marginRight: 10 }}>{social.facebook.name}</span>
        <CheckCircleFilled style={{ color: GLOBAL_THEME_COLOR.$success_color }} />
      </Button>
    </Popover>
  ) : (
    <a href={OAUTH_ROUTE}>
      <Button loading={loading} style={{ ...MIDDLE_STYLE }}>
        <SocialIcon platform="facebook" width={20} style={{ marginRight: 10 }} /> Connect to
        Facebook
      </Button>
    </a>
  );
};

export default ConnectFacebookButton;
