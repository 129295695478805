import { facebookApiService } from '@services/service-register';
import { connectToThirdParty } from '@core/features';
import { useAppDispatch } from '@core/configureStore';

export const useSocialAccountDispatcher = () => {
  const dispatch = useAppDispatch();
  const handleLoadFacebookConnect = async () => {
    try {
      const socialAccount = await facebookApiService.getFacebookProfile();
      dispatch(
        connectToThirdParty({
          platform: 'facebook',
          profile: socialAccount,
        })
      );
    } catch (error: any) {
      console.log(error);
    }
  };

  return {
    handleLoadFacebookConnect,
  };
};
