import { StarOutlined } from '@ant-design/icons';
import { RateConversion } from '@constants/rate-conversion';
import { GoogleReview } from '@core/models';
import { formatRelativeUtcDate, timeDiff } from '@utils/date.util';
import { Avatar, Col, Row } from 'antd';
import { BRAND_NAME, BRAND_URL } from '@constants/brand';
import { useState } from 'react';
import Paragraph from 'antd/es/typography/Paragraph';
import { ReviewStarsContainer } from '..';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { shortenString } from '@utils/string.util';

type Props = {
  review: GoogleReview;
  isUIMobile: boolean;
};
const ReviewWidget = ({ review, isUIMobile }: Props) => {
  const [ellipsis, setEllipsis] = useState(true);
  const { isMobile } = useBreakpoint();

  const isMobileView = isMobile || isUIMobile;
  const monthDiff = timeDiff(review.updateTime, 'month');

  return (
    <Row
      wrap={false}
      style={{
        paddingRight: isMobileView ? '10px' : '20px',
        minHeight: isMobileView ? '60px' : '80px',
        width: '100%',
        marginInline: 0,
      }}>
      {/* Avatar Column */}
      <Col
        flex={isMobileView ? '50px' : '65px'}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div
          style={{
            overflow: 'hidden',
            position: 'relative',
          }}>
          <Avatar
            src={review.reviewer.profilePhotoUrl}
            size={isMobileView ? 45 : 60}
            icon={<StarOutlined />}
          />
          <img
            src="https://cdn-icons-png.flaticon.com/512/720/720255.png"
            alt={'google-icon'}
            style={{
              width: isMobileView ? 16 : 24,
              height: isMobileView ? 16 : 24,
              overflow: 'hidden',
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          />
        </div>
      </Col>

      {/* Content Column */}
      <Col
        flex="auto"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginLeft: '10px',
        }}>
        {/* Name and Rating */}
        <Row
          wrap={false}
          style={{
            marginInline: 0,
            display: 'flex',
            alignContent: 'center',
            gap: '15px',
          }}>
          <Col flex="auto" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div
              ref={el => {
                if (el) {
                  el.style.setProperty(
                    'font-size',
                    `${isMobileView} ? '13px' : '14px'`,
                    'important'
                  );
                  el.style.setProperty('font-family', `'DM Sans', sans-serif`, 'important');
                }
              }}
              style={{
                color: '#0958d9',
                margin: 0,
                fontSize: isMobileView ? '13px' : '14px',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {shortenString(review.reviewer.displayName, isMobileView ? 13 : 21)}
            </div>
          </Col>
          <Col flex={isMobileView ? '75px' : '85px'} style={{ justifyContent: 'flex-end' }}>
            <ReviewStarsContainer
              style={{ fontSize: isMobileView ? '14px' : '16px' }}
              rating={RateConversion[review.starRating]}
            />
          </Col>
        </Row>

        {/* Content */}
        <div
          style={{ marginTop: 2, marginBottom: 5 }}
          onMouseEnter={() => setEllipsis(false)}
          onMouseLeave={() => setEllipsis(true)}>
          <Paragraph
            ref={el => {
              if (el) {
                el.style.setProperty('font-family', `'DM Sans', sans-serif`, 'important');
              }
            }}
            ellipsis={ellipsis ? { rows: 2, expandable: !isMobileView, symbol: 'more' } : false}
            style={{
              fontSize: '12px',
              lineHeight: '16px',
              marginBottom: 0,
            }}>
            {review.comment}
          </Paragraph>
        </div>

        {/* Time and Watermark */}
        {!isMobileView && (
          <div
            ref={el => {
              if (el) {
                el.style.setProperty('font-size', '10px', 'important');
                el.style.setProperty('font-family', `'DM Sans', sans-serif`, 'important');
              }
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}>
            <div
              style={{
                color: 'gray',
                margin: 0,
                fontWeight: 'bold',
              }}>
              {monthDiff <= 3 ? formatRelativeUtcDate(review.updateTime) : 'a while ago'}
            </div>

            <a href={BRAND_URL} style={{ color: '#1677ff' }} target="_blank" rel="noreferrer">
              powered by {BRAND_NAME}
            </a>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ReviewWidget;
