/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useAuthState } from '@core/.';
import WelcomSelectLocationModalContent from '@components/WelcomeSelectLocationModal/WelcomSelectLocationModalContent';
import { Divider } from 'antd';

type Props = {
  children: React.ReactElement;
};

const ProtectedFreelancerModeRoute = ({ children }: Props) => {
  const { user } = useAuthState();
  return user?.selected_location?.freelancer_mode ? (
    <div style={{ padding: '30px 50px' }}>
      <h3>Select Business</h3>
      <p>Please select a location to use this feature</p>
      <Divider />
      <WelcomSelectLocationModalContent />
    </div>
  ) : (
    children
  );
};

export default ProtectedFreelancerModeRoute;
