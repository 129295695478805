export type AppRoutes =
  | 'getting-started'
  | 'showcase-reviews-onboard'
  | 'generate-reviews'
  | 'home'
  | 'reviews'
  | 'inquiries'
  | 'integrations'
  | 'requests'
  | 'settings'
  | 'studio'
  | 'studio-onboard'
  | 'requestCreation'
  | 'socialPostCreation'
  | 'widget'
  | 'requestEmailSMS'
  | 'requestQrScanner'
  | 'autoRequest'
  | 'help-center'
  | 'manualSharing'
  | 'subscription'
  | 'grow-social-presence'
  | 'grow-reputation'
  | 'booking'
  | 'blogs'
  | 'wall-of-love';

export const FREELANCER_MODE_PROTECTED_ROUTES: Record<AppRoutes, boolean> = {
  'getting-started': false,
  'showcase-reviews-onboard': true,
  'generate-reviews': true,
  home: false,
  reviews: true,
  inquiries: true,
  integrations: true,
  requests: false,
  settings: false,
  studio: false,
  'studio-onboard': false,
  requestCreation: true,
  socialPostCreation: false,
  widget: true,
  requestEmailSMS: true,
  requestQrScanner: true,
  autoRequest: true,
  'help-center': false,
  manualSharing: true,
  subscription: false,
  blogs: false,
  'grow-social-presence': false,
  'grow-reputation': false,
  booking: false,
  'wall-of-love': false,
};
