import { GooglePlaceDetailData, PlaceData } from '@core/models';
import axios from 'axios';

const BACKEND_ENDPOINT = `${
  process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT || 'http://localhost:8000'
}/public`;

export default class PublicRouteService {
  public async getPhotoBase64Data(url: string) {
    try {
      const response = await axios.get(`${BACKEND_ENDPOINT}/photos?url=${url}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateCustomContent({ prompt }: { prompt: string }): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/custom-generate`,
        {
          prompt,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async askGPT(prompt: string): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/ask-gpt`,
        {
          prompt,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateSocialPostContent({
    businessName,
    businessIndustry,
    imageDescription,
    topic,
    prevOutputs,
  }: {
    businessName: string;
    businessIndustry: string;
    imageDescription?: string;
    topic?: string;
    prevOutputs?: string[];
  }): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/social-post`,
        {
          businessName,
          businessIndustry,
          imageDescription,
          topic,
          prevOutputs,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async sendSlackNotification(
    locationName: string,
    locationIndustry: string
  ): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/pitch-notify`,
        {
          locationName,
          locationIndustry,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async sendUserActivitySlackNotification(
    locationName: string,
    activityMessage: string
  ): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/general-notify`,
        {
          locationName,
          activityMessage,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generatePitchBlogPost(
    title: string,
    primary_category: string,
    topic: string
  ): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/pitch/blog`,
        {
          title,
          primary_category,
          topic,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateBlogPost(
    title: string,
    primary_category: string,
    topic: string
  ): Promise<{ title: string; contentBody: string; subtitle: string }> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/blog`,
        {
          title,
          primary_category,
          topic,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateMockReview(
    title: string,
    primary_category: string,
    isPositive: boolean
  ): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/mock-review`,
        {
          title,
          primary_category,
          isPositive,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async generateReviewResponse(
    title: string,
    primary_category: string,
    rating: number,
    comment: string,
    authorName: string
  ): Promise<string> {
    try {
      const response = await axios.post(
        `${BACKEND_ENDPOINT}/review`,
        {
          title,
          primary_category,
          rating,
          comment,
          authorName,
        },
        {
          headers: {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getGooglePhotoData(
    photoReference: string,
    maxWidth: number,
    maxHeight: number
  ): Promise<any> {
    try {
      const response = await axios.post(`${BACKEND_ENDPOINT}/place/photo`, {
        photoReference,
        maxWidth,
        maxHeight,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async searchForGooglePlaces(name: string, address: string): Promise<any> {
    try {
      const response = await axios.post(`${BACKEND_ENDPOINT}/places`, {
        name,
        address,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async searchNearbyByGooglePlaces(
    keyword: string,
    type: string,
    latitude: number,
    longtitude: number
  ): Promise<any> {
    try {
      const response = await axios.post(`${BACKEND_ENDPOINT}/places/nearby`, {
        keyword,
        type,
        latitude,
        longtitude,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getPlaceDetail(id: string): Promise<GooglePlaceDetailData> {
    try {
      const response = await axios.get(`${BACKEND_ENDPOINT}/place/${id}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getMarketingPlaceByPitchId(pitchId: string): Promise<any> {
    try {
      const response = await axios.get(`${BACKEND_ENDPOINT}/pitch-place/${pitchId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async updateMarketingPlaceData(pitchId: string, placeData: Partial<PlaceData>) {
    try {
      const response = await axios.put(`${BACKEND_ENDPOINT}/pitch-place/${pitchId}`, {
        placeData,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
