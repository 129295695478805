/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  useAppDispatch,
  useAuthState,
  useBusinessState,
  useStudioState,
} from '@core/configureStore';
import { Alert, Button, Divider, Radio, Select, Skeleton, Space } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import LoadableContainer from '@components/LoadableContainer';
import { formatUtcTimestamp, getDefaultMonthKey, groupObjectArrayByMonth } from '@utils/date.util';
import { CalendarOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppNavigation } from '@core/hooks';
import { TbLayoutGrid, TbLayoutList } from 'react-icons/tb';
import { MonthHorizontalSelector, SocialIcon } from '..';
import { FacebookPost, SocialPost, SocialPostStage } from '@core/models';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { facebookApiService } from '@services/service-register';
import { getUpcomingXMonths } from '@utils/string.util';
import moment from 'moment';
import { openModal } from '@core/features';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import FacebookPostGridViewTab from './FacebookPostGridViewTab';
import SocialPostGridViewTab from './SocialPostGridViewTab';
import SocialPostListViewTab from './SocialPostListViewTab';
import SocialPostCalendarViewTab from './SocialPostCalendarViewTab';
import { cloneArray } from '@utils/array.util';

enum ViewMode {
  CalendarView = 'calendar-view',
  GridView = 'grid-view',
  ListView = 'list-view',
}

const SocialPostsTab = () => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const { user } = useAuthState();
  const { integration } = useBusinessState();
  const [viewMode, setViewMode] = useState<ViewMode>(
    !isTablet ? ViewMode.ListView : ViewMode.GridView
  );
  const { socialPosts } = useStudioState();
  const [filteredSocialPosts, setFilteredSocialPosts] = useState<SocialPost[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(getDefaultMonthKey());
  const [loadingFacebookPosts, setLoadingFacebookPosts] = useState<boolean>(true);
  const [facebookPagePosts, setFacebookPagePosts] = useState<FacebookPost[]>([]);
  const [filteredBy, setFilteredBy] = useState<{
    value: SocialPostStage | string;
    type: 'filteredParams' | 'facebookPage';
  }>({
    value: SocialPostStage.Scheduled,
    type: 'filteredParams',
  });
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);

  const handleGenerateNewPost = () => {
    navigate('socialPostCreation');
  };

  const handleCustomizeAiProfile = () => {
    dispatch(
      openModal({
        modalName: 'customizeAiProfileModal',
      })
    );
  };

  const upcomingMonths = useMemo(() => getUpcomingXMonths(isTablet ? 3 : 6), [isTablet]);

  const monthFilteredSocialPosts = useMemo(
    () =>
      !selectedMonth
        ? filteredSocialPosts
        : cloneArray(filteredSocialPosts).filter(
            socialPost =>
              moment(socialPost.publish_at).format(MONTH_KEY_DATE_FORMAT) === selectedMonth
          ),
    [filteredSocialPosts, selectedMonth]
  );

  const sortedSocialPosts = useMemo(
    () =>
      filteredBy.value !== 'ALL'
        ? cloneArray(monthFilteredSocialPosts)
        : cloneArray(socialPosts).sort((postA, postB) =>
            filteredBy.value === SocialPostStage.Scheduled
              ? formatUtcTimestamp(postA.publish_at) - formatUtcTimestamp(postB.publish_at)
              : formatUtcTimestamp(postB.publish_at) - formatUtcTimestamp(postA.publish_at)
          ),
    [filteredBy, monthFilteredSocialPosts]
  );

  const socialPostGroupedByMonth = useMemo(
    () => groupObjectArrayByMonth<SocialPost>(filteredSocialPosts, 'publish_at'),
    [filteredSocialPosts]
  );

  useEffect(() => {
    const loadSocialPosts = async () => {
      switch (filteredBy.type) {
        case 'facebookPage':
          setLoadingFacebookPosts(true);
          const posts = await facebookApiService.getFacebookPagePosts(filteredBy.value as string);
          setFacebookPagePosts(posts || []);
          setLoadingFacebookPosts(false);
          break;
        case 'filteredParams':
          // Filtered social posts
          if (filteredBy.value === 'ALL') return setFilteredSocialPosts(socialPosts);
          const _filteredSocialPosts: SocialPost[] = [];
          for (const socialPost of socialPosts) {
            const isInThePast = moment(socialPost.publish_at).unix() < moment().unix();
            if (socialPost.status === SocialPostStage.Scheduled && isInThePast) continue;
            if (socialPost.status === filteredBy.value) _filteredSocialPosts.push(socialPost);
          }
          setFilteredSocialPosts(_filteredSocialPosts);
          break;
      }
    };
    loadSocialPosts();
  }, [socialPosts, filteredBy]);

  useEffect(() => {
    const scheduledSocialPosts = socialPosts.filter(
      socialPost => socialPost.status === SocialPostStage.Scheduled
    );
    if (scheduledSocialPosts.length === 0) {
      setFilteredBy({
        value: SocialPostStage.Pending,
        type: 'filteredParams',
      });
    }
  }, [socialPosts]);

  useEffect(() => {
    if (viewMode === ViewMode.ListView) {
      setSelectedMonth(undefined);
    } else {
      setSelectedMonth(getDefaultMonthKey());
    }
  }, [viewMode]);

  return (
    <LoadableContainer isLoading={!locationId} loadComponent={<div>No location found</div>}>
      <React.Fragment>
        <Space
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Space style={{ flexWrap: 'wrap' }}>
            <Radio.Group
              value={viewMode}
              onChange={e => {
                setViewMode(e.target.value);
              }}>
              <Radio.Button value={ViewMode.GridView}>
                <div style={{ ...MIDDLE_STYLE }}>
                  <TbLayoutGrid style={{ marginRight: 5 }} />
                  Grid View
                </div>
              </Radio.Button>
              <Radio.Button value={ViewMode.ListView}>
                <div style={{ ...MIDDLE_STYLE }}>
                  <TbLayoutList style={{ marginRight: 5 }} />
                  List View
                </div>
              </Radio.Button>
              <Radio.Button value={ViewMode.CalendarView}>
                <CalendarOutlined style={{ marginRight: 5 }} />
                Calendar
              </Radio.Button>
            </Radio.Group>
            <Select
              style={{ width: isTablet ? '80vw' : '200px' }}
              value={JSON.stringify(filteredBy)}
              onChange={value => setFilteredBy(JSON.parse(value))}
              options={[
                {
                  label: 'ALL',
                  value: JSON.stringify({
                    value: 'ALL',
                    type: 'filteredParams',
                  }),
                },
                {
                  label: 'Pending',
                  value: JSON.stringify({
                    value: SocialPostStage.Pending,
                    type: 'filteredParams',
                  }),
                },
                {
                  label: 'Scheduled',
                  value: JSON.stringify({
                    value: SocialPostStage.Scheduled,
                    type: 'filteredParams',
                  }),
                },
                {
                  label: 'Published',
                  value: JSON.stringify({
                    value: SocialPostStage.Published,
                    type: 'filteredParams',
                  }),
                },
                ...(integration.facebook_pages
                  ? integration.facebook_pages.map(facebookPage => ({
                      label: (
                        <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start' }}>
                          <img
                            width={20}
                            height={20}
                            style={{ borderRadius: 50, marginRight: 5 }}
                            src={`data:image/jpeg;base64,${facebookPage.picture}`}
                            alt={'Facebook page profile'}
                          />
                          <SocialIcon style={{ marginRight: 5 }} width={20} platform="facebook" />
                          {facebookPage.name}
                        </div>
                      ),
                      value: JSON.stringify({
                        value: facebookPage.id,
                        type: 'facebookPage',
                      }),
                    }))
                  : []),
              ]}
            />
          </Space>
          <Space style={{ flexWrap: 'wrap' }}>
            <Button
              id={'customize-ai-profile'}
              style={{ width: isTablet ? '80vw' : 'fit-content' }}
              onClick={handleCustomizeAiProfile}>
              <EditOutlined />
              Customize Setting
            </Button>
            <Button
              style={{ width: isTablet ? '80vw' : 'fit-content' }}
              type="primary"
              onClick={handleGenerateNewPost}>
              <PlusOutlined />
              Create new post
            </Button>
          </Space>
        </Space>
        <Divider />
        {[...integration.facebook_pages, ...integration.instagram_profiles].length === 0 && (
          <Alert
            showIcon
            style={{ marginBottom: 20 }}
            type="warning"
            message="Your posts will not be published until you connect your social accounts."
          />
        )}
        {filteredBy.value !== 'ALL' && (
          <React.Fragment>
            <MonthHorizontalSelector
              label="social posts"
              records={socialPostGroupedByMonth}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              upcomingMonths={upcomingMonths}
            />
          </React.Fragment>
        )}
        {viewMode === ViewMode.GridView && (
          <React.Fragment>
            {filteredBy.type === 'facebookPage' ? (
              <LoadableContainer isLoading={loadingFacebookPosts} loadComponent={<Skeleton />}>
                <FacebookPostGridViewTab facebookPosts={facebookPagePosts} />
              </LoadableContainer>
            ) : (
              <SocialPostGridViewTab
                stage={filteredBy.value}
                socialPosts={sortedSocialPosts}
                selectedMonth={selectedMonth}
              />
            )}
          </React.Fragment>
        )}
        {viewMode === ViewMode.ListView && (
          <React.Fragment>
            {filteredBy.type === 'facebookPage' ? (
              <LoadableContainer isLoading={loadingFacebookPosts} loadComponent={<Skeleton />}>
                <FacebookPostGridViewTab facebookPosts={facebookPagePosts} />
              </LoadableContainer>
            ) : (
              <SocialPostListViewTab
                stage={filteredBy.value}
                socialPosts={sortedSocialPosts}
                selectedMonth={selectedMonth}
              />
            )}
          </React.Fragment>
        )}
        {viewMode === ViewMode.CalendarView && (
          <SocialPostCalendarViewTab socialPosts={sortedSocialPosts} />
        )}
      </React.Fragment>
    </LoadableContainer>
  );
};

export default SocialPostsTab;
