/* eslint-disable jsx-a11y/alt-text */
import { Button, Modal } from 'antd';
import React from 'react';

type Props = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const FacebookInAppHandler = ({ children, open, setOpen }: Props) => {
  return (
    <React.Fragment>
      {children}
      <Modal
        title={'Facebook In-app Browser Detected'}
        open={open}
        closeIcon
        onCancel={() => setOpen(false)}
        footer={<Button onClick={() => setOpen(false)}>Close</Button>}
        closable>
        You are currently using Amazely inside Facebook unsupported browser. To continue, please
        open Amazely from a system browser (Chrome, Safari, ...)
        <img src="facebook-inapp-detect.webp" style={{ marginTop: 20, borderRadius: 10 }} />
      </Modal>
    </React.Fragment>
  );
};

export default FacebookInAppHandler;
