import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { shortenString } from '@utils/string.util';
import React from 'react';
import { StudioPhoto } from '@core/models';
import moment from 'moment';
import { DATE_FORMAT } from '@utils/date.util';
import { MIDDLE_STYLE } from '@constants/responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SmallStudioImageListItem: React.FC<{ item: StudioPhoto; style?: React.CSSProperties }> = ({
  item,
  style,
}) => {
  return (
    <div
      style={{
        marginBottom: 10,
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        padding: '5px',
        borderRadius: 5,
        ...MIDDLE_STYLE,
      }}>
      <LazyLoadImage
        loading="lazy"
        className="card--image"
        src={item.url}
        width={30}
        style={{
          objectFit: 'cover',
          overflow: 'hidden',
          ...style,
        }}
      />
      <div style={{ marginLeft: 10 }}>
        <span style={{ fontSize: 10 }}>{shortenString(item.description, 100)}</span>
        <h3 style={{ fontSize: 10 }}>{moment(item.created_date).format(DATE_FORMAT)}</h3>
      </div>
    </div>
  );
};

export default SmallStudioImageListItem;
