import { MIDDLE_STYLE } from '@constants/responsive';
import { Tooltip } from 'antd';
import { ExpandableSuggestionContainer, LoadableContainer } from '@components/index';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { LoadingOutlined } from '@ant-design/icons';
import { BiRefresh } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import { blogApiService } from '@services/service-register';
import { useAuthState } from '@core/configureStore';
import { BlogPostModel, LocalLocation } from '@core/models';
import { buildCachePayload, getRevalidatedJsonData } from '@utils/cache.util';
import { BlogCreationTourIdRegistry } from '@utils/blog.util';

interface Props {
  limit: number;
  topic: string;
  onTopicChanged: (topic: string) => void;
  existingBlogPost: BlogPostModel | undefined;
}

const CACHE_EXPIRATION_MS = 60 * 3 * 60 * 1000; // 3 hour

const DEFAULT_TOPIC_SUGGESTIONS = [
  'Introduce your business',
  'Congrats special event',
  'Throwback a memorable moment',
  'Express gratitude to the customers',
];

async function getRevalidatedBlogTopics(
  location: LocalLocation,
  limit: number,
  forceRefetch?: boolean
) {
  let isRevalidated = false;
  const blogTopics = await getRevalidatedJsonData<string[]>(
    `${location.location_id}:blog-topics`,
    async () => {
      const topics = await blogApiService.generateBlogTopics(
        limit,
        location.primary_category,
        location.business_description
      );
      isRevalidated = true;
      return buildCachePayload(topics, CACHE_EXPIRATION_MS);
    },
    {
      forceRefetch,
    }
  );

  return {
    blogTopics: blogTopics || [],
    isRevalidated,
  };
}

const BlogPostTopicEditor: React.FC<Props> = ({ limit, onTopicChanged }) => {
  const { user } = useAuthState();
  const [generatingTopicSuggestions, setGeneratingTopicSuggestions] = useState<boolean>(false);
  const [topicSuggestions, setTopicSuggestions] = useState<string[]>(DEFAULT_TOPIC_SUGGESTIONS);

  const generateTopicSuggestions = async (forceRefetch: boolean) => {
    try {
      setGeneratingTopicSuggestions(true);
      if (user && user?.selected_location) {
        const { blogTopics } = await getRevalidatedBlogTopics(
          user.selected_location,
          limit,
          forceRefetch
        );
        setTopicSuggestions(blogTopics);
      }
    } catch (e) {
      setTopicSuggestions(DEFAULT_TOPIC_SUGGESTIONS);
    }
    setGeneratingTopicSuggestions(false);
  };

  useEffect(() => {
    generateTopicSuggestions(false);
  }, []);

  return (
    <div id={BlogCreationTourIdRegistry.TopicSuggestionSection}>
      <LoadableContainer
        isLoading={generatingTopicSuggestions}
        loadComponent={
          <div style={{ ...MIDDLE_STYLE, color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            <LoadingOutlined style={{ marginRight: 10 }} />
            Suggesting topics for your blog post...
          </div>
        }>
        <div
          style={{
            padding: '10px 15px',
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            borderRadius: 10,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
          }}>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <h4 style={{ fontSize: 'smaller' }}>⚡️ Blog topic suggestions</h4>
            <Tooltip title={'Re-generate suggestions'}>
              <BiRefresh
                style={{ cursor: 'pointer' }}
                onClick={() => generateTopicSuggestions(true)}
              />
            </Tooltip>
          </div>
          <div style={{ margin: '10px 0px 0px 0px' }}>
            <ExpandableSuggestionContainer
              limit={limit}
              topics={topicSuggestions}
              onTopicClicked={onTopicChanged}
            />
          </div>
        </div>
      </LoadableContainer>
    </div>
  );
};

export default BlogPostTopicEditor;
