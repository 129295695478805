import React from 'react';
import { useGenerationDataStore } from '@core/hooks';
import { QrCodeGeneratorEngineStatus } from '@core/models';
import loadable from '@loadable/component';

const QrCodeProcessingStep = loadable(() => import('@components/QrCodeProcessingStep'));
const QrCodeGenerateStatusOverlay = loadable(
  () => import('@components/QrCodeGenerateStatusOVerlay')
);
const QrCodeGenerateResultScreen = loadable(() => import('@pages/QrCodeGenerateResultScreen'));
const InfoCollectorScreen = loadable(() => import('@pages/InfoCollectorScreen'));

type Props = {};

const QrCodeGeneratorScreen = (props: Props) => {
  const { qrCodeEngineStatus } = useGenerationDataStore();
  return (
    <div>
      {qrCodeEngineStatus === QrCodeGeneratorEngineStatus.NotStarted ? (
        <InfoCollectorScreen>
          <QrCodeProcessingStep />
        </InfoCollectorScreen>
      ) : (
        <QrCodeGenerateResultScreen />
      )}
      <QrCodeGenerateStatusOverlay />
    </div>
  );
};

export default QrCodeGeneratorScreen;
