/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useAuthState } from '@core/configureStore';
import { SocialPost, SocialPostType } from '@core/models';
import { socialStudioApiService } from '@services/service-register';
import moment from 'moment';

export const useSocialPosts = () => {
  const { user } = useAuthState();
  const [socialPosts, setSocialPosts] = useState<SocialPost[]>([]);
  const locationId = useMemo(() => user?.selected_location?.location_id, [user]);
  const [loading, setLoading] = useState(true);

  const showcaseReviewSocialPosts = useMemo<SocialPost[]>(() => {
    return socialPosts.filter(socialPost => socialPost.type === SocialPostType.ShowcaseReview);
  }, [socialPosts]);

  const photoSocialPosts = useMemo<SocialPost[]>(() => {
    return socialPosts.filter(socialPost => socialPost.type === SocialPostType.Default);
  }, [socialPosts]);

  const sortedLatestShowcaseReviewSocialPosts = useMemo<SocialPost[]>(
    () =>
      showcaseReviewSocialPosts.sort(
        (socialPostA, socialPostB) =>
          moment(socialPostB.publish_at).unix() - moment(socialPostA.publish_at).unix()
      ),
    [showcaseReviewSocialPosts]
  );

  useEffect(() => {
    const init = async () => {
      if (!locationId) return;
      setLoading(true);
      try {
        const socialPosts = await socialStudioApiService.getLocationSocialPosts();
        setSocialPosts(socialPosts);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    init();
  }, [locationId]);

  return {
    loading,
    socialPosts,
    photoSocialPosts,
    showcaseReviewSocialPosts,
    sortedLatestShowcaseReviewSocialPosts,
    setSocialPosts,
  };
};
