import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { SubscriptionReducerProps } from '../type';

export const checkout = createAsyncThunk(
  'subscription/checkout',
  async (noLicenses: number, thunkApi) => {
    const result = await heroApiService.createCheckoutSession(noLicenses);
    return result;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(checkout);

export const checkoutReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    window.location.replace(action.payload);
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<SubscriptionReducerProps>;
