export const BRAND_NAME = 'Amazely';
export const BRAND_URL = 'https://amazely.co';
export const BRAND_LOGO = '/amazely-logo.webp';
export const BRAND_LOGO_CONSTRAST = '/amazely-logo.webp';
export const GOOGLE_LOGO = '/google.png';
export const JUMBOTRON = {
  LOGIN: {
    title: `Login to ${BRAND_NAME}!`,
    description: 'Managing your business with AI-powered suite tools',
  },
};

export const TONE_OF_VOICES = [
  {
    tone: 'Humble and Down-to-earth',
    example: 'Thankful for the journey and your amazing support. 🙏 #Gratitude',
  },
  {
    tone: 'Casual and Conversational',
    example: 'Hey there! 😊 Ready to conquer the day? 🚀 #MondayMotivation #LetsDoThis',
  },
  {
    tone: 'Formal and Professional',
    example:
      "We're thrilled to announce our latest partnership with [Company Name]. Exciting things ahead! #BusinessNews #Collaboration",
  },
  {
    tone: 'Humorous and Playful',
    example: 'When life gives you lemons, trade them for coffee. ☕️😄 #MondayMood #CoffeeLover',
  },
  {
    tone: 'Inspirational and Uplifting',
    example: 'Your journey is unique, embrace it. 💪✨ #MotivationMonday #OwnYourStory',
  },
  {
    tone: 'Educational and Informative',
    example:
      'Did you know? Studies show that regular exercise can improve both physical and mental well-being. 🏋️‍♂️🧠 #WellnessWednesday #HealthFacts',
  },
  {
    tone: 'Interactive and Engaging',
    example:
      "What's your favorite weekend activity? Comment below and let's share some ideas! 👇 #WeekendVibes #Community",
  },
  {
    tone: 'Appreciative and Thankful',
    example:
      'A big shoutout to our amazing customers! 🙌 Your support means the world to us. Thank you for being a part of our journey. 💙',
  },
];

export const DEFAULT_UNKNOWN_ADDRESS = 'No Storefront Address';
