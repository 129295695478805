import { RootState } from '@core/configureStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequestReducerProps } from '../type';

export const selectRequest = createAsyncThunk(
  'review-request/selectRequest',
  async (id: number, thunkApi) => {
    const {
      auth: { user },
    } = thunkApi.getState() as RootState;
    if (!user) throw new Error('Not user found');
    const result = await heroApiService.getReviewRequestById(id);
    return result;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(selectRequest);

export const selectRequestReducer = {
  [fulfilled]: (state, action) => {
    state.isDetailLoading = false;
    state.isSelected = true;
    state.request = action.payload;
  },
  [rejected]: (state, action) => {
    state.isDetailLoading = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isDetailLoading = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
