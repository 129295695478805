/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button } from 'antd';
import { DisconnectOutlined } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import { fetchSquareMerchant } from '@core/features/business/thunks/fetchSquareMerchant.thunk';
import { heroApiService } from '@services/service-register';
import TitleWithText from '@components/TitleWithText';

type Props = {};

const ConnectSquareIntegrationButton = (props: Props) => {
  const { user } = useAuthState();
  const dispatch = useAppDispatch();
  const { integration, isLoading } = useBusinessState();
  const handleConnect = async () => {
    const url = await heroApiService.squareOauthLogin();
    window.open(url);
  };

  const handleLogoutSquare = async () => {
    if (!user) return;
    try {
      await heroApiService.unintegrateSquareFromLocation();
      await dispatch(fetchSquareMerchant());
    } catch (error) {
      console.log(error);
    }
  };

  return integration.square ? (
    <div
      style={{
        ...MIDDLE_STYLE,
        flexWrap: 'wrap',
        flexDirection: 'column',
      }}>
      <TitleWithText title="Connected Account">{integration.square}</TitleWithText>
      <br />
      <Button icon={<DisconnectOutlined />} onClick={handleLogoutSquare}>
        Disconnect Square Integration
      </Button>
    </div>
  ) : (
    <Button loading={isLoading} onClick={handleConnect} style={{ ...MIDDLE_STYLE }}>
      <img alt="Square" src="square-inc.png" width={25} height={25} style={{ marginRight: 10 }} />{' '}
      Connect to Square
    </Button>
  );
};

export default ConnectSquareIntegrationButton;
