import React from 'react';
import UploadImageModal from '@components/UploadImageModal';
import { PhotoImageDataItem, openModal } from '@core/features';
import { Tooltip } from 'antd';
import { TbPhoto } from 'react-icons/tb';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { BlogCreationTourIdRegistry } from '@utils/blog.util';
import { useAppDispatch } from '@core/configureStore';

interface Props {
  disabled?: boolean;
  mediaItems: PhotoImageDataItem[];
  onMediaUploaded: (mediaItems: PhotoImageDataItem[]) => void;
  selectPhotoDisabled: boolean;
}

const BlogAddFeaturedImageButton: React.FC<Props> = ({
  mediaItems,
  onMediaUploaded,
  selectPhotoDisabled,
}) => {
  const dispatch = useAppDispatch();
  const handleAddFeaturedImage = () => {
    if (selectPhotoDisabled) return;
    dispatch(
      openModal({
        modalName: 'uploadImageModal',
      })
    );
  };

  return (
    <React.Fragment>
      {mediaItems.length > 0 ? (
        <React.Fragment>
          <Tooltip title={'Replace featured image'}>
            <img
              onClick={handleAddFeaturedImage}
              width={'100%'}
              height={400}
              style={{
                objectFit: 'cover',
                borderRadius: 10,
                marginBottom: 20,
                cursor: 'pointer',
              }}
              alt={'blog-featured'}
              src={mediaItems[0].url}
            />
          </Tooltip>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!selectPhotoDisabled && (
            <div
              id={BlogCreationTourIdRegistry.UploadPhotoSection}
              onClick={handleAddFeaturedImage}
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
                color: 'gray',
                backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
                padding: '50px 40px',
                borderRadius: 10,
                marginBottom: 20,
                cursor: 'pointer',
              }}>
              <TbPhoto style={{ fontSize: 100 }} />
              <span style={{ marginTop: 10, fontSize: 15 }}>Click to select a thumbnail image</span>
            </div>
          )}
        </React.Fragment>
      )}
      <UploadImageModal
        actionTitle="Upload Thumbnail"
        singleFile={true}
        existingMedias={mediaItems}
        onUpload={onMediaUploaded}
        disabledSquarePhotoSource
      />
    </React.Fragment>
  );
};

export default BlogAddFeaturedImageButton;
