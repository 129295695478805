import moment from 'moment';
import { Buffer } from 'buffer';
import { PhotoImageDataItem } from '@core/features';

export const shortenString = (str: string, maxLength: number) =>
  str?.length > maxLength ? `${str.slice(0, maxLength).trim()}...` : str;

export const isValidEmail = (mail: string) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);

export const buildCallbackURL = (locationId: string) => {
  return `https://gogl.page/#/ar/${locationId}`;
};

export function makeid(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const generateRandomRgbaStr = () => {
  return `rgba(${Math.floor(Math.random() * 150)}, ${Math.floor(Math.random() * 150)}, ${Math.floor(
    Math.random() * 150
  )})`;
};

export const buildBase64DataUrl = (data: string) => {
  return `data:image/jpeg;base64,${data}`;
};

export const isValidUrl = (url: string) => {
  const hrefRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/\S*)?$/;
  return hrefRegex.test(url);
};

export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export const getUpcomingXMonths = (xMonths: number): number[] => {
  const months: number[] = [];

  // Loop to get the upcoming x months
  for (let i = 0; i < xMonths; i++) {
    months.push(moment().add(i, 'months').unix());
  }
  return months;
};

export function base64ToPhotoImageDataItem(base64DataUrl: string): PhotoImageDataItem {
  const dataPrefix = 'data:image/';
  const [header, data] = base64DataUrl.split(';base64,');
  const extension = header.substring(dataPrefix.length);
  const id = new Date().getTime(); // You can use any appropriate ID generation logic
  const name = `image_${id}.${extension}`;

  return {
    id,
    url: base64DataUrl,
    extension,
    name,
    data: Buffer.from(data, 'base64'),
  };
}

export const formatUrl = (url: string | undefined) => {
  url = url?.toLowerCase();
  if (!url) return '';
  if (url.includes('https') || url.includes('http')) return url;
  return `https://${url}`;
};

export function hexToRgba(hex: string, opacity: number) {
  // Convert each hex character pair into an integer
  const red = parseInt(hex.substring(1, 3), 16);
  const green = parseInt(hex.substring(3, 5), 16);
  const blue = parseInt(hex.substring(5, 7), 16);

  // Concatenate these codes into proper RGBA format
  return ` rgba(${red}, ${green}, ${blue}, ${opacity})`;
}
