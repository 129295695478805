import { GLOBAL_THEME_COLOR } from '@constants/theme';
import React from 'react';

type Props = {
  content: string;
};

const HighlightedSubtext = ({ content }: Props) => {
  return (
    <span
      style={{
        color: GLOBAL_THEME_COLOR.$highlight_color,
        fontWeight: 'bold',
      }}>
      {content}
    </span>
  );
};

export default HighlightedSubtext;
