import { EmailSubscriptionType } from '@core/models/email';
import axios from 'axios';

export type UnsubscribeEmailParams = {
  googleSub: string;
  locationId: string;
  emailSubscriptionType: EmailSubscriptionType;
};

export default class EmailSubscriptionService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/users`;

  public async handleUpdateEmailSubscription(
    googleSub: string,
    locationId: string,
    emailType: EmailSubscriptionType,
    status: boolean
  ) {
    try {
      const url = `${this.url}/${googleSub}/location/${locationId}/${
        status ? 'subscribe-email' : 'unsubscribe-email'
      }`;
      console.log(url);
      await axios.post(
        url,
        {
          emailType,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
