import { GLOBAL_THEME_COLOR } from '@constants/theme';

const CardSectionHeader = ({ title, style }: { title: string; style?: React.CSSProperties }) => {
  return (
    <h3
      className="airbnb-font"
      style={{
        fontSize: 13,
        fontWeight: 600,
        color: GLOBAL_THEME_COLOR.$text_color,
        ...style,
      }}>
      {title.toUpperCase()}
    </h3>
  );
};

export default CardSectionHeader;
