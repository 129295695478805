import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import loadable from '@loadable/component';
import ProtectedRoute from './components/ProtectedRoute';
import React from 'react';

const UpdateEmailSubscriptionCallbackScreen = loadable(
  () => import('@pages/UpdateEmailSubscriptionCallbackScreen')
);
const DashboardLayout = loadable(() => import('@components/DashboardLayout'));
const BusinessBuilderScreen = loadable(() => import('@pages/BusinessBuilderScreen'));
const AutoReviewBuilderScreen = loadable(() => import('@pages/AutoReviewBuilderScreen'));
const QrCodeGeneratorScreen = loadable(() => import('@pages/QrCodeGeneratorScreen'));
const QrCodeGeneratorScreenV2 = loadable(() => import('@pages/QrCodeGeneratorScreen/v2'));
const QrCodeGeneratorReviewScreen = loadable(() => import('@pages/QrCodeGeneratorReviewScreen'));
const AuthScreen = loadable(() => import('@pages/AuthScreen'));
const AnonymousRequestCallbackScreen = loadable(
  () => import('@pages/AnonymousRequestCallbackScreen')
);
const PlaygroundScreen = loadable(() => import('@pages/PlaygroundScreen'));
const InquiryCallbackScreen = loadable(() => import('@pages/InquiryCallbackScreen'));
const ApprovalCallbackScreen = loadable(() => import('@pages/ApprovalCallbackScreen'));
const RequestCallbackScreen = loadable(() => import('@pages/RequestCallbackScreen'));
const BookingAppScreen = loadable(() => import('@pages/BookingAppScreen'));
const ReviewPostApprovalCallbackScreen = loadable(
  () => import('@pages/ReviewPostApprovalCallbackScreen')
);
const BlogPostApprovalCallbackScreen = loadable(
  () => import('@pages/blogs/BlogPostApprovalCallbackScreen')
);

export interface CustomRouteProps {
  isExact: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | React.ComponentType<any>;
  path: string;
}

export const routeList: CustomRouteProps[] = [
  {
    isExact: true,
    component: () => <BusinessBuilderScreen />,
    path: '/r/',
  },
  {
    isExact: true,
    component: () => <BusinessBuilderScreen />,
    path: '/r/:locationPlaceId',
  },
  {
    isExact: true,
    component: () => <AutoReviewBuilderScreen />,
    path: '/review-page/:locationPlaceId',
  },
  // QRCode V1
  {
    isExact: true,
    component: () => <QrCodeGeneratorScreen />,
    path: '/qrcode-generator/:locationPlaceId',
  },
  // QRCode V2
  {
    isExact: true,
    component: () => <QrCodeGeneratorScreenV2 />,
    path: '/qrcode-app/:locationPlaceId',
  },
  {
    isExact: true,
    component: () => <BookingAppScreen />,
    path: '/booking/:locationId',
  },
  {
    isExact: true,
    component: () => (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    path: '/app/',
  },
  {
    isExact: false,
    component: () => (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    path: '/app/:tabName',
  },
  {
    isExact: true,
    component: () => <AuthScreen />,
    path: '/',
  },
  {
    isExact: true,
    component: () => <PlaygroundScreen />,
    path: '/playground',
  },
  {
    isExact: true,
    component: () => <RequestCallbackScreen />,
    path: '/rq/:requestId',
  },
  {
    isExact: true,
    component: () => <InquiryCallbackScreen />,
    path: '/inquiry/:inquiryId/',
  },
  {
    isExact: true,
    component: () => <ApprovalCallbackScreen />,
    path: '/approval/reviews/:googleReviewId/',
  },
  {
    isExact: true,
    component: () => <AnonymousRequestCallbackScreen />,
    path: '/ar/:locationId',
  },
  {
    isExact: true,
    component: () => <QrCodeGeneratorReviewScreen activationRequired={true} shieldFeature={true} />,
    path: '/qr/:businessPlaceId',
  },
  {
    isExact: true,
    component: () => (
      <QrCodeGeneratorReviewScreen activationRequired={false} shieldFeature={false} />
    ),
    path: '/qr-v2/:businessPlaceId',
  },
  {
    isExact: true,
    component: () => <UpdateEmailSubscriptionCallbackScreen />,
    path: '/unsubscribe-email',
  },
  {
    isExact: true,
    component: () => <ReviewPostApprovalCallbackScreen />,
    path: '/approval/posts/:socialPostId/',
  },
  {
    isExact: true,
    component: () => <BlogPostApprovalCallbackScreen />,
    path: '/approval/blogs/:blogPostId/',
  },
];
