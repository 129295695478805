import { fetchCurrentUserReducer } from './fetchCurrentUser.thunk';
import { fetchAccountFacebookReducer } from './fetchAccountFacebook.thunk';
import { logoutReducer } from './logout.thunk';
import { checkSupportSessionReducer } from './checkSupportSession.thunk';

export * from './fetchCurrentUser.thunk';
export * from './logout.thunk';
export * from './checkSupportSession.thunk';

export const authThunkReducers = {
  ...fetchCurrentUserReducer,
  ...fetchAccountFacebookReducer,
  ...logoutReducer,
  ...checkSupportSessionReducer,
};
