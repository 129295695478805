import React from 'react';
import { StudioPhoto, StudioPhotoSource } from '@core/models';
import { MIDDLE_STYLE } from '@constants/responsive';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { shortenString } from '@utils/string.util';
import { EditOutlined } from '@ant-design/icons';

type ImageCardItemProps = {
  item: StudioPhoto;
  isSelected?: boolean;
  loading?: boolean;
  onClick?: (item: StudioPhoto) => void;
  style?: React.CSSProperties;
};

const StudioImageItemCard = ({ item, isSelected, onClick, style }: ImageCardItemProps) => {
  return (
    <div
      className="card hover-to-scale"
      style={{
        position: 'relative',
        margin: '15px 5px',
        cursor: 'pointer',
        overflow: 'hidden',
        ...(isSelected ? { border: '2px solid #4096ff' } : {}),
      }}
      onClick={() => onClick && onClick(item)}>
      <LazyLoadImage
        loading="lazy"
        className="card--image"
        src={item.url}
        width="50%"
        height="50%"
        style={{
          ...style,
        }}
      />
      <div
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'space-between',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}>
        <div
          style={{
            backgroundColor: GLOBAL_THEME_COLOR.$background_color,
            width: '100%',
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            boxShadow: STRIPE_BOX_SHADOW,
            padding: '10px 10px',
            fontSize: 'smaller',
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: 5,
              color: GLOBAL_THEME_COLOR.$highlight_color,
            }}>
            {item.source === StudioPhotoSource.SquareProductPhoto ? (
              <React.Fragment>
                <div style={{ ...MIDDLE_STYLE }}>
                  <img
                    alt="Square logo"
                    src="square-inc.png"
                    width={25}
                    height={25}
                    style={{ marginRight: 5 }}
                  />
                  Square Product
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <EditOutlined /> Annotation
              </React.Fragment>
            )}
          </span>
          <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
            {item.description.length > 0
              ? shortenString(item.description, 70)
              : 'No Annotation. Click to annotate.'}
          </span>
        </div>
      </div>
      {/*<div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>*/}
      {/*  {(item.labels.length <= 6 ? item.labels : item.labels.slice(0, 6).concat('...')).map(*/}
      {/*    label => (*/}
      {/*      <Tag color="blue" style={{ margin: '5px' }}>*/}
      {/*        {label}*/}
      {/*      </Tag>*/}
      {/*    )*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
};

export default StudioImageItemCard;
