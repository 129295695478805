import React, { useEffect, useMemo } from 'react';
import AppIntegrationCard, { AppIntegration } from '@components/AppIntegrationCard';
import { useAppDispatch } from '@core/configureStore';
import { AppIntegrationKey, openDrawer } from '@core/features';
import { Space } from 'antd';
import { useURLQuery } from '@core/hooks/useUrlQuery';

type Props = {};

const AppIntegrationScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const query = useURLQuery();
  const appQuery = useMemo<AppIntegrationKey | undefined>(() => query.get('app') as any, [query]);

  useEffect(() => {
    if (appQuery) {
      onAppIntegrationClicked(appQuery);
    }
  }, [appQuery]);

  const onAppIntegrationClicked = (appId: AppIntegrationKey) => {
    dispatch(
      openDrawer({
        drawerName: 'appIntegrationDrawer',
        extraParams: {
          appId,
        },
      })
    );
  };

  const apps: AppIntegration[] = [
    {
      appId: 'square',
      icon: <img alt="Square logo" src="square-inc.png" />,
      title: 'Square',
      description: 'Automatically send post-purchase review invitations to customers.',
    },
    {
      appId: 'quickbooks',
      icon: <img alt="QuickBooks logo" src="quickbooks.png" />,
      title: 'QuickBooks',
      description: 'Automatically send post-purchase review invitations to customers.',
    },
    {
      appId: 'jobber',
      icon: <img alt="Jobber logo" src="jobber.png" />,
      title: 'Jobber',
      description: 'Automatically send post-purchase review invitations to customers.',
    },
    {
      appId: 'xero',
      icon: <img alt="Jobber logo" src="xero.png" />,
      title: 'Xero',
      description: 'Automatically send post-purchase review invitations to customers.',
    },
    {
      appId: 'openDental',
      icon: <img alt="Open Dental logo" src="open-dental.jpg" />,
      title: 'Open Dental',
      description: 'Automatically send post-appointment review invitations to patients.',
    },
  ];

  return (
    <React.Fragment>
      <Space className="screen-card" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {apps.map(appIntegration => (
          <AppIntegrationCard
            app={appIntegration}
            onClick={() => onAppIntegrationClicked(appIntegration.appId)}
          />
        ))}
      </Space>
    </React.Fragment>
  );
};

export default AppIntegrationScreen;
