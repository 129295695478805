/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Divider, List, Skeleton } from 'antd';
import {
  useAppDispatch,
  useAppNavigation,
  useAuthState,
  useBusinessState,
  useDigitalCreatorMode,
  usePublicRouteService,
} from '@core/.';
import { closeModal } from '@core/features';
import { selectLocation } from '@core/features/business/thunks/selectLocation.thunk';
import { Location } from '@core/models';
import { CheckCircleOutlined, UserSwitchOutlined } from '@ant-design/icons';
import Lottie from 'lottie-react';
import { ReviewLottieFile } from '@components/animations/lottie';
import { logout } from '@core/features/auth/thunks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LoadableContainer } from '..';
import { getGoogleIncrementalUrl } from '@constants/auth';
import { useLocation } from 'react-router';
import { MAX_ONBOARDING_STAGE } from '@constants/pitch-business';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {};

const WelcomeSelectLocationModalContent = (_: Props) => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { user } = useAuthState();
  const { locations, isLoading, error } = useBusinessState();
  const [countConversion, setCountConversion] = useState<boolean>(false);
  const { handleSwitchDigitalCreatorMode } = useDigitalCreatorMode();
  const publicRouteService = usePublicRouteService();
  const [notifying, setNotifying] = useState(false);
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || '/';

  const onSelectLocation = async (locationName: string) => {
    try {
      if ((window as any).gtag && user) {
        console.log(
          '################# TRACK ONE GOOGLE ADS CONNECT GOOGLE PROFILE CONVERSION for user ' +
            user.email
        );
        (window as any).gtag('event', 'conversion', {
          send_to: 'AW-11250660118/gbiNCPua68QZEJbm3fQp',
          transaction_id: user.email,
        });
      }
      await dispatch(selectLocation(locationName));
      if (user?.selected_location?.onboarding_stages?.length === MAX_ONBOARDING_STAGE)
        navigate('home');
      else navigate('getting-started');
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'welcomeLocationModal',
      })
    );
  };

  const handleSwitchAccount = () => {
    dispatch(logout());
  };

  const renderDescription = (item: Location) => {
    const storeFrontAddress = item.storefrontAddress;
    if (!storeFrontAddress) return 'Unknown location address';
    const storeFrontAddressLines = storeFrontAddress.addressLines || [];
    return `${storeFrontAddressLines?.[0]}, ${storeFrontAddress?.locality}, ${storeFrontAddress?.regionCode}`;
  };

  const handleNotifiedAction = async (message: string, action: () => any) => {
    try {
      setNotifying(true);
      await publicRouteService.sendUserActivitySlackNotification(user?.email || 'Unknown', message);
    } catch (error) {
      console.log(error);
    }
    setNotifying(false);
    action();
  };

  const noPermissionGranted = useMemo(() => error === 'MISSING_PERMISSION', [error]);

  useEffect(() => {
    console.log('############ CHECKING CONVERSION', noPermissionGranted, countConversion, user);
    if (noPermissionGranted && user && !countConversion) {
      if ((window as any).gtag) {
        console.log('################# TRACK ONE GOOGLE ADS CONVERSION for user ' + user.email);
        (window as any).gtag('event', 'conversion', {
          send_to: 'AW-11250660118/2YszCPmY-bcYEJbm3fQp',
          transaction_id: user.email,
        });
      }

      if ((window as any).rdt) {
        (window as any).rdt('init', 'a2_doqqz20fx1t5', { email: user.email });
        (window as any).rdt('track', 'SignUp');
        console.log('################# TRACK ONE REDIT ADS CONVERSION for user ' + user.email);
      }

      setCountConversion(true);
    }
  }, [noPermissionGranted]);

  const isLocationSelected = useCallback(
    (locationItem: Location) => {
      return locationItem.metadata.placeId
        ? locationItem.metadata.placeId === user?.selected_location?.place_id
        : locationItem.title === user?.selected_location?.title;
    },
    [user]
  );

  return (
    <LoadableContainer isLoading={isLoading} loadComponent={<Skeleton />}>
      {noPermissionGranted ? (
        <div>
          <p>To get started, please connect your Google Business Profile with Amazely.</p>
          <img
            src="welcome-testimonial.png"
            alt="Google My Business"
            style={{ margin: '20px 0px' }}
          />
          <Button
            type="primary"
            className="ant-btn-lg"
            style={{ width: '100%', marginTop: 10, height: 50, ...MIDDLE_STYLE }}
            loading={notifying}
            onClick={async () => {
              handleNotifiedAction(
                '[ONBOARDING LOCATION] Attempt to connect Google Business',
                () => {
                  window.location.href = getGoogleIncrementalUrl(
                    user?.email.toString() || '',
                    from
                  );
                }
              );
            }}>
            Connect your Google Business
          </Button>
          <Button
            style={{ width: '100%', marginTop: 10 }}
            onClick={async () => {
              handleNotifiedAction('[ONBOARDING LOCATION] Switch account', handleSwitchAccount);
            }}>
            <UserSwitchOutlined /> Switch Account
          </Button>
          <br /> <br />
          <p>
            Note that Google might request for all accesses. However, Amazely only works with
            reviews. <span> We do not edit your listings. </span> Amazely is a{' '}
            <a
              onClick={async () => {
                handleNotifiedAction(
                  '[ONBOARDING LOCATION] Learn about security, Google verified app partner clicked',
                  () => {
                    window.open(
                      'https://docs.amazely.co/en/articles/8599115-amazely-security-overview'
                    );
                  }
                );
              }}
              target="_blank">
              Google verified app partner.
            </a>
          </p>
        </div>
      ) : (
        <div>
          <p>
            Please select your preferred location to begin exploring and enjoying all the amazing
            features our platform has to offer.
          </p>
          <LoadableContainer
            isLoading={locations.length === 0}
            loadComponent={
              <div style={{ marginTop: 10 }}>
                <Alert
                  message={
                    <span>
                      No location found. Please find add{' '}
                      <span style={{ fontWeight: 'bold' }}>{user?.email}</span> as manager of your
                      business locations following this guide:
                      <a
                        style={{ marginLeft: 5 }}
                        href="https://support.google.com/business/answer/3403100?hl=en">
                        Learn how to add manager to your business.
                      </a>
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            }>
            <Lottie
              style={{
                height: 300,
              }}
              animationData={ReviewLottieFile}
            />
            <List
              dataSource={locations}
              pagination={{
                onChange: page => {
                  console.log(page);
                },
                pageSize: 5,
                hideOnSinglePage: true,
              }}
              renderItem={(item, index) => (
                <List.Item
                  key={item.name}
                  actions={[
                    !!user && !isLocationSelected(item) ? (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => onSelectLocation(item.name)}>
                        Select
                      </Button>
                    ) : (
                      <CheckCircleOutlined style={{ fontSize: 20 }} />
                    ),
                  ]}>
                  <List.Item.Meta
                    title={<p>{item.title}</p>}
                    description={renderDescription(item)}
                  />
                </List.Item>
              )}
            />
          </LoadableContainer>
          <Button
            className="airbnb-font"
            style={{ width: '100%', marginTop: 10 }}
            type="primary"
            onClick={handleSwitchAccount}>
            <UserSwitchOutlined /> Switch Account
          </Button>
          {!!user?.selected_location && (
            <Button
              className="airbnb-font"
              style={{ width: '100%', marginTop: 10 }}
              onClick={() =>
                dispatch(
                  closeModal({
                    modalName: 'welcomeLocationModal',
                  })
                )
              }>
              Cancel
            </Button>
          )}
        </div>
      )}
      <Divider />
      <div style={{ fontSize: 'smaller' }}>
        If you don't have a Google Business Profile,{' '}
        <a
          onClick={() =>
            handleNotifiedAction(
              '[ONBOARDING LOCATION] Learn how to create a Google location',
              () => window.open('https://www.google.com/business/')
            )
          }>
          create one (5 minutes)
        </a>{' '}
        or{' '}
        <a onClick={() => handleSwitchDigitalCreatorMode(location)}>
          use Amazely as a digtal creator
        </a>
      </div>
    </LoadableContainer>
  );
};

export default WelcomeSelectLocationModalContent;
