import { Button, Modal, Skeleton } from 'antd';
import {
  useAppDispatch,
  useAppNavigation,
  useAuthState,
  useBlogOnboardingState,
  useBlogState,
  useModalState,
} from '@core/.';
import { SnackbarItem, closeModal, enqueueNotification } from '@core/features';
import { OnboardingChecklistContainer } from '..';
import { BlogOnboardingStage } from '@core/hooks/useBlogPageTourStore';
import { ArrowRightOutlined } from '@ant-design/icons';
import { storeJsonData } from '@utils/cache.util';
import { useMemo } from 'react';
import { LocalLocation } from '@core/models';
import { heroApiService } from '@services/service-register';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';

type Props = {};

const BlogOnboardingModal = (_: Props) => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { user } = useAuthState();
  const { blogOnboardingModal } = useModalState();
  const { isLoading, blogPosts } = useBlogState();
  const { maxOnboardingStages, onboardingStages, belowOnboardingStages } = useBlogOnboardingState();
  const location = useMemo<LocalLocation | undefined>(() => user?.selected_location, [user]);

  const stageChecked = (stage: BlogOnboardingStage) => {
    return onboardingStages.includes(stage);
  };

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'blogOnboardingModal',
      })
    );
  };

  const handleSetupLater = () => {
    if (!location) return;
    storeJsonData(`${location.location_id}:blog-onboarding`, true);
    handleCloseModal();
  };

  const handleStageComponentClicked = (stage: BlogOnboardingStage) => {
    if (stageChecked(stage)) return;
    let modalClosed = true;
    switch (stage) {
      case BlogOnboardingStage.AddBusinesWebsite:
        navigate<string>('settings?field_focus=website');
        break;
      case BlogOnboardingStage.CreateYourFirstBlog:
        navigate<string>('blogs/new?guide=new');
        break;
      case BlogOnboardingStage.PublishBlogPost:
        if (blogPosts.length > 0) navigate<string>(`blogs/${blogPosts[0].id}`);
        else navigate<string>(`blogs/new`);
        break;
      case BlogOnboardingStage.GenerateBlogPost:
        navigate<string>('blogs?guide=generate');
        break;
      case BlogOnboardingStage.AutoShareBlogPostToSocial:
        const handleToggleAutoShareOn = async () => {
          try {
            await heroApiService.updateLocationBlogAssistantProfile({
              autoShareToSocial: true,
            });
            await dispatch(fetchSelectedLocation());
          } catch (error) {
            dispatch(
              enqueueNotification({
                name: 'Failed to turn on the feature',
                type: 'Error',
              } as SnackbarItem)
            );
          }
        };
        Modal.confirm({
          title: 'Auto Share Blog to Social Channels',
          content:
            'Blog post will be shared automatically to your connected social channels on published',
          type: 'confirm',
          okCancel: true,
          okText: 'Turn On',
          cancelText: 'Later',
          autoFocusButton: 'ok',
          onOk: handleToggleAutoShareOn,
        });
        modalClosed = false;
        break;
    }
    if (modalClosed) handleCloseModal();
  };

  const modalOpen = useMemo<boolean>(
    () => !!location && blogOnboardingModal,
    [blogOnboardingModal, location]
  );

  return (
    <Modal
      centered
      open={belowOnboardingStages && modalOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      mask={undefined}
      footer={
        belowOnboardingStages
          ? [
              <Button onClick={handleSetupLater}>
                Setup Later <ArrowRightOutlined style={{ marginLeft: 10 }} />
              </Button>,
            ]
          : []
      }
      width={600}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <OnboardingChecklistContainer
          containerTitle="Business Blog Site Setup"
          containerDescription={
            <a href="/#/app/instruction">Learn more about business blog site in this guide.</a>
          }
          currentOnboardingStages={onboardingStages.length}
          maxOnboardingStages={maxOnboardingStages}
          onStageChecked={stageChecked}
          onStageComponentClicked={handleStageComponentClicked}
          stageComponents={[
            {
              stage: BlogOnboardingStage.AddBusinesWebsite,
              title: 'Add your business website',
            },
            {
              stage: BlogOnboardingStage.CreateYourFirstBlog,
              title: 'Create a first blog',
            },
            {
              stage: BlogOnboardingStage.PublishBlogPost,
              title: 'Publish a blog post',
            },
            {
              stage: BlogOnboardingStage.GenerateBlogPost,
              title: 'Generate new blog posts',
            },
            {
              stage: BlogOnboardingStage.AutoShareBlogPostToSocial,
              title: 'Automatically share a published blog post',
            },
          ]}
        />
      )}
    </Modal>
  );
};

export default BlogOnboardingModal;
