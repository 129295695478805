import React from 'react';
import { DescriptionItem } from '..';
import SolidColorButton from '@components/SolidColorButton';

type Props = {
  title: string;
  value: string;
  onValueChanged: (value: string) => void;
};

const BackgroundSolidSetting = ({ title, value, onValueChanged }: Props) => {
  return (
    <React.Fragment>
      <DescriptionItem
        title={title}
        style={{ margin: 0 }}
        content={<SolidColorButton value={value} onValueChanged={value => onValueChanged(value)} />}
      />
    </React.Fragment>
  );
};

export default BackgroundSolidSetting;
