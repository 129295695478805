/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import LoadableContainer from '@components/LoadableContainer';
import { Empty, Space } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import SocialPostCard from '@components/SocialPostCard';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { SocialPost, SocialPostStage } from '@core/models';
import SocialPostMonthContainerLayout from '@components/SocialPostTab/SocialPostMonthContainerLayout';
import { fetchSocialPosts } from '@core/features/studio/thunks';
import { useStudioTourStore } from '@core/hooks';

interface Props {
  stage: SocialPostStage | string;
  selectedMonth: string | undefined;
  socialPosts: SocialPost[];
}
const SocialPostGridViewTab: React.FC<Props> = ({ stage, selectedMonth, socialPosts }) => {
  const { user } = useAuthState();
  const { selectedTabKey } = useStudioTourStore();
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSocialPosts());
  }, [selectedTabKey]);

  const GridViewRenderer = ({ currentSocialPosts }: { currentSocialPosts: SocialPost[] }) => {
    return (
      <React.Fragment>
        <LoadableContainer
          isLoading={currentSocialPosts.length === 0}
          loadComponent={
            <div style={{ ...MIDDLE_STYLE }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No social posts found</span>}
              />
            </div>
          }>
          <Space
            style={{
              ...MIDDLE_STYLE,
              flexWrap: 'wrap',
              marginTop: 20,
            }}>
            <React.Fragment>
              {currentSocialPosts.map(socialPost => (
                <SocialPostCard
                  hideOverlay
                  socialPost={socialPost}
                  locationId={locationId as any}
                />
              ))}
            </React.Fragment>
          </Space>
        </LoadableContainer>
      </React.Fragment>
    );
  };

  return stage === 'ALL' ? (
    <GridViewRenderer currentSocialPosts={socialPosts} />
  ) : (
    <React.Fragment>
      {selectedMonth ? (
        <SocialPostMonthContainerLayout month={selectedMonth} socialPosts={socialPosts}>
          {({ socialPosts: currentSocialPosts }) => (
            <GridViewRenderer currentSocialPosts={currentSocialPosts} />
          )}
        </SocialPostMonthContainerLayout>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

export default SocialPostGridViewTab;
