/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { PhotoImageDataItem } from '@core/features';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { AnnotatedImageItemCard } from '@components/AnnotatedImageItemCard';

type ImageCardItemProps = {
  item: PhotoImageDataItem;
  isSelected?: boolean;
  style?: React.CSSProperties;
  onClick?: (item: PhotoImageDataItem) => void;
};

const FileImageItemCard = ({ style, item, isSelected, onClick }: ImageCardItemProps) => {
  return (
    <div
      style={{
        width: 300,
        objectFit: 'cover',
        margin: '5px 5px',
        cursor: 'pointer',
        borderRadius: 10,
        aspectRatio: '1/1',
        overflow: 'hidden',
        ...style,
        ...(isSelected ? { border: `6px solid ${GLOBAL_THEME_COLOR.$highlight_color}` } : {}),
      }}
      onClick={() => onClick && onClick(item)}>
      <AnnotatedImageItemCard url={item.url} description={item.description} />
    </div>
  );
};

export default FileImageItemCard;
