import { MIDDLE_STYLE } from '@constants/responsive';
import React from 'react';
import {
  FACEBOOK_STUDIO_PAGE_OAUTH,
  GOOGLE_BUSINESS_LOCATION_OAUTH,
  INSTAGRAM_STUDIO_BUSINESS_OAUTH,
  SocialAccountContact,
  ThirdPartyOauthType,
} from '@core/models';
import { Button, Divider, Popover, Space } from 'antd';

interface Props {
  publishedAccounts: SocialAccountContact[];
}

const renderAccountLogo = (type: ThirdPartyOauthType) => {
  switch (type) {
    case FACEBOOK_STUDIO_PAGE_OAUTH:
      return 'https://app.amazely.co/social-logo/facebook_logo.webp';
    case INSTAGRAM_STUDIO_BUSINESS_OAUTH:
      return 'https://app.amazely.co/social-logo/instagram_logo.webp';
    case GOOGLE_BUSINESS_LOCATION_OAUTH:
      return 'https://app.amazely.co/social-logo/google_logo.webp';
  }
};

const SocialAccountContactButtonList: React.FC<{
  socialAccountContacts: SocialAccountContact[];
}> = ({ socialAccountContacts }) => {
  return (
    <Space
      direction={'vertical'}
      style={{ padding: '10px 10px', maxHeight: 600, overflowY: 'auto' }}>
      {socialAccountContacts.map((account, index) => (
        <React.Fragment>
          <Space
            size={'large'}
            style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '100%' }}>
            <Space style={{ ...MIDDLE_STYLE }}>
              <img
                width={30}
                height={30}
                style={{ borderRadius: 50 }}
                src={renderAccountLogo(account.type)}
                alt={'social logo'}
              />
              <p style={{ margin: 0 }}>{account.name}</p>
            </Space>
          </Space>
          {index !== socialAccountContacts.length - 1 && <Divider style={{ margin: '0px 0px' }} />}
        </React.Fragment>
      ))}
    </Space>
  );
};

const PublishedAccountsContainer: React.FC<Props> = ({ publishedAccounts }) => {
  return (
    <React.Fragment>
      <Popover
        placement="bottom"
        content={<SocialAccountContactButtonList socialAccountContacts={publishedAccounts} />}>
        <Button>
          <span style={{ marginLeft: 10 }}>{publishedAccounts.length} social accounts</span>
        </Button>
      </Popover>
    </React.Fragment>
  );
};

export default PublishedAccountsContainer;
