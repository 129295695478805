import React from 'react';
import {
  CalendarModal,
  ReviewDrawer,
  SelectLocationModal,
  UploadCSVModal,
  WelcomeSelectLocationModal,
  SelectSocialAccountsModal,
  SocialPostDetailModal,
  CalendarItemDetailModal,
  SocialPostCreationModal,
  CustomizeAiProfileModal,
  AssistantScheduleSettingModal,
  CustomizeAiResponderModal,
  PreviewGenerateScheduleModal,
  ManageLicenseModal,
  CustomizeBlogAiProfileModal,
  BlogOnboardingModal,
  ShareBlogPostToSocialModal,
  SocialPostSelectionModal,
  CreateNewReviewRequestModal,
  PreviewPhotoPickerModal,
  FreelancerModeSetupModal,
  SubscriptionCancellationModal,
} from '..';
import AppIntegrationDrawer from '@components/AppIntegrationDrawer';

type Props = {};

const ModalContainer = (props: Props) => {
  return (
    <React.Fragment>
      <ReviewDrawer />
      <SelectLocationModal />
      <CalendarModal />
      <UploadCSVModal />
      <SocialPostDetailModal />
      <SocialPostCreationModal />
      <WelcomeSelectLocationModal />
      <SelectSocialAccountsModal />
      <CalendarItemDetailModal />
      <CustomizeAiProfileModal />
      <CustomizeAiResponderModal />
      <AssistantScheduleSettingModal />
      <ManageLicenseModal />
      <PreviewGenerateScheduleModal />
      <PreviewPhotoPickerModal />
      <CustomizeBlogAiProfileModal />
      <BlogOnboardingModal />
      <AppIntegrationDrawer />
      <ShareBlogPostToSocialModal />
      <SocialPostSelectionModal />
      <CreateNewReviewRequestModal />
      <FreelancerModeSetupModal />
      <SubscriptionCancellationModal />
    </React.Fragment>
  );
};

export default ModalContainer;
