import { createAsyncThunk } from '@reduxjs/toolkit';
import { facebookApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { AuthReducerProps } from '../type';

export const fetchAccountFacebook = createAsyncThunk(
  'business/fetchAccountFacebook',
  async (_, thunkApi) => {
    try {
      return await facebookApiService.getFacebookProfile();
    } catch (error: any) {
      console.log(error);
      return undefined;
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchAccountFacebook);

export const fetchAccountFacebookReducer = {
  [fulfilled]: (state, action) => {
    state.isAuthLoading = false;
    state.integration.facebook = action.payload;
  },
  [rejected]: (state, action) => {
    state.isAuthLoading = false;
    state.integration.facebook = undefined;
  },
  [pending]: (state, action) => {
    state.isAuthLoading = true;
  },
} as ExtraReducer<AuthReducerProps>;
