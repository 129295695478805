/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { Button, Divider, Steps } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import OnboardingPhotoPreparationStage from '@components/OnboardingPhotoPreparationStage';
import { PhotoImageDataItem } from '@core/features';
import OnboardingPostPreviewStage from '@components/OnboardingPostPreviewStage';
import { useAppNavigation } from '@core/hooks';

type Props = {};

enum SocialStudioOnboardingStage {
  Welcome = 1,
  Explanation = 2,
  PhotoPreparation = 3,
  SocialPostPreview = 4,
}

const SocialStudioOnboardingScreen = (props: Props) => {
  const { navigate } = useAppNavigation();
  const [preparedPhotos, setPreparedPhotos] = useState<PhotoImageDataItem[]>([]);
  const [stage, setStage] = useState<SocialStudioOnboardingStage>(
    SocialStudioOnboardingStage.Welcome
  );

  const WelcomeStage = () => {
    return (
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', padding: '0px 30px' }}>
        <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Welcome to Social Studio!</h1>
        <p
          style={{
            maxWidth: 400,
            textAlign: 'center',
            color: GLOBAL_THEME_COLOR.$dark_text_color,
          }}>
          Crafting beautiful social posts and showcasing best reviews to take your social presence
          to the next level
        </p>
        <img src="jumbotron/social2.png" height={350} style={{ margin: '20px 0px' }} />
        <Button
          onClick={() => setStage(SocialStudioOnboardingStage.Explanation)}
          type="primary"
          size="large"
          style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
          Learn how does it work <ArrowRightOutlined style={{ marginLeft: 10, marginRight: 0 }} />
        </Button>
      </div>
    );
  };

  const ExplanationStage = () => {
    const [step, setStep] = useState(0);
    return (
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', padding: '0px 30px' }}>
        <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>How does it work?</h1>
        <Divider />
        <Steps
          direction="vertical"
          current={step}
          items={[
            {
              title: 'Upload Photo',
              description: step === 0 && (
                <React.Fragment>
                  <div style={{ ...MIDDLE_STYLE }}>
                    <img
                      src="jumbotron/photo-stack.webp"
                      height={150}
                      style={{ marginRight: 25 }}
                    />
                    <span>
                      Social studio requires a small action from your end to upload photo. You can{' '}
                      <span style={{ fontWeight: 'bold' }}>manually upload photo</span>, choose from{' '}
                      <span style={{ fontWeight: 'bold' }}>Google Business</span> or{' '}
                      <span style={{ fontWeight: 'bold' }}>Stock images gallery</span>
                    </span>
                  </div>
                  <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => setStep(step => step + 1)}
                      type="primary"
                      size="large"
                      style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
                      Crafting social posts{' '}
                      <ArrowRightOutlined style={{ marginLeft: 10, marginRight: 0 }} />
                    </Button>
                  </div>
                </React.Fragment>
              ),
            },
            {
              title: 'Craft social post',
              description: step === 1 && (
                <React.Fragment>
                  <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
                    <img
                      src="jumbotron/social-post-generate.webp"
                      height={200}
                      style={{ marginBottom: 20 }}
                    />
                    <br />
                    <span>
                      After uploading your business photos, Amazely will do the rest to
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        craft social posts
                      </span> automatically{' '}
                    </span>
                  </div>
                  <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => setStep(step => step + 1)}
                      type="primary"
                      size="large"
                      style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
                      Schedule & Publish{' '}
                      <ArrowRightOutlined style={{ marginLeft: 10, marginRight: 0 }} />
                    </Button>
                  </div>
                </React.Fragment>
              ),
            },
            {
              title: 'Schedule & Publish',
              description: step === 2 && (
                <React.Fragment>
                  <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
                    <img
                      src="jumbotron/schedule-posts.webp"
                      height={150}
                      style={{ marginBottom: 10, objectFit: 'fill' }}
                    />
                    <br />
                    <span>
                      Amazely automatically schedules your social posts and distribute them on
                      multiple social platform
                    </span>
                  </div>
                  <div style={{ ...MIDDLE_STYLE, justifyContent: 'flex-end' }}>
                    <Button
                      onClick={() => setStage(SocialStudioOnboardingStage.PhotoPreparation)}
                      type="primary"
                      size="large"
                      style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
                      Get started <ArrowRightOutlined style={{ marginLeft: 10, marginRight: 0 }} />
                    </Button>
                  </div>
                </React.Fragment>
              ),
            },
          ]}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        ...MIDDLE_STYLE,
        backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
        paddingTop: 50,
      }}>
      <div
        style={{
          boxShadow: STRIPE_BOX_SHADOW,
          backgroundColor: GLOBAL_THEME_COLOR.$background_color,
          padding: '30px 20px',
          border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          height: 'fit-content',
          width: '100%',
          maxWidth: 1000,
        }}>
        {stage === SocialStudioOnboardingStage.Welcome && <WelcomeStage />}
        {stage === SocialStudioOnboardingStage.Explanation && <ExplanationStage />}
        {stage === SocialStudioOnboardingStage.PhotoPreparation && (
          <OnboardingPhotoPreparationStage
            onNextButtonClicked={(photos: PhotoImageDataItem[]) => {
              setPreparedPhotos(photos);
              setStage(SocialStudioOnboardingStage.SocialPostPreview);
            }}
          />
        )}
        {stage === SocialStudioOnboardingStage.SocialPostPreview && (
          <OnboardingPostPreviewStage
            preparedPhotos={preparedPhotos}
            onNextButtonClicked={() => {
              navigate('studio');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SocialStudioOnboardingScreen;
