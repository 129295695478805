import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { StudioReducerProps } from '../type';
import { socialStudioApiService } from '@services/service-register';

export const fetchSocialPosts = createAsyncThunk('studio/fetchSocialPosts', async (_, thunkApi) => {
  try {
    const socialPosts = await socialStudioApiService.getLocationSocialPosts();
    return socialPosts;
  } catch (error: any) {
    throw new Error(error);
  }
});

const [fulfilled, pending, rejected] = getSideEffectState(fetchSocialPosts);

export const fetchSocialPostsReducer = {
  [fulfilled]: (state, action: PayloadAction<any[]>) => {
    state.socialPosts = action.payload;
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.socialPosts = [];
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<StudioReducerProps>;
