import { useAuthState } from '@core/configureStore';
import { useBreakpoint } from '@core/hooks';
import { PartialShowcasedReview } from '@core/models';
import { heroApiService } from '@services/service-register';
import { Optional } from '@utils/type.util';
import SkeletonImage from 'antd/es/skeleton/Image';
import React, { useEffect, useMemo, useState } from 'react';

type Props = {
  ref: Optional<React.MutableRefObject<null | HTMLDivElement>>;
  review: PartialShowcasedReview;
  scale?: number;
};

const HtmlReviewCardFromTemplate = ({ ref, review, scale }: Props) => {
  const { user } = useAuthState();
  const reviewTemplate = useMemo(() => user?.selected_location.review_template_setting, [user]);
  const [currentReviewHtml, setCurrentReviewHtml] = useState<string | undefined>(undefined);
  const { isTablet } = useBreakpoint();

  useEffect(() => {
    const init = async () => {
      if (reviewTemplate && review) {
        const reviewTemplateHtml = await heroApiService.showcaseReviewWithTemplateId(
          review,
          reviewTemplate?.templateId || 0
        );
        setCurrentReviewHtml(reviewTemplateHtml);
      }
    };
    init();
  }, [reviewTemplate, review]);

  return currentReviewHtml ? (
    <div
      ref={ref}
      style={{
        width: '800px',
        margin: '20px 0px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        transform: !scale
          ? isTablet
            ? 'scale(0.4)'
            : 'scale(0.8)'
          : `scale(${scale?.toString()})`,
      }}
      dangerouslySetInnerHTML={{
        __html: `<div class="overridden-review-html">${currentReviewHtml}</div>`,
      }}></div>
  ) : (
    <SkeletonImage />
  );
};

export default HtmlReviewCardFromTemplate;
