import { SocialPlatformType } from '@components/BusinessSocialPostCard';
import { GENERATE_SOCIAL_POSTS } from '@constants/pitch-business';
import { MIDDLE_STYLE } from '@constants/responsive';
import { STRIPE_BOX_SHADOW } from '@constants/theme';
import { useGenerationDataStore } from '@core/hooks';
import { Skeleton } from 'antd';
import React from 'react';
import { BusinessSocialPostCard, LoadableContainer } from '..';

type Props = {};

const PitchSocialPostsSection = (props: Props) => {
  const { businessData, stageLoading } = useGenerationDataStore();
  return (
    <div id="generated-social-post">
      <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
        <h2 className="pitch_title">Grow your social presence</h2>
        <div className="pitch_subtitle">
          No more manual postings! All you need to do is upload your best photos. Amazely will craft
          and propose a month-by-month posting schedule for your approvals. The photos below are
          only testing samples.
        </div>
      </div>
      <LoadableContainer
        loadComponent={<Skeleton active style={{ marginTop: 20 }} />}
        isLoading={businessData.socialPosts.length === 0 || stageLoading[GENERATE_SOCIAL_POSTS]}>
        <div style={{ flexWrap: 'wrap', marginTop: 20, ...MIDDLE_STYLE }}>
          {businessData.socialPosts.map((socialPost, index) => (
            <BusinessSocialPostCard
              style={{ boxShadow: STRIPE_BOX_SHADOW }}
              socialPost={socialPost}
              type={(['facebook', 'instagram', 'pinterest'] as SocialPlatformType[])[index % 3]}
            />
          ))}
        </div>
      </LoadableContainer>
    </div>
  );
};

export default PitchSocialPostsSection;
