import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { authThunkReducers } from './thunks';
import { AuthReducerProps, AuthState } from './type';
import { LocalLocation } from '@core/models';

const authSlice = createSlice<AuthReducerProps, SliceCaseReducers<AuthReducerProps>, string>({
  name: SliceName.Authentication,
  initialState: {
    isAuthLoading: false,
    authState: AuthState.None,
    user: undefined,
    isSupportSession: false,
    integration: {
      facebook: undefined,
      instagram: undefined,
    },
  },
  reducers: {
    updateSelectedLocation: (state, action: PayloadAction<LocalLocation>) => {
      if (state.user) state.user.selected_location = action.payload;
    },
    connectToThirdParty: (
      state,
      action: PayloadAction<{
        platform: 'facebook' | 'instagram';
        profile: any;
      }>
    ) => {
      const platform = action.payload.platform;
      state.integration[platform] = action.payload.profile;
      return state;
    },
  },
  extraReducers: authThunkReducers,
});

export const { connectToThirdParty, updateSelectedLocation } = authSlice.actions;
export const authReducer = authSlice.reducer;
