import React from 'react';
import { Button, Card, Divider, Input, notification, Rate } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GoogleSmartReview } from '@core/models';
import { shortenString } from '@utils/string.util';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { DescriptionItem } from '..';
import { STRIPE_BOX_SHADOW } from '@constants/theme';
import moment from 'moment';
import { US_DATE_LAYOUT } from '@utils/date.util';

type Props = {
  review: GoogleSmartReview;
  inApp?: boolean;
};

const BusinessReviewCard = ({ review, inApp }: Props) => {
  const { isMobile } = useBreakpoint();
  const [api, contextHolder] = notification.useNotification();

  const notify = () => {
    api.open({
      message: 'Please sign in to setup your business first.',
    });
  };
  return (
    <Card
      className="no-padding"
      style={{
        margin: '10px 0px',
        borderRadius: 15,
        cursor: 'pointer',
        fontSize: 12,
        boxShadow: inApp ? 'none' : STRIPE_BOX_SHADOW,
      }}>
      <div style={{ maxWidth: isMobile ? '100%' : 640, padding: '20px 30px' }}>
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
          <div style={{ ...MIDDLE_STYLE }}>
            <img
              referrerPolicy="no-referrer"
              style={{ width: 40, marginRight: 20 }}
              src={review.profile_photo_url}
              alt={review.author_name}
            />
            <div>
              <h2>{review.author_name}</h2>
              {moment.unix(review.time).format(`dddd, ${US_DATE_LAYOUT}`)}
            </div>
          </div>
        </div>
        <Divider />
        <h3>Customer Review</h3>
        <DescriptionItem title="Rating" content={<Rate value={review.rating} disabled />} />
        <p>{shortenString(review.text, 300) || 'N/A'}</p>
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
            flexWrap: 'wrap',
          }}>
          <h3>Written by Amazely</h3>
          {!inApp && (
            <span>
              {contextHolder}
              <Button className="success-btn" type="primary" onClick={notify}>
                Approve
              </Button>
            </span>
          )}
        </div>
        <Input.TextArea
          style={{ fontSize: '13px' }}
          placeholder="Respond to the review"
          value={review.generated_response?.replace('\n\n', '')}
          autoSize
        />
      </div>
    </Card>
  );
};

export default BusinessReviewCard;
