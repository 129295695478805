import { MonthlyGenerateParams, StreamApiMethod, callStreamApi } from './stream-api.service';

const BACKEND_URL = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/blogs`;

export type MonthlyGenerateBlogPostsParams = MonthlyGenerateParams & { topics: string[] };

export interface BlogContentGenerateParams {
  title: string;
  primary_category: string;
  topic: string;
  keywords: string[];
}

export default class BlogStreamApiService {
  public streamGenerateBlogPosts: StreamApiMethod<MonthlyGenerateBlogPostsParams> = (
    params,
    abortSignal?: AbortSignal
  ): Promise<ReadableStreamDefaultReader<Uint8Array>> => {
    return callStreamApi(`${BACKEND_URL}/batch-generate/stream`, 'POST', params, abortSignal);
  };

  public streamGenerateBlogPostContent: StreamApiMethod<BlogContentGenerateParams> = (
    params,
    abortSignal?: AbortSignal
  ) => {
    return callStreamApi(`${BACKEND_URL}/generate/stream`, 'POST', params, abortSignal);
  };
}
