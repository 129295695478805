import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { SnackbarItem, SnackbarReducerProps } from './type';

const snackbarSlice = createSlice<
  SnackbarReducerProps,
  SliceCaseReducers<SnackbarReducerProps>,
  string
>({
  name: SliceName.Snackbar,
  initialState: {
    queue: [],
    front: null,
    lastModifiedDate: undefined,
  },
  reducers: {
    enqueueNotification(state, action: PayloadAction<SnackbarItem>) {
      state.queue.push(action.payload);
      state.lastModifiedDate = +new Date();
    },
    dequeueNotification(state, _) {
      state.front = state.queue.shift();
    },
  },
});

export const { enqueueNotification, dequeueNotification } = snackbarSlice.actions;
export const snackbarReducer = snackbarSlice.reducer;
