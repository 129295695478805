/* eslint-disable react-hooks/exhaustive-deps */
import Joyride from 'react-joyride';
import { EditOutlined, LockOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import LoadableContainer from '@components/LoadableContainer';
import OwnerSettingRow from '@components/OwnerSettingRow';
import TimezoneSettingRow from '@components/TimezoneSettingRow';
import ReviewUrlSettingRow from '@components/ReviewUrlSettingRow';
import RequestTemplateSettingRow from '@components/RequestTemplateSettingRow';
import SplashScreen from '@components/SplashScreen';
import TitleWithText from '@components/TitleWithText';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { updateOwner } from '@core/features/business/thunks/updateOwner';
import { updateTimezone } from '@core/features/business/thunks/updateTimezone.thunk';
import { updateReviewUrl } from '@core/features/business/thunks/updateReviewUrl.thunk';
import { AccountContact, LicenseType, LocalLocation } from '@core/models';
import { Button, Card, Divider, Input, Popover, Space, Switch, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import EnableAutomaticResponseContainer from '@components/EnableAutomaticResponseContainer';
import {
  CardSectionHeader,
  EmailSubscriptionContainer,
  LanguageSettingRow,
} from '@components/index';
import { MIDDLE_STYLE } from '@constants/responsive';
import CustomizeAiResponderButton from '@components/CustomizeAiResponderButton';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { heroApiService, publicRouteService, reviewApiService } from '@services/service-register';
import { enqueueNotification } from '@core/features';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { updateGeneralInfo } from '@core/features/business/thunks/updateGeneralInfo.thunk';
import { useURLQuery } from '@core/hooks/useUrlQuery';
import { useAppNavigation, useJoyrideHelpers } from '@core/hooks';
import { AppRoutes } from '@utils/route.util';
import { updateLanguage } from '@core/features/business/thunks/updateLanguage.thunk';

type Props = {};

const SettingsScreen = (props: Props) => {
  const query = useURLQuery();
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { user, isAuthLoading } = useAuthState();
  const [businessDescription, setBusinessDescription] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<LocalLocation | undefined>(undefined);
  const [editOwner, setEditOwner] = useState(false);
  const [owner, setOwner] = useState<AccountContact>({
    email: '',
    given_name: '',
    family_name: '',
  } as AccountContact);
  // fieldFocus is used to run the UX tour
  const fieldFocus = useMemo(() => query.get('field_focus'), [query]);
  const {
    guideTourRunning,
    guideTourStepIndex,
    handleJoyrideCallback,
    setGuideTourRunning,
    setGuideTourStepIndex,
  } = useJoyrideHelpers();

  // setting information fields
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [editTimezone, setEditTimezone] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [timezone, setTimezone] = useState<string>('UTC');
  const [language, setLanguage] = useState<string>('en');
  const [editReviewUrl, setEditReviewUrl] = useState(false);
  const [reviewUrl, setReviewUrl] = useState<string>('');
  const [watermarkVisible, setWatermarkVisible] = useState<boolean>(true);
  const [isWatermarkToggling, setIsWatermarkToggling] = useState<boolean>(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setOwner({
      ...owner,
      [name]: value,
    });
  };

  const handleUpdateOwner = () => {
    dispatch(
      updateOwner({
        ownerEmail: owner.email,
        ownerGivenName: owner.given_name,
        ownerFamilyName: owner.family_name,
      })
    );
  };

  const getOwnerDetail = () => {
    const ownerContact = user?.selected_location?.owner_contact;
    if (ownerContact) {
      return `${ownerContact.given_name} ${ownerContact.family_name} (${ownerContact.email})`;
    }
    return '';
  };

  const handleTimezoneChange = (tzvalue: string) => {
    setTimezone(tzvalue);
  };

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang);
  };

  const handleUpdateTimezone = () => {
    dispatch(updateTimezone(timezone));
    setEditTimezone(false);
  };

  const handleUpdateLanguage = () => {
    dispatch(updateLanguage(language));
  };

  const handleReviewUrlChange = (reviewUrlValue: any) => {
    setReviewUrl(reviewUrlValue);
  };

  const handleUpdateReviewUrl = () => {
    dispatch(updateReviewUrl(reviewUrl));
    setEditReviewUrl(false);
  };

  const handleUpdateBusinessDescription = async () => {
    if (!selectedLocation) return;
    await heroApiService.updateBusinessDescription(
      selectedLocation.location_id,
      businessDescription
    );
    await dispatch(fetchSelectedLocation());
  };

  const handleGenerateDescription = async () => {
    try {
      if (!selectedLocation) return;
      setGenerating(true);
      const content = await publicRouteService.generateCustomContent({
        prompt: `Here is a business name ${selectedLocation.title} and its industry ${selectedLocation.primary_category}, give a short description on what does the business do. Return the description directly, don't need to say anything else.`,
      });
      setBusinessDescription(content);
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to generate content',
          type: 'Error',
        })
      );
    }
    setGenerating(false);
  };

  const handleUpdateGeneralInfo = () => {
    dispatch(
      updateGeneralInfo({
        website_url: websiteUrl,
        title: title,
        primary_category: category,
      })
    );
    navigate<AppRoutes>('settings');
  };

  const checkWatermarkVisible = async (locationId: string): Promise<boolean> => {
    const reviewSetting = await heroApiService.getLocationReviewSetting(locationId);
    return !!reviewSetting?.watermark_visible;
  };

  const fetchWatermarkVisibility = async () => {
    if (user?.selected_location) {
      setIsWatermarkToggling(true);
      const isWatermarkVisible = await checkWatermarkVisible(user.selected_location.location_id);
      setWatermarkVisible(isWatermarkVisible);
      setIsWatermarkToggling(false);
    }
  };

  const toggleWatermarkVisibility = async () => {
    await reviewApiService.toggleWatermarkVisibility();
    await fetchWatermarkVisibility();
  };

  useEffect(() => {
    fetchWatermarkVisibility();
  }, [user?.selected_location]);

  useEffect(() => {
    if (user?.selected_location) {
      const selectedLocation = user.selected_location;
      const locationTz = selectedLocation.timezone || 'UTC';
      setTimezone(locationTz);
      setLanguage(selectedLocation.language);
      setReviewUrl(selectedLocation?.review_url || '');
      setSelectedLocation(selectedLocation);
      setBusinessDescription(selectedLocation.business_description || '');
      setWebsiteUrl(selectedLocation.website_url || '');
      setTitle(selectedLocation.title);
      setCategory(selectedLocation.primary_category);
    }
  }, [user?.selected_location]);

  useEffect(() => {
    if (fieldFocus === 'website') {
      console.log(fieldFocus);
      setGuideTourRunning(true);
      setGuideTourStepIndex(0);
    }
  }, [fieldFocus]);

  return (
    <div className="screen-card">
      <h3>Settings</h3>
      <Divider />
      <LoadableContainer isLoading={isAuthLoading} loadComponent={<SplashScreen />}>
        <React.Fragment>
          <Card hoverable style={{ height: '100%', padding: 0 }} className="tablespace">
            <CardSectionHeader title="Business information" />
            <Divider />
            <div>
              {(websiteUrl !== (user?.selected_location.website_url || '') ||
                title !== user?.selected_location?.title ||
                category !== user.selected_location.primary_category) && (
                <Button
                  type="primary"
                  className="success-btn"
                  onClick={handleUpdateGeneralInfo}
                  style={{ float: 'right', marginBottom: 20 }}>
                  <SaveOutlined /> Save changes
                </Button>
              )}
              <Space
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  marginBottom: 20,
                }}>
                <Typography.Text strong>Name</Typography.Text>
                <Input
                  id="name-text-field"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder="Enter your business name"></Input>
              </Space>
              <Space
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  marginBottom: 20,
                }}>
                <div>
                  <Typography.Text strong>Language for Content</Typography.Text>
                  <br />
                  <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                    Content generated by Amazely will be in your provided language
                  </span>
                </div>
                <LanguageSettingRow
                  language={language}
                  handleLanguageChange={handleLanguageChange}
                  handleUpdateLanguage={handleUpdateLanguage}
                />
              </Space>
              <Space
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  marginBottom: 20,
                }}>
                <Typography.Text strong>Category</Typography.Text>
                <Input
                  id="category-text-field"
                  value={category}
                  onChange={e => setCategory(e.target.value)}
                  placeholder="Enter your business category"></Input>
              </Space>
              <Space
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  marginBottom: 20,
                }}>
                <Typography.Text strong>Website</Typography.Text>
                <Input
                  id="website-text-field"
                  value={websiteUrl}
                  onChange={e => setWebsiteUrl(e.target.value)}
                  placeholder="Enter your website URL"></Input>
              </Space>
              <Space
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  marginBottom: 20,
                }}>
                <div>
                  <div>
                    <Typography.Text strong>Business description</Typography.Text>
                    <br />
                    <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                      Tell more about the industry, product or customer of your business
                    </span>
                  </div>
                </div>
                <Space>
                  <Button loading={generating} onClick={handleGenerateDescription}>
                    ✨Generate description
                  </Button>
                  {businessDescription !== (selectedLocation?.business_description || '') && (
                    <Button
                      loading={generating}
                      type={'primary'}
                      className="success-btn"
                      id="description-text-field"
                      onClick={handleUpdateBusinessDescription}>
                      <SaveOutlined /> Update description
                    </Button>
                  )}
                </Space>
              </Space>
              <Input.TextArea
                style={{ marginBottom: 20 }}
                value={businessDescription}
                onChange={e => setBusinessDescription(e.target.value)}
                placeholder={'Enter business description (max 300 characters)'}></Input.TextArea>

              <div style={{ padding: '0 0 15px 0' }}>
                <TitleWithText title="Address">{`${user?.selected_location?.full_address}`}</TitleWithText>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <TitleWithText title="Owner">{`${getOwnerDetail()}`}</TitleWithText>
                <Button
                  type="link"
                  icon={editOwner ? <></> : <EditOutlined />}
                  onClick={() => setEditOwner(!editOwner)}>
                  {editOwner ? 'Cancel' : 'Edit'}
                </Button>
              </div>
              {editOwner && (
                <OwnerSettingRow
                  owner={owner}
                  handleInputChange={handleInputChange}
                  handleUpdateOwner={handleUpdateOwner}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <TitleWithText title="Review Link">{reviewUrl || 'Google Default'}</TitleWithText>
                <Button
                  type="link"
                  icon={editReviewUrl ? <></> : <EditOutlined />}
                  onClick={() => setEditReviewUrl(!editReviewUrl)}>
                  {editReviewUrl ? 'Cancel' : 'Edit'}
                </Button>
              </div>
              {editReviewUrl && (
                <ReviewUrlSettingRow
                  reviewUrl={reviewUrl}
                  handleReviewUrlChange={handleReviewUrlChange}
                  handleUpdateReviewUrl={handleUpdateReviewUrl}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <TitleWithText title="Timezone">{`${timezone}`}</TitleWithText>
                <Button
                  type="link"
                  icon={editTimezone ? <></> : <EditOutlined />}
                  onClick={() => setEditTimezone(!editTimezone)}>
                  {editTimezone ? 'Cancel' : 'Edit'}
                </Button>
              </div>
              {editTimezone && (
                <TimezoneSettingRow
                  timezone={timezone}
                  handleTimezoneChange={handleTimezoneChange}
                  handleUpdateTimezone={handleUpdateTimezone}
                />
              )}
            </div>
          </Card>
          <br />
          <Card
            hoverable
            className="tablespace mb-24"
            style={{ width: 'full', marginBottom: '10px', padding: 0 }}>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <CardSectionHeader title="Auto Engage" />
              <CustomizeAiResponderButton />
            </div>
            <Divider />
            <EnableAutomaticResponseContainer />
          </Card>
          <br />
          <Card
            hoverable
            className="tablespace mb-24"
            style={{ width: 'full', marginBottom: '10px', padding: 0 }}>
            <CardSectionHeader title="Review Request Template" />
            <Divider />
            <RequestTemplateSettingRow autoToggle editBranding />
          </Card>
          <br />
          <Card
            hoverable
            className="tablespace mb-24"
            style={{ width: 'full', marginBottom: '10px', padding: 0 }}>
            <CardSectionHeader title="Email Subscription" />
            <Divider />
            <EmailSubscriptionContainer />
          </Card>
          <Card
            hoverable
            className="tablespace mb-24"
            style={{ width: 'full', marginBottom: '10px', padding: 0 }}>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <CardSectionHeader title="Amazely Watermark" />
              {selectedLocation?.license_type === LicenseType.Pro ? (
                <Popover
                  content={
                    <Switch
                      loading={isWatermarkToggling}
                      checked={watermarkVisible}
                      onChange={toggleWatermarkVisibility}
                    />
                  }>
                  <SettingOutlined />
                </Popover>
              ) : (
                <Button type="primary" onClick={() => navigate<AppRoutes>('subscription')}>
                  <LockOutlined style={{ color: 'yellow' }} /> Activate Amazely Pro to customize
                </Button>
              )}
            </div>
          </Card>
        </React.Fragment>
      </LoadableContainer>
      <Joyride
        continuous
        run={guideTourRunning}
        callback={handleJoyrideCallback}
        stepIndex={guideTourStepIndex}
        hideCloseButton
        disableOverlay={!guideTourRunning}
        steps={[
          {
            content: (
              <div>
                <h3>Business Website</h3>
                <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, marginTop: 10 }}>
                  Your blog posts, social media content, and business website will include the URL
                  to provide customers with more information about your business.
                </p>
                <Button
                  type={'primary'}
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={async () => {
                    setGuideTourRunning(false);
                  }}>
                  Provide a Website URL
                </Button>
              </div>
            ),
            hideFooter: true,
            target: '#website-text-field',
          },
        ]}
        disableScrolling
        styles={{
          overlay: { height: '100%' },
          options: {
            zIndex: 10000,
          },
        }}
      />
    </div>
  );
};

export default SettingsScreen;
