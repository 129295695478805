import { ArrowRightOutlined } from '@ant-design/icons';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME } from '@constants/brand';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useBreakpoint } from '@core/hooks';
import { Button } from 'antd';
import React from 'react';

type Props = {
  handleActivate: any;
};

const PitchSummaryToActionSection = ({ handleActivate }: Props) => {
  const { isTablet } = useBreakpoint();
  let isDemo = window.location.href.toString().includes('demo');

  const services = [
    'Expand your social presence effortlessly',
    'Grow your Google reviews automatically',
    'Answer reviews on your behalf',
    'Rectify negative reviews and protect your reputation',
    'Write SEO blogs to boost your Google rankings',
    'Grow your local presence & attract more customers faster',
  ];
  return (
    <React.Fragment>
      <div
        style={{
          ...MIDDLE_STYLE,
          flexDirection: 'column',
          padding: '0px 20px',
        }}>
        <h2>Amazely, the AI marketing engine for small businesses.</h2>
        <div>
          {services.map(service => (
            <div style={{ margin: '10px 0px', fontSize: 18 }}>✅ {service}</div>
          ))}
        </div>
        <br /> <br />
        {!isDemo && (
          <span style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 'bold', fontSize: 15 }}>Trusted by</div>
            <div style={{ borderBottom: '1px solid orange', fontWeight: 'bold', fontSize: 18 }}>
              800+ businesses globally
            </div>
            <br />
          </span>
        )}
        <h2
          style={{
            fontFamily: 'Rubik, sans-serif',
            margin: '20px 0px',
            color: 'black',
            fontWeight: 'normal',
            fontSize: isTablet ? '1rem' : '1.5rem',
            borderBottom: '1px dashed grey',
          }}>
          {/*{businessData.location}*/}
          This is only a high level demo.
        </h2>
        <Button
          onClick={handleActivate}
          type="primary"
          size="large"
          style={{ cursor: 'pointer', padding: 24, borderRadius: 50, ...MIDDLE_STYLE }}>
          <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 30, marginRight: 6 }} />
          Sign in for a full app experience &nbsp;
          <ArrowRightOutlined />
        </Button>
        <br />
        <br />
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};

export default PitchSummaryToActionSection;
