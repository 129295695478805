import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAuthState, useModalState } from '@core/configureStore';
import { closeModal, enqueueNotification, openModal } from '@core/features';
import { Button, Divider, Input, InputNumber, Modal, Space, Switch, Tag, Tooltip } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { BlogAssistantProfile, BlogAssistantProfileDefault } from '@core/models';
import { heroApiService } from '@services/service-register';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import cronstrue from 'cronstrue';
import { CalendarOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BiReset } from 'react-icons/bi';
import { useAppNavigation, useBreakpoint } from '@core/hooks';
import { TONE_OF_VOICES } from '@constants/brand';
import { BlogTargetKeywordsEditor, ExpandableSuggestionContainer } from '@components/index';
import { AppRoutes } from '@utils/route.util';
import { shortenString } from '@utils/string.util';
import { getDefaultBlogProfileKeywords } from '@utils/blog.util';

type Props = {};

const MAX_VISIBLE_TONE_OF_VOICES = 3;

const CustomizeBlogAiProfileModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { user } = useAuthState();
  const { isTablet } = useBreakpoint();
  const { customizeBlogAiProfileModal } = useModalState();
  const selectedLocation = useMemo(() => user?.selected_location, [user]);
  const [blogAssistantProfile, setBlogAssistantProfile] = useState<
    BlogAssistantProfile | undefined
  >(undefined);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'customizeBlogAiProfileModal',
      })
    );
  };

  const handleUpdateProfile = (field: keyof BlogAssistantProfile, value: any) => {
    setBlogAssistantProfile({
      ...blogAssistantProfile,
      [field]: value,
    } as any);
    setUpdated(true);
  };

  const handleDiscard = () => {
    if (selectedLocation)
      setBlogAssistantProfile({
        ...selectedLocation?.blog_assistant_profile,
      });
    setUpdated(false);
  };

  const handleAddNewSchedule = (field: 'schedules') => {
    dispatch(
      openModal({
        modalName: 'assistantScheduleSettingModal',
        extraParams: {
          onScheduleAdd: (cronExpressions: string[]) => {
            const addedCronExpressions = [];
            for (const cronExpression of cronExpressions) {
              if (!blogAssistantProfile?.schedules.includes(cronExpression))
                addedCronExpressions.push(cronExpression);
            }
            handleUpdateProfile(field, [
              ...(blogAssistantProfile?.[field] || []),
              ...addedCronExpressions,
            ]);
          },
        },
      })
    );
  };

  const handleSaveChanges = async () => {
    if (!blogAssistantProfile) return;
    try {
      setUpdating(true);
      await heroApiService.updateLocationBlogAssistantProfile(blogAssistantProfile);
      await dispatch(fetchSelectedLocation());
      setUpdated(false);
      dispatch(
        enqueueNotification({
          name: 'Successfully update blog assistant profile',
          description: 'Successfully update assistant profile',
          type: 'Success',
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update blog assistant profile',
          description: 'Failed to update assistant profile',
          type: 'Error',
        })
      );
    }
    setUpdating(false);
  };

  useEffect(() => {
    setBlogAssistantProfile({
      ...BlogAssistantProfileDefault,
      ...selectedLocation?.blog_assistant_profile,
    });
  }, [selectedLocation, customizeBlogAiProfileModal]);

  return (
    <Modal
      width={isTablet ? '100%' : 700}
      title={'Customize Blog Setting'}
      open={customizeBlogAiProfileModal}
      footer={
        updated
          ? [
              <Button onClick={handleModalClose}>Cancel</Button>,
              <Button loading={updating} danger onClick={handleDiscard}>
                <BiReset /> Discard
              </Button>,
              <Button
                loading={updating}
                type={'primary'}
                className={'success-btn'}
                onClick={handleSaveChanges}>
                Save changes
              </Button>,
            ]
          : [
              <Button onClick={handleModalClose}>Cancel</Button>,
              <Button loading={updating} danger onClick={handleDiscard}>
                <BiReset /> Discard
              </Button>,
            ]
      }
      onCancel={handleModalClose}
      closable>
      <Divider />
      {selectedLocation && blogAssistantProfile && (
        <Space size={'large'} direction={'vertical'} style={{ width: '100%' }}>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <div>Business Description</div>
            <Button
              onClick={() => {
                navigate<AppRoutes>('settings');
                handleModalClose();
              }}>
              <EditOutlined />{' '}
              {selectedLocation?.business_description ? 'Edit description' : 'Add description'}
            </Button>
          </div>
          {selectedLocation?.business_description && (
            <Tooltip title={selectedLocation?.business_description}>
              <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                {shortenString(selectedLocation?.business_description || '', 200)}
              </p>
            </Tooltip>
          )}
          <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div>Auto generate blog posts</div>
              <Space>
                <Switch
                  checked={blogAssistantProfile.autoGeneratedBlogContent}
                  onChange={isChecked => {
                    handleUpdateProfile('autoGeneratedBlogContent', isChecked);
                  }}
                />
              </Space>
            </div>
          </React.Fragment>
          <React.Fragment>
            <Space
              style={{
                ...MIDDLE_STYLE,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%',
              }}>
              <div>
                Schedule
                <br />
                <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                  How often a blog post is scheduled
                </span>
              </div>
              <Button style={{ width: '100%' }} onClick={() => handleAddNewSchedule('schedules')}>
                <CalendarOutlined /> Add new schedule
              </Button>
            </Space>
            <Space style={{ marginTop: 10, flexWrap: 'wrap' }}>
              {blogAssistantProfile.schedules.map((schedule, index) => (
                <Tag color={'blue'}>
                  {cronstrue.toString(schedule)}
                  <DeleteOutlined
                    style={{ marginLeft: 10 }}
                    onClick={() =>
                      handleUpdateProfile(
                        'schedules',
                        blogAssistantProfile?.schedules.filter((_, i) => i + 1 !== index + 1)
                      )
                    }
                  />
                </Tag>
              ))}
            </Space>
          </React.Fragment>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Tone of voice <br />
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Select a voice of your business AI assistant
              </span>
            </div>
            <Input
              onChange={e => handleUpdateProfile('tone_of_voice', e.target.value)}
              placeholder={'Enter AI tone of voice'}
              value={blogAssistantProfile.tone_of_voice}
            />
          </Space>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Auto share to social
              <br />
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Automatically share your newly published blog post on social channels
              </span>
            </div>
            <Switch
              id="auto-share-social-switch"
              checked={blogAssistantProfile.autoShareToSocial}
              onChange={isChecked => {
                handleUpdateProfile('autoShareToSocial', isChecked);
              }}
            />
          </Space>
          <div
            style={{
              backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
              padding: '10px 10px',
              borderRadius: 10,
            }}>
            <ExpandableSuggestionContainer
              limit={MAX_VISIBLE_TONE_OF_VOICES}
              topics={TONE_OF_VOICES.map(toneOfVoice => toneOfVoice.tone)}
              onTopicClicked={tone_of_voice => handleUpdateProfile('tone_of_voice', tone_of_voice)}
            />
          </div>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Content character limit <br />{' '}
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Blog post content will be generated within {blogAssistantProfile.content_word_limit}{' '}
                characters.
              </span>
            </div>
            <InputNumber
              onChange={value => handleUpdateProfile('content_word_limit', value || 0)}
              placeholder={'Set limit'}
              width={'100%'}
              min={50}
              max={1000}
              value={blogAssistantProfile.content_word_limit}
            />
          </Space>
          <div style={{ padding: '0px 10px', marginTop: 20, width: '100%' }}>
            <BlogTargetKeywordsEditor
              keywords={
                blogAssistantProfile.keywords?.length > 0
                  ? blogAssistantProfile.keywords
                  : getDefaultBlogProfileKeywords(selectedLocation)
              }
              setKeywords={keywords => handleUpdateProfile('keywords', keywords)}
            />
          </div>
          <Divider />
        </Space>
      )}
    </Modal>
  );
};

export default CustomizeBlogAiProfileModal;
