import { Button, Popover, Space } from 'antd';
import { BrowsePexelsButton, BrowsePhotoGallery, BrowseUnsplashPhotos } from '@components/index';
import { HiPhoto } from 'react-icons/hi2';
import React from 'react';

interface Props {
  disabledPhotoGallerySource?: boolean;
  style?: React.CSSProperties;
}

const BrowseMediaLibrary: React.FC<Props> = ({ style, disabledPhotoGallerySource }) => {
  return (
    <Popover
      trigger={'click'}
      content={
        <Space direction={'vertical'}>
          {!disabledPhotoGallerySource && <BrowsePhotoGallery style={{ width: '100%' }} />}
          {/*<BrowseFacebookAlbum style={{ width: '100%' }} />*/}
          <BrowseUnsplashPhotos style={{ width: '100%' }} />
          <BrowsePexelsButton style={{ width: '100%' }} />
        </Space>
      }>
      <Button style={{ ...style }}>
        <HiPhoto style={{ marginRight: 10 }} /> Browse Media Library
      </Button>
    </Popover>
  );
};

export default BrowseMediaLibrary;
