import { RootState } from '@core/configureStore';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { fetchCurrentUser } from '@core/features/auth/thunks';

export const activateAutoRequest = createAsyncThunk(
  'business/activateAutoRequest',
  async (_, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('No user found');
      const locationId = user.selected_location.location_id;
      const result = await heroApiService.updateAutoPilot(
        locationId,
        user.selected_location.auto_pilot_activated,
        true
      );
      await thunkApi.dispatch(fetchCurrentUser());
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to activate auto request',
          description: 'There was error fetching locations. Please refresh the page',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(activateAutoRequest);

export const activateAutoRequestReducer = {
  [fulfilled]: (state, action) => {
    state.isAutoRequestActivating = false;
  },
  [rejected]: (state, action) => {
    state.isAutoRequestActivating = false;
  },
  [pending]: (state, action) => {
    state.isAutoRequestActivating = true;
  },
} as ExtraReducer<LocationReducerProps>;
