/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AnimatedComponent, LoadableContainer, LocalReviewCard } from '..';
import { Button, Card, List, Skeleton } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { Review } from '@core/models';
import { useAuthState } from '@core/configureStore';
import { heroApiService } from '@services/service-register';

type Props = {};

const AnonymousReviewContainer = (props: Props) => {
  const { user } = useAuthState();
  const [loading, setLoading] = useState<boolean>(true);
  const [reviews, setReviews] = React.useState<Review[]>([]);
  const handleRefresh = () => fetchAnonymousReviews();

  const fetchAnonymousReviews = async () => {
    if (!user) return;
    setLoading(true);
    try {
      const locationId = user.selected_location.location_id;
      const fetchedReviews = await heroApiService.getAllAnonymousReviews(locationId);
      setReviews(fetchedReviews);
    } catch (error: any) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAnonymousReviews();
  }, [user]);

  return reviews.length > 0 ? (
    <React.Fragment>
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        <Card
          style={{ marginTop: 20, padding: '20px 30px', height: 'fit-content' }}
          className="criclebox tablespace mb-24"
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Reviews generated from QR</span>
              <div>
                <Button style={{ marginLeft: 20 }} type="primary" onClick={handleRefresh}>
                  <ReloadOutlined /> Refresh
                </Button>
              </div>
            </div>
          }>
          <List
            dataSource={reviews}
            renderItem={(item, index) => (
              <AnimatedComponent.OpacityFadeInDiv delay={200 + index * 50}>
                <LocalReviewCard size={'large'} item={item} key={item.id} />
              </AnimatedComponent.OpacityFadeInDiv>
            )}
          />
        </Card>
      </LoadableContainer>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default AnonymousReviewContainer;
