/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useAppDispatch } from '@core/configureStore';
import { fetchCurrentUser, checkSupportSession } from '@core/features/auth/thunks';

type Props = {
  children: React.ReactNode;
};

const AuthWidget: React.FC<Props> = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCurrentUser());
    dispatch(checkSupportSession());
  }, []);

  return <div>{children}</div>;
};

export default AuthWidget;
