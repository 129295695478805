import { MenuProps } from 'antd';
import React, { useMemo } from 'react';
import { MAX_ONBOARDING_STAGE } from '@constants/pitch-business';
import {
  BookOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  FlagOutlined,
  HeartOutlined,
  HomeOutlined,
  LikeOutlined,
  LockOutlined,
  MessageOutlined,
  SettingOutlined,
  StarOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { TbApps, TbShieldCheck } from 'react-icons/tb';
import { useAuthState } from '@core/configureStore';
import { AppRoutes, FREELANCER_MODE_PROTECTED_ROUTES } from '@utils/route.util';
import { getItem, MenuItem } from '@utils/menu.util';
import { MIDDLE_STYLE } from '@constants/responsive';

export const useNavigationHelper = () => {
  const { user } = useAuthState();
  const getNavigationItem = (
    label: React.ReactNode,
    key: AppRoutes,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ) => {
    if (user?.selected_location?.freelancer_mode && FREELANCER_MODE_PROTECTED_ROUTES[key]) {
      return getItem(
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
          {label} <LockOutlined style={{ fontSize: 'smaller' }} />
        </div>,
        key,
        icon,
        children,
        type
      );
    }
    return getItem(label, key, icon, children, type);
  };
  return {
    getNavigationItem,
  };
};

export const useNavigationItems = () => {
  const { user } = useAuthState();
  const { getNavigationItem } = useNavigationHelper();

  const GeneralNavigationItems: MenuProps['items'] = useMemo(
    () => [
      ...(user?.selected_location?.onboarding_stages?.length === MAX_ONBOARDING_STAGE
        ? []
        : [
            getNavigationItem(
              `Getting Started (${
                user?.selected_location?.onboarding_stages?.length || 0
              } / ${MAX_ONBOARDING_STAGE})`,
              'getting-started',
              <FlagOutlined />
            ),
          ]),
      getNavigationItem('Home', 'home', <HomeOutlined />),
    ],
    [user]
  );

  const GrowReputationNavigationItems: MenuProps['items'] = [
    getNavigationItem('Generate Reviews', 'generate-reviews', <MessageOutlined />),
    getNavigationItem('Answer Reviews', 'reviews', <LikeOutlined />),
    getNavigationItem('Showcase Reviews', 'widget', <StarOutlined />),
    getNavigationItem('Review Wall of Love', 'wall-of-love', <HeartOutlined />),
    getNavigationItem('Reputation Defender', 'inquiries', <TbShieldCheck />),
  ];

  const GrowSocialPresenceNavigationItems: MenuProps['items'] = [
    getNavigationItem('Social Studio', 'studio', <YoutubeOutlined />),
    getNavigationItem('Blogs', 'blogs', <BookOutlined />),
  ];

  const OtherNavigationItems: MenuProps['items'] = [
    getNavigationItem('Booking', 'booking', <CalendarOutlined />),
    getNavigationItem('Integrations', 'integrations', <TbApps />),
    getNavigationItem('Settings', 'settings', <SettingOutlined />),
    getNavigationItem('Billings', 'subscription', <CheckSquareOutlined />),
  ];

  return {
    GeneralNavigationItems,
    GrowReputationNavigationItems,
    GrowSocialPresenceNavigationItems,
    OtherNavigationItems,
  };
};
