import { StreamingPayload } from '@core/hooks/useStreamingTaskStore';

export const useStreamApi = () => {
  async function executeStreamApi<T, P>({
    apiMethod,
    onDecodedData,
    onFinishedCallback,
    payload,
    abortSignal,
  }: StreamingPayload<T, P>) {
    const streamApiMethodReader = await apiMethod(payload, abortSignal);

    while (true) {
      const textDecoder = new TextDecoder();
      const { done, value } = await streamApiMethodReader.read();
      if (done) {
        await onFinishedCallback();
        break;
      }
      const streamedTextData = textDecoder.decode(value);
      const regex = /DATABEGIN:(.*?):DATAEND/g;
      const matches = streamedTextData.matchAll(regex);
      for (const matchData of matches) {
        const data: T = JSON.parse(matchData[1]);
        onDecodedData && onDecodedData(data);
      }
    }
  }
  return {
    executeStreamApi,
  };
};
