import { useAppDispatch, useDrawerState } from '@core/configureStore';
import { AppIntegrationKey, closeDrawer } from '@core/features';
import { Divider, Drawer } from 'antd';
import React, { useMemo } from 'react';
import { AppIntegrationStatusBadge, ConnectSquareIntegrationButton } from '..';
import { ConnectQuickBooksIntegrationButton } from '..';
import { ConnectJobberIntegrationButton } from '..';
import { ConnectXeroIntegrationButton } from '..';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {};

const AppIntegrationDrawer = (props: Props) => {
  const dispatch = useAppDispatch();
  const { appIntegrationDrawer, extraParams } = useDrawerState();
  const appId = useMemo<AppIntegrationKey>(() => extraParams?.appId, [extraParams]);

  const handleClose = () => {
    dispatch(
      closeDrawer({
        drawerName: 'appIntegrationDrawer',
      })
    );
  };

  return (
    <Drawer
      width={500}
      placement="right"
      closable={true}
      onClose={handleClose}
      open={appIntegrationDrawer}>
      {appId === 'square' && (
        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
          <div
            style={{
              width: 70,
              height: 70,
              padding: '10px',
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              borderRadius: 10,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            }}>
            <img src="square-inc.png" width={'100%'} height={'100%'} alt="Square" />
          </div>
          <h1 style={{ margin: '20px 0px', color: 'black' }}>Square</h1>
          <div style={{ margin: '10px 0px', ...MIDDLE_STYLE }}>
            <AppIntegrationStatusBadge style={{ marginRight: 10 }} appId="square" />
            <ConnectSquareIntegrationButton />
          </div>
          <Divider />
          <p>
            Amazely's app for Square makes it easy for businesses to{' '}
            <span style={{ fontWeight: 'bold' }}>collect Google reviews</span>. The app
            automatically sends review invitations to customers after they make a purchase.
          </p>
          <br />
          <p>
            These invitations can be triggered by transactions placed on both{' '}
            <span style={{ fontWeight: 'bold' }}>Square Point of Sale and Invoices</span>. This
            helps businesses collect more reviews, which can improve their online visibility and
            boost customer engagement.
          </p>
        </div>
      )}
      {appId === 'quickbooks' && (
        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
          <div
            style={{
              width: 70,
              height: 70,
              padding: '10px',
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              borderRadius: 10,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            }}>
            <img src="quickbooks.png" width={'100%'} height={'100%'} alt="QuickBooks" />
          </div>
          <h1 style={{ margin: '20px 0px', color: 'black' }}>QuickBooks</h1>
          <div style={{ margin: '10px 0px', ...MIDDLE_STYLE }}>
            <AppIntegrationStatusBadge style={{ marginRight: 10 }} appId="quickbooks" />
            <ConnectQuickBooksIntegrationButton />
          </div>
          <Divider />
          <p>
            Amazely's app for QuickBooks makes it easy for businesses to{' '}
            <span style={{ fontWeight: 'bold' }}>collect Google reviews</span>. The app
            automatically sends review invitations to customers after they make a purchase.
          </p>
        </div>
      )}
      {appId === 'jobber' && (
        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
          <div
            style={{
              width: 70,
              height: 70,
              padding: '10px',
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              borderRadius: 10,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            }}>
            <img src="jobber.png" width={'100%'} height={'100%'} alt="Jobber" />
          </div>
          <h1 style={{ margin: '20px 0px', color: 'black' }}>Jobber</h1>
          <div style={{ margin: '10px 0px', ...MIDDLE_STYLE }}>
            <AppIntegrationStatusBadge style={{ marginRight: 10 }} appId="jobber" />
            <ConnectJobberIntegrationButton />
          </div>
          <Divider />
          <p>
            Amazely's app for Jobber makes it easy for businesses to{' '}
            <span style={{ fontWeight: 'bold' }}>collect Google reviews</span>. The app
            automatically sends review invitations to customers after they make a purchase.
          </p>
        </div>
      )}
      {appId === 'xero' && (
        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
          <div
            style={{
              width: 70,
              height: 70,
              padding: '10px',
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              borderRadius: 10,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            }}>
            <img src="xero.png" width={'100%'} height={'100%'} alt="Xero" />
          </div>
          <h1 style={{ margin: '20px 0px', color: 'black' }}>Xero</h1>
          <div style={{ margin: '10px 0px', ...MIDDLE_STYLE }}>
            <AppIntegrationStatusBadge style={{ marginRight: 10 }} appId="xero" />
            <ConnectXeroIntegrationButton />
          </div>
          <Divider />
          <p>
            Amazely's app for Xero makes it easy for businesses to{' '}
            <span style={{ fontWeight: 'bold' }}>collect Google reviews</span>. The app
            automatically sends review invitations to customers after they make a purchase.
          </p>
        </div>
      )}
      {appId === 'openDental' && (
        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
          <div
            style={{
              width: 70,
              height: 70,
              padding: '10px',
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              borderRadius: 10,
              boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            }}>
            <img src="open-dental.jpg" width={'100%'} height={'100%'} alt="Square" />
          </div>
          <h1 style={{ margin: '20px 0px', color: 'black' }}>Open Dental</h1>
          <Divider />
          <p>
            Amazely's app for Open Dental makes it easy for dental practices to{' '}
            <span style={{ fontWeight: 'bold' }}>collect Google reviews</span>. The app
            automatically sends review invitations to new patients after their appointments.
          </p>
          <br />
          <p>
            For more details, please refer to this guide{' '}
            <a
              href="https://docs.amazely.co/en/articles/8764162-integrate-with-open-dental"
              target="_blank">
              Open Dental integration guide
            </a>
            . Please send us a message if you would like to setup the integration.
          </p>
        </div>
      )}
    </Drawer>
  );
};

export default AppIntegrationDrawer;
