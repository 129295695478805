import { Button, Space, Tag, Typography } from 'antd';
import { RequestSource, ReviewRequest, ReviewRequestStatus } from '@core/models';
import { formatUtcDate } from '@utils/.';
import { RequestOperationHandler } from '.';
import { CheckCircleFilled, DeleteOutlined, EyeOutlined, RedoOutlined } from '@ant-design/icons';
import { NEGATIVE_RATE_CEILING } from '@constants/rate-conversion';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

const { Title } = Typography;

export const renderData = (handler: RequestOperationHandler, data: ReviewRequest[]) => {
  return data.map(request => {
    const isReviewed = request.stage === ReviewRequestStatus.Reviewed;
    const isOpened = request.number_of_email_open > 0;
    const isDrafted = request.stage === ReviewRequestStatus.Draft;
    const isSquare = request.request_source === RequestSource.Square;
    const isEmail = request.request_source === RequestSource.Email;
    const isOpenDental = request.request_source === RequestSource.OpenDental;
    const isQuickBooks = request.request_source === RequestSource.QuickBooks;
    const isJobber = request.request_source === RequestSource.Jobber;
    const isXero = request.request_source === RequestSource.Xero;
    const isFollowedUp = request.followUpCount > 0;
    return {
      key: request.id,
      name: (
        <div>
          <Title level={5} style={{ marginTop: 0 }}>
            {request.fullName}
          </Title>
          <p>
            {request.contact} {request.phone && ` (${request.phone})`}
          </p>
        </div>
      ),
      status: (isReviewed && <Tag color="success">Reviewed</Tag>) ||
        (isOpened && <Tag color="cyan">Opened</Tag>) ||
        (isDrafted && <Tag color="default">Draft</Tag>) || <Tag color="processing">Pending</Tag>,
      followedUp: isFollowedUp && (
        <CheckCircleFilled style={{ color: GLOBAL_THEME_COLOR.$success_color }} />
      ),
      date: isReviewed ? (
        <span>Reviewed on {formatUtcDate(request.last_modified_date)}</span>
      ) : isDrafted ? (
        <span>Saved on {formatUtcDate(request.created_date)}</span>
      ) : (
        <span>Requested on {formatUtcDate(request.created_date)}</span>
      ),
      source: (isSquare && <span>Square</span>) ||
        (isEmail && <span>Email</span>) ||
        (isQuickBooks && <span>QuickBooks</span>) ||
        (isJobber && <span>Jobber</span>) ||
        (isXero && <span>Xero</span>) ||
        (isOpenDental && <span>Open Dental</span>) || <span>Manual</span>,
      action: (
        <Space>
          <Button
            style={{ height: 30, ...MIDDLE_STYLE }}
            onClick={() => handler(request.id, 'view')}>
            <EyeOutlined /> View
          </Button>
          {isReviewed && request.rating <= NEGATIVE_RATE_CEILING && (
            <Button
              style={{ height: 30, ...MIDDLE_STYLE }}
              onClick={() =>
                handler(request.id, 'request-again', {
                  request,
                })
              }>
              <RedoOutlined /> Request again
            </Button>
          )}
          {!isReviewed && (
            <Button
              style={{ height: 30, ...MIDDLE_STYLE }}
              onClick={() => handler(request.id, 'delete')}>
              <DeleteOutlined /> Delete
            </Button>
          )}
        </Space>
      ),
    };
  });
};
