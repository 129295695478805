import { CheckOutlined, CopyOutlined, SaveOutlined } from '@ant-design/icons';
import {
  BackgroundSolidSetting,
  CardSectionHeader,
  DescriptionItem,
  EmbeddedBlogComponents,
  HighlightedSubText,
} from '@components/index';
import { DEFAULT_EMBEDDED_BLOG_SETTING } from '@constants/defaults';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useAppDispatch, useAuthState, useBlogState } from '@core/configureStore';
import { enqueueNotification } from '@core/features';
import { useBlogPostDispatcher, useBreakpoint } from '@core/hooks';
import { EmbeddedBlogSetting, EmbeddedBlogViewMode } from '@core/models';
import { blogApiService } from '@services/service-register';
import { Button, Card, Col, Divider, InputNumber, Radio, Row, Space, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useMemo, useState } from 'react';

enum ColorTheme {
  DarkMode = 'DarkMode',
  WhiteMode = 'WhiteMode',
  CustomMode = 'CustomMode',
}

const DARK_MODE_COLOR_THEME = {
  cardColor: 'black',
  cardTitleColor: 'white',
};

const WHITE_MODE_COLOR_THEME = {
  cardColor: 'white',
  cardTitleColor: 'black',
};

const BlogPostSettingTab = () => {
  const { isTablet } = useBreakpoint();
  const { blogPosts } = useBlogState();
  const { user } = useAuthState();
  const dispatch = useAppDispatch();
  const { sorteBlogPostsByPublishedDate, filterEmbeddedBlogPosts } = useBlogPostDispatcher();
  const currentLocation = useMemo(() => user?.selected_location, [user]);
  const [selectedTheme, setSelectedTheme] = useState<ColorTheme>(ColorTheme.WhiteMode);
  const [isCopied, setIsCopied] = useState(false);
  const [embeddedSettingUpdated, setEmbeddedSettingUpdated] = useState(false);
  const [embeddedSetting, setEmbeddedSetting] = useState<EmbeddedBlogSetting>(
    DEFAULT_EMBEDDED_BLOG_SETTING
  );

  const embeddedCode = useMemo(() => {
    return `<div class="amazely-blog-cards-${currentLocation?.location_id}" />\n<script src="https://embed-blog.amazely.co/index.js" defer></script>`;
  }, [currentLocation]);

  const handleUpdateEmbeddedSetting = (
    partialEmbeddedSetting: Partial<EmbeddedBlogSetting>,
    noUpdated: boolean = false
  ) => {
    setEmbeddedSetting({
      ...embeddedSetting,
      ...partialEmbeddedSetting,
    } as any);
    if (!noUpdated) {
      setEmbeddedSettingUpdated(true);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(embeddedCode);
    setIsCopied(true);
  };

  const handleSaveSettingChanges = async () => {
    try {
      await blogApiService.updateEmbeddedBlogSetting(embeddedSetting || {});
      dispatch(
        enqueueNotification({
          name: 'Embedded setting is updated.',
          description: 'Embedded setting is updated.',
          type: 'Success',
        })
      );
      setEmbeddedSettingUpdated(false);
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Embedded setting is failed to update.',
          description: 'Embedded setting is failed to update',
          type: 'Error',
        })
      );
    }
  };

  useEffect(() => {
    const init = async () => {
      if (currentLocation?.location_id) {
        const blogSetting = await blogApiService.getLocationBlogSetting();
        if (blogSetting) {
          const _embeddedSetting = blogSetting.embedded_setting;
          await setEmbeddedSetting(_embeddedSetting);
          const { cardColor, cardTitleColor } = _embeddedSetting;
          if (
            cardColor === WHITE_MODE_COLOR_THEME.cardColor &&
            cardTitleColor === WHITE_MODE_COLOR_THEME.cardTitleColor
          ) {
            setSelectedTheme(ColorTheme.WhiteMode);
          } else if (
            cardColor === DARK_MODE_COLOR_THEME.cardColor &&
            cardTitleColor === DARK_MODE_COLOR_THEME.cardTitleColor
          ) {
            setSelectedTheme(ColorTheme.DarkMode);
          } else {
            setSelectedTheme(ColorTheme.CustomMode);
          }
        }
      }
    };
    init();
  }, [currentLocation]);

  const handleUpdateTheme = (theme: ColorTheme) => {
    switch (theme) {
      case ColorTheme.WhiteMode:
        handleUpdateEmbeddedSetting(WHITE_MODE_COLOR_THEME);
        break;
      case ColorTheme.DarkMode:
        handleUpdateEmbeddedSetting(DARK_MODE_COLOR_THEME);
        break;
      case ColorTheme.CustomMode:
        handleUpdateEmbeddedSetting(embeddedSetting as any);
        break;
    }
    setSelectedTheme(theme);
  };

  return (
    <Card style={{ borderRadius: 10, overflow: 'hidden' }}>
      {embeddedSetting && (
        <React.Fragment>
          <Row style={{ width: '100%' }}>
            <Col span={isTablet ? 24 : 12} style={{ width: '100%' }}>
              <Space size={40} direction="vertical" style={{ width: '100%' }}>
                <DescriptionItem
                  title={'Display Mode'}
                  style={{ margin: 0 }}
                  content={
                    <Radio.Group
                      onChange={e =>
                        handleUpdateEmbeddedSetting({
                          displayMode: e.target.value,
                        })
                      }
                      value={embeddedSetting.displayMode}>
                      <Radio value={EmbeddedBlogViewMode.ListView}>List View</Radio>
                      <Radio value={EmbeddedBlogViewMode.Marquee}>Marquee</Radio>
                    </Radio.Group>
                  }
                />
                <DescriptionItem
                  title={'Theme'}
                  style={{ margin: 0 }}
                  content={
                    <Radio.Group
                      onChange={e => handleUpdateTheme(e.target.value)}
                      value={selectedTheme}>
                      <Radio value={ColorTheme.WhiteMode}>White Mode</Radio>
                      <Radio value={ColorTheme.DarkMode}>Dark Mode</Radio>
                      <Radio value={ColorTheme.CustomMode}>Custom</Radio>
                    </Radio.Group>
                  }
                />
                {selectedTheme === ColorTheme.CustomMode && (
                  <Space size={30}>
                    <BackgroundSolidSetting
                      title="Blog Card Background"
                      value={embeddedSetting?.cardColor}
                      onValueChanged={value =>
                        handleUpdateEmbeddedSetting({
                          cardColor: value,
                        })
                      }
                    />
                    <BackgroundSolidSetting
                      title="Text Color"
                      value={embeddedSetting?.cardTitleColor}
                      onValueChanged={value =>
                        handleUpdateEmbeddedSetting({
                          cardTitleColor: value,
                        })
                      }
                    />
                  </Space>
                )}
                {embeddedSetting.displayMode === EmbeddedBlogViewMode.ListView && (
                  <DescriptionItem
                    title={'Number of Blogs / Page'}
                    style={{ margin: 0 }}
                    content={
                      <InputNumber
                        value={embeddedSetting.cardPerPage}
                        min={1}
                        max={20}
                        onChange={e =>
                          handleUpdateEmbeddedSetting({
                            cardPerPage: e || embeddedSetting.cardPerPage,
                          })
                        }
                      />
                    }
                  />
                )}
                <Space size={30}>
                  <DescriptionItem
                    title={'Container Width'}
                    style={{ margin: 0 }}
                    content={
                      <InputNumber
                        value={embeddedSetting.maxContainerSize}
                        min={500}
                        max={3500}
                        onChange={e =>
                          handleUpdateEmbeddedSetting({
                            maxContainerSize: e || embeddedSetting.maxContainerSize,
                          })
                        }
                      />
                    }
                  />
                  <DescriptionItem
                    title={'Card Width'}
                    style={{ margin: 0 }}
                    content={
                      <InputNumber
                        value={embeddedSetting.cardMaxSize}
                        min={200}
                        max={800}
                        onChange={e =>
                          handleUpdateEmbeddedSetting({
                            cardMaxSize: e || embeddedSetting.cardMaxSize,
                          })
                        }
                      />
                    }
                  />
                </Space>
              </Space>
            </Col>
            <Col span={isTablet ? 24 : 12} style={{ width: '100%' }}>
              <React.Fragment>
                {embeddedSettingUpdated && (
                  <Button
                    onClick={handleSaveSettingChanges}
                    type="primary"
                    className="success-btn"
                    style={{ marginBottom: 10, width: '100%' }}>
                    <SaveOutlined />
                    Save Setting
                  </Button>
                )}
                <div>
                  <CardSectionHeader title="Add to your web page" />
                  <p>
                    Copy and add this script to your web page. You{' '}
                    <HighlightedSubText content="only need to copy it once." />
                  </p>
                  <Divider />
                  <TextArea
                    style={{ marginTop: '10px' }}
                    contentEditable={false}
                    value={embeddedCode}
                    autoSize={{ minRows: 2 }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'baseline',
                      marginBottom: '10px',
                    }}>
                    <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                      <Button
                        type="link"
                        size="small"
                        icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
                        onClick={handleCopy}>
                        {isCopied ? 'Copied' : 'Copy'}
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </React.Fragment>
            </Col>
          </Row>
          <div
            style={{
              marginTop: 50,
              backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
              backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
              backgroundSize: '20px 20px',
              padding: '10px 10px',
              borderRadius: 10,
              overflow: 'hidden',
              ...MIDDLE_STYLE,
              justifyContent: 'space-evenly',
            }}>
            <EmbeddedBlogComponents.EmbeddedBlogViewContainer
              blogPosts={sorteBlogPostsByPublishedDate(filterEmbeddedBlogPosts(blogPosts))}
              embeddedBlogSetting={embeddedSetting}
            />
          </div>
        </React.Fragment>
      )}
    </Card>
  );
};

export default BlogPostSettingTab;
