/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, Rate, Row, theme } from 'antd';
import { Review } from '@core/models';
import { formatRelativeUtcDate, shortenString } from '@utils/.';
import { CenteredCardColumn } from '..';

type Props = {
  item: Review;
  actions?: React.ReactElement;
  size: 'small' | 'large';
};

const LocalReviewCard = ({ item, actions, size }: Props) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Card
      size="small"
      style={{
        cursor: 'pointer',
        background: colorBgContainer,
        marginBottom: '10px',
        padding: '10px 20px',
      }}>
      <Row>
        <CenteredCardColumn span={12}>
          <React.Fragment>
            <div style={{ display: 'flex' }}>
              <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                {item.reviewer.displayName}
              </h3>
              <h3
                style={{
                  display: 'flex',
                  marginLeft: 10,
                  fontWeight: 'normal',
                  fontSize: 'smaller',
                  color: 'gray',
                  alignItems: 'center',
                }}>
                {formatRelativeUtcDate(item.updated_date)}
              </h3>
            </div>
            {shortenString(item.comment, size === 'small' ? 50 : 80)}
          </React.Fragment>
        </CenteredCardColumn>
        <CenteredCardColumn span={6}>
          <div>{item.reviewer.emailAddress || ''}</div>
        </CenteredCardColumn>
        <CenteredCardColumn span={6}>
          <Rate style={{ fontSize: 18 }} defaultValue={0} disabled value={item.rating} />
        </CenteredCardColumn>
      </Row>
    </Card>
  );
};

export default LocalReviewCard;
