import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequestReducerProps } from '../type';

type OpenRequestPayload = {
  requestId: number;
  rate: number;
  feedback: string | undefined;
};

export const respondRequest = createAsyncThunk(
  'review-request/respondRequest',
  async ({ requestId, rate, feedback }: OpenRequestPayload, thunkApi) => {
    await heroApiService.respondRequest(requestId, rate, feedback);
    const data = await heroApiService.getReviewRequestById(requestId);
    return data;
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(respondRequest);

export const respondRequestReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.request = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isOpening = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
