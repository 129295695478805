import { Col } from 'antd';
import React from 'react';

type Props = {
  span: number;
  children?: React.ReactElement;
};

const CenteredCardColumn = (props: Props) => {
  return (
    <Col
      span={props.span}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}>
      {props.children}
    </Col>
  );
};

export default CenteredCardColumn;
