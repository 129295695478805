/* eslint-disable jsx-a11y/alt-text */
import { MIDDLE_STYLE } from '@constants/responsive';
import { InstagramProfile } from '@core/models';
import { Space } from 'antd';
import React from 'react';
import { SocialIcon } from '..';

type Props = {
  instagramProfile: InstagramProfile;
  prefix: React.ReactNode;
};

const InstagramAccountListItem = ({ instagramProfile, prefix }: Props) => {
  return (
    <React.Fragment>
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '100%' }}>
        <Space size={'large'} style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start' }}>
          {prefix}
          <img
            width={50}
            height={50}
            style={{ borderRadius: 50 }}
            src={instagramProfile.profile_picture_url}
          />
          <div>
            <h3>{instagramProfile.name}</h3>
            <p>{instagramProfile.username}</p>
          </div>
        </Space>
        <SocialIcon platform="instagram" />
      </div>
    </React.Fragment>
  );
};

export default InstagramAccountListItem;
