/* eslint-disable react-hooks/exhaustive-deps */
import { Radio, Table, Space, Divider, Pagination, Button } from 'antd';
import { renderData } from './render-data';
import { useAppDispatch, useAppNavigation, useExtendedRequests } from '@core/.';
import AddRequestButton from '../AddRequestButton';
import {
  askForReview,
  fetchReviewRequests,
  selectRequest,
  requestAgain,
} from '@core/features/review-request/thunks';
import { deleteRequest } from '@core/features/review-request/thunks/deleteRequest.thunk';
import { useEffect, useMemo, useState } from 'react';
import { ReviewRequest } from '@core/models';
import { MIDDLE_STYLE } from '@constants/responsive';
import CardSectionHeader from '@components/CardSectionHeader';
import { Card } from 'antd';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { isInTimeWindow } from '@utils/date.util';
import moment from 'moment';
import { useURLQuery } from '@core/hooks/useUrlQuery';

const columns = [
  {
    title: 'CONTACT',
    dataIndex: 'name',
    key: 'name',
    width: '32%',
  },
  {
    title: 'STATUS',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'FOLLOWED UP',
    key: 'followedUp',
    dataIndex: 'followedUp',
  },
  {
    title: 'REQUESTED DATE',
    key: 'date',
    dataIndex: 'date',
  },
  {
    title: 'SOURCE',
    key: 'source',
    dataIndex: 'source',
  },
  {
    title: 'ACTIONS',
    key: 'action',
    dataIndex: 'action',
  },
];

enum ReviewStatus {
  Reviewed = 'REVIEWED',
  Pending = 'PENDING',
  Drafted = 'DRAFTED',
}

const DEFAULT_CURRENT_PAGE_SIZE = 20;

const ActionRateWidget = ({
  actionTitle,
  value,
  isPercentage,
}: {
  actionTitle: string;
  value: number;
  isPercentage: boolean;
}) => {
  return (
    <Card className="no-padding" style={{ padding: '0px', borderRadius: 10 }}>
      <div
        style={{
          padding: '5px 10px',
          ...MIDDLE_STYLE,
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <CardSectionHeader
          style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}
          title={actionTitle}
        />
        <h3 style={{ fontSize: 14, marginLeft: 15 }}>
          {parseFloat(value.toFixed(2)) || 0}
          {isPercentage ? '%' : ''}
        </h3>
      </div>
    </Card>
  );
};

export type RequestOperationHandler = (
  id: number,
  action: 'delete' | 'view' | 'submit' | 'request-again',
  extraParams?: Record<string, any>
) => void;

function RequestEmailSMSTable() {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const query = useURLQuery();
  const showstat = useMemo<string>(() => query.get('showstat') || '', [query]);

  const [currentPage, setCurrentPage] = useState(1);
  const tabs = [
    'ALL',
    ReviewStatus.Pending,
    ReviewStatus.Reviewed,
    'FOLLOWED UP',
    ReviewStatus.Drafted,
    'OPENED',
  ];
  const {
    isLoading,
    isMutating,
    all,
    followUpRequests,
    openedRequests,
    pendingRequests,
    reviewedRequests,
    draftedRequests,
  } = useExtendedRequests();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const onChange = (e: any) => {
    setSelectedTab(e.target.value);
  };

  const handler: RequestOperationHandler = async (id, action, extraParams) => {
    if (action === 'view') dispatch(selectRequest(id));
    else if (action === 'delete') dispatch(deleteRequest(id));
    else if (action === 'submit') {
      await dispatch(askForReview(extraParams?.request));
      await dispatch(fetchReviewRequests());
    } else if (action === 'request-again') {
      await dispatch(requestAgain(id));
    }
  };

  const renderedRequests = useMemo<ReviewRequest[]>(() => {
    return (
      {
        ALL: all,
        OPENED: openedRequests,
        PENDING: pendingRequests,
        REVIEWED: reviewedRequests,
        'FOLLOWED UP': followUpRequests,
        DRAFTED: draftedRequests,
      }[selectedTab] || []
    );
  }, [selectedTab, isLoading]);

  useEffect(() => {
    if (!isLoading && all.length === 0) navigate('requestCreation');
  }, [all]);

  return (
    <div className="screen-card">
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <h3 className="airbnb-font">Review Requests</h3>
        <div>
          <Space direction="horizontal" style={{ flexWrap: 'wrap' }}>
            <Radio.Group onChange={onChange} defaultValue={selectedTab}>
              {tabs.map(tab => (
                <Radio.Button value={tab}>{tab}</Radio.Button>
              ))}
            </Radio.Group>
            <AddRequestButton />
          </Space>
        </div>
      </div>
      <Divider />
      {showstat && (
        <Space style={{ flexWrap: 'wrap', ...MIDDLE_STYLE }}>
          <ActionRateWidget
            actionTitle="Total Review Requests"
            value={all.length}
            isPercentage={false}
          />
          <ActionRateWidget
            isPercentage
            actionTitle="Pending Rate"
            value={(pendingRequests.length / all.length) * 100}
          />
          <ActionRateWidget
            actionTitle="Opened Rate"
            value={((openedRequests.length + reviewedRequests.length) / all.length) * 100}
            isPercentage
          />
          <ActionRateWidget
            actionTitle="Reviewed Rate"
            value={(reviewedRequests.length / all.length) * 100}
            isPercentage
          />
          <ActionRateWidget
            actionTitle="New requests (7 days)"
            value={
              pendingRequests.filter(request =>
                isInTimeWindow(moment(request.created_date).toDate(), {
                  value: 1,
                  unit: 'week',
                })
              ).length
            }
            isPercentage={false}
          />
          <ActionRateWidget
            actionTitle="Reviewed requests (7 days)"
            value={
              reviewedRequests.filter(request =>
                isInTimeWindow(moment(request.created_date).toDate(), {
                  value: 1,
                  unit: 'week',
                })
              ).length
            }
            isPercentage={false}
          />
        </Space>
      )}
      <div style={{ ...MIDDLE_STYLE, margin: '20px 0px' }}>
        <Pagination
          current={currentPage}
          pageSize={DEFAULT_CURRENT_PAGE_SIZE}
          total={renderedRequests.length}
          onChange={setCurrentPage}
          hideOnSinglePage={true}
          showSizeChanger={false}></Pagination>
      </div>
      <div className="table-responsive">
        <Table
          loading={isLoading || isMutating}
          columns={columns}
          dataSource={renderData(handler, renderedRequests).slice(
            (currentPage - 1) * DEFAULT_CURRENT_PAGE_SIZE,
            currentPage * DEFAULT_CURRENT_PAGE_SIZE
          )}
          pagination={false}
          className="ant-border-space"
        />
      </div>
    </div>
  );
}

export default RequestEmailSMSTable;
