/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons';
import {
  useAppDispatch,
  useAuthState,
  useBusinessState,
  useStudioState,
} from '@core/configureStore';
import { closeModal, enqueueNotification, PhotoImageDataItem } from '@core/features';
import {
  Button,
  Calendar,
  Card,
  Col,
  Divider,
  Input,
  Popconfirm,
  Popover,
  Row,
  Skeleton,
  Space,
} from 'antd';
import moment from 'moment';
import {
  AnimatedComponent,
  DateTimeScheduleEditor,
  DescriptionItem,
  FileImageItemCard,
  GoogleBusinessButtonListContainer,
  LoadableContainer,
  PhotoGallerySuggestionList,
  SocialAccountButtonListContainer,
  SocialPostImage,
} from '..';
import * as dayjs from 'dayjs';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import {
  SocialPost,
  SocialPostMadeBy,
  SocialPostStage,
  SocialPostType,
  StudioPhoto,
} from '@core/models';
import { fetchGalleryImages, fetchSocialPosts } from '@core/features/studio/thunks';
import { socialStudioApiService } from '@services/service-register';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import NewlineText from '@components/NewlineText';
import SocialPostCard from '@components/SocialPostCard';
import SplashScreen from '@components/SplashScreen';
import SocialPostCardContainer from './SocialPostCardContainer';
import PublishedAccountsContainer from './PublishedAccountsContainer';
import UploadSocialPostMediaButton from './UploadSocialPostMediaButton';
import SocialPostTopicEditor from '@components/CreateSocialPostContainer/SocialPostTopicEditor';
import { DATE_FORMAT } from '@utils/date.util';
import { useSubscriptionDispatcher } from '@core/hooks';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import { countExistentialObject } from '@utils/array.util';
import StatusBadge from '@components/StatusBadge';

type Props = {
  type: SocialPostType;
  selectPhotoDisabled?: boolean;
  existingSocialPost?: SocialPost;
  defaultCaption?: string;
  defaultTopic?: string;
  defaultPhotoFile?: PhotoImageDataItem;
  onCreated?: any;
  onPublished?: any;
  style?: React.CSSProperties;
  defaultSelectedAccounts?: Record<string, boolean>;
  allowUploadPhoto?: boolean;
  allowScheduleLater?: boolean;
};

const CreateSocialPostContainer = ({
  selectPhotoDisabled,
  existingSocialPost,
  defaultCaption,
  defaultTopic,
  defaultPhotoFile,
  onCreated,
  onPublished,
  type,
  defaultSelectedAccounts = {},
  allowScheduleLater = true,
  allowUploadPhoto = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { isTablet } = useBreakpoint();
  const { integration } = useBusinessState();
  const today = moment();
  const [date, setDate] = useState<number>(today.unix());
  const [time, setTime] = useState<{
    hour: number;
    minute: number;
  }>({
    hour: today.hour(),
    minute: today.minute(),
  });
  const { studioPhotos } = useStudioState();
  const [sentTo, setSentTo] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [mutating, setMutating] = useState<boolean>(false);
  const [scheduleLaterEnabled, setScheduleLaterEnabled] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<PhotoImageDataItem[]>(
    defaultPhotoFile ? [defaultPhotoFile] : []
  );
  const [caption, setCaption] = useState<string>(defaultCaption || '');
  const [generateContentEnabled, setGenerateContentEnabled] = useState<boolean>(true);
  const getDefaultGeneratedNonce = () =>
    defaultTopic || `Introduce about ${user?.selected_location?.title}`;
  const [generateNonce, setGenerateNonce] = useState<string>(getDefaultGeneratedNonce());
  const [isUpdated, setUpdated] = useState<boolean>(false);
  const { checkPassiveTriggerActivated, handleActivateNow } = useSubscriptionDispatcher();
  const selectedLocation = useMemo(() => user?.selected_location, [user]);

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'newSocialPostModal',
      })
    );
  };

  const onCalendarSelected = (day: dayjs.Dayjs) => {
    setDate(day.unix());
    setUpdated(true);
  };

  const handleCreateSocialPost = async () => {
    try {
      setMutating(true);
      const scheduledDate = moment.unix(date);
      const scheduleTime = moment({
        hour: time.hour,
        minute: time.minute,
        day: scheduledDate.get('date'),
        month: scheduledDate.get('month'),
      }).unix();
      const accountDestinations = Object.keys(sentTo).filter(
        sentToAccount => sentTo[sentToAccount]
      );
      await socialStudioApiService.createSocialPost({
        fileImageItems: selectedPhotos,
        profileIds: accountDestinations,
        caption: caption,
        scheduleLaterEnabled,
        scheduleTime,
        type,
        topic: generateNonce,
      });
      handleReset();
      dispatch(
        enqueueNotification({
          name: 'Successfully create a new social post',
          description: 'Create a new social post successfully',
          type: 'Success',
        })
      );
      if (onCreated) onCreated();
    } catch (error: any) {
      console.log(error);
      dispatch(
        enqueueNotification({
          name: error.message,
          description: 'Create a new social post failed',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const handleSaveSocialPost = async () => {
    if (!existingSocialPost) return;
    if (isUpdated) {
      try {
        setMutating(true);
        await socialStudioApiService.updateSocialPost(
          existingSocialPost.id,
          date,
          caption,
          generateNonce
        );
        dispatch(
          enqueueNotification({
            name: 'Social post is updated',
            description: 'Successfully updated social post',
            type: 'Success',
          })
        );
      } catch (error) {
        dispatch(
          enqueueNotification({
            name: 'Failed to update social post',
            description: 'Could not update social post',
            type: 'Error',
          })
        );
      }
      setUpdated(false);
      setMutating(false);
      await dispatch(fetchSocialPosts());
    }
  };

  const handlePublishNow = async () => {
    if (!existingSocialPost) return;
    setMutating(true);
    try {
      const selectedAccount = Object.keys(sentTo).filter(key => sentTo[key]);
      await socialStudioApiService.publishSocialPost(existingSocialPost.id, selectedAccount);
      await dispatch(fetchSocialPosts());
      dispatch(
        enqueueNotification({
          name: 'Social post is published',
          description: 'Successfully published social post',
          type: 'Success',
        })
      );
      await dispatch(fetchSelectedLocation());
      if (onPublished) onPublished();
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to publish social post',
          description: 'Could not publish social post',
          type: 'Error',
        })
      );
    }
    setMutating(false);
    handleCloseModal();
  };

  const handleRegenerateContent = async () => {
    setGenerating(true);
    try {
      if (!user?.selected_location) return;
      const content = await socialStudioApiService.generateSocialPostContent({
        location: user.selected_location,
        topic: generateNonce,
      });
      if (existingSocialPost) {
        await socialStudioApiService.updateSocialPost(
          existingSocialPost.id,
          date,
          content,
          generateNonce
        );
        await dispatch(fetchSocialPosts());
      }
      setCaption(content);
      dispatch(
        enqueueNotification({
          name: 'New post content generated!',
          description: 'Successfully generate content for social post',
          type: 'Success',
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Generate social post content failed',
          description: 'Could not generate content for social post',
          type: 'Error',
        })
      );
    }
    setGenerating(false);
  };

  const handleReset = () => {
    setCaption('');
    setScheduleLaterEnabled(false);
    setDate(moment().unix());
    setSelectedPhotos([]);
  };

  const handleDiscard = async () => {
    await handleInitSocialPostParams();
    setUpdated(false);
  };

  const handleInitSocialPostParams = async () => {
    if (!existingSocialPost) return;
    if (existingSocialPost.status === SocialPostStage.Scheduled) {
      setScheduleLaterEnabled(true);
    }
    setCaption(existingSocialPost.content);
    if (existingSocialPost.publish_at) {
      const existingPublishDate = moment(new Date(existingSocialPost.publish_at));
      setDate(existingPublishDate.unix());
      setTime({
        hour: existingPublishDate.hour(),
        minute: existingPublishDate.minute(),
      });
    }
    if (existingSocialPost.mediaIds.length > 0) {
      const mediaIdUrl = existingSocialPost.mediaIds[0];
      if (mediaIdUrl) {
        const studioPhoto = await socialStudioApiService.getStudioPhotoByMediaId(mediaIdUrl);
        if (existingSocialPost.topic) {
          setGenerateNonce(existingSocialPost.topic);
        } else if (studioPhoto && studioPhoto.description.length > 0) {
          setGenerateNonce(studioPhoto.description);
        } else {
          setGenerateNonce(getDefaultGeneratedNonce());
        }
      }
    }
  };

  const handleSchedulePost = () => {
    setCalendarOpen(true);
  };

  const handleSetScheduleTime = (scheduledTime: dayjs.Dayjs | null, _: string | string[]) => {
    if (!scheduledTime) return;
    setTime({
      hour: scheduledTime.hour(),
      minute: scheduledTime.minute(),
    });
  };

  const handleEnableGenerateContent = () => {
    setGenerateContentEnabled(!generateContentEnabled);
  };

  const handleOnUpload = (mediaItems: PhotoImageDataItem[]) => {
    setSelectedPhotos(mediaItems);
  };

  const handleEnableScheduleLater = () => {
    setScheduleLaterEnabled(!scheduleLaterEnabled);
  };

  const handleSocialPostCaptionChanged = (e: any) => {
    setCaption(e.target.value);
    setUpdated(true);
  };

  const handleRemoveSocialPost = async () => {
    if (!existingSocialPost) return;
    setLoading(true);
    await socialStudioApiService.removeSocialPost(existingSocialPost.id);
    await dispatch(fetchSocialPosts());
    handleCloseModal();
    setLoading(false);
  };

  const onPhotoChanged = async (photos: StudioPhoto[]) => {
    const convertedPhotos = photos.map(photo => ({
      url: photo.url,
      data: undefined,
      name: photo.name,
      id: photo.id,
      extension: photo.extension,
      _studioPhotoId: photo.id,
    }));
    handleOnUpload(convertedPhotos);
  };

  const isSocialPostPublished = useMemo(
    () => existingSocialPost?.status === SocialPostStage.Published,
    [existingSocialPost]
  );

  const publishDisabled = useMemo(
    () => caption.length === 0 || countExistentialObject(sentTo) === 0,
    [caption, sentTo]
  );

  useEffect(() => {
    if (integration.facebook_pages && integration.instagram_profiles) {
      const sentTo: Record<string, boolean> = {};
      for (const page of [...integration.facebook_pages, ...integration.instagram_profiles]) {
        sentTo[page.id] = true;
      }
      setSentTo(sentTo);
    }
  }, [integration.facebook_pages, integration.instagram_profiles]);

  useEffect(() => {
    const init = async () => {
      if (existingSocialPost && existingSocialPost.publish_at) {
        setDate(moment(existingSocialPost.publish_at).unix());
      } else {
        setDate(today.unix());
      }
    };
    init();
    // eslint-disable-next-line
  }, [existingSocialPost, scheduleLaterEnabled, type]);

  useEffect(() => {
    setSelectedPhotos(defaultPhotoFile ? [defaultPhotoFile] : []);
  }, [defaultPhotoFile]);

  useEffect(() => {
    handleInitSocialPostParams();
  }, [existingSocialPost]);

  useEffect(() => {
    handleDiscard();
  }, []);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchGalleryImages());
    };
    init();
  }, []);

  useEffect(() => {
    if (countExistentialObject(defaultSelectedAccounts)) {
      setSentTo(defaultSelectedAccounts);
    }
  }, [defaultSelectedAccounts]);

  return (
    <React.Fragment>
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        <div
          style={{
            maxWidth: '100%',
            height: isTablet ? '100%' : 'auto',
            overflow: 'hidden',
            width: '100%',
            backgroundColor: 'white',
            padding: '20px 30px',
          }}>
          <div
            style={{
              ...MIDDLE_STYLE,
              maxHeight: '100%',
              overflow: 'hidden',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            <h2 className="airbnb-font" style={{ marginBottom: '20px' }}>
              {type === SocialPostType.GoogleBusinessPost ? (
                <React.Fragment>
                  {existingSocialPost
                    ? 'Edit a Google Business post'
                    : 'Create a New Google Business Post'}{' '}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {existingSocialPost ? 'Edit a social post' : 'Create new social post'}{' '}
                </React.Fragment>
              )}
            </h2>
            <Space style={{ flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
              {existingSocialPost && (
                <Button style={{ width: '100%' }} onClick={handleCloseModal}>
                  Cancel
                </Button>
              )}
              <Space style={{ flexWrap: 'wrap' }}>
                {existingSocialPost ? (
                  <React.Fragment>
                    {existingSocialPost && !isSocialPostPublished && (
                      <React.Fragment>
                        <Popconfirm
                          onConfirm={handleRemoveSocialPost}
                          title="Delete the post"
                          description="Are you sure to delete this post?"
                          okButtonProps={{
                            size: 'middle',
                          }}
                          cancelButtonProps={{
                            size: 'middle',
                          }}
                          okText="Yes"
                          cancelText="No">
                          <Button danger>
                            <DeleteOutlined /> Delete
                          </Button>
                        </Popconfirm>
                      </React.Fragment>
                    )}
                    {existingSocialPost.status !== SocialPostStage.Published && (
                      <React.Fragment>
                        <Button
                          style={{ width: '100%' }}
                          className="success-btn"
                          loading={mutating}
                          disabled={!isUpdated}
                          onClick={handleSaveSocialPost}
                          type="primary">
                          <SaveOutlined style={{ marginRight: -5 }} /> Update
                        </Button>
                        <Button
                          style={{ width: '100%' }}
                          className="error-btn"
                          loading={mutating}
                          disabled={!isUpdated}
                          onClick={handleDiscard}
                          type="primary">
                          <SaveOutlined style={{ marginRight: -5 }} /> Discard
                        </Button>
                        {selectedLocation &&
                        checkPassiveTriggerActivated(
                          selectedLocation,
                          'manual_social_post_published'
                        ) ? (
                          <Button
                            style={{ width: '100%' }}
                            disabled={publishDisabled}
                            loading={mutating}
                            type="primary"
                            onClick={handlePublishNow}>
                            <SendOutlined style={{ marginRight: -5 }} rotate={-30} />
                            Publish Now
                          </Button>
                        ) : (
                          <Button
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={handleActivateNow}>
                            <SendOutlined style={{ marginRight: -5 }} rotate={-30} />
                            Publish Now
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <Button
                    disabled={!existingSocialPost && publishDisabled}
                    loading={mutating}
                    onClick={handleCreateSocialPost}
                    type="primary">
                    <SendOutlined style={{ marginRight: -5 }} rotate={-30} />{' '}
                    {scheduleLaterEnabled ? 'Schedule' : 'Publish Now'}
                  </Button>
                )}
              </Space>
            </Space>
          </div>
          <Divider />
          <AnimatedComponent.OpacityFadeInDiv delay={300}>
            {calendarOpen ? (
              <Card
                title={
                  <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                    <h3>Calendar</h3>
                    <Button onClick={() => setCalendarOpen(false)}>
                      <ArrowLeftOutlined /> Back
                    </Button>
                  </div>
                }>
                <Calendar
                  disabledDate={current => current.isBefore(dayjs.default().subtract(1, 'day'))}
                  value={dayjs.unix(date)}
                  fullscreen={false}
                  onSelect={onCalendarSelected}
                />
              </Card>
            ) : (
              <Row gutter={30}>
                <Col
                  style={{ margin: isTablet ? '10px' : 0 }}
                  span={isTablet ? 24 : allowUploadPhoto ? 14 : 24}>
                  {existingSocialPost && (
                    <DescriptionItem
                      title="Status"
                      content={<StatusBadge status={existingSocialPost.status} />}
                    />
                  )}
                  {existingSocialPost && (
                    <DescriptionItem
                      title="Created Date"
                      content={<>{moment(existingSocialPost.created_date).format(DATE_FORMAT)}</>}
                    />
                  )}
                  {existingSocialPost && existingSocialPost.published_accounts && (
                    <DescriptionItem
                      title={'Published to'}
                      style={{ display: 'block' }}
                      content={
                        <PublishedAccountsContainer
                          publishedAccounts={existingSocialPost.published_accounts}
                        />
                      }
                    />
                  )}
                  {((existingSocialPost &&
                    existingSocialPost?.status !== SocialPostStage.Published) ||
                    !existingSocialPost) &&
                    (type === SocialPostType.Default || type === SocialPostType.ShowcaseReview) && (
                      <DescriptionItem
                        title="Sent to"
                        style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
                        content={
                          <SocialAccountButtonListContainer
                            selectedAccounts={sentTo}
                            setSelectedAccounts={setSentTo}
                          />
                        }
                      />
                    )}
                  {((existingSocialPost &&
                    existingSocialPost?.status !== SocialPostStage.Published) ||
                    !existingSocialPost) &&
                    type === SocialPostType.GoogleBusinessPost && (
                      <DescriptionItem
                        title="Publish to Businesses"
                        style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
                        content={
                          <GoogleBusinessButtonListContainer
                            selectedAccounts={sentTo}
                            setSelectedAccounts={setSentTo}
                          />
                        }
                      />
                    )}
                  <DescriptionItem
                    title="Topic"
                    style={{ justifyContent: 'space-between' }}
                    content={
                      <React.Fragment>
                        {!isSocialPostPublished && (
                          <div
                            onClick={handleEnableGenerateContent}
                            style={{
                              ...MIDDLE_STYLE,
                              width: 'fit-content',
                              color: GLOBAL_THEME_COLOR.$highlight_color,
                              cursor: 'pointer',
                            }}>
                            Using AI assistant
                            {!generateContentEnabled ? (
                              <TbChevronDown style={{ marginLeft: 5 }} />
                            ) : (
                              <TbChevronUp style={{ marginLeft: 5 }} />
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    }
                  />
                  {generateContentEnabled && !isSocialPostPublished && (
                    <SocialPostTopicEditor
                      topic={generateNonce}
                      loading={generating}
                      onTopicChanged={setGenerateNonce}
                      existingSocialPost={existingSocialPost}
                      onGenerateButtonClicked={handleRegenerateContent}
                    />
                  )}
                  {!existingSocialPost && allowUploadPhoto && studioPhotos.length > 0 && (
                    <PhotoGallerySuggestionList onPhotosChanged={onPhotoChanged} />
                  )}
                  <div style={{ display: 'flex', margin: '10px 0px' }}>
                    {existingSocialPost ? (
                      <React.Fragment>
                        {existingSocialPost.mediaIds.map(mediaId => (
                          <SocialPostImage
                            style={{
                              width: 50,
                              aspectRatio: '1/1',
                              marginRight: 10,
                            }}
                            mediaId={mediaId}
                          />
                        ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {selectedPhotos.map((selectedPhoto, index) => (
                          <div style={{ position: 'relative' }}>
                            <Button
                              className="no-padding"
                              style={{
                                height: '30px',
                                width: '30px',
                                borderRadius: '50px',
                                position: 'absolute',
                                top: -10,
                                left: -10,
                                zIndex: 99,
                              }}
                              onClick={() => {
                                setSelectedPhotos(selectedPhotos.filter((_, i) => i !== index));
                              }}>
                              <DeleteOutlined style={{ marginLeft: 5 }} />
                            </Button>
                            <FileImageItemCard
                              item={selectedPhoto}
                              style={{
                                padding: 0,
                                margin: 0,
                                borderRadius: 10,
                                width: 80,
                                marginRight: 10,
                              }}
                            />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </div>
                  {existingSocialPost && isSocialPostPublished ? (
                    <NewlineText text={caption} />
                  ) : (
                    <LoadableContainer isLoading={generating} loadComponent={<Skeleton />}>
                      <Input.TextArea
                        value={caption}
                        onChange={handleSocialPostCaptionChanged}
                        rows={5}
                        autoFocus
                        showCount
                        maxLength={1000}
                        placeholder="Enter a social post caption"
                      />
                    </LoadableContainer>
                  )}
                  {allowScheduleLater && (
                    <DateTimeScheduleEditor
                      date={date}
                      time={time}
                      scheduleLaterEnabled={scheduleLaterEnabled}
                      handleEnableScheduleLater={handleEnableScheduleLater}
                      handleSetScheduleTime={handleSetScheduleTime}
                      handleSchedulePost={handleSchedulePost}
                    />
                  )}
                </Col>
                {allowUploadPhoto && (
                  <Col style={{ margin: isTablet ? '10px' : 0 }} span={isTablet ? 24 : 10}>
                    {user?.selected_location.location_id && (
                      <React.Fragment>
                        {existingSocialPost ? (
                          <SocialPostCardContainer
                            locationId={user.selected_location.location_id}
                            existingSocialPost={existingSocialPost}
                            caption={caption}
                            date={date}
                          />
                        ) : (
                          <React.Fragment>
                            <div
                              style={{
                                ...MIDDLE_STYLE,
                                flexDirection: 'column',
                                height: '100%',
                                backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
                                backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
                                backgroundSize: '20px 20px',
                                border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
                              }}>
                              <LoadableContainer
                                isLoading={loading}
                                loadComponent={<SplashScreen />}>
                                <SocialPostCard
                                  onClickDisabled={true}
                                  locationId={user?.selected_location.location_id}
                                  CustomPhotoRenderer={() => (
                                    <UploadSocialPostMediaButton
                                      mediaItems={selectedPhotos}
                                      onMediaUploaded={handleOnUpload}
                                      selectPhotoDisabled={!!selectPhotoDisabled}
                                    />
                                  )}
                                  socialPost={{
                                    id: 1,
                                    mediaIds: [],
                                    topic: generateNonce,
                                    status: SocialPostStage.Scheduled,
                                    type: SocialPostType.Default,
                                    created_date: moment.unix(date).toString(),
                                    made_by: SocialPostMadeBy.User,
                                    published_accounts: [],
                                    updated_date: moment.unix(date).toString(),
                                    content: generating
                                      ? 'Social post content is being generated...'
                                      : caption,
                                    publish_at: moment.unix(date).toString(),
                                  }}
                                  style={{
                                    transform: 'scale(0.9)',
                                    boxShadow: STRIPE_BOX_SHADOW,
                                    width: '500px',
                                  }}
                                />
                              </LoadableContainer>
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </Col>
                )}
              </Row>
            )}
          </AnimatedComponent.OpacityFadeInDiv>
        </div>
      </LoadableContainer>
    </React.Fragment>
  );
};

export default CreateSocialPostContainer;
