import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  InputNumber,
  Radio,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from 'antd';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { EmbeddedWallOfLoveSetting, GoogleReview } from '@core/models';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';
import { useBreakpoint } from '@core/hooks';
import moment from 'moment/moment';
import CardSectionHeader from '@components/CardSectionHeader';
import {
  BackgroundSolidSetting,
  DescriptionItem,
  HighlightedSubText,
  ReviewWallOfLove,
} from '@components/index';
import TextArea from 'antd/es/input/TextArea';
import { CheckOutlined, CopyOutlined, SaveOutlined } from '@ant-design/icons';
import { enqueueNotification } from '@core/features';
import { heroApiService, reviewApiService } from '@services/service-register';
import { Widget } from '@core/models/widget';
import {
  buildCachePayload,
  getJsonCacheData,
  isCacheExpires,
  storeJsonCacheData,
} from '@utils/cache.util';
import { DEFAULT_EMBEDDED_WOL_SETTING } from '@constants/defaults';
import { starRatingToNumber } from '@utils/type.util';
import { cloneArray } from '@utils/array.util';

type Props = {};

const MAX_REVIEWS = 20;
const MAX_REVIEWS_MOBILE = 6;
const REVIEWS_CACHE_EXPIRATION_KEY = 'REVIEW_CACHE_EXPIRATION_KEY';
// Refetch the cache every 5 days
const REVIEWS_CACHE_DURATION = 1000 * 60 * 60 * 24 * 5;

enum ColorTheme {
  DarkMode = 'DarkMode',
  WhiteMode = 'WhiteMode',
  CustomMode = 'CustomMode',
}

const DARK_MODE_COLOR_THEME = {
  cardColor: 'black',
  cardTitleColor: 'white',
};

const WHITE_MODE_COLOR_THEME = {
  cardColor: 'white',
  cardTitleColor: 'black',
};

const ReviewWallOfLoveScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { user } = useAuthState();
  const currentLocation = useMemo(() => user?.selected_location, [user]);
  const [embeddedSettingUpdated, setEmbeddedSettingUpdated] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [featuredReviews, setFeaturedReviews] = useState<GoogleReview[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<ColorTheme>(ColorTheme.WhiteMode);
  const [embeddedSetting, setEmbeddedSetting] = useState<EmbeddedWallOfLoveSetting | undefined>(
    DEFAULT_EMBEDDED_WOL_SETTING
  );
  const FEATURE_MAX_REVIEWS = useMemo(
    () => embeddedSetting?.maxReviews || MAX_REVIEWS,
    [embeddedSetting]
  );

  const FEATURE_MAX_REVIEWS_MOBILE = useMemo(
    () => embeddedSetting?.maxReviewsMobile || MAX_REVIEWS_MOBILE,
    [embeddedSetting]
  );
  const RESPONSIVE_FEATURE_MAX_REVIEWS = useMemo(
    () => (isTablet ? FEATURE_MAX_REVIEWS_MOBILE : FEATURE_MAX_REVIEWS),
    [FEATURE_MAX_REVIEWS, FEATURE_MAX_REVIEWS_MOBILE]
  );

  const embeddedCode = useMemo(() => {
    return `<div class="amazely-wall-of-love-${currentLocation?.location_id}" />\n<script src="https://embed-wall-of-love.amazely.co/index.js" defer></script>`;
  }, [currentLocation]);

  const handleUpdateEmbeddedSetting = (
    partialEmbeddedSetting: Partial<EmbeddedWallOfLoveSetting>,
    noUpdated: boolean = false
  ) => {
    setEmbeddedSetting({
      ...embeddedSetting,
      ...partialEmbeddedSetting,
    } as any);
    if (!noUpdated) {
      setEmbeddedSettingUpdated(true);
    }
  };

  const getBestReviews = (reviews: GoogleReview[]) => {
    const featuredReviews = reviews.slice(0, RESPONSIVE_FEATURE_MAX_REVIEWS);
    const sortedFeaturedReviews = cloneArray(featuredReviews)
      .filter(review => starRatingToNumber(review.starRating) >= 4)
      .sort((reviewA, reviewB) =>
        moment(reviewA.updateTime).unix() > moment(reviewB.updateTime).unix() ? -1 : 1
      );
    return sortedFeaturedReviews;
  };

  const getCurrentWidget = async () => {
    if (currentLocation && embeddedSetting) {
      try {
        const widget: Widget = await heroApiService.getWidgetByLocationID(
          currentLocation.location_id
        );
        const cacheData = getJsonCacheData(REVIEWS_CACHE_EXPIRATION_KEY);
        // We will refetch reviews if the cache expires and there is not enough reviews stored in the widget
        if (
          cacheData &&
          !isCacheExpires(cacheData) &&
          widget &&
          widget.reviews.length > MAX_REVIEWS
        ) {
          setFeaturedReviews(widget.reviews);
        } else {
          // Caching the temporary data to check for later expiration status
          storeJsonCacheData(
            REVIEWS_CACHE_EXPIRATION_KEY,
            buildCachePayload(true, REVIEWS_CACHE_DURATION)
          );
          const reviews = await heroApiService.getOnceLocationGoogleReviews(
            currentLocation.location_id
          );
          setFeaturedReviews(getBestReviews(reviews));
        }
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(embeddedCode);
    setIsCopied(true);
  };

  const handleSaveSettingChanges = async () => {
    try {
      await reviewApiService.updateEmbeddedWallOfLoveSetting(embeddedSetting || {});
      dispatch(
        enqueueNotification({
          name: 'Embedded setting is updated.',
          description: 'Embedded setting is updated.',
          type: 'Success',
        })
      );
      setEmbeddedSettingUpdated(false);
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Embedded setting is failed to update.',
          description: 'Embedded setting is failed to update',
          type: 'Error',
        })
      );
    }
  };

  const fetchReviewSetting = async () => {
    if (currentLocation?.location_id) {
      const reviewSetting = await reviewApiService.getLocationReviewSetting();
      if (reviewSetting) {
        const _embeddedSetting = reviewSetting.wol_embedded_setting;
        await setEmbeddedSetting(_embeddedSetting);
        const { cardColor, cardTitleColor } = _embeddedSetting;
        if (
          cardColor === WHITE_MODE_COLOR_THEME.cardColor &&
          cardTitleColor === WHITE_MODE_COLOR_THEME.cardTitleColor
        ) {
          setSelectedTheme(ColorTheme.WhiteMode);
        } else if (
          cardColor === DARK_MODE_COLOR_THEME.cardColor &&
          cardTitleColor === DARK_MODE_COLOR_THEME.cardTitleColor
        ) {
          setSelectedTheme(ColorTheme.DarkMode);
        } else {
          setSelectedTheme(ColorTheme.CustomMode);
        }
      }
    }
  };

  const handleUpdateTheme = (theme: ColorTheme) => {
    switch (theme) {
      case ColorTheme.WhiteMode:
        handleUpdateEmbeddedSetting(WHITE_MODE_COLOR_THEME);
        break;
      case ColorTheme.DarkMode:
        handleUpdateEmbeddedSetting(DARK_MODE_COLOR_THEME);
        break;
      case ColorTheme.CustomMode:
        handleUpdateEmbeddedSetting(embeddedSetting as any);
        break;
    }
    setSelectedTheme(theme);
  };

  useEffect(() => {
    const init = async () => {
      await fetchReviewSetting();
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      if (isLoaded) return;
      try {
        setIsLoading(true);
        await getCurrentWidget();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [embeddedSetting, currentLocation]);

  return (
    <div
      style={{
        padding: '0px 50px',
        backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
        paddingTop: 50,
      }}>
      <div
        style={{
          boxShadow: STRIPE_BOX_SHADOW,
          backgroundColor: GLOBAL_THEME_COLOR.$background_color,
          position: 'relative',
          padding: '30px 50px',
          border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          height: 'fit-content',
          borderRadius: 10,
        }}>
        <React.Fragment>
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>
              Embed "Wall of Love" of {user?.selected_location?.title}
            </h1>
          </div>
          <p>
            Amazely cherry-pick top-notch reviews from your business to showcase on social channels
          </p>
          <Divider />
          <LoadableContainer isLoading={isLoading} loadComponent={<Skeleton />}>
            <React.Fragment>
              {embeddedSetting && (
                <React.Fragment>
                  <Row style={{ width: '100%' }} slot="30">
                    <Col span={isTablet ? 24 : 12} style={{ width: '100%' }}>
                      <Space size={40} direction="vertical" style={{ width: '100%' }}>
                        <DescriptionItem
                          title={'Theme'}
                          style={{ margin: 0 }}
                          content={
                            <Radio.Group
                              onChange={e => handleUpdateTheme(e.target.value)}
                              value={selectedTheme}>
                              <Radio value={ColorTheme.WhiteMode}>White Mode</Radio>
                              <Radio value={ColorTheme.DarkMode}>Dark Mode</Radio>
                              <Radio value={ColorTheme.CustomMode}>Custom</Radio>
                            </Radio.Group>
                          }
                        />
                        {selectedTheme === ColorTheme.CustomMode && (
                          <Space size={30}>
                            <BackgroundSolidSetting
                              title="Blog Card Background"
                              value={embeddedSetting?.cardColor}
                              onValueChanged={value =>
                                handleUpdateEmbeddedSetting({
                                  cardColor: value,
                                })
                              }
                            />
                            <BackgroundSolidSetting
                              title="Text Color"
                              value={embeddedSetting?.cardTitleColor}
                              onValueChanged={value =>
                                handleUpdateEmbeddedSetting({
                                  cardTitleColor: value,
                                })
                              }
                            />
                          </Space>
                        )}
                        <Space size={30} style={{ flexWrap: 'wrap' }}>
                          <DescriptionItem
                            title={'Container Width'}
                            style={{ margin: 0 }}
                            content={
                              <InputNumber
                                value={embeddedSetting.maxContainerSize}
                                min={500}
                                max={3500}
                                onChange={e =>
                                  handleUpdateEmbeddedSetting({
                                    maxContainerSize: e || embeddedSetting.maxContainerSize,
                                  })
                                }
                              />
                            }
                          />
                          <DescriptionItem
                            title={'Max Reviews'}
                            style={{ margin: 0 }}
                            content={
                              <InputNumber
                                value={FEATURE_MAX_REVIEWS}
                                min={1}
                                max={40}
                                onChange={e =>
                                  handleUpdateEmbeddedSetting({
                                    maxReviews: e || embeddedSetting.maxReviews,
                                  })
                                }
                              />
                            }
                          />
                        </Space>
                        <Space size={30} style={{ flexWrap: 'wrap' }}>
                          <DescriptionItem
                            title={'Max Reviews Mobile'}
                            style={{ margin: 0 }}
                            content={
                              <InputNumber
                                value={FEATURE_MAX_REVIEWS_MOBILE}
                                min={1}
                                max={40}
                                onChange={e =>
                                  handleUpdateEmbeddedSetting({
                                    maxReviewsMobile: e || embeddedSetting.maxReviewsMobile,
                                  })
                                }
                              />
                            }
                          />
                          <DescriptionItem
                            title={'Hide No Comment Reviews'}
                            style={{ margin: 0 }}
                            content={
                              <Checkbox
                                checked={embeddedSetting.hideNodeCommentReviews}
                                onChange={e =>
                                  handleUpdateEmbeddedSetting({
                                    hideNodeCommentReviews: e.target.checked,
                                  })
                                }
                              />
                            }
                          />
                        </Space>
                        {embeddedSettingUpdated && (
                          <Button
                            onClick={handleSaveSettingChanges}
                            type="primary"
                            className="success-btn"
                            style={{ marginBottom: 10 }}>
                            <SaveOutlined />
                            Save Setting
                          </Button>
                        )}
                      </Space>
                    </Col>
                    <Col span={isTablet ? 24 : 12} style={{ width: '100%' }}>
                      <React.Fragment>
                        <div>
                          <CardSectionHeader title="Add to your web page" />
                          <p>
                            Copy and add this script to your web page. You{' '}
                            <HighlightedSubText content="only need to copy it once." />
                          </p>
                          <Divider />
                          <TextArea
                            style={{ marginTop: '10px' }}
                            contentEditable={false}
                            value={embeddedCode}
                            autoSize={{ minRows: 2 }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'baseline',
                              marginBottom: '10px',
                            }}>
                            <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                              <Button
                                type="link"
                                size="small"
                                icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}
                                onClick={handleCopy}>
                                {isCopied ? 'Copied' : 'Copy'}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </React.Fragment>
                    </Col>
                  </Row>
                  <Divider />
                  <LoadableContainer isLoading={isLoading} loadComponent={<SplashScreen />}>
                    <LoadableContainer
                      isLoading={featuredReviews.length === 0}
                      loadComponent={
                        <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>No reviews found</span>}
                          />
                        </div>
                      }>
                      <ReviewWallOfLove
                        embeddedSetting={embeddedSetting}
                        maxReviews={RESPONSIVE_FEATURE_MAX_REVIEWS}
                        reviews={featuredReviews}
                      />
                    </LoadableContainer>
                  </LoadableContainer>
                </React.Fragment>
              )}
            </React.Fragment>
          </LoadableContainer>
        </React.Fragment>
      </div>
    </div>
  );
};

export default ReviewWallOfLoveScreen;
