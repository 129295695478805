import { RootState } from '@core/configureStore';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, formatTimestamp, getSideEffectState } from '@utils/.';
import moment from 'moment';
import { ReviewRequestReducerProps } from '../type';
import { fetchReviewRequests } from './fetchReviewRequest.thunk';

export const deleteRequest = createAsyncThunk(
  'review-request/deleteRequest',
  async (id: number, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('Not user found');

      await heroApiService.deleteRequestById(id);
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Delete review successfully',
          description: `Request is deleted ${formatTimestamp(moment.now())}`,
          type: 'Success',
        } as SnackbarItem)
      );
      thunkApi.dispatch(fetchReviewRequests());
      return id;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to delete request',
          description: `Review request deleted failed. Please try again`,
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(deleteRequest);

export const deleteRequestReducer = {
  [fulfilled]: (state, action: PayloadAction<number>) => {
    state.isLoading = false;
    state.isMutating = false;
    state.requests = state.requests.filter(request => request.id !== action.payload);
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.isMutating = false;
    state.error = '';
  },
  [pending]: (state, action) => {
    state.isLoading = true;
    state.isMutating = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
