import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Divider, List, Modal } from 'antd';
import {
  useAppDispatch,
  useAuthState,
  useBusinessState,
  useModalState,
} from '@core/configureStore';
import { LicenseType, LocalLocation } from '@core/models';
import { closeModal } from '@core/features';
import { heroApiService } from '@services/service-register';
import { fetchAmazelyLocalLocations } from '@core/features/business/thunks/fetchAmazelyLocalLocations';
import { LocationLicenseBadge } from '..';

type Props = {};

const ManageLicenseModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { manageLicenseModal } = useModalState();
  const { user } = useAuthState();
  const [refreshing, setRefreshing] = useState(+new Date());
  const { localLocations, isLocalLocationLoading } = useBusinessState();

  const assignedLocations = useMemo(
    () =>
      localLocations.filter(
        location =>
          location.license_type === LicenseType.Pro && location.license_owner_id === user?.id
      ),
    [user, localLocations]
  );

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'manageLicenseModal',
      })
    );
  };

  const handleRevokeLicense = async (item: LocalLocation) => {
    try {
      await heroApiService.revokeLicenseFromLocation(item.location_id);
      setRefreshing(+new Date());
    } catch (e) {
      console.log(e);
    }
  };

  const handleAssignLicense = async (item: LocalLocation) => {
    try {
      await heroApiService.assignLicenseToLocation(item.location_id);
      setRefreshing(+new Date());
    } catch (e) {
      console.log(e);
    }
  };

  const renderDescription = (item: LocalLocation) => {
    const address = item?.full_address;
    return address ? item?.full_address : 'No Storefront Address';
  };

  useEffect(() => {
    const init = async () => {
      try {
        dispatch(fetchAmazelyLocalLocations());
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, [user, manageLicenseModal, refreshing]);

  return (
    <Modal
      title={'Manage License'}
      open={manageLicenseModal}
      footer={[]}
      destroyOnClose={true}
      onCancel={handleModalClose}
      closable>
      <p>
        Number of available licenses: {(user?.no_licenses || 0) - assignedLocations.length}/
        {user?.no_licenses || 0}
      </p>
      <Divider />
      <List
        style={{ marginTop: 20 }}
        loading={isLocalLocationLoading}
        dataSource={localLocations}
        renderItem={item => (
          <React.Fragment>
            <List.Item
              actions={[
                <Checkbox
                  onChange={async e => {
                    if (e.target.checked) {
                      await handleAssignLicense(item);
                    } else {
                      await handleRevokeLicense(item);
                    }
                  }}
                  disabled={
                    item.license_type === LicenseType.Pro && item.license_owner_id !== user?.id
                  }
                  checked={item.license_type === LicenseType.Pro}
                />,
              ]}
              key={item.id}>
              <List.Item.Meta
                title={
                  <div>
                    <p>{item.title}</p>
                    <LocationLicenseBadge location={item} />
                  </div>
                }
                description={renderDescription(item)}
              />
            </List.Item>
            <Divider style={{ margin: '3px 0px' }} />
          </React.Fragment>
        )}
      />
    </Modal>
  );
};

export default ManageLicenseModal;
