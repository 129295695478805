import MediaItemGridLayout from '@components/MediaItemGridLayout';
import React from 'react';

interface Props {
  mediaIds: string[];
  width: number | string;
  columnCount?: number;
  hideOverlay?: boolean;
}

const getAmazelyStudioBucket = () => `amazely-studio-${process.env.NODE_ENV}`;

const SocialPostImageGrid: React.FC<Props> = ({ mediaIds, width, columnCount, hideOverlay }) => {
  return (
    <div style={{ width }}>
      <MediaItemGridLayout
        hideOverlay={hideOverlay}
        countFrom={columnCount || 5}
        images={mediaIds.map(
          mediaId => `https://s3.amazonaws.com/${getAmazelyStudioBucket()}/${mediaId}`
        )}
      />
    </div>
  );
};

export default SocialPostImageGrid;
