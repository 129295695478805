import React from 'react';
import { Layout, Menu, Space, Tag } from 'antd';
import {
  useAppDispatch,
  useAppNavigation,
  useAuthState,
  useNavigationItems,
  useSubscriptionDispatcher,
} from '@core/.';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { AppRoutes } from '@utils/.';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME } from '@constants/brand';
import { useParams } from 'react-router';
import { MIDDLE_STYLE } from '@constants/responsive';
import { TbHelp } from 'react-icons/tb';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { SelectBusinessButton } from '..';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { closeDrawer } from '@core/features';

type Props = {};

export const LeftSiderInner = (props: Props) => {
  const {
    GeneralNavigationItems,
    GrowReputationNavigationItems,
    OtherNavigationItems,
    GrowSocialPresenceNavigationItems,
  } = useNavigationItems();
  const { tabName } = useParams<{ tabName: AppRoutes }>();
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const handleNavigation: MenuClickEventHandler = info => {
    dispatch(
      closeDrawer({
        drawerName: 'navigationDrawer',
      })
    );
    navigate(info.key as AppRoutes);
  };

  const handleTabName = (tabName: AppRoutes): AppRoutes => {
    switch (tabName) {
      case 'studio-onboard':
      case 'socialPostCreation':
        return 'studio';
      case 'requestCreation':
        return 'requestEmailSMS';
      default:
        return tabName;
    }
  };

  return (
    <div style={{ textAlign: 'left', overflowY: 'auto' }}>
      <div
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: '0px 10px',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100vh',
        }}>
        <div
          style={{
            ...MIDDLE_STYLE,
            margin: '10px 0px 20px 0px',
            padding: '10px 0px',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <div style={{ ...MIDDLE_STYLE }}>
            <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 40 }} />{' '}
            <span
              style={{
                color: GLOBAL_THEME_COLOR.$text_color,
                fontWeight: 'bold',
                fontSize: 16,
                marginLeft: 10,
              }}>
              {BRAND_NAME.toUpperCase()}
            </span>
          </div>
          <TbHelp
            onClick={() => navigate('help-center')}
            style={{
              fontSize: 20,
              color: GLOBAL_THEME_COLOR.$dark_text_color,
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          style={{
            ...MIDDLE_STYLE,
            marginBottom: 20,
            padding: '0px 20px',
          }}>
          <SelectBusinessButton />
        </div>
        {[
          {
            items: GeneralNavigationItems,
          },
          {
            title: 'Grow Online Presence',
            items: GrowSocialPresenceNavigationItems,
          },
          {
            title: 'Grow Reputation',
            items: GrowReputationNavigationItems,
          },
          {
            title: 'Other',
            items: OtherNavigationItems,
          },
        ].map(navigation => (
          <React.Fragment>
            <div
              style={{
                ...MIDDLE_STYLE,
                justifyContent: 'flex-start',
                width: '100%',
                margin: '10px',
                paddingLeft: 20,
              }}>
              <h3 style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, fontSize: 'smaller' }}>
                {navigation.title?.toUpperCase()}
              </h3>
            </div>
            <Menu
              mode="inline"
              onClick={handleNavigation}
              selectedKeys={[handleTabName(tabName)]}
              items={navigation.items}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const LeftSider = (props: Props) => {
  const { isTablet } = useBreakpoint();
  const {
    GeneralNavigationItems,
    GrowReputationNavigationItems,
    OtherNavigationItems,
    GrowSocialPresenceNavigationItems,
  } = useNavigationItems();
  const { getLicenseDetails } = useSubscriptionDispatcher();
  const { user } = useAuthState();
  const { tabName } = useParams<{ tabName: AppRoutes }>();
  const { navigate } = useAppNavigation();
  const handleNavigation: MenuClickEventHandler = info => {
    navigate(info.key as AppRoutes);
  };

  const handleTabName = (tabName: AppRoutes): AppRoutes => {
    switch (tabName) {
      case 'studio-onboard':
      case 'socialPostCreation':
        return 'studio';
      case 'requestCreation':
        return 'requestEmailSMS';
      default:
        return tabName;
    }
  };

  return (
    <Layout.Sider width={250} style={{ textAlign: 'left', overflowY: 'auto' }} breakpoint="md">
      <div
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'flex-start',
          flexDirection: 'column',
          padding: '0px 10px',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100vh',
        }}>
        <div
          style={{
            ...MIDDLE_STYLE,
            margin: '10px 0px 20px 0px',
            padding: '5px 0px',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <div style={{ ...MIDDLE_STYLE }}>
            <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 40 }} />{' '}
            {!isTablet && (
              <span
                style={{
                  color: GLOBAL_THEME_COLOR.$text_color,
                  fontWeight: 'bold',
                  fontSize: 14,
                  marginLeft: 10,
                }}>
                {BRAND_NAME.toUpperCase()}
              </span>
            )}
          </div>
          {!isTablet && (
            <TbHelp
              onClick={() => navigate('help-center')}
              style={{
                fontSize: 16,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                cursor: 'pointer',
              }}
            />
          )}
        </div>

        <div
          style={{
            ...MIDDLE_STYLE,
            marginBottom: 20,
            padding: '0px 10px',
          }}>
          <SelectBusinessButton />
        </div>
        {user?.selected_location && (
          <Space style={{ margin: '0px 0px 3px 0px' }}>
            {user.selected_location.suspended && <Tag color="red">Suspended</Tag>}
            <Tag
              style={{ borderRadius: '20px' }}
              color={getLicenseDetails(user.selected_location) === 'Pro' ? 'green' : 'blue'}>
              Plan: {getLicenseDetails(user.selected_location)}
            </Tag>
          </Space>
        )}
        {[
          {
            items: GeneralNavigationItems,
          },
          {
            title: 'Grow Online Presence',
            items: GrowSocialPresenceNavigationItems,
          },
          {
            title: 'Grow Reputation',
            items: GrowReputationNavigationItems,
          },
          {
            title: 'Other',
            items: OtherNavigationItems,
          },
        ].map(navigation => (
          <React.Fragment>
            {!isTablet && navigation.title && (
              <div
                style={{
                  ...MIDDLE_STYLE,
                  justifyContent: 'flex-start',
                  width: '100%',
                  margin: '5px',
                  paddingLeft: 10,
                }}>
                <h3 style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, fontSize: 'smaller' }}>
                  {navigation.title?.toUpperCase()}
                </h3>
              </div>
            )}
            <Menu
              mode="inline"
              onClick={handleNavigation}
              selectedKeys={[handleTabName(tabName)]}
              items={navigation.items}
            />
          </React.Fragment>
        ))}
      </div>
    </Layout.Sider>
  );
};

export default LeftSider;
