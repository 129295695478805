import React, { useMemo, useState } from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import {
  FacebookPostSizeMode,
  PartialShowcasedReview,
  SocialPost,
  SocialPostStage,
  SocialPostType,
} from '@core/models';
import { getSocialPostSizeMode } from '@utils/social.util';
import { Button, Popover, Select, Space } from 'antd';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import {
  CloseOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { TwitterPicker } from 'react-color';
import html2canvas from 'html2canvas';
import UploadImageModal from '@components/UploadImageModal';
import {
  closeModal,
  enqueueNotification,
  openDrawer,
  openModal,
  PhotoImageDataItem,
  SnackbarItem,
} from '@core/features';
import { TbPhoto } from 'react-icons/tb';
import { Optional } from '@utils/type.util';
import { ReviewSocialPostCard } from '..';
import ReactDOMServer from 'react-dom/server';
import { socialStudioApiService, socialStudioStreamApiService } from '@services/service-register';
import ReviewAttributeDrawer from '@components/ReviewAttributeDrawer';
import HtmlReviewCardFromTemplate from './HtmlReviewCardFromTemplate';
import SkeletonImage from 'antd/es/skeleton/Image';
import { useSocialPosts, useStreamingTaskStore } from '@core/hooks';
import { makeid } from '@utils/string.util';

type Props = {
  review: Optional<PartialShowcasedReview>;
  onClose?: () => void;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  settingMode?: boolean;
};

const ReviewSocialPostEditor = ({ review, onClose, style, innerStyle, settingMode }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { integration } = useBusinessState();
  const printRef = React.useRef(null);
  const [backgroundImage, setBackgroundImage] = useState<PhotoImageDataItem | undefined>(undefined);
  const [sizeMode, setSizeMode] = useState<FacebookPostSizeMode>(FacebookPostSizeMode.Square);
  const [imageWidth, imageHeight] = useMemo(() => getSocialPostSizeMode(sizeMode), [sizeMode]);
  const [mutating, setMutating] = useState<boolean>(false);
  const { socialPosts } = useSocialPosts();
  const [applyingTheme, setApplyingTheme] = useState<boolean>(false);
  const { addTask } = useStreamingTaskStore();
  const [backgroundColor, setBackgroundColor] = useState<string>(
    GLOBAL_THEME_COLOR.$highlight_color
  );

  const handleCancel = () => {
    onClose && onClose();
  };

  const handleUploadImage = () => {
    dispatch(
      openModal({
        modalName: 'uploadImageModal',
      })
    );
  };

  const convertReactComponentToImageData = async (element: HTMLDivElement) => {
    const canvas = await html2canvas(element, {
      allowTaint: true,
      useCORS: true,
    });

    return canvas.toDataURL('image/png');
  };

  const handleCreateSocialMediaPost = async () => {
    const element = printRef.current;
    if (!element || !review || !user?.selected_location) return;
    setMutating(true);
    try {
      const data = await convertReactComponentToImageData(element);
      const reviewerName =
        review.reviewer.displayName.split(' ')?.[0] || review.reviewer.displayName;
      const topic = `Thank you ${reviewerName} for ${review.rating} star reviews in one sentence`;
      const content = await socialStudioApiService.generateShowcaseReviewContent({
        location: user?.selected_location,
        reviewerName,
      });
      dispatch(
        openModal({
          modalName: 'socialPostCreationModal',
          extraParams: {
            photoData: data,
            topic,
            caption: content,
            type: SocialPostType.ShowcaseReview,
            selectPhotoDisabled: true,
          },
        })
      );
    } catch (error: any) {
      if (integration.facebook_pages.length === 0) {
        dispatch(
          enqueueNotification({
            name: `Failed to create social media post: ${error.message}`,
            type: 'Error',
          })
        );
      } else {
        dispatch(
          enqueueNotification({
            name: `No Facebook page is selected yet. Please try again!`,
            type: 'Error',
          })
        );
      }
    }
    setMutating(false);
  };

  const handleDownloadImage = async () => {
    const element = printRef.current;
    if (!element) return;
    setMutating(true);
    try {
      const data = await convertReactComponentToImageData(element);
      const link = document.createElement('a');
      link.href = data;
      link.download = `${user?.selected_location?.title}__${review?.reviewer.displayName}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to download image',
          type: 'Error',
        })
      );
    }
    setMutating(false);
  };

  const handleCopyHTML = async () => {
    const htmlString = ReactDOMServer.renderToString(
      <ReviewSocialPostCard
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        location={user?.selected_location}
        review={review}
        sizeMode={sizeMode}
      />
    );
    await navigator.clipboard.writeText(htmlString);
    dispatch(
      enqueueNotification({
        name: 'Copied HTML code',
        type: 'Success',
      })
    );
  };

  const handleOnUpload = async (uploadedItems: PhotoImageDataItem[]) => {
    if (!user) return;
    setBackgroundImage(uploadedItems[0]);
  };

  const handleEditTemplate = () => {
    dispatch(
      openDrawer({
        drawerName: 'reviewAttributeDrawer',
      })
    );
  };

  const publishedShowcaseReviewFilter = (post: SocialPost) =>
    post.type === SocialPostType.ShowcaseReview && post.status !== SocialPostStage.Published;

  const hanldeApplyTemplateOnSelectCb = async (_: number[], notSelectedSocialPostIds: number[]) => {
    try {
      setApplyingTheme(true);
      const publishedShowcaseReviewPosts = socialPosts.filter(publishedShowcaseReviewFilter);
      const publishedShowcaseReviewCount = publishedShowcaseReviewPosts.length;
      const notSelectedSocialPostCount = notSelectedSocialPostIds.length;
      const processShowcaseReviewPosts = publishedShowcaseReviewCount - notSelectedSocialPostCount;
      addTask(
        {
          id: makeid(5),
          name: 'Apply Showcase Review Template',
          payload: {
            excludedIds: notSelectedSocialPostIds,
          },
          apiMethod: socialStudioStreamApiService.streamApplyTemplateToShowcaseReviewPosts,
          async onFinishedCallback() {
            dispatch(
              enqueueNotification({
                name: 'Template is applied to posts',
                type: 'Success',
              })
            );
          },
        },
        processShowcaseReviewPosts
      );
      dispatch(
        closeModal({
          modalName: 'socialPostSelectionModal',
        })
      );
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to apply template to posts',
          type: 'Error',
        } as SnackbarItem)
      );
    }
    setApplyingTheme(false);
  };

  const handleApplyTemplate = () => {
    dispatch(
      openModal({
        modalName: 'socialPostSelectionModal',
        extraParams: {
          onSelect: hanldeApplyTemplateOnSelectCb,
          filteredBy: publishedShowcaseReviewFilter,
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
          backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
          backgroundSize: '20px 20px',
          border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          ...style,
        }}>
        <Space
          style={{
            ...MIDDLE_STYLE,
            justifyContent: 'space-between',
            padding: '10px 15px',
            backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            overflow: 'auto',
          }}>
          {settingMode && (
            <Space>
              <Button type="primary" onClick={handleEditTemplate}>
                <EditOutlined /> Edit Template
              </Button>
              <Button loading={applyingTheme} onClick={handleApplyTemplate}>
                Apply for all existing posts
              </Button>
            </Space>
          )}
          {!settingMode && (
            <Space direction="horizontal">
              <Select
                style={{ width: 'fit-content' }}
                value={sizeMode}
                onChange={value => setSizeMode(value)}
                options={Object.keys(FacebookPostSizeMode).map(key => ({
                  label: key,
                  value: key,
                }))}
              />
              {backgroundImage ? (
                <Button onClick={() => setBackgroundImage(undefined)} style={{ ...MIDDLE_STYLE }}>
                  <CloseOutlined /> Remove background image
                </Button>
              ) : (
                <React.Fragment>
                  <Button onClick={handleUploadImage} style={{ ...MIDDLE_STYLE }}>
                    <TbPhoto /> Change background image
                  </Button>
                  <UploadImageModal
                    singleFile
                    actionTitle="Change background"
                    onUpload={handleOnUpload}
                    disabledSquarePhotoSource
                  />
                </React.Fragment>
              )}
            </Space>
          )}
          <Space direction="horizontal">
            {!settingMode && (
              <Button type="primary" loading={mutating} onClick={handleDownloadImage}>
                <DownloadOutlined /> Download
              </Button>
            )}
            {!settingMode && (
              <Button onClick={handleCancel} style={{ color: GLOBAL_THEME_COLOR.$error_color }}>
                <CloseOutlined /> Cancel
              </Button>
            )}
          </Space>
        </Space>
        <div style={{ ...MIDDLE_STYLE, padding: '10px 10px', ...innerStyle, width: '100%' }}>
          <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', width: '100%' }}>
            <div
              style={{
                ...MIDDLE_STYLE,
                width: '100%',
                flexDirection: 'column',
              }}>
              <Popover
                content={
                  settingMode ? (
                    <div>Example Showcased Review</div>
                  ) : (
                    <TwitterPicker
                      triangle="hide"
                      onChange={color => setBackgroundColor(color.hex)}
                    />
                  )
                }>
                {settingMode ? (
                  <React.Fragment>
                    {review ? (
                      <HtmlReviewCardFromTemplate ref={printRef} review={review} />
                    ) : (
                      <SkeletonImage />
                    )}
                  </React.Fragment>
                ) : (
                  <div ref={printRef} style={{ maxWidth: '500px', width: '100%' }}>
                    <ReviewSocialPostCard
                      backgroundColor={backgroundColor}
                      backgroundImage={backgroundImage}
                      imageHeight={imageHeight}
                      imageWidth={imageWidth}
                      location={user?.selected_location}
                      review={review}
                      sizeMode={sizeMode}
                    />
                  </div>
                )}
              </Popover>
            </div>
            {!settingMode && (
              <Space style={{ marginTop: 20, marginBottom: 20 }}>
                <Button
                  type="primary"
                  size="large"
                  loading={mutating}
                  style={{ ...MIDDLE_STYLE }}
                  onClick={handleCreateSocialMediaPost}>
                  <SendOutlined rotate={-30} /> Create social media post
                </Button>
                <Button size="large" style={{ ...MIDDLE_STYLE }} onClick={handleCopyHTML}>
                  <CopyOutlined /> Copy HTML
                </Button>
              </Space>
            )}
          </div>
        </div>
        {review && <ReviewAttributeDrawer mockReview={review} />}
      </div>
    </React.Fragment>
  );
};

export default ReviewSocialPostEditor;
