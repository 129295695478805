import React from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import {
  useAppDispatch,
  useAuthState,
  useBusinessState,
  useModalState,
} from '@core/configureStore';
import { openModal } from '@core/features';
import { Button } from 'antd';
import { TbBriefcase } from 'react-icons/tb';
import { shortenString } from '@utils/string.util';

type Props = {};

const SelectBusinessButton = (props: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { locationModal } = useModalState();
  const { locations } = useBusinessState();

  const openBusinessModal = () => {
    if (locations.length === 0) {
      dispatch(
        openModal({
          modalName: 'welcomeLocationModal',
        })
      );
    } else {
      dispatch(
        openModal({
          modalName: 'locationModal',
        })
      );
    }
  };

  return (
    <Button style={{ fontSize: 11 }} type="primary" onClick={openBusinessModal}>
      <TbBriefcase />{' '}
      <React.Fragment>
        {user?.selected_location
          ? `Business: ${shortenString(user.selected_location.title, 15)}`
          : 'Select Business'}{' '}
        &nbsp;
        {locationModal ? <DownOutlined /> : <RightOutlined />}
      </React.Fragment>
    </Button>
  );
};

export default SelectBusinessButton;
