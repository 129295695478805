import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';
import { updateSelectedLocation } from '@core/features';

export const fetchSelectedLocation = createAsyncThunk(
  'business/fetchSelectedLocation',
  async (_, thunkApi) => {
    try {
      const result = await heroApiService.getSelectedLocation();
      thunkApi.dispatch(updateSelectedLocation(result));
      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchSelectedLocation);

export const fetchSelectedLocationReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
