import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './fonts/Airbnb-Cereal-App-Bold.ttf';
import './fonts/Airbnb-Cereal-App-Book.ttf';
import './fonts/Airbnb-Cereal-App-Black.ttf';
import './fonts/Airbnb-Cereal-App-ExtraBold.ttf';
import './fonts/Airbnb-Cereal-App-Light.ttf';
import './fonts/Airbnb-Cereal-App-Medium.ttf';
import './fonts/Airbnb-Plus-Script-App-Regular.ttf';
import './fonts/Airglyphs.otf';
import './fonts/AirMojix.ttf';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
