import React from 'react';
import { Col, Row } from 'antd';
import './grid-layout.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  images: string[];
  hideOverlay?: boolean;
  renderOverlay?: any;
  overlayBackgroundColor?: string;
  onClickEach?: any | undefined;
  countFrom?: number;
}

const MediaItemGridLayout: React.FC<Props> = ({
  countFrom = 5,
  overlayBackgroundColor = '#222222',
  hideOverlay = false,
  renderOverlay = () => {},
  onClickEach = null,
  images = [],
}) => {
  const renderOne = () => {
    const overlay =
      images.length > countFrom && countFrom === 1
        ? renderCountOverlay(true)
        : renderOverloadedOverlay();

    return (
      <Row>
        <Col
          xs={24}
          md={24}
          onClick={onClickEach}
          className={`border background`}
          style={{ backgroundSize: 'cover' }}>
          <img className={'height-one'} alt={images[0]} src={images[0]} />
          {overlay}
        </Col>
      </Row>
    );
  };

  const renderTwo = () => {
    const overlay =
      images.length > countFrom && [2, 3].includes(+countFrom)
        ? renderCountOverlay(true)
        : renderOverloadedOverlay();
    const conditionalRender =
      [3, 4].includes(images.length) || (images.length > +countFrom && [3, 4].includes(+countFrom));

    return (
      <Row style={{ width: '100%' }}>
        <Col
          span={12}
          className="border background"
          onClick={onClickEach}
          style={{
            backgroundSize: 'cover',
          }}>
          <LazyLoadImage
            className={'height-one'}
            loading="lazy"
            effect="blur"
            alt={'height-one'}
            src={conditionalRender ? images[1] : images[0]}
          />
          {renderOverloadedOverlay()}
        </Col>
        <Col
          span={12}
          className="border background"
          onClick={onClickEach}
          style={{
            backgroundSize: 'cover',
          }}>
          <img
            className={'height-one'}
            alt={'height-one'}
            src={conditionalRender ? images[2] : images[1]}
          />
          {overlay}
        </Col>
      </Row>
    );
  };

  const renderThree = () => {
    const overlay =
      !countFrom || countFrom > 5 || (images.length > countFrom && [4, 5].includes(+countFrom))
        ? renderCountOverlay(true)
        : renderOverloadedOverlay();
    const conditionalRender =
      images.length === 4 || (images.length > +countFrom && +countFrom === 4);

    return (
      <Row>
        <Col
          xs={12}
          md={8}
          className="border  background"
          onClick={onClickEach}
          style={{
            backgroundSize: 'cover',
          }}>
          <img
            className={'height-one'}
            alt={'height-one'}
            src={conditionalRender ? images[1] : images[2]}
          />
          {renderOverloadedOverlay(conditionalRender ? 1 : 2)}
        </Col>
        <Col
          xs={12}
          md={8}
          className="border background"
          onClick={onClickEach}
          style={{
            backgroundSize: 'cover',
          }}>
          <img
            className={'height-one'}
            alt={'height-one'}
            src={conditionalRender ? images[2] : images[3]}
          />
          {renderOverloadedOverlay(conditionalRender ? 2 : 3)}
        </Col>
        <Col
          xs={12}
          md={8}
          className="border  background"
          style={{
            backgroundSize: 'cover',
          }}>
          <img
            className={'height-one'}
            alt={'height-one'}
            src={conditionalRender ? images[3] : images[4]}
          />
          {overlay}
        </Col>
      </Row>
    );
  };

  const renderOverloadedOverlay = (id: number = 0) => {
    if (hideOverlay) {
      return false;
    }

    return (
      <React.Fragment>
        <div
          key={`cover-${id}`}
          className="cover slide"
          style={{ backgroundColor: overlayBackgroundColor }}></div>
        <div
          key={`cover-text-${id}`}
          className="cover-text slide animate-text"
          style={{ fontSize: '100%' }}>
          {renderOverlay()}
        </div>
      </React.Fragment>
    );
  };

  const renderCountOverlay = (more: any) => {
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);
    return [
      more && <div key="count" className="cover"></div>,
      more && (
        <div key="count-sub" className="cover-text" style={{ fontSize: '200%' }}>
          <p>+{extra}</p>
        </div>
      ),
    ];
  };

  return (
    <div className="grid-container">
      {[1, 3, 4].includes(images.length) && renderOne()}
      {images.length >= 2 && images.length !== 4 && renderTwo()}
      {images.length >= 4 && renderThree()}
    </div>
  );
};

export default MediaItemGridLayout;
