import { BackgroundEvent, BlogGenerateBackgroundEvent } from '@core/models';
import axios from 'axios';

export default class BackgroundEventApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}/backgroundEvents`;

  async removeBackgroundEventById(backgroundEventId: string) {
    try {
      const response = await axios.delete(`${this.url}/blogs/${backgroundEventId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getBlogGenerateEvents(): Promise<BackgroundEvent<BlogGenerateBackgroundEvent>[]> {
    try {
      const response = await axios.get(`${this.url}/blogs`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
