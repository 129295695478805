import { CreditCardOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useSubscriptionDispatcher } from '@core/hooks';
import { MIDDLE_STYLE } from '@constants/responsive';

const SubscriptionActivateButton = () => {
  const { handleActivateNow } = useSubscriptionDispatcher();
  return (
    <Button
      onClick={handleActivateNow}
      type={'primary'}
      size={'middle'}
      className="success-btn"
      style={{ padding: '0px 10px', height: 30, ...MIDDLE_STYLE }}>
      Activate Free Trial <CreditCardOutlined style={{ margin: 0, marginLeft: 10 }} />
    </Button>
  );
};

export default SubscriptionActivateButton;
