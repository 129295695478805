export enum StudioTabKey {
  ImageGallery = 'image-gallery',
  UpcomingPosts = 'upcoming-posts',
  PhotoFromSquare = 'photo-from-square',
  Setting = 'setting',
}

export type SquareProductItemCatalogObject = {
  name: string;
  description: string;
  imageUrls: string[];
};

export type SquarePhotoImageData = {
  id: string;
  url: string;
  description: string;
};
