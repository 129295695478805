/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Skeleton } from 'antd';
import { useParams } from 'react-router';
import ReviewSubmissionForm from '@components/ReviewSubmissionForm';
import { publicRouteService } from '@services/service-register';
import { useAppDispatch } from '@core/configureStore';
import { PlaceData } from '@core/models';
import { enqueueNotification } from '@core/features';
import { MIDDLE_STYLE } from '@constants/responsive';
import { UnlockFilled } from '@ant-design/icons';
import ReviewScreenLayout from '@components/ReviewScreenLayout';

type Props = {
  activationRequired: boolean;
  shieldFeature: boolean;
};

const QrCodeGeneratorReviewScreen = ({ activationRequired, shieldFeature }: Props) => {
  const dispatch = useAppDispatch();
  const [businessPlaceData, setBusinessPlaceData] = useState<PlaceData | undefined>(undefined);
  const { businessPlaceId } = useParams<{
    businessPlaceId: string;
  }>();

  useEffect(() => {
    const init = async () => {
      try {
        const placeData = await publicRouteService.getPlaceDetail(businessPlaceId);
        setBusinessPlaceData(placeData.result);
      } catch (error) {
        dispatch(
          enqueueNotification({
            name: 'Failed to get business data',
            type: 'Error',
          })
        );
      }
    };
    init();
  }, []);

  return (
    <html>
      <head>
        <title>{businessPlaceData?.name || 'Business'}</title>
      </head>
      <ReviewScreenLayout>
        {businessPlaceData ? (
          <React.Fragment>
            <ReviewSubmissionForm
              shieldFeature={shieldFeature}
              negativeReviewButtonText="Submit Feedback"
              locationName={businessPlaceData.name}
              onPostReviewSubmitted={() => {
                window.location.replace(
                  `https://search.google.com/local/writereview?placeid=${businessPlaceId}`
                );
              }}
              placeId={businessPlaceId}
            />
            {activationRequired && (
              <Alert
                style={{ marginTop: 10 }}
                type="info"
                message={
                  <span>
                    When customers select 4-5 stars, we'll send them to your public Google review
                    page. <br />
                    <br />
                    For 1-3 stars, we'll guide them to a private feedback form to protect your
                    reputation. Try it out!
                    <br />
                    <br />
                    Please activate your QR code so we know whom to send private feedback to.
                    <Button
                      onClick={() =>
                        window.open(
                          'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https%3A%2F%2Fapi.amazely.co%2Fauth%2Famazely%2Fgoogle%2Fcallback&client_id=784924628497-bceeuihsjmvurtrt0ac4oab2meevh4n4.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&include_granted_scopes=false&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=%2F'
                        )
                      }
                      type="primary"
                      style={{ height: 30, ...MIDDLE_STYLE, marginTop: 10 }}>
                      <UnlockFilled style={{ color: 'yellow' }} /> Activate now for free
                    </Button>
                  </span>
                }
              />
            )}
          </React.Fragment>
        ) : (
          <Skeleton />
        )}
      </ReviewScreenLayout>
    </html>
  );
};

export default QrCodeGeneratorReviewScreen;
