import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import {
  fetchReviewRequestsReducer,
  askForReviewReducer,
  selectRequestReducer,
  resendRequestReducer,
  publishDraftReducer,
  requestAgainReducer,
} from './thunks';
import { deleteRequestReducer } from './thunks/deleteRequest.thunk';
import { InProcessRequest, ReviewRequestReducerProps } from './type';

const reviewRequestSlice = createSlice<
  ReviewRequestReducerProps,
  SliceCaseReducers<ReviewRequestReducerProps>,
  string
>({
  name: SliceName.ReviewRequest,
  initialState: {
    isLoading: true,
    isDetailLoading: false,
    isMutating: false,
    isSelected: false,
    isOpening: false,
    requests: [],
    inProcessRequests: [],
    request: null,
    error: '',
  },
  reducers: {
    addInProcessRequest: (state, action: PayloadAction<InProcessRequest>) => {
      state.inProcessRequests.push(action.payload);
      return state;
    },
    setInProcessRequests: (state, action: PayloadAction<InProcessRequest[]>) => {
      state.inProcessRequests = action.payload;
      return state;
    },
    deselectRequest: (state, _) => {
      return {
        ...state,
        isSelected: false,
        isDetailLoading: false,
        isLoading: false,
        request: null,
      };
    },
  },
  extraReducers: {
    ...fetchReviewRequestsReducer,
    ...askForReviewReducer,
    ...selectRequestReducer,
    ...deleteRequestReducer,
    ...resendRequestReducer,
    ...publishDraftReducer,
    ...requestAgainReducer,
  },
});

export const { deselectRequest, addInProcessRequest, setInProcessRequests } =
  reviewRequestSlice.actions;
export const reviewRequestReducer = reviewRequestSlice.reducer;
