import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
  SendOutlined,
} from '@ant-design/icons';
import EmbeddedBlogPostVerticalCard from '@components/EmbeddedBlogComponents/EmbeddedBlogPostVerticalCard';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useBreakpoint } from '@core/hooks';
import { BlogPostModel, BlogPostStage, EmbeddedBlogSetting, LocalLocation } from '@core/models';
import { Button, Calendar, Card, Col, Divider, Modal, Row } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import * as dayjs from 'dayjs';
import { DateTimeScheduleEditor, DescriptionItem, SocialAccountButtonListContainer } from '..';

type Props = {
  location?: LocalLocation;
  blogPostData: {
    title: string;
    subtitle: string;
    thumbnail: string | undefined;
    content: string;
  };
  schedulable: boolean;
  embeddedSetting: EmbeddedBlogSetting;
  existingBlogPost?: BlogPostModel;
  selectedSocialChannels: Record<string, boolean>;
  open: boolean;
  mutated?: boolean;
  setSelectedSocialChannels: (selectedAccounts: Record<string, boolean>) => void;
  setOpen: (open: boolean) => void;
  onUpdated?: () => void;
  onCreated: (status: BlogPostStage) => void;
};

const BlogPostPublishModal = ({
  location,
  existingBlogPost,
  embeddedSetting,
  blogPostData,
  onUpdated,
  mutated,
  schedulable,
  open,
  setOpen,
  onCreated,
  selectedSocialChannels,
  setSelectedSocialChannels,
}: Props) => {
  const today = moment();
  const { isTablet } = useBreakpoint();
  const [date, setDate] = useState<number>(today.unix());
  const [scheduleLaterEnabled, setScheduleLaterEnabled] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [time, setTime] = useState<{
    hour: number;
    minute: number;
  }>({
    hour: today.hour(),
    minute: today.minute(),
  });
  const onCreatedDisabled = useMemo(
    () =>
      !blogPostData.title ||
      !blogPostData.subtitle ||
      !blogPostData.content ||
      !blogPostData.thumbnail,
    [blogPostData]
  );

  const handleEnableScheduleLater = () => {
    setScheduleLaterEnabled(!scheduleLaterEnabled);
  };

  const handleSetScheduleTime = (scheduledTime: dayjs.Dayjs | null, _: string | string[]) => {
    if (!scheduledTime) return;
    if (onUpdated) onUpdated();
    setTime({
      hour: scheduledTime.hour(),
      minute: scheduledTime.minute(),
    });
  };

  const handleSchedulePost = () => {
    setCalendarOpen(true);
  };

  const onCalendarSelected = (day: dayjs.Dayjs) => {
    setDate(day.unix());
  };
  return (
    <Modal
      title={'Publish a blog post'}
      open={open}
      footer={[]}
      width={isTablet ? '100%' : 1000}
      style={{ maxWidth: 1000 }}
      onCancel={() => setOpen(false)}>
      <Divider />
      <Row style={{ width: '100%' }}>
        <Col span={isTablet ? 24 : 12}>
          <div
            style={{
              ...MIDDLE_STYLE,
              flexDirection: 'column',
              height: '100%',
              backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
              backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
              backgroundSize: '20px 20px',
              border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              padding: '25px 0px',
            }}>
            <EmbeddedBlogPostVerticalCard
              clickedDisabled
              blogPost={blogPostData as any}
              setting={embeddedSetting}
            />
          </div>
        </Col>
        <Col
          span={isTablet ? 24 : 12}
          style={{
            padding: '0px 25px',
          }}>
          {schedulable && !mutated && (
            <DateTimeScheduleEditor
              date={date}
              time={time}
              scheduleLaterEnabled={scheduleLaterEnabled}
              handleEnableScheduleLater={handleEnableScheduleLater}
              handleSetScheduleTime={handleSetScheduleTime}
              handleSchedulePost={handleSchedulePost}
            />
          )}
          {calendarOpen && (
            <Card
              className={'no-padding'}
              title={
                <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
                  <h3>Calendar</h3>
                  <Button onClick={() => setCalendarOpen(false)}>
                    <ArrowLeftOutlined /> Back
                  </Button>
                </div>
              }>
              <Calendar
                disabledDate={current => current.isBefore(dayjs.default().subtract(1, 'day'))}
                value={dayjs.unix(date)}
                fullscreen={false}
                onSelect={onCalendarSelected}
              />
            </Card>
          )}
          {!scheduleLaterEnabled &&
            (!existingBlogPost || existingBlogPost.status !== BlogPostStage.Published) &&
            location?.blog_assistant_profile.autoShareToSocial && (
              <DescriptionItem
                title="Share to social channels"
                style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}
                content={
                  <SocialAccountButtonListContainer
                    selectedAccounts={selectedSocialChannels}
                    setSelectedAccounts={setSelectedSocialChannels}
                    disabledPlatforms={['instagram']}
                  />
                }
              />
            )}
          <Button
            disabled={onCreatedDisabled}
            onClick={() =>
              onCreated(scheduleLaterEnabled ? BlogPostStage.Scheduled : BlogPostStage.Published)
            }
            type={'primary'}
            loading={mutated}
            style={{ width: '100%', marginTop: 15 }}
            className={'success-btn'}>
            {scheduleLaterEnabled ? (
              <span>
                <ClockCircleOutlined /> Schedule Post
              </span>
            ) : (
              <span>
                <SendOutlined rotate={-30} /> Publish Now
              </span>
            )}
          </Button>
          {mutated && (
            <p
              style={{
                marginTop: 13,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                fontStyle: 'italic',
              }}>
              <LoadingOutlined style={{ marginRight: 10 }} /> Please keep this window open as
              Amazely is crafting your blog site...
            </p>
          )}
          {onCreatedDisabled && (
            <div style={{ color: GLOBAL_THEME_COLOR.$error_color, marginTop: 10 }}>
              Missing required fields
            </div>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default BlogPostPublishModal;
