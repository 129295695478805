import React from 'react';
import { CalendarItem, renderStatusColor, renderStatusStr } from '@utils/social-studio.util';
import { shortenString } from '@utils/string.util';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { SocialPost } from '@core/models';
import { Space, Tag } from 'antd';
import { SocialPostImageGrid } from '..';
import { openModal } from '@core/features';
import { useAppDispatch } from '@core/configureStore';

type Props = {
  item: CalendarItem;
};

const CalendarItemCard = ({ item }: Props) => {
  const dispatch = useAppDispatch();
  const socialPost: SocialPost = item.extra;
  const handleItemClicked = async (socialPost: SocialPost) => {
    dispatch(
      openModal({
        modalName: 'socialPostModal',
        extraParams: {
          socialPost,
        },
      })
    );
  };
  return (
    <div
      onClick={() => handleItemClicked(socialPost)}
      style={{
        maxWidth: 450,
        border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        padding: '10px 10px',
        margin: '5px 0px',
        borderRadius: 10,
        cursor: 'pointer',
      }}>
      <Space style={{ ...MIDDLE_STYLE }} size="large">
        <SocialPostImageGrid mediaIds={socialPost?.mediaIds || []} width={150} />
        <div>
          <Tag color={renderStatusColor(socialPost.status)}>
            {renderStatusStr(socialPost?.status)}
          </Tag>
          <p style={{ marginTop: 10, fontSize: 12 }}>{shortenString(item.name, 100)}</p>
        </div>
      </Space>
    </div>
  );
};

export default CalendarItemCard;
