import { InstagramProfile } from '@core/models';
import axios from 'axios';

export default class InstagramApiService {
  private url = `${process.env.REACT_APP_GOOGLE_OAUTH_ENDPOINT}`;

  public async getLocationInstagramSelectedProfiles(): Promise<InstagramProfile[]> {
    try {
      const response = await axios.get(`${this.url}/location/instagram/profiles`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async getInstagramProfilesByLocationId(locationId: string): Promise<InstagramProfile[]> {
    try {
      const response = await axios.get(`${this.url}/location/${locationId}/instagram/profiles`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async selectInstagramProfile(profile: InstagramProfile): Promise<any> {
    try {
      const response = await axios.post(`${this.url}/location/instagram/profiles`, profile, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  public async unselectInstagramProfile(profileId: string): Promise<any> {
    try {
      const response = await axios.delete(`${this.url}/location/instagram/profiles/${profileId}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
}
