import {
  DefendResponseType,
  GoogleLocalLocation,
  InstagramProfile,
  LocalLocation,
} from '@core/models';
import { Location } from '../../models/location';
import { FacebookPage } from '@core/models';

export enum IntegrationType {
  Activation,
  Manual,
}
export const INTEGRATION_APP_REGISTRY = {
  square: IntegrationType.Activation,
  quickbooks: IntegrationType.Activation,
  jobber: IntegrationType.Activation,
  xero: IntegrationType.Activation,
  openDental: IntegrationType.Manual,
};

export type AppIntegrationKey = keyof typeof INTEGRATION_APP_REGISTRY;

export type LocationReducerProps = {
  isLoading: boolean;
  isSelecting: boolean;
  isRequestTemplateUpdating: boolean;
  isLocalLocationLoading: boolean;
  isAutopilotActivating: boolean;
  isAutoRequestActivating: boolean;
  defendResponseType: DefendResponseType;
  locations: Location[];
  googleLocations: GoogleLocalLocation[];
  localLocations: LocalLocation[];
  error?: string;
  integration: Record<AppIntegrationKey, string | undefined> & {
    facebook_pages: FacebookPage[];
    instagram_profiles: InstagramProfile[];
  };
};
