import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { BlogReducerProps } from './type';
import { blogThunkReducers } from '@core/features/blog/thunks';
import { BlogPostModel } from '@core/models';

const blogSlice = createSlice<BlogReducerProps, SliceCaseReducers<BlogReducerProps>, string>({
  name: SliceName.Blog,
  initialState: {
    blogPosts: [],
    isLoading: true,
  },
  reducers: {
    appendBlogPosts: (
      state,
      action: PayloadAction<{
        blogPosts: BlogPostModel[];
      }>
    ) => {
      state.blogPosts = state.blogPosts.concat(action.payload.blogPosts);
      return state;
    },
  },
  extraReducers: blogThunkReducers,
});

export const { appendBlogPosts } = blogSlice.actions;
export const blogReducer = blogSlice.reducer;
