/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { MAX_ONBOARDING_STAGE } from '@constants/pitch-business';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import { FacebookPage, OnboardingStage } from '@core/models';
import { Button, Card, Checkbox, Col, Divider, Progress, Row } from 'antd';
import { useAppNavigation, useUserOnboarding } from '@core/hooks';
import ConnectFacebookButton from '@components/ConnectFacebookButton';
import ConnectSocialAccountsLocation from '@components/ConnectFacebookLocation';
import EnableAutomaticResponseContainer from '@components/EnableAutomaticResponseContainer';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import OnboardingMarkCompeteButton from '@components/OnboardingMarkCompleteButton';
import { fetchFacebookSelectedPages } from '@core/features/business/thunks';
import { fetchInstagramSelectedProfiles } from '@core/features/business/thunks/fetchInstagramSelectedProfiles.thunk';
import { AppRoutes } from '@utils/route.util';

type Props = {};

const OnboardingContent = ({ tab }: { tab: OnboardingStage }) => {
  const { stageChecked } = useUserOnboarding();
  const { navigate } = useAppNavigation();

  const handleCollectCustomerReviews = () => {
    navigate('generate-reviews');
  };

  return (
    <React.Fragment>
      {tab === OnboardingStage.CollectReviews && (
        <React.Fragment>
          <div style={{ ...MIDDLE_STYLE }}>
            <img src="jumbotron/generate-review.png" style={{ height: 350 }} />
          </div>
          <h3 style={{ marginTop: 20, marginBottom: 10 }}>Automate your review collection.</h3>
          <p style={{ fontSize: 'larger' }}>
            Asking for reviews doesn't have to be manual & tedious. Amazely puts your review
            collection process on autopilot mode.
          </p>
          <br />
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <OnboardingMarkCompeteButton stage={OnboardingStage.CollectReviews} />
            <Button type="primary" onClick={handleCollectCustomerReviews}>
              Generate Reviews
              <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
            </Button>
          </div>
        </React.Fragment>
      )}
      {tab === OnboardingStage.SocialStudio && (
        <React.Fragment>
          <img src="jumbotron/social-post-generate.webp" />
          <h3 style={{ marginTop: 20, marginBottom: 10 }}>
            Take your social presence to the next level with Social Studio!
          </h3>
          <p style={{ fontSize: 'larger' }}>
            Amazely social studio craft captivating social posts automatically from your business
            photos
          </p>
          <br />
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <OnboardingMarkCompeteButton stage={OnboardingStage.SocialStudio} />
            {stageChecked(OnboardingStage.SocialStudio) ? (
              <Button onClick={() => navigate('studio')}>
                Visit social studio <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            ) : (
              <Button type="primary" onClick={() => navigate('studio-onboard')}>
                Set up social studio <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
      {tab === OnboardingStage.SocialAccountConnect && (
        <React.Fragment>
          <p style={{ fontSize: 'larger', marginBottom: 20 }}>
            To enable automatic publishing, Amazely requires you to connect your Facebook page.
          </p>
          <h3>1. Connect your Facebook account</h3>
          <p style={{ fontSize: 'larger', marginTop: 20 }}>
            First, connect your personal account which is a manager of a business page
          </p>
          <div style={{ ...MIDDLE_STYLE, margin: 30 }}>
            <ConnectFacebookButton />
          </div>
          <Divider />
          <h3>2. Select business page</h3>
          <div style={{ ...MIDDLE_STYLE, margin: 30 }}>
            <ConnectSocialAccountsLocation />
          </div>
          <OnboardingMarkCompeteButton stage={OnboardingStage.SocialAccountConnect} />
        </React.Fragment>
      )}
      {tab === OnboardingStage.AutomaticResponse && (
        <React.Fragment>
          <EnableAutomaticResponseContainer />
          <OnboardingMarkCompeteButton stage={OnboardingStage.AutomaticResponse} />
        </React.Fragment>
      )}
      {tab === OnboardingStage.ShowcaseReviews && (
        <React.Fragment>
          <div style={{ ...MIDDLE_STYLE }}>
            <img src="jumbotron/review-widget.png" height={'300'} />
          </div>
          <br />
          <h3 style={{ marginTop: 20, marginBottom: 10 }}>
            Boost sales instantly by showcasing the best reviews on your website.
          </h3>
          <p style={{ fontSize: 'larger' }}>
            Amazely puts all your previously hard-earned Google reviews to work by elegantly
            showcasing the very best 4+ stars reviews on your website.
          </p>
          <br />
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <OnboardingMarkCompeteButton stage={OnboardingStage.ShowcaseReviews} />
            <Button type="primary" onClick={() => navigate<AppRoutes>('widget')}>
              Showcase reviews <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
            </Button>
          </div>
        </React.Fragment>
      )}
      {tab === OnboardingStage.BusinessBlogSite && (
        <React.Fragment>
          <div style={{ ...MIDDLE_STYLE }}>
            <img
              src="jumbotron/blog-post-onboarding.png"
              height={'200'}
              style={{ objectFit: 'cover', boxShadow: STRIPE_BOX_SHADOW }}
            />
          </div>
          <br />
          <h3 style={{ marginTop: 20, marginBottom: 10 }}>
            Deliver SEO optimized contents to increase your search ranking
          </h3>
          <p style={{ fontSize: 'larger' }}>
            Amazely automatically crafts blog posts to drive up your search ranking and attract more
            organic leads for your business.
          </p>
          <br />
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <OnboardingMarkCompeteButton stage={OnboardingStage.BusinessBlogSite} />
            {stageChecked(OnboardingStage.BusinessBlogSite) ? (
              <Button onClick={() => navigate('blogs/')}>
                Visit Blog studio
                <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            ) : (
              <Button type="primary" onClick={() => navigate<AppRoutes>('blogs')}>
                Setup now <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
      {tab === OnboardingStage.ShareReviewToSocial && (
        <React.Fragment>
          <div style={{ ...MIDDLE_STYLE }}>
            <img src="jumbotron/showcase-social-reviews.webp" height={'300'} />
          </div>
          <br />
          <h3 style={{ marginTop: 20, marginBottom: 10 }}>
            Boost your brand with top-notch reviews shared across your social media channels!
          </h3>
          <p style={{ fontSize: 'larger' }}>
            Elevate your business reputation with Amazely's review-sharing prowess on social media!
          </p>
          <br />
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <OnboardingMarkCompeteButton stage={OnboardingStage.ShareReviewToSocial} />
            {stageChecked(OnboardingStage.ShareReviewToSocial) ? (
              <Button onClick={() => navigate('studio')}>
                Visit social studio <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            ) : (
              <Button type="primary" onClick={() => navigate('showcase-reviews-onboard')}>
                Setup now <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
      {tab === OnboardingStage.SmartQrCode && (
        <React.Fragment>
          <div style={{ ...MIDDLE_STYLE }}>
            <img
              src="jumbotron/smart-qr-code.png"
              height={'300'}
              style={{ objectFit: 'contain' }}
            />
          </div>
          <h3 style={{ marginTop: 20, marginBottom: 10 }}>Google Smart QR Code</h3>
          <p style={{ fontSize: 'larger' }}>
            Collect Google reviews with confidence using Amazely Smart QR Code. Our QR Code
            automatically filters out negative reviews, helping you to gather valuable and
            constructive feedback from your customers without impacting your public Google rating.
          </p>
          <br />
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <OnboardingMarkCompeteButton stage={OnboardingStage.SmartQrCode} />
            {stageChecked(OnboardingStage.SmartQrCode) ? (
              <Button onClick={() => navigate<AppRoutes>('requestQrScanner')}>
                View QR Code <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            ) : (
              <Button onClick={() => navigate<AppRoutes>('requestQrScanner')}>
                Print QR Code <ArrowRightOutlined style={{ margin: 0, marginLeft: 10 }} />
              </Button>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OnboardingScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const { stageChecked } = useUserOnboarding();
  const { isTablet } = useBreakpoint();
  const { user } = useAuthState();
  const { navigate } = useAppNavigation();
  const [tab, setTab] = useState(OnboardingStage.ShareReviewToSocial);
  const { integration } = useBusinessState();
  const selectedPages = useMemo<FacebookPage[]>(() => integration.facebook_pages, [integration]);

  useEffect(() => {
    const init = async () => {
      if (user?.selected_location?.onboarding_stages?.length === MAX_ONBOARDING_STAGE) {
        navigate('home');
      }
    };
    init();
  }, [selectedPages, user]);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchFacebookSelectedPages());
      await dispatch(fetchInstagramSelectedProfiles());
    };
    init();
  }, []);

  return (
    <div
      style={{
        ...(!isTablet ? MIDDLE_STYLE : { marginBottom: 500 }),
        flexDirection: 'column',
        height: '100%',
        backgroundColor: GLOBAL_THEME_COLOR.$background_color,
        backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
        backgroundSize: '20px 20px',
        overflow: isTablet ? 'hidden' : 'auto',
      }}>
      <Card
        style={{
          maxWidth: 1000,
          width: '100%',
          borderRadius: 10,
          height: isTablet ? '100%' : 'fit-content',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          overflow: 'auto',
        }}>
        <Row>
          <Col
            span={isTablet ? 24 : 10}
            style={{
              borderRight: isTablet
                ? 'unset'
                : `1px solid ${GLOBAL_THEME_COLOR.$dark_secondary_color}`,
              paddingRight: isTablet ? 0 : 50,
            }}>
            <h1 className="airbnb-font" style={{ fontSize: 25 }}>
              Getting Started
            </h1>
            <p style={{ fontSize: 'larger', color: GLOBAL_THEME_COLOR.$dark_text_color }}>
              Follow our quick checklist to get started with Amazely
            </p>
            <div style={{ display: 'flex', marginTop: 20 }}>
              <span style={{ width: 70 }}>
                {user?.selected_location?.onboarding_stages?.length || 0} of {MAX_ONBOARDING_STAGE}
              </span>
              <Progress
                percent={
                  ((user?.selected_location?.onboarding_stages?.length || 0) /
                    MAX_ONBOARDING_STAGE) *
                  100
                }
                showInfo={false}
              />
            </div>
            <br />
            {[
              {
                stage: OnboardingStage.AutomaticResponse,
                title: 'Answer Reviews',
              },
              {
                stage: OnboardingStage.ShareReviewToSocial,
                title: 'Showcase Reviews',
              },
              { stage: OnboardingStage.SocialStudio, title: 'Grow Social Presence' },
              {
                stage: OnboardingStage.BusinessBlogSite,
                title: 'Business Blog Site',
              },
              {
                stage: OnboardingStage.SocialAccountConnect,
                title: 'Connect Social Accounts',
              },
              {
                stage: OnboardingStage.SmartQrCode,
                title: 'Smart QR Code',
              },
              {
                stage: OnboardingStage.CollectReviews,
                title: 'Grow Google Reviews',
              },
              {
                stage: OnboardingStage.ShowcaseReviews,
                title: 'Enable Review Widget',
              },
              // {
              //   stage: OnboardingStage.AutomaticResponse,
              //   title: 'Reputation Defender',
              // },
            ].map(tabItem => (
              <React.Fragment>
                <div
                  onClick={() => setTab(tabItem.stage)}
                  style={{
                    ...MIDDLE_STYLE,
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    borderTop: `1px solid ${GLOBAL_THEME_COLOR.$dark_secondary_color}`,
                    padding: '15px 0px',
                    fontWeight: tabItem.stage === tab ? 'bold' : 'normal',
                    color:
                      tabItem.stage === tab
                        ? GLOBAL_THEME_COLOR.$highlight_color
                        : GLOBAL_THEME_COLOR.$text_color,
                  }}>
                  <div style={{ fontSize: 15 }}>{tabItem.title}</div>
                  <Checkbox checked={stageChecked(tabItem.stage)} />
                </div>
                {tabItem.stage === tab && isTablet && (
                  <div style={{ margin: '10px 0px 20px 0px' }}>
                    <OnboardingContent tab={tab} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </Col>
          {!isTablet && (
            <Col
              span={isTablet ? 24 : 14}
              style={{
                padding: isTablet ? '20px 0px 0px 0px' : '0px 30px',
                borderTop: isTablet
                  ? `1px solid ${GLOBAL_THEME_COLOR.$dark_secondary_color}`
                  : 'unset',
              }}>
              <OnboardingContent tab={tab} />
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default OnboardingScreen;
