/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import CardSectionHeader from '@components/CardSectionHeader';
import { useReviewState } from '@core/configureStore';
import { StarRating } from '@core/models';
import { Card, Rate } from 'antd';
import React, { useMemo } from 'react';

type Props = {};

const AverageRatingWidget = (props: Props) => {
  const { reviewResponse, isLoading: isLoadingReview } = useReviewState();
  const reviewStarRatings: number[] = useMemo(
    () =>
      (reviewResponse.reviews || []).map(
        review => StarRating[review?.starRating.valueOf()] as any
      ) || [],
    [reviewResponse]
  );

  const averageRating = useMemo(() => {
    return reviewStarRatings?.length > 0
      ? reviewStarRatings.reduce((rA: any, rB: any) => rA + rB) / reviewStarRatings.length
      : 0;
  }, [reviewStarRatings]);
  return (
    <Card className="no-padding" style={{ padding: '0px', borderRadius: 10 }}>
      <div style={{ padding: '15px 15px' }}>
        <CardSectionHeader title="Average Rating" />
        {isLoadingReview ? (
          <LoadingOutlined />
        ) : (
          <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <h3 style={{ fontSize: 14, margin: 0 }}>{parseFloat(averageRating.toFixed(2)) || 0}</h3>
            <Rate
              style={{ fontSize: 17 }}
              defaultValue={0}
              allowHalf
              disabled
              value={averageRating}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default AverageRatingWidget;
