import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Select, Skeleton, Typography } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { useReviewsLineChart, useReviewStatistics } from '@core/hooks';
import { useReviewState } from '@core/configureStore';
import moment from 'moment';
import CardSectionHeader from '@components/CardSectionHeader';

function LineChart() {
  const { isLoading, startDate } = useReviewState();
  const { monthlyChanges } = useReviewStatistics();
  const { config: reviewLineChartConfig, selectedYear, setSelectedYear } = useReviewsLineChart();
  const { Paragraph } = Typography;

  return (
    <React.Fragment>
      <div className="linechart">
        <div>
          <CardSectionHeader title="RECENT REVIEWS" />
          <Paragraph className="lastweek">
            Monthly changes{' '}
            {monthlyChanges > 0 ? (
              <span className="greenfont">+{monthlyChanges}%</span>
            ) : (
              <span className="redfont">{monthlyChanges}%</span>
            )}
          </Paragraph>
        </div>
        {startDate && (
          <div>
            <Select
              onChange={(value: string) => setSelectedYear(value)}
              defaultValue={selectedYear}
              value={selectedYear}>
              {new Array(new Date().getFullYear() - parseInt(moment(startDate).format('YYYY')))
                .fill(0)
                .map((_, index) => (
                  <Select.Option
                    key={index + 1}
                    value={moment(startDate)
                      .add(index + 1, 'years')
                      .format('YYYY')}>
                    {moment(startDate)
                      .add(index + 1, 'years')
                      .format('YYYY')}
                  </Select.Option>
                ))}
            </Select>
          </div>
        )}
        <div className="sales">
          <ul>
            <li style={{ fontSize: 'smaller' }}>
              {<MinusOutlined />} {'Positive Reviews (> 3)'}
            </li>
            <li style={{ fontSize: 'smaller' }}>
              {<MinusOutlined />} {'Negative Reviews (<= 3)'}
            </li>
          </ul>
        </div>
      </div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <ReactApexChart
          className="full-width"
          options={reviewLineChartConfig.options}
          series={reviewLineChartConfig.series}
          type="area"
          height={200}
          width={'100%'}
        />
      )}
    </React.Fragment>
  );
}

export default LineChart;
