import { BREAKPOINTS } from '@constants/responsive';
import { useBreakpoint } from '@core/hooks';
import { EmbeddedWallOfLoveSetting, GoogleReview } from '@core/models';
import React from 'react';
import { ReviewWallOfLoveCard } from '..';

type Props = {
  reviews: GoogleReview[];
  embeddedSetting: EmbeddedWallOfLoveSetting;
  maxReviews?: number;
};

const ReviewWallOfLove = ({ embeddedSetting, reviews, maxReviews }: Props) => {
  const { isTablet } = useBreakpoint();
  return (
    <div
      className="card-list"
      style={{
        ...(isTablet || embeddedSetting.maxContainerSize <= BREAKPOINTS.lg
          ? { columnCount: 1 }
          : { columnCount: 3 }),
        maxWidth: embeddedSetting.maxContainerSize,
      }}>
      {reviews
        .filter(review =>
          embeddedSetting.hideNodeCommentReviews ? review.comment?.length > 0 : true
        )
        .slice(0, embeddedSetting.maxReviews || maxReviews)
        .map(review => (
          <ReviewWallOfLoveCard
            key={review.reviewId}
            backgroundColor={embeddedSetting.cardColor}
            titleColor={embeddedSetting.cardTitleColor}
            review={review}
          />
        ))}
    </div>
  );
};

export default ReviewWallOfLove;
