import { MIDDLE_STYLE } from '@constants/responsive';
import { Button, Drawer } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React from 'react';
import { useBreakpoint } from '@core/hooks';

interface Props {
  title: string;
  handleUpload: any;
  handleOnClose: any;
  open: boolean;
  children: React.ReactNode;
}
const BrowsePhotoDrawerLayout: React.FC<Props> = ({
  title,
  handleOnClose,
  handleUpload,
  open,
  children,
}) => {
  const { isTablet } = useBreakpoint();
  return (
    <Drawer
      title={
        <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
          <h3>{title}</h3>
          <Button onClick={handleUpload}>
            <UploadOutlined />
            Select
          </Button>
        </div>
      }
      zIndex={1000}
      placement={'bottom'}
      closable={true}
      onClose={handleOnClose}
      height={isTablet ? '100%' : '90%'}
      getContainer={undefined}
      open={open}>
      {children}
    </Drawer>
  );
};

export default BrowsePhotoDrawerLayout;
