/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Button, Card, notification, Rate, Skeleton, Space } from 'antd';
import { SocialPlatformType } from '../BusinessSocialPostCard';
import { EditOutlined } from '@ant-design/icons';
import LoadableContainer from '@components/LoadableContainer';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { shortenString } from '@utils/string.util';
import { BusinessSocialPost, GoogleSmartReview } from '@core/models';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { formatTimestamp } from '@utils/date.util';
import { useLocation } from 'react-router';
import { NewlineText } from '..';
import _ from 'lodash';

type Props = {
  innerRef?: React.LegacyRef<HTMLDivElement>;
  socialPost: BusinessSocialPost;
  businessName: string;
  type: SocialPlatformType;
  review: GoogleSmartReview;
  imageWidth: number;
  imageHeight: number;
  hideApproveButton?: boolean;
};

const getRandomImageImage = () => {
  const TOTAL_ASSET_INDEX = 12;
  const randomPhotos = new Array(TOTAL_ASSET_INDEX)
    .fill('https://amazely-assets.s3.amazonaws.com')
    .map((path, index) => `${path}/asset-${index + 1}.jpg`);
  const currentPhotoIndx = _.random(0, TOTAL_ASSET_INDEX - 1);

  return randomPhotos[currentPhotoIndx];
};

function capitalizeEveryFirstLetter(str: string) {
  if (str.length === 0) return '';
  const words = str.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}

const ShowcaseReviewSocialPost = ({
  innerRef,
  socialPost,
  businessName,
  review,
  type,
  imageHeight,
  imageWidth,
  hideApproveButton,
}: Props) => {
  const { isMobile } = useBreakpoint();
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();

  const notify = () => {
    api.open({
      message: 'Please sign in to setup your business first.',
    });
  };
  return (
    <Card
      hoverable
      className="no-padding"
      style={{
        maxWidth: 400,
        minWidth: 330,
        width: '100%',
        marginRight: 10,
        marginTop: 40,
        boxShadow: hideApproveButton ? 'unset' : STRIPE_BOX_SHADOW,
      }}>
      <div style={{ padding: '20px' }}>
        <LoadableContainer isLoading={false} loadComponent={<Skeleton active />}>
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <h3 style={{ margin: '10px 5px' }}>{businessName}</h3>
              <img src={`social-logo/${type}_logo.webp`} width={30} />
            </div>
            <div
              style={{
                top: -20,
                position: 'absolute',
                backgroundColor: 'grey',
                width: 'fit-content',
                color: 'white',
                padding: '5px 10px',
                borderRadius: 5,
                fontWeight: 600,
              }}>
              Scheduled for {socialPost && formatTimestamp(socialPost.scheduledAt)}
            </div>
            <NewlineText text={socialPost?.caption || ''} />
          </React.Fragment>
        </LoadableContainer>
      </div>
      <div
        style={{
          ...MIDDLE_STYLE,
          backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
          width: '100%',
          flexDirection: 'column',
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        }}>
        <div
          ref={innerRef}
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${getRandomImageImage()}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            aspectRatio: imageWidth / imageHeight,
            overflow: 'hidden',
            ...MIDDLE_STYLE,
            flexDirection: 'column',
            padding: '10px 30px',
            position: 'relative',
          }}>
          {!hideApproveButton && (
            <span>
              {contextHolder}
              <Button
                onClick={notify}
                size="large"
                type="primary"
                style={{
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  zIndex: 10,
                  ...MIDDLE_STYLE,
                }}>
                <EditOutlined /> Approve
              </Button>
            </span>
          )}
          <div style={{ textAlign: 'center', marginBottom: 0 }}>
            <p
              style={{
                color: GLOBAL_THEME_COLOR.$text_color_contrast,
                margin: 0,
              }}>
              Best review from
            </p>
            <h1
              style={{
                color: GLOBAL_THEME_COLOR.$text_color_contrast,
                marginTop: 0,
                fontFamily: 'Rubik',
              }}>
              {businessName}
            </h1>
          </div>
          <div
            style={{
              flexDirection: 'column',
              width: '100%',
              backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
              boxShadow: STRIPE_BOX_SHADOW,
              padding: '10px 15px 10px 15px',
              marginTop: 10,
            }}>
            <img src="https://app.amazely.co/google.png" width="10%" />
            <h3 className="airbnb-font" style={{ fontSize: 16 }}>
              {capitalizeEveryFirstLetter(review.author_name)}
            </h3>
            <Rate style={{ fontSize: 15 }} value={review.rating} disabled />
            <p
              style={{
                marginTop: 10,
                marginBottom: 15,
                fontSize: isMobile ? 10 : '0.75rem',
              }}>
              <span>"</span>
              {shortenString(review.text || 'N/A', 250)}
              <span>"</span>
            </p>
          </div>
        </div>
      </div>
      <Space
        size={40}
        style={{
          ...MIDDLE_STYLE,
          justifyContent: 'space-between',
          padding: '20px 10px',
        }}>
        <p
          style={{
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            ...MIDDLE_STYLE,
            textAlign: 'center',
          }}>
          <img width={45} src={`social_emo_icon/${type}-icon.png`} />{' '}
          <span style={{ marginLeft: 10 }}>{Math.floor(Math.random() * 100) + 100} likes</span>
        </p>
        <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
          {Math.floor(Math.random() * 10) + 10} comments
        </p>
      </Space>
    </Card>
  );
};

export default ShowcaseReviewSocialPost;
