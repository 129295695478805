import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';

export const fetchJobberInfo = createAsyncThunk('business/fetchJobberInfo', async (_, thunkApi) => {
  try {
    const jobberInfo = await heroApiService.getLocationJobberInfo();
    return jobberInfo;
  } catch (error: any) {
    return undefined;
  }
});

const [fulfilled, pending, rejected] = getSideEffectState(fetchJobberInfo);

export const fetchJobberInfoReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.integration.jobber = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.integration.jobber = undefined;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
