import React from 'react';
import UploadImageModal from '@components/UploadImageModal';
import { PhotoImageDataItem, openModal } from '@core/features';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { TbPhoto } from 'react-icons/tb';
import MediaItemGridLayout from '@components/MediaItemGridLayout';
import { useAppDispatch } from '@core/configureStore';

interface Props {
  mediaItems: PhotoImageDataItem[];
  onMediaUploaded: (mediaItems: PhotoImageDataItem[]) => void;
  selectPhotoDisabled: boolean;
}

const UploadSocialPostMediaButton: React.FC<Props> = ({
  mediaItems,
  onMediaUploaded,
  selectPhotoDisabled,
}) => {
  const dispatch = useAppDispatch();
  const handleSelectPhotoFromGallery = () => {
    if (selectPhotoDisabled) return;
    dispatch(
      openModal({
        modalName: 'uploadImageModal',
      })
    );
  };

  return (
    <React.Fragment>
      {mediaItems.length > 0 ? (
        <React.Fragment>
          <MediaItemGridLayout
            onClickEach={() => {
              dispatch(
                openModal({
                  modalName: 'uploadImageModal',
                })
              );
            }}
            renderOverlay={() => 'Replace Media'}
            images={mediaItems.map(mediaItem => mediaItem.url)}
          />
        </React.Fragment>
      ) : (
        <div
          onClick={handleSelectPhotoFromGallery}
          style={{
            ...MIDDLE_STYLE,
            flexDirection: 'column',
            textAlign: 'center',
            width: '100%',
            aspectRatio: '1/1',
            color: GLOBAL_THEME_COLOR.$dark_text_color,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            padding: '0px 40px',
            cursor: 'pointer',
          }}>
          <TbPhoto style={{ fontSize: 100 }} />
          <span style={{ marginTop: 10, fontSize: 15 }}>
            Click to select a photo from your gallery
          </span>
        </div>
      )}
      <UploadImageModal
        actionTitle="Select"
        maxNumberOfPhotos={6}
        existingMedias={mediaItems}
        onUpload={onMediaUploaded}
        disabledSquarePhotoSource={true}
      />
    </React.Fragment>
  );
};

export default UploadSocialPostMediaButton;
