import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useDrawerState, useStudioState } from '@core/configureStore';
import { closeDrawer } from '@core/features';
import { Empty, Skeleton } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LoadableContainer } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { StudioPhoto } from '@core/models';
import { fetchGalleryImages } from '@core/features/studio/thunks';
import BrowsePhotoDrawerLayout from '@components/BrowsePhotoDrawerLayout';

type Props = {
  singleFile?: boolean;
  onLoad: (studioPhotos: StudioPhoto[]) => Promise<void>;
};

const BrowsePhotoGalleryDrawer = ({ singleFile, onLoad }: Props) => {
  const dispatch = useAppDispatch();
  const { studioPhotos, isLoading } = useStudioState();
  const { photoGalleryBrowseDrawer, extraParams } = useDrawerState();
  const defaultSelectedPics = useMemo(() => extraParams?.defaultSelectedPics || {}, [extraParams]);
  const [selectedPics, setSelectedPics] = useState<Record<string, boolean>>(defaultSelectedPics);

  const handleOnClose = () => {
    dispatch(
      closeDrawer({
        drawerName: 'photoGalleryBrowseDrawer',
      })
    );
  };

  const handleUpload = () => {
    onLoad(studioPhotos.filter(studioPhoto => selectedPics[studioPhoto.id]));
    handleOnClose();
  };

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchGalleryImages());
    };
    if (studioPhotos.length === 0 && photoGalleryBrowseDrawer) {
      init();
    }
  }, [studioPhotos, photoGalleryBrowseDrawer]);

  useEffect(() => {
    setSelectedPics(defaultSelectedPics);
  }, [defaultSelectedPics]);

  return (
    <BrowsePhotoDrawerLayout
      title={'Photo Gallery'}
      handleUpload={handleUpload}
      handleOnClose={handleOnClose}
      open={photoGalleryBrowseDrawer}>
      <LoadableContainer isLoading={isLoading} loadComponent={<Skeleton />}>
        <LoadableContainer
          isLoading={studioPhotos.length === 0}
          loadComponent={
            <div style={{ ...MIDDLE_STYLE }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No images found</span>}
              />
            </div>
          }>
          <div className="card-list">
            {studioPhotos.map(pic => (
              <div
                onClick={() =>
                  setSelectedPics(
                    !singleFile
                      ? {
                          ...selectedPics,
                          [pic.id]: !selectedPics[pic.id],
                        }
                      : {
                          [pic.id]: !selectedPics[pic.id],
                        }
                  )
                }
                className="card"
                key={pic.id}
                style={{
                  borderRadius: 20,
                  padding: selectedPics[pic.id] ? '5px' : '0px',
                  cursor: 'pointer',
                  ...(selectedPics[pic.id]
                    ? { border: `6px solid ${GLOBAL_THEME_COLOR.$highlight_color}` }
                    : {}),
                }}>
                <LazyLoadImage
                  loading="lazy"
                  className="card--image"
                  alt={pic.description}
                  src={pic.url}
                  width="50%"
                  height="50%"
                />
              </div>
            ))}
          </div>
        </LoadableContainer>
      </LoadableContainer>
    </BrowsePhotoDrawerLayout>
  );
};

export default BrowsePhotoGalleryDrawer;
