import { createAsyncThunk } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { LocationReducerProps } from '../type';

export const fetchQuickBooksInfo = createAsyncThunk(
  'business/fetchQuickBooksInfo',
  async (_, thunkApi) => {
    try {
      const quickbooksInfo = await heroApiService.getLocationQuickBooksInfo();
      return quickbooksInfo;
    } catch (error: any) {
      return undefined;
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(fetchQuickBooksInfo);

export const fetchQuickBooksInfoReducer = {
  [fulfilled]: (state, action) => {
    state.isLoading = false;
    state.integration.quickbooks = action.payload;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
    state.integration.quickbooks = undefined;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<LocationReducerProps>;
