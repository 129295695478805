import React from 'react';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import { Card, Col, Divider, Row, Space, Switch, Typography } from 'antd';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { activateAutoRequest } from '@core/features/business/thunks/activateAutoRequest.thunk';
import { deactivateAutoRequest } from '@core/features/business/thunks/deactivateAutoRequest.thunk';
import confirm from 'antd/es/modal/confirm';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';

type Props = {};

const AutoRequestScreen = (props: Props) => {
  const dispatch = useAppDispatch();
  const { user, isAuthLoading } = useAuthState();
  const { isAutoRequestActivating } = useBusinessState();
  const { Paragraph } = Typography;

  const handleActivateAutoRequest = async () => {
    if (!user?.selected_location?.auto_request_activated) {
      confirm({
        title: 'Activate auto request mode',
        icon: <ExclamationCircleFilled />,
        content:
          'Activate auto request mode for your business? This will automatically send a review request 1 day after you send a BCC to our created mailbox. Confirm to proceed.',
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {},
        okText: 'Activate',
        onOk: () => {
          dispatch(activateAutoRequest());
        },
      });
    }
  };

  const handleDeactivateAutoRequest = async () => {
    if (user?.selected_location?.auto_request_activated) {
      dispatch(deactivateAutoRequest());
    }
  };

  return (
    <LoadableContainer isLoading={isAuthLoading} loadComponent={<SplashScreen />}>
      <React.Fragment>
        <div className="screen-card">
          <h3>Auto Request</h3>
          <Divider />
          <div style={{ ...MIDDLE_STYLE }}>
            <Card
              style={{
                maxWidth: '1000px',
                backgroundColor: GLOBAL_THEME_COLOR.$primary_color,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              }}>
              <Row style={{ ...MIDDLE_STYLE }}>
                <Col span={18}>
                  Auto send a review request to your customer when you BCC us in your invoice email
                  with our created mailbox for your business:{' '}
                  <b>
                    <Paragraph copyable>
                      {user?.selected_location?.location_id
                        ? `invite+${user?.selected_location?.location_id}@amazely.co`
                        : ''}
                    </Paragraph>
                  </b>
                </Col>
                <Col span={6} style={{ ...MIDDLE_STYLE }}>
                  {user?.selected_location && !isAuthLoading && !isAutoRequestActivating ? (
                    <Space>
                      <Switch
                        checked={user?.selected_location.auto_request_activated}
                        onChange={isChecked => {
                          !isChecked ? handleDeactivateAutoRequest() : handleActivateAutoRequest();
                        }}
                      />
                    </Space>
                  ) : (
                    <LoadingOutlined />
                  )}
                </Col>
              </Row>
              <div style={{ ...MIDDLE_STYLE, marginTop: 20, marginBottom: 50 }}>
                <img src="auto-request-jumbotron.png" width={500} alt="Auto Request Banner" />
              </div>
            </Card>
          </div>
        </div>
      </React.Fragment>
    </LoadableContainer>
  );
};

export default AutoRequestScreen;
