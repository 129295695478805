const ROOT_GOOGLE_OAUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth`;
const GOOGLE_BASIC_SCOPES = [
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
];
const GOOGLE_SENSITIVE_SCOPES = ['https://www.googleapis.com/auth/business.manage'];

export const getGoogleIncrementalUrl = (userId: string, from: string) => {
  const options = {
    redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT as string,
    client_id: process.env.REACT_APP_AMAZELY_GOOGLE_OAUTH_CLIENT_ID as string,
    access_type: 'offline',
    response_type: 'code',
    login_hint: userId,
    prompt: 'consent',
    include_granted_scopes: 'true',
    scope: [...GOOGLE_SENSITIVE_SCOPES].join(' '),
    state: from,
  };

  const qs = new URLSearchParams(options);

  return `${ROOT_GOOGLE_OAUTH_URL}?${qs.toString()}`;
};

export const getGoogleUrl = (state: string, redirectUri: string) => {
  const options = {
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    include_granted_scopes: 'true',
    scope: [...GOOGLE_BASIC_SCOPES].join(' '),
    state: state,
  };

  const qs = new URLSearchParams(options);

  return `${ROOT_GOOGLE_OAUTH_URL}?${qs.toString()}`;
};

export const getAmazelyGoogleUrl = (state: string, redirectUri: string) => {
  const options = {
    redirect_uri: redirectUri,
    client_id: process.env.REACT_APP_AMAZELY_GOOGLE_OAUTH_CLIENT_ID as string,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    include_granted_scopes: 'false',
    scope: [...GOOGLE_BASIC_SCOPES].join(' '),
    state: state,
  };

  const qs = new URLSearchParams(options);

  return `${ROOT_GOOGLE_OAUTH_URL}?${qs.toString()}`;
};
