/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, List, Modal, Skeleton, Space, Tooltip } from 'antd';
import {
  useAppDispatch,
  useAppNavigation,
  useAuthState,
  useBusinessState,
  useModalState,
} from '@core/.';
import { closeModal, setBusinessStateLoading, setGoogleLocations } from '@core/features';
import { selectLocation } from '@core/features/business/thunks/selectLocation.thunk';
import { Location } from '@core/models';
import { CheckCircleOutlined, SwitcherOutlined } from '@ant-design/icons';
import { MAX_ONBOARDING_STAGE } from '@constants/pitch-business';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import MultipleLocationView from './MultipleLocationView';
import { fetchAllGoogleBusinessLocations } from '@core/features/business/thunks/fetchAllGoogleBusinessLocations.thunk';
import { LoadableContainer } from '..';
import { IoRefreshOutline } from 'react-icons/io5';
import { getJsonData, storeJsonData } from '@utils/cache.util';
import { publicRouteService } from '@services/service-register';

type Props = {};

const SelectLocationModal = (_: Props) => {
  const { user } = useAuthState();
  const GLOBAL_LOCAL_CACHE_KEY = useMemo(() => `${user?.id}:blog-onboarding`, [user]);
  const [globalSwitch, setGlobalSwitch] = useState(!!getJsonData(GLOBAL_LOCAL_CACHE_KEY));
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { locationModal } = useModalState();
  const { locations, isLoading, googleLocations } = useBusinessState();

  const onSelectLocation = async (locationName: string) => {
    try {
      await dispatch(selectLocation(locationName));
      if (user?.selected_location?.onboarding_stages?.length === MAX_ONBOARDING_STAGE)
        navigate('home');
      else navigate('getting-started');
      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'locationModal',
      })
    );
  };

  const renderDescription = (item: Location) => {
    const address = item?.storefrontAddress;
    return address
      ? `${address.addressLines?.[0]}, ${address.locality}, ${address.regionCode}`
      : 'No Storefront Address';
  };

  const isLocationSelected = useCallback(
    (locationItem: Location) => {
      return locationItem.metadata.placeId
        ? locationItem.metadata.placeId === user?.selected_location.place_id
        : locationItem.title === user?.selected_location?.title;
    },
    [user]
  );

  const handleSwitchGlobalView = () => {
    storeJsonData(GLOBAL_LOCAL_CACHE_KEY, !globalSwitch);
    setGlobalSwitch(!globalSwitch);
  };

  const handleForceRefresh = async (forceRefetch: boolean = true) => {
    if (isLoading || !user) return;
    if (!forceRefetch && googleLocations.length > 0) return;
    if (forceRefetch) dispatch(setGoogleLocations({ locations: [] }));
    dispatch(setBusinessStateLoading(true));
    await dispatch(fetchAllGoogleBusinessLocations({ locations, user, forceRefetch }));
    dispatch(setBusinessStateLoading(false));
  };

  useEffect(() => {
    if (!globalSwitch) return;
    handleForceRefresh(false);
  }, [globalSwitch]);

  return (
    <Modal
      title="Select your location"
      centered
      open={locationModal}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      width={globalSwitch ? '100%' : 600}
      style={{ maxWidth: globalSwitch ? '1400px' : '100%' }}>
      <LoadableContainer
        isLoading={globalSwitch ? isLoading && googleLocations.length === 0 : isLoading}
        loadComponent={<Skeleton />}>
        <div>
          {!globalSwitch && (
            <img
              src="jumbotron/business-inquiry-card.webp"
              style={{ width: '100%', height: '250px', objectFit: 'cover' }}
            />
          )}
          <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
            <i>
              <a
                onClick={() => {
                  window.open('https://support.google.com/business/answer/3403100?hl=en');
                  publicRouteService.sendUserActivitySlackNotification(
                    user?.selected_location?.title || 'Unknown',
                    '[ONBOARDING LOCATION] Learning how to create a location'
                  );
                }}>
                Don't see your location? Follow this guide.
              </a>
            </i>
            <Space>
              <Tooltip title="Manage all locations">
                <Button style={{ borderRadius: 20 }} onClick={handleSwitchGlobalView}>
                  {globalSwitch ? (
                    <React.Fragment>
                      <SwitcherOutlined /> Normal View
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <SwitcherOutlined /> Global View
                    </React.Fragment>
                  )}
                </Button>
              </Tooltip>
              {globalSwitch && (
                <Button style={{ borderRadius: 20 }} onClick={() => handleForceRefresh(true)}>
                  <IoRefreshOutline /> Refresh
                </Button>
              )}
            </Space>
          </div>
          {globalSwitch ? (
            <MultipleLocationView />
          ) : (
            <List
              style={{ marginTop: 20 }}
              dataSource={locations}
              pagination={{
                pageSizeOptions: undefined,
                onChange: page => {
                  console.log(page);
                },
                hideOnSinglePage: true,
                pageSize: 5,
              }}
              renderItem={item => (
                <List.Item
                  key={item.name}
                  actions={[
                    !!user && !isLocationSelected(item) ? (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => onSelectLocation(item.name)}>
                        Select
                      </Button>
                    ) : (
                      <CheckCircleOutlined style={{ fontSize: 20 }} />
                    ),
                  ]}>
                  <List.Item.Meta
                    title={<p>{item.title}</p>}
                    description={renderDescription(item)}
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      </LoadableContainer>
    </Modal>
  );
};

export default SelectLocationModal;
