/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  StreamingTask,
  StreamingTaskStatus,
  useGlobalEventEmitter,
  useStreamingTaskStore,
} from '@core/hooks';
import { useStreamApi } from '@core/hooks';
import { emitCloseStream } from '@components/EventEmitterProvider';
import { serverSignalService } from '@services/service-register';

interface Props {
  children: React.ReactNode;
}

const StreamingEventObserver: React.FC<Props> = ({ children }) => {
  const { inner } = useGlobalEventEmitter();
  const { executeStreamApi } = useStreamApi();
  const { tasks, taskMetadata, removeTask, setTaskMetadata, incrTaskProgress } =
    useStreamingTaskStore();

  const updateTaskStatus = (task: StreamingTask, status: StreamingTaskStatus) => {
    setTaskMetadata({
      ...taskMetadata,
      [task.id]: {
        ...taskMetadata[task.id],
        status,
      },
    });
  };

  useEffect(() => {
    const completeTask = (task: StreamingTask) => {
      updateTaskStatus(task, StreamingTaskStatus.Finished);
      removeTask(task.id);
    };

    const taskHandler = async () => {
      for (const task of tasks) {
        updateTaskStatus(task, StreamingTaskStatus.InProgress);
        const abortController = new AbortController();

        if (inner) {
          inner.on(emitCloseStream(task.id), async () => {
            task.onInterrupted && task.onInterrupted();
            await serverSignalService.interruptStream();
            abortController.abort();
            completeTask(task);
          });
        }

        await executeStreamApi({
          apiMethod: task.apiMethod,
          payload: task.payload,
          abortSignal: abortController.signal,
          onDecodedData: data => {
            incrTaskProgress(task.id);
            task.onDecodedData && task.onDecodedData(data);
          },
          onFinishedCallback: async () => {
            await task.onFinishedCallback();
            completeTask(task);
          },
        });
      }
    };
    taskHandler();
  }, [tasks]);

  return <div>{children}</div>;
};

export default StreamingEventObserver;
