import { SocialPlatformType } from '@components/BusinessSocialPostCard';
import { COLLECT_SHOWCASE_REVIEWS } from '@constants/pitch-business';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useGenerationDataStore } from '@core/hooks';
import { GoogleSmartReview } from '@core/models';
import { Skeleton } from 'antd';
import React from 'react';
import { LoadableContainer, ShowcaseReviewSocialPost } from '..';

const PitchReviewMarketingSection = ({ reviews }: { reviews: GoogleSmartReview[] }) => {
  const { businessData, stageLoading } = useGenerationDataStore();
  return (
    <div id="generate-review-showcase">
      <div style={{ fontSize: 'larger', lineHeight: 1.7, padding: '0px 20px' }}>
        <h2 className="pitch_title">Review Marketing</h2>
        <div className="pitch_subtitle">
          Amazely boosts sales & increases customer trust by showcasing the best 5-star reviews on
          your website and social channels, every week.
        </div>
      </div>
      <LoadableContainer
        isLoading={reviews.length === 0 || stageLoading[COLLECT_SHOWCASE_REVIEWS]}
        loadComponent={<Skeleton active style={{ marginTop: 20 }} />}>
        <div
          style={{
            flexWrap: 'wrap',
            marginTop: 20,
            ...MIDDLE_STYLE,
          }}>
          {reviews.map((review, index) => (
            <ShowcaseReviewSocialPost
              type={(['facebook', 'instagram', 'pinterest'] as SocialPlatformType[])[index % 3]}
              socialPost={businessData.showcaseReviewSocialPosts[index]}
              imageHeight={1000}
              imageWidth={1000}
              review={review}
              businessName={businessData.name}
            />
          ))}
        </div>
      </LoadableContainer>
    </div>
  );
};

export default PitchReviewMarketingSection;
