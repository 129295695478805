import React from 'react';
import { Button, Skeleton, Space, Steps, Tag } from 'antd';
import { useAppDispatch, useReviewRequestState } from '@core/.';
import { formatUtcDate, formatUtcDateWithTimeDiff, isInTimeWindow } from '@utils/.';
import { RequestServiceStage, ReviewRequest, ReviewRequestStatus } from '@core/models';
import { resendRequest } from '@core/features/review-request/thunks';
import moment from 'moment';
import { RedoOutlined } from '@ant-design/icons';

type Props = {};

const RequestStageSection = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isDetailLoading, request } = useReviewRequestState();

  const handleResendEmail = () => {
    if (request) {
      dispatch(resendRequest({ requestId: request.id, requestType: 'email' }));
    }
  };

  const handleResendSMS = () => {
    if (request) {
      dispatch(resendRequest({ requestId: request.id, requestType: 'sms' }));
    }
  };

  const renderStatusText = (
    status: RequestServiceStage,
    serviceName: string
  ): React.ReactElement => {
    switch (status) {
      case RequestServiceStage.Reviewed:
      case RequestServiceStage.Open:
        return <Tag color="green">{serviceName} Opened</Tag>;
      case RequestServiceStage.Sent:
        return <Tag color="green">{serviceName} Sent</Tag>;
      case RequestServiceStage.Failed:
        return <Tag color="red">{serviceName} Sent Failed</Tag>;
    }
    return <Tag color="yellow">Pending</Tag>;
  };

  const renderEmailStatus = (request: ReviewRequest) => {
    switch (request.email_status) {
      case RequestServiceStage.Pending:
        return {
          title: `Sending request email...`,
          description: 'Request email is being processed',
        };
      case RequestServiceStage.Scheduled:
        return {
          title: `Auto Email Request Scheduled`,
          description: `Email request will be sent on ${formatUtcDateWithTimeDiff(
            request.created_date,
            request.schedule_duration,
            'seconds'
          )}`,
        };
      default:
        return {
          title: `Email INVITE: ${
            request.email_sent_date
              ? `Sent on ${formatUtcDate(request.email_sent_date)}`
              : 'Not yet sent.'
          }`,
          description: (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space style={{ flexWrap: 'wrap' }}>
                {renderStatusText(request.email_status, 'Email')}
                {request.email_status !== RequestServiceStage.Open &&
                request.email_status !== RequestServiceStage.Reviewed ? (
                  <Tag color="yellow">Not opened</Tag>
                ) : (
                  <Tag color="green">Opened</Tag>
                )}
                {request.email_status !== RequestServiceStage.Reviewed ? (
                  <Tag color="yellow">Not reviewed</Tag>
                ) : (
                  <Tag color="green">Reviewed</Tag>
                )}
              </Space>
              {request.email_status === RequestServiceStage.Failed ||
                (!isInTimeWindow(moment(request.email_sent_date).toDate(), {
                  unit: 'week',
                  value: 1,
                }) && (
                  <Button onClick={handleResendEmail}>
                    <RedoOutlined />
                    Resend email
                  </Button>
                ))}
            </div>
          ),
        };
    }
  };

  const renderSmsStatus = (request: ReviewRequest) => {
    switch (request.sms_status) {
      case RequestServiceStage.Pending:
        return {
          title: `Sending request SMS...`,
          description: 'Request SMS is being processed',
        };
      default:
        return {
          title: `SMS INVITE: ${
            request.email_sent_date
              ? `Sent on ${formatUtcDate(request.sms_sent_date)}`
              : 'Not yet sent.'
          }`,
          description: (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Space style={{ flexWrap: 'wrap' }}>
                {renderStatusText(request.sms_status, 'SMS')}
                {request.sms_status !== RequestServiceStage.Open &&
                request.sms_status !== RequestServiceStage.Reviewed ? (
                  <Tag color="yellow">Not opened</Tag>
                ) : (
                  <Tag color="green">Opened</Tag>
                )}
                {request.sms_status !== RequestServiceStage.Reviewed ? (
                  <Tag color="yellow">Not reviewed</Tag>
                ) : (
                  <Tag color="green">Reviewed</Tag>
                )}
              </Space>
              {request.sms_status === RequestServiceStage.Failed && (
                <Button onClick={handleResendSMS}>Resend SMS</Button>
              )}
            </div>
          ),
        };
    }
  };

  const renderRequestStage = (): number => {
    if (request && request.email_status > RequestServiceStage.Pending) return 2;
    return request && request.stage === ReviewRequestStatus.Pending ? 0 : -1;
  };

  const getRenderItems = (request: ReviewRequest) => {
    let contact = request.contact;
    if (request.phone) {
      contact = contact ? contact.concat(` (${request.phone})`) : request.phone;
    }
    const action = request.stage === ReviewRequestStatus.Draft ? 'drafted' : 'created';
    const items: { title: string | JSX.Element; description: string | JSX.Element }[] = [
      {
        title: (
          <div>
            Request <span style={{ fontWeight: 'bold' }}>{contact}</span> for review
          </div>
        ),
        description: `Request was ${action} on ${formatUtcDate(request.created_date)}`,
      },
    ];

    if (request.stage !== ReviewRequestStatus.Draft) {
      if (request.email_status !== RequestServiceStage.NA) {
        items.push(renderEmailStatus(request));
      }
      if (request.sms_status !== RequestServiceStage.NA) {
        items.push(renderSmsStatus(request));
      }
    }

    return items;
  };

  return !isDetailLoading && request ? (
    <Steps direction="vertical" current={renderRequestStage()} items={getRenderItems(request)} />
  ) : (
    <Skeleton />
  );
};

export default RequestStageSection;
