/* eslint-disable @typescript-eslint/no-unused-vars */
import { AsyncThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  authReducer,
  businessReducer,
  drawerReducer,
  inquiryReducer,
  modalReducer,
  reviewReducer,
  reviewRequestReducer,
  snackbarReducer,
  studioReducer,
  subscriptionReducer,
  blogReducer,
} from './features';

const reducer = combineReducers({
  auth: authReducer,
  business: businessReducer,
  review: reviewReducer,
  reviewRequest: reviewRequestReducer,
  modal: modalReducer,
  snackbar: snackbarReducer,
  studio: studioReducer,
  inquiry: inquiryReducer,
  drawer: drawerReducer,
  subscription: subscriptionReducer,
  blog: blogReducer,
});

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useBatchAppDispatch = () => {
  const dispatch = useAppDispatch();
  const batchDispatch = (actions: (AsyncThunkAction<any, void, any> | any)[]) => {
    const promises: Promise<any>[] = [];
    for (const action of actions) {
      promises.push(dispatch(action));
    }
    Promise.all(promises);
  };
  return batchDispatch;
};

export const useSnackbarState = () => useAppSelector(state => state.snackbar);
export const useModalState = () => useAppSelector(state => state.modal);
export const useAuthState = () => useAppSelector(state => state.auth);
export const useBusinessState = () => useAppSelector(state => state.business);
export const useReviewState = () => useAppSelector(state => state.review);
export const useReviewRequestState = () => useAppSelector(state => state.reviewRequest);
export const useStudioState = () => useAppSelector(state => state.studio);
export const useInquiryState = () => useAppSelector(state => state.inquiry);
export const useDrawerState = () => useAppSelector(state => state.drawer);
export const useBlogState = () => useAppSelector(state => state.blog);
export const useSubscriptionState = () => useAppSelector(state => state.subscription);
