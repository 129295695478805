export enum SliceName {
  Authentication = 'userAuthentication',
  Navigation = 'navigation',
  Modal = 'modal',
  Location = 'location',
  Drawer = 'drawer',
  Review = 'review',
  ReviewRequest = 'reviewRequest',
  Snackbar = 'snackbar',
  Studio = 'studio',
  Blog = 'blog',
  Inquiry = 'inquiry',
  Subscription = 'subscription',
}
