import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Divider, Modal, Space } from 'antd';
import { useAppDispatch, useModalState } from '@core/configureStore';
import { useSocialPosts } from '@core/hooks';
import { SocialPost } from '@core/models';
import { closeModal } from '@core/features';
import SmallSocialPostCard from '@components/SocialPostCard/small';
import { countExistentialObject } from '@utils/array.util';
import { sortSocialPostsByPublishedDate } from '@utils/social.util';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { fetchSocialPostsSilent } from '@core/features/studio/thunks';

const SocialPostSelectionModal: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const { socialPostSelectionModal, extraParams } = useModalState();
  const onSelect = useMemo<
    (selectedIds: number[], notSelectedIds: number[]) => Promise<void> | undefined
  >(() => extraParams?.onSelect, [extraParams]);
  const filteredBy = useMemo<(socialPost: SocialPost) => boolean>(
    () => extraParams?.filteredBy,
    [extraParams]
  );

  const { socialPosts } = useSocialPosts();
  const [selectedSocialPosts, setSelectedSocialPosts] = useState<Record<string, boolean>>({});
  const filteredSocialPosts = useMemo(
    () => (filteredBy ? socialPosts.filter(filteredBy) : socialPosts),
    [socialPosts, filteredBy]
  );
  const sortedSocialPosts = useMemo(
    () => filteredSocialPosts.sort(sortSocialPostsByPublishedDate('newestToOldest')),
    [filteredSocialPosts]
  );

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'socialPostSelectionModal',
      })
    );
  };

  const handleSelect = () => {
    const selectedIds: number[] = [];
    const notSelectedIds: number[] = [];
    for (const socialPost of sortedSocialPosts) {
      if (selectedSocialPosts[socialPost.id]) selectedIds.push(socialPost.id);
      else notSelectedIds.push(socialPost.id);
    }
    onSelect && onSelect(selectedIds, notSelectedIds);
  };

  const toggleCheckAll = (checked: boolean) => {
    const selectedSocialPosts: Record<string, boolean> = {};
    for (const socialPost of sortedSocialPosts) {
      selectedSocialPosts[socialPost.id] = checked;
    }
    setSelectedSocialPosts(selectedSocialPosts);
  };

  useEffect(() => {
    if (socialPostSelectionModal) {
      const selected: Record<string, boolean> = {};
      for (const socialPost of sortedSocialPosts) {
        selected[socialPost.id] = true;
      }
      setSelectedSocialPosts(selected);
    }
  }, [socialPostSelectionModal]);

  useEffect(() => {
    if (socialPostSelectionModal) {
      dispatch(fetchSocialPostsSilent());
    }
  }, [socialPostSelectionModal]);

  return (
    <Modal
      title={extraParams?.title || 'Select social posts'}
      centered
      open={socialPostSelectionModal}
      onCancel={handleCloseModal}
      width={'100%'}
      style={{ maxWidth: 400, overflow: 'hidden' }}
      footer={[
        <Button onClick={handleCloseModal}>Cancel</Button>,
        <Button type={'primary'} onClick={handleSelect}>
          Select ({countExistentialObject(selectedSocialPosts)})
        </Button>,
      ]}>
      <Space>
        <Checkbox onChange={e => toggleCheckAll(e.target.checked)}></Checkbox>
        <div style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>Select all social posts</div>
      </Space>
      <Divider style={{ marginTop: 5 }} />
      <div style={{ maxHeight: 500, overflow: 'auto' }}>
        {sortedSocialPosts.map(socialPost => (
          <Space>
            <Checkbox
              checked={selectedSocialPosts[socialPost.id]}
              onClick={() =>
                setSelectedSocialPosts({
                  ...selectedSocialPosts,
                  [socialPost.id]: !selectedSocialPosts[socialPost.id],
                })
              }></Checkbox>
            <SmallSocialPostCard item={socialPost} />
          </Space>
        ))}
      </div>
    </Modal>
  );
};

export default SocialPostSelectionModal;
