import { useEffect, useState } from 'react';
import { useAppDispatch, useAuthState, useBusinessState } from '@core/configureStore';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { RequestTemplate } from '@core/models';
import { updateRequestTemplate } from '@core/features/business/thunks/updateRequestTemplate.thunk';
import {
  defaultInvitationEmail,
  defaultFollowupEmail,
  defaultInvitationSms,
} from '@constants/request-template';
import { UploadBrandBannerButton, UploadBrandLogoButton } from '..';
import { PhotoImageDataItem } from '@core/features';
import { MIDDLE_STYLE } from '@constants/responsive';

interface Props {
  editBranding?: boolean;
  autoToggle?: boolean;
}

const urlToPhotoImageData = (index: number, url: string): PhotoImageDataItem => ({
  id: index,
  data: undefined,
  name: `photo-${index}`,
  extension: '',
  url,
});

const RequestTemplateSettingRow: React.FC<Props> = ({ autoToggle, editBranding }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const { isRequestTemplateUpdating } = useBusinessState();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [editRequestTemplate, setEditRequestTemplate] = useState(false);
  const [brandBannerUpdated, setBrandBannerUpdated] = useState(false);
  const [brandLogoUpdated, setBrandLogoUpdated] = useState(false);
  const [requestTemplate, setRequestTemplate] = useState<RequestTemplate>({
    invitation_email: '',
    followup_email: '',
    invitation_sms: '',
  });

  const setRequestTemplateLocationValues = () => {
    const location = user?.selected_location;
    if (location) {
      if (location.request_template) {
        setRequestTemplate(location.request_template);
      } else {
        const owner = location.owner_contact.given_name;
        setRequestTemplate({
          invitation_email: defaultInvitationEmail(location.title, owner)[0],
          followup_email: defaultFollowupEmail(location.title, owner)[0],
          invitation_sms: defaultInvitationSms(location.title, owner)[0],
        });
      }
    }
  };

  const handleDiscardChanges = () => {
    setBrandBannerUpdated(false);
    setRequestTemplateLocationValues();
  };

  const handleUpdateBrandBanner = (url: string | undefined) => {
    setBrandBannerUpdated(true);
    setRequestTemplate({
      ...requestTemplate,
      brand_banner: url,
    });
  };

  const handleUpdateBrandLogo = (url: string | undefined) => {
    setBrandLogoUpdated(true);
    setRequestTemplate({
      ...requestTemplate,
      brand_logo: url,
    });
  };

  const handleRequestTemplateChange = (e: any) => {
    const { name, value } = e.target;
    setRequestTemplate({
      ...requestTemplate,
      [name]: value,
    });
  };

  const handleUpdateRequestTemplate = () => {
    dispatch(
      updateRequestTemplate({
        invitationEmail: requestTemplate.invitation_email,
        followupEmail: requestTemplate.followup_email,
        invitationSms: requestTemplate.invitation_sms,
        ...(brandBannerUpdated
          ? {
              brandBannerPayload: requestTemplate.brand_banner
                ? urlToPhotoImageData(0, requestTemplate.brand_banner)
                : undefined,
            }
          : {}),
        ...(brandLogoUpdated
          ? {
              brandLogoPayload: requestTemplate.brand_logo
                ? urlToPhotoImageData(0, requestTemplate.brand_logo)
                : undefined,
            }
          : {}),
      })
    );
  };

  useEffect(() => {
    setRequestTemplateLocationValues();
    // To disable submit button at the beginning.
    forceUpdate({});
  }, []);

  useEffect(() => {
    setEditRequestTemplate(true);
  }, [autoToggle]);

  return (
    <div>
      {!editRequestTemplate && (
        <Row gutter={5}>
          <Col lg={6} md={24}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setEditRequestTemplate(!editRequestTemplate)}>
              Edit Review Request Template
            </Button>
          </Col>
        </Row>
      )}

      {editRequestTemplate && (
        <Form form={form} name="request_template_form" onFinish={handleUpdateRequestTemplate}>
          {editBranding && (
            <Row gutter={20} style={{ marginBottom: 25 }}>
              <Col lg={12} md={24} style={{ marginBottom: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                  <h4 style={{ marginBottom: 10 }}>Business Brand Banner</h4>
                  <Button
                    style={{ height: 30, ...MIDDLE_STYLE }}
                    onClick={() => handleUpdateBrandBanner(undefined)}>
                    <DeleteOutlined /> Remove
                  </Button>
                </div>
                <UploadBrandBannerButton
                  mediaItems={
                    requestTemplate.brand_banner
                      ? [urlToPhotoImageData(0, requestTemplate.brand_banner)]
                      : []
                  }
                  onBannerUploaded={photoImageDataItem => {
                    handleUpdateBrandBanner(photoImageDataItem.url);
                  }}
                />
              </Col>
              <Col lg={12} md={24} style={{ marginBottom: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                  <h4 style={{ marginBottom: 10 }}>Business Brand Logo</h4>
                  <Button
                    style={{ height: 30, ...MIDDLE_STYLE }}
                    onClick={() => handleUpdateBrandLogo(undefined)}>
                    <DeleteOutlined /> Remove
                  </Button>
                </div>
                <UploadBrandLogoButton
                  mediaItems={
                    requestTemplate.brand_logo
                      ? [urlToPhotoImageData(1, requestTemplate.brand_logo)]
                      : []
                  }
                  onLogoUploaded={photoImageDataItem => {
                    handleUpdateBrandLogo(photoImageDataItem.url);
                  }}
                />
              </Col>
            </Row>
          )}
          <Row gutter={20}>
            <Col lg={12} md={24}>
              <h4 style={{ marginBottom: 10 }}>Invitation Email</h4>
              <Form.Item
                name="invitation_email"
                initialValue={requestTemplate.invitation_email}
                rules={[{ required: true, message: 'Missing invitation email content' }]}>
                <Input.TextArea
                  id="invitation_email"
                  autoSize={{ minRows: 5 }}
                  value={requestTemplate.invitation_email}
                  maxLength={500}
                  showCount
                  name="invitation_email"
                  placeholder="Enter invitation email content"
                  onChange={handleRequestTemplateChange}
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={24}>
              <h4 style={{ marginBottom: 10 }}>Follow up (after 7 days of no response)</h4>
              <Form.Item
                name="followup_email"
                initialValue={requestTemplate.followup_email}
                rules={[{ required: true, message: 'Missing followup email content' }]}>
                <Input.TextArea
                  id="followup_email"
                  autoSize={{ minRows: 5 }}
                  value={requestTemplate.followup_email}
                  name="followup_email"
                  maxLength={500}
                  showCount
                  placeholder="Enter follow up email content"
                  onChange={handleRequestTemplateChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col lg={18} md={24}>
              <h4 style={{ marginBottom: 10 }}>Invitation SMS</h4>
              <Form.Item
                name="invitation_sms"
                initialValue={requestTemplate.invitation_sms}
                rules={[{ required: true, message: 'Missing invitation SMS content' }]}>
                <Input.TextArea
                  id="invitation_sms"
                  autoSize={{ minRows: 2 }}
                  value={requestTemplate.invitation_sms}
                  name="invitation_sms"
                  maxLength={200}
                  showCount
                  placeholder="Enter invitation SMS content"
                  onChange={handleRequestTemplateChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col lg={6} md={24}>
              <Space style={{ margin: '0px 0px 20px 0px' }}>
                <Button type="default" onClick={() => setEditRequestTemplate(!editRequestTemplate)}>
                  Cancel
                </Button>
                {(brandBannerUpdated ||
                  brandLogoUpdated ||
                  form.isFieldTouched('invitation_email') ||
                  form.isFieldTouched('followup_email') ||
                  form.isFieldTouched('invitation_sms')) && (
                  <Button className="error-btn" type="default" onClick={handleDiscardChanges}>
                    Discard Changes
                  </Button>
                )}
                &nbsp;&nbsp;
                <Form.Item style={{ margin: 0 }} shouldUpdate>
                  {() => (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isRequestTemplateUpdating}
                      disabled={
                        !(
                          brandBannerUpdated ||
                          brandLogoUpdated ||
                          form.isFieldTouched('invitation_email') ||
                          form.isFieldTouched('followup_email') ||
                          form.isFieldTouched('invitation_sms')
                        ) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                      }>
                      <SaveOutlined /> Save
                    </Button>
                  )}
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <p>[RECIPIENT] will be replaced by the name of the recipient</p>
          <p>For SMS: [REVIEW-URL] will be replaced by the link to your business review page</p>
          <p>For Email: the review button will be added at the end of the email</p>
        </Form>
      )}
    </div>
  );
};

export default RequestTemplateSettingRow;
