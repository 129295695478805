import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { PhotoImageDataItem, StudioReducerProps } from '../type';
import { socialStudioApiService } from '@services/service-register';
import { StudioPhoto } from '@core/models';

export const deleteStudioPhotos = createAsyncThunk(
  'studio/deleteStudioPhotos',
  async ({ studioPhotos }: { studioPhotos: StudioPhoto[] }, thunkApi) => {
    try {
      await socialStudioApiService.removeImagesFromAlbum(
        studioPhotos.map(studioPhoto => studioPhoto.id)
      );
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Successfully remove images from album',
          description: `Remove images from album`,
          type: 'Success',
        } as SnackbarItem)
      );
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to remove images from album',
          description: `Failed to remove images. Please try again`,
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(deleteStudioPhotos);

export const deleteStudioPhotosReducer = {
  [fulfilled]: (state, action: PayloadAction<PhotoImageDataItem[]>) => {
    state.isLoading = false;
  },
  [rejected]: (state, action) => {
    state.isLoading = false;
  },
  [pending]: (state, action) => {
    state.isLoading = true;
  },
} as ExtraReducer<StudioReducerProps>;
