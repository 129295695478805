import React from 'react';
import RequestCreationTable from '@components/RequestCreationTable';
import AnimatedComponent from '@components/AnimatedComponent';

type Props = {};

const RequestCreationScreen = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 100,
      }}>
      <AnimatedComponent.OpacityFadeInDiv delay={300}>
        <RequestCreationTable />
      </AnimatedComponent.OpacityFadeInDiv>
    </div>
  );
};

export default RequestCreationScreen;
