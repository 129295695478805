import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAuthState, useModalState } from '@core/configureStore';
import { closeModal, enqueueNotification, openModal } from '@core/features';
import { Button, Checkbox, Divider, Input, InputNumber, Modal, Space, Tag, Tooltip } from 'antd';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { AssistantProfile } from '@core/models';
import { heroApiService } from '@services/service-register';
import { fetchSelectedLocation } from '@core/features/business/thunks/fetchSelectedLocation.thunk';
import cronstrue from 'cronstrue';
import { CalendarOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BiReset } from 'react-icons/bi';
import { useAppNavigation, useBreakpoint } from '@core/hooks';
import { TONE_OF_VOICES } from '@constants/brand';
import { ExpandableSuggestionContainer } from '@components/index';
import { shortenString } from '@utils/string.util';
import { AppRoutes } from '@utils/route.util';

type Props = {};

const MAX_VISIBLE_TONE_OF_VOICES = 3;

const CustomizeAiProfileModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { navigate } = useAppNavigation();
  const { user } = useAuthState();
  const { isTablet } = useBreakpoint();
  const { customizeAiProfileModal } = useModalState();
  const selectedLocation = useMemo(() => user?.selected_location, [user]);
  const [assistantProfile, setAssistantProfile] = useState<AssistantProfile | undefined>(undefined);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);

  const handleModalClose = () => {
    dispatch(
      closeModal({
        modalName: 'customizeAiProfileModal',
      })
    );
  };

  const handleUpdateProfile = (field: keyof AssistantProfile, value: any) => {
    setAssistantProfile({
      ...assistantProfile,
      [field]: value,
    } as any);
    setUpdated(true);
  };

  const handleDiscard = () => {
    if (selectedLocation)
      setAssistantProfile({
        ...selectedLocation?.assistant_profile,
      });
    setUpdated(false);
  };

  const handleAddNewSchedule = (field: 'schedules' | 'showcase_schedules') => {
    dispatch(
      openModal({
        modalName: 'assistantScheduleSettingModal',
        extraParams: {
          onScheduleAdd: (cronExpressions: string[]) => {
            const addedCronExpressions = [];
            for (const cronExpression of cronExpressions) {
              if (!assistantProfile?.[field].includes(cronExpression))
                addedCronExpressions.push(cronExpression);
            }
            handleUpdateProfile(field, [
              ...(assistantProfile?.[field] || []),
              ...addedCronExpressions,
            ]);
          },
        },
      })
    );
  };

  const handleSaveChanges = async () => {
    if (!assistantProfile) return;
    try {
      setUpdating(true);
      await heroApiService.updateLocationAssistantProfile(assistantProfile);
      await dispatch(fetchSelectedLocation());
      setUpdated(false);
      dispatch(
        enqueueNotification({
          name: 'Successfully update assistant profile',
          description: 'Successfully update assistant profile',
          type: 'Success',
        })
      );
    } catch (e) {
      dispatch(
        enqueueNotification({
          name: 'Failed to update assistant profile',
          description: 'Failed to update assistant profile',
          type: 'Error',
        })
      );
    }
    setUpdating(false);
  };

  useEffect(() => {
    setAssistantProfile(selectedLocation?.assistant_profile);
  }, [selectedLocation, customizeAiProfileModal]);

  return (
    <Modal
      width={isTablet ? '100%' : 700}
      title={'Customize Setting'}
      open={customizeAiProfileModal}
      footer={
        updated
          ? [
              <Button onClick={handleModalClose}>Cancel</Button>,
              <Button loading={updating} danger onClick={handleDiscard}>
                <BiReset /> Discard
              </Button>,
              <Button
                loading={updating}
                type={'primary'}
                className={'success-btn'}
                onClick={handleSaveChanges}>
                Save changes
              </Button>,
            ]
          : [
              <Button onClick={handleModalClose}>Cancel</Button>,
              <Button loading={updating} danger onClick={handleDiscard}>
                <BiReset /> Discard
              </Button>,
            ]
      }
      onCancel={handleModalClose}
      closable>
      <Divider />
      {assistantProfile && (
        <Space size={'large'} direction={'vertical'} style={{ width: '100%' }}>
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <div>Business Description</div>
              <Button
                onClick={() => {
                  navigate<AppRoutes>('settings');
                  handleModalClose();
                }}>
                <EditOutlined />{' '}
                {selectedLocation?.business_description ? 'Edit description' : 'Add description'}
              </Button>
            </div>
            {selectedLocation?.business_description && (
              <Tooltip title={selectedLocation?.business_description}>
                <p style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                  {shortenString(selectedLocation?.business_description || '', 200)}
                </p>
              </Tooltip>
            )}
          </React.Fragment>
          <Space
            style={{
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              width: '100%',
            }}>
            <div>
              Schedule
              <br />
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                How often a social post is scheduled
              </span>
            </div>
            <Button style={{ width: '100%' }} onClick={() => handleAddNewSchedule('schedules')}>
              <CalendarOutlined /> Add new schedule
            </Button>
          </Space>
          <Space style={{ marginTop: 10, flexWrap: 'wrap' }}>
            {assistantProfile.schedules.map((schedule, index) => (
              <Tag color={'blue'}>
                {cronstrue.toString(schedule)}
                <DeleteOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    handleUpdateProfile(
                      'schedules',
                      assistantProfile?.schedules.filter((_, i) => i + 1 !== index + 1)
                    )
                  }
                />
              </Tag>
            ))}
          </Space>
          <Space
            style={{
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              width: '100%',
            }}>
            <div>
              Showcase Schedule
              <br />
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                How often a showcase review post is scheduled
              </span>
            </div>
            <Button
              style={{ width: '100%' }}
              onClick={() => handleAddNewSchedule('showcase_schedules')}>
              <CalendarOutlined /> Add new schedule
            </Button>
          </Space>
          <Space style={{ marginTop: 10, flexWrap: 'wrap' }}>
            {assistantProfile?.showcase_schedules.map((schedule, index) => (
              <Tag color={'blue'}>
                {cronstrue.toString(schedule)}
                <DeleteOutlined
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    handleUpdateProfile(
                      'showcase_schedules',
                      assistantProfile?.showcase_schedules.filter((_, i) => i + 1 !== index + 1)
                    )
                  }
                />
              </Tag>
            ))}
          </Space>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Tone of voice <br />
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Select a voice of your business AI assistant
              </span>
            </div>
            <Input
              onChange={e => handleUpdateProfile('tone_of_voice', e.target.value)}
              placeholder={'Enter AI tone of voice'}
              value={assistantProfile.tone_of_voice}
            />
          </Space>
          <div
            style={{
              backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
              padding: '10px 10px',
              borderRadius: 10,
            }}>
            <ExpandableSuggestionContainer
              limit={MAX_VISIBLE_TONE_OF_VOICES}
              topics={TONE_OF_VOICES.map(toneOfVoice => toneOfVoice.tone)}
              onTopicClicked={tone_of_voice => handleUpdateProfile('tone_of_voice', tone_of_voice)}
            />
          </div>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Content character limit <br />{' '}
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Social post content will be generated within {assistantProfile.content_word_limit}{' '}
                characters.
              </span>
            </div>
            <InputNumber
              onChange={value => handleUpdateProfile('content_word_limit', value || 0)}
              placeholder={'Set limit'}
              width={'100%'}
              min={50}
              max={1000}
              value={assistantProfile.content_word_limit}
            />
          </Space>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Auto Suggest HashTags
              <br />{' '}
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Include auto suggest hashtags for each post
              </span>
            </div>
            <Checkbox
              onChange={e => handleUpdateProfile('autoSocialSuggestHashtag', e.target.checked)}
              checked={assistantProfile.autoSocialSuggestHashtag}
            />
          </Space>
          <Space style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div>
              Number of Images
              <br />{' '}
              <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color }}>
                Number of images included in the auto generated social post
              </span>
            </div>
            <Space>
              From
              <InputNumber
                style={{ width: 50 }}
                onChange={value => handleUpdateProfile('min_number_of_media', value)}
                min={1}
                max={assistantProfile.max_number_of_media}
                value={assistantProfile.min_number_of_media}
              />
              to
              <InputNumber
                style={{ width: 50 }}
                onChange={value => handleUpdateProfile('max_number_of_media', value)}
                max={5}
                min={assistantProfile.min_number_of_media}
                value={assistantProfile.max_number_of_media}
              />
            </Space>
          </Space>
          <Divider />
        </Space>
      )}
    </Modal>
  );
};

export default CustomizeAiProfileModal;
