import React, { useEffect, useMemo, useState } from 'react';
import { BlogPostListView, MonthHorizontalSelector } from '..';
import { Button, Divider, Select, Space } from 'antd';
import { getUpcomingXMonths } from '@utils/string.util';
import { useAppNavigation, useBreakpoint } from '@core/hooks';
import { useAppDispatch, useAuthState, useBlogState } from '@core/configureStore';
import { formatUtcTimestamp, getDefaultMonthKey, groupObjectArrayByMonth } from '@utils/date.util';
import {
  BackgroundEvent,
  BlogGenerateBackgroundEvent,
  BlogPostModel,
  BlogPostStage,
  LocalLocation,
} from '@core/models';
import moment from 'moment';
import { MONTH_KEY_DATE_FORMAT } from '@constants/social';
import { EditOutlined, GlobalOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { openModal } from '@core/features';
import {
  backgroundEventApiService,
  blogApiService,
  heroApiService,
} from '@services/service-register';
import { buildBlogSiteUrl } from '@utils/blog.util';
import { cloneArray } from '@utils/array.util';

type Props = {};

const BlogPostTab = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const { blogPosts } = useBlogState();
  const { user } = useAuthState();
  const [isDeployingSite, setIsDeployingSite] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(getDefaultMonthKey());
  const [backgroundEvents, setBackgroundEvents] = useState<
    BackgroundEvent<BlogGenerateBackgroundEvent>[]
  >([]);
  const [backgroundEventLoading, setBackgroundEventLoading] = useState<boolean>(true);

  const publishedBlogPosts = useMemo(
    () => blogPosts.filter(blogPost => blogPost.status === BlogPostStage.Published),
    [blogPosts]
  );
  const upcomingMonths = useMemo(() => getUpcomingXMonths(isTablet ? 3 : 6), [isTablet]);
  const [filteredBy, setFilteredBy] = useState<{
    value: BlogPostStage | string;
  }>({
    value: 'ALL',
  });

  const filteredBlogPosts = useMemo(() => {
    const showAll = (blogPost: BlogPostModel) =>
      filteredBy.value === 'ALL' && blogPost.status !== BlogPostStage.Draft;

    const showDraftedOrApprovalRequired = (blogPost: BlogPostModel) =>
      filteredBy.value === BlogPostStage.Draft &&
      (blogPost.status === BlogPostStage.Draft ||
        blogPost.status === BlogPostStage.ApprovalRequested);

    const showPendingBackgroundEvent = (blogPost: BlogPostModel) => {
      return blogPost.isBackgroundEvent && filteredBy.value === BlogPostStage.Scheduled;
    };

    return blogPosts.filter(
      blogPost =>
        showAll(blogPost) ||
        showPendingBackgroundEvent(blogPost) ||
        showDraftedOrApprovalRequired(blogPost) ||
        blogPost.status === filteredBy.value
    );
  }, [blogPosts, filteredBy]);

  const monthFilteredBlogPosts = useMemo(
    () =>
      !selectedMonth || filteredBy.value === 'ALL'
        ? filteredBlogPosts
        : cloneArray(filteredBlogPosts).filter(
            blogPost => moment(blogPost.publish_at).format(MONTH_KEY_DATE_FORMAT) === selectedMonth
          ),
    [filteredBlogPosts, selectedMonth, filteredBy.value]
  );

  const sortedBlogPosts = useMemo(
    () =>
      monthFilteredBlogPosts.length > 0
        ? monthFilteredBlogPosts.sort((postA, postB) =>
            filteredBy.value === BlogPostStage.Scheduled
              ? formatUtcTimestamp(postB.publish_at) - formatUtcTimestamp(postA.publish_at)
              : formatUtcTimestamp(postA.updated_date) - formatUtcTimestamp(postB.updated_date)
          )
        : filteredBlogPosts,
    [filteredBy, monthFilteredBlogPosts, filteredBlogPosts]
  );

  const blogPostGroupedByMonth = useMemo(
    () => groupObjectArrayByMonth<BlogPostModel>(filteredBlogPosts, 'publish_at'),
    [filteredBlogPosts]
  );

  const handleNewBlogPost = () => {
    navigate<string>('blogs/new');
  };

  const handleCustomizeAiProfile = () => {
    dispatch(
      openModal({
        modalName: 'customizeBlogAiProfileModal',
      })
    );
  };

  const handleViewBlogSite = async () => {
    if (!user?.selected_location) return;
    const result: LocalLocation = await heroApiService.getSelectedLocation();
    window.open(buildBlogSiteUrl(result.slug));
  };

  const handleViewPendingBlogPosts = () => {
    setFilteredBy({
      value: BlogPostStage.Pending,
    });
  };

  useEffect(() => {
    const fetchBackgroundEvents = async () => {
      setBackgroundEventLoading(true);
      try {
        const _backgroundEvents = await backgroundEventApiService.getBlogGenerateEvents();
        setBackgroundEvents(_backgroundEvents);
      } catch (error) {
        console.log(error);
      }
      setBackgroundEventLoading(false);
    };
    fetchBackgroundEvents();
  }, []);

  useEffect(() => {
    const init = async () => {
      const isDeploying = await blogApiService.isDeploying();
      setIsDeployingSite(isDeploying);
    };
    init();
  }, []);

  return (
    <div>
      <Space
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          WebkitFlexWrap: 'wrap',
          marginBottom: 20,
        }}>
        <Space style={{ flexWrap: 'wrap' }}>
          {backgroundEvents.length > 0 && !backgroundEventLoading && (
            <Button onClick={handleViewPendingBlogPosts} style={{ borderRadius: 20 }}>
              <LoadingOutlined />
              Generating {backgroundEvents.length} blog posts{' '}
            </Button>
          )}
          <Select
            style={{ width: isTablet ? '80vw' : '200px' }}
            value={JSON.stringify(filteredBy)}
            onChange={value => setFilteredBy(JSON.parse(value))}
            options={[
              {
                label: 'ALL',
                value: JSON.stringify({
                  value: 'ALL',
                }),
              },
              {
                label: 'Draft',
                value: JSON.stringify({
                  value: BlogPostStage.Draft,
                }),
              },
              {
                label: 'Pending',
                value: JSON.stringify({
                  value: BlogPostStage.Pending,
                }),
              },
              {
                label: 'Scheduled',
                value: JSON.stringify({
                  value: BlogPostStage.Scheduled,
                }),
              },
              {
                label: 'Published',
                value: JSON.stringify({
                  value: BlogPostStage.Published,
                }),
              },
            ]}
          />
        </Space>
        <Space style={{ flexWrap: 'wrap' }}>
          <Button
            id={'customize-blog-ai-profile'}
            style={{ width: isTablet ? '80vw' : 'fit-content' }}
            onClick={handleCustomizeAiProfile}>
            <EditOutlined />
            Customize Setting
          </Button>
          {publishedBlogPosts.length > 0 && (
            <Button
              style={{ width: isTablet ? '80vw' : 'fit-content' }}
              onClick={handleViewBlogSite}>
              <GlobalOutlined />
              Visit blog site {isDeployingSite && <LoadingOutlined style={{ marginLeft: 10 }} />}
            </Button>
          )}
          <Button style={{ width: '100%' }} onClick={handleNewBlogPost} type={'primary'}>
            <PlusOutlined /> New blog post
          </Button>
        </Space>
      </Space>
      <Divider />
      {filteredBy.value !== 'ALL' && (
        <MonthHorizontalSelector
          label="blog posts"
          records={blogPostGroupedByMonth}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          upcomingMonths={upcomingMonths}
        />
      )}
      <BlogPostListView
        blogPosts={sortedBlogPosts}
        stage={filteredBy.value}
        selectedMonth={selectedMonth}
      />
    </div>
  );
};

export default BlogPostTab;
