import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { DateRange } from '@utils/date.util';
import { Moment } from 'moment';
import { GoogleReview, Review } from '../../models';
import { SliceName } from '../../name';
import {
  fetchReviewsReducer,
  generateReviewsReducer,
  respondReviewReducer,
  generateInquiryReducer,
  fetchReviewsSilentReducer,
} from './thunks';
import { ReviewFilterStatus, ReviewReducerProps, ReviewSortOrder } from './type';
import { deduplicateArrayByField } from '@utils/array.util';

const reviewSlice = createSlice<ReviewReducerProps, SliceCaseReducers<ReviewReducerProps>, string>({
  name: SliceName.Navigation,
  initialState: {
    isLoading: false,
    isGenerating: false,
    isReviewLoading: false,
    isReviewRefreshing: undefined,
    review: undefined,
    isSelected: false,
    error: undefined,
    dateRange: null,
    filterStatus: ReviewFilterStatus.All,
    sortBy: 'date',
    startDate: null,
    sortOrder: ReviewSortOrder.DESC,

    reviewResponse: { reviews: [], averageRating: 0, totalReviewCount: 0 },
  },
  reducers: {
    addReviews(state, action: PayloadAction<GoogleReview[]>) {
      const addedReviews = deduplicateArrayByField(
        state.reviewResponse.reviews.concat(action.payload),
        'reviewId'
      );
      state.reviewResponse.reviews = addedReviews;
      state.reviewResponse.totalReviewCount = addedReviews.length;
      return state;
    },
    setReviews(state, action: PayloadAction<GoogleReview[]>) {
      state.reviewResponse.reviews = action.payload;
      state.reviewResponse.totalReviewCount = action.payload.length;
      return state;
    },
    resetReviewRefreshing(state, _) {
      state.isReviewRefreshing = undefined;
      return state;
    },
    setStartDate(state, action) {
      state.startDate = action.payload;
      return state;
    },
    selectDateRange: (state, action: PayloadAction<DateRange<Moment>>) => {
      state.dateRange = action.payload;
      return state;
    },
    sortOrdersChanged: (state, action: PayloadAction<ReviewSortOrder>) => {
      state.sortOrder = action.payload;
      state.filterStatus = ReviewFilterStatus.All;
      return state;
    },
    sortReviewBy: (state, action: PayloadAction<'date' | 'rating' | 'name'>) => {
      state.sortBy = action.payload;
      return state;
    },
    filterReviewStatus: (state, action: PayloadAction<ReviewFilterStatus>) => {
      state.filterStatus = action.payload;
      return state;
    },
    selectReview: (state, action: PayloadAction<Review>) => {
      return {
        ...state,
        isSelected: true,
        review: action.payload,
      };
    },
    deselectReview: (state, _) => {
      return {
        ...state,
        isSelected: false,
        isLoading: false,
        review: null,
      };
    },
    editUnfinishedResponse: (
      state,
      action: PayloadAction<{
        message: string;
      }>
    ) => {
      if (state.review) {
        state.review.generated_response = action.payload.message;
      }
      return state;
    },
  },
  extraReducers: {
    ...fetchReviewsReducer,
    ...fetchReviewsSilentReducer,
    ...respondReviewReducer,
    ...generateReviewsReducer,
    ...generateInquiryReducer,
  },
});

export const {
  resetReviewRefreshing,
  selectDateRange,
  selectReview,
  editUnfinishedResponse,
  deselectReview,
  filterReviewStatus,
  sortReviewBy,
  sortOrdersChanged,
  setStartDate,
  addReviews,
  setReviews,
} = reviewSlice.actions;
export const reviewReducer = reviewSlice.reducer;
