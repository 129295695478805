import BlogPostsScreenTourRegister from '@pages/blogs/BlogPostsScreenTourRegister';
import React from 'react';

type Props = {};

const JoyrideContainer = (props: Props) => {
  return (
    <React.Fragment>
      <BlogPostsScreenTourRegister />
    </React.Fragment>
  );
};

export default JoyrideContainer;
