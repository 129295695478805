import SelectBusinessButton from '@components/SelectBusinessButton';
import React from 'react';

type Props = {};

const NoBusinessFound = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '80vh',
        width: '100%',
        flexDirection: 'column',
      }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: 'fit-content', textAlign: 'center' }}>
          <img src="/no-business-found.svg" alt="No Business Found" width={'30%'} />
          <h1 style={{ marginTop: 20, fontSize: 25 }}>No Business Is Selected</h1>
          <p>Click the button to select your business</p>
          <div style={{ marginTop: 30 }}>
            <SelectBusinessButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoBusinessFound;
