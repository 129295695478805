/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import CardSectionHeader from '@components/CardSectionHeader';
import { useReviewState } from '@core/configureStore';
import { StarRating } from '@core/models';
import { Card } from 'antd';
import React, { useMemo } from 'react';

type Props = {};

const PositiveReviewWidget = (props: Props) => {
  const { reviewResponse, isLoading: isLoadingReview } = useReviewState();
  const goodRatingReviews: number[] = useMemo(
    () =>
      (reviewResponse.reviews || [])
        .map(review => parseInt(StarRating[review?.starRating.valueOf()]) as number)
        .filter(rating => rating >= 3) || [],
    [reviewResponse]
  );
  return (
    <Card className="no-padding" style={{ padding: '0px', borderRadius: 10 }}>
      <div style={{ padding: '15px 15px' }}>
        <CardSectionHeader title="Positive Reviews" />
        {isLoadingReview ? (
          <LoadingOutlined />
        ) : (
          <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
            <h3 style={{ fontSize: 14, margin: 0 }}>{goodRatingReviews.length || 0}</h3>
            <p>3 to 5 stars reviews</p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default PositiveReviewWidget;
