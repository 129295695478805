import { SocialPost } from '@core/models';
import React from 'react';
import { Col, Row } from 'antd';
import { SocialPostImageGrid } from '@components/index';
import { MIDDLE_STYLE } from '@constants/responsive';
import { shortenString } from '@utils/string.util';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { openModal } from '@core/features';
import { useAppDispatch } from '@core/configureStore';
import moment from 'moment/moment';
import { useBreakpoint } from '@core/hooks';
import StatusBadge from '@components/StatusBadge';

interface Props {
  socialPost: SocialPost;
  locationId: string;
}

const SocialPostListItem: React.FC<Props> = ({ socialPost, locationId }) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const handleItemClicked = async (socialPost: SocialPost) => {
    dispatch(
      openModal({
        modalName: 'socialPostModal',
        extraParams: {
          socialPost,
          locationId,
        },
      })
    );
  };

  return (
    <div
      onClick={async e => {
        e.stopPropagation();
        await handleItemClicked(socialPost);
      }}
      className={'post-list-item'}
      style={{
        width: '100%',
        padding: isTablet ? '10px 0px 10px 20px' : `5px 0px`,
        borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
        cursor: 'pointer',
      }}>
      <Row style={{ ...MIDDLE_STYLE }}>
        <Col span={isTablet ? 6 : 2} style={{ ...MIDDLE_STYLE }}>
          <SocialPostImageGrid width={80} mediaIds={socialPost.mediaIds || []} hideOverlay />
        </Col>
        <Col span={isTablet ? 7 : 3} style={{ ...MIDDLE_STYLE }}>
          <StatusBadge status={socialPost.status} />
        </Col>
        <Col span={isTablet ? 24 : 19}>
          <div>
            {socialPost.publish_at && (
              <span style={{ fontSize: 'smaller', fontWeight: 500 }}>
                {moment(socialPost.publish_at).format('MMMM, DD - HH:mm A')}
              </span>
            )}
          </div>
          <span style={{ color: GLOBAL_THEME_COLOR.$dark_text_color, fontSize: 13 }}>
            {shortenString(socialPost.content, isTablet ? 50 : 200)}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default SocialPostListItem;
