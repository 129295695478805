import { GLOBAL_THEME_COLOR } from '@constants/theme';
import {
  BlogPostStage,
  LocalLocation,
  SocialPostStage,
  SquareProductItemCatalogObject,
  StudioPhoto,
  StudioPhotoSource,
} from '@core/models';
import { formatUtcTimestamp } from './date.util';
import _ from 'lodash';
import { CheckCircleOutlined } from '@ant-design/icons';

/**
 * {
    "name": "New Year's Day",
    "name_local": "",
    "language": "",
    "description": "",
    "country": "AU",
    "location": "Australia",
    "type": "National",
    "date": "01/01/2020",
    "date_year": "2020",
    "date_month": "01",
    "date_day": "01",
    "week_day": "Wednesday"
   }
 */
export type SpecialCalendarEvent = {
  name: string;
  name_local: string;
  language: string;
  description: string;
  country: string;
  location: string;
  type: string;
  date: string;
  date_year: string;
  date_month: string;
  date_day: string;
  week_day: string;
};

export type ConfiguredEvent = SpecialCalendarEvent & {
  scheduledBefore?: number;
  numberOfPosts?: number;
};

export enum StudioPostStage {
  Failed = 'Failed',
  Published = 'Published',
  Scheduled = 'Scheduled',
  Draft = 'Draft',
}

export enum CalendarItemType {
  RecurringPost,
  OnceTimeOff,
}

export enum CalendarEventSourceType {
  SocialPost,
}

export type CalendarItem<T = any> = {
  name: string;
  description: string;
  type: CalendarItemType;
  date: string;
  date_year: number;
  date_month: number;
  date_day: number;
  source_type: CalendarEventSourceType;
  social_post_id: number | undefined;
  extra?: T;
};

export enum RecurringType {
  Week = 'Weeks',
  Day = 'Days',
  Month = 'Months',
}
export const renderStatusColor = (socialPostStatus: SocialPostStage | BlogPostStage) => {
  switch (socialPostStatus) {
    case BlogPostStage.Pending:
    case SocialPostStage.Pending:
      return GLOBAL_THEME_COLOR.$processing_color;
    case BlogPostStage.Published:
    case SocialPostStage.Published:
      return GLOBAL_THEME_COLOR.$success_color;
    case BlogPostStage.Scheduled:
    case SocialPostStage.Scheduled:
      return GLOBAL_THEME_COLOR.$processing_color;
    default:
      return GLOBAL_THEME_COLOR.$dark_text_color;
  }
};

export const renderStatusStr = (socialPostStatus: SocialPostStage | BlogPostStage) => {
  if (socialPostStatus === BlogPostStage.ApprovalRequested) return 'Drafted';
  return ['Drafted', 'Pending', 'Scheduled', 'Queued', 'Published'][socialPostStatus];
};

export const renderStatusIcon = (socialPostStatus: SocialPostStage | BlogPostStage): any => {
  return [undefined, undefined, , undefined, CheckCircleOutlined][socialPostStatus];
};

export const countSquareProductUrls = (productCatalogObjects: SquareProductItemCatalogObject[]) => {
  let totalImageUrlCount = 0;
  for (const productCatalogObject of productCatalogObjects) {
    const collectedImageUrls = productCatalogObject.imageUrls;
    totalImageUrlCount += collectedImageUrls.length;
  }
  return totalImageUrlCount;
};

export const sortStudioPhotoByAccessedAt =
  (order: 'oldestToNewest' | 'newestToOldest') => (photoA: StudioPhoto, photoB: StudioPhoto) =>
    (formatUtcTimestamp(photoB.accessed_at || new Date().toString()) -
      formatUtcTimestamp(photoA.accessed_at || new Date().toString())) *
    (order === 'oldestToNewest' ? -1 : 1);

export const photoIsInGallery = (source: StudioPhotoSource) => {
  return source === StudioPhotoSource.Image || source === StudioPhotoSource.SquareProductPhoto;
};

export const getGenerationQueuedImages = (studioPhotos: StudioPhoto[]) => {
  const imageStudioPhotos = studioPhotos.filter(photo => photoIsInGallery(photo.source));

  const notAccessedPhotos = imageStudioPhotos.filter(photo => !photo.accessed_at);
  const accessedInOrderPhotos = imageStudioPhotos
    .filter(photo => photo.accessed_at)
    .sort(sortStudioPhotoByAccessedAt('oldestToNewest'));

  const studioPhotoQueue = [...notAccessedPhotos, ...accessedInOrderPhotos];
  return studioPhotoQueue;
};

export const getMediaCountInRange = (location: LocalLocation, random?: boolean) => {
  const minNumberOfMedia = location.assistant_profile.min_number_of_media;
  const maxNumberOfMedia = location.assistant_profile.max_number_of_media;
  if (random) {
    const randomNumberOfMedia = _.random(minNumberOfMedia, maxNumberOfMedia, false);
    return [minNumberOfMedia, maxNumberOfMedia, randomNumberOfMedia];
  }
  return [minNumberOfMedia, maxNumberOfMedia];
};
