import { SmileFilled } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { useBreakpoint, useGenerationDataStore } from '@core/hooks';
import { Button } from 'antd';
import React from 'react';
import { HiShieldCheck } from 'react-icons/hi2';
import { TbClick, TbMoodSadFilled } from 'react-icons/tb';
import QRCode from 'react-qr-code';

type Props = {
  version: number;
};

const PitchQrCodeCard = ({ version }: Props) => {
  const { isMobile } = useBreakpoint();
  const { businessData } = useGenerationDataStore();
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: 'white',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px 20px',
          borderRadius: 20,
          boxShadow: STRIPE_BOX_SHADOW,
        }}>
        {version === 1 && (
          <div
            style={{
              maxWidth: 450,
              padding: '15px 20px',
              marginBottom: 20,
              borderRadius: 20,
              background: GLOBAL_THEME_COLOR.$highlight_color,
              color: GLOBAL_THEME_COLOR.$text_color_contrast,
              ...MIDDLE_STYLE,
              flexDirection: isMobile ? 'column' : 'row',
            }}>
            <HiShieldCheck
              style={{
                marginRight: isMobile ? 0 : 20,
                marginBottom: isMobile ? 20 : 0,
                fontSize: '40px',
              }}
            />
            <div>
              <h2 className="airbnb-font" style={{ fontSize: 18 }}>
                SMART QR CODE THAT <br /> SHIELDS NEGATIVE REVIEWS
              </h2>
              <div style={{ display: 'block' }}>
                <div style={{ textAlign: 'left' }}>
                  <SmileFilled style={{ color: 'yellow', fontSize: 16, marginRight: 10 }} /> Happy
                  customers will publish reviews on Google.
                </div>
                <div style={{ textAlign: 'left' }}>
                  <TbMoodSadFilled
                    style={{
                      color: GLOBAL_THEME_COLOR.$error_color,
                      fontSize: 20,
                      marginRight: 5,
                      marginLeft: 0,
                      marginTop: 5,
                    }}
                  />{' '}
                  Unhappy customers will send feedback privately.
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            padding: '10px 20px',
            boxShadow: STRIPE_BOX_SHADOW,
            borderRadius: 20,
            maxWidth: 400,
            border: `1.5px solid ${GLOBAL_THEME_COLOR.$border_color}`,
            ...MIDDLE_STYLE,
            flexDirection: 'column',
            backgroundColor: 'white',
          }}>
          <div style={{ textAlign: 'center' }}>
            <h1 className="airbnb-font">{businessData.name}</h1>
            <h3 style={{ fontWeight: 'normal' }}>{businessData.formatted_address}</h3>
          </div>
          <br />
          <Button
            className="success-btn"
            style={{
              fontSize: '20px',
              ...MIDDLE_STYLE,
              padding: '20px',
            }}
            onClick={() => {
              if (version === 1) {
                window.open(`https://gogl.page/#/qr/${businessData.place_id}`);
              }
              if (version === 2) {
                window.open(`https://gogl.page/#/qr-v2/${businessData.place_id}`);
              }
            }}
            type="primary">
            <TbClick /> Preview QR Code
          </Button>
          <br />
          <div
            style={{
              background: "url('images/qrcode-background.jpg')",
              padding: '30px',
              borderRadius: '10px',
              maxWidth: 400,
              ...MIDDLE_STYLE,
            }}>
            <div
              style={{
                padding: '20px',
                boxShadow: STRIPE_BOX_SHADOW,
                borderRadius: 15,
                backgroundColor: 'white',
                height: 'fit-content',
                width: 'fit-content',
                ...MIDDLE_STYLE,
              }}>
              <QRCode
                style={{ width: isMobile ? 150 : 200, height: isMobile ? 150 : 200 }}
                value={`https://gogl.page/#/qr/${businessData.place_id}`}
              />
            </div>
          </div>
          <div style={{ maxWidth: 400, textAlign: 'center', marginTop: 25, marginBottom: 15 }}>
            <h1 className="airbnb-font" style={{ fontSize: 20, ...MIDDLE_STYLE }}>
              Write us a Review on
              <img height={35} style={{ marginLeft: 10 }} src="social-logo/google_logo_full.png" />
            </h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PitchQrCodeCard;
