/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useDrawerState } from '@core/configureStore';
import { closeDrawer } from '@core/features';
import { Button, Empty, Skeleton } from 'antd';
import { LoadableContainer } from '..';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import BrowsePhotoDrawerLayout from '@components/BrowsePhotoDrawerLayout';
import { heroApiService, socialStudioApiService } from '@services/service-register';
import { makeid } from '@utils/string.util';
import { SquarePhotoImageData } from '@core/models';
import { AnnotatedImageItemCard } from '@components/AnnotatedImageItemCard';

type Props = {
  singleFile?: boolean;
  onLoad: (pics: any[]) => Promise<void>;
};

const BrowseSquareIntegrationPhotoDrawer = ({ singleFile, onLoad }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [photos, setPhotos] = useState<SquarePhotoImageData[]>([]);
  const dispatch = useAppDispatch();
  const { squareIntegrationPhotoBrowseDrawer } = useDrawerState();
  const [selectedPics, setSelectedPics] = useState<Record<string, boolean>>({});

  const handleOnClose = () => {
    dispatch(
      closeDrawer({
        drawerName: 'squareIntegrationPhotoBrowseDrawer',
      })
    );
  };

  const handleUpload = () => {
    onLoad(photos.filter(pic => selectedPics[pic.id]));
    handleOnClose();
  };

  const handleConnect = async () => {
    const url = await heroApiService.squareOauthLogin();
    window.open(url);
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const productCatalogObjects = await socialStudioApiService.listSquareProductObjects();
        const _photos: SquarePhotoImageData[] = productCatalogObjects
          .map(catalogObject => {
            const imageUrls = catalogObject.imageUrls;
            return imageUrls.map(url => ({
              url,
              description: `${catalogObject.name} - ${catalogObject.description}`,
              id: makeid(5),
            }));
          })
          .flat();
        setPhotos(_photos);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (squareIntegrationPhotoBrowseDrawer) {
      init();
    }
  }, [squareIntegrationPhotoBrowseDrawer]);

  return (
    <BrowsePhotoDrawerLayout
      title={'Photos from Square'}
      handleUpload={handleUpload}
      handleOnClose={handleOnClose}
      open={squareIntegrationPhotoBrowseDrawer}>
      <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
        <LoadableContainer
          isLoading={photos.length === 0}
          loadComponent={
            <div style={{ ...MIDDLE_STYLE, flexDirection: 'column' }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No Square photos found</span>}
              />
              <Button loading={loading} onClick={handleConnect} style={{ ...MIDDLE_STYLE }}>
                <img
                  alt="Square"
                  src="square-inc.png"
                  width={25}
                  height={25}
                  style={{ marginRight: 10 }}
                />{' '}
                Reconnect to Square
              </Button>
            </div>
          }>
          <div className="card-list">
            {photos.map(photo => (
              <div
                onClick={() =>
                  setSelectedPics(
                    singleFile
                      ? {
                          [photo.id]: !selectedPics[photo.id],
                        }
                      : {
                          ...selectedPics,
                          [photo.id]: !selectedPics[photo.id],
                        }
                  )
                }
                className="card"
                key={photo.id}
                style={{
                  borderRadius: 5,
                  padding: selectedPics[photo.id] ? '5px' : '0px',
                  cursor: 'pointer',
                  ...(selectedPics[photo.id]
                    ? { border: `6px solid ${GLOBAL_THEME_COLOR.$highlight_color}` }
                    : {}),
                }}>
                <AnnotatedImageItemCard
                  key={photo.id}
                  description={photo.description}
                  url={photo.url}
                />
              </div>
            ))}
          </div>
        </LoadableContainer>
      </LoadableContainer>
    </BrowsePhotoDrawerLayout>
  );
};

export default BrowseSquareIntegrationPhotoDrawer;
