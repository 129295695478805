import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { StudioReducerProps } from '../type';
import { socialStudioApiService } from '@services/service-register';
import { StudioPhoto } from '@core/models';
import { updateStudioPhotoById } from '@core/features';

export const updateStudioPhoto = createAsyncThunk(
  'studio/updateStudioPhoto',
  async (
    { mediaId, labels, description }: { mediaId: string; labels: string[]; description: string },
    thunkApi
  ) => {
    try {
      const updatedStudioPhoto = await socialStudioApiService.updateStudioPhoto(
        mediaId,
        labels,
        description
      );
      if (updatedStudioPhoto)
        thunkApi.dispatch(
          updateStudioPhotoById({
            id: updatedStudioPhoto.id,
            data: updatedStudioPhoto,
          })
        );
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Successfully update studio photo',
          description: `Updated studio photo ${mediaId}`,
          type: 'Success',
        } as SnackbarItem)
      );
      return updatedStudioPhoto;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to update studio photo',
          description: `Failed to update photo. Please try again`,
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(updateStudioPhoto);

export const updateStudioPhotoReducer = {
  [fulfilled]: (state, action: PayloadAction<StudioPhoto>) => {
    state.isPhotoUpdating = false;
  },
  [rejected]: (state, action) => {
    state.isPhotoUpdating = false;
  },
  [pending]: (state, action) => {
    state.isPhotoUpdating = true;
  },
} as ExtraReducer<StudioReducerProps>;
