import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { TIMEZONES } from '@constants/timezones';

type Props = {
  timezone: string;
  handleTimezoneChange: any;
  handleUpdateTimezone: any;
};
const TimezoneSettingRow = ({ timezone, handleTimezoneChange, handleUpdateTimezone }: Props) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  return (
    <Form form={form} name="timezone_form" onFinish={handleUpdateTimezone}>
      <Row gutter={5}>
        <Col lg={6} md={24}>
          <Form.Item name="timezone" initialValue={timezone}>
            <Select
              showSearch={true}
              value={timezone}
              style={{ width: '100%' }}
              onChange={value => handleTimezoneChange(value)}
              options={TIMEZONES}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col lg={6} md={24}>
          <Form.Item shouldUpdate>
            {() => (
              <Button type="primary" htmlType="submit" disabled={!form.isFieldTouched('timezone')}>
                Save
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TimezoneSettingRow;
