import { useAppDispatch, useInquiryState, useReviewState } from '@core/configureStore';
import React, { useEffect, useState } from 'react';
import { fetchReviews } from '@core/features/review/thunks';
import { Button, Col, Divider, Row, Skeleton, Space } from 'antd';
import ReviewTable from '@components/ReviewTable';
import ReviewFilterButton from '@components/ReviewFilterButton';
import ReviewSortByButton from '@components/ReviewSortbyButton';
import ReviewOrderbyButton from '@components/ReviewOrderbyButton';
import { ReloadOutlined, StarOutlined } from '@ant-design/icons';
import FlexDatePicker from '@components/FlexDatePicker';
import moment, { Moment } from 'moment';
import { selectDateRange, setReviews } from '@core/features';
import { GoogleReview } from '@core/models';
import LoadableContainer from '@components/LoadableContainer';
import SplashScreen from '@components/SplashScreen';
import AnimatedComponent from '@components/AnimatedComponent';
import NumberOfReviewsWidget from '@components/NumberOfReviewsWidget';
import AverageRatingWidget from '@components/AverageRatingWidget';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import BusinessReviewCard from '@components/BusinessReviewCard';
import { starRatingToNumber } from '@utils/type.util';
import { formatUtcTimestamp, isDateInBetweenRange } from '@utils/date.util';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useAppNavigation } from '@core/hooks';
import CustomizeAiResponderButton from '@components/CustomizeAiResponderButton';
import PositiveReviewWidget from '@components/PositiveReviewWidget';
import NegativeReviewWidget from '@components/NegativeReviewWidget';

const { RangePicker } = FlexDatePicker;

const ReviewScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { navigate } = useAppNavigation();
  const { isLoading: isLoadingInquiry } = useInquiryState();
  const { reviewResponse, dateRange, isLoading: isLoadingReview } = useReviewState();
  const [isFilteringReviews, setIsFilteringReviews] = useState<boolean>(false);
  const [rangedReviews, setRangedReviews] = useState<GoogleReview[]>([]);

  const handleRefresh = async () => {
    await dispatch(setReviews([]));
    await dispatch(fetchReviews());
  };

  const handleCalendarChange = (value: Moment[]) => {
    const [startDate, endDate] = value;
    dispatch(
      selectDateRange({
        startDate,
        endDate,
      })
    );
  };

  const handleReset = async () => {
    dispatch(selectDateRange(undefined));
    await handleRefresh();
  };

  const handleCollectReviews = () => {
    navigate('generate-reviews');
  };

  useEffect(() => {
    let localReviews: GoogleReview[] = reviewResponse?.reviews || [];
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      setIsFilteringReviews(true);
      const filteredByRangeReviews = localReviews.filter(review => {
        return isDateInBetweenRange(
          dateRange.startDate.unix(),
          dateRange.endDate.unix(),
          moment(review.updateTime).unix()
        );
      });
      localReviews = filteredByRangeReviews;
    }
    setIsFilteringReviews(false);
    setRangedReviews(localReviews);
  }, [dateRange, reviewResponse]);

  useEffect(() => {
    handleRefresh();
  }, []);

  console.log(isLoadingReview);

  return (
    <React.Fragment>
      <LoadableContainer
        isLoading={rangedReviews.length === 0 && (isLoadingReview || isLoadingInquiry)}
        loadComponent={<SplashScreen />}>
        {reviewResponse.reviews.length === 0 ? (
          <div style={{ ...MIDDLE_STYLE, height: '100%' }}>
            <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', overflow: 'hidden' }}>
              <img
                src="jumbotron/empty-review-placeholder.png"
                style={{ maxWidth: 350, width: '100%', marginBottom: 30 }}
              />
              <h2 style={{ textAlign: 'center' }}>
                Your business has no reviews. Start collecting!
              </h2>
              <p
                style={{
                  color: GLOBAL_THEME_COLOR.$dark_text_color,
                  maxWidth: 400,
                  textAlign: 'center',
                }}>
                Amazely provides powerful features to help you automate review collection.
              </p>
              <Button style={{ marginTop: 20 }} type="primary" onClick={handleCollectReviews}>
                <StarOutlined />
                Collect customers reviews
              </Button>
            </div>
          </div>
        ) : (
          <div className="screen-card">
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between' }}>
              <h3 className="airbnb-font">Reviews</h3>
              <Space style={{ flexWrap: 'wrap' }}>
                <CustomizeAiResponderButton />
                <Button style={{ marginLeft: 20 }} type="primary" onClick={handleRefresh}>
                  <ReloadOutlined /> Refresh
                </Button>
              </Space>
            </div>
            <Divider />
            <Row gutter={10}>
              <Col style={{ marginBottom: isTablet ? 10 : 0 }} span={isTablet ? 24 : 6}>
                <AnimatedComponent.OpacityFadeInDiv delay={200}>
                  <NumberOfReviewsWidget />
                </AnimatedComponent.OpacityFadeInDiv>
              </Col>
              <Col style={{ marginBottom: isTablet ? 10 : 0 }} span={isTablet ? 24 : 6}>
                <AnimatedComponent.OpacityFadeInDiv delay={200}>
                  <AverageRatingWidget />
                </AnimatedComponent.OpacityFadeInDiv>
              </Col>
              <Col style={{ marginBottom: isTablet ? 10 : 0 }} span={isTablet ? 24 : 6}>
                <AnimatedComponent.OpacityFadeInDiv delay={200}>
                  <PositiveReviewWidget />
                </AnimatedComponent.OpacityFadeInDiv>
              </Col>
              <Col style={{ marginBottom: isTablet ? 10 : 0 }} span={isTablet ? 24 : 6}>
                <AnimatedComponent.OpacityFadeInDiv delay={200}>
                  <NegativeReviewWidget />
                </AnimatedComponent.OpacityFadeInDiv>
              </Col>
            </Row>
            <Divider />
            <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'space-between' }}>
              <ReviewFilterButton />
              <ReviewSortByButton />
              <ReviewOrderbyButton />
              <Space style={{ display: 'flex' }}>
                <span style={{ fontWeight: 'normal', fontSize: 15 }}>Date Range:</span>
                <RangePicker
                  allowClear
                  {...(!dateRange && { defaultValue: undefined })}
                  onCalendarChange={handleCalendarChange as any}
                />
                <Button onClick={handleReset}>Reset</Button>
              </Space>
            </div>
            <LoadableContainer isLoading={isFilteringReviews} loadComponent={<Skeleton />}>
              {isTablet ? (
                <React.Fragment>
                  {rangedReviews.map(review => (
                    <BusinessReviewCard
                      inApp
                      review={{
                        author_url: review.reviewer.profilePhotoUrl,
                        profile_photo_url: review.reviewer.profilePhotoUrl,
                        author_name: review.reviewer.displayName,
                        generated_response: review.reviewReply?.comment || '',
                        rating: starRatingToNumber(review.starRating),
                        text: review.comment,
                        time: formatUtcTimestamp(review.createTime),
                      }}
                    />
                  ))}
                </React.Fragment>
              ) : (
                <ReviewTable reviews={rangedReviews} size="large" style={{ padding: '20px 0px' }} />
              )}
            </LoadableContainer>
          </div>
        )}
      </LoadableContainer>
    </React.Fragment>
  );
};

export default ReviewScreen;
