import { fetchLocationDataReducer } from './fetchLocationData.thunk';
import { fetchSelectedLocationReducer } from './fetchSelectedLocation.thunk';
import { selectLocationReducer } from './selectLocation.thunk';
import { deactivateAutopilotReducer } from './deactivateAutopilot.thunk';
import { activateAutopilotReducer } from './activateAutopilot.thunk';
import { deactivateAutoRequestReducer } from './deactivateAutoRequest.thunk';
import { activateAutoRequestReducer } from './activateAutoRequest.thunk';
import { fetchSquareMerchantReducer } from './fetchSquareMerchant.thunk';
import { fetchQuickBooksInfoReducer } from './fetchQuickBooksInfo.thunk';
import { fetchFacebookSelectedPagesReducer } from './fetchFacebookSelectedPages.thunk';
import { fetchInstagramSelectedProfilesReducer } from './fetchInstagramSelectedProfiles.thunk';
import { updateDefendResponseReducer } from './updateDefendResponse.thunk';
import { updateTimezoneReducer } from './updateTimezone.thunk';
import { updateReviewUrlReducer } from './updateReviewUrl.thunk';
import { updateGeneralInfoReducer } from './updateGeneralInfo.thunk';
import { fetchJobberInfoReducer } from './fetchJobberInfo.thunk';
import { fetchXeroInfoReducer } from './fetchXeroInfo.thunk';
import { fetchAllGoogleBusinesLocationsReducer } from './fetchAllGoogleBusinessLocations.thunk';
import { updateLanguageReducer } from './updateLanguage.thunk';
import { fetchAmazelyLocalLocationsReducer } from './fetchAmazelyLocalLocations';

export * from './fetchSquareMerchant.thunk';
export * from './fetchFacebookSelectedPages.thunk';
export * from './fetchLocationData.thunk';

export const locationThunkReducers = {
  ...fetchLocationDataReducer,
  ...fetchSquareMerchantReducer,
  ...fetchQuickBooksInfoReducer,
  ...fetchJobberInfoReducer,
  ...fetchXeroInfoReducer,
  ...fetchFacebookSelectedPagesReducer,
  ...fetchInstagramSelectedProfilesReducer,
  ...fetchAllGoogleBusinesLocationsReducer,
  ...fetchAmazelyLocalLocationsReducer,
  ...selectLocationReducer,
  ...fetchSelectedLocationReducer,
  ...activateAutopilotReducer,
  ...activateAutoRequestReducer,
  ...deactivateAutopilotReducer,
  ...deactivateAutoRequestReducer,
  ...updateDefendResponseReducer,
  ...updateTimezoneReducer,
  ...updateLanguageReducer,
  ...updateReviewUrlReducer,
  ...updateGeneralInfoReducer,
};
