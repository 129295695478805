/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from '@ant-design/icons';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { useAppDispatch, useAuthState } from '@core/configureStore';
import { PhotoImageDataItem, openModal } from '@core/features';
import { useSocialPostDispatcher } from '@core/hooks';
import { makeid } from '@utils/string.util';
import { Button, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { TbPhoto } from 'react-icons/tb';
import { Buffer } from 'buffer';
import { LoadableContainer } from '..';
import axios from 'axios';
import UploadImageModal from '@components/UploadImageModal';
import { socialStudioApiService } from '@services/service-register';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getSocialPostRangeSchedules } from '@utils/date.util';
import moment from 'moment';

type Props = { onNextButtonClicked: any };

const OnboardingPhotoPreparationStage = ({ onNextButtonClicked }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthState();
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchGoogleBusinessPhotos } = useSocialPostDispatcher();
  const [fileImageItems, setFileImageItems] = useState<PhotoImageDataItem[]>([]);

  const handleUploadGoogleBusinessPhotos = (photos: { id: string; data: string }[]) => {
    const fileImageItems: PhotoImageDataItem[] = [];
    for (let i = 0; i < photos.length; i++) {
      const pic = photos[i];
      const imageItem: PhotoImageDataItem = {
        id: Date.now() + Math.floor(Math.random() * 100000),
        extension: 'jpeg',
        name: makeid(10),
        url: `data:image/jpeg;base64,${pic.data}`,
        data: Buffer.from(pic.data, 'base64'),
      };
      fileImageItems.push(imageItem);
    }
    return fileImageItems;
  };

  const handleUploadUnsplashImage = async (pics: any[]): Promise<PhotoImageDataItem[]> => {
    const fileImageItems: PhotoImageDataItem[] = [];
    for (let i = 0; i < pics.length; i++) {
      const pic = pics[i];
      const response = await axios.get<ArrayBuffer>(pic.urls.full, {
        responseType: 'arraybuffer',
      });
      const imageItem: PhotoImageDataItem = {
        id: Date.now() + Math.floor(Math.random() * 100000),
        extension: 'jpeg',
        name: makeid(10),
        url: `data:image/jpeg;base64,${Buffer.from(response.data).toString('base64')}`,
        data: response.data,
      };
      fileImageItems.push(imageItem);
    }
    return fileImageItems;
  };

  const handleOnUpload = async (uploadedItems: PhotoImageDataItem[]) => {
    if (!user) return;
    try {
      setFileImageItems([...fileImageItems, ...uploadedItems]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const { socialPostSchedules } = await getSocialPostRangeSchedules(
        moment().startOf('month'),
        2
      );
      let photos: PhotoImageDataItem[] = [];
      try {
        const businessPhotos = await fetchGoogleBusinessPhotos(user?.selected_location);
        const businessPhotoImageItems = handleUploadGoogleBusinessPhotos(
          businessPhotos.slice(0, socialPostSchedules.length)
        );
        photos = photos.concat(businessPhotoImageItems);
      } catch (error) {
        console.log(error);
      }
      try {
        const stockPhotos = await socialStudioApiService.getUnsplashPhotos(
          `${user?.selected_location.primary_category}`
        );
        const stockPhotoImageItems = await handleUploadUnsplashImage(
          stockPhotos.slice(0, socialPostSchedules.length - photos.length)
        );
        photos = photos.concat(stockPhotoImageItems);
      } catch (error) {
        console.log(error);
      }
      setFileImageItems(photos);
      setLoading(false);
    };
    init();
  }, [user?.selected_location]);

  return (
    <React.Fragment>
      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', padding: '0px 30px' }}>
        <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Prepare your post photos</h1>
        <p
          style={{
            maxWidth: 400,
            textAlign: 'center',
            color: GLOBAL_THEME_COLOR.$dark_text_color,
          }}>
          Below are some recommended photos for your business. You can manually upload photos for a
          better quality
        </p>
        <div
          style={{
            width: '100%',
            height: 450,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
            borderRadius: 10,
            marginTop: 20,
            padding: '10px',
            overflowY: 'scroll',
          }}>
          <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
            <React.Fragment>
              <div style={{ columnCount: 3 }}>
                {fileImageItems.map(fileImageItem => (
                  <LazyLoadImage
                    style={{ margin: '5px 0px' }}
                    loading="lazy"
                    className="card--image"
                    src={fileImageItem.url}
                  />
                ))}
              </div>
            </React.Fragment>
          </LoadableContainer>
        </div>
        <div
          style={{
            ...MIDDLE_STYLE,
            textAlign: 'center',
          }}>
          <Button
            onClick={() => onNextButtonClicked(fileImageItems)}
            type="primary"
            size="large"
            style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
            Craft social posts <TbPhoto style={{ marginLeft: 10, marginRight: 0 }} />
          </Button>{' '}
          <div style={{ margin: '0px 15px', paddingTop: 20 }}>
            <span>or</span>
          </div>
          <Button
            onClick={() =>
              dispatch(
                openModal({
                  modalName: 'uploadImageModal',
                })
              )
            }
            size="large"
            style={{ ...MIDDLE_STYLE, marginTop: 20 }}>
            Upload photos <UploadOutlined style={{ marginLeft: 10, marginRight: 0 }} />
          </Button>
        </div>
      </div>
      <UploadImageModal actionTitle="Upload" onUpload={handleOnUpload} disabledSquarePhotoSource />
    </React.Fragment>
  );
};

export default OnboardingPhotoPreparationStage;
