export enum InquiryStage {
  Pending = 0,
  Sent = 1,
  Opened = 2,
  Submitted = 3,
}

export interface Inquiry {
  id: string;
  location_id: string;
  review_id: string;
  contact_email: string;
  contact_phone: string;
  status: InquiryStage;
  email_content: string;
  email_sent_date: string;
  email_open_date: string;
  created_date: string;
  last_modified_date: string;
  submitted_date: string;
}
