import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Button, Divider, Empty, Modal, Skeleton, Space } from 'antd';
import LoadableContainer from '@components/LoadableContainer';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { PhotoImageDataItem, closeDrawer, closeModal } from '@core/features';
import { Buffer } from 'buffer';
import FileImageItemCard from '../FileImageItemCard';
import BrowseBusinessPhotoButton from '@components/BrowseBusinessPhotoButton';
import { MIDDLE_STYLE } from '@constants/responsive';
import { useAppDispatch, useBusinessState, useModalState } from '@core/configureStore';
import {
  BrowseBusinessPhotoDrawer,
  BrowseMediaLibrary,
  BrowsePexelsPhotoDrawer,
  BrowsePhotoGalleryDrawer,
  BrowseSquareIntegrationPhotoButton,
  BrowseSquareIntegrationPhotoDrawer,
  BrowseUnsplashPhotoDrawer,
} from '@components/index';
import { useBreakpoint, useFileUploader } from '@core/hooks';
import { SquarePhotoImageData, StudioPhoto } from '@core/models';

const MAX_NUMBER_PHOTOS = 50;
const MAX_MEGABYTE_LIMIT = 30;
const megabyte = (num: number) => 1024 * 1024 * num;
const byteToMegabyte = (byte: number) => byte / megabyte(1);

type Props = {
  forComponent?: string;
  existingMedias?: PhotoImageDataItem[];
  actionTitle: string;
  singleFile?: boolean;
  onSingleUpload?: (uploadedFileItem: PhotoImageDataItem) => void;
  onUpload?: (uploadedFileItems: PhotoImageDataItem[]) => void;
  maxNumberOfPhotos?: number;
  disabledSquarePhotoSource?: boolean;
  disabledPhotoGallerySource?: boolean;
  disabledGoogleBusinessSource?: boolean;
};

const UploadImageModal = ({
  forComponent,
  existingMedias = [],
  maxNumberOfPhotos = MAX_NUMBER_PHOTOS,
  actionTitle,
  singleFile,
  onSingleUpload,
  onUpload,
  disabledSquarePhotoSource,
  disabledPhotoGallerySource,
  disabledGoogleBusinessSource,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useBreakpoint();
  const { uploadImageModal, extraParams } = useModalState();
  const {
    uploading,
    setFileImageItems,
    fileImageItems,
    handleSetFileImageItem,
    onUnsplashPhotoUploaded,
    onGoogleBusinessPhotosUploaded,
    onSquareIntegrationPhotosUploaded,
    onPhotoGalleryUploaded,
    onPexelsPhotoUploaded,
  } = useFileUploader(!!singleFile);
  const {
    integration: { square: squareIntegration },
  } = useBusinessState();
  const forComponentParam = useMemo<string | undefined>(
    () => extraParams?.forComponent,
    [extraParams]
  );
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const isExceedMaxPhotoLimit = useMemo(
    () => fileImageItems.length > maxNumberOfPhotos,
    [maxNumberOfPhotos, fileImageItems]
  );

  const getTotalFileImageBytes = (dataArray: PhotoImageDataItem[]) => {
    const byteLengthArray = dataArray.map(item => item.data?.byteLength || 0);
    if (byteLengthArray.length === 0) return 0;
    return byteLengthArray.reduce((bA, bB) => bA + bB);
  };

  const modalOpened = useMemo(
    () => uploadImageModal && (forComponentParam ? forComponentParam === forComponent : true),
    [uploadImageModal, forComponentParam, forComponent]
  );

  const megabyteDataSize = useMemo(
    () => byteToMegabyte(getTotalFileImageBytes(fileImageItems)),
    [fileImageItems]
  );
  const isExceedByteDataLimit = useMemo(
    () => megabyteDataSize > MAX_MEGABYTE_LIMIT,
    [megabyteDataSize]
  );

  const handleImageItemClicked = (item: PhotoImageDataItem) => {
    const isItemSelected = selectedItems[item.id];
    setSelectedItems({
      ...selectedItems,
      [item.id]: !isItemSelected,
    });
  };

  const handleUploadGoogleBusinessPhotos = async (photos: { id: string; data: string }[]) => {
    setLoading(true);
    try {
      await onGoogleBusinessPhotosUploaded(photos);
      dispatch(
        closeDrawer({
          drawerName: 'businessPhotoBrowseDrawer',
        })
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const hanldeUploadSquareIntegrationPhotos = async (photos: SquarePhotoImageData[]) => {
    setLoading(true);
    try {
      await onSquareIntegrationPhotosUploaded(photos);
      dispatch(
        closeDrawer({
          drawerName: 'businessPhotoBrowseDrawer',
        })
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleUploadPhotoGallery = async (photos: StudioPhoto[]) => {
    try {
      setLoading(true);
      await onPhotoGalleryUploaded(photos);
      dispatch(
        closeDrawer({
          drawerName: 'photoGalleryBrowseDrawer',
        })
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleUploadUnsplashImage = async (pics: any[]) => {
    try {
      setLoading(true);
      await onUnsplashPhotoUploaded(pics);
      dispatch(
        closeDrawer({
          drawerName: 'unsplashPhotoBrowseDrawer',
        })
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleUploadPexelsImage = async (pics: any[]) => {
    try {
      setLoading(true);
      await onPexelsPhotoUploaded(pics);
      dispatch(
        closeDrawer({
          drawerName: 'unsplashPhotoBrowseDrawer',
        })
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleUploadImage = async (e: any) => {
    setLoading(true);
    const fileList = e.target.files as FileList;
    const uploadedFiles = [];
    for (let i = 0; i < fileList.length; i++) {
      const fileListItem = fileList.item(i);
      if (fileListItem) {
        uploadedFiles.push(fileListItem);
      }
    }
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i];
      const filename = uploadedFiles[i].name;
      const extension = uploadedFiles[i].type;

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (e) {
        if (!e.target || !e.target.result) return;
        const data = e.target.result as ArrayBuffer;
        const imageItem: PhotoImageDataItem = {
          id: Date.now() + Math.floor(Math.random() * 100000),
          extension: extension,
          name: filename,
          url: `data:${extension};base64,${Buffer.from(data).toString('base64')}`,
          data: data,
        };
        handleSetFileImageItem(imageItem);
      };
    }
    setLoading(false);
  };

  const handleClearSelected = () => {
    setFileImageItems(items => (items = items.filter(item => !selectedItems[item.id])));
  };

  const handleCloseModal = () => {
    setFileImageItems([]);
    dispatch(
      closeModal({
        modalName: 'uploadImageModal',
      })
    );
  };

  const handleUpload = async () => {
    handleCloseModal();
    if (onUpload) onUpload(fileImageItems);
    if (onSingleUpload) onSingleUpload(fileImageItems[0]);
  };

  useEffect(() => {
    setFileImageItems(existingMedias);
    setSelectedItems({});
  }, [modalOpened]);

  return (
    <Modal
      title={`${actionTitle} image (${megabyteDataSize.toFixed(1)} MB)`}
      centered
      // Allow multiple instances of the modal compoent
      open={modalOpened}
      onCancel={handleCloseModal}
      width={'100%'}
      style={{ maxWidth: 1200, overflow: 'hidden' }}
      footer={[
        <Button onClick={handleCloseModal}>Cancel</Button>,
        <Button
          loading={uploading}
          type={'primary'}
          disabled={isExceedMaxPhotoLimit || isExceedByteDataLimit}
          onClick={handleUpload}>
          <UploadOutlined /> {actionTitle}
        </Button>,
      ]}>
      <div style={{ minHeight: '500px' }}>
        {isExceedMaxPhotoLimit && (
          <Alert
            type={'warning'}
            banner={true}
            message={'Exceeds max number of photos'}
            showIcon={true}
          />
        )}
        {isExceedByteDataLimit && (
          <Alert
            type={'warning'}
            banner={true}
            message={`Exceeds the allowed limit (< ${MAX_MEGABYTE_LIMIT} MB per upload). Please remove some photos`}
            showIcon={true}
          />
        )}
        <Divider />
        <React.Fragment>
          {isTablet ? (
            <Space direction="vertical" style={{ width: '100%' }}>
              <label className="upload-file-button" style={{ width: '100%', ...MIDDLE_STYLE }}>
                <input
                  type="file"
                  onChange={handleUploadImage}
                  multiple={!singleFile}
                  accept="image/jpg, image/png, image/jpeg"
                />
                <UploadOutlined style={{ marginRight: 10 }} /> Upload photos
              </label>
              {!disabledGoogleBusinessSource && (
                <BrowseBusinessPhotoButton style={{ width: '100%' }} />
              )}
              {!disabledSquarePhotoSource && (
                <BrowseSquareIntegrationPhotoButton style={{ width: '100%' }} />
              )}
              <BrowseMediaLibrary
                disabledPhotoGallerySource={disabledPhotoGallerySource}
                style={{ width: '100%' }}
              />
              <Button onClick={handleClearSelected} style={{ width: '100%', ...MIDDLE_STYLE }}>
                <DeleteOutlined /> Remove
              </Button>
            </Space>
          ) : (
            <div
              style={{
                ...MIDDLE_STYLE,
                justifyContent: 'space-between',
                marginBottom: 20,
              }}>
              <Space>
                <label className="upload-file-button">
                  <input
                    type="file"
                    onChange={handleUploadImage}
                    multiple={!singleFile}
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <UploadOutlined style={{ marginRight: 10 }} /> Upload photos
                </label>
              </Space>
              <Space style={{ ...MIDDLE_STYLE }}>
                {!disabledGoogleBusinessSource && <BrowseBusinessPhotoButton />}
                {!disabledSquarePhotoSource && <BrowseSquareIntegrationPhotoButton />}
                <BrowseMediaLibrary disabledPhotoGallerySource={disabledPhotoGallerySource} />
              </Space>
              <Button onClick={handleClearSelected}>
                <DeleteOutlined /> Remove
              </Button>
            </div>
          )}
          <Divider />
          <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
            <LoadableContainer
              isLoading={fileImageItems.length === 0}
              loadComponent={
                <div style={{ ...MIDDLE_STYLE }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No images uploaded</span>}
                  />
                </div>
              }>
              <React.Fragment>
                <div
                  style={{ display: 'flex', flexWrap: 'wrap', maxHeight: 500, overflow: 'auto' }}>
                  {fileImageItems.map(fileImageItem => (
                    <FileImageItemCard
                      key={fileImageItem.id}
                      style={
                        isTablet
                          ? {
                              width: '100px',
                            }
                          : {
                              aspectRatio: '2/1',
                            }
                      }
                      onClick={item => handleImageItemClicked(item)}
                      isSelected={selectedItems[fileImageItem.id]}
                      item={fileImageItem}
                    />
                  ))}
                </div>
              </React.Fragment>
            </LoadableContainer>
          </LoadableContainer>
          {!disabledPhotoGallerySource && (
            <BrowsePhotoGalleryDrawer singleFile={singleFile} onLoad={handleUploadPhotoGallery} />
          )}
          <BrowseUnsplashPhotoDrawer singleFile={singleFile} onLoad={handleUploadUnsplashImage} />
          <BrowsePexelsPhotoDrawer singleFile={singleFile} onLoad={handleUploadPexelsImage} />
          {!disabledGoogleBusinessSource && (
            <BrowseBusinessPhotoDrawer
              singleFile={singleFile}
              onLoad={handleUploadGoogleBusinessPhotos}
            />
          )}
          {squareIntegration && !disabledSquarePhotoSource && (
            <BrowseSquareIntegrationPhotoDrawer
              singleFile={singleFile}
              onLoad={hanldeUploadSquareIntegrationPhotos}
            />
          )}
        </React.Fragment>
      </div>
    </Modal>
  );
};

export default UploadImageModal;
