import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { MIDDLE_STYLE } from '@constants/responsive';
import { Checkbox, Col, Divider, Empty, Row } from 'antd';
import { AnimatedComponent, BlogPostCard, LoadableContainer } from '@components/index';
import React, { useMemo } from 'react';
import { BlogPostModel } from '@core/models';
import { useAuthState } from '@core/configureStore';
import { useBreakpoint } from '@core/hooks';
import BlogPostMonthContainerLayout from '@components/BlogPostMonthContainerLayout';

interface Props {
  month: string;
  blogPosts: BlogPostModel[];
}

const BlogPostMonthListContainer: React.FC<Props> = ({ blogPosts, month }) => {
  const { isTablet } = useBreakpoint();
  const { user } = useAuthState();
  const locationId = useMemo(() => user?.selected_location.location_id, [user]);
  return (
    <BlogPostMonthContainerLayout month={month} blogPosts={blogPosts}>
      {({
        blogPosts: currentBlogPosts,
        allChecked,
        setAllChecked,
        setSelectedBlogPosts,
        selectedBlogPosts,
      }) => (
        <React.Fragment>
          {!isTablet && currentBlogPosts.length > 0 && (
            <div
              style={{
                ...MIDDLE_STYLE,
                width: '100%',
                margin: '10px 0px 5px 0px',
                paddingBottom: 5,
                color: GLOBAL_THEME_COLOR.$dark_text_color,
                borderBottom: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
              }}>
              {!isTablet && (
                <Checkbox
                  checked={allChecked}
                  style={{ marginRight: 20 }}
                  onClick={() => setAllChecked(!allChecked)}
                />
              )}
              <Row style={{ width: '100%' }}>
                <Col span={2} style={{ ...MIDDLE_STYLE }}>
                  <span style={{ fontSize: '12px' }}>Photo</span>
                </Col>
                <Col span={3} style={{ ...MIDDLE_STYLE }}>
                  <span style={{ fontSize: '12px' }}>Status</span>
                </Col>
                <Col span={18}>
                  <span style={{ fontSize: '12px' }}>Content</span>
                </Col>
              </Row>
            </div>
          )}
          <div id="blog-posts-month-container" style={{ height: '100%' }}>
            <LoadableContainer
              isLoading={currentBlogPosts.length === 0}
              loadComponent={
                <div style={{ ...MIDDLE_STYLE }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No blog posts found</span>}
                  />
                </div>
              }>
              {currentBlogPosts.map((blogPost, index) => (
                <AnimatedComponent.OpacityFadeInDiv delay={200}>
                  {locationId && (
                    <React.Fragment>
                      <div
                        style={{ ...MIDDLE_STYLE }}
                        onClick={() =>
                          setSelectedBlogPosts({
                            ...selectedBlogPosts,
                            [blogPost.id]: !selectedBlogPosts[blogPost.id.toString()],
                          })
                        }>
                        {!isTablet && (
                          <Checkbox
                            checked={selectedBlogPosts[blogPost.id.toString()]}
                            style={{ marginRight: 20 }}
                          />
                        )}
                        <React.Fragment>
                          <BlogPostCard blogPost={blogPost} />
                        </React.Fragment>
                      </div>
                      {index !== blogPosts.length - 1 && <Divider style={{ margin: '5px 0px' }} />}
                    </React.Fragment>
                  )}
                </AnimatedComponent.OpacityFadeInDiv>
              ))}
            </LoadableContainer>
          </div>
        </React.Fragment>
      )}
    </BlogPostMonthContainerLayout>
  );
};

export default BlogPostMonthListContainer;
