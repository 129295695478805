import React from 'react';
import { useAppDispatch } from '@core/configureStore';
import { openModal } from '@core/features';
import { FacebookPost, SocialPostStage } from '@core/models';
import { renderStatusColor, renderStatusStr } from '@utils/social-studio.util';
import { Card } from 'antd';
import { formatUtcDate } from '@utils/date.util';
import axios from 'axios';
import { Buffer } from 'buffer';

type Props = {
  locationId: string;
  facebookPost: FacebookPost;
};

const FacebookPostCard = ({ locationId, facebookPost }: Props) => {
  const dispatch = useAppDispatch();

  const handleItemClicked = async (facebookPost: FacebookPost) => {
    const response = await axios.get<ArrayBuffer>(facebookPost.full_picture, {
      responseType: 'arraybuffer',
    });
    dispatch(
      openModal({
        modalName: 'socialPostCreationModal',
        extraParams: {
          photoData: `data:image/jpeg;base64,${Buffer.from(response.data).toString('base64')}`,
          locationId,
          caption: facebookPost.message,
        },
      })
    );
  };

  return (
    <Card
      onClick={() => handleItemClicked(facebookPost)}
      hoverable
      className="no-padding"
      style={{
        maxWidth: 350,
        minWidth: 310,
        marginRight: 10,
        marginTop: 20,
        borderRadius: 15,
      }}>
      <React.Fragment>
        <div
          style={{
            top: -15,
            left: 20,
            position: 'absolute',
            backgroundColor: renderStatusColor(SocialPostStage.Published),
            width: 'fit-content',
            color: 'white',
            padding: '5px 10px',
            borderRadius: 5,
            cursor: 'pointer',
            fontWeight: 600,
          }}>
          <React.Fragment>
            {`${renderStatusStr(SocialPostStage.Published)} at ${
              facebookPost.created_time && formatUtcDate(facebookPost.created_time)
            }`}
          </React.Fragment>
        </div>
        <div style={{ marginBottom: 10 }}>
          <p style={{ marginTop: 10, fontSize: 13, padding: '20px 20px' }}>
            {facebookPost.message || ''}
          </p>
          {locationId && (
            <img
              loading="lazy"
              style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
              src={facebookPost?.full_picture}
              alt={facebookPost?.id}
            />
          )}
        </div>
      </React.Fragment>
    </Card>
  );
};

export default FacebookPostCard;
