import { useAppDispatch, useReviewState } from '@core/configureStore';
import { ReviewSortOrder, sortOrdersChanged } from '@core/features';
import { Select, Space } from 'antd';
import React from 'react';

type Props = {};

const ReviewOrderbyButton = (props: Props) => {
  const dispatch = useAppDispatch();
  const { sortOrder } = useReviewState();

  const onOrderChange = (value: ReviewSortOrder) => {
    dispatch(sortOrdersChanged(value));
  };

  return (
    <React.Fragment>
      <Space style={{ display: 'flex' }}>
        <span style={{ fontWeight: 'normal', fontSize: 15 }}>Order:</span>
        <Select
          style={{ width: 120 }}
          placeholder="Select order"
          defaultValue={sortOrder}
          onChange={onOrderChange}
          options={[
            {
              value: ReviewSortOrder.DESC,
              label: 'Descending',
            },
            {
              value: ReviewSortOrder.ASC,
              label: 'Ascending',
            },
          ]}
        />
      </Space>
    </React.Fragment>
  );
};

export default ReviewOrderbyButton;
