/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { BRAND_LOGO_CONSTRAST, BRAND_NAME, GLOBAL_THEME_COLOR, MIDDLE_STYLE } from '../constants';
import { Button, Divider, Layout, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import ReactConfetti from 'react-confetti';
import LoadableContainer from '../components/LoadableContainer';
import { useGenerationDataStore } from '@core/hooks';
import { useBreakpoint } from '@core/hooks/useBreakpoint';
import { AutoReviewGeneratorEngineStatus } from '@core/models';
import { getAmazelyGoogleUrl } from '@constants/auth';
import { useLocation } from 'react-router';
import FacebookInAppHandler from '@components/FacebookInAppHandler';
import { isFacebookApp } from '@constants/social';
import { publicRouteService } from '@services/service-register';
import { PitchAutoReviewResponseSection } from '@components/pitch';

type Props = {};

const GOOGLE_OAUTH_REDIRECT = process.env.REACT_APP_AMAZELY_GOOGLE_OAUTH_REDIRECT as string;

const AutoReviewGenerateScreen = (props: Props) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { isTablet, isMobile } = useBreakpoint();
  const { businessData, autoReviewEngineStatus } = useGenerationDataStore();
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const confettiRef = useRef<HTMLDivElement | null>(null);
  let from = ((location.state as any)?.from?.pathname as string) || '/';
  let isDemo = window.location.href.toString().includes('demo');

  useEffect(() => {
    const section = (() => {
      switch (autoReviewEngineStatus) {
        case AutoReviewGeneratorEngineStatus.GenerateReviewResponse:
          return document.getElementById('generated-reviews');
        case AutoReviewGeneratorEngineStatus.Finished:
          return document.getElementById('generate-screen');
        default:
          return undefined;
      }
    })();
    section?.scrollIntoView({ behavior: 'smooth' });
  }, [autoReviewEngineStatus, businessData]);

  const services = [
    'Expand your social presence effortlessly',
    'Grow your Google reviews automatically',
    'Answer reviews on your behalf',
    'Rectify negative reviews and protect your reputation',
    'Write SEO blogs to boost your Google rankings',
    'Grow your local presence & attract more customers faster',
  ];

  const handleActivate = async () => {
    try {
      await publicRouteService.sendSlackNotification(
        `${businessData?.name} [✅ Activate Clicked]`,
        businessData?.types?.[0] || ''
      );
    } catch (error) {
      console.log(error);
    }

    if (isFacebookApp()) setOpen(true);
    else {
      window.location.href = getAmazelyGoogleUrl(from, GOOGLE_OAUTH_REDIRECT);
    }
  };

  const handleLearnMoreAboutAmazely = async () => {
    try {
      await publicRouteService.sendSlackNotification(
        `${businessData?.name} [👀 Learning about Amazely...]`,
        businessData?.types?.[0] || ''
      );
    } catch (error) {
      console.log(error);
    }
    window.open('https://amazely.co/');
  };

  useEffect(() => {
    if (!confettiRef.current) return;
    setHeight(confettiRef.current.clientHeight);
    setWidth(confettiRef.current.clientWidth);
  }, [confettiRef]);

  useEffect(() => {
    if (autoReviewEngineStatus === AutoReviewGeneratorEngineStatus.Finished) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  }, [autoReviewEngineStatus]);

  return (
    <FacebookInAppHandler open={open} setOpen={setOpen}>
      <div
        ref={confettiRef}
        style={{
          backgroundColor: '#f0f2f5',
          maxHeight: '100vh',
          overflow: 'auto',
        }}>
        {showConfetti && <ReactConfetti numberOfPieces={1000} width={width} height={height} />}
        <Layout>
          <Layout.Header
            style={{
              position: 'fixed',
              zIndex: 100,
              width: '100%',
              ...MIDDLE_STYLE,
              justifyContent: 'space-between',
            }}>
            <div style={{ ...MIDDLE_STYLE }}>
              <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 40 }} />{' '}
              {!isTablet && (
                <span
                  style={{
                    color: GLOBAL_THEME_COLOR.$text_color,
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginLeft: 10,
                  }}>
                  {BRAND_NAME.toUpperCase()}
                </span>
              )}
            </div>
            <Space style={{ ...MIDDLE_STYLE }}>
              <Button
                onClick={handleLearnMoreAboutAmazely}
                size="large"
                style={{ cursor: 'pointer', ...MIDDLE_STYLE }}>
                Learn more
              </Button>
              <Button
                onClick={handleActivate}
                type="primary"
                size="large"
                style={{ cursor: 'pointer', ...MIDDLE_STYLE }}>
                {!isMobile && 'Getting started'}
                <ArrowRightOutlined style={{ marginLeft: isMobile ? 0 : 10 }} />
              </Button>
            </Space>
          </Layout.Header>
          <Layout.Content style={{ overflowX: 'hidden' }}>
            <div
              id="generate-screen"
              className="section-glassy"
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                padding: '150px 50px 40px 50px',

                width: '100vw',
                overflow: 'hidden',
                position: 'relative',
              }}>
              <img
                src="jumbotron/builder-background-purple.webp"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                }}
              />
              <div
                style={{
                  zIndex: 10,
                  ...MIDDLE_STYLE,
                  flexDirection: 'column',
                  textAlign: 'center',
                  color: GLOBAL_THEME_COLOR.$text_color_contrast,
                }}>
                <h1
                  style={{
                    margin: 0,
                    fontSize: isTablet ? '1.5rem' : '3.5rem',
                    fontFamily: 'Rubik, sans-serif',
                  }}>
                  {businessData.name}
                </h1>
                <h2
                  style={{
                    fontFamily: 'Rubik, sans-serif',
                    margin: '20px 0px',
                    color: GLOBAL_THEME_COLOR.$text_color_contrast,
                    fontWeight: 'normal',
                    fontSize: isTablet ? '1rem' : '1.5rem',
                    borderBottom: '1px dashed #ffffff80',
                  }}>
                  This is just a glimpse of Amazely features.
                </h2>
                <Button
                  onClick={handleActivate}
                  type="primary"
                  size="large"
                  style={{ cursor: 'pointer', padding: 24, borderRadius: 50, ...MIDDLE_STYLE }}>
                  <img
                    src={BRAND_LOGO_CONSTRAST}
                    alt={BRAND_NAME}
                    style={{ width: 30, marginRight: 6 }}
                  />
                  Sign in for a full app experience &nbsp;
                  <ArrowRightOutlined />
                </Button>
                <br />
                <br />

                {!isDemo && (
                  <span>
                    <div style={{ fontWeight: 'bold', fontSize: 15 }}>Trusted by</div>
                    <div
                      style={{
                        borderBottom: '1px solid orange',
                        fontWeight: 'bold',
                        fontSize: 18,
                      }}>
                      800+ businesses globally
                    </div>
                    <br /> <br />
                  </span>
                )}
                {isDemo && (
                  <div
                    style={{
                      color: 'white',
                      marginTop: 20,
                      fontSize: 15,
                      ...MIDDLE_STYLE,
                    }}>
                    Made by
                    <div style={{ ...MIDDLE_STYLE, marginLeft: 5 }}>
                      <img src={BRAND_LOGO_CONSTRAST} alt={BRAND_NAME} style={{ width: 30 }} />{' '}
                      <span
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginLeft: 5,
                        }}>
                        {BRAND_NAME}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                ...MIDDLE_STYLE,
                padding: isMobile ? '50px 20px' : '30px 10%',
                flexDirection: 'column',
              }}>
              <LoadableContainer
                isLoading={
                  businessData.reviews.length === 0 &&
                  autoReviewEngineStatus === AutoReviewGeneratorEngineStatus.Finished
                }
                loadComponent={<></>}>
                <div id="generated-reviews">
                  <PitchAutoReviewResponseSection />
                </div>
                <Divider />
              </LoadableContainer>
            </div>
            <Divider />
            <div
              style={{
                ...MIDDLE_STYLE,
                flexDirection: 'column',
                padding: '0px 20px',
              }}>
              <h2>Amazely, the AI marketing engine for small businesses.</h2>
              <div>
                {services.map(service => (
                  <div style={{ margin: '10px 0px', fontSize: 18 }}>✅ {service}</div>
                ))}
              </div>
              <br /> <br />
              {!isDemo && (
                <span style={{ textAlign: 'center' }}>
                  <div style={{ fontWeight: 'bold', fontSize: 15 }}>Trusted by</div>
                  <div
                    style={{ borderBottom: '1px solid orange', fontWeight: 'bold', fontSize: 18 }}>
                    800+ businesses globally
                  </div>
                  <br />
                </span>
              )}
              <h2
                style={{
                  fontFamily: 'Rubik, sans-serif',
                  margin: '20px 0px',
                  color: 'black',
                  fontWeight: 'normal',
                  fontSize: isTablet ? '1rem' : '1.5rem',
                  borderBottom: '1px dashed grey',
                }}>
                {/*{businessData.location}*/}
                This is only a high level demo.
              </h2>
              <Button
                onClick={handleActivate}
                type="primary"
                size="large"
                style={{ cursor: 'pointer', padding: 24, borderRadius: 50, ...MIDDLE_STYLE }}>
                <img
                  src={BRAND_LOGO_CONSTRAST}
                  alt={BRAND_NAME}
                  style={{ width: 30, marginRight: 6 }}
                />
                Sign in for a full app experience &nbsp;
                <ArrowRightOutlined />
              </Button>
              <br />
              <br />
              <br />
              <br />
            </div>
          </Layout.Content>
        </Layout>
      </div>
    </FacebookInAppHandler>
  );
};

export default AutoReviewGenerateScreen;
