import { LoadingOutlined } from '@ant-design/icons';
import CardSectionHeader from '@components/CardSectionHeader';
import { useReviewState } from '@core/configureStore';
import { GoogleReview } from '@core/models';
import { Card, Tag } from 'antd';
import React, { useMemo } from 'react';

type Props = {};

const NumberOfReviewsWidget = (props: Props) => {
  const { reviewResponse, isLoading: isLoadingReview } = useReviewState();
  const respondedReviews: GoogleReview[] = useMemo(
    () => reviewResponse.reviews?.filter((review: any) => review.reviewReply) || [],
    [reviewResponse]
  );
  return (
    <Card className="no-padding" style={{ borderRadius: 10 }}>
      <div style={{ padding: '15px 15px' }}>
        <CardSectionHeader title="Number of reviews" />
        {isLoadingReview ? (
          <LoadingOutlined />
        ) : (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
              }}>
              <Tag color="green" style={{ marginTop: 10 }}>
                {respondedReviews.length} responded
              </Tag>
              <Tag color="processing" style={{ marginTop: 10 }}>
                {reviewResponse.totalReviewCount - respondedReviews.length || 0} not responded
              </Tag>
            </div>
          </React.Fragment>
        )}
      </div>
    </Card>
  );
};

export default NumberOfReviewsWidget;
