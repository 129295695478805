import { useGenerationDataStore } from '@core/hooks';
import { PlaceData } from '@core/models';
import React from 'react';
import { GoogleMapRenderer } from '..';

const PitchLocalReviewRankingMap = () => {
  const { businessData, nearbyBusinesses } = useGenerationDataStore();
  return (
    <React.Fragment>
      <div
        style={{
          fontSize: 'larger',
          lineHeight: 1.7,
          width: '100vw',
          maxWidth: 1500,
          padding: '0px 20px',
        }}>
        <h2 className="pitch_title">Get 5x more Google reviews</h2>
        <div className="pitch_subtitle">
          Amazely collects Google reviews automatically on your behalf, helps you to stand out and
          be visible to more customers.
        </div>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <GoogleMapRenderer
          nearbyBusinesses={nearbyBusinesses}
          businessData={businessData as PlaceData}
          latitude={businessData.latitude}
          longtitude={businessData.longtitude}
        />
      </div>
    </React.Fragment>
  );
};

export default PitchLocalReviewRankingMap;
