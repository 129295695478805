import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequest } from '@core/models';
import { ReviewRequestReducerProps } from '../type';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { RootState } from '@core/configureStore';

type ResendRequestPayload = {
  requestId: number;
  requestType: 'email' | 'sms';
};

export const resendRequest = createAsyncThunk(
  'review-request/resendRequest',
  async ({ requestId, requestType }: ResendRequestPayload, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('Not user found');

      const result = await heroApiService.resendEmail(requestId, requestType);
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Successfully resend request email',
          description: 'Request email is sent successfully',
          type: 'Success',
        } as SnackbarItem)
      );
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed to send request email',
          description: 'There was error sending review request email. Please try again',
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(resendRequest);

export const resendRequestReducer = {
  [fulfilled]: (state, action: PayloadAction<ReviewRequest>) => {
    state.isDetailLoading = false;
    state.request = action.payload;
  },
  [rejected]: (state, action) => {
    state.isDetailLoading = false;
    state.error = action.payload;
  },
  [pending]: (state, action) => {
    state.isDetailLoading = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
