import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { SliceName } from '../../name';
import { locationThunkReducers } from './thunks';
import { LocationReducerProps } from './type';
import { DefendResponseType, GoogleLocalLocation } from '@core/models';

const businessSlice = createSlice<
  LocationReducerProps,
  SliceCaseReducers<LocationReducerProps>,
  string
>({
  name: SliceName.Location,
  initialState: {
    isLoading: true,
    isSelecting: false,
    isLocalLocationLoading: false,
    isRequestTemplateUpdating: false,
    isAutopilotActivating: false,
    isAutoRequestActivating: false,
    defendResponseType: DefendResponseType.NONE,
    locations: [],
    googleLocations: [],
    localLocations: [],
    integration: {
      square: undefined,
      quickbooks: undefined,
      jobber: undefined,
      xero: undefined,
      openDental: undefined,
      facebook_pages: [],
      instagram_profiles: [],
    },
  },
  reducers: {
    addGoogleLocation: (
      state,
      action: PayloadAction<{
        location: GoogleLocalLocation;
      }>
    ) => {
      state.googleLocations = state.googleLocations.concat(action.payload.location);
      return state;
    },
    setGoogleLocations: (
      state,
      action: PayloadAction<{
        locations: GoogleLocalLocation[];
      }>
    ) => {
      state.googleLocations = action.payload.locations;
      return state;
    },
    setBusinessStateLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      return state;
    },
    integrateManyToService: (
      state,
      action: PayloadAction<{
        platform: 'facebook_pages' | 'instagram_profiles';
        profiles: any[];
      }>
    ) => {
      const platform = action.payload.platform;
      state.integration[platform] = action.payload.profiles;
      return state;
    },
    integrateToService: (
      state,
      action: PayloadAction<{
        platform: 'square';
        profile: any;
      }>
    ) => {
      const platform = action.payload.platform;
      state.integration[platform] = action.payload.profile;
      return state;
    },
  },
  extraReducers: locationThunkReducers,
});

export const {
  integrateToService,
  integrateManyToService,
  setBusinessStateLoading,
  addGoogleLocation,
  setGoogleLocations,
} = businessSlice.actions;
export const businessReducer = businessSlice.reducer;
