/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch } from '@core/configureStore';
import { enqueueNotification } from '@core/features';
import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 10,
  marginTop: 20,
  height: '200px',
  borderColor: '#2196f3',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#2196f3',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type Props = {
  title: React.ReactNode;
  onLoadFileResult: (data: string) => any;
};

const UploadFileDropzone = ({ title, onLoadFileResult }: Props) => {
  const dispatch = useAppDispatch();
  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = async () => {
        await dispatch(
          enqueueNotification({
            name: 'Reading file process aborted',
            description: 'Please upload CSV file to try again',
            type: 'Error',
          })
        );
      };
      reader.onerror = async () => {
        await dispatch(
          enqueueNotification({
            name: 'Reading file process failed',
            description: 'Please upload CSV file to try again',
            type: 'Error',
          })
        );
      };
      reader.onload = () => {
        onLoadFileResult(reader.result as string);
      };
      reader.readAsText(file);
    });
  }, []);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div style={style} {...getRootProps()}>
      <input id="file-upload" type="file" {...getInputProps()} />
      <p>{title}</p>
    </div>
  );
};

export default UploadFileDropzone;
