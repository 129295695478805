import React, { useEffect } from 'react';
import { Calendar, Popover } from 'antd';
import * as dayjs from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/lib/interface';
import { CalendarItem } from '@utils/social-studio.util';
import CalendarEventsPopover from '../CalendarEventsPopover';
import { useBreakpoint, useCalendarEvents, useStudioTourStore } from '@core/hooks';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import { SocialPost } from '@core/models';
import { MIDDLE_STYLE } from '@constants/responsive';
import { LoadableContainer } from '@components/index';
import SmallSocialPostCard from '@components/SocialPostCard/small';
import { useAppDispatch } from '@core/configureStore';
import { fetchSocialPosts } from '@core/features/studio/thunks';

const RenderSocialPostCalendarItems = ({
  currentMonthItems,
  isCompacted,
}: {
  currentMonthItems: CalendarItem<SocialPost>[];
  isCompacted?: boolean;
}) => {
  const { isTablet } = useBreakpoint();
  return (
    <React.Fragment>
      {isCompacted || isTablet ? (
        <LoadableContainer isLoading={currentMonthItems.length === 0} loadComponent={<></>}>
          <div
            style={{
              padding: '5px',
              height: '100%',
              width: '100%',
              ...MIDDLE_STYLE,
              backgroundColor: GLOBAL_THEME_COLOR.$highlight_color,
              color: GLOBAL_THEME_COLOR.$text_color_contrast,
              fontWeight: 'bold',
              boxShadow: STRIPE_BOX_SHADOW,
            }}>
            {currentMonthItems.length}
          </div>
        </LoadableContainer>
      ) : (
        <React.Fragment>
          {currentMonthItems.map(item => (
            <SmallSocialPostCard item={item.extra as any} />
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const MonthCellRender = ({
  current,
  socialPosts,
}: {
  current: dayjs.Dayjs;
  socialPosts: SocialPost[];
}) => {
  const [month, year] = [current.month(), current.year()];
  const { events: currentMonthItems } = useCalendarEvents(socialPosts, {
    year,
    month,
  });
  return (
    <Popover
      trigger={'click'}
      content={<CalendarEventsPopover socialPosts={socialPosts} month={month} />}>
      <div className="events" style={{ height: 'fit-content', ...MIDDLE_STYLE }}>
        <RenderSocialPostCalendarItems isCompacted currentMonthItems={currentMonthItems} />
      </div>
    </Popover>
  );
};

const DateCellRender = ({
  current,
  socialPosts,
}: {
  current: dayjs.Dayjs;
  socialPosts: SocialPost[];
}) => {
  const [day, month, year] = [current.date(), current.month(), current.year()];
  const { events: currentMonthItems } = useCalendarEvents(socialPosts, {
    year,
    month,
    day,
  });
  return (
    <Popover
      trigger={'click'}
      content={<CalendarEventsPopover socialPosts={socialPosts} day={day} month={month} />}>
      <div className="events" style={{ height: 'fit-content' }}>
        <RenderSocialPostCalendarItems currentMonthItems={currentMonthItems} />
      </div>
    </Popover>
  );
};

const SocialPostCalendarViewTab = ({ socialPosts }: { socialPosts: SocialPost[] }) => {
  const dispatch = useAppDispatch();
  const { selectedTabKey } = useStudioTourStore();

  const cellRender = (current: dayjs.Dayjs, info: CellRenderInfo<dayjs.Dayjs>) => {
    if (info.type === 'date') return <DateCellRender socialPosts={socialPosts} current={current} />;
    if (info.type === 'month')
      return <MonthCellRender socialPosts={socialPosts} current={current} />;
    return info.originNode;
  };

  useEffect(() => {
    dispatch(fetchSocialPosts());
  }, [selectedTabKey]);

  return <Calendar defaultValue={dayjs.default()} cellRender={cellRender} />;
};
export default SocialPostCalendarViewTab;
