import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { heroApiService } from '@services/.';
import { ExtraReducer, getSideEffectState } from '@utils/.';
import { ReviewRequest } from '@core/models';
import { ReviewRequestReducerProps } from '../type';
import { enqueueNotification, SnackbarItem } from '@core/features/snackbar';
import { RootState } from '@core/configureStore';

export const publishDraft = createAsyncThunk(
  'review-request/publishDraft',
  async (id: number, thunkApi) => {
    try {
      const {
        auth: { user },
      } = thunkApi.getState() as RootState;
      if (!user) throw new Error('Not user found');

      const result: ReviewRequest = await heroApiService.publishDraft(id);
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Successfully created review request from draft',
          description: `Review request has been created for ${result.contact}`,
          type: 'Success',
        } as SnackbarItem)
      );
      return result;
    } catch (error: any) {
      thunkApi.dispatch(
        enqueueNotification({
          name: 'Failed create review request from draft',
          description: `Review request created failed. Please try again`,
          type: 'Error',
        } as SnackbarItem)
      );
      throw new Error(error);
    }
  }
);

const [fulfilled, pending, rejected] = getSideEffectState(publishDraft);

export const publishDraftReducer = {
  [fulfilled]: (state, action: PayloadAction<ReviewRequest>) => {
    state.isMutating = false;
    state.requests.push(action.payload);
  },
  [rejected]: (state, action) => {
    state.isMutating = false;
    state.error = action.payload;
  },
  [pending]: (state, action) => {
    state.isMutating = true;
  },
} as ExtraReducer<ReviewRequestReducerProps>;
