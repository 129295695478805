import React from 'react';
import StudioImageItemCard from '@components/StudioImageItemCard';
import { StudioPhoto } from '@core/models';

interface Props {
  studioPhotos: StudioPhoto[];
  onItemClicked: (studioPhoto: StudioPhoto) => void;
}

const ImageGalleryGridView: React.FC<Props> = ({ studioPhotos, onItemClicked }) => {
  return (
    <div className="card-list">
      {studioPhotos.map(studioPhoto => (
        <StudioImageItemCard onClick={item => onItemClicked(item)} item={studioPhoto} />
      ))}
    </div>
  );
};

export default ImageGalleryGridView;
