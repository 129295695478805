/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { MIDDLE_STYLE } from '@constants/responsive';
import { FacebookPage } from '@core/models';
import { Space } from 'antd';
import { SocialIcon } from '..';

type Props = {
  facebookPage: FacebookPage;
  prefix?: React.ReactNode;
};

const FacebookAccountListItem = ({ facebookPage, prefix }: Props) => {
  return (
    <React.Fragment>
      <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', width: '100%' }}>
        <Space size={'large'} style={{ ...MIDDLE_STYLE, justifyContent: 'flex-start' }}>
          {prefix}
          <img
            width={50}
            height={50}
            style={{ borderRadius: 50 }}
            src={`data:image/jpeg;base64,${facebookPage.picture}`}
          />
          <div>
            <h3>{facebookPage.name}</h3>
            <p>{facebookPage.category}</p>
          </div>
        </Space>
        <SocialIcon platform="facebook" />
      </div>
    </React.Fragment>
  );
};

export default FacebookAccountListItem;
