import { AuthUser, SocialAcccount } from '../../models';

export enum AuthState {
  Unauthenticated,
  Authenticating,
  Authenticated,
  None,
}

export type AuthReducerProps = {
  isAuthLoading: boolean;
  authState: AuthState;
  user: AuthUser | undefined;
  isSupportSession: boolean;
  integration: {
    facebook: SocialAcccount | undefined;
    instagram: SocialAcccount | undefined;
  };
};
