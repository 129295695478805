/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useBusinessState, useModalState } from '@core/configureStore';
import { closeModal, enqueueNotification, integrateManyToService } from '@core/features';
import { FacebookPage, InstagramProfile } from '@core/models';
import { Button, Checkbox, Divider, Empty, Modal, Skeleton, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FacebookAccountListItem, InstagramAccountListItem, LoadableContainer } from '..';
import { fetchFacebookSelectedPages } from '@core/features/business/thunks';
import { facebookApiService, instagramApiService } from '@services/service-register';
import { fetchInstagramSelectedProfiles } from '@core/features/business/thunks/fetchInstagramSelectedProfiles.thunk';
import { MIDDLE_STYLE } from '@constants/responsive';

type Props = {};

const SelectSocialAccountsModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [facebookPageList, setFacebookPageList] = useState<FacebookPage[]>([]);
  const [instagramProfileList, setInstagramProfileList] = useState<InstagramProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAccountIds, setSelectedAccountIds] = useState<Record<string, boolean>>({});
  const { selectSocialAccountsModal } = useModalState();
  const { integration } = useBusinessState();
  const selectedFacebookPages = useMemo<FacebookPage[]>(
    () => integration.facebook_pages || [],
    [integration]
  );
  const selectedInstagramProfiles = useMemo<InstagramProfile[]>(
    () => integration.instagram_profiles || [],
    [integration]
  );

  const handleCloseModal = () => {
    dispatch(
      closeModal({
        modalName: 'selectSocialAccountsModal',
      })
    );
  };

  const handleAccountCheckedChange = (id: string, checked: boolean) => {
    setSelectedAccountIds({
      ...selectedAccountIds,
      [id]: checked,
    });
  };

  const handleSelectFacebookPages = async () => {
    try {
      const promises: Promise<any>[] = [];
      // Get selected facebook pages from the remote page list
      for (const page of facebookPageList) {
        if (selectedAccountIds[page.id]) promises.push(facebookApiService.selectFacebookPage(page));
      }

      // Get unselected existing facebook pages to deselect
      for (const page of selectedFacebookPages) {
        if (!selectedAccountIds[page.id])
          promises.push(facebookApiService.unselectFacebookPage(page.id));
      }
      if (promises.length === 0) return;
      await Promise.all(promises);
      await dispatch(fetchFacebookSelectedPages());
    } catch (error: any) {
      dispatch(
        integrateManyToService({
          platform: 'facebook_pages',
          profile: [],
        })
      );
      throw new Error(error);
    }
  };

  const handleSelectInstagramProfiles = async () => {
    try {
      const promises: Promise<any>[] = [];
      // Get selected instagram profiles from the remote profile list
      for (const profile of instagramProfileList) {
        if (selectedAccountIds[profile.id])
          promises.push(instagramApiService.selectInstagramProfile(profile));
      }

      // Get unselected existing facebook pages to deselect
      for (const profile of selectedInstagramProfiles) {
        if (!selectedAccountIds[profile.id])
          promises.push(instagramApiService.unselectInstagramProfile(profile.id));
      }
      if (promises.length === 0) return;
      await Promise.all(promises);
      await dispatch(fetchInstagramSelectedProfiles());
    } catch (error: any) {
      dispatch(
        integrateManyToService({
          platform: 'instagram_profiles',
          profile: [],
        })
      );
      throw new Error(error);
    }
  };

  const handleSelectSocialAccounts = async () => {
    try {
      setLoading(true);
      await handleSelectFacebookPages();
      await handleSelectInstagramProfiles();
      dispatch(
        enqueueNotification({
          name: 'Social account is selected',
          description: 'Select account sucessfully',
          type: 'Success',
        })
      );
      setSelectedAccountIds({});
    } catch (error) {
      dispatch(
        enqueueNotification({
          name: 'Failed to select account',
          description: 'Select account unsucessfully',
          type: 'Error',
        })
      );
    }
    handleCloseModal();
    setLoading(false);
  };

  useEffect(() => {
    const _selectedAccountIds: Record<string, boolean> = {};
    for (const page of [...selectedFacebookPages, ...selectedInstagramProfiles]) {
      _selectedAccountIds[page.id] = true;
    }
    setSelectedAccountIds(_selectedAccountIds);
  }, [selectSocialAccountsModal, selectedFacebookPages, selectedInstagramProfiles]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const { facebookPages, instagramProfiles } = await facebookApiService.getFacebookPages();
      setFacebookPageList(facebookPages);
      setInstagramProfileList(instagramProfiles);
      setLoading(false);
    };
    if (selectSocialAccountsModal) init();
  }, [selectSocialAccountsModal]);

  return (
    <React.Fragment>
      <Modal
        title="Select social pages"
        centered
        open={selectSocialAccountsModal}
        closable
        onCancel={handleCloseModal}
        destroyOnClose
        footer={[
          <Button onClick={handleCloseModal}>Cancel</Button>,
          <Button
            onClick={handleSelectSocialAccounts}
            type="primary"
            disabled={
              ![...facebookPageList, ...instagramProfileList].some(
                selectedPage => selectedAccountIds[selectedPage.id]
              )
            }>
            Select
          </Button>,
        ]}>
        <Divider />
        <LoadableContainer isLoading={loading} loadComponent={<Skeleton />}>
          <React.Fragment>
            <LoadableContainer
              isLoading={facebookPageList.length === 0 && instagramProfileList.length === 0}
              loadComponent={
                <div style={{ ...MIDDLE_STYLE }}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No social page found</span>}
                  />
                </div>
              }>
              <Space direction="vertical" style={{ width: '100%' }}>
                {facebookPageList.map(page => (
                  <FacebookAccountListItem
                    prefix={
                      <Checkbox
                        checked={selectedAccountIds[page.id]}
                        onChange={e => handleAccountCheckedChange(page.id, e.target.checked)}
                      />
                    }
                    facebookPage={page}
                  />
                ))}
                {instagramProfileList.map(profile => (
                  <InstagramAccountListItem
                    instagramProfile={profile}
                    prefix={
                      <Checkbox
                        checked={selectedAccountIds[profile.id]}
                        onChange={e => handleAccountCheckedChange(profile.id, e.target.checked)}
                      />
                    }
                  />
                ))}
              </Space>
            </LoadableContainer>
          </React.Fragment>
        </LoadableContainer>
      </Modal>
    </React.Fragment>
  );
};

export default SelectSocialAccountsModal;
