import React from 'react';
import { LoadableContainer, SocialPostImageGrid } from '@components/index';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR, STRIPE_BOX_SHADOW } from '@constants/theme';
import SplashScreen from '@components/SplashScreen';
import SocialPostCard from '@components/SocialPostCard';
import moment from 'moment/moment';
import { SocialPost } from '@core/models';
import { useBreakpoint } from '@core/hooks';

interface Props {
  locationId: string;
  existingSocialPost: SocialPost;
  caption: string;
  date: number;
  generating?: boolean;
  loading?: boolean;
}

const SocialPostCardContainer: React.FC<Props> = ({
  existingSocialPost,
  loading,
  locationId,
  generating,
  date,
  caption,
}) => {
  const { isTablet } = useBreakpoint();
  return (
    <React.Fragment>
      {isTablet ? (
        <SocialPostImageGrid hideOverlay width={'100%'} mediaIds={existingSocialPost.mediaIds} />
      ) : (
        <div
          style={{
            ...MIDDLE_STYLE,
            backgroundColor: GLOBAL_THEME_COLOR.$secondary_color,
            backgroundImage: `radial-gradient(${GLOBAL_THEME_COLOR.$dark_secondary_color} 8%, transparent 0)`,
            backgroundSize: '20px 20px',
            padding: '10px',
            border: `1px solid ${GLOBAL_THEME_COLOR.$border_color}`,
          }}>
          <LoadableContainer isLoading={!!loading} loadComponent={<SplashScreen />}>
            <SocialPostCard
              hideOverlay
              locationId={locationId}
              socialPost={{
                ...existingSocialPost,
                content: generating ? 'Social post content is being generated...' : caption,
                publish_at: moment.unix(date).toString(),
              }}
              style={{
                transform: 'scale(0.9)',
                boxShadow: STRIPE_BOX_SHADOW,
              }}
            />
          </LoadableContainer>
        </div>
      )}
    </React.Fragment>
  );
};

export default SocialPostCardContainer;
