/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import LoadableContainer from '@components/LoadableContainer';
import { Alert, Button, Card, Col, Divider, Modal, Popconfirm, Radio, Row, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import UploadImageModal from '../UploadImageModal';
import pluralize from 'pluralize';
import StudioImageItemDetail from '../StudioImageItemDetail';
import { CloseOutlined, NotificationFilled, UploadOutlined } from '@ant-design/icons';
import { PhotoImageDataItem, SnackbarItem, enqueueNotification, openModal } from '@core/features';
import {
  useAppDispatch,
  useAuthState,
  useBusinessState,
  useStudioState,
} from '@core/configureStore';
import { deleteStudioPhotos, fetchGalleryImages } from '@core/features/studio/thunks';
import { StudioPhoto, StudioPhotoSource } from '@core/models';
import SplashScreen from '@components/SplashScreen';
import { socialStudioApiService, socialStudioStreamApiService } from '@services/service-register';
import { MIDDLE_STYLE } from '@constants/responsive';
import { GLOBAL_THEME_COLOR } from '@constants/theme';
import { TbLayoutGrid, TbLayoutList } from 'react-icons/tb';
import ImageGalleryGridView from '@components/ImageGalleryTab/ImageGalleryGridView';
import ImageGalleryListView from '@components/ImageGalleryTab/ImageGalleryListView';
import {
  STREAM_IMPORT_SQUARE_PRODUCTS_TASK,
  STREAM_UPLOAD_IMAGE_TASK,
  useGlobalEventEmitter,
  useSocialPostDispatcher,
  useStreamingTaskStore,
  useStudioTourStore,
} from '@core/hooks';
import { useBreakpoint } from '@core/hooks';
import moment from 'moment';
import { emitCloseStream } from '@components/EventEmitterProvider';
import SmallStudioImageListItem from '@components/StudioImageListItem/small';
import { cloneArray } from '@utils/array.util';

enum ImageGalleryViewMode {
  GridView,
  ListView,
}

const RECOMMENDED_PHOTOS = 50;

const ImageGalleryTab = () => {
  const dispatch = useAppDispatch();
  const { inner } = useGlobalEventEmitter();
  const { isTablet } = useBreakpoint();
  const { user } = useAuthState();
  const { setRunning } = useStudioTourStore();
  const { isLoading: isIntegrationLoading } = useBusinessState();
  const { preCheckedGenerate } = useSocialPostDispatcher();
  const [selectedImageItem, setSelectedImageItem] = useState<StudioPhoto | undefined>(undefined);
  const {
    integration: { square: squareIntegration },
  } = useBusinessState();
  const { studioPhotos, isLoading, socialPosts } = useStudioState();
  const [importingSquarePhotos, setImportingSquarePhotos] = useState(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ImageGalleryViewMode>(ImageGalleryViewMode.GridView);
  const isSquareButtonDisabled = useMemo(
    () => !squareIntegration || importingSquarePhotos || isIntegrationLoading,
    [squareIntegration, importingSquarePhotos, isIntegrationLoading]
  );
  const { addTask } = useStreamingTaskStore();

  const handleUploadImage = () => {
    dispatch(
      openModal({
        modalName: 'uploadImageModal',
      })
    );
  };

  const handleImageItemClicked = (item: StudioPhoto) => {
    setSelectedImageItem(item);
  };

  const handleCloseDetail = () => {
    setSelectedImageItem(undefined);
  };

  const handleOnUpload = async (uploadedItems: PhotoImageDataItem[]) => {
    if (!user) return;
    try {
      setUploading(true);
      /** Request Google Photo upload tokens **/
      await addTask(
        {
          id: STREAM_UPLOAD_IMAGE_TASK,
          name: 'Uploading images to gallery',
          payload: uploadedItems,
          apiMethod: socialStudioStreamApiService.streamUploadImagesToS3Bucket,
          onInterrupted: async () => {
            await dispatch(fetchGalleryImages());
          },
          async onFinishedCallback() {
            await dispatch(fetchGalleryImages());
            // Start a product tour if there is no system social posts
            dispatch(
              enqueueNotification({
                name: 'Successfully upload images',
                type: 'Success',
              } as SnackbarItem)
            );

            const { hasSystemMadeSocialPosts } = await preCheckedGenerate(socialPosts);
            if (!hasSystemMadeSocialPosts) setRunning(true);
          },
        },
        uploadedItems.length
      );
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueNotification({
          name: 'Failed to upload images',
          description: `Gallery images fetched`,
          type: 'Error',
        } as SnackbarItem)
      );
    }
    setUploading(false);
  };

  const handleImportSquareProducts = async () => {
    if (!user) return;
    try {
      setImportingSquarePhotos(true);
      /** Request Google Photo upload tokens **/
      const productCatalogObjects = await socialStudioApiService.listSquareProductObjects();
      await addTask(
        {
          id: STREAM_IMPORT_SQUARE_PRODUCTS_TASK,
          name: 'Importing Square product photos',
          payload: undefined,
          apiMethod: socialStudioStreamApiService.streamImportSquarePhotosToS3Bucket,
          onInterrupted: async () => {
            await dispatch(fetchGalleryImages());
          },
          async onFinishedCallback() {
            await dispatch(fetchGalleryImages());
            setImportingSquarePhotos(false);
            // Start a product tour if there is no system social posts
            dispatch(
              enqueueNotification({
                name: 'Successfully upload Square images',
                type: 'Success',
              } as SnackbarItem)
            );

            const { hasSystemMadeSocialPosts } = await preCheckedGenerate(socialPosts);
            if (!hasSystemMadeSocialPosts) setRunning(true);
          },
        },
        productCatalogObjects.length
      );
    } catch (error) {
      console.log(error);
      dispatch(
        enqueueNotification({
          name: 'Failed to upload images',
          description: `Gallery images fetched`,
          type: 'Error',
        } as SnackbarItem)
      );
    }
  };

  const handlePreCheckedGeneratePosts = async () => {
    const squareProductPhotos = studioPhotos.filter(
      photo => photo.source === StudioPhotoSource.SquareProductPhoto
    );
    const renderSquareProductPhotos = squareProductPhotos.map(photo => (
      <SmallStudioImageListItem style={{ maxWidth: 200 }} item={photo} />
    ));

    if (squareProductPhotos.length > 0) {
      Modal.warning({
        title: 'Square product photos are imported',
        content: (
          <div>
            There are {squareProductPhotos.length} Square{' '}
            {pluralize('product', squareProductPhotos.length)} imported already. Import all Square
            products will lead to duplication of photos in your image gallery.
            <div style={{ marginTop: 20, overflow: 'auto', maxHeight: 300 }}>
              {renderSquareProductPhotos}
            </div>
          </div>
        ),
        cancelText: 'Cancel',
        okCancel: true,
        onCancel: () => {},
        okText: `Remove ${squareProductPhotos.length} Square product photos`,
        onOk: async () => {
          Modal.destroyAll();
          await dispatch(deleteStudioPhotos({ studioPhotos: squareProductPhotos }));
          await handleFetchImages();
        },
      });
      return;
    }

    await handleImportSquareProducts();
  };

  const handleFetchImages = async () => {
    if (!user) return;
    await dispatch(fetchGalleryImages());
  };

  const sortedStudioPhotos = useMemo(
    () =>
      cloneArray(studioPhotos).sort(
        (studioPhotoA, studioPhotoB) =>
          moment(studioPhotoB.created_date).unix() - moment(studioPhotoA.created_date).unix()
      ),
    [studioPhotos]
  );

  useEffect(() => {
    const init = async () => {
      await handleFetchImages();
    };
    init();
  }, [user?.selected_location]);

  window.addEventListener('unload', ev => {
    ev.preventDefault();
    if (inner) inner.emit(emitCloseStream(STREAM_UPLOAD_IMAGE_TASK));
  });

  return (
    <React.Fragment>
      <LoadableContainer isLoading={isLoading} loadComponent={<SplashScreen />}>
        <React.Fragment>
          <React.Fragment>
            <div style={{ ...MIDDLE_STYLE, justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <p style={{ margin: isTablet ? '10px 0px' : 0 }}>
                {studioPhotos.length} {pluralize('photo', studioPhotos.length)} in the total
              </p>
              <Space>
                {!isTablet && (
                  <Radio.Group
                    value={viewMode}
                    onChange={e => {
                      setViewMode(e.target.value);
                    }}>
                    <Radio.Button value={ImageGalleryViewMode.GridView}>
                      <div style={{ ...MIDDLE_STYLE }}>
                        <TbLayoutGrid style={{ marginRight: 10 }} />
                        Grid View
                      </div>
                    </Radio.Button>
                    {!isTablet && (
                      <Radio.Button value={ImageGalleryViewMode.ListView}>
                        <div style={{ ...MIDDLE_STYLE }}>
                          <TbLayoutList style={{ marginRight: 10 }} />
                          List View
                        </div>
                      </Radio.Button>
                    )}
                  </Radio.Group>
                )}
                <Space style={{ flexWrap: 'wrap' }}>
                  <Popconfirm
                    onConfirm={handlePreCheckedGeneratePosts}
                    title="Import Square Products"
                    description="Are you sure to import products from Square?"
                    disabled={isSquareButtonDisabled}
                    okButtonProps={{
                      size: 'middle',
                    }}
                    cancelButtonProps={{
                      size: 'middle',
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button loading={importingSquarePhotos} disabled={isSquareButtonDisabled}>
                      {!importingSquarePhotos && (
                        <span style={{ ...MIDDLE_STYLE }}>
                          <img
                            alt="Square"
                            src="square-inc.png"
                            width={25}
                            height={25}
                            style={{ marginRight: 10 }}
                          />{' '}
                          Import Square products
                        </span>
                      )}
                    </Button>
                  </Popconfirm>
                  <Button loading={uploading} type="primary" onClick={handleUploadImage}>
                    <UploadOutlined />
                    Upload image
                  </Button>
                </Space>
              </Space>
            </div>
            <p style={{ color: GLOBAL_THEME_COLOR.$text_color, marginTop: 10 }}>
              <NotificationFilled
                style={{ marginRight: 15, color: GLOBAL_THEME_COLOR.$highlight_color }}
              />{' '}
              Help Amazely generate better posts by annotating the photos below.
            </p>
            {studioPhotos.length < RECOMMENDED_PHOTOS && (
              <Alert
                showIcon
                style={{ marginTop: 20 }}
                type="info"
                message="The more photos you upload the better. We recommend 50 photos or more."
              />
            )}
            <Divider />
          </React.Fragment>
          <Row gutter={20}>
            <Col
              span={viewMode === ImageGalleryViewMode.GridView && selectedImageItem ? 17 : 24}
              style={{ height: '100%', overflow: 'auto' }}>
              <React.Fragment>
                <div style={{ marginTop: 20, paddingBottom: 100 }}>
                  <LoadableContainer
                    isLoading={studioPhotos.length === 0}
                    loadComponent={
                      <div style={{ ...MIDDLE_STYLE, flexDirection: 'column', overflow: 'hidden' }}>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img
                          alt={'Empty photo placeholder'}
                          src="jumbotron/empty-photo-placeholder.png"
                          style={{ maxWidth: 350, width: '100%', marginBottom: 30 }}
                        />
                        <h2 style={{ textAlign: 'center' }}>Let's upload photos and ready to go</h2>
                        <p
                          style={{
                            color: GLOBAL_THEME_COLOR.$dark_text_color,
                            maxWidth: 400,
                            textAlign: 'center',
                          }}>
                          Amazely uses AI to craft captivating social posts from the uploaded photos
                          on your behalf
                        </p>
                        <Button
                          style={{ marginTop: 20 }}
                          loading={uploading}
                          type="primary"
                          onClick={handleUploadImage}>
                          <UploadOutlined />
                          Upload image
                        </Button>
                      </div>
                    }>
                    {viewMode === ImageGalleryViewMode.GridView && (
                      <ImageGalleryGridView
                        studioPhotos={sortedStudioPhotos}
                        onItemClicked={handleImageItemClicked}
                      />
                    )}
                    {viewMode === ImageGalleryViewMode.ListView && (
                      <ImageGalleryListView
                        studioPhotos={sortedStudioPhotos}
                        onItemClicked={handleImageItemClicked}
                      />
                    )}
                  </LoadableContainer>
                </div>
              </React.Fragment>
            </Col>
            {selectedImageItem && (
              <Col span={7}>
                <Card
                  style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: '100vh',
                    zIndex: 2,
                    minWidth: isTablet ? '100%' : 500,
                    margin: isTablet ? 0 : 20,
                    overflow: 'auto',
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                  }}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <h3 className="airbnb-font">Image Detail</h3>
                      <CloseOutlined onClick={handleCloseDetail} />
                    </div>
                  }>
                  <LoadableContainer
                    isLoading={!selectedImageItem}
                    loadComponent={<div>No image selected</div>}>
                    <StudioImageItemDetail
                      onClose={() => setSelectedImageItem(undefined)}
                      studioPhotoId={selectedImageItem.id}
                    />
                  </LoadableContainer>
                </Card>
              </Col>
            )}
          </Row>
        </React.Fragment>
      </LoadableContainer>
      <UploadImageModal
        actionTitle="Upload"
        disabledPhotoGallerySource={true}
        disabledSquarePhotoSource={false}
        onUpload={handleOnUpload}
      />
    </React.Fragment>
  );
};

export default ImageGalleryTab;
